/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import './CommonModal.scss';

export default class CommonModal extends Component {
    constructor(props) {
        super(props);

        this.handleClose = this.handleClose.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);
    }

    handleClose() {
        if (this.props.onClose !== undefined) {
            this.props.onClose();
        }
    }

    handleOutsideClick(event) {
        event.preventDefault();
        if (event.target === event.currentTarget) {
            this.handleClose();
        }
    }

    render() {
        const {
            title,
            msg,
            button,
            onPrimaryClick,
            secondaryText,
            onClickSecondary,
        } = this.props;

        return (
            <Grid onClick={this.handleOutsideClick} class="common-modal">
                <Grid className="modal-container">
                    <Grid className="header position-relative">
                        <Typography className="header-text">{title}</Typography>
                    </Grid>
                    <div className="common-modal-content">
                        <Typography className="common-modal-message">
                            {msg}
                        </Typography>
                    </div>
                    <div className="common-modal-footer d-flex mt-3 justify-content-center">
                        {secondaryText && (
                            <Button
                                variant="contained"
                                color="secondary"
                                style={{ marginRight: '16px' }}
                                onClick={() => {
                                    onClickSecondary?.();
                                    this.handleClose();
                                }}
                            >
                                {secondaryText}
                            </Button>
                        )}
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                onPrimaryClick?.();
                                this.handleClose();
                            }}
                        >
                            {button}
                        </Button>
                    </div>
                </Grid>
            </Grid>
        );
    }
}
