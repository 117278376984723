import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Link } from 'react-router-dom';
import HeaderLogo from '../../../assets/images/drybarLogo.svg';
import PhoneLogo from '../../../assets/images/phoneLogo.svg';
import { doQuery, getPreviewMode } from '../../../state/utils/contentful';

const useStyles = makeStyles((theme) => ({
    headerCopy: {
        color: '#42413D',
        background: '#F9F9F9',
        padding: '20px 2px 20px 0px',
        textAlign: 'center',
        fontSize: '20px',
        fontFamily: 'URWForm',
    },
    backDryBarPage: {
        fontSize: '17px',
        lineHeight: '22px',
        fontFamily: 'URWForm',
        color: '#42413D',
        [theme.breakpoints.down('sm')]: { display: 'none' },
    },
    headerContainer: {
        background: '#FFFFFF',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        position: 'relative',
        // [theme.breakpoints.down('sm')]: { display: 'none' },
    },
    backHeaderIcon: {
        margin: 0,
    },
    displayInline: {
        display: 'inline-flex',
        margin: 0,
        position: 'absolute',
        left: 18,
        height: '100%',
        alignItems: 'center',
        top: 0,
    },
    dryBarLogo: {
        display: 'block',
        height: 70,
        width: 188,
        position: 'relative',
    },
    dryBarLogoImg: {
        position: 'absolute',
        bottom: 0,
    },
    bookingHelp: {
        alignItems: 'center',
        display: 'flex',
        position: 'absolute',
        right: 18,
        height: '100%',
        alignItems: 'center',
        top: 0,
        [theme.breakpoints.down('sm')]: { display: 'none' },
    },
    bookingHelpCopy: {
        fontFamily: 'URWForm',
        fontSize: '15px',
        margin: '0 13px 0 -35px',
    },
    bookingHelplineNo: {
        fontFamily: 'URWForm',
        fontSize: '14px',
        margin: '0 2px 0 13px',
    },
    backLink: {
        color: '#42413D',
        '&:focus, &:hover, &:visited, &:link, &:active': {
            textDecoration: 'none',
        },
        display: 'flex',
    },
}));

export default function TopHeader() {
    const classes = useStyles();
    const [globalConfig, setGlobalConfig] = useState({});
    
    useEffect(async () => {
        const navsRequest = await doQuery(getNavigators(), true);

        const navs = navsRequest?.drybarSiteNavigationCollection?.items;
        const mainNav = navs.find((n) => n.slugPrefix === 'drybar-main');
        const mainNavRequest = await doQuery(
            getNavigator(mainNav?.sys?.id),
            true
        );

        const config = {
            logo: '',
            settings: {
                phoneNumbers: {
                    drybarProducts: undefined
                }
            }
        }

        config.logo = mainNavRequest?.drybarSiteNavigation?.imageUrl;

        setGlobalConfig(config);
    }, []);

    return (
        <div>
            <div container className={classes.headerContainer}>
                <div className={classes.displayInline}>
                    <Link to="/" className={classes.backLink}>
                        <ArrowBackIosIcon fontSize="small" className={classes.backHeaderIcon} />
                        <span className={classes.backDryBarPage}>Back to Drybar Shops</span>
                    </Link>
                </div>
                <Link to="/" className={classes.dryBarLogo}>
                    <img
                        className={classes.dryBarLogoImg}
                        src={globalConfig?.logo?.replace('http:', 'https:') || HeaderLogo}
                        alt="header-logo"
                    />
                </Link>
                <div className={classes.bookingHelp}>
                    <span className={classes.bookingHelpCopy}>Need booking help?</span>
                    <img
                        src={PhoneLogo}
                        alt="header-logo"
                    />
                    <span className={classes.bookingHelplineNo}>{globalConfig?.settings?.phoneNumbers?.drybarProducts ?? '+44 (0)20 7893 8444'}</span>
                </div>
            </div>
        </div>
    );
}

const getNavigators = () => `
{
    drybarSiteNavigationCollection(preview: true) {
        items {
            sys {
                id
            }
            slugPrefix
        }
    }
}`;

const getNavigator = (id) => `
{
    drybarSiteNavigation(preview: true, id: "${id}") {
        imageUrl
        slugPrefix
        menuLinksCollection {
          items {
            __typename
            ... on DrybarNavigationLinkL1 {
              label
              externalUrl
              sublinkNavImageUrl
              internalLinkCollection {
                items {
                  label
                  externalUrl
                }
              }
            }
          }
        }
    }
}`;