/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';
import {
    Map, GoogleApiWrapper, Marker, InfoWindow,
} from 'google-maps-react';
import {
    Button, ButtonBase, Grid, withStyles,
} from '@material-ui/core';
import { object, func, array } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DirectionsOutlinedIcon from '@material-ui/icons/DirectionsOutlined';
import { withRouter } from 'react-router-dom';
import inactiveMarker from '../../../../assets/images/inactiveMarker.svg';
import phoneMapVector from '../../../../assets/images/phoneMapVector.svg';
import activeMarker from '../../../../assets/images/activePin.svg';
import favoriteMarker from '../../../../assets/images/favoriteMarker.svg';
// import retailMarker from '../../../../assets/images/retailMarker.svg';
import { getStoresData, getFocusStore } from '../../../../state/ducks/Service/Service-Selectors';
import { setFocusStore } from '../../../../state/ducks/Service/Service-Actions';
// import { Link, NavLink } from 'react-router-dom';

import useEvent from '../../../../utils/event/useEvent';
import useEventDispatch from '../../../../utils/event/useEventDispatch';

// let infoWindowRef = React.createRef();
// let containerElement = document.createElement(`div`);

const styles = () => ({
    infoWindowContainer: {
        width: '304px',
        padding: '0 15px',
    },
    infoWindowText: {
        display: 'block',
        fontFamily: 'URWForm',
        marginBottom: '12px',
    },
    infoWindowHeading: {
        display: 'flex',
        margin: '16px 0 !important',
        fontFamily: 'URWForm',
        fontSize: '18px',
        fontWeight: '600',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    inforWindowAddress: {
        fontSize: '15px',
        marginBottom: '8px',
    },
    infoWindowDistance: {
        fontSize: '14px',
        color: '#989898',
    },
    selectMapLatitude: {
        background: '#54575A',
        borderRadius: '0px',
        fontSize: '13px',
        width: '79px',
        height: '45px',
        textTransform: 'none',
        color: '#54575A',
        backgroundColor: '#FFDD30',
        '&:hover': {
            backgroundColor: '#b29a21',
        },
    },
    displayInline: {
        display: 'flex',
        whiteSpace: 'nowrap',
        fontSize: '14px',
        justifyContent: 'space-between',
        alignContent: 'center',
        marginBottom: '5px',
    },
    marginRight: {
        marginRight: '5px',
    },
    directionIcon: {
        color: '#42413D',
        '&:hover': {
            color: '#42413D',
        },
    },
});

const mapStyle = [
    {
        featureType: 'all',
        elementType: 'labels.text.fill',
        stylers: [
            {
                saturation: 36,
            },
            {
                color: '#333333',
            },
            {
                lightness: 40,
            },
        ],
    },
    {
        featureType: 'all',
        elementType: 'labels.text.stroke',
        stylers: [
            {
                visibility: 'on',
            },
            {
                color: '#ffffff',
            },
            {
                lightness: 16,
            },
        ],
    },
    {
        featureType: 'all',
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'administrative',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#fefefe',
            },
            {
                lightness: 20,
            },
        ],
    },
    {
        featureType: 'administrative',
        elementType: 'geometry.stroke',
        stylers: [
            {
                color: '#fefefe',
            },
            {
                lightness: 17,
            },
            {
                weight: 1.2,
            },
        ],
    },
    {
        featureType: 'landscape',
        elementType: 'geometry',
        stylers: [
            {
                color: '#f5f5f5',
            },
            {
                lightness: 20,
            },
        ],
    },
    {
        featureType: 'landscape.natural.landcover',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#ffffff',
            },
        ],
    },
    {
        featureType: 'landscape.natural.terrain',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#ffffff',
            },
        ],
    },
    {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [
            {
                color: '#f5f5f5',
            },
            {
                lightness: 21,
            },
        ],
    },
    {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [
            {
                color: '#dedede',
            },
            {
                lightness: 21,
            },
        ],
    },
    {
        featureType: 'poi.park',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#b1f1bb',
            },
        ],
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#ffffff',
            },
            {
                lightness: 17,
            },
        ],
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry.stroke',
        stylers: [
            {
                color: '#ffffff',
            },
            {
                lightness: 29,
            },
            {
                weight: 0.2,
            },
        ],
    },
    {
        featureType: 'road.arterial',
        elementType: 'geometry',
        stylers: [
            {
                color: '#ffffff',
            },
            {
                lightness: 18,
            },
        ],
    },
    {
        featureType: 'road.local',
        elementType: 'geometry',
        stylers: [
            {
                color: '#ffffff',
            },
            {
                lightness: 16,
            },
        ],
    },
    {
        featureType: 'transit',
        elementType: 'geometry',
        stylers: [
            {
                color: '#f2f2f2',
            },
            {
                lightness: 19,
            },
        ],
    },
    {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [
            {
                color: '#e9e9e9',
            },
            {
                lightness: 17,
            },
        ],
    },
    {
        featureType: 'water',
        elementType: 'geometry.fill',
        stylers: [
            {
                visibility: 'on',
            },
            {
                color: '#ade8ff',
            },
        ],
    },
];

const mapStyles = {
    width: '100%',
    height: '100%',
};

const containerStyle = {
    position: 'relative',
    width: '100%',
    height: '100%',
};

function delay(t, v) {
    return new Promise((resolve) => {
        setTimeout(resolve.bind(null, v), t);
    });
 }

const MapContainer = ({
    google, classes, onLocationSelect, currentLocation, stores, locationType, setFocusStore, focusStore, zoom
}) => {
    // The Harrods Knighsbridge store coords
    const centerPos = {
        lat: 51.5000388,
        lng: -0.1611783,
    };
    const [marker, setMarker] = useState(null);
    const [markerDistance, setMarkerDistance] = useState(0);
    const [zoomLevel, setZoomLevel] = useState(14);
    const [initialCenter, setInitialCenter] = useState(centerPos);
    const points = stores.map((data) => ({ lat: Number(data?.contact?.coordinates?.[0]), lng: Number(data?.contact?.coordinates?.[1]) }));
    const markersAddress = marker?.contact || {};
    const _mapLoaded = (mapProps, map) => {
        map.setOptions({
            styles: mapStyle,
        });
    };

    // map ref
    const mapRef = React.createRef();

    // refs for each marker
    const refs = stores.reduce((acc, value) => {
        acc[value.slug] = React.createRef();
        return acc;
    }, {});

    // calculate map bound
    const bounds = new google.maps.LatLngBounds();
    points.forEach((point) => bounds.extend(point));

    // map center pos
    // defalut is Harrods Knighsbridge
    let pos = centerPos;
    if (points?.length > 0) {
        pos = {
            lat: points?.[0]?.lat,
            lng: points?.[0]?.lng,
        };
    }

    if (currentLocation != null) {
        pos = currentLocation;
    }

    useEffect(() => {
        delay(300, pos).then((p) => {
            setInitialCenter(p);
        });
    }, [pos.lat, pos.lng]);

    useEffect(() => {
        if (!marker) return;
        getDistanceFromCurrentLoation(marker);
    }, [marker]);

    // info view pos
    let infoviewPos = { lat: 0, lng: 0 };
    if (marker?.settings?.enabled) {
        infoviewPos = { lat: parseFloat(marker?.contact?.coordinates[0]) + 0.003, lng: parseFloat(marker?.contact?.coordinates[1]) };
    }

    // event handler for centering map to store
    // when user click location on list, this event is emitted.
    const handleFocusStoreEvent = useCallback((store) => {
        if (marker == null || marker.slug != store.slug) {
            setMarker(store);
            refs[store.slug].current.marker.map.setZoom(15);
            refs[store.slug].current.marker.map.setCenter(refs[store.slug].current.marker.position);
        } else {
            setMarker(null);
        }

        setInitialCenter({
            lat: store?.contact?.coordinates?.[0],
            lng: store?.contact?.coordinates?.[1],
        });
    }, [refs]);
    useEvent('focus-store-from-list', handleFocusStoreEvent);

    // marker click event handler
    // when click marker, emit event for scrolling event list
    const dispatchEvent = useEventDispatch();
    const handleClick = useCallback((e, data) => {
        setMarker(data);
        e.map.setZoom(16);
        e.map.setCenter(e.position);
        // setFocusStore(data);

        dispatchEvent('focus-store', data);
    }, [dispatchEvent]);

    // close button handler of infoview
    const handleClose = () => {
        setMarker(null);
    };

    // book button handler of infoview
    const onBook = () => {
        if (marker?.settings?.enabled) onLocationSelect(marker);
    };

    function distance(lat1, lon1, lat2, lon2, unit) {
        if ((lat1 == lat2) && (lon1 == lon2)) {
            return 0;
        }

        const radlat1 = Math.PI * lat1 / 180;
        const radlat2 = Math.PI * lat2 / 180;
        const theta = lon1 - lon2;
        const radtheta = Math.PI * theta / 180;
        let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
            dist = 1;
        }
        dist = Math.acos(dist);
        dist = dist * 180 / Math.PI;
        dist = dist * 60 * 1.1515;
        if (unit == 'K') { dist *= 1.609344; }
        if (unit == 'N') { dist *= 0.8684; }
        return dist;
    };

    function handleZoomChanged() {
        setZoomLevel(mapRef.current.map.getZoom());
    };

    const getDistanceFromCurrentLoation = (marker) => {
        if (!marker) return;
        // if (!navigator.geolocation) return;
        // navigator.geolocation.getCurrentPosition((position) => {
        //     const current_pos = {
        //         lat: position.coords.latitude,
        //         lng: position.coords.longitude,
        //     };

        //     const pos_shop = {
        //         lat: marker.contact?.coordinates[0] || 34.052235,
        //         lng: marker.contact?.coordinates[1] || -118.243683,
        //     };
        //     const DIS = distance(current_pos.lat, current_pos.lng, pos_shop.lat, pos_shop.lng);
        //     setMarkerDistance(DIS);
        // }, (err) => {
        //     console.log('-- err : ', err);
        // });
    };

    return (
        <Map
            google={google}
            containerStyle={containerStyle}
            zoom={13}
            style={mapStyles}
            initialCenter={
                initialCenter
            }
            center={initialCenter}
            // bounds={bounds}
            ref={mapRef}
            onReady={(mapProps, map) => _mapLoaded(mapProps, map)}
            onZoomChanged={handleZoomChanged}
        >
            {stores.length && stores.filter((data) => !!data.contact).map((data) => {
                let markerIcon;
                if (
                    (Number(marker?.contact?.coordinates?.[0]) === Number(data?.contact?.coordinates?.[0]))
                    && (Number(marker?.contact?.coordinates?.[1]) === Number(data?.contact?.coordinates?.[1]))
                ) {
                    markerIcon = activeMarker;
                } else if (locationType == 'favorite') {
                    markerIcon = favoriteMarker;
                } else if (data.type == 'Retail Store') {
                    markerIcon = favoriteMarker;
                } else {
                    markerIcon = inactiveMarker;
                }
                return (
                    <Marker
                        key={`${Number(data?.contact?.coordinates?.[0])}`}
                        title={zoomLevel > 8 ? `${data.title}\n${data.contact.street1.replace(', ', '\n')}\n${data.contact.street2 != null ? data.contact.street2 + '\n' : ''}${data.contact.postalCode}` : `${data.title}`}
                        name={data.title}
                        position={{ lat: Number(data?.contact?.coordinates?.[0]), lng: Number(data?.contact?.coordinates?.[1]) }}
                        icon={markerIcon}
                        onClick={(e) => handleClick(e, data)}
                        ref={refs[data.slug]}
                        style={{ margin: '-51px 0px -12px 155px' }}
                    />
);
            })}
            {
                <InfoWindowEx
                    off
                    visible={marker?.settings?.enabled}
                    position={infoviewPos}
                    onClose={() => handleClose()}
                >
                    <div className={classes.infoWindowContainer}>
                        <p className={classes.infoWindowHeading}>
                            <span>{marker?.title}</span>
                            <a href={`https://www.google.com/maps/search/?api=1&query=${marker?.contact?.coordinates[0]},${marker?.contact?.coordinates[1]}`} target="_blank" className={classes.directionIcon}>
                                <DirectionsOutlinedIcon style={{ marginLeft: '24px' }} />
                            </a>

                        </p>
                        <span className={`${classes.infoWindowText} ${classes.inforWindowAddress}`}>
                            {markersAddress.street1}
                            ,
                            <br />
                            {markersAddress.city}
                            ,
                            {' '}
                            {markersAddress.state}
                            {' '}
                            {markersAddress.postalCode}
                        </span>
                        <Grid className={classes.displayInline}>
                            <Grid>
                                {markerDistance > 0
                                && (
                                <span className={`${classes.infoWindowText} ${classes.infoWindowDistance}`}>
                                    {markerDistance.toFixed(1)}
                                    {' '}
                                    miles away
                                </span>
)}
                                <div className={classes.displayInline}>
                                    <img src={phoneMapVector} alt="phone-vector" className={classes.marginRight} />
                                    <span>{marker?.contact?.phoneNumber}</span>
                                </div>
                            </Grid>
                            {/* {marker?.type == 'Drybar Shop' && <Grid>
                                <ButtonBase className={classes.selectMapLatitude} variant="outlined" onClick={onBook}>
                                    Book
                                </ButtonBase>
                            </Grid>} */}
                        </Grid>
                    </div>
                </InfoWindowEx>
            }
        </Map>
    );
};

class InfoWindowEx extends React.Component {
    constructor(props) {
      super(props);
      this.infoWindowRef = React.createRef();
      this.contentElement = document.createElement('div');
    }

    componentDidUpdate(prevProps) {
      if (this.props.children !== prevProps.children) {
        ReactDOM.render(
          React.Children.only(this.props.children),
          this.contentElement,
        );
        this.infoWindowRef.current.infowindow.setContent(this.contentElement);
      }
    }

    render() {
      return <InfoWindow ref={this.infoWindowRef} {...this.props} />;
    }
}

MapContainer.propTypes = {
    classes: object.isRequired,
    google: object,
    onLocationSelect: func.isRequired,
    stores: array.isRequired,
    focusStore: object.isRequired,
};

MapContainer.defaultProps = {
    google: {},
};

const mapStateToProps = (state) => ({
    stores: getStoresData(state),
    focusStore: getFocusStore(state),
});

const mapDispatchToProps = (dispatch) => ({
    setFocusStore: bindActionCreators(setFocusStore, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(GoogleApiWrapper({
    apiKey: 'AIzaSyAixN59eNVJmUFjTac2K4KCB88lM6zP4t4',
})(withStyles(styles)(MapContainer))));
