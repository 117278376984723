/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-indent */
import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import appConfig from 'app.config';
import { Button, TextField, CircularProgress } from '@material-ui/core';
import restClient from 'api/restClient';
import { updateContactList } from 'api/emarsys-api';
import TagManager from 'app/Components/GoogleTagManager';

import './SubscribeForm.scss';

const SubscribeSchema = Yup.object().shape({
    email: Yup.string().email().required('Error: Email is required'),
});

export const SubscribeForm = (props) => {
    const [subsribeError, setSubscribeError] = useState(null);
    const onSubmit = async (values) => {
        const { email } = values;
        setSubscribeError(null);
        props.setLoading(true);
        await restClient
            .post(updateContactList({ sourceType: 'DrybarshopsSubscribe', email, ...props.extraData }))
            .then((res) => {
                if (res.data?.ids?.length) {
                    const {
                        gtm: {
                            formCompleteEvent,
                            subscription: {
                                type,
                                option,
                            },
                        },
                    } = appConfig;
                    const tagManagerArgs = {
                        dataLayer: {
                            event: formCompleteEvent,
                            form_type: type,
                            subscription_option: option,
                        },
                    };
                    TagManager.dataLayer(tagManagerArgs);
                    gtag(googleAnalyticsEvents.EVENT, googleAnalyticsEvents.NEWSLETTER)
                    props.onSuccess();
                } else {
                    const errorCode = res.data?.errors[`${email}`];
                    const errorMsg = Object.keys(errorCode || { '': '' })[0] === '2009' ? 'Error: this email already exists' : res.data?.errors[`${email}`][`${errorCode}`];
                    setSubscribeError(errorMsg);
                }
                props.setLoading(false);
            });
    };

    return (
        <Formik
            initialValues={{
            email: '',
        }}
            enableReinitialize
            onSubmit={onSubmit}
            validationSchema={SubscribeSchema}
        >
            {({
            values,
            errors,
            touched,
            handleSubmit,
            handleChange,
        }) => (
            <form onSubmit={handleSubmit}>
                <TextField
                    placeholder="Enter your email address"
                    onChange={handleChange}
                    name="email"
                    value={values.email}
                    error={(errors && touched.email && errors.email) || !!subsribeError}
                    helperText={errors.email || subsribeError}
                    InputProps={{
                        endAdornment: <Button className="btn-gray" type="submit" disabled={props.loading}>
                            {props.loading ? <CircularProgress size={30} /> : 'Join'}
                                      </Button>,
                    }}
                    fullWidth
                    classes={{
                        root: 'subscribe-form-email',
                    }}
                />
            </form>
        )}
        </Formik>
);
};
