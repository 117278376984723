import * as actions from './Account-Actions';
import * as actionTypes from './Account-ActionTypes';
import reducers from './Account-Reducer';
import * as operations from './Account-Operations';

const watchers = {
    ...operations.watchers,
};

export {
    actionTypes,
    actions,
    reducers,
    watchers,
};
