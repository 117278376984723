import { fork, takeLatest } from 'redux-saga/effects';
import { setViewAppointment } from './Account-Actions';

// eslint-disable-next-line no-empty-function
function* workerSetViewAppointment() {
}

function* watcherSetViewAppointment() {
    yield takeLatest(setViewAppointment().type, workerSetViewAppointment);
}

export const watchers = {
    watcherSetViewAppointment: fork(watcherSetViewAppointment),
};

export default {
    watchers,
};
