/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { User } from "oidc-client"
import appconfig from 'app.config';

const HOST = appconfig.apiUrl.replace(/(^\w+:|^)\/\//, '');

const getUrl = (endpoint, host, protocolOverride) => `${protocolOverride || 'https://'}${host || HOST}${endpoint}`;

axios.defaults.withCredentials = true;

const callAxios = (method, endpoint, params = {}, data = {}, headers = {
    'X-Requested-With': 'XMLHttpRequest',
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    withCredentials: true,
    crossDomain: true
}, options) => {
    const customHost = options.host;
    const protocolOverride = options.protocol;

    const config = {
        method,
        url: getUrl(endpoint, customHost, protocolOverride),
        params,
        headers,
        data,
        endpoint: endpoint,
        withCredentials: true
    };

    return axios(config)
};

axios.interceptors.request.use((config) => {
    if (config.url.includes('/booker/') || config.url.includes('/user') || config.url.includes('/payment/')) {
        let user = getUserFromStorage();

        try {
            if (user) {
                config.headers = {
                    ...config.headers,
                    Authorization: `Bearer ${user.access_token}`
                };
            }
        } catch (e) {
            tokenData = {};
        }
    }

    /* for Mokta account endpoints, we need to modify the host */
    /* all 'account' calls go to Mokta apart from '/user/' endpoints */
    if (config.url.includes('/account/') && !config.url.includes('user/')) {
        config.url = getUrl(config.endpoint, appconfig.mokta.account.replace('https://', ''));
        console.log('modified url: ' + config.url);
    }
    /* for Mokta password endpoints, we need to modify the host */
    /* all 'password' calls go to Mokta apart from '/user/' endpoints */
    if (config.url.includes('/password/') && !config.url.includes('user/')) {
        config.url = getUrl(config.endpoint, appconfig.mokta.recovery.replace('https://', ''));
        console.log('modified url: ' + config.url);
    }

    return config;
});
axios.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data


        // If we have a response from the barfly instore token endpoint and it appears valid then set the user identity into localStorage...
        // ... the bearer token will then be picked up automatically by the restClient for subsequent requests
        if (response.config.url.includes('user/Lite') && response.data != null && response.data.access_token.length > 0) {
            response.data.scope = 'instore';
            localStorage.setItem('oidc.user:' + appconfig.mokta.authority + ':' + appconfig.mokta.client_id, JSON.stringify(response.data));
        }

        return response;
    },
    async (error) => {  
            if (
                error.response?.status === 401
                && (error.config.url.includes('/booker/') || error.config.url.includes('/payment/'))
            ) {
                const isInstoreUrl = window.location.href.includes('instore');
                //const user = getUserFromStorage();
                if (isInstoreUrl) {
                    window.location = '/instore-barfly-membership';
                }
                else {
                    // 401 unauthorized, so redirect back to logon path but with a redirect to come back to where we were
                    window.location = '/auth/login?redirect=' + window.location.pathname;
                }
            } 


        console.log('error>>>>>', error, error.response);

        return Promise.reject(error);

    },

);

function getUserFromStorage() {
    const oidcStorage = localStorage.getItem(`oidc.user:${appconfig.mokta.authority}:${appconfig.mokta.client_id}`)
    if (!oidcStorage) {
        return null
    }

    return User.fromStorageString(oidcStorage)
}

const get = ({
    url, data, params, headers, options = {},
}) => callAxios('get', url, params, data, headers, options);
const post = ({
    url, data, params, headers, options = {},
}) => callAxios('post', url, params, data, headers, options);
const put = ({
    url, data, params, headers, options = {},
}) => callAxios('put', url, params, data, headers, options);

export default {
    get,
    post,
    put,
};
