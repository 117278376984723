/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { object } from 'prop-types';
import { Typography, Grid, Dialog, Button } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { withRouter } from 'react-router-dom';
import Slider from 'react-slick';

import MagicSliderDots from 'react-magic-slider-dots';
import { getPageLabelFromUrl } from 'utils/helper';
import { DocumentToComponent } from '../../../../../../components';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-magic-slider-dots/dist/magic-dots.css';
import addonBanner from '../../../../../../assets/images/addon-banner.png';
import addOnAd from '../../../../../../assets/images/add-on-ad.png';

import arrowLeftIcon from '../../../../../../assets/images/arrow-left-1.svg';
import arrowRightIcon from '../../../../../../assets/images/arrow-right-1.svg';
import {
    doQuery,
    getPreviewMode,
} from '../../../../../../state/utils/contentful';

const ScreenServicesAndAddOnsCollection = ({
    classes,
    screenServicesAddOnsData,
    children,
    history,
    location,
}) => {
    const [marketingCard, setMarketingCard] = useState({});

    const addOnsCollection = screenServicesAddOnsData || [];
    const addOnsDescription =
        addOnsCollection?.description?.json?.content?.[0]?.content || [];
    const bookAddOnsCollection =
        addOnsCollection?.productsCollection?.items || [];
    const isBlowServices = location?.pathname?.includes('blow-services');
    const { marketingComponentsCollection } = screenServicesAddOnsData;

    const [selectedItem, setSelectedItem] = useState();
    const [modalOpen, setModalOpen] = useState(false);

    const handleOpenModal = (item) => {
        mParticle.logEvent(
            'Services - View Service',
            mParticle.EventType.Navigation,
            {
                'Source Page': getPageLabelFromUrl(location.pathname),
                Service: item?.title,
            }
        );
        setModalOpen(true);
        setSelectedItem(item);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleMeClick = (item) => {
        mParticle.logEvent(
            'Services - Book an Appointment',
            mParticle.EventType.Other,
            {
                'Source Page': getPageLabelFromUrl(location.pathname),
                Service: item?.title,
            }
        );
        history.push('/booking/location');
    };

    const NextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <>
                <div className="slick-arrow right" onClick={onClick}>
                    <img src={arrowLeftIcon} alt="slick arrow right" />
                </div>
            </>
        );
    };
    const PrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <>
                <div className="slick-arrow left" onClick={onClick}>
                    <img src={arrowRightIcon} alt="slick arrow left" />
                </div>
            </>
        );
    };

    const settings = {
        dots: true,
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        appendDots: (dots) => (
            <MagicSliderDots
                dots={dots}
                numDotsToShow={4}
                dotWidth={30}
                dotContainerClassName={`magic-dots slick-dots ${classes.sliderDotsWrap}`}
            />
        ),
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const marketingCardId = marketingComponentsCollection?.items[1]?.sys?.id;
    const marketingCardQuery = `{
        marketingCardCollection(${getPreviewMode()}, where: {sys: {id: "${marketingCardId}"}}) {
            items {          
              actionsCollection{
                items{
                  linkToUrl
                  style
                }
              }          
            }
          }    
    }`;
    useEffect(() => {
        if (marketingCardId) {
            doQuery(marketingCardQuery).then((data) => {
                setMarketingCard(data?.marketingCardCollection?.items?.[0]);
            });
        }
    }, [marketingCardId]);

    const action = marketingCard?.actionsCollection?.items?.[0];

    return (
        <Grid className={classes.container}>
            <Grid className={classes.bannerContainer}>
                <img
                    src={
                        marketingComponentsCollection?.items[0]?.mobileMedia
                            ?.url || addonBanner
                    }
                    className={classes.banner}
                    alt={
                        marketingComponentsCollection?.items[0]
                            ?.alternateTitle || ''
                    }
                />
            </Grid>
            {children}
            <Typography variant={'h1'} className={classes.addOnsTitle}>
                {addOnsCollection.title}
            </Typography>
            <Grid className={classes.addOnsContainer}>
                <Typography className={classes.headerSubTitle}>
                    {addOnsDescription?.[0]?.value}
                </Typography>
                <Typography
                    className={`${classes.addOnsTreatmentOffer} ${classes.fontWeight}`}
                >
                    {addOnsDescription?.[1]?.value}
                </Typography>
            </Grid>
            <Grid className={classes.borderAddOns} />
            <Grid container className={classes.collectionCotainer}>
                {bookAddOnsCollection.map((items) => {
                    return (
                        <>
                            <Grid
                                item
                                xs={3}
                                className={classes.bookYourAddOns}
                            >
                                <img
                                    src={
                                        items?.imagesCollection?.items?.[0]
                                            ?.desktopMedia?.url
                                    }
                                    alt="add-ons"
                                    className={classes.imageAddOns}
                                    onClick={() => handleOpenModal(items)}
                                />

                                <Grid
                                    className={`${classes.addOnsDetails} ${
                                        isBlowServices
                                            ? classes.serviceDetailsContent
                                            : classes.addOnsDetailsContent
                                    }`}
                                    onClick={() => handleOpenModal(items)}
                                >
                                    <Grid className={classes.displayflex}>
                                        <Typography
                                            className={classes.addOnsCopy}
                                        >
                                            {items?.title}
                                        </Typography>
                                        <Typography
                                            className={`${classes.addOnsTextBold} ${classes.marginLeft}`}
                                        >
                                            £{items.price}
                                        </Typography>
                                    </Grid>
                                    <Typography
                                        className={classes.addOnsDescJson}
                                    >
                                        {
                                            items?.description?.json
                                                ?.content?.[0]?.content[0]
                                                ?.value
                                        }
                                    </Typography>
                                </Grid>

                                {items.serviceTime ? (
                                    <Grid
                                        className={`${classes.serviceTime} mx-auto mb-2`}
                                    >
                                        <Typography
                                            className={classes.servicesTime}
                                        >
                                            Service Time
                                        </Typography>
                                        <Typography
                                            className={classes.addOnsTextBold}
                                        >
                                            {items.serviceTime} mins
                                        </Typography>
                                    </Grid>
                                ) : null}
                                <Button
                                    onClick={() => handleMeClick(items)}
                                    className={classes.selected}
                                    variant="outlined"
                                >
                                    Book Now
                                </Button>
                            </Grid>
                        </>
                    );
                })}
            </Grid>
            <Grid className={classes.advertiseContainer}>
                <Grid container>
                    <Grid>
                        <Grid container className={classes.advertiseImgWrap}>
                            {action?.style == 'Wrapper' &&
                            action?.linkToUrl != '' ? (
                                <a href={action.linkToUrl}>
                                    <img
                                        src={
                                            marketingComponentsCollection
                                                ?.items[1]?.image?.mobileMedia
                                                ?.url ||
                                            marketingComponentsCollection
                                                ?.items[1]?.desktopMedia?.url
                                        }
                                        className={classes.advertiseImg}
                                        alt={
                                            marketingComponentsCollection
                                                ?.items[1]?.image
                                                ?.alternateTitle || ''
                                        }
                                    />
                                </a>
                            ) : (
                                <img
                                    src={
                                        marketingComponentsCollection?.items[1]
                                            ?.image?.mobileMedia?.url || addOnAd
                                    }
                                    className={classes.advertiseImg}
                                ></img>
                                //<img src={bannerUrl} className={classes.advertiseImg} alt="advertise image" />
                            )}
                        </Grid>
                        {/*     <Grid container className={classes.blowoutsBtnWrap}>*/}
                        {/*        <a className={`btn-common btn-gray-trans btn-lg ${classes.blowoutsBtn}`}>*/}
                        {/*            Shop Blowouts*/}
                        {/*        </a>*/}
                        {/*    </Grid> */}
                    </Grid>
                </Grid>
            </Grid>
            <Dialog
                open={modalOpen}
                onClose={handleCloseModal}
                PaperProps={{
                    style: {
                        margin: 15,
                    },
                }}
            >
                <div className={classes.modalContent}>
                    <ArrowBackIosIcon
                        className={classes.backIcon}
                        onClick={handleCloseModal}
                    />
                    <Typography
                        className={`${classes.addOnsCopy} ${classes.textCenter}`}
                    >
                        {selectedItem?.title}
                    </Typography>
                    <div className={classes.slideSection}>
                        <Slider {...settings}>
                            {selectedItem?.imagesCollection?.items?.map(
                                (imageCollection) => (
                                    <img
                                        src={imageCollection?.desktopMedia?.url}
                                        alt="add-ons"
                                        className={classes.imageAddOns}
                                        onLoad={() =>
                                            window.dispatchEvent(
                                                new Event('resize')
                                            )
                                        }
                                    />
                                )
                            )}
                        </Slider>
                    </div>
                    <Grid className={classes.addOnsDetails}>
                        {isBlowServices ? (
                            <>
                                <Grid>
                                    <Grid className={classes.descriptionItem}>
                                        <Typography
                                            className={classes.TitleIndex}
                                        >
                                            How It Works
                                        </Typography>
                                        <Typography
                                            className={
                                                classes.serviceDescription
                                            }
                                        >
                                            {
                                                selectedItem?.description?.json
                                                    ?.content?.[0]?.content?.[0]
                                                    ?.value
                                            }
                                        </Typography>
                                    </Grid>
                                    {/*<Grid className={classes.descriptionItem}>*/}
                                    {/*    <Typography className={classes.TitleIndex}>Results</Typography>*/}
                                    {/*    <Typography className={classes.serviceDescription}>*/}
                                    {/*        Pro in gravida dolor sit amet*/}
                                    {/*        lacus accumsan et viverra justo commodo.*/}
                                    {/*        {' '}*/}
                                    {/*    </Typography>*/}
                                    {/*</Grid>*/}
                                    <Grid
                                        className={`${classes.modalFlexItem} ${classes.borderTop}`}
                                    >
                                        <Typography
                                            className={classes.flexTitle}
                                        >
                                            Price
                                        </Typography>
                                        <Typography
                                            className={classes.flexDescription}
                                        >
                                            £{selectedItem?.price}
                                        </Typography>
                                    </Grid>
                                    <Grid className={classes.modalFlexItem}>
                                        <Typography
                                            className={classes.flexTitle}
                                        >
                                            Approximate Service Time
                                        </Typography>
                                        <Typography
                                            className={classes.flexDescription}
                                        >
                                            20 mins
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </>
                        ) : (
                            <>
                                <Grid>
                                    <Grid className={classes.descriptionItem}>
                                        <Typography
                                            className={classes.TitleIndex}
                                        >
                                            How It Works
                                        </Typography>
                                        <Typography
                                            className={
                                                classes.serviceDescription
                                            }
                                        >
                                            {
                                                selectedItem?.description?.json
                                                    ?.content?.[0]?.content?.[0]
                                                    ?.value
                                            }
                                        </Typography>
                                    </Grid>
                                    {/*<Grid className={classes.descriptionItem}>*/}
                                    {/*    <Typography className={classes.TitleIndex}>Results</Typography>*/}
                                    {/*    <Typography className={classes.serviceDescription}>*/}
                                    {/*        Pro in gravida dolor sit amet*/}
                                    {/*        lacus accumsan et viverra justo commodo.*/}
                                    {/*        {' '}*/}
                                    {/*    </Typography>*/}
                                    {/*</Grid>*/}

                                    <Grid
                                        className={`${classes.modalFlexItem} ${classes.borderTop}`}
                                    >
                                        <Typography
                                            className={classes.flexTitle}
                                        >
                                            Price
                                        </Typography>
                                        <Typography
                                            className={classes.flexDescription}
                                        >
                                            £{selectedItem?.price}
                                        </Typography>
                                    </Grid>
                                    <Grid className={classes.modalFlexItem}>
                                        <Typography
                                            className={classes.flexTitle}
                                        >
                                            Approximate Service Time
                                        </Typography>
                                        <Typography
                                            className={classes.flexDescription}
                                        >
                                            20 mins
                                        </Typography>
                                    </Grid>

                                    {selectedItem?.price && (
                                        <Grid
                                            className={`${classes.modalFlexItem} ${classes.borderTop}`}
                                        >
                                            <Typography
                                                className={classes.flexTitle}
                                            >
                                                Price
                                            </Typography>
                                            <Typography
                                                className={
                                                    classes.flexDescription
                                                }
                                            >
                                                £{selectedItem?.price}
                                            </Typography>
                                        </Grid>
                                    )}
                                    {selectedItem?.serviceTime ? (
                                        <Grid className={classes.modalFlexItem}>
                                            <Typography
                                                className={classes.flexTitle}
                                            >
                                                Approximate Service Time
                                            </Typography>
                                            <Typography
                                                className={
                                                    classes.flexDescription
                                                }
                                            >
                                                {selectedItem.serviceTime} mins
                                            </Typography>
                                        </Grid>
                                    ) : null}

                                    {/* {
                                        selectedItem?.bestFor ?
                                            <>
                                                <Typography className={classes.bestForCopy}>Best For</Typography>
                                                <Typography className={classes.subtitle}>{selectedItem?.bestFor}</Typography>
                                            </> : null
                                    }                                     */}
                                </Grid>
                            </>
                        )}
                    </Grid>
                    <Button
                        variant="outlined"
                        className={classes.selected}
                        onClick={handleMeClick}
                    >
                        Book Now
                    </Button>
                </div>
            </Dialog>
        </Grid>
    );
};

ScreenServicesAndAddOnsCollection.propTypes = {
    screenServicesAddOnsData: object.isRequired,
    classes: object.isRequired,
};

export default withRouter(ScreenServicesAndAddOnsCollection);
