/* eslint-disable no-console */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import axios from 'axios';
import {
    Button,
    Grid,
    Typography,
    withStyles,
    Backdrop,
    CircularProgress,
    FormControl,
    Select,
    MenuItem,
} from '@material-ui/core';
import jwtDecode from 'jwt-decode';
import { object } from 'prop-types';
import React, {useRef, useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {NAME_REGEX, PHONE_REGEX, PASSWORD_SPECIAL_OR_NUM_REGEX, PASSWORD_UPPER_CASE_REGEX, PASSWORD_LOWER_CASE_REGEX} from '../../../../src/utils/validation/regex'
import { getCustomer } from 'api/booking-api';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getPageLabelFromUrl } from 'utils/helper';
import restClient from 'api/restClient';
import EditableTextField from '../../../app/Components/EditableTextField/EditableTextField';
import { MOBILE_BREAKPOINT } from '../../../Helpers/breakpoints';
import UpdateAccountModal from './UpdateAccountModal';
import { setAuthUserDetails } from '../../../state/ducks/Account/Account-Actions';
import {updateUser} from '../../../../src/api/auth-api'
import TextInputField from 'app/Components/Auth/Partials/TextInputField';
import {getCountryCodes} from 'app/Components/Auth/Partials/Registration/signupFormFields';


const styles = (theme) => ({
    container: {
        background: '#fff',
        marginLeft: '15px',
        padding: '34px 22px',
        textAlign: 'center',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            background: '#f9f9f9',
            marginLeft: 0,
        },
    },

    errorStyle: {
        color: '#EE0000',
        fontSize: '0.75rem',
        textAlign: 'left',
        fontWeight: 400,
        lineHeight: 1.66
    },

    successStyle: {
        color: 'green',
        fontSize: '0.75rem',
        textAlign: 'left',
        fontWeight: 400,
        lineHeight: 1.66
    },

    dropDownContainer: {
        padding: '12px 0px 0px 0px',
        marginRight: '25px',
        minWidth: '100px'
    },

    inputContainer: {
        display: 'flex',
        width:'100%'
    },

    heading: {
        fontWeight: '800',
        textAlign: 'left',
        paddingBottom: '10px !important',
        borderBottom: '1px solid #D1D1D1',
        color: '#42413D',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            textTransform: 'uppercase',
            fontFamily: 'MrsEavesOT-Roman',
            fontWeight: 'normal',
        },
    },

    borderlessHeading: {
        fontWeight: '500',
        textAlign: 'left',
        paddingBottom: '10px !important',
        color: '#42413D',
        fontSize: '15px',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            textTransform: 'uppercase',
            fontFamily: 'MrsEavesOT-Roman',
            fontWeight: 'normal',
        },
    },

    formContainer: {
        padding: '30px 0 0',
    },

    button: {
        width: '378px',
        height: '63px',
        maxWidth: '100%',
        margin: '20px 0 48px',
    },
    emailPref: {
        textAlign: 'left',
        margin: '32px 0 0',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            background: '#ffffff',
            padding: '15px',
            margin: 0,
        },
    },
    emailHeader: {
        padding: '0',
        fontSize: '13px',
    },
    emailTypography: {
        margin: '10px 0 20px',
        color: '#979797',
        fontSize: '13px',
        lineHeight: '20px',
    },
    dialog: {
        padding: '25px',
    },
    backdrop: {
        zIndex: 11,
        color: '#fff',
    },
    modalTitle: {
        textAlign: 'center',
        fontSize: '31px',
        fontFamily: 'DINCondensed-Regular',
        lineHeight: '37px',
        textTransform: 'uppercase',
        fontWeight: '600',
    },
    passwordIcon: {
        verticalAlign: 'middle', 
        fontSize: '14px'
    }
});

const AccountDetailsSchema = Yup.object().shape({
    given_name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('First name is required').matches(
        NAME_REGEX,'Error: Invalid First Name',
    ),
    family_name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Last name is required').matches(
        NAME_REGEX,'Error: Invalid Last Name',
    ),
    primaryPhone: Yup.string().nullable().required('Phone number is required')
        .matches(
            PHONE_REGEX,
            'Error: Invalid phone number',
        ), 
});

/**
 * Static data - make dynamic when connected with API
 */
const AccountInformation = ({
classes, user, setAuthUserInfo, location,
}) => {
    console.log('User:', user);
    const [userData, setUserData] = useState(user);
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
    const [showLoading, setLoading] = useState(false);
    const [countryCodes, setCountryCodes] = useState(["UK +44"])
    const [passwordErrors, setPasswordErrors] = useState({});
 
    useEffect( async () => {
        mParticle.logEvent('Account - Edit Profile', mParticle.EventType.Other, {
            'Source Page': getPageLabelFromUrl(location.pathname),
        });
        setCountryCodes(await getCountryCodes());
    }, []);

    const handleChange = (e) => {
        setUserData({
            ...userData,
            [e.target.name]: e.target.value,
        });
    };

    const handlePasswordChange = (e) => {
        handleChange(e);
        const newError = { ...passwordErrors };
        delete newError.password;
        setPasswordErrors(newError);
    };

    const handleUpdateUser = async (values) => {
        const {
            given_name, family_name, primaryPhone, phoneCountryCode, email, password
        } = values;
        const newPasswordErrors = { ...passwordErrors };
        delete newPasswordErrors.password;
        if (!password?.length) {
            newPasswordErrors.password = 'Error: Password is required';
            setPasswordErrors(newPasswordErrors);
            return
        } else {
            delete newPasswordErrors.password;
        }
        setLoading(true);
                try {  
                    const { data: resUserData } = await restClient.post(updateUser(
                        given_name,
                        family_name,
                        primaryPhone,
                        phoneCountryCode,
                        user.bookerID,
                        user.locationId,
                        user.rewardsCardId,
                        password,
                    ));
                        
                //This data should come back from api?
                setAuthUserInfo({
                    ...user,
                    given_name: given_name,
                    family_name: family_name,
                    name: `${given_name} ${family_name}`,
                    primaryPhone: primaryPhone,
                    phoneCountryCode: phoneCountryCode
                });
                setShowConfirmationDialog(true);
                } catch (err) {
                    setPasswordErrors({ password:"We're sorry, there was a problem updating your details. Please check and try again." });
                    setLoading(false);
                }
    setLoading(false);
    };

    return (
        <>
            <Grid className={classes.container}>
                <Typography className={classes.heading}>
                    Profile
                </Typography>
                <Grid className={classes.formContainer}>
                    <Formik
                        initialValues={userData}
                        enableReinitialize
                        onSubmit={handleUpdateUser}
                        validationSchema={AccountDetailsSchema}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleSubmit,
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <EditableTextField
                                    label="First Name"
                                    onChange={handleChange}
                                    defaultValue={values.given_name}
                                    name="given_name"
                                    placeholder="Your first name here..."
                                    error={errors && touched.given_name && errors.given_name}
                                    helperText={errors && touched.given_name && errors.given_name}                                   
                                />
                                <EditableTextField
                                    label="Last Name"
                                    onChange={handleChange}
                                    defaultValue={values.family_name}
                                    name="family_name"
                                    placeholder="Your last name here..."
                                    error={errors && touched.family_name && errors.family_name}
                                    helperText={errors && touched.family_name && errors.family_name}
                                />
                                <EditableTextField
                                    disabled
                                    label="Email address"
                                    defaultValue={values.email}
                                    name="email"
                                    error={errors && touched.email && errors.email}
                                    helperText={errors && touched.email && errors.email}
                                />
                            
                                <Grid container className={classes.inputContainer}>
                                    <Grid item xs={12}>
                                         <Typography className={classes.borderlessHeading}>
                                    Mobile Number
                                </Typography>
                                    </Grid>
                                    <Grid>
                                    <FormControl className={classes.dropDownContainer}>
                                        <Select
                                            name="phoneCountryCode" 
                                            defaultValue={values.phoneCountryCode}   
                                            onChange={handleChange} 
                                            required={true}>
                                            {countryCodes.map((option) => (
                                                <MenuItem value={option}>{option}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    </Grid>
                                    <Grid item xs={10}>
                                    <EditableTextField
                                        onChange={handleChange}
                                        defaultValue={values.primaryPhone}
                                        name="primaryPhone"
                                        placeholder="Your phone number here..."
                                        error={errors && touched.primaryPhone && errors.primaryPhone}
                                        helperText={errors && touched.primaryPhone && errors.primaryPhone}
                                    />
                                    </Grid>
                                </Grid>
                                    <Grid>
                                        <EditableTextField
                                            name="password"
                                            type="password"
                                            label="Enter password to save"
                                            placeholder="Your password here..."
                                            onChange={handlePasswordChange}
                                            error={passwordErrors.password}
                                            helperText={passwordErrors.password ? passwordErrors.password : ''}
                                        />
                                    </Grid>
                     
                                <Grid /*className={classes.inputContainer}*/>  
                                <Button
                                    className={classes.button}
                                    variant="contained"
                                    color="secondary"
                                    type="submit"
                                >
                                    Save
                                </Button>
                                </Grid>
                            </form>
                        )}
                    </Formik>
                </Grid>
                {showLoading ? (
                    <Backdrop className={classes.backdrop} open>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                ) : null}
            </Grid>

            <UpdateAccountModal
                showConfirmationDialog={showConfirmationDialog}
                setShowConfirmationDialog={setShowConfirmationDialog}
                classes={classes}
                successMessage="Your account information has been updated successfully"
            />
        </>
    );
};

AccountInformation.propTypes = {
    classes: object.isRequired,
    user: object.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
    setAuthUserInfo: bindActionCreators(setAuthUserDetails, dispatch),
});

export default connect(null, mapDispatchToProps)(withRouter(withStyles(styles)(AccountInformation)));
