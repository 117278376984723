/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable react/require-default-props */
import {
    Button, Grid, Typography, withStyles, Checkbox,
} from '@material-ui/core';
import { object } from 'prop-types';
import React, { useState, useEffect } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Media from 'react-media';
import { bindActionCreators } from 'redux';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { toast, ToastContainer } from 'react-toastify';
import { getPageLabelFromUrl } from 'utils/helper';
import moment from 'moment';
import { MOBILE_BREAKPOINT } from '../../../Helpers/breakpoints';
import DateCard from './Partials/DateCard';
import {
    getSelectedDateTime,
    getSelectedSlot,
    getNumberOfGuests,
    isGuestWithDifferentServices,
    getSelectedDate,
    getServicesData,
    getServicesDataAsObject,
    getExtensions,
    getExtensionAddon,
    getLocationData,
    getServiceTime,
} from '../../../state/ducks/Booking/Booking-Selectors';
import {
    getDateStringMMDDYY,
    monthNames,
    getWeekByFirstDate,
    getDateString,
    getMonth,
    getDateWithFirstTime,
    getEndDate,
    getTime,
    getDateWithoutTimezone,
} from '../../../Helpers/dateTime';
import {
 setDateTimeClient, setSlotTime, availEmployeeBookingSlots, availRoomBookingSlot, setExtensionAddon,
} from '../../../state/ducks/Booking/Booking-Actions';
import Calendar from './Partials/Calendar';
import SlotsContainer from './Partials/SlotsContainer';
import { getBookerTimeSlot, getServices } from '../../../api/booking-api';
import restClient from '../../../api/restClient';

const styles = (theme) => ({
    topContainer: {
        width: '100%',
        backgroundColor: theme.palette.common.white,
        height: '320px',
        padding: '26px 22px 0',
        [theme.breakpoints.down('sm')]: {
            backgroundColor: '#f3f3f3',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: 'unset',
            padding: '26px 5px 0'
        },
    },
    heading: {
        marginBottom: '15px',
        [theme.breakpoints.down('sm')]: {
//            width: '24rem',
            textAlign: 'center',
            marginBottom: '25px !important',
        },
    },
    datePickerContainer: {
        display: 'flex',
        flexDirection: 'column',
        borderTop: `1px solid ${theme.palette.common.lightGrey[0]}`,
        backgroundColor: theme.palette.common.lightGrey[3],
        padding: '24px 16px',
        marginBottom: '8px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            backgroundColor: '#f3f3f3',
            padding: 0,
        },
    },
    datePicker: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-around',
        alignSelf: 'center',
        marginTop: '20px',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'unset',
        },
    },
    monthName: {
        margin: '0 20px 0 10px !important',
        fontWeight: '600',
        fontSize: '18px',
        [theme.breakpoints.down('sm')]: {
            margin: '5px 10px !important',
        },
    },
    datePickerSections: {
        margin: 'auto',
        width: '100%',
        overflowY: 'auto',
    },
    openingsText: {
        fontSize: '13px',
        margin: '10px 5px',
    },
    bottomContainer: {
        padding: '0px',
        [theme.breakpoints.down('sm')]: { padding: '0px' },
    },
    fullDate: {
        fontSize: '18px',
        fontWeight: '600',
        margin: '0px 0px 0px 16px !important',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            whiteSpace: 'nowrap',
            margin: '0px 0px 0px 0px !important',
            paddingBottom: '10px !important',
            fontSize: '16px',
            fontWeight: '500',
            textAlign: 'center'
        },
    },
    showCalendarAction: {
        display: 'flex',
        color: '#42413D',
        textTransform: 'none',
        [theme.breakpoints.down('sm')]: {
            whiteSpace: 'nowrap',
            paddingRight: 0,
        },
    },
    differentServiceCard: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 20,
        [theme.breakpoints.down('sm')]: {
            marginTop: 20,
        },
    },
});

const DateTime = ({
    classes,
    getSelectedCalendarInfo,
    setSlot,
    goToNextPage,
    selectedSlot,
    availableDates,
    actionTriggerToSetDateTime,
    hasLocationId,
    selectedServices,
    selectedServicesObj,
    actionTriggerToSetEmployeeId,
    actionTriggerToSetRoomId,
    guests,
    dateTime,
    buyoutLocation,
    buyout,
    extensionAddon,
    extensions,
    setExtensionAddon,
    selectedLocation,
    serviceTime,
}) => {
    const weekStartDate = availableDates?.[0] ? getDateWithoutTimezone(new Date(availableDates[0])) : getDateWithoutTimezone(new Date());
    const today = getDateWithoutTimezone(new Date());

    const seldate = getSelectedCalendarInfo || weekStartDate;

    const [selectedDate, setSelectedDate] = useState(new Date(seldate) >= new Date(today) ? seldate : today);
    const [showCalendar, setShowCalendar] = useState(false);
    const [buyoutAvailableDates, setBuyoutAvailableDates] = useState([]);

    useEffect(async () => {
        if (extensions && !extensionAddon && hasLocationId) {
            const { data, error } = await restClient.post(getServices(hasLocationId));
            if (data.IsSuccess) {
                const extensionData = data?.Treatments.filter((s) => s.Name === 'Extensions');
                if (extensionData.length) {
                    setExtensionAddon(extensionData[0]);
                }
            }
        }
    }, [extensionAddon, extensions, hasLocationId]);

    const guestNames = [];

    for (let i = 0; i < (guests || 0) + 1; i += 1) {
        if (i === 0) {
            guestNames.push('Me');
        } else {
            guestNames.push(`Guest ${i}`);
        }
    }

    const handleSelectSlot = async (slot) => {
        const updatedSlot = guestNames.reduce((obj, cur) => {
            obj[cur] = slot;
            return obj;
        }, {});

        if (dateTime == '') {
            actionTriggerToSetDateTime(selectedDate);
        }

        let availableTime = true;

        if (Object.keys(updatedSlot).length > guests) {
            availableTime = await getTimeSlots(updatedSlot);
        }

        if (availableTime) {
            setSlot(updatedSlot);
        } else {
            toast('This time is not available. Please select another.');
        }
    };

    const getTimeSlots = async (updatedSlot) => {
        const guestNames = Object.keys(updatedSlot);

        let extensionsGuestNames = [];

        if (extensionAddon) {
            if (extensions === true) {
                extensionsGuestNames = guestNames;
            } else if (extensions) {
                extensionsGuestNames = guestNames.filter((user) => extensions[user]);
            }
        }

        const usedEmployees = {};

        let requests = guestNames.reduce((list, user) => {
            const employeeIds = (updatedSlot[user].availabilityItems || []).filter(
                (item) => item.serviceId === selectedServicesObj[user].ID && !Object.keys(usedEmployees).map((user) => usedEmployees[user].service).includes(item.employeeId),
            );
            const selectedEmployeeId = employeeIds.length ? employeeIds[0].employeeId : null;
            list.push(
                restClient.post(getBookerTimeSlot({
                    startTime: updatedSlot[user].startDateTime,
                    locationId: hasLocationId,
                    serviceId: selectedServicesObj[user].ID,
                    employeeId: selectedEmployeeId,
                })),
            );

            if (selectedEmployeeId || !updatedSlot[user].availabilityItems) {
                usedEmployees[user] = {
                    service: selectedEmployeeId,
                    extension: null,
                };
            }
            return list;
        }, []);

        if (Object.values(usedEmployees).length < guestNames.length) {
            return false;
        }

        requests = requests.concat(extensionsGuestNames.map((user) => {
            const endTime = getEndDate(updatedSlot[user].startDateTime, selectedServicesObj[user].TotalDuration);

            return restClient.post(getBookerTimeSlot({
                startTime: endTime,
                locationId: hasLocationId,
                serviceId: extensionAddon.ID,
                employeeId: usedEmployees[user] ? usedEmployees[user].service : null
            }));
        }));

        const data = await Promise.all(requests);

        const usedRooms = {};

        let isAvailable = true;

        data.filter((_, idx) => idx < guestNames.length).forEach((datum, idx) => {
            const user = guestNames[idx];
            const availableRooms = datum.data.rooms?.filter(
                (room) => (
                    room.available === 'Yes'
                    && !Object.keys(usedRooms).reduce(
                        (list, cur) => {
                            if (usedRooms[cur].service) list.push(usedRooms[cur].service);
                            if (usedRooms[cur].extension) list.push(usedRooms[cur].extension);
                            return list;
                        }, [],
                    ).includes(room.roomId)
                ),
            ).map((r) => r.roomId);
            if (!usedEmployees[user].service) {
                const employee = datum.data.employees?.find((employee) => employee.available === 'Yes');
                if (employee) {
                    usedEmployees[user].service = employee.employeeId;
                }
            }
            const extensionGuestIdx = extensionsGuestNames.indexOf(user);

            if (extensionGuestIdx >= 0 && data[guestNames.length + extensionGuestIdx]) {
                let extensionAvailableRooms = [];
                let extensionAvailableEmployees = [];

                const extensionData = data[guestNames.length + extensionGuestIdx].data;

                extensionAvailableEmployees = extensionData.employees?.filter(
                    (empID) => empID.available === 'Yes',
                ).sort((a, b) => a.employeeId - b.employeeId).map((a) => a.employeeId);

                if (extensionAvailableEmployees.includes(usedEmployees[user].service)) {
                    usedEmployees[user].extension = usedEmployees[user].service;
                } else {
                    const notUsedEmployees = extensionAvailableEmployees.filter(
                        (employeeId) => !Object.keys(usedEmployees).reduce((list, c) => {
                            list.push(c.service);

                            if (c.extension) {
                                list.push(c.extension);
                            }
                            return list;
                        }, []).includes(employeeId),
);

                    if (notUsedEmployees.length === 0) {
                        isAvailable = false;
                    } else {
                        usedEmployees[user].extension = notUsedEmployees[0];
                    }
                }

                extensionAvailableRooms = extensionData.rooms?.filter((room) => room.available === 'Yes').map((room) => room.roomId);
                const commonRooms = extensionAvailableRooms.filter((roomId) => availableRooms.includes(roomId));
                if (commonRooms.length) {
                    usedRooms[user] = {
                        service: commonRooms[0],
                        extension: commonRooms[0],
                    };
                } else {
                    const notUsedRooms = extensionAvailableRooms.filter(
                        (roomId) => !Object.keys(usedRooms).reduce((list, c) => {
                            if (c.service) {
                                list.push(c.service);
                            }
                            if (c.extension) {
                                list.push(c.extension);
                            }
                            return list;
                        }, []).includes(roomId),
);
                    usedRooms[user] = {
                        service: availableRooms[0],
                    };
                    if (notUsedRooms.length) {
                        usedRooms[user].extension = notUsedRooms[0];
                    } else {
                        isAvailable = false;
                    }
                }
            } else if (availableRooms.length) {
                usedRooms[user] = {
                    service: availableRooms[0],
                    extension: null,
                };
            } else {
                isAvailable = false;
            }
        });

        if (!isAvailable) {
            return false;
        }

        actionTriggerToSetEmployeeId(usedEmployees);
        actionTriggerToSetRoomId(usedRooms);
        goToNextPage();
        return true;
    };

    const onDateSelect = (date) => {
        setSelectedDate(date);
        actionTriggerToSetDateTime(date);
        setShowCalendar(false);
        setSlot({});
    };

    return (
        <>
            <Grid className={classes.topContainer}>
                <Media query={{ maxWidth: 599 }}>
                    {(matches) => (matches ? (
                        <Typography className={classes.heading}>
                            Plan for
                            {' '}
                            <strong>
                                {serviceTime || 50}
                                {' '}
                                mins
                            </strong>
                            {' '}
                            for your service
                            {' '}
                        </Typography>
                    ) : (
                        <Typography className={classes.heading}>
                            Plan for
                            {' '}
                            <strong>
                                {serviceTime || 50}
                                {' '}
                                mins
                            </strong>
                            {' '}
                            for your service
                        </Typography>
                        )
                    )}
                </Media>
                <Grid className={classes.datePickerContainer}>
                    <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography className={classes.monthName}>
                            {monthNames[getMonth(selectedDate)]}
                        </Typography>
                        <Button onClick={() => setShowCalendar(true)} variant="container" className={classes.showCalendarAction}>
                            <Typography style={{ borderBottom: '0.5px solid #42413D', fontSize: '13px', height: '20px' }}>
                                Show Calendar
                            </Typography>
                            <CalendarTodayIcon style={{ height: '13px' }} />
                        </Button>
                    </Grid>
                    <Grid className={classes.datePickerSections}>
                        <Grid className={classes.datePicker}>
                            {getWeekByFirstDate(selectedDate).map((date, index) => (
                                <DateCard
                                    date={date}
                                    disabled={moment(today).format('YYYY-MM-DD') > moment(date).format('YYYY-MM-DD')}
                                    selectedDate={selectedDate}
                                    onDateSelect={onDateSelect}
                                    key={`dateCard_${index}`}
                                />
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid>
                    <Typography className={classes.fullDate}>
                        {getDateStringMMDDYY(selectedDate)}
                    </Typography>
                </Grid>
            </Grid>

            <Grid className={classes.bottomContainer}>
                {/* {availableDates.some((d) => getDateWithFirstTime(d) === getDateWithFirstTime(selectedDate)) ? ( */}
                <SlotsContainer
                    handleSelectSlot={handleSelectSlot}
                    selectedSlot={selectedSlot}
                    selectedServices={selectedServices}
                    selectedDate={selectedDate}
                    hasLocationId={hasLocationId}
                    guests={guests}
                    buyout={buyout}
                    buyoutLocation={buyoutLocation}
                    selectedLocation={selectedLocation}
                />
                {/* ) : null} */}
            </Grid>
            <Calendar
                selectedDate={selectedDate}
                setSelectedDate={onDateSelect}
                open={showCalendar}
                onClose={() => setShowCalendar(false)}
                onClick={onDateSelect}
                hasLocationId={hasLocationId}
                availableDateTimes={buyoutAvailableDates}
            />
            <ToastContainer hideProgressBar />
        </>
    );
};

DateTime.propTypes = {
    classes: object.isRequired,
    getSelectedCalendarInfo: PropTypes.string,
};

DateTime.defaultProps = {
    getSelectedCalendarInfo: '',
};

const mapStateToProps = (state) => ({
    getSelectedCalendarInfo: getSelectedDateTime(state),
    selectedSlot: getSelectedSlot(state),
    selectedLocation: getLocationData(state),
    selectedServices: getServicesData(state),
    selectedServicesObj: getServicesDataAsObject(state),
    guests: getNumberOfGuests(state),
    isGuestWithDifferentServices: isGuestWithDifferentServices(state),
    dateTime: getSelectedDate(state),
    extensions: getExtensions(state),
    extensionAddon: getExtensionAddon(state),
    serviceTime: getServiceTime(state),
});

const mapDispatchToProps = (dispatch) => ({
    setSlot: bindActionCreators(setSlotTime, dispatch),
    actionTriggerToSetDateTime: bindActionCreators(setDateTimeClient, dispatch),
    actionTriggerToSetEmployeeId: bindActionCreators(availEmployeeBookingSlots, dispatch),
    actionTriggerToSetRoomId: bindActionCreators(availRoomBookingSlot, dispatch),
    setExtensionAddon: bindActionCreators(setExtensionAddon, dispatch),
});

const enhance = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
);

export default enhance(DateTime);
