import { getPreviewMode } from '../../state/utils/contentful';

const resyTopLevelQuery = (slug = 'resy') => (
    `{
      ressiCollection(${getPreviewMode()}, where: {slug: "${slug}"}) {
        items {
          metadata{
            sys {
              id
            }
          }
          title
          slug
          description {
            json
          }
          locations: settings
          marketingComponentsCollection {
            items {
              __typename
              ... on MarketingCard {
                internalName
                sys {
                  id
                }
              }
            }
          }
        }
      }
    }
    `
);

const resySecondLevelQuery = (marketingSectionId) => (
    `{
        marketingCard(${getPreviewMode()}, id: "${marketingSectionId}") {
          title
          image {
            desktopMedia {
              url
            }
          }          
          settings
        }
    }
    `
);

export {
    resyTopLevelQuery,
    resySecondLevelQuery,
};
