import { getPreviewMode, getScreenCollectionPreviewMode } from '../../state/utils/contentful';

const storeByLocationId = (locationId) => `{
    storeCollection(${getScreenCollectionPreviewMode()}, where: {bookerLocationId: ${locationId}}) {
        items {
          title
          number
          bookerLocationId
          type
          information
          contact
          slug
          mapImage {
            url
          } 
          settings
          arrivalInformation
          storefrontImage {
            internalName
            title
            alternateTitle
            caption
            desktopMedia {
              title
              description
              contentType
              fileName
              size
              url
              width
              height
            }
            mobileMedia {
              title
              description
              contentType
              fileName
              size
              url
              width
              height
            }
          }
        }
      }    
    }`;

  export default storeByLocationId;
