/* eslint-disable no-console */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { object } from 'prop-types';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import { useAuth } from 'oidc-react';
import appConfig from '../../../../../app.config';
import { withOktaAuth } from '@okta/okta-react';
import {
    Button, Grid,
    Typography, TextField,
    Backdrop, CircularProgress
} from '@material-ui/core';
import {resendVerificationEmail} from '../../../../../api/auth-api';
import { setRedirectUrl } from 'state/ducks/Auth/Auth-Actions';
import { getRedirectUrl } from 'state/ducks/Auth/Auth-Selectors';
import SectionTitle from '../../../SectionTitle';
import TextInputField from '../TextInputField'
import { googleAnalyticsEvents } from 'state/utils/googleAnalyticsEvents';
import restClient from '../../../../../api/restClient';

const LogIn = (props) => {
    const {
        classes, oktaAuth, authState, buyout, location, setRedirectUrl, redirectUrl,
    } = props;
    const params = queryString.parse(location.search);

    const [email, setEmailValue] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [showLoading, setLoading] = useState(false);
    const auth = useAuth();

    useEffect(() => {
        if (params && params.redirect && params.redirect !== '') {
            setRedirectUrl(params.redirect);
        }
    }, [params]);

    const handleEmailChange = (e) => {
        const newError = { ...errors };
        delete newError.email;
        setErrors(newError);
        setEmailValue(e.target.value);
    };

    const handlePasswordChange = (e) => {
        const newError = { ...errors };
        delete newError.password;
        setErrors(newError);
        setPassword(e.target.value);
    };

    const displayPlaceholder = (e) => {
        e.target.placeholder = `Your ${e.target.name} here...`;
    };

    const hidePlaceholder = (e) =>{
        e.target.placeholder = "";
    }

    const validateEmail = (value) => {
        if (/^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9]{2,}(?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i
            .test(value)) return true;
        return false;
    };

    const handleSubmit = async (e) => {

        e.preventDefault();
        const newError = { ...errors };
        delete newError.signin;
        if (!password?.length) {
            newError.password = 'Error: Password is required';
        } else {
            delete newError.password;
        }
        if (!email?.length) {
            newError.email = 'Error: Email is required';
        } else if (!validateEmail(email)) {
            newError.email = 'Please enter a valid email address'
        } else {
            delete newError.email;
        }
        if (!Object.keys(newError).length) {
            setLoading(true);

            // remove 'oidc' keys before we attempt a login
            var removeKeys = [];

             for (const key in localStorage) {
                if (key.indexOf('oidc') > -1) {
                    removeKeys.push(key);
                }
            }

            for (var i = 0; i < removeKeys.length; i++) {          
                localStorage.removeItem(removeKeys[i]);                
            }

            // remove the 'idserv' auth cookies
            document.cookie = 'idsrv.session' + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
            document.cookie = 'idsrv' + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';

            // now attempt to signin
            axios.post(appConfig.mokta.sign_in_uri, {
                Username: email, Password: password
            }, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    'Accept': 'application/json',
                    'content-Type': 'application/json'
                },
                withCredentials: true
            }).then((response) => {
                if (response) {
                    if(auth.userManager){
                        auth.userManager.removeUser()
                        .then(() => {
                            auth.userManager.signinRedirect().then(() => setLoading(false));
                            gtag(googleAnalyticsEvents.EVENT, googleAnalyticsEvents.LOGIN, {
                                method: 'web'
                            });

                            if (window.dtrum) {
                                let actionId = window.dtrum.enterAction('UserLogin');
                                window.dtrum.actionName('UserLoggedIn', actionId);
                                window.dtrum.addActionProperties(actionId, null, null, { "userlogin": "User Login to drybar", "public": "true" }, null);
                                window.dtrum.leaveAction(actionId);
                            }
                            else { console.log('Warning: DT logging not connected'); }
                        })
                        .catch(e => {
                        }) 
                    }
                }
            })
            .catch(async (err) => {
                
                    setLoading(false);
                    
                    if(err.response?.status == 400){
                        if(err.response?.data 
                            && err.response?.data.custom_error 
                            && err.response?.data.custom_error.error_code 
                            && err.response?.data.custom_error.error_code === 412){
                                const res = await restClient.post(resendVerificationEmail(email));
                                if (res.status === 202) {
                                    setErrors({ signin: 'This email has not been verified. We have sent you another verification link. Please check your email.' });
                                } else {
                                    setErrors({ signin: 'This email has not been verified. We tried to send you a verification link but something went wrong. Please try again later.' });
                                }        
                        } else {
                            setErrors({ signin: err.response?.status == 400 ? 'Email and/or password is incorrect' : 'Something went wrong, please try again later' });
                        }  
                    }   

                });
        } else {
            setErrors(newError);
        }
    }; 

    /*if (authState.isPending) {
        //return null;
    }
    if (authState.isAuthenticated) {
        // history.push('/');
    }*/

    return (
        <>
            <Grid container className={classes.requestServiceContainer}>
                <SectionTitle title="LOG IN TO DRYBAR" />
                <Grid className={classes.requestNoteDetails}>
                    <Grid className={classes.requestContainer}>
                        <form onSubmit={handleSubmit} className={classes.subscribeEmailForm}>
                            <Grid>
                                <Grid className={classes.fieldsContainer}>
                                    <Typography className={classes.enterEmailPasswordCopy}>Email</Typography>
                                    <TextField
                                        classes={{
                                            input: classes.input,
                                        }}
                                        id="standard-adornment-amount"
                                        error={!!errors.email}
                                        helperText={errors.email ? errors.email : undefined}
                                        value={email}
                                        onChange={(e) => handleEmailChange(e)}
                                        startAdornment=""
                                        onFocus={displayPlaceholder}
                                        onBlur={hidePlaceholder}
                                        name="email"
                                        helperText={errors.email ? errors.email : ''}
                                        className={classes.enterYourEmail}
                                    />
                                </Grid>
                                <Grid className={classes.enterEmailPassword}>
                                    <Typography className={classes.enterEmailPasswordCopy}>Password</Typography>
                                    <TextInputField
                                        classes={{
                                            input: classes.input,
                                        }}
                                        id="standard-adornment-amount"
                                        value={password}
                                        onChange={(e) => handlePasswordChange(e)}
                                        type='password'
                                        onFocus={displayPlaceholder}
                                        onBlur={hidePlaceholder}
                                        name="password"
                                        className={classes.enterYourEmail}
                                        error={errors.password}
                                        helperText={errors.password ? errors.password : ''}
                                    />

                                </Grid>
                            </Grid>

                            <Button type="submit" className={classes.authOperation} variant="outlined">
                                Log In
                            </Button>

                        </form>
                        {!buyout && (<Link to="/auth/sign-up"><Typography className={classes.recoverPassword}>Sign Up</Typography></Link>)}
                        <Link to="/auth/recover-password"><Typography className={classes.recoverPassword}>Recover Password</Typography></Link>
                        {errors.signin ? (
                            <Typography color="error">
                                {`Error: ${errors.signin}`}
                            </Typography>
                        ) : null}
                    </Grid>
                </Grid>
                {showLoading ? (
                    <Backdrop className={classes.backdrop} open>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                ) : null}
            </Grid>
            {/* <LoginGoogleFacebook handleSocialLogin={handleSocialLogin} classes={classes} /> */}
        </>
    );
};

LogIn.prototype = {
    classes: object.isRequired,
};

const mapStateToProps = (state) => ({
    redirectUrl: getRedirectUrl(state),
});

const mapDispatchToProps = (dispatch) => ({
    setRedirectUrl: bindActionCreators(setRedirectUrl, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withOktaAuth(LogIn)));
