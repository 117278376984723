import dayjs from 'dayjs';

export const dateTimeRegex = /^\d\d\d\d-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])T(00|[0,1][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])/g;

export const dateRegex = /^\d\d\d\d-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])/g;

/* eslint-disable max-len */
export const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December',
];

export const monthShortNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
];

export const getDateTimeString = (d) => {
    if (d instanceof Date) {
        return dayjs(d).format('YYYY-MM-DDTHH:mm:ss');
    }
    if (typeof d === 'string') {
        const datestr = d.match(dateTimeRegex);
        if (datestr) return datestr[0];
    }
};

export const getTimezoneString = (d) => {
    const res = d instanceof Date ? dayjs(d).format('YYYY-MM-DDTHH:mm:ssZ') : d;
    return res.replace(getDateTimeString(d), '');
};

export const getDateWithoutTimezone = (d) => dayjs(d).format('YYYY-MM-DDTHH:mm:ss');

export const getDateWithTimezone = (d) => dayjs(d).format('YYYY-MM-DDTHH:mm:ssZ');

export const getDateString = (d) => {
    if (d instanceof Date) {
        return dayjs(d).format('YYYY-MM-DD');
    }

    const datestr = d.match(dateRegex);
    return datestr[0];
};

export const extractDataMonthYearTime = (d) => {
    const date = new Date(getDateTimeString(d));
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
    ];

    // Extract 12 hour format time
    let minutes = date.getMinutes();
    let hour = date.getHours();
    const ampm = hour >= 12 ? 'pm' : 'am';
    hour %= 12;
    hour = hour || 12;
    minutes = minutes < 10 ? `0${minutes}` : minutes;

    const month = monthNames[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    const time = `${hour}:${minutes}${ampm}`;

    return {
        day, month, year, time,
    };
};

export const getDateStringMMDDYY = (dateObj) => {
    const month = monthNames[getMonth(dateObj)];
    const date = getDate(dateObj);
    const year = getFullYear(dateObj);

    return `${date} ${month} ${year}`;
};

export const getDateDDmm = (dateObj) => {
    const month = monthShortNames[getMonth(dateObj)];
    const date = getDate(dateObj);
    const ordinalInd = getOrdinalIndicator(dateObj);

    return `${date}${ordinalInd} ${month}`;
};

export const getOrdinalIndicator = (dateObj) => {
    const date = getDate(dateObj);

    var oi = 'th';
    if (date.toString().endsWith('1')) {
        oi = 'st';
    }
    else if(date.toString().endsWith('2'))
    {
        oi = 'nd';
    }

    return oi;
};

export const getNumberOfDaysBetween = (dateObj) => {
    const currDate = new Date();
    const futureDate = dateObj;
    return Math.ceil((futureDate - currDate) / (1000 * 60 * 60 * 24));
};

export const getGreeting = () => {
    const currTime = new Date(Date.now());
    const hour = currTime.getHours();
    let greeting = 'morning';
    if (hour > 11) {
        greeting = 'afternoon';
    } else if (hour > 17) {
        greeting = 'evening';
    } else {
        greeting = 'morning';
    }
    return greeting;
};

export const formatAMPM = (date) => {
    let hours = getHours(date);
    let minutes = getMinutes(date);
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours %= 12;
    hours = hours || 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    const strTime = `${hours}:${minutes}${ampm}`;
    return strTime;
};

export const getDateWithFirstTime = (d) => {
    const dateTimeStr = getDateTimeString(d);
    return dayjs(dateTimeStr || d).format('YYYY-MM-DDT00:00:00');
};

export const getPrevMonthDate = (d) => {
    const date = dayjs(d).toDate();
    date.setMonth(date.getMonth() - 1);

    return dayjs(date).format('YYYY-MM-DD');
};

export const getNextMonthDate = (d) => {
    const date = dayjs(d).toDate();
    date.setMonth(date.getMonth() + 1);

    return dayjs(date).format('YYYY-MM-DD');
};

export const getMonth = (d) => dayjs(d).toDate().getMonth();

export const getTime = (d) => dayjs(d).toDate().getTime();

export const getWeekDay = (d) => dayjs(d).toDate().toLocaleString('en-GB', { weekday: 'short' });

export const getDate = (d) => dayjs(d).toDate().getDate();

export const getHours = (d) => dayjs(d).toDate().getHours();

export const getMinutes = (d) => dayjs(d).toDate().getMinutes();

export const getFullYear = (d) => dayjs(d).toDate().getFullYear();

export const getWeekByFirstDate = (d) => {
    const curr = new Date(d);
    const firstDayOfWeek = new Date(curr.setTime(curr.getTime() - curr.getDay() * 24 * 60 * 60 * 1000));

    const week = [getDateWithFirstTime(firstDayOfWeek)];

    for (let i = 1; i < 7; i++) {
        const date = new Date(firstDayOfWeek);
        date.setTime(date.getTime() + i * 24 * 60 * 60 * 1000);
        week.push(getDateWithFirstTime(date));
    }
    return week;
};

export const getMonthFirtDate = (d) => dayjs(d).format('YYYY-MM-01T00:00:00');

function weekCount(year, month) {
    const firstOfMonth = new Date(year, month - 1, 1);
    const lastOfMonth = new Date(year, month, 0);

    const used = firstOfMonth.getDay() + lastOfMonth.getDate();
    return Math.ceil(used / 7);
}

// too complex to habdke --- fix this
export const getMonthDates = (d) => {
    const month = [];
    const date = dayjs(d || new Date()).toDate();
    const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const isFirstDayOfMonthFirstDayOfWeek = getWeekByFirstDate(new Date(date)?.setDate(1))[0] === getMonthFirtDate(d);
    const lastCount = weekCount(lastDayOfMonth?.getFullYear(), lastDayOfMonth?.getMonth() + 1);
    const weekCountIndex = isFirstDayOfMonthFirstDayOfWeek ? lastCount + 1 : lastCount;
    for (let i = 0; i < weekCountIndex; i += 1) {
        let newWeek = [];
        if (i === 0 && !isFirstDayOfMonthFirstDayOfWeek) {
            newWeek = getWeekByFirstDate(new Date(date).setDate(1));
        } else if (i !== 0) {
            newWeek = getWeekByFirstDate(new Date(date).setDate(i * 7));
        }
        month.push(newWeek);
    }

    return month;
};

export const getTimeFromDate = (d) => {
    const datestr = getDateTimeString(d);
    if (datestr) return formatAMPM(new Date(datestr));
};

export const splitTimesByDuration = (startTime, endTime, duration) => {
    const s = dayjs(getDateTimeString(startTime));
    const e = dayjs(getDateTimeString(endTime));
    const timezone = getTimezoneString(startTime);

    let i = s;
    const res = [startTime];

    while (i < e) {
        i = i.add(duration, 'minutes');
        res.push(`${i.format('YYYY-MM-DDTHH:mm:ss')}${timezone}`);
    }

    return res;
};

export const formatDate = (d) => dayjs(d).format('YYYY-MM-DD');

export const getEndDate = (startDate, duration) => {
    const datestr = getDateTimeString(startDate);
    if (datestr) {
        const enddateobj = new Date(datestr);
        enddateobj.setTime(enddateobj.getTime() + duration * 1000 * 60);
        let enddate = dayjs(enddateobj);
        enddate = enddate.format('YYYY-MM-DDTHH:mm:ss');
        enddate = startDate.replace(dateTimeRegex, enddate);
        return enddate;
    }
    // else {
    //     return dayjs(startDate).add(duration, 'minutes').format('YYYY-MM-DDTHH:mm:ssZ')
    // }
};

export default {};
