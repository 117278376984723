/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useQuery } from '@apollo/client';
import './contact.scss';
import {
    Typography, Grid, TextField, Button, CircularProgress, Backdrop, Link,
    withStyles,
} from '@material-ui/core';

import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import { MOBILE_BREAKPOINT, TABLET_BREAKPPOINT } from '../../Helpers/breakpoints';
import screenContactCollection from '../../gql/queries/marketingCollection';
import appConfig from '../../app.config';

import CommonModal from '../../app/Components/Modal/CommonModal';
import { getMetaData } from '../../state/utils/contentful';
import { MetaTagWrapper } from '../../components';
import { EMAIL_REGEX, NAME_REGEX, PHONE_REGEX } from '../../utils/validation/regex';
import { doQuery } from '../../state/utils/contentful';

const styles = (theme) => ({
    bannerContainer: {
        background: '#FFFFFF',
        width: '100%',
        height: '390px',
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down(TABLET_BREAKPPOINT + 1)]: {
            height: '300px',
        },
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            height: '229px',
        },
    },
    banner: {
        width: '1549px',
        [theme.breakpoints.down(TABLET_BREAKPPOINT + 1)]: {
            width: 'auto',
            height: '300px',
        },
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            width: 'auto',
            height: '229px',
        },
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    contactCotainer: {
        justifyContent: 'center',
        width: '1367px',
        margin: '0 auto !important',
        padding: 36,
        [theme.breakpoints.down(1367)]: {
            width: '100%',
        },
        [theme.breakpoints.down(TABLET_BREAKPPOINT)]: {
            display: 'block',
        },
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            padding: '10px 0',
        },
    },
    content: {
        padding: 15,
    },
    blockItem: {
        boxShadow: '2px 2px 46px rgba(235, 235, 235, 0.5)',
        backgroundColor: 'white',
        padding: '27px 36px',
        marginBottom: 30,
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            padding: 10,
            backgroundColor: 'transparent',
            boxShadow: 'none',
        },
    },
    inputItem: {
        marginBottom: 29,
        '& label': {
            fontFamily: 'URWForm',
            color: '#767676',
            fontSize: 18,
            lineHeight: '25px',
        },
        '& .MuiInput-formControl': {
            marginTop: 30,
        },
        '& textarea': {
            width: '100%',
            border: '1px solid #d1d1d1',
            outline: 'none',
            padding: '32px 26px',
            minHeight: 285,
        },
        '& p.error-msg': {
            color: '#FF0000',
        },
    },
    mr0: {
        marginBottom: 0,
    },
    blockTitle: {
        fontFamily: 'URWForm',
        fontWeight: 600,
        fontSize: 18,
        lineHeight: '45px',
        color: '#42413D',
    },
    flexItem: {
        display: 'flex',
        alignItems: 'center',
    },
    socialMedia: {
        width: '100%',
    },
    socialMediaItem: {
        width: '48%',
        height: 161,
        background: '#F7F8F9',
        borderTop: '1px solid #D1D1D1',
        justifyContent: 'center',

        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            flexDirection: 'column',
            backgroundColor: 'white',
            border: 'none',
            boxShadow: '2px 2px 46px rgba(235, 235, 235, 0.5)',

            '& p': {
                marginLeft: '0px !important',
            },
        },

        '&:first-child': {
            marginRight: '2%',
        },

        '&:last-child': {
            marginLeft: '2%',
        },

        '& p': {
            marginLeft: 15,
        },
    },
    phoneItem: {
        background: '#F7F8F9',
        borderTop: '1px solid #D1D1D1',
        padding: '17px 21px',
        marginBottom: 14,

        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            backgroundColor: 'white',
            border: 'none',
            boxShadow: '2px 2px 46px rgba(235, 235, 235, 0.5)',
        },

        '& p.title': {
            color: '#767676',
        },

        '& div': {
            justifyContent: 'space-between',
        },
    },
    width100: {
        width: '100%',
    },
    width60: {
        width: '60%',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            width: '100%',
            marginBottom: 20,
        },
    },
    width35: {
        width: '35%',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            width: '100%',
        },
    },
    justifyContent: {
        justifyContent: 'space-between',
    },
    inputGroup: {
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            padding: 18,
            boxShadow: '2px 2px 46px rgba(235, 235, 235, 0.5)',
            backgroundColor: 'white',
            marginBottom: 19,
        },
    },
    rowReverse: {
        [theme.breakpoints.up(960)]: {
            alignItems: 'flex-end',
        },
        [theme.breakpoints.down(960)]: {
            flexDirection: 'column-reverse',
        },
    },
    sectionTitle: {
        textAlign: 'center',
        fontFamily: 'DINCondensed-Regular',
        fontWeight: 'bold',
        fontSize: 42,
        lineHeight: '50px',
        color: '#42413D',
    },
    selected: {
        backgroundColor: theme.palette.primary.main,
        fontWeight: '800',
        '&:hover': {
            backgroundColor: theme.palette.common.hover[1],
        },
        height: '55px',
        margin: '0 20px',
        borderRadius: '0px',
        textTransform: 'capitalize',
        fontSize: '18px',
        width: 'calc(100% - 40px)',
        [theme.breakpoints.down(TABLET_BREAKPPOINT + 1)]: {
            margin: '20px 10px 0',
            width: 'calc(100% - 20px)',
        },
        marginTop: 20,
    },
    border: {
        margin: '36px 0',
        borderBottom: '3px dashed #BDBDBD',
    },
    giftcard: {
        width: '100%',
        border: '3px solid #BDBDBD',
        height: 400,
        minHeight: 400,
        position: 'relative',
        background: 'linear-gradient(270deg, #FEFEFE 63.64%, rgba(254, 254, 254, 0) 93.64%)',

        [theme.breakpoints.down(600)]: {
            height: 200,
            minHeight: 200,
        },

        '& .main-part': {
            width: '80%',
            display: 'flex',
            alignItems: 'center',
            height: '100%',

            [theme.breakpoints.down(600)]: {
                width: '100%',
                objectFit: 'contain',
            },
        },

        '& img.right-side': {
            position: 'absolute',
            top: 0,
            right: 54,
            height: '100%',

            [theme.breakpoints.down(1005)]: {
                right: '10px !important',
            },

            [theme.breakpoints.down(600)]: {
                width: 122,
                objectFit: 'contain',
                right: '0 !important',
            },
        },
    },
    shoppingPart: {
        textAlign: 'center',
        width: '40%',
        borderRight: '1px solid #BDBDBD',
        padding: '30px 50px',

        [theme.breakpoints.down(1005)]: {
            padding: '30px 20px !important',
        },

        [theme.breakpoints.down(768)]: {
            width: '70%',
            border: 'none',
        },

        [theme.breakpoints.down(600)]: {
            '& img': {
                width: 76,
            },
        },

        '& p': {
            color: '#636263',
            fontSize: 18,
            fontWeight: 'bold',
            margin: '20px 0',

            [theme.breakpoints.down(600)]: {
                margin: '10px 0',
                fontSize: 15,
            },
        },

        '& button': {
            border: '1px solid #42413D',
            background: 'white',
            width: 175,
            padding: 15,
            cursor: 'pointer',
            outline: 'none',

            [theme.breakpoints.down(600)]: {
                padding: 7,
                width: 100,
            },
        },
    },
    giftCardDescription: {
        width: '50%',
        height: '100%',
        padding: '30px 50px',

        [theme.breakpoints.down(1005)]: {
            padding: '30px 20px !important',
        },

        [theme.breakpoints.between(901, 1100)]: {
            width: '45% !important',
        },

        [theme.breakpoints.between(769, 900)]: {
            width: '40% !important',
        },

        [theme.breakpoints.down(768)]: {
            display: 'none',
        },

        '& p': {
            fontSize: 30,
            lineHeight: '50px',
            fontStyle: 'italic',
            color: '#42413D',
            fontFamily: 'URWForm',

            [theme.breakpoints.between(901, 1100)]: {
                fontSize: 25,
                lineHeight: '40px',
            },

            [theme.breakpoints.down(900)]: {
                fontSize: 20,
                lineHeight: '40px',
            },

            [theme.breakpoints.between(769, 900)]: {
                fontSize: 18,
                lineHeight: '40px',
            },
        },
    },
});

const Contact = (props) => {
    const { classes } = props;
    const [metadata, setMetadata] = useState({});
    // const SCREEN_MARKETING_QUERY = screenContactCollection();
    // const { data, error, loading } = useQuery(SCREEN_MARKETING_QUERY);
    const [loading, setIsLoading] = useState(false);
    const [data, setData] = useState({});
    const [modalContent, setModalContent] = useState({});
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phoneNumber: '',
        message: '',
        preferredShop: null,
        reason: null
    });

    const [formDataError, setFormDataError] = useState({
        name: '',
        email: '',
        phoneNumber: '',
        message: '',
        preferredShop: '',
        reason:''
    });
    const [reasonChoices, setReasonChoices] = useState([]);
    const [preferredShopChoices, setPreferredShopChoices] = useState([]);

    useEffect(async () => {
        setIsLoading(true);

        const data = await doQuery(queryContactUs(), true);
        const pageData = data.drybarPageCollection?.items[0];

        setMetadata({
            pageDescription: pageData?.seoDescription,
            pageKeywords: [],
            pageTitle: pageData?.seoTitle,
            searchKeywords: [],
        });

        const firstLevelIds = pageData.formatofPageCollection?.items?.map(
            (item) => item.sys.id
        );

        let banner = {};

        if (firstLevelIds.length > 0) {
            banner = await doQuery(fetchBannerData(firstLevelIds[0]), true);
        }

        const links = pageData.formatofPageCollection?.items?.find(i => i.__typename === 'DrybarFeatureBox')

        const finalData = {
            banner: banner?.heroBanner?.mediaCollection?.items?.[0]
            ?.desktopImageUrl,
            facebook: links?.socialMediaHeading1,
            facebookUrl: links?.socialMediaUrl1,
            instagram: links?.socialMediaHeading2,
            instagramUrl: links?.socialMediaUrl2,  
            socialTitle: links?.title
        }

        setData(finalData);

        setIsLoading(false);
    }, []);

    useEffect(() => {
        axios.post(appConfig.apiUrl + '/forms/getForms', {
            subDomain: appConfig.wufoo.subDomain,
            formId: appConfig.wufoo.genericPartyFormId,
            key: btoa(`${appConfig.wufoo.apiKey }:password`),
        })
            .then((res) => {
                const { data } = res;
                const fields = data?.Fields || [];
                fields.forEach((field) => {
                    if (field.Title === 'Preferred Shop') {
                        setPreferredShopChoices(field.Choices.filter((choice) => !!choice.Label).map((choice) => choice.Label));
                    }
                });
            })
            .catch((error) => console.log('error in wufoo api ', error));

        setReasonChoices(["Shop Inquiry","Online Account Access Issue","Barfly Membership"])
    }, []);

    // if (error) {
    //     console.log('error ===>', error);
    // }

    if (loading) {
        return (
            <Backdrop style={{ zIndeX: 11, color: '#fff' }} open>
                <CircularProgress color="inherit" />
            </Backdrop>
);
    }

    const validateInput = (value, stateName) => {
        let fieldLabel = stateName;
        // Map field names to field labels for text output
        if (fieldLabel === 'phoneNumber') fieldLabel = 'phone number';
        if (fieldLabel === 'preferredShop') fieldLabel = 'preferred shop';
        // All fields are required
        if (!value) {
            setFormDataError((formDataError) => ({ ...formDataError, [stateName]: `Error: ${fieldLabel} can not be empty.` }));
            return false;
        }
        // Validate name format
        if (stateName === 'name') {
            if (!NAME_REGEX.test(value)) {
                setFormDataError((formDataError) => ({ ...formDataError, [stateName]: `Error: ${fieldLabel} is not a valid name.` }));
                return false;
            }
        }
        // Validate email format
        if (stateName === 'email') {
            if (!EMAIL_REGEX.test(value)) {
                setFormDataError((formDataError) => ({ ...formDataError, [stateName]: `Error: ${fieldLabel} is not a valid email address.` }));
                return false;
            }
        }
        // Validate phone format
        if (stateName === 'phoneNumber') {
            if (!PHONE_REGEX.test(value)) {
                setFormDataError((formDataError) => ({ ...formDataError, [stateName]: `Error: ${fieldLabel} is not a valid phone number.` }));
                return false;
            }
        }
        setFormDataError((formDataError) => ({ ...formDataError, [stateName]: '' }));
        return true;
    };

    const handleChange = (value, stateName) => {
        validateInput(value, stateName);
        setFormData({ ...formData, [stateName]: value });
    };

    const handleSubmit = () => {
        const result = Object.keys(formData).map((key) => validateInput(formData[key], key));

        const isInvalid = result.filter((r) => !r).length > 0;

        if (isInvalid) {
            return;
        }

        const postData = {};
        postData.Field1 = formData.name;
        postData.Field3 = formData.email;
        postData.Field4 = formData.phoneNumber;
        postData.Field5 = formData.message;
        postData.Field7 = formData.preferredShop;
        postData.Field8 = formData.reason;

        console.log(postData)

        axios.post(appConfig.apiUrl + '/forms/postForms', {
                subDomain: appConfig.wufoo.subDomain,
                formId: appConfig.wufoo.contactFormId,
                key: btoa(`${appConfig.wufoo.apiKey }:password`),
                formData: postData,
            })
            .then((res) => {
                setModalContent({
                    visible: true,
                    title: 'Thank you',
                    msg: 'Thank you for your request. Someone from the DryBar team will reach out to you within 48 hours.',
                    button: 'OK',
                });
                setFormData({
                    name: '',
                    email: '',
                    phoneNumber: '',
                    message: '',
                    preferredShop: '',
                    reason: ''
                });
            })
            .catch((error) => {
                console.log('error in wufoo api ', error);
                setModalContent({
                    visible: true,
                    title: 'Error',
                    msg: 'Something went wrong. Please try a few seconds later.',
                    button: 'OK',
                });
            });
    };

    if (data) {
        const { banner, facebook, facebookUrl, instagram, instagramUrl, socialTitle } = data;

        return (
            <Grid className="contact-container">
                <MetaTagWrapper metadata={metadata} />
                <Grid className={classes.bannerContainer}>
                    <img src={banner} className={classes.banner} alt="Contact Header" />
                </Grid>
                <Grid className={classes.contactCotainer}>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <Typography variant={'h1'} className={[classes.sectionTitle, 'd-none d-md-block']} style={{ opacity: 0 }}>CONTACT US</Typography>
                            <div className={classes.content}>
                                <div className={classes.blockItem}>
                                    <Typography className={classes.blockTitle}>{socialTitle}</Typography>
                                    <div className={`${classes.socialMedia} ${classes.flexItem}`}>
                                        <div className={`${classes.socialMediaItem} ${classes.flexItem}`}>
                                            <Link href={instagramUrl} target="_blank" className="social-link">
                                                <InstagramIcon />
                                                <Typography className={classes.blockTitle}>{instagram}</Typography>
                                            </Link>
                                        </div>
                                        <div className={`${classes.socialMediaItem} ${classes.flexItem}`}>
                                            <Link href={facebookUrl} target="_blank" className="social-link">
                                                <FacebookIcon />
                                                <Typography className={classes.blockTitle}>{facebook}</Typography>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className={classes.blockItem}>
                                    <Typography className={classes.blockTitle}>
                                        Need help? Call
                                        Us.
                                    </Typography>
                                    <div className={classes.phoneItem}>
                                        <p className="title">Drybar Services</p>
                                        <div className={classes.flexItem}>
                                            <Typography className={classes.blockTitle}>{phone1}</Typography>
                                            <PhoneAndroidIcon />
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant={'h1'} className={classes.sectionTitle}>CONTACT US</Typography>
                            <div className={classes.content}>
                                <div className={classes.blockItem}>
                                    <div className={classes.inputGroup}>
                                        <div className={classes.inputItem}>
                                            <TextField
                                                id="name"
                                                label="Name"
                                                type="text"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                placeholder={'Your name here...'}
                                                className={classes.width100}
                                                value={formData.name}
                                                onChange={(e) => handleChange(e.target.value, 'name')}
                                                helperText={formDataError.name}
                                                error={formDataError.name}
                                            />
                                        </div>
                                        <Grid
                                            container
                                            className={`${classes.inputItem} ${classes.flexItem} ${classes.justifyContent}`}
                                        >
                                            <TextField
                                                id="email"
                                                label="Email"
                                                type="email"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                placeholder={'Your email here...'}
                                                className={classes.width60}
                                                value={formData.email}
                                                onChange={(e) => handleChange(e.target.value, 'email')}
                                                helperText={formDataError.email}
                                                error={formDataError.email}
                                            />
                                            <TextField
                                                id="phone"
                                                label="Phone Number"
                                                type="text"
                                                placeholder="Your phone number here..."
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                className={classes.width35}
                                                value={formData.phoneNumber}
                                                onChange={(e) => handleChange(e.target.value, 'phoneNumber')}
                                                helperText={formDataError.phoneNumber}
                                                error={formDataError.phoneNumber}
                                            />
                                        </Grid>
                                        <div className={classes.inputItem}>
                                            <TextField
                                                id="preferred_shop"
                                                label="Preferred Shop"
                                                select
                                                SelectProps={{
                                                    native: true,
                                                }}
                                                placeholder="Select Preferred Shop"
                                                fullWidth
                                                value={formData.preferredShop}
                                                onChange={(e) => handleChange(e.target.value, 'preferredShop')}
                                                helperText={formDataError.preferredShop}
                                                error={formDataError.preferredShop}
                                            >
                                                <option>Select Preferred Shop</option>
                                                {
                                                    preferredShopChoices.map((s) => <option key={s} value={s}>{s}</option>)
                                                }
                                            </TextField>
                                        </div>
                                        <div className={classes.inputItem}>
                                            <TextField
                                                id="reason"
                                                label="Reason"
                                                select
                                                SelectProps={{
                                                    native: true,
                                                }}
                                                placeholder="Select Reason"
                                                fullWidth
                                                value={formData.reason}
                                                onChange={(e) => handleChange(e.target.value, 'reason')}
                                                helperText={formDataError.reason}
                                                error={formDataError.reason}
                                            >
                                                <option>Select Reason</option>
                                                {
                                                    reasonChoices.map((s) => <option key={s} value={s}>{s}</option>)
                                                }
                                            </TextField>
                                        </div>
                                    </div>
                                    <div className={`${classes.inputItem} ${classes.mr0}`}>
                                        <Typography className={classes.blockTitle}>YOUR MESSAGE</Typography>
                                        <textarea
                                            name="message"
                                            id="message"
                                            cols="30"
                                            rows="10"
                                            onChange={(e) => handleChange(e.target.value, 'message')}
                                            value={formData.message}
                                        />
                                        {formDataError.message && <p className="error-msg">{formDataError.message}</p>}
                                    </div>
                                    <Button
                                        onClick={handleSubmit}
                                        className={classes.selected}
                                        variant="outlined"
                                    >
                                        SEND MESSAGE
                                    </Button>
                                    {modalContent.visible
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    && <CommonModal {...modalContent} onClose={() => setModalContent({ visible: false })} />}
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
        return <div />;
};

export default withStyles(styles)(Contact);

const queryContactUs = () => (
    `{
        drybarPageCollection(preview: true, where: { slug: "contact-us" }) {
            items {
              title
              subtitle
              tcsAndPoliciesCopy
              seoTitle
              seoDescription
              canonicalUrl
              addANoindexTag
              slug
              formatofPageCollection {
                items {
                  __typename
                  ... on HeroBanner {
                    sys {
                      id
                    }
                  }
                  ... on DrybarFeatureBox {
                    sys { id }
                    title
                    socialMediaUrl1
                    socialMediaHeading1
                    socialMediaUrl2
                    socialMediaHeading2
                  }
                }
              }
            }
        }
    }
    `
  );

  const fetchBannerData = (id) => `
{
  heroBanner(id: "${id}", preview: true) {
    targetPageUrl
    slugPrefix
    mediaCollection {
      items {
              internalName
              desktopImageUrl
              mobileImageUrl
              videoUrl
            }
    }
  }
}
`;
