/* eslint-disable import/prefer-default-export */

import modalActionTypes from './Auth-ActionTypes';

export const setRedirectUrl = (url) => ({
    type: modalActionTypes.SET_REDIRECT_URL,
    payload: url,
});

export const setMoktaUser = (user) => ({
    type: modalActionTypes.SET_MOKTA_USER,
    payload: user,
});
