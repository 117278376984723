import React from 'react';
import { Typography, withStyles } from '@material-ui/core';

const styles = (theme) => ({
  title: {
    fontSize: theme.fontSize.xl,
    fontFamily: theme.typography.fontFamily[1],
    textTransform: 'uppercase',
    textAlign: 'center',
  },
});

const Title = ({ classes, className, children }) => (
    <Typography className={`${classes.title} ${className}`}>
        {children}
    </Typography>
);

export default withStyles(styles)(Title);
