/* eslint-disable no-console */
import React from 'react';
import '../../assets/css/style.scss';
import '../../assets/css/style-responsive.scss';
import './home.scss';

const LogoutCallback = () => {
    return (
        <div className="tab-pane fade show homepage active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
        </div>
    );
};


export default LogoutCallback;
