import React, {
 Fragment, useState, useEffect, useCallback,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import useMoktaAuth from 'utils/oktaAuth';
import { Button, makeStyles, TextField } from '@material-ui/core';
import dayjs from 'dayjs';
import restClient from 'api/restClient';
import { getServices } from 'api/booking-api';
import { getTimezoneString } from 'Helpers/dateTime';
import { loadBuyoutContentful } from '../../state/ducks/Buyout/Buyout-Actions';
import {
 overwriteServicesData, setBuyoutLocation, setDateTimeClient, setLocationData, setNumberOfGuests,
} from '../../state/ducks/Booking/Booking-Actions';
import {
getBanners, getHeadlines, getMetaData, getTitle, getDescription, isBuyoutLoaded, getLocations,
} from '../../state/ducks/Buyout/Buyout-Selectors';

import buyoutLoginLocation from '../../assets/images/buyout-login-location.png';
import stockUpImage from '../../assets/images/stock-up.png';

import LogInCard from '../../app/Components/Auth/LogInContainer';
import Registration from '../../app/Components/Auth/Partials/Registration/RegistrationUser';
import { DocumentToComponent, MetaTagWrapper, CustomImg } from '../../components';

import '../../assets/css/style.scss';
import '../../assets/css/style-responsive.scss';
import './buyout.scss';
import './buyout-responsive.scss';

const useStyles = makeStyles((theme) => ({
    bookingProcess: {
        fontSize: '18px',
        lineHeight: '45px',
        color: '54575A',
        textTransform: 'capitalize',
        background: '#ffdd30',
        height: '63px',
        width: '378px',
        borderRadius: '0px',
        margin: '70px auto',
        '&:hover': {
            backgroundColor: '#b29a21',
        },
    },
}));

const BuyOut = ({
    title,
    description,
    banners,
    headlines,
    setBookingLocation,
    overwriteServicesData,
    setNumberOfGuests,
    loadPageDataFromContentful,
    buyoutSettings,
    metadata,
    setDateTimeClient,
    setBuyoutLocation,
 }) => {
    const classes = useStyles();
    const history = useHistory();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isAuthenticated, getUser] = useMoktaAuth();

    const [renderData, setRenderData] = useState(null);
    const [buyoutServices, setBuyoutServices] = useState([]);
    const [buyoutDates, setBuyoutDates] = useState([]);

    const [selectedLocation, setSelectedLocation] = useState('');
    const [selectedService, setSelectedService] = useState(-1);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedGuest, setSelectedGuest] = useState('Me');

    const { slug } = useParams();

    useEffect(() => {
        if (slug) {
            console.log('Slug:', slug);
            loadPageDataFromContentful(slug);
        } else {
            history.push('/');
        }
    }, [slug]);

    useEffect(() => {
        let loggedIn = isAuthenticated();
        setIsLoggedIn(loggedIn);
    }, [isAuthenticated]);

    console.log('Banners:', banners, headlines, renderData);

    useEffect(() => {
        if (banners && banners.length > 0) {
            const data = {
                banner: banners[0]?.items[0]?.image?.desktopMedia?.url,
                headline: headlines[0]?.items[0]?.image?.desktopMedia?.url,
            };
            setRenderData(data);
        }
    }, [banners, headlines]);

    const handleAuth = () => {
        //setIsAuthenticated(!isAuthenticated)
    };

    const bookService = () => {
        if (selectedLocation !== '') {
            setBookingLocation({
                bookerLocationId: selectedLocation,
                title: buyoutSettings.locations.find((l) => l.locationID === selectedLocation)?.name,
            });
            setBuyoutLocation(selectedLocation);
            const service = buyoutServices.find((s) => `${s.ID}` === selectedService);
            const serviceData = [{
                user: 'Me',
                data: service,
            }];

            if (selectedGuest === 'Me + Guest') {
                serviceData.push({
                    user: 'Guest 1',
                    data: service,
                });
                setNumberOfGuests(1);
            } else {
                setNumberOfGuests(0);
            }
            overwriteServicesData(serviceData);
            const datestr = dayjs(selectedDate).format(`YYYY-MM-DDT00:00:00${getTimezoneString(new Date())}`);
            setDateTimeClient(datestr);
            history.push('/booking/select-date');
        }
    };

    const fetchLocationServices = useCallback(async () => {
        const { data } = await restClient.post(getServices(selectedLocation));
        if (data.Treatments) {
            const tempBuyoutLocations = data.Treatments.filter((s) => buyoutSettings.treatments.includes(`${s.ID}`));
            setBuyoutServices(tempBuyoutLocations);
        }
    }, [selectedLocation]);

    useEffect(() => {
        if (selectedLocation) {
            setBuyoutServices([]);
            setSelectedService(-1);
            fetchLocationServices();
        }
    }, [selectedLocation]);

    useEffect(() => {
        if (buyoutSettings) {
            if (buyoutSettings.locations) {
                setSelectedLocation(buyoutSettings.locations[0].locationID);
            }

            if (buyoutSettings.slots) {
                const dateoptions = buyoutSettings.slots.reduce(
                    (s, c) => {
                        let [startDate, endDate] = c.date.split(' ~ ');
                        if (endDate) {
                            startDate = dayjs(startDate);
                            endDate = dayjs(endDate);
                            let date = startDate;
                            for (let i = 0; i < endDate.diff(startDate, 'day'); i++) {
                                const dateStr = date.format('YYYY-M-D');
                                if (!s.includes(dateStr)) {
                                    s.push(dateStr);
                                }
                                date = date.add(1, 'days');
                            }
                        } else if (!s.includes(startDate)) {
                                s.push(startDate);
                            }

                        return s;
                    }, [],
                ).sort();
                setBuyoutDates(dateoptions);
            }
        }
    }, [buyoutSettings]);

    useEffect(() => {
        if (buyoutServices && buyoutServices.length) {
            setSelectedService(`${buyoutServices[0].ID}`);
        }
    }, [buyoutServices]);

    useEffect(() => {
        if (buyoutDates && buyoutDates.length) {
            setSelectedDate(buyoutDates[0]);
        }
    }, [buyoutDates]);

    return (
        <div onClick={handleAuth} className="section__background__grey">
            <MetaTagWrapper metadata={metadata} />
            {renderData && (
                <div
                    className="tab-pane fade show active mw-1295 m-auto buyout-page"
                    id="nav-home"
                    role="tabpanel"
                    aria-labelledby="nav-home-tab"
                >
                    <div className="banner-section">
                        <div className="banner-image">
                            <CustomImg className="d-block w-100" src={renderData.banner} alt="Banner" />
                        </div>
                    </div>
                    <div className="middle-section px-3 px-md-4 px-lg-0">
                        <div className="buyout-headline">
                            <div className="row buyout-headline-title">
                                <h2>{title || ''}</h2>
                            </div>
                            <div className={`row p-4 d-flex w-100 buyout-headline-body ${ isAuthenticated ? ' flex-row-reverse ' : ''}`}>
                                {isLoggedIn ? (
                                    <div className="col-md-6 pl-md-4 mb-4 mb-md-0 buyout-headline-body-content">
                                        {description && description.json && <DocumentToComponent doc={description.json} />}
                                    </div>
                                ) : (
                                    <div className="col-md-6 pr-md-4 mb-4 mb-md-0 buyout-headline-body-content">
                                        {description && description.json && <DocumentToComponent doc={description.json} />}
                                    </div>
                                )}
                                <div className={`col-md-6 buyout-headline-body-banner ${ isLoggedIn ? 'pr-4' : 'pl-4'}`}>
                                    <CustomImg className="d-block w-100" src={renderData.headline} alt="Headline Banner" />
                                </div>
                            </div>
                        </div>
                        <div className="buyout-get-started pt-5">
                            <div className="get-started-title pb-4">
                                {isLoggedIn ? (
                                    <p>
                                        Please select a location to get started
                                    </p>
                                ) : (
                                    <p>
                                        Please login to get started
                                    </p>
                                )}
                            </div>
                            <div className="row get-started-body flex-row-reverse">
                                {!isLoggedIn ? (
                                    <>
                                        <div className="col-md-6">
                                            <Registration />
                                        </div>
                                        <div className="col-md-6">
                                            <LogInCard buyout={`/buyout/${slug}`} />
                                            {/* <div className="login-footer">
                                                <div className="stock-up">
                                                    <CustomImg src={stockUpImage} alt="stock up image"/>
                                                    <Button type="button" className="learn-more-btn" variant={"outlined"}>
                                                        Learn More
                                                    </Button>
                                                </div>
                                            </div> */}
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="col-md-6 pt-5 pb-5 location-image">
                                            <CustomImg src={buyoutLoginLocation} alt="location image" />
                                        </div>
                                        <div className="col-md-6 location-info pt-5">
                                            <div className="w-100 select-wrapper">
                                                <TextField
                                                    select
                                                    label="Select Location"
                                                    SelectProps={{
                                                        native: true,
                                                    }}
                                                    value={selectedLocation}
                                                    fullWidth
                                                    onChange={(e) => setSelectedLocation(e.target.value)}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                >
                                                    {
                                                        buyoutSettings && buyoutSettings.locations && buyoutSettings.locations.map(
                                                            (l) => (
                                                                <option value={l.locationID} key={l.locationID}>
                                                                    {l.name}
                                                                </option>
),
                                                        )
                                                    }
                                                </TextField>
                                                <TextField
                                                    select
                                                    label={'Who\'s coming'}
                                                    SelectProps={{
                                                        native: true,
                                                    }}
                                                    value={selectedGuest}
                                                    fullWidth
                                                    onChange={(e) => setSelectedGuest(e.target.value)}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                >
                                                    <option value="Me">Me</option>
                                                    <option value="Me + Guest">Me + Guest</option>
                                                </TextField>
                                                {buyoutSettings && buyoutSettings.slots && (
                                                <TextField
                                                    select
                                                    label="Select Date"
                                                    SelectProps={{
                                                        native: true,
                                                    }}
                                                    value={selectedDate}
                                                    fullWidth
                                                    onChange={(e) => setSelectedDate(e.target.value)}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                >
                                                    {
                                                        buyoutDates.map(
                                                            (d) => (
                                                                <option value={d} key={d}>
                                                                    {d}
                                                                </option>
),
                                                        )
                                                    }
                                                </TextField>
)}
                                                {buyoutServices.length > 0 && (
                                                <TextField
                                                    select
                                                    label="Select Service"
                                                    SelectProps={{
                                                        native: true,
                                                    }}
                                                    value={selectedService}
                                                    fullWidth
                                                    onChange={(e) => setSelectedService(e.target.value)}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                >
                                                    {
                                                        buyoutServices.map(
                                                            (s) => (
                                                                <option value={s.ID} key={s.ID}>
                                                                    {s.Name}
                                                                </option>
),
                                                        )
                                                    }
                                                </TextField>
)}
                                            </div>

                                            <div className="w-100 button-wrapper">
                                                <Button type="submit" className={classes.bookingProcess} variant="outlined" onClick={() => bookService()}>
                                                    Begin Booking Process
                                                </Button>
                                            </div>

                                        </div>

                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>

    );
};

BuyOut.propTypes = {

};

const mapStateToProps = (state) => ({
    title: getTitle(state),
    description: getDescription(state),
    banners: getBanners(state),
    headlines: getHeadlines(state),
    buyoutSettings: getLocations(state),
    metadata: getMetaData(state),
    isBuyoutLoaded: isBuyoutLoaded(state),
});

const mapDispatchToProps = (dispatch) => ({
    loadPageDataFromContentful: bindActionCreators(loadBuyoutContentful, dispatch),
    setBookingLocation: bindActionCreators(setLocationData, dispatch),
    setBuyoutLocation: bindActionCreators(setBuyoutLocation, dispatch),
    overwriteServicesData: bindActionCreators(overwriteServicesData, dispatch),
    setNumberOfGuests: bindActionCreators(setNumberOfGuests, dispatch),
    setDateTimeClient: bindActionCreators(setDateTimeClient, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(BuyOut);
