import { gql } from '@apollo/client';
import contentIds from '../contentIds';
import { getPreviewMode } from '../../state/utils/contentful';

const screenBarfly = () => gql`{
    barfly(${getPreviewMode()}, id: "${contentIds.barflyMembership}") {
        membershipsCollection(limit: 2) {
          items {
            title
            subtitle
            price
            benefitsCollection(limit: 6) {
              items {
                ... on BarflyBenefits {
                  name
                  value
                }
              }
            }
          }
        }
        finePrint {
          json
        }
        thankYou {
          json
        }
        marketingComponentsCollection(limit: 1) {
          items {
            __typename
            ... on MarketingSection {
              internalName
              marketingComponentsCollection(limit: 1) {
                items {
                  __typename
                  ... on MarketingCard {
                    image {
                      desktopMedia {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
  }
`;

const barflyPageQuery = () => (
  `{
    drybarPageCollection(preview: true, where: { slug: "barfly-membership" }) {
      items {
        sys {
          id
        }
        title
        subtitle
        tcsAndPoliciesCopy
        seoTitle
        seoDescription
        canonicalUrl
        addANoindexTag
        slug
        formatofPageCollection(preview: true) {
          items {
            __typename
            ... on HeroBanner {
              sys {
                id
              }
            }
            ... on DrybarMembershipGrid {
              sys {
                id
              }
            }
            ... on DrybarFeatureBox {
              sys {
                id
              }
              description
              title
              subtitle
              finePrint {
                json
              }
            }
          }
        }
      }
    }
  }`
)

const fetchBannerData = (id) => `
{
  heroBanner(id: "${id}", preview: true) {
    targetPageUrl
    slugPrefix
    mediaCollection(preview: true) {
      items {
              internalName
              desktopImageUrl
              mobileImageUrl
              videoUrl
            }
    }
  }
}
`;

const fetchBarflyMemberships = (id) => (
`{
  drybarMembershipGrid(id: "${id}", ${getPreviewMode()}) {
    footnote
    slugPrefix
    membershipCardsCollection {
      items {
        title
        subtitle
        price
        locationId
        bookerMembershipId
        bookerPaymentPlanId
        benefitType
        benefitDescription
        buttonCtaCollection {
          items {
            buttonCtaUrl
            buttonCtaTitle
            buttonCtaStyle
          }
        }
        slugPrefix
      }
    }
  }
}`
)

export {screenBarfly, barflyPageQuery, fetchBannerData, fetchBarflyMemberships};
