/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable max-len */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Hyperlink } from '../../Links/Hyperlink';
import { TABLET_BREAKPPOINT } from '../../../../Helpers/breakpoints';
import facebookIconFooter from '../../../../assets/images/Facebook.svg';
import instagramIconFooter from '../../../../assets/images/InstagramFooter.svg';
import xIconFooter from '../../../../assets/images/X.svg';
import pinterestIconFooter from '../../../../assets/images/Pinterest.svg';
import youtubeIconFooter from '../../../../assets/images/Youtube.svg';

const useStyles = makeStyles((theme) => ({
    footerIcon: {
        margin: '0 8px',
        [theme.breakpoints.down(TABLET_BREAKPPOINT)]: {
            height: '24px',
            margin: '0 18px',
        },
    },
}));

const linksFooter = () => {
    const classes = useStyles();

    return (
        <div>
            <Hyperlink to={"https://www.facebook.com/DrybarUK/"} newkey={"https://www.facebook.com/DrybarUK/"}>
                <img alt="Facebook logo" src={facebookIconFooter} className={classes.footerIcon} />
            </Hyperlink>
            <Hyperlink to={"https://www.instagram.com/drybar_uk/"} newkey={"https://www.instagram.com/drybar_uk/"}>
                <img alt="Instagram logo" src={instagramIconFooter} className={classes.footerIcon} />
            </Hyperlink>
            <Hyperlink to={"https://x.com/thedrybar"} newkey={"https://x.com/thedrybar"}>
                <img alt="Twitter logo" src={xIconFooter} className={classes.footerIcon} style={{height: '18px'}}/>
            </Hyperlink>
            <Hyperlink to={"https://www.pinterest.co.uk/thedrybar/"} newkey={"https://www.pinterest.co.uk/thedrybar/"}>
                <img alt="Pinterest logo" src={pinterestIconFooter} className={classes.footerIcon} />
            </Hyperlink>
            <Hyperlink to={"https://www.youtube.com/user/drybarblowdrybar"} newkey={"https://www.youtube.com/user/drybarblowdrybar"}>
                <img alt="Youtube logo" src={youtubeIconFooter} className={classes.footerIcon} />
            </Hyperlink>

        </div>
    );
};

export default linksFooter;
