import {
 call, fork, put, takeLatest,
} from 'redux-saga/effects';
import { loadSubscribeContentful, setSubscribeContent } from './Subscribe-Actions';
import { gqlLoadSubscribe } from '../../../gql/subscribe/contentfulSubscribeActions';

function* workerLoadSubscribeContentful({ payload }) {
    const subscribeSectionData = yield call(gqlLoadSubscribe, { slug: payload });
    yield put(setSubscribeContent(subscribeSectionData));
}

function* watcherLoadSubscribeContentful() {
    yield takeLatest(loadSubscribeContentful().type, workerLoadSubscribeContentful);
}

export const watchers = {
    watcherSubscribeLoadContentful: fork(watcherLoadSubscribeContentful),
};

export default {
    watchers,
};
