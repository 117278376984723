/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import { Grid, Typography, withStyles } from '@material-ui/core';
import { object, string } from 'prop-types';
import React from 'react';
import Media from 'react-media';
import { withRouter } from 'react-router-dom';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { MOBILE_BREAKPOINT } from '../../Helpers/breakpoints';

const styles = (theme) => ({
    text: {
        fontFamily: 'DINCondensed-Regular',
        textAlign: 'center',
        // margin: '8px 0',
        fontWeight: 600,
        fontSize: '42px!important',
        lineHeight: '30px!important',
        color: '#42413D',
        [theme.breakpoints.down('sm')]: {
            fontSize: '31px!important',
        },
    },
    container: {
        padding: '45px',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            background: '#FFFFFF',
        },
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            padding: '30px'
        }
    },
    goBack: {
        float: 'left',
        margin: 'auto 0 auto 0',
    },
    displayFlex: {
        display: 'flex',
    },
    goToNextCopy: {
        fontWeight: '600',
        fontSize: '13px',
        lineHeight: 1,
    },
    containerTitleCenter: {
        display: 'flex',
        justifyContent: 'center',
    },
    containerTitle: {
        padding: '6px 52px !important',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            padding: '6px 125px !important',
        },
    },
    bookingTitle: {
        fontSize: '42px',
    },
});

const SectionTitle = ({
    classes, title, history, location, containerTitle, containerTitleCenter, addCardBlock, onButtonClick,
}) => {
    const isAddonsLocation = location?.pathname?.includes('/booking/addons');
    const isMobileAccountNav = location?.pathname?.includes('/account/my-account');

    const onBack = () => {
        history.goBack();
    };

    return (
        <Grid style={{ width: '100%' }}>
            <Grid className={classes.container}>
                <Typography
                    className={classes.text}
                    variant="h1"
                >
                    <Media query={{ maxWidth: 599 }}>
                        {(matches) => (matches ? (
                            <Grid container spacing={3}>
                                <Grid item xs={2} className={classes.displayFlex}>
                                    {!isMobileAccountNav ? <ArrowBackIosIcon onClick={onBack} className={classes.goBack} /> : null}
                                </Grid>
                                <Grid item xs={8}>
                                    {title?.toUpperCase()}
                                </Grid>
                                {isAddonsLocation ? (
                                    <Grid item xs={2} className={classes.displayFlex}>
                                        <div
                                            onClick={() => {
                                            if (onButtonClick) {
                                                onButtonClick();
                                            }
                                        }}
                                            style={{ color: '#42413D', display: 'flex', alignItems: 'center' }}
                                        >
                                            <Typography className={classes.goToNextCopy}>NEXT</Typography>
                                            <ArrowForwardIosIcon />
                                        </div>
                                    </Grid>
                                ) : null}

                            </Grid>
                        ) : (
                            <>
                                {title?.toUpperCase()}
                            </>
                        )
                        )}
                    </Media>
                </Typography>
                {/* Optional Title */}

            </Grid>
            {containerTitle?.length
                ? (
                    <Typography
                        className={`${classes.containerTitle} ${containerTitleCenter ? classes.containerTitleCenter : ''}`}
                    >
                        {containerTitle}
                    </Typography>
                )
                : null}
        </Grid>
    );
};

SectionTitle.propTypes = {
    classes: object.isRequired,
    title: string,
    history: object.isRequired,
    location: object.isRequired,
};

SectionTitle.defaultProps = {
    title: '',
};

export default withRouter(withStyles(styles)(SectionTitle));
