import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import algoliasearch from 'algoliasearch/lite';
import {
 Grid, Button, withStyles, Typography,
} from '@material-ui/core';
import {
  Container,
  Title,
  SearchItem,
} from 'components';
import { getAddress } from 'state/utils/helpers';
import config from 'app.config';
import { gqlLoadTheStyleImages } from 'gql/the-styles/contentfulTheStylesActions';
import StoreMarker from 'assets/images/inactiveMarker.svg';
import favoriteMarker from 'assets/images/favoriteMarker.svg';

const styles = (theme) => ({
  title: {
    fontSize: theme.fontSize.lg,
    lineHeight: '51px',
    padding: '48px 0 24px !important',
  },
  loadMore: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
  },
  button: {
    minWidth: '300px',
  },
});

function SearchResult({ classes }) {
  const location = useLocation();

  const { algolia } = config;
  const { searchType } = algolia;

  const algoliaClient = algoliasearch(
    algolia.appId,
    algolia.apiKey,
  );

  const CATEGORY = [
    searchType.style,
    searchType.store,
    searchType.video,
  ];
  const stylesIndex = algolia.index.style;
  const storeIndex = algolia.index.store;
  const howtosIndex = algolia.index.video;


  const query = location?.state?.searchValue;
  const queries = [{
    indexName: stylesIndex,
    query,
  }, {
    indexName: storeIndex,
    query,
    hitsPerPage: 20,
   }, 
   //{  indexName: howtosIndex,
   //   // query,
   // }
  //{
  //  indexName: productsIndex,
  //  query,
  //    }
  ];

  const totalResults = [];
  const perPage = 5;

  const [totalHits, setTotalHits] = useState([]);
  const [styles, setStyles] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(async () => {
    if (!query || query.trim().length < 2) {
      setTotalHits([]);
      return;
    }

    setLoading(true);

    algoliaClient.multipleQueries(queries).then(({ results }) => {
      results?.map((result) => {
        result?.hits?.map((item) => {
          if(item?.locationStatus==="disabled"){

          }
          else{
          totalResults.push(item);
        }
      });
      });

      setTotalHits(totalResults);
      setLoading(false);
      setPage(1);
    });

    const data = await gqlLoadTheStyleImages();
    setStyles(data);
  }, [query]);

  const StyleItem = (hit, isLast) => {
    if (!hit) return null;

    const title = hit.styleName;
    const description = hit.styleDescription
    const slug = hit.styleUrl
    const image = hit.styleThumbnail
 
    return (
        <SearchItem
            category={CATEGORY[0]}
            styles={styles}
            media={image}
            title={title}
            content={description}
            slug={`/the-styles/#${slug}`}
            isLast={isLast}
        />
    );
  };

  const StoreItem = (hit, isLast) => {
    if (!hit) return null;

    const { street1, city, postalcode} = hit.locationAddress;
    const title = hit.locationName
    const address = getAddress({
      street: street1,
      city: city,
      zip: postalcode,
    });
    const description = hit.information;
    const slug = hit.locationUrl;

    return (
        <SearchItem
            category={CATEGORY[1]}
            media={favoriteMarker}
            title={title}
            address={address}
            content={description}
            slug={slug}
            isLast={isLast}
        />
    );
  };

  // const HowtoItem = (hit, isLast) => {
  //   if (!hit) return null;

  //   const previewImg = hit.image_url;
  //   const title = hit.name;
  //   const { description } = hit;
  //   const { url } = hit;

  //   return (
  //       <SearchItem
  //           category={CATEGORY[2]}
  //           media={previewImg}
  //           title={title}
  //           content={description}
  //           slug={url}
  //           isLast={isLast}
  //       />
  //   );
  // };

  //const ProductItem = (hit, isLast) => {
  //  if (!hit) return null;

  //  const image = hit.image_link;
  //  const { description, title } = hit;
  //  const price = hit.price
  //  const slug = hit.link;

  //  return (
  //      <SearchItem
  //          category={CATEGORY[3]}
  //          media={image}
  //          title={title}
  //          content={description}
  //          price={price}
  //          slug={slug}
  //          isLast={isLast}
  //      />
  //  );
  //};

  const RenderItem = ({ hit, isLast }) => {
    if (hit.hasOwnProperty("styleThumbnail")) {
      return StyleItem(hit, isLast);
    } if (hit.hasOwnProperty('locationAddress') && hit.locationStatus==="visible") {
      return StoreItem(hit, isLast);
    // } if (hit._tags?.length && hit._tags[0] === 'video') {
    //   return HowtoItem(hit, isLast);
    //} if (hit.hasOwnProperty('price')) {
    //  return ProductItem(hit, isLast);
    }

    return null;
  };

  const handleLoadMore = () => {
    setPage(page + 1);
  };

  return (
      <Container>
          <Title className={classes.title}>Search Results</Title>
          {loading && <Typography className="text-center" style={{ paddingBottom: '10px' }}>Loading...</Typography>}
          {!totalHits.length && !loading && <Typography className="text-center">No results found</Typography>}
          <Grid container spacing={3}>
              {totalHits.slice(0, page * perPage).map((item, index) => {
          let isLast = false;
          if (totalHits.length > page * perPage && page * perPage === index + 1) isLast = true;
          return (
              <RenderItem
                  key={item.objectID}
                  hit={item}
                  isLast={isLast}
              />
          );
        })}
          </Grid>

          <Grid className={classes.loadMore}>
              <Button
                  variant="contained"
                  color="primary"
                  onClick={handleLoadMore}
                  className={classes.button}
                  hidden={totalHits.length < page * perPage}
              >
                  Show More
              </Button>
          </Grid>
      </Container>
  );
}

export default withStyles(styles)(SearchResult);
