import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const RegistrationSuccess = ({
    showConfirmationDialog,
    setShowConfirmationDialog,
    classes,
    successMessage,
}) => {
    const handleClose = () => {
        setShowConfirmationDialog(false);
    };

    return (
        <div>
            <Dialog
                open={showConfirmationDialog}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                      this.handleClose(event, reason);
                    }
                  }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" className={classes.modalTitle}>
                    <div style={{ display: 'flex' }}>
                        <Typography className={classes.modalTitle} style={{ marginLeft: '40px' }}>Updated Successfully</Typography>
                        <CloseIcon
                            onClick={() => handleClose()}
                            style={{
                        margin: '-6px -20px 0px 64px',
                        cursor: 'pointer',
                    }}
                        />
                    </div>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {successMessage}
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default RegistrationSuccess;
