import {
 call, fork, put, takeLatest,
} from 'redux-saga/effects';

import { loadFranchisingContentful, setFranchisingContent } from './Franchising-Actions';
import { gqlLoadFranchising } from '../../../gql/franchising/contentfulFranchisingActions';

function* workerLoadFranchisingContentful() {
    const franchisingSectionData = yield call(gqlLoadFranchising);
    yield put(setFranchisingContent(franchisingSectionData));
}

function* watcherLoadFranchisingContentful() {
    yield takeLatest(loadFranchisingContentful().type, workerLoadFranchisingContentful);
}

export const watchers = {
    watcherLoadFranchisingContentful: fork(watcherLoadFranchisingContentful),
};

export default {
    watchers,
};
