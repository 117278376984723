/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { string } from 'prop-types';
import { connect } from 'react-redux';
import AddOnCard from './Partials/AddOnCard';
import ServiceSkeleton from '../../../app/Components/Skeleton/ServiceSkeleton';
import AddOnOneMany from './Partials/AddOnOneMany';
import { getAddonsByUser, getServicesData } from '../../../state/ducks/Booking/Booking-Selectors';

// TODO refactor
const AddOns = ({
    guests,
    addonData,
    getAddonsByUserFromState,
    goToNextPage,
    serviceData,
}) => {
    const [selectedUser, setSelectedUser] = useState('Me');
    const serviceDataForSelectedUser = serviceData.find((s) => s.user === selectedUser);

    if (addonData) {
        return (
            <>
                {guests > 0 ? <AddOnOneMany setSelectedUser={setSelectedUser} guests={guests} selectedUser={selectedUser} goToNextPage={goToNextPage} /> : null}
                {
                    serviceDataForSelectedUser?.data?.Name === 'Dry Styling'
                        ? 'Dry Styling does not offer any add-ons.'
                        : (
                            <>
                                {addonData?.length > 0 ? addonData.map((addon, index) => (
                                addon?.PriceInfo?.Amount !== 0
                                    ? (
                                        <AddOnCard
                                            isSelectedForUser={getAddonsByUserFromState(selectedUser).some((data) => data && data.ServiceID === addon.ServiceID)}
                                            selectedUser={selectedUser}
                                            addonData={addon}
                                            guests={guests}
                                            key={`add-on ${{index}}`}
                                        />
)
                                    : null
                            )) : <ServiceSkeleton />}
                            </>
)
                }

            </>
        );
    }
    return null;
};

AddOns.propTypes = {
    guests: string.isRequired,
};

const mapStateToProps = (state) => ({
    getAddonsByUserFromState: (user) => getAddonsByUser(state, user),
    serviceData: getServicesData(state),
});

export default connect(mapStateToProps)(AddOns);
