import restClient from 'api/restClient';
import apis from 'api/auth-api'

const UkPhoneCode = 'UK +44'

export const getCountryCodes = async () => {

    const defaultCode = [UkPhoneCode];

    return await restClient.post(apis.getPhoneCountryCodes())
        .then(result => {
            if(result.status === 200){
                return result.data;
            }
            return defaultCode;
            })
        .catch(() => defaultCode);
}

export default async () => [
    {
        label: 'First Name',
        name: 'firstName',
        required: true,
        placeholder: 'Your first name here...',
    },
    {
        label: 'Last Name',
        name: 'lastName',
        required: true,
        placeholder: 'Your last name here...',
    },
    {
        label: 'Phone Number',
        name: 'primaryPhone',
        placeholder: 'Your phone number here...',
        type: 'tel',
        required: true,
        select: {
            name: 'phoneCountryCode',
            options: await getCountryCodes(),
            default: UkPhoneCode,
            required: true
        }
    },
    {
        label: 'Email',
        name: 'email',
        type: 'email',
        required: true,
        placeholder: 'Your email here...',
    },
    {
        label: 'Password',
        name: 'password',
        type: 'password',
        required: true,
        placeholder: 'Your password here...',
    },
    {
        label: 'Confirm Password',
        name: 'confirmPassword',
        type: 'password',
        required: true,
        placeholder: 'Your password confirmation here...',
    }];