/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Slider from 'react-slick';
import axios from 'axios';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Media from 'react-media';

import '../../assets/css/style.scss';
import '../../assets/css/style-responsive.scss';
import divider1Icon from '../../assets/images/ic-divider-1.png';
import divider2Icon from '../../assets/images/ic-divider-2.png';
import divider3Icon from '../../assets/images/ic-divider-3.png';
import arrowLeftIcon from '../../assets/images/arrow-left-1.svg';
import arrowRightIcon from '../../assets/images/arrow-right-1.svg';

import {
    getEvents,
    getEventsSectionHeroImage,
    getEventsSectionSubTitle,
    getEventsSectionTitle,
} from '../../state/ducks/Events/Events-Selectors';
import EventSummaryView from './event-summary';
import EventDetailView from './event-detail';
import appConfig from '../../app.config';
import { CustomImg } from '../../components';

const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <>
            <div className="slick-arrow right" onClick={onClick}>
                <img src={arrowLeftIcon} alt="Arrow Right" />
            </div>
        </>
    );
};
const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <>
            <div className="slick-arrow left" onClick={onClick}>
                <img src={arrowRightIcon} alt="Arrow Left" />
            </div>
        </>
    );
};
const Events = ({ title, subtitle, heroImage, events }) => {
    const slickSettings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    dots: true,
                },
            },
        ],
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
    };

    const dividerIcons = [divider1Icon, divider2Icon, divider3Icon];

    const [preferredShopChoices, setPreferredShopChoices] = useState([]);
    const [preferredDateChoices, setPreferredDateChoices] = useState([]);
    const [preferredStartTimeChoices, setPreferredStartTimeChoices] = useState(
        []
    );
    const [partySizeChoices, setPpartySizeChoices] = useState([]);
    const [occasions, setOccasions] = useState([]);

    useEffect(() => {
        axios
            .post(appConfig.apiUrl + '/forms/getForms', {
                subDomain: appConfig.wufoo.subDomain,
                formId: appConfig.wufoo.genericPartyFormId,
                key: btoa(`${appConfig.wufoo.apiKey}:password`),
            })
            .then((res) => {
                const { data } = res;
                const fields = data?.Fields || [];
                fields.forEach((field) => {
                    if (field.Title === 'Preferred Shop') {
                        setPreferredShopChoices(
                            field.Choices.map((choice) => choice.Label || '')
                        );
                    } else if (field.Title === 'Date') {
                        setPreferredDateChoices(
                            field.Choices?.map((choice) => choice.Label || '')
                        );
                    } else if (field.Title === 'Time') {
                        setPreferredStartTimeChoices(
                            field.Choices.map((choice) => choice.Label || '')
                        );
                    } else if (field.Title === 'Party Size') {
                        setPpartySizeChoices(
                            field.Choices.map((choice) => choice.Label || '')
                        );
                    } else if (field.Title === 'Occasion') {
                        setOccasions(
                            field.Choices.map((choice) => choice.Label || '')
                        );
                    }
                });
            })
            .catch((error) => console.log('error in wufoo api ', error));
    }, []);

    if (!title && !subtitle && !heroImage) {
        return <div />;
    }
    return (
        <>
            <div className="banner-section">
                <div className="banner-image">
                    <Media query={{ maxWidth: 767 }}>
                        {(matches) =>
                            matches ? (
                                <CustomImg
                                    className="img-fluid banner_help"
                                    src={heroImage.mobile}
                                    alt={heroImage.alternateTitle || ''}
                                    style={{
                                        maxWidth: '767px',
                                    }}
                                />
                            ) : (
                                <CustomImg
                                    className="img-fluid banner_help"
                                    src={heroImage.mobile}
                                    alt={heroImage.alternateTitle || ''}
                                    style={{ maxWidth: '1295px' }}
                                />
                            )
                        }
                    </Media>
                </div>
            </div>
            <div className="middle-section bg-light">
                <div className="event-section">
                    <div className="row">
                        <h1>{title.toUpperCase()}</h1>
                        {/* <h5>{EVENT_HEAD_1}</h5> */}
                        <p dangerouslySetInnerHTML={{ __html: subtitle }} />
                    </div>

                    <div className="slider-row">
                        <Slider {...slickSettings}>
                            {events.map(
                                ({ image, title, subtitle, action }, index) => (
                                    <EventSummaryView
                                        image={image}
                                        title={title}
                                        subtitle={subtitle}
                                        action={action}
                                        key={`event_${index}`}
                                    />
                                )
                            )}
                        </Slider>
                    </div>

                    <div className="detail-row">
                        {events.map((event, index) => (
                            <EventDetailView
                                {...event}
                                dividerIcon={dividerIcons[index % 2]}
                                preferredShopChoices={preferredShopChoices}
                                preferredDateChoices={preferredDateChoices}
                                preferredStartTimeChoices={
                                    preferredStartTimeChoices
                                }
                                partySizeChoices={partySizeChoices}
                                occasions={occasions}
                                key={`event_${index}`}
                            />
                        ))}
                    </div>
                </div>
                <div className="gradient-bottom-decorator" />
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    title: getEventsSectionTitle(state),
    subtitle: getEventsSectionSubTitle(state),
    heroImage: getEventsSectionHeroImage(state),
    events: getEvents(state),
});

export default connect(mapStateToProps)(Events);
