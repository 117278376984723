import {
    queryMarketingCardInMarketingCollectionFromContentfull,
    queryMarketingStylesFromContentfull,
    homeTopLevelQueryFromContentful
} from './contentfulHomeQueries';
import {
    doQuery,
} from '../../state/utils/contentful';

// const parseMarketingCollectionWithMarketingCards = (data) => {
//     const marketingCollectionData = {
//         title: '',
//         items: [],
//         settings: {},
//     };

//     try {
//         marketingCollectionData.title = data.marketingCollection?.title || '';
//         marketingCollectionData.settings =
//             data.marketingCollection?.settings || {};
//         const items =
//             data.marketingCollection?.marketingComponentsCollection?.items ||
//             [];
//         for (let i = 0; i < items.length; i++) {
//             const item = items[i];

//             // TODO -- Account for the potential of more than one action
//             marketingCollectionData.items.push({
//                 title: item.title,
//                 subtitle: item.subtitle,
//                 avatar: item.avatar?.url || '',
//                 icon: item.icon?.url || '',
//                 image: {
//                     url: item.image?.desktopMedia?.url || '',
//                     format: item.image?.desktopMedia?.contentType || '',
//                 },
//                 mobileImage: {
//                     url: item.image?.mobileMedia?.url || '',
//                     format: item.image?.mobileMedia?.contentType || '',
//                 },
//                 action: {
//                     title: item.actionsCollection?.items[0].title || '',
//                     link: item.actionsCollection?.items[0].linkToUrl || '',
//                     style: item.actionsCollection?.items[0].style || '',
//                     // reference: item.actionsCollection?.items[0]['linkToContent'] || {}
//                 },
//                 settings: item.settings || {},
//             });
//         }
//     } catch (err) {
//         console.error(err);
//     }
//     return marketingCollectionData;
// };

const parseMarketingCollectionWithMarketingCardsFromContentfull = (data) => {
    const marketingCollectionData = {
        title: '',
        items: [],
        settings: {},
    };

    try {
        marketingCollectionData.title = data.carousel?.title || '';

        marketingCollectionData.settings = {
            container: data.carousel?.format[0] || 'carousel',
            settings: {
                slider: {
                    arrows: data.carousel?.arrows || true,
                    autoplay: data.carousel?.autoplay || true,
                    dots: data.carousel?.dots || true,
                    infinite: data.carousel?.infinite || true,
                    slidesToScroll: data.carousel?.slidesToScroll || 1,
                    speed: data.carousel?.speed || 5000,
                },
            },
        };

        const items = data.carousel?.slidesCollection?.items || [];
        for (let i = 0; i < items.length; i++) {
            const item = items[i];

            // TODO -- Account for the potential of more than one action
            marketingCollectionData.items.push({
                title: item.title,
                subtitle: item.subtitle,
                image: {
                    url:
                        item.mediaCollection?.items?.[0]?.desktopImageUrl || '',
                    format:
                        item.image?.desktopMedia?.contentType || 'image/jpeg',
                },
                mobileImage: {
                    url: item.mediaCollection?.items?.[0]?.mobileImageUrl || '',
                    format:
                        item.image?.mobileMedia?.contentType || 'image/jpeg',
                },
                action: {
                    title: item.buttonCtaTitle || '',
                    link: item.buttonCtaUrl || '',
                    style:
                        `Button - ${item.buttonCtaStyle}` ||
                        'Button - Light',
                },
                settings: {
                    container: {
                        backgroundColor: item.backgroundColor || 'Default',
                        format: data.carousel?.format || 'hero',
                        textAlign: item.textAlign || 'Middle',
                        textJustify: item.textJustify || 'Left',
                    },
                    title: {
                        color: item.titleColour || 'Grey',
                        dropShadow: item.titleDropShadow || true,
                        size: item.titleSize || 'Medium',
                    },
                },
            });
        }
    } catch (err) {
        console.error(err);
    }

    return marketingCollectionData;
};

const parseMarketingStylesFromContentfull = (data) => {
    const marketingStylesData = {
        title: '',
        subtitle: '',
        action: {
            title: '',
            link: '',
        },
        items: [],
    };

    const styles = data.drybarStylesCarousel;

    try {
        marketingStylesData.title = styles?.title || '';
        marketingStylesData.subtitle = styles?.subtitle || '';
        marketingStylesData.action.title = styles?.ctaTitle;
        marketingStylesData.action.link = styles.ctaUrl;

        // TODO -- Account for Mobile Image
        // TODO -- Account for the potential of more than one action
        const items =
            styles?.carouselItemsCollection?.items || [];
        for (let i = 0; i < items.length; i++) {
            const item = items[i];

            marketingStylesData.items.push({
                slug: item.slugPrefix,
                type: item.__typename ?? '',
                title: item.title ?? '',
                subtitle: item.subtitle ?? '',
                image: {
                    url: item.imageUrl || '',
                    format: '',
                },
                avatar: item.avatar?.url || '',
                icon: item.icon?.url || '',
                action: {
                    title: item.actionsCollection?.items[0].title || '',
                    link: item.actionsCollection?.items[0].linkToUrl || '',
                    style: item.actionsCollection?.items[0].style || '',
                },
                settings: item.settings || {},
            });
        }
    } catch (err) {
        console.error(err);
    }
    return marketingStylesData;
};

// const parseMarketingStyles = (data) => {
//     const marketingStylesData = {
//         title: '',
//         subtitle: '',
//         action: {
//             title: '',
//             link: '',
//         },
//         items: [],
//     };

//     try {
//         marketingStylesData.title = data.marketingStyles?.title || '';
//         marketingStylesData.subtitle = data.marketingStyles?.subtitle || '';
//         const actions = data.marketingStyles?.actionsCollection?.items || [];
//         if (actions.length > 0) {
//             marketingStylesData.action.title = actions[0].title;
//             marketingStylesData.action.link = actions[0].linkToUrl;
//         }

//         // TODO -- Account for Mobile Image
//         // TODO -- Account for the potential of more than one action
//         const items =
//             data.marketingStyles?.marketingComponentsCollection?.items || [];
//         for (let i = 0; i < items.length; i++) {
//             const item = items[i];

//             marketingStylesData.items.push({
//                 slug: item.slug,
//                 type: item.__typename,
//                 title: item.title,
//                 subtitle: item.subtitle,
//                 image: {
//                     url: item.featuredImage?.desktopMedia?.url || '',
//                     format: item.featuredImage?.desktopMedia?.contentType || '',
//                 },
//                 avatar: item.avatar?.url || '',
//                 icon: item.icon?.url || '',
//                 action: {
//                     title: item.actionsCollection?.items[0].title || '',
//                     link: item.actionsCollection?.items[0].linkToUrl || '',
//                     style: item.actionsCollection?.items[0].style || '',
//                     // reference: item.actionsCollection?.items[0]['linkToContent'] || {}
//                 },
//                 settings: item.settings || {},
//             });
//         }
//     } catch (err) {
//         console.error(err);
//     }
//     return marketingStylesData;
// };

// const parseSocialInMarketingCollection = (data) => {
//     const socialData = {
//         title: '',
//         hashtag: '',
//         handle: '',
//     };
//     try {
//         const items =
//             data.marketingSection?.marketingComponentsCollection?.items || [];
//         if (items.length > 0) {
//             socialData.title = items[0].title || '';
//             socialData.hashtag = items[0].hashtag || '';
//             socialData.handle = items[0].handle || '';
//         }
//     } catch (err) {
//         console.error(err);
//     }
//     return socialData;
// };

export const gqlLoadHome = async () => {
    const homeSectionData = {
        hero: {},
        ribbon: {},
        theStyles: {},
        promos: {},
        offers: {},
        social: {},
        metadata: {},
    };

    //let data = await doQuery(homeTopLevelQuery());
    let data = await doQuery(homeTopLevelQueryFromContentful(), true);
    const pageData = data.drybarPageCollection?.items[0];

    homeSectionData.metadata = {
        pageDescription: pageData?.seoDescription,
        pageKeywords: [],
        pageTitle: pageData?.seoTitle,
        searchKeywords: []
    };

    // const metaDataId =
    //     data?.screenHomeCollection?.items[0]?.metadata?.sys?.id || null;
    // if (metaDataId) {
    //     const metadata = await getMetaData(metaDataId);
    //     homeSectionData.metadata = metadata;
    // }
    // const firstLevelIds = extractIdsFromTopLevel(
    //     data.screenHomeCollection || {}
    // );

    const firstLevelIds = pageData.formatofPageCollection?.items?.map(item => item.sys.id);

    if (firstLevelIds.length > 0) {
        // Load primary section
        //data = await doQuery(homeSecondLevelQuery(firstLevelIds[0]));

        // const primarySecondLevelIds = extractIdsFromSecondLevel(
        //     data.marketingSection || {}
        // );

        const heroData = parseMarketingCollectionWithMarketingCardsFromContentfull(
            await doQuery(
                queryMarketingCardInMarketingCollectionFromContentfull(
                    firstLevelIds[0]
                ),
                true
            )
        );

        // Load ribbon section data
        const ribbonData = parseMarketingCollectionWithMarketingCardsFromContentfull(
            await doQuery(
                queryMarketingCardInMarketingCollectionFromContentfull(
                    firstLevelIds[1]
                ),
                true
            )
        );

        homeSectionData.hero = heroData;
        homeSectionData.ribbon = ribbonData;
    }

    if (firstLevelIds.length > 2) {
        // Load secondary section
        // data = await doQuery(homeSecondLevelQuery(firstLevelIds[1]));
        // const secondarySecondLevelIds = extractIdsFromSecondLevel(
        //     data.marketingSection || {}
        // );

        // Load the-the-styles section data
        const theStylesData = parseMarketingStylesFromContentfull(
            await doQuery(queryMarketingStylesFromContentfull(firstLevelIds[2]), true)
        );

        // Load promo section data
        // const promoData = parseMarketingCollectionWithMarketingCards(
        //     await doQuery(
        //         queryMarketingCardInMarketingCollection(
        //             secondarySecondLevelIds[1]
        //         )
        //     )
        // );

        // Load offers section data
        // const offersData = parseMarketingCollectionWithMarketingCards(
        //     await doQuery(
        //         queryMarketingCardInMarketingCollection(
        //             secondarySecondLevelIds[2]
        //         )
        //     )
        // );

        homeSectionData.theStyles = theStylesData;
        //homeSectionData.promos = promoData;
        //homeSectionData.offers = offersData;
    }

    // if (firstLevelIds.length > 2) {
    //     // Load instagram section
    //     data = await doQuery(
    //         querySocialInMarketingCollection(firstLevelIds[2])
    //     );
    //     homeSectionData.social = parseSocialInMarketingCollection(data);
    // }

    return homeSectionData;
};
