import { getPreviewMode } from '../../state/utils/contentful';

/**
 * Load screenEventsCollection and its children's id
 * @returns {DocumentNode}
 */
const queryStylesCollection = () => (
    `{
      stylesCollection(${getPreviewMode()}, where: {slug: "the-styles"}) {
        items {
          metadata {
            sys {
              id
            }
          }
          title
          description {
            json
          }
          sys {
            id
          }
          marketingComponentsCollection {
            items {
              ... on MarketingSection {
                sys {
                  id
                }
              }
            }
          }
        }
      }
    }
    `
);

/**
 * Load styles' ids
 * @param screenStylesId
 * @returns {DocumentNode}
 */
const queryStylesExceptGallery = (screenStylesId) => (
    `{
      styles(${getPreviewMode()}, id: "${screenStylesId}") {
        stylesCollection {
          items {
            title
            subtitle
            featuredVideo {
              desktopUrl
            }
            slug
            featuredImage {
              alternateTitle
              desktopMedia {
                url
                width
                height
              }
            }
            featured360Gif {
              alternateTitle
              desktopMedia {
                url
                width
                height
              }
            }
            galleryCollection {
              items {
                sys {
                  id
                }
              }
            }
          }
        }
      }
    }
    `
);

/**
 * Load single gallery
 * @param galleryId
 * @returns {DocumentNode}
 */
const queryGallery = (galleryId) => (
    `{
      styleGallery(${getPreviewMode()}, id: "${galleryId}") {
        title
        imagesCollection {
          items {
            title
            alternateTitle
            desktopMedia {
              url
              contentType
            }
          }
        }
      }
    }
    `
);

/**
 * Load gallery collection
 * @param idsToFilter
 * @param skip
 * @param limit
 * @returns {DocumentNode}
 */
const queryGalleryCollection = (skip, limit, idsToFilter) => (
    `{
      styleGalleryCollection(
        ${getPreviewMode()},
        skip: ${skip},
        limit: ${limit},
        where: {
              sys: {
                id_in: ${idsToFilter}
              }
            }
        ) {
        items {
          title
          sys {
            id
          }
          imagesCollection {
            items {
              title
              alternateTitle
              desktopMedia {
                url
                contentType
                width
                height
              }
            }
          }
        }
      }
    }
    `
);

/**
 *
 * @param marketingSectionId
 * @returns {string}
 */
const queryMarketingSection = (marketingSectionId) => (
    `{
      marketingSection(${getPreviewMode()}, id:"${marketingSectionId}") {
        marketingComponentsCollection {
          items {
            ... on MarketingCard {
              image {
                alternateTitle
                desktopMedia {
                  url
                  contentType
                }
                mobileMedia {
                  url
                  contentType
                }
              }
            }
          }
        }
      }
    }
    `
);

const queryStyling = () => (
  `{
    drybarPageCollection(preview: true, where: { slug: "the-styles" }) {
      items {
        title
        subtitle
        tcsAndPoliciesCopy
        seoTitle
        seoDescription
        canonicalUrl
        addANoindexTag
        slug
        formatofPageCollection(preview: true) {
          items {
            __typename
            ... on HeroBanner {
              sys {
                id
              }
            }
            ... on ButtonCta {
              buttonCtaTitle
              buttonCtaUrl
              buttonCtaStyle
              newTab
            }
            ... on Carousel {
              sys {
                id
              }
            }
          }
        }
      }
    }
  }
  `
);

const fetchBannerData = (id) => `
{
  heroBanner(id: "${id}", preview: true) {
    targetPageUrl
    slugPrefix
    mediaCollection(preview: true) {
      items {
              internalName
              desktopImageUrl
              mobileImageUrl
              videoUrl
            }
    }
  }
}
`;

const queryCarousel = (marketingCollectionId) => (
  `{
    carousel(preview: true, id: "${marketingCollectionId}") {
      internalName
      title
      autoplay
      dots
      infinite
      slidesToScroll
      slidesToShow
      arrows
      slugPrefix
      format
      speed
      slidesCollection {
        items {
          ... on DrybarStylesItem {
            title
            subtitle
            imageUrl
            slugPrefix
          }
          ... on CarouselSlide {
            slugPrefix
            internalName
            title
            subtitle
            buttonCtaUrl
            buttonCtaTitle
            buttonCtaStyle
            titleDropShadow
            titleSize
            titleColour
            backgroundColour
            textJustify
            textAlign
            mediaCollection {
              items {
                internalName
                desktopImageUrl
                mobileImageUrl
                videoUrl
                showThisModel1Url
                showThisModel2Url
                showThisModel3Url
              }
            }
          }
        }
      }
    }
  }
  `
);

export {
    queryStylesCollection,
    queryStylesExceptGallery,
    queryGallery,
    queryMarketingSection,
    queryGalleryCollection,
    fetchBannerData,
    queryStyling,
    queryCarousel
};
