/* eslint-disable import/prefer-default-export */

import serviceActionTypes from './Service-ActionTypes';

export const setStoresData = (storesData) => ({
    type: serviceActionTypes.SERVICE_STORES,
    payload: storesData,
});

export const setFavouritesData = (favouritesData) => ({
    type: serviceActionTypes.SERVICE_FAVOURITES,
    payload: favouritesData,
});

export const setFocusStore = (focusStore) => ({
    type: serviceActionTypes.SERVICE_FOCUSSTORE,
    payload: focusStore,
});

export const setChooseStore = (chooseStore) => ({
    type: serviceActionTypes.SERVICE_CHOOSESTORE,
    payload: chooseStore,
});
