/* eslint-disable import/no-cycle */
/* eslint-disable max-len */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { func } from 'prop-types';
import ConnectedAccountBarfly from './AccountBarflyMembership';

import { gqlLoadStores } from '../../../gql/barfly/contentfulBarflyActions';

const SelectStoreLocation = ({ classes, user }) => {
    const [stores, setStores] = useState([]);

    useEffect(() => {
        const searchStores = async () => {
            const stores = await gqlLoadStores();
                setStores(stores);
                return stores;
        }

        searchStores();
    },[])

    return (
        <div>
            <ConnectedAccountBarfly
                contentfulStores={stores}
                user={user}
                classes={classes}
            />
        </div>
    );
};

SelectStoreLocation.propTypes = {
    goToNextPage: func.isRequired,
};

export default SelectStoreLocation;
