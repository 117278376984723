import { Typography, Box, withStyles } from '@material-ui/core';

import { styles } from './CreditCard.styles';
import MasterCard from '../../assets/images/paymentCard.svg';

const CreditCardComponent = ({ maskedCardNumber, classes }) => {
    return (
        <Box className={classes.container}>
            <img
                src={MasterCard}
                alt="The MasterCard logo showing a red and orange circle intersecting."
            />
            <Box className={classes.textContainer}>
                <Box mb={0.5}>
                    <Typography className={classes.cardEndingText}>
                        User card ending
                    </Typography>
                </Box>
                <Typography
                    className={classes.maskedCardText}
                    data-testid="masked-card-text"
                >
                    {maskedCardNumber}
                </Typography>
            </Box>
        </Box>
    );
};

export const CreditCard = withStyles(styles)(CreditCardComponent);
