/* eslint-disable prefer-destructuring */
/* eslint-disable max-len */
// get full date-time selectors
export const getStoresData = (state) => state.service?.stores || [];

export const getFavouritesData = (state) => state.service?.favourites || [];

export const getFocusStore = (state) => state.service?.focusStore || {};

export const getSelectedStore = (state) => state.service.selectedStore || {}

export default {};
