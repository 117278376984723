/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import {
    Button, FormControl, Grid, Input, Typography, withStyles,
} from '@material-ui/core';
import {
    array, bool, func, object,
} from 'prop-types';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import CancelIcon from '@material-ui/icons/Cancel';
import {
    getAddOnsServiceData,
    getLocationData,
    getSelectedDate,
    getSelectedSlot,
    getServicesData,
    getNotesMessage,
    getExtensions,
    getNumberOfGuests,
    getCouponCode,
    getBuyoutLocation,
} from '../../../state/ducks/Booking/Booking-Selectors';
import { getBookingTotal } from 'state/utils/helpers';
import SummaryCard from './Partials/SummaryCard';
import { getDateStringMMDDYY, getTimeFromDate } from '../../../Helpers/dateTime';
import { getSpecialByCode, validatePromoCode } from '../../../api/booking-api';
import restClient from '../../../api/restClient';
import { setCouponCode, setIsEditExtensions } from '../../../state/ducks/Booking/Booking-Actions';

/*
    Displays an Appointment Summary Card with
     - all selected options
     - Option to edit them
     - and estimated total price
*/

const styles = (theme) => ({
    container: {
        backgroundColor: '#fff',
        boxShadow: '2px 2px 46px rgba(235, 235, 235, 0.5)',
        padding: '26px 22px',
        minWidth: '422px',
        margin: '0 15px',
        width: '422px',
        textAlign: 'left',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    },
    fullWidth: {
        backgroundColor: 'transparent',
        padding: '26px 22px',
        margin: '0 15px',
        textAlign: 'left',
    },
    appointmentSummaryTitle: {
        fontSize: '16px',
        fontWeight: '600',
        textAlign: 'center',
        color: '#42413D',
        marginBottom: '15px !important',
    },
    cardsContainer: {
        borderTop: '1px solid #D1D1D1',
    },
    estimateCard: {
        display: 'flex',
        background: '#E5E5E5;',
        alignItems: 'center',
        padding: '22px 16px',
        justifyContent: 'space-between',
        marginTop: '24px',
    },
    price: {
        width: '29%',
        textAlign: 'right',
        fontWeight: 800,
    },
    bigNextButton: {
        width: '100%',
        maxWidth: '100%',
        height: '63px',
        marginBottom: '37px',
        color: '#54575A',
        backgroundColor: '#FFDD30',
        '&:hover': {
            backgroundColor: '#b29a21',
        },
    },
    services: {
        display: 'flex',
        flexDirection: 'row',
    },
    serviceText: {
        // width: '80%',
        marginRight: '15px',
    },
    priceText: {
        fontWeight: 800,
        marginLeft: '10px',
        color: '#42413D',
    },
    userText: {
        minWidth: '74px',
        fontSize: '18px',
    },
    promoCodeContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        margin: '20px 0',
    },
    promoCodeInput: {
        width: '100%',
    },
    applyPromoCode: {
        textTransform: 'none',
        margin: '0',
    },
    appointmentSummCopy: {
        fontSize: '18px',
        fontFamily: 'URWForm',
        color: '#42413',
    },
    displayContent: {
        display: 'flex',
    },
    marginRight: {
        width: '74% !important',
    },
    marginRight84: {
        marginRight: '81% !important',
    },
    couponNotValid: {
        background: '#e65146',
        color: 'white',
        textAlign: 'center',
        padding: '7px !important',
        marginTop: '19px !important',
    },
    couponValid: {
        background: '#4CAF50',
        color: 'white',
        textAlign: 'center',
        padding: '7px !important',
        marginTop: '19px !important',
        display: 'flex',
    },
    // disabled: {
    //     background: "yellow"
    // }
    paidArrivalText: { 
        fontSize: '14px',
        fontWeight: '500',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#777'
    }
});

const AppointmentSummaryContainer = ({
    location,
    locationStore,
    classes,
    nextButtonEnabled,
    onButtonClick,
    selectedServices,
    fullWidth,
    showPromoCode,
    addons,
    slot,
    dateTime,
    extensions,
    estimatedTotal,
    guests,
    setCouponCode,
    couponCode,
    buyoutLocation,
    setIsEditExtensions
}) => {
    // const [estimatedTotal, setEstimatedTotal] = useState(0);
    const [discountAmount, setDiscountAmnt] = useState(couponCode ? couponCode.discountAmount : 0);
    const [discountType, setDiscountType] = useState(couponCode ? couponCode.discountType : 0);
    const [promoMessage, setPromoMessage] = useState('');
    const [isSuccess, setIsSucess] = useState(false);
    const [isFail, setIsFail] = useState(false);
    const isAccountAppointmentDetails = location?.pathname?.includes('appointment-details');
    const [promoValue, setPromoValue] = useState(couponCode ? couponCode.promocode || couponCode.code : '');
    const isAppointmentReview = location?.pathname?.includes('/booking/review');

    const applyPromoCode = async () => {
        let code = promoValue;

        const isRequiredValidation = promoValue && promoValue.startsWith('HUS-');

        if (isRequiredValidation) {
            const validateCode = await restClient.get(validatePromoCode(promoValue));
            if (validateCode.data.error) {
                setPromoMessage(validateCode.data.error.replace("CouponCode", "Promo Code"));
                setIsFail(true);
                setIsSucess(false);
                return;
            } if (!validateCode.data.code) {
                setPromoMessage('Service Issue');
                setIsFail(true);
                setIsSucess(false);
                return;
            }
            code = validateCode.data.code;
        }

        const res = await restClient.post(getSpecialByCode(code, locationStore.bookerLocationId));
        if (res.data.IsSuccess) {
            setCouponCode({
                code,
                promocode: isRequiredValidation ? promoValue : undefined,
                discountAmount: res.data.DiscountAmount,
                discountType: res.data.DiscountType,
                id: res.data.ID,
            });
            setDiscountAmnt(res.data.DiscountAmount);
            setDiscountType(res.data.DiscountType);
            setPromoMessage(isRequiredValidation ? promoValue : res.data.Name);
            setIsSucess(true);
            setIsFail(false);
        } else {
            setPromoMessage(res.data.ArgumentErrors[0].ErrorMessage.replace("CouponCode", "Promo Code"));
            setIsFail(true);
            setIsSucess(false);
        }
    };

    const handlePromoChange = (event) => {
        setPromoValue(event.target.value);
    };

    const cancelPromoCode = () => {
        setIsSucess(false);
        setDiscountAmnt(0);
        setPromoValue('');
    };

    const AddOnSummary = (addOnData) => {
        if (addOnData) {
            return (
                <Typography style={{ display: 'flex' }}>
                    <span className={classes.appointmentSummCopy}>{addOnData.ServiceName}</span>
                    <span className={classes.priceText}>{` (£${addOnData.PriceInfo.Amount || '10'})`}</span>
                </Typography>
            );
        }
        return null;
    };

    const dynamicPrice = discountAmount > 0 ? (discountType ? estimatedTotal * (100 - discountAmount) / 100 : estimatedTotal - ((guests + 1) * discountAmount)) : estimatedTotal;

    return (
        <Grid className={fullWidth ? classes.fullWidth : classes.container}>
            {nextButtonEnabled ? (
                <Button
                    variant="contained"
                    // color="secondary"
                    onClick={onButtonClick}
                    className={classes.bigNextButton}
                >
                    Next
                </Button>
            ) : null}
            <Typography className={classes.appointmentSummaryTitle}>
                Please confirm your details and book your Appointment below.
            </Typography>
            {
                // Location Card
                locationStore?.title
                    ? (
                        <Grid className={classes.cardsContainer}>
                            <SummaryCard editClickPath="/booking/location" heading="Location" classes={classes} isAccountAppointmentDetails={isAccountAppointmentDetails} disableEdit={!!buyoutLocation}>
                                <Typography>
                                    {locationStore.title}
                                </Typography>
                            </SummaryCard>
                        </Grid>
                    ) : null
            }
            {
                // Services Card
                selectedServices?.length
                    ? (
                        <SummaryCard editClickPath="/booking/services" classes={classes} heading={`Service${selectedServices?.length > 1 ? 's' : ''}`} disableEdit={!!buyoutLocation}>
                            {selectedServices?.length > 1 ? selectedServices.map((service) => (
                                <Grid className={classes.services}>
                                    <Typography className={classes.userText}>
                                        {service?.user}
                                    </Typography>
                                    <Grid className={`${classes.services} ${classes.serviceText}`}>
                                        <Typography style={{ display: 'flex' }}>
                                            <span className={classes.appointmentSummCopy}>{service.data?.Name}</span>
                                            <span className={classes.priceText}>{` (£${service.data?.Price?.Amount})`}</span>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            ))
                                : (
                                    <Typography style={{ display: 'flex' }}>
                                        <span className={classes.appointmentSummCopy}>{selectedServices?.[0].data?.Name}</span>
                                        <span className={classes.priceText}>{` (£${selectedServices?.[0]?.data?.Price?.Amount})`}</span>
                                    </Typography>
                                )}
                        </SummaryCard>
                    ) : null
            }
            {
                // Addons card
                addons?.length
                    ? (
                        <SummaryCard editAddOns={() => { setIsEditExtensions(false); }} editClickPath="/booking/addons" classes={classes} heading={`Add-on${addons?.length > 1 ? 's' : ''}`} disableEdit={!!buyoutLocation}>
                            {guests > 0 ? addons.map((addon) => (
                                <Grid className={classes.services}>
                                    <Typography className={classes.userText}>
                                        {addon?.user}
                                    </Typography>
                                    <Grid>
                                        {addon.data.map((addonData) => (
                                            <Grid className={`${classes.services} ${classes.serviceText}`}>
                                                {AddOnSummary(addonData)}
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                            ))
                                : (
                                    addons?.map((addon) => (
                                        <>
                                            {addon.data.map((addonData) => (
                                                <Grid className={`${classes.services} ${classes.serviceText}`}>
                                                    {AddOnSummary(addonData)}
                                                </Grid>
                                            ))}
                                        </>
                                    ))
                                )}
                        </SummaryCard>
                    ) : null
            }
            {
                extensions && extensions !== true && Object.values(extensions).includes(true) && (
                    <SummaryCard editExtensions={() => { setIsEditExtensions(true); }} editClickPath="/booking/addons" classes={classes} heading="Extensions">
                        {
                            Object.keys(extensions).filter((k) => extensions[k]).map((k) => (
                                <Grid className={classes.services} key={k}>
                                    <Typography className={classes.userText}>
                                        {k}
                                    </Typography>
                                    <Grid>
                                        <span className={classes.appointmentSummCopy}>Yes</span>
                                        <span className={classes.priceText}>(£20)</span>
                                    </Grid>
                                </Grid>
                            ))
                        }
                    </SummaryCard>
                )
            }
            {
                extensions === true && (
                    <SummaryCard editExtensions={() => { setIsEditExtensions(true); }} editClickPath="/booking/addons" classes={classes} heading="Extensions">
                        <Grid>
                            <span className={classes.appointmentSummCopy}>Yes</span>
                            <span className={classes.priceText}>(£20)</span>
                        </Grid>
                    </SummaryCard>
                )
            }
            {
                // Date and time
                slot?.Me?.startDateTime?.length && dateTime?.length
                    ? (
                        <SummaryCard classes={classes} editClickPath="/booking/select-date" heading="Date & Time">
                            <Typography>
                                {`${getDateStringMMDDYY(new Date(dateTime))} at ${getTimeFromDate(slot.Me.startDateTime)}`}
                            </Typography>
                        </SummaryCard>
                    )
                    : null
            }
            {(!buyoutLocation || !isAppointmentReview) && (
                <>
                    {
                        showPromoCode ? (
                            <FormControl fullWidth className={classes.promoCodeContainer}>
                                <Input
                                    id="standard-adornment-amount-1"
                                    value={promoValue}
                                    onChange={(event) => handlePromoChange(event)} // this will use in future when data come from API
                                    startAdornment=""
                                    className={classes.promoCodeInput}
                                    placeholder="Promo Code"
                                    disabled={!!isSuccess}
                                    classes={{
                                        disabled: classes.disabled,
                                    }}
                                />
                                <Button className={classes.applyPromoCode} variant="contained" color="secondary" onClick={() => applyPromoCode()}>
                                    Apply
                                </Button>
                            </FormControl>
                        ) : null
                    }

                    <Grid container className={classes.estimateCard}>
                        <Grid item xs={12} className={classes.displayContent}>
                            <Typography className={classes.marginRight}>
                                Estimated Total
                                <div className={classes.paidArrivalText}>(To be paid on arrival)</div>
                            </Typography>
                            <Typography className={classes.price}>
                                {`£${estimatedTotal}`}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} className={classes.displayContent}>
                            <Typography className={classes.marginRight}>
                                Discount
                            </Typography>
                            <Typography className={classes.price}>
                                {discountType ? `${discountAmount}%` : `£${(guests + 1) * discountAmount}`}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} className={classes.displayContent}>
                            <Typography className={classes.marginRight}>
                                Total
                            </Typography>
                            <Typography className={classes.price}>
                                £
                                {dynamicPrice}
                            </Typography>
                        </Grid>
                    </Grid>
                </>
            )}
            {isFail && (
                <Grid className={classes.couponNotValid}>
                    <Typography>{promoMessage}</Typography>
                </Grid>
            )}

            {isSuccess && (
                <Grid className={classes.couponValid}>
                    <Typography style={{ margin: '0 auto' }}>
                        {promoMessage}
                        {' '}
                        Coupon Applied
                    </Typography>
                    <CancelIcon style={{ cursor: 'pointer' }} onClick={() => cancelPromoCode()} />
                </Grid>
            )}
        </Grid>
    );
};

AppointmentSummaryContainer.propTypes = {
    locationStore: object.isRequired,
    classes: object.isRequired,
    nextButtonEnabled: bool.isRequired,
    onButtonClick: func.isRequired,
    selectedServices: array.isRequired,
};

const mapStateToProps = (state) => ({
    locationStore: getLocationData(state),
    services: getServicesData(state),
    selectedServices: getServicesData(state),
    addons: getAddOnsServiceData(state),
    slot: getSelectedSlot(state),
    dateTime: getSelectedDate(state),
    getSavedNoteMessage: getNotesMessage(state),
    extensions: getExtensions(state),
    estimatedTotal: getBookingTotal(state),
    guests: getNumberOfGuests(state),
    couponCode: getCouponCode(state),
    buyoutLocation: getBuyoutLocation(state),
});

const mapDispatchToProps = (dispatch) => ({
    setCouponCode: bindActionCreators(setCouponCode, dispatch),
    setIsEditExtensions: bindActionCreators(setIsEditExtensions, dispatch)
});

const enhance = compose(
    withRouter,
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
);

export default enhance(AppointmentSummaryContainer);
