import config from '../app.config';
import axios from 'axios';
import { useAuth } from 'oidc-react';
import { useHistory } from 'react-router-dom';
import appConfig from '../app.config';
import { User } from "oidc-client"
import { useLocation } from 'react-router-dom'
import { generateRedirectQueryStringForLogin } from 'Helpers/login';

export default function useMoktaAuth() {
    const auth = useAuth();
    const history = useHistory();
    const location = useLocation();

    function getUser() {
        let authUser = auth?.userData;
        if (!authUser) {
            authUser = getUserFromStorage();
        }
        return axios.get(config.apiUrl + '/user/info', {
            headers: {
                'Authorization': 'Bearer ' + authUser?.access_token
            }
        }).then((res) => {
            return res.data;
        }).catch((e) => {
            const redirect = generateRedirectQueryStringForLogin(location.pathname);
            auth.userManager.removeUser().then(() => history.replace('/auth/login' + redirect));
        });
    }

    function sessionCookieReset() {
        return axios.post(config.mokta.authority + '/auth/sessionreset');
    }

    function getUserId() {
        let authUser = auth?.userData;
        if (!authUser) {
            authUser = getUserFromStorage();
        }
        return axios.get(config.apiUrl + '/user/info?lite=true', {
            headers: {
                'Authorization': 'Bearer ' + authUser?.access_token
            }
        }).then((res) => {
            return res.data;
        }).catch((e) => {
            const redirect = generateRedirectQueryStringForLogin(location.pathname);
            auth.userManager.removeUser().then(() => history.replace('/auth/login' + redirect));
        });
    }

    function isAuthenticated() {
        let authUser = auth?.userData;
        if (!authUser) {
            authUser = getUserFromStorage();
        }
        const result = authUser && !authUser.expired;
        return result;
    }

    function getUserFromStorage() {
        const oidcStorage = localStorage.getItem(`oidc.user:${appConfig.mokta.authority}:${appConfig.mokta.client_id}`)
        if (!oidcStorage) {
            return null
        }
    
        return User.fromStorageString(oidcStorage)
    }

    return [isAuthenticated, getUser, getUserId, sessionCookieReset];
}