/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable max-len */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Grid, Typography, Link
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { Hyperlink } from '../../Links/Hyperlink';
import copyRightMock from '../../../../__mocks__/copyRightMock.json';
import { CONTAINER_BREAKPOINT, DESKTOP_BREAKPOINT, TABLET_BREAKPPOINT } from '../../../../Helpers/breakpoints';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '20px 0',
        margin: '0 auto',
        textAlign: 'center',
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'row',
        maxWidth: '1295px',
        width: '1295px',
        [theme.breakpoints.down(CONTAINER_BREAKPOINT)]: {
            maxWidth: '100%',
            width: '100%',
            margin: '0 35px',
        },
        [theme.breakpoints.down('md')]: {
            padding: '30px 0',
            borderTop: '1px solid #D1D1D1',
            flexDirection: 'column',
            margin: 0,
        },
    },
    copyrightText: {
        margin: 'auto',
        fontSize: '11px',
        width: '100%',
        [theme.breakpoints.up(TABLET_BREAKPPOINT + 1)]: {
            textAlign: 'left',
        },
    },
    copyrightLinks: {
        width: '100%',
        textAlign: 'right',
        margin: 'auto',
        [theme.breakpoints.down(TABLET_BREAKPPOINT + 1)]: {
            textAlign: 'center',
            lineHeight: '28px',
        },
    },
    link: {
        fontSize: '11px',
        display: 'inline-block',
        margin: '0 15px !important',
        color: '#42413D',
    },
}));

const date = new Date();

const copyrightFooter = (location) => {
    const classes = useStyles();
    const isCommonFooter = location?.pathname?.includes('booking');
    return (
        <Grid className={classes.container}>
            <Grid>
                <Typography className={classes.copyrightText}>
                    {isCommonFooter ? copyRightMock.copyrightText?.replace('__currentYear__', date.getFullYear()) : copyRightMock.copyrightCommonText?.replace('__currentYear__', date.getFullYear())}
                </Typography>
            </Grid>
            <Grid className={classes.copyrightLinks}>
                <>
                    {isCommonFooter ? copyRightMock.links.map((link, index) => (
                        <Hyperlink key={`common_${index}`} to={link.link + "#"} >
                            <Typography className={classes.link}>
                                {link.title}
                            </Typography>
                        </Hyperlink>
                    )) : null}
                    {copyRightMock.commonLinks.map((link, index) => (
                        <Hyperlink key={`commonlink_${index}`} to={link.link + "#"} >
                            <Typography className={classes.link}>
                                {link.title}
                            </Typography>
                        </Hyperlink>
                    ))}
                    <Hyperlink onClick={()=>Didomi.preferences.show()} >
                        <Typography className={classes.link}>
                            Cookie Preferences
                        </Typography>
                    </Hyperlink>
                </>
            </Grid>
        </Grid>
    );
};

export default withRouter(copyrightFooter);
