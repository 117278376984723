/* eslint-disable no-console */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
    Button,
    Grid,
    Input,
    Typography,
    FormHelperText,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { object } from 'prop-types';

import { getPageLabelFromUrl } from 'utils/helper';
import SectionTitle from '../../../SectionTitle';
import { recoverPassword, resendVerificationEmail } from '../../../../../api/auth-api';
import restClient from '../../../../../api/restClient';
import RecoverAlertDialog from './Partials/RecoverAlert';
import { EMAIL_REGEX } from '../../../../../utils/validation/regex'

const SUCCESS_MSG = 'We have received your password reset request. If your email matches our records we will send you a password reset link in just a second.';

const ACCOUNT_LOCKED_MSG = 'Your account has been locked, please email customer service at enquiry@drybarshops.co.uk';

const EMAIL_REQ_MSG = '* Email is required';

const EMAIL_INVALID_MSG = '* Email is invalid';

const useStyles = (theme) => ({
    requestNoteDetails: {
        float: 'right',
        background: theme.palette.common.white,
        boxShadow: '2px 2px 17px rgba(235, 235, 235, 0.5)',
        margin: 'auto',
        maxWidth: '800px',
        padding: '30px 22px',
        textAlign: 'center',
        height: '286px',
        width: '754px',
    },
    authOperation: {
        fontSize: '18px',
        lineHeight: '45px',
        color: theme.palette.common.white,
        textTransform: 'capitalize',
        background: theme.palette.secondary.main,
        height: '63px',
        width: '378px',
        borderRadius: '0px',
        '&:hover': {
            backgroundColor: 'rgb(58, 60, 62);',
        },
    },
    requestServiceContainer: {
        background: theme.palette.common.lightGrey[3],
        marginBottom: '86px',
    },
    enterYourEmail: {
        width: '100%',
        padding: '7px 0px 0px 0px',
    },
    enterEmailPassword: {
        padding: '29px 0px 0px 0px',
    },
    enterEmailPasswordCopy: {
        float: 'left',
        color: theme.palette.common.lightGrey[4],
    },
    recoverPassword: {
        color: theme.palette.common.lightGrey[4],
        textDecoration: 'underline',
        marginTop: '29px',
        cursor: 'pointer',
    },
    logInWithGoogleFacebook: {
        textAlign: 'center',
    },
    facebookLogin: {
        '& .kep-login-facebook': {
            backgroundColor: theme.palette.common.white,
        },
    },
    visibilityIcon: {
        float: 'right',
        margin: '-33px 0px 0px 0px',
        cursor: 'pointer',
    },
    fieldsContainer: {
        marginBottom: '69px',
    },
    errorMessage: {
        color: '#FF0000',
        textAlign: 'left',
        paddingTop: '5px',
        paddingLeft: '3px',
    },
    backdrop: {
        zIndex: 11,
        color: '#fff',
    },
    modalTitle: {
        textAlign: 'center',
        fontSize: '31px',
        fontFamily: 'DINCondensed-Regular',
        lineHeight: '37px',
        textTransform: 'uppercase',
        fontWeight: '600',
    },
    modalHeader: {
        padding: '30px 24px 15px',
    },
    registerEmail: {
        textAlign: 'center',
        fontSize: '32px',
        color: '#42413D',
    },
    recoverOperation: {
        fontSize: '18px',
        lineHeight: '45px',
        textTransform: 'capitalize',
        height: '63px',
        width: '378px',
        borderRadius: '0px',
        '&:hover': {
            backgroundColor: 'rgb(58, 60, 62);',
            color: '#FFFFFF',
        },
        background: '#F9F9F9',
        margin: '14px 2px 19px 111px',
        border: '1px solid #54575A',
        color: '#54575A',
    },
});

const RecoverPassword = ({
    classes,
}) => {
    const [email, setEmailValue] = useState('');
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState(null);
    const [error, setError] = useState(null);
    const history = useHistory();
    const location = useLocation();

    const handleEmailChange = (e) => {
        setError('');
        setEmailValue(e.target.value);
    };

    const resendActivationEmail = async () => {
        const res = await restClient.post(resendVerificationEmail(email));
        setOpen(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (!email?.length) {
            return setError(EMAIL_REQ_MSG);
        }

        if (!EMAIL_REGEX.test(email)) {
            return setError(EMAIL_INVALID_MSG);
        }

        mParticle.logEvent('Forgot Password', mParticle.EventType.Other, {
            'Source Page': getPageLabelFromUrl(location.pathname),
            Email: email,
        });

        let message = null;

        try {
            await restClient.post(recoverPassword(email));
            message = SUCCESS_MSG;
        } catch (e) {
            if (e.response && e.response.data && e.response.data.status === 404) {
                // does not exist
                message = SUCCESS_MSG;
            } else if (e.response && e.response.data && e.response.data.status === 'PROVISIONED') {
                // not activated
                message = (
                    <>
                        Your account needs to be activated before resetting the password,
                        <a style={{ textDecoration: 'underline', fontWeight: 700, cursor: 'pointer' }} onClick={resendActivationEmail}>please click here</a>
                        {' '}
                        to get an activation email.
                    </>
);
            } else {
                message = ACCOUNT_LOCKED_MSG;
            }
        }
        if (message) {
            setMessage(message);
            setOpen(true);
        }
    };

    const handleClose = () => {
        setOpen(false);
        setMessage(null);
        history.push("/auth/login")
    };

    const displayPlaceholder = (e) => {
        e.target.placeholder = `Your ${e.target.name} here...`;
    };

    const hidePlaceholder = (e) =>{
        e.target.placeholder = "";
    }

    return (
        <>
            <Grid container className={classes.requestServiceContainer}>
                <SectionTitle title="Recover or Change Password" />
                <Grid className={classes.requestNoteDetails}>
                    <Grid className={classes.requestContainer}>
                        <form onSubmit={handleSubmit} className={classes.subscribeEmailForm}>
                            <Grid>
                                <Grid className={classes.fieldsContainer}>
                                    <Typography className={classes.enterEmailPasswordCopy}>Email</Typography>
                                    <Input
                                        classes={{
                                            input: classes.input,
                                        }}
                                        id="standard-adornment-amount"
                                        value={email}
                                        name="email"
                                        onChange={(e) => handleEmailChange(e)}
                                        startAdornment=""
                                        onFocus={displayPlaceholder}
                                        onBlur={hidePlaceholder}
                                        className={classes.enterYourEmail}
                                        error={!!error}
                                    />
                                    <FormHelperText>
                                        {error}
                                    </FormHelperText>
                                </Grid>
                            </Grid>
                            <Button type="submit" className={classes.authOperation} variant="outlined">
                                Recover or Change Password
                            </Button>
                            <Link to="/auth/login"><Typography className={classes.recoverPassword}>Log In</Typography></Link>
                        </form>
                    </Grid>
                </Grid>
                {open
                    && <RecoverAlertDialog openModal={open} classes={classes} onClose={handleClose} message={message} />}
            </Grid>
        </>
    );
};

RecoverPassword.prototype = {
    classes: object.isRequired,
};

export default withStyles(useStyles)(RecoverPassword);
