/* eslint-disable import/no-cycle */
/* eslint-disable max-len */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import {
    CircularProgress, Backdrop,
} from '@material-ui/core';
import ConnectedServiceLocator from './serviceLocator';
import {queryLocationsPageFromContentful} from '../../../gql/queries/screenStoreLocatorCollection';

import { MetaTagWrapper } from '../../../components';
import { doQuery } from '../../../state/utils/contentful';

const SelectStoreLocation = () => {
    const [metadata, setMetadata] = useState({});
    const [loading, setIsLoading] = useState(false);
    const [data, setData] = useState({});

    useEffect(async () => {
        setIsLoading(true);

        const data = await doQuery(queryLocationsPageFromContentful(), true);
        const pageData = data.drybarPageCollection?.items[0];

        setMetadata({
            pageDescription: pageData?.seoDescription,
            pageKeywords: [],
            pageTitle: pageData?.seoTitle,
            searchKeywords: [],
        });

        setData({
            title: pageData?.title,
            slug: pageData?.slug
        })

        setIsLoading(false);
    },[])

    if (loading) {
        return (
            // todo - replace with skeleton
            <Backdrop
                open
                style={{
                    zIndex: 11,
                    color: '#fff',
                }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    }

    if (data) {
        const marketingComponentCollection = data;

        return (
            <div>
                <MetaTagWrapper metadata={metadata} />
                <ConnectedServiceLocator
                    marketingComponentCollection={marketingComponentCollection}
                />
            </div>
        );
    }
    return null;
};

export default (SelectStoreLocation);
