import { Grid, Typography, withStyles } from '@material-ui/core';
import { object, shape, string } from 'prop-types';
import React, { useContext } from 'react';
import { useAuth } from 'oidc-react';
import { Link, withRouter } from 'react-router-dom';
import { TABLET_BREAKPPOINT } from '../../../../Helpers/breakpoints';
import { getCustomer } from 'api/booking-api';
import restClient from 'api/restClient';
import useMoktaAuth from 'utils/oktaAuth';
import { useState, useEffect } from 'react';

const styles = (theme) => ({
    container: {
        flex: 1,
        padding: '34px 22px',
        backgroundColor: '#FFF',
        maxWidth: '422px',
        [theme.breakpoints.down(TABLET_BREAKPPOINT)]: {
            margin: '0 auto',
            maxWidth: '100%',
            width: '100%',
            flex: 'none',
            display: 'flex',
            overflow: 'scroll',
            alignItems: 'flex-end',
            padding: '0',
        },
    },
    menuButton: {
        backgroundColor: '#F9F9F9',
        borderBottom: '1px solid #D1D1D1',
        padding: '14px',
        fontSize: '18px',
        marginBottom: '11px',
        [theme.breakpoints.down(TABLET_BREAKPPOINT)]: {
            background: 'none',
        },
    },
    link: {
        color: '#42413D',
        textDecoration: 'none',
        '&:focus, &:hover, &:visited, &:link, &:active': {
            textDecoration: 'none',
            color: '#42413D',
        },
    },
    selected: {
        fontWeight: '800',
    },
    needHelp: {
        fontSize: '13px',
        margin: '22px 0px 0px 2px',
        [theme.breakpoints.down(TABLET_BREAKPPOINT)]: {
            display: 'none',
        },
    },
    linkFaq: {
        color: '#42413D',
        '&:hover,': {
            color: '#42413D',
            textDecoration: 'underline',
        },
        whiteSpace: 'nowrap',
        fontSize: '13px',
        [theme.breakpoints.down(TABLET_BREAKPPOINT)]: {
            textDecoration: 'none',
            '&:focus, &:hover, &:visited, &:link, &:active': {
                textDecoration: 'none',
            },
            fontSize: '1rem',
        },
    },
    signOut: {
        backgroundColor: '#F9F9F9',
        borderBottom: '1px solid #D1D1D1',
        padding: '14px',
        fontSize: '18px',
        marginBottom: '11px',
        maxWidth: '105px',
        marginTop: '47px',
        cursor: 'pointer',
        [theme.breakpoints.down(TABLET_BREAKPPOINT)]: {
            background: 'none',
            marginTop: '0',
        },
    },
    faqContainer: {
        alignItems: 'center',
        [theme.breakpoints.down(TABLET_BREAKPPOINT)]: {
            background: 'none',
            marginTop: '0',
            borderBottom: '1px solid #D1D1D1',
            padding: '14px',
            fontSize: '1rem',
            marginBottom: '11px',
            maxWidth: '105px',
            cursor: 'pointer',
        },
    },
});

const buttons = [
    {
        label: 'My Appointments',
        path: '/account/my-appointments',
        path_appoint_sub: '/account/appointment-details',
        path_appoint_pro: '/account/appointment-rebook',
    },
    {
        label: 'Manage Cards',
        path: '/account/manage-cards',
        path_appoint_sub: '',
        path_appoint_pro: '',
    },
    {
        label: 'Communication Preferences',
        path: '/account/communication-preferences',
        path_appoint_sub: '',
        path_appoint_pro: '',
    },
    {
        label: 'Account Information',
        path: '/account/information',
        path_appoint_sub: '',
        path_appoint_pro: '',
    },
    {
        label: 'Update Password',
        path: '/account/update-password',
        path_appoint_sub: '',
        path_appoint_pro: '',
    },
    {
        label: 'Barfly Membership',
        path: '/account/barfly-membership',
        path_appoint_sub: '',
        path_appoint_pro: '',
    },
    //    {
    //    label: 'Favourites',
    //    path: '/account/favorites',
    //    path_appoint_sub: '',
    //    path_appoint_pro: '',
    // },
    //     {
    //     label: 'Order History',
    //     path: '/account/order-history',
    //     path_appoint_sub: '',
    //     path_appoint_pro: '',
    // }, {
    //     label: 'Auto Replenishment',
    //     path: '/account/auto-replenishment',
    //     path_appoint_sub: '',
    //     path_appoint_pro: '',
    // }
];

const AccountNav = ({ classes, location }) => {
    const auth = useAuth();
    const [user, setUser] = useState(null);
    const [isAuthenticated, getUser, getUserId] = useMoktaAuth();
    const [customer, setCustomer] = useState(null);

    useEffect(async () => {
        if (!customer && user && user.bookerID) {
            const { data } = await restClient.post(getCustomer(user.bookerID));
            if (data.Customer?.Customer) {
                setCustomer(data.Customer?.Customer);
            }
        } else {
            try {
                const res = await getUserId();
                setUser(res);
            } catch (e) {}
        }
    }, [user]);

    const handleSignOut = () => {
        auth.userManager.signoutRedirect();

        sessionStorage.clear();
        localStorage.clear();
        document.cookie =
            'idsrv.session' +
            '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        document.cookie =
            'idsrv' + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    };

    return (
        <Grid className={classes.container}>
            {buttons.map((button) => (
                <Link
                    hidden={
                        button.label === 'Barfly Membership' &&
                        !(
                            customer?.HasActiveMembership ||
                            customer?.HasMembership
                        )
                    }
                    className={`${classes.link} ${
                        location.pathname === button.path ||
                        location.pathname === button.path_appoint_sub ||
                        location.pathname === button.path_appoint_pro
                            ? classes.selected
                            : ''
                    }`}
                    to={button.path}
                >
                    <Grid className={classes.menuButton}>
                        <Typography style={{ whiteSpace: 'nowrap' }}>
                            {button.label}
                        </Typography>
                    </Grid>
                </Link>
            ))}

            <Grid className={classes.signOut} onClick={() => handleSignOut()}>
                <Typography style={{ whiteSpace: 'nowrap' }}>
                    Sign Out
                </Typography>
            </Grid>
            <Grid container className={classes.faqContainer}>
                <Typography className={classes.needHelp}>
                    Need Help?&nbsp;
                </Typography>
                <Typography>
                    <Link to="/help-centre" className={classes.linkFaq}>
                        <span>View FAQ</span>
                    </Link>
                </Typography>
            </Grid>
        </Grid>
    );
};
AccountNav.propTypes = {
    classes: object.isRequired,
    location: shape({
        pathname: string,
    }).isRequired,
};

export default withRouter(withStyles(styles)(AccountNav));
