/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import axios from 'axios';
import {
    Box, Button, Grid, Typography,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { toast, ToastContainer } from 'react-toastify';

import { strings } from '../../assets/constants';
import { googleAnalyticsEvents } from 'state/utils/googleAnalyticsEvents';
import appConfig from '../../app.config';
import './ContactModal.scss';

const {
    FIRST_NAME,
    LAST_NAME,
    EMAIL,
    ADDRESS1,
    ADDRESS2,
    CITY,
    STATE,
    POSTAL_CODE,
    PHONE_NUMBER,
    PREFERRED_SHOP,
    PREFERRED_START_TIME,
    PREFERRED_DATE,
    PARTY_SIZE,
    NOTES,
    NOTE_PLACEHOLDER,
    SUBMIT,
    OCCASION,
} = strings;
export default class ContactModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            firstNameError: '',
            lastNameError: '',
            emailError: '',
            phoneNumberError: '',
            addressError: '',
            cityError: '',
            regionError: '',
            postalCodeError: '',
            preferredShopError: '',
            preferredStartTimeError: '',
            partySizeError: '',
            showConfirmationDialog: false,
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.validateInput = this.validateInput.bind(this);
    }

    handleClose = () => {
        this.setState((state) => {
            this.props.onClose();
            return { showConfirmationDialog: false};
        })
    }

    handleOpen = () => {
        this.setState((state) => {
            return { showConfirmationDialog: true};
        })
    }

    onSubmit() {
        const validState = {
            ...this.validateInput(this.firstName.value, 'firstNameError'),
            ...this.validateInput(this.lastName.value, 'lastNameError'),
            ...this.validateInput(this.email.value, 'emailError'),
            ...this.validateInput(this.phoneNumber.value, 'phoneNumberError'),
            ...this.validateInput(this.address1.value, 'addressError'),
            ...this.validateInput(this.city.value, 'cityError'),
            ...this.validateInput(this.region.value, 'stateError'),
            ...this.validateInput(this.postalCode.value, 'postalCodeError'),
            ...this.validateInput(this.preferredShop.value, 'preferredShopError'),
            ...this.validateInput(this.preferredStartTime.value, 'preferredStartTimeError'),
            ...this.validateInput(this.preferredDate.value, 'preferredDateError'),
            ...this.validateInput(this.partySize.value, 'partySizeError'),
        };

        if (Object.values(validState).join('')) {
            this.setState(validState);
            return;
        }

        const formData = {};
        formData.Field1 = this.firstName.value;
        formData.Field2 = this.lastName.value;
        formData.Field3 = this.email.value;
        formData.Field10 = this.phoneNumber.value;
        formData.Field4 = this.address1.value;
        formData.Field5 = this.address2.value;
        formData.Field6 = this.city.value;
        formData.Field7 = this.region.value;
        formData.Field8 = this.postalCode.value;
        formData.Field12 = this.preferredShop.value;

        formData.Field29 = this.preferredDate.value;
        formData.Field15 = this.preferredStartTime.value;
        formData.Field17 = this.partySize.value;
        formData.Field19 = this.note.value;
        formData.Field21 = 'Book More Than 4';
        formData.Field25 = this.occasions.value;

        axios.post(appConfig.apiUrl + '/forms/postForms', {
            subDomain: appConfig.wufoo.subDomain,
            formId: appConfig.wufoo.genericPartyFormId,
            key: btoa(`${appConfig.wufoo.apiKey }:password`),
            formData,
        }).then((response) => {
                    this.handleOpen();
                    gtag(googleAnalyticsEvents.EVENT, googleAnalyticsEvents.BOOKING_GROUP,{
                        attendees: this.partySize.value
                    });
            },(error) => console.log('error in wufoo api ', error));
    }

    validateInput(value, stateName) {
        const stateObj = {};
        if (!value) {
            stateObj[stateName] = 'This field should not be empty.';
        } else {
            stateObj[stateName] = '';
        }
        return stateObj;
    }

    render() {
        const {
 preferredShopChoices, preferredStartTimeChoices, partySizeChoices, occasions,
} = this.props;

        return (
            <Grid id="contact-modal">
                <Grid className="modal-container">
                    <Grid className="header position-relative">
                        <Typography className="header-text">
                            Please input contact information.
                        </Typography>
                        <Button onClick={this.props.onClose} style={{ padding: 0 }}>
                            <CloseIcon className="close-icon" />
                        </Button>
                    </Grid>
                    <div className="contact-section">
                        <div className="row">
                            <div className="col-sm-6">
                                <label>{FIRST_NAME}</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    ref={(c) => this.firstName = c}
                                    onChange={(sender) => {
                                           this.setState({ ...this.validateInput(sender.target.value, 'firstNameError') });
                                       }}
                                />
                                {this.state.firstNameError
                                && <p className="error-msg">{this.state.firstNameError}</p>}
                            </div>
                            <div className="col-sm-6">
                                <label>{LAST_NAME}</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    ref={(c) => this.lastName = c}
                                    onChange={(sender) => {
                                           this.setState({ ...this.validateInput(sender.target.value, 'lastNameError') });
                                       }}
                                />
                                {this.state.lastNameError
                                && <p className="error-msg">{this.state.lastNameError}</p>}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <label>{EMAIL}</label>
                                <input
                                    className="form-control"
                                    type="email"
                                    ref={(c) => this.email = c}
                                    onChange={(sender) => {
                                           this.setState({ ...this.validateInput(sender.target.value, 'emailError') });
                                       }}
                                />
                                {this.state.emailError
                                && <p className="error-msg">{this.state.emailError}</p>}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-9">
                                <label>{ADDRESS1}</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    ref={(c) => this.address1 = c}
                                    onChange={(sender) => {
                                           this.setState({ ...this.validateInput(sender.target.value, 'addressError') });
                                       }}
                                />
                                {this.state.addressError
                                && <p className="error-msg">{this.state.addressError}</p>}
                            </div>
                            <div className="col-sm-3">
                                <label>{ADDRESS2}</label>
                                <input className="form-control" type="text" ref={(c) => this.address2 = c} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <label>{CITY}</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    ref={(c) => this.city = c}
                                    onChange={(sender) => {
                                           this.setState({ ...this.validateInput(sender.target.value, 'cityError') });
                                       }}
                                />
                                {this.state.cityError
                                && <p className="error-msg">{this.state.cityError}</p>}
                            </div>
                            <div className="col-sm-6">
                                <label>{STATE}</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    ref={(c) => this.region = c}
                                    onChange={(sender) => {
                                           this.setState({ ...this.validateInput(sender.target.value, 'stateError') });
                                       }}
                                />
                                {this.state.stateError
                                && <p className="error-msg">{this.state.stateError}</p>}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <label>{POSTAL_CODE}</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    ref={(c) => this.postalCode = c}
                                    onChange={(sender) => {
                                           this.setState({ ...this.validateInput(sender.target.value, 'postalCodeError') });
                                       }}
                                />
                                {this.state.postalCodeError
                                && <p className="error-msg">{this.state.postalCodeError}</p>}
                            </div>
                            <div className="col-sm-6">
                                <label>{PHONE_NUMBER}</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    ref={(c) => this.phoneNumber = c}
                                    onChange={(sender) => {
                                           this.setState({ ...this.validateInput(sender.target.value, 'phoneNumberError') });
                                       }}
                                />
                                {this.state.phoneNumberError
                                && <p className="error-msg">{this.state.phoneNumberError}</p>}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4">
                                <label>{PREFERRED_SHOP}</label>
                                <select
                                    className="form-control"
                                    ref={(c) => this.preferredShop = c}
                                    onChange={(sender) => {
                                            this.setState({ ...this.validateInput(sender.target.value, 'preferredShopError') });
                                        }}
                                >
                                    {preferredShopChoices.map((choice) => <option value={choice}>{choice}</option>)}
                                </select>
                                {this.state.preferredShopError
                                && <p className="error-msg">{this.state.preferredShopError}</p>}
                            </div>

                            <div className="col-sm-4">
                                <label>{PARTY_SIZE}</label>
                                <select
                                    className="form-control"
                                    ref={(c) => this.partySize = c}
                                    onChange={(sender) => {
                                        this.setState({ ...this.validateInput(sender.target.value, 'partySizeError') });
                                    }}
                                >
                                    {partySizeChoices.map((choice) => <option value={choice}>{choice}</option>)}
                                </select>
                                {this.state.partySizeError
                                    && <p className="error-msg">{this.state.partySizeError}</p>}
                            </div>

                            <div className="col-sm-4">
                                <label>{OCCASION}</label>
                                <select
                                    className="form-control"
                                    ref={(c) => this.occasions = c}
                                    onChange={(sender) => {
                                        this.setState({ ...this.validateInput(sender.target.value, 'occasionsError') });
                                    }}
                                >
                                    {occasions.map((choice) => <option value={choice}>{choice}</option>)}
                                </select>
                                {this.state.occasionsError
                                    && <p className="error-msg">{this.state.occasionsError}</p>}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <label>
                                    {PREFERRED_DATE}
                                </label>
                                <input
                                    className="form-control"
                                    type="date"
                                    value={this.state.preferredDate}
                                    name="preferredDate"
                                    placeholder="(DD/MM/YYYY)"
                                    min={new Date().toISOString().split('T')[0]}
                                    max="9999-12-31"
                                    ref={(c) => this.preferredDate = c}
                                    onChange={(sender) => {
                                        this.setState({ ...this.validateInput(sender.target.value, 'preferredDateError') });
                                    }}
                                    style={{ marginTop: 15 }}
                                />
                                {this.state.preferredDateError
                                    && <p className="error-msg">{this.state.preferredDateError}</p>}
                            </div>
                            <div className="col-sm-6">
                                <label>{PREFERRED_START_TIME}</label>
                                <select
                                    className="form-control"
                                    ref={(c) => this.preferredStartTime = c}
                                    onChange={(sender) => {
                                        this.setState({ ...this.validateInput(sender.target.value, 'preferredStartTimeError') });
                                    }}
                                >
                                    {preferredStartTimeChoices.map((choice) => <option value={choice}>{choice}</option>)}
                                </select>
                                {this.state.preferredStartTimeError
                                    && <p className="error-msg">{this.state.preferredStartTimeError}</p>}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <label className="emphas">{NOTES.toUpperCase()}</label>
                                <textarea
                                    className="form-control"
                                    placeholder={NOTE_PLACEHOLDER}
                                    rows="5"
                                    ref={(c) => this.note = c}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex mt-3 justify-content-center">
                        <button
                            className="btn-common btn-yellow btn-lg font-weight-bold responsive-500-w-100"
                            onClick={this.onSubmit}
                        >
                            {SUBMIT}
                        </button>
                    </div>
                </Grid>
                <Dialog
                open={this.state.showConfirmationDialog}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                      this.handleClose(event, reason);
                    }
                  }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <div style={{ display: 'flex' }}>
                        <Typography style={{ marginLeft: '204px' }}><Box textAlign="center" fontSize="31px" fontFamily="DINCondensed-Regular" lineHeight="37px" textTransform="uppercase" fontWeight="600" >Success</Box></Typography>
                        <CloseIcon
                            onClick={this.handleClose}
                            style={{
                        margin: '-6px -20px 0px 236px',
                        cursor: 'pointer',
                    }}
                        />
                    </div>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <h5>Request Sent. Someone from Drybar will contact you soon.</h5>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
            </Grid>
            
        );
    }
}
