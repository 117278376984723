import React, { useRef, useEffect, useState } from 'react';
import { Grid, withStyles } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import {
  Configure,
  SearchBox,
  Hits,
  Index,
  Stats,
} from 'react-instantsearch-dom';
import { AlgoliaSearch } from 'components';
import config from 'app.config';
import addressMarker from 'assets/images/activeMarker.svg';
import favoriteMarker from 'assets/images/favoriteMarker.svg';
import UserImg from 'assets/images/AccountInformation.svg';
import StoreMarker from 'assets/images/inactiveMarker.svg';
import VideoPlayBtnImg from 'assets/images/Youtube.svg';
import NoImage from 'assets/images/noimage.png';
import { DESKTOP_BREAKPOINT, MOBILE_BREAKPOINT } from 'Helpers/breakpoints';
import { gqlLoadTheStyleImages } from 'gql/the-styles/contentfulTheStylesActions';
import './SearchMegaMenu.scss';
import { string } from 'prop-types';

const { algolia } = config;
const stylesIndex = algolia.index.style;
const storeIndex = algolia.index.store;
const howtosIndex = algolia.index.video;

const styleType = algolia.searchType.style;
const storeType = algolia.searchType.store;
const videoType = algolia.searchType.video;

const styles = (theme) => ({
    input: {
        width: 'calc(100% - 40px)',
        margin: '0 20px',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            width: 'calc(100% - 40px)',
            margin: '8px 20px',
        },
    },
    searchWrapper: {
        width: '100%',
    },
    search: {
        '&.dropdown-content': {
            display: 'flex',
            color: '#42413D',
            zIndex: '99',
            [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
                marginTop: '60px',
                paddingTop: '20px',
                color: '#989898',
            },
        },
    },
    section: {
        width: '50%',
        padding: '16px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        '& a': {
            display: 'block',
            '&:hover': {
                textDecoration: 'none',
                backgroundColor: '#F9F9F9',
            },
        },
        [theme.breakpoints.down(DESKTOP_BREAKPOINT)]: {
            width: '50%',
        },
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            width: '100%',
            padding: '8px',
        },
    },
    videoSection: {
        [theme.breakpoints.down(768)]: {
            width: '100%',
        },
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        paddingBottom: '8px',
        fontFamily: 'MrsEavesOT-Roman',
        borderBottom: '1px solid #d1d1d1',
    },
    subSection: {
        display: 'block',
        padding: '16px 0',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            display: 'none',
        },
    },
    subSectionMobile: {
        display: 'none',
        padding: '16px 0',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            display: 'block',
            padding: '4px 0',
            width: '100%',
            overflowX: 'auto',
        },
    },
    categoryTitle: {
        fontSize: '20px',
        paddingRight: '8px',
        textTransform: 'uppercase',
        color: '#42413D',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            fontSize: '18px',
        },
    },
    categoryCount: {
        fontSize: '18px',
        color: '#767676',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            fontSize: '16px',
        },
    },
    flexRow: {
        display: 'flex',
        alignItems: 'center',
        padding: '4px 0',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
            margin: '0 4px',
            padding: '4px 0',
        },
    },
    locationWrapper: {
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            alignItems: 'center',
        },
    },
    bgImage: {
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top center',
        backgroundSize: 'cover',
        border: '1.5px solid #E3E3E3',
        marginRight: '16px',
        flexGrow: '0',
        flexShrink: '0',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            marginRight: '0',
        },
    },
    video: {
        flexBasis: '200px',
        flexGrow: '0',
        flexShrink: '0',
        marginRight: '16px',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            flexBasis: '95px',
            margin: '2px',
        },
    },
    itemTitle: {
        fontSize: '18px',
        fontWeight: '600',
        color: '#42413D',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            fontSize: '13px',
            paddingTop: '4px',
        },
    },
    itemContent: {
        fontSize: '15px',
    },
    locationAddress: {
        fontSize: '15px',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            fontSize: '14px',
        },
    },
    price: {
        fontSize: '15px',
        fontWeight: '600',
    },
    ellipsis: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': 1,
        '-webkit-box-orient': 'vertical',
    },
    ellipsis4: {
        '-webkit-line-clamp': 4,
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            '-webkit-line-clamp': 2,
        },
    },
    link: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#42413D',
        fontSize: '20px',
        [theme.breakpoints.down(DESKTOP_BREAKPOINT)]: {
            display: 'none',
        },
    },
    linkContainer:{
        marginTop:"20px"
    },
    anchor: {
        color: '#42413D !important',
    },
    anchorBorder: {
        fontSize: '20px',
        color: '#007bff',
        '&:hover': {
            textDecoration: 'none',
            borderColor: 'black',
            color: '#0056b3',
        },
    },
});

function useOursideClicker(ref, handleVisible) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        handleVisible(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}

function SearchMegaMenu({ classes, className, handleVisible }) {
    const history = useHistory();
    const wrapperRef = useRef(null);
    useOursideClicker(wrapperRef, handleVisible);

    const [searchValue, setSearchValue] = useState('');
    const [styles, setStyles] = useState([]);
    const [styleHits, setStyleHits] = useState(0);
    const [locationHits, setLocationHits] = useState(0);
    const [productHits, setProductHits] = useState(0);
    const [videosHits, setVideosHits] = useState(0);

    useEffect(async () => {
        const data = await gqlLoadTheStyleImages();
        setStyles(data);
    }, []);

    const Title = ({ classes, title}) => (
        <div className={classes.header}>
            <span className={classes.categoryTitle}>{title}</span>
        </div>
    );

    const chopDescription = (description) => {
        if(description.length > 150){
           return `${description.substring(0,150).trim()}...`;
        }
        else{
            return description;
        }
    }

    const StyleItem = (props, classes, width = 117, isMobile = false) => {
        const hit = props?.hit;
        if (!hit || !hit.hasOwnProperty('styleThumbnail') || searchValue.trim().length < 2) return null;

        const title = hit.styleName;
        let image = hit.styleThumbnail;
        if (!image) {
            styles.forEach((item) => {
                if (item.title === title) image = item.featuredImage;
            });
        }
        const slug = hit.styleUrl;
        const description = hit.styleDescription

        return (
            <a className={classes.anchor} href={`/the-styles/#${slug}`}>
                <div className={classes.flexRow}>
                    <div
                        className={classes.bgImage}
                        style={{
                                backgroundImage: `url('${image}'), url('${UserImg}')`,
                                width,
                                height: width,
                            }}
                    />
                    <div>
                        <strong className={`${classes.itemTitle} ${classes.ellipsis}`}>{title}</strong>
                        {<div className={classes.itemContent}>{chopDescription(description)}</div>}
                    </div>
                </div>
            </a>
        );
    };

    const LocationItem = (props, classes, width, isMobile = false) => {
        const hit = props?.hit;
        if (!hit || !hit.hasOwnProperty('locationAddress') || searchValue.trim().length < 2 || hit.locationStatus==="disabled") return null;

        const {
 street1, city, postalcode
} = hit.locationAddress;
        const image = hit.locationUrl;
        const title = hit.locationName;
        const address = `${street1} ${city} ${postalcode}`;
        const openHours = null;
        return (
            <a className={classes.anchor} href={image}>
                <div className={`${classes.flexRow} ${classes.locationWrapper}`} style={{ flexDirection: 'row' }}>
                    <div
                        className={classes.bgImage}
                        style={{
                            backgroundImage: `url('${favoriteMarker}')`,
                            flexBasis: width,
                            width,
                            height: isMobile ? width + 10 : width + 20,
                            border: isMobile ? 'none' : 'inherit',
                        }}
                    />
                    <div style={{ marginLeft: isMobile ? '8px' : '0' }}>
                        {title && <div className={`${classes.itemTitle} ${classes.ellipsis}`}>{title}</div>}
                        {address && <div className={`${classes.locationAddress} ${classes.ellipsis}`}>{address}</div>}
                        {openHours && <div className={`${classes.itemContent} ${classes.ellipsis}`}>{openHours}</div>}
                    </div>
                </div>
            </a>
        );
    };

    // const VideoItem = (props, classes, width, height) => {
    //     const hit = props?.hit;
    //     if (!hit
    //         || !hit._tags?.length
    //         || hit._tags[0] !== 'video'
    //         || searchValue.trim().length < 2) {
    //             return null;
    //         }

    //     const previewImg = hit.image_url;
    //     const title = hit.name;
    //     const description = hit.short_description;
    //     const link = hit.url;
    //     return (
    //         <a className={classes.anchor} href={link}>
    //             <div className={classes.flexRow}>
    //                 <div
    //                     className={`d-flex align-items-center justify-content-center ${classes.bgImage}`}
    //                     style={{
    //                         backgroundImage: `url('${previewImg}'), url('${NoImage}')`,
    //                         width,
    //                         height,
    //                     }}
    //                 >
    //                     <img src={VideoPlayBtnImg} width={50} alt="video play" />
    //                 </div>
    //                 <div>
    //                     <div className={`${classes.itemTitle} ${classes.ellipsis}`}>{title}</div>
    //                     <div className={`${classes.itemContent} ${classes.ellipsis} ${classes.ellipsis4}`}>
    //                         {description}
    //                     </div>
    //                 </div>
    //             </div>
    //         </a>
    //     );
    // };

    //const ProductItem = (props, classes, width, isMobile) => {
    //    const hit = props?.hit;
    //    if (!hit || !hit.hasOwnProperty('price') || searchValue.trim().length < 2) return null;

    //    const image = hit.image_link;
    //    const price = hit.price.replace("GBP","").replace(" ","");
    //    const { description, title } = hit;
    //    const slug = hit.link;
    
    //    return (
    //        <a className={classes.anchor} href={slug} target='_blank'>
    //            <div className={classes.flexRow}>
    //                <div
    //                    className={classes.bgImage}
    //                    style={{
    //                        backgroundImage: `url('${image}'), url('${NoImage}')`,
    //                        flexBasis: width,
    //                        width,
    //                        height: width,
    //                    }}
    //                />
    //                <div>
    //                    <div className={`${classes.itemTitle} ${classes.ellipsis}`}>
    //                        {title}
    //                    </div>
    //                    <div className={classes.price}>
    //                        {`\u00A3 ${price}`}
    //                    </div>
    //                    <div className={`${classes.itemContent} ${classes.ellipsis} ${classes.ellipsis4}`}>
    //                        {chopDescription(description)}
    //                    </div>
    //                </div>
    //            </div>
    //        </a>
    //    );
    //};

    const LinkItem = ({ classes, link, text }) => (
        <div className={classes.link}>
            <Link className={` ${classes.anchorBorder}`} to={link} target="_parent">{text}</Link>
        </div>
    );

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        if(e.target[0]?.value.length > 1)
        {
            history.push({
                pathname: '/search-result',
                state: { searchValue: e.target[0]?.value },
            });
            handleVisible(false);
        }
    };

    const handleSearchChange = (e) => {
        setSearchValue(e.target.value);
    };

    return (
        <AlgoliaSearch indexName={stylesIndex}>
            <div ref={wrapperRef} className={classes.searchWrapper}>
                <Configure hitsPerPage={3} />
                <SearchBox
                    autoFocus
                    onSubmit={handleSearchSubmit}
                    // onReset={() => handleVisible(false)}
                    onChange={handleSearchChange}
                    className="searchMegaMenu-input"
                />
                <div className={`${className}`}>
                    <Grid className={`mega-menu-section dropdown-content ${classes.search}`} container>
                        <Grid
                            item
                            className={classes.section}
                            data-result_element_type={styleType}
                        >
                            <div>
                                <Title classes={classes} title={`Styles (${styleHits})`}></Title>
                                <div className={classes.subSection}>
                                    <Index indexName={stylesIndex}>
                                        <Hits hitComponent={(props) => StyleItem(props, classes, 117)} />
                                        <Stats
                                    translations={{
                                        stats(nbHits) {setStyleHits(nbHits > 0 && searchValue.trim().length > 1 ? 
                                        `${nbHits.toLocaleString()}`: 0)
                                        }}}/>
                                        
                                    </Index>
                                </div>
                                <div className={classes.subSectionMobile}>
                                    <Index indexName={stylesIndex}>
                                        <Hits hitComponent={(props) => StyleItem(props, classes, 105, true)} />
                                    </Index>
                                </div>
                            </div>
                            <Grid className={classes.linkContainer}>
                                <LinkItem
                                    classes={classes}
                                    link="/the-styles"
                                    text="View All Styles"
                                />
                            </Grid>
                        </Grid>

                        <Grid
                            item
                            className={classes.section}
                            data-result_element_type={storeType}
                        >
                            <div>
                                <Title classes={classes} title={`Locations (${locationHits})`}/>
                                <div className={classes.subSection}>
                                    <Index indexName={storeIndex}>
                                        <Hits hitComponent={(props) => LocationItem(props, classes, 60)} />
                                        <Stats
                                    translations={{
                                        stats(nbHits) {setLocationHits(nbHits > 0 && searchValue.trim().length > 1 ? 
                                        `${nbHits.toLocaleString()}`: 0)
                                        }}}/>
                                    </Index>
                                </div>
                                <div className={`locationSectionMobile ${classes.subSectionMobile}`}>
                                    <Index indexName={storeIndex}>
                                        <Hits hitComponent={(props) => LocationItem(props, classes, 20, true)} />
                                    </Index>
                                </div>
                            </div>
                            <Grid className={classes.linkContainer}>
                                <LinkItem
                                    classes={classes}
                                    link="/service/locator"
                                    text="View All Locations"
                                />
                            </Grid>
                        </Grid>

                        {/* <Grid
                            item
                            className={`${classes.section} ${classes.videoSection}`}
                            data-result_element_type={videoType}
                        >
                            <div>
                                <Title classes={classes} title={`How to videos (${videosHits})`} />
                                <div className={classes.subSection}>
                                    <Index indexName={howtosIndex}>
                                        <Hits hitComponent={(props) => VideoItem(props, classes, '200px', '112px')} />
                                           <Stats
                                    translations={{
                                        stats(nbHits) {setVideosHits(nbHits > 0 && searchValue.trim().length > 1? 
                                        `${nbHits.toLocaleString()}`: 0")
                                        }}}/>
                                    </Index>
                                </div>
                                <div className={`videoSectionMobile ${classes.subSectionMobile}`}>
                                    <Index indexName={howtosIndex}>
                                        <Hits hitComponent={(props) => VideoItem(props, classes, '170px', '102px')} />
                                    </Index>
                                </div>
                            </div>
                            <Grid className={classes.linkContainer}>
                                {/* <LinkItem
                                    classes={classes}
                                    link="/dry-styling"
                                    text="View Videos"
                                /> }
                            </Grid>
                        </Grid> */}

                        {/*<Grid*/}
                        {/*    item*/}
                        {/*    className={`${classes.section} ${classes.productSection}`}*/}
                        {/*    data-result_element_type={productType}*/}
                        {/*>*/}
                        {/*    <div>*/}
                        {/*        <Title classes={classes} title={`Products (${productHits})`}/>*/}
                        {/*        <div className={classes.subSection}>*/}
                        {/*            <Index indexName={productsIndex}>*/}
                        {/*                <Hits hitComponent={(props) => ProductItem(props, classes, '117px')} />*/}
                        {/*                <Stats*/}
                        {/*            translations={{*/}
                        {/*                stats(nbHits) {setProductHits(nbHits > 0 && searchValue.trim().length > 1 ? */}
                        {/*                `${nbHits.toLocaleString()}`:0)*/}
                        {/*                }}}/>*/}
                        {/*            </Index>*/}
                        {/*        </div>*/}
                        {/*        <div className={classes.subSectionMobile}>*/}
                        {/*            <Index indexName={productsIndex}>*/}
                        {/*                <Hits hitComponent={(props) => ProductItem(props, classes, '105px', true)} />*/}
                        {/*            </Index>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*    <Grid className={classes.linkContainer}>*/}
                        {/*        {<div className={classes.link}>*/}
                        {/*            <a*/}
                        {/*                className={classes.anchorBorder}*/}
                        {/*                href="https://www.harrods.com/en-gb/shopping/drybar"*/}
                        {/*                target="_blank"*/}
                        {/*            >*/}
                        {/*                View All Products*/}
                        {/*            </a>*/}
                        {/*        </div>}*/}
                        {/*    </Grid>*/}
                        {/*</Grid>*/}
                    </Grid>
                </div>
            </div>
        </AlgoliaSearch>
    );
}

export default withStyles(styles)(SearchMegaMenu);
