/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { object } from 'prop-types';
import { Typography, Grid } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-magic-slider-dots/dist/magic-dots.css';
import BookAddOnsServicesCollection from './partials/BookAddOnsServicesCollection';
import addonBanner from '../../../../../../assets/images/addon-banner.png';
// import addOnAd from '../../../../../../assets/images/add-on-ad.png';
import {
    doQuery,
    getPreviewMode,
} from '../../../../../../state/utils/contentful';

const ScreenServicesAndAddOnsCollection = ({
    classes,
    screenServicesAddOnsData,
    children,
}) => {
    const [marketingCard, setMarketingCard] = useState({});

    const addOnsCollection = screenServicesAddOnsData || [];
    const addOnsDescription =
        addOnsCollection?.description?.json?.content?.[0]?.content || [];
    const bookAddOnsCollection =
        addOnsCollection?.productsCollection?.items || [];
    const { marketingComponentsCollection } = screenServicesAddOnsData;

    console.log(marketingComponentsCollection);

    const marketingCardId = marketingComponentsCollection?.items[1]?.sys?.id;
    const marketingCardQuery = `{
        marketingCardCollection(${getPreviewMode()}, where: {sys: {id: "${marketingCardId}"}}) {
            items {          
              actionsCollection{
                items{
                  linkToUrl
                  style
                }
              }          
            }
          }    
    }`;
    useEffect(() => {
        if (marketingCardId) {
            doQuery(marketingCardQuery).then((data) => {
                setMarketingCard(data?.marketingCardCollection?.items?.[0]);
            });
        }
    }, [marketingCardId]);
    const action = marketingCard?.actionsCollection?.items?.[0];

    return (
        <Grid className={classes.container}>
            <Grid className={classes.bannerContainer}>
                <img
                    src={
                        marketingComponentsCollection?.items[0]?.desktopMedia
                            ?.url || addonBanner
                    }
                    className={classes.banner}
                    alt={
                        marketingComponentsCollection?.items[0]
                            ?.alternateTitle || ''
                    }
                />
            </Grid>
            {children}
            <Typography className={classes.addOnsTitle} component="h1">
                {addOnsCollection.title}
            </Typography>
            <Grid className={classes.addOnsContainer}>
                <Typography className={classes.headerSubTitle}>
                    {addOnsDescription?.[0]?.value}
                </Typography>
                <Typography
                    className={`${classes.addOnsTreatmentOffer} ${classes.fontWeight}`}
                >
                    {addOnsDescription?.[1]?.value}
                </Typography>
            </Grid>
            <Grid className={classes.borderAddOns} />
            <BookAddOnsServicesCollection
                bookAddOnsData={bookAddOnsCollection}
                classes={classes}
            />
            <Grid className={classes.advertiseContainer}>
                <Grid container>
                    <Grid>
                        <Grid container className={classes.advertiseImgWrap}>
                            {action?.style == 'Wrapper' &&
                            action?.linkToUrl != '' ? (
                                <a href={action.linkToUrl}>
                                    <img
                                        src={
                                            marketingComponentsCollection
                                                ?.items[1]?.image?.desktopMedia
                                                ?.url ||
                                            marketingComponentsCollection
                                                ?.items[1]?.desktopMedia?.url
                                        }
                                        className={classes.advertiseImg}
                                        alt={
                                            marketingComponentsCollection
                                                ?.items[1]?.image
                                                ?.alternateTitle || ''
                                        }
                                    />
                                </a>
                            ) : (
                                <img
                                    src={
                                        marketingComponentsCollection?.items[1]
                                            ?.image?.desktopMedia?.url ||
                                        marketingComponentsCollection?.items[1]
                                            ?.desktopMedia?.url
                                    }
                                    className={classes.advertiseImg}
                                    alt={
                                        marketingComponentsCollection?.items[1]
                                            ?.image?.alternateTitle || ''
                                    }
                                />
                            )}
                        </Grid>
                        {/*     <Grid container className={classes.blowoutsBtnWrap}>*/}
                        {/*        <a className={`btn-common btn-gray-trans btn-lg ${classes.blowoutsBtn}`}>*/}
                        {/*            Shop Blowouts*/}
                        {/*        </a>*/}
                        {/*    </Grid> */}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

ScreenServicesAndAddOnsCollection.propTypes = {
    screenServicesAddOnsData: object.isRequired,
    classes: object.isRequired,
};

export default withRouter(ScreenServicesAndAddOnsCollection);
