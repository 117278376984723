import { createHttpLink } from 'apollo-link-http';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import appconfig from 'app.config';

const CONTENTFUL_SPACE = process.env.REACT_APP_SPACE_ID;
const CONTENTFUL_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || 'master';
const CONTENTFUL_PREVIEW = process.env.REACT_APP_PREVIEW_MODE || false;
const CONTENTFUL_TOKEN = CONTENTFUL_PREVIEW ? process.env.REACT_APP_PREVIEW_TOKEN : process.env.REACT_APP_ACCESS_TOKEN;

const baseGraphQLClient = () => {
    const link = createHttpLink({
        uri: appconfig.contentUrl + `/v2/graphql`,
        headers: {
            Authorization: `Bearer ${CONTENTFUL_TOKEN}`,
        },
    });

    const cache = new InMemoryCache();
    const apolloClient = new ApolloClient({
        link,
        cache,
    });
    return { apolloClient };
};

export default baseGraphQLClient;
