/* eslint-disable import/no-cycle */
/* eslint-disable max-len */
/* eslint-disable no-console */
import React, { useCallback, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import {
    CircularProgress,
    Backdrop,
    Typography,
    Grid,
    FormControl,
    MenuItem,
    Select,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
    screenAddOnsCollections as ScreenAddOnsCollection,
    blowServicesPageQuery,
    fetchBannerData,
    fetchItems,
    fetchGenericBannerData
} from '../../../gql/queries/screenAddOnsCollection';
import ConnectedScreenServices from '../common/partials/ScreenAddOnsServices/ScreenServicesAddOns';
import { getStores } from '../../../state/ducks/Barfly/Barfly-Selectors';
import { getSelectedStore } from 'state/ducks/Service/Service-Selectors';
import { setChooseStore } from '../../../state/ducks/Service/Service-Actions';
import { loadStoresContentful } from '../../../state/ducks/Barfly/Barfly-Actions';
import { useFetchAsync } from '../../../Helpers/useFetch';
import { doQuery } from '../../../state/utils/contentful';
import { getServices } from '../../../api/booking-api';
import { MetaTagWrapper } from '../../../components';
import appconfig from 'app.config';
import './GraphqlServiceCollections.scss';

const SERVICES_SLUG = 'services';

const ServiceLocatorContainer = ({
    selectedStore,
    loadStoresFromContentful,
    stores,
    chooseStore,
}) => {
    const [searchKey, setSearchKey] = useState('');
    const [matchedStores, setMatchedStores] = useState([]);
    const [error, setError] = useState(null);
    const [screenProducts, setScreenProducts] = useState(null);
    const [services, setServices] = useState(null);
    const [storeType, setStoreType] = useState('Drybar Shop');

    const [metadata, setMetadata] = useState(null);
    const [newLoading, setIsLoading] = useState(false);
    const [newData, setData] = useState(null);

    // const SCREEN_ADD_ONS_QUERY = ScreenAddOnsCollection();
    // const { data: queryData, error: queryError, loading } = useQuery(
    //     SCREEN_ADD_ONS_QUERY
    // );

    useEffect(() => {
        loadStoresFromContentful({});
    }, []);

    useEffect(async () => {
        setIsLoading(true);

        const data = await doQuery(blowServicesPageQuery(), true);
        const pageData = data.drybarPageCollection?.items?.[0];

        setMetadata({
            pageDescription: pageData?.seoDescription,
            pageKeywords: [],
            pageTitle: pageData?.seoTitle,
            searchKeywords: [],
        });

        const firstLevelIds = pageData.formatofPageCollection?.items?.map(
            (item) => item.sys.id
        );

        let banner = {};
        let items = {};
        let genericBanner = {};

        if (firstLevelIds.length > 0) {
            banner = await doQuery(fetchBannerData(firstLevelIds[0]), true);

            items = await doQuery(fetchItems(firstLevelIds[1]), true);

            genericBanner = await doQuery(fetchGenericBannerData(firstLevelIds[2]), true);
        }

        items = items?.drybarPictureGrid?.drybarGridCardsCollection?.items?.map(
            (item) => {
                return {
                    title: item.title,
                    subtitle: item.subtitle,
                    price: item.price,
                    serviceTime: item.serviceTime,
                    productId: process.env.REACT_APP_DRYBAR_ENV === 'production' ? item?.productId : item?.sandboxProductId,
                    slug: item.slugPrefix,
                    description: {
                        json: {
                            content: [
                                {
                                    content: [
                                        {
                                            marks: [],
                                            value: item.description,
                                            nodeType: 'text',
                                        },
                                    ],
                                    nodeType: 'paragraph',
                                },
                            ],
                            nodeType: 'document',
                        },
                    },
                    bestFor: item.bestFor?.join(', '),
                    imagesCollection: {
                        items: item?.imagesCollectionCollection?.items?.map(
                            (image) => {
                                return {
                                    desktopMedia: {
                                        url: image.desktopImageUrl,
                                    },
                                    mobileMedia: {
                                        url: image.mobileImageUrl,
                                    },
                                };
                            }
                        ),
                    },
                };
            }
        );

        const finalData = {
            slug: pageData.slug,
            title: pageData.title,
            description: {
                json: {
                    content: [
                        {
                            content: [
                                {
                                    marks: [],
                                    value: pageData.subtitle,
                                    nodeType: 'text',
                                },
                            ],
                            nodeType: 'paragraph',
                        },
                    ],
                    nodeType: 'document',
                },
            },
            marketingComponentsCollection: {
                items: [
                    {
                        desktopMedia: {
                            url:
                                banner?.heroBanner?.mediaCollection?.items?.[0]
                                    ?.desktopImageUrl,
                        },
                        mobileMedia: {
                            url:
                                banner?.heroBanner?.mediaCollection?.items?.[0]
                                    ?.mobileImageUrl,
                        },
                    },
                    {
                        desktopMedia: {
                            url:
                                genericBanner?.genericBanner?.mediaCollection?.items?.[0]
                                    ?.desktopImageUrl,
                        },
                        mobileMedia: {
                            url:
                                genericBanner?.genericBanner?.mediaCollection?.items?.[0]
                                    ?.mobileImageUrl,
                        },
                    },
                ],
            },
            productsCollection: {
                items: items,
            },
        };

        setServices(finalData);
        setScreenProducts(finalData);

        setData(finalData);

        setIsLoading(false);
    }, []);

    // If there has not been a store selected previously then KB is selected as a default.
    useEffect(async () => {
        if (selectedStore?.title === undefined) {
            chooseStore(stores[0]);
        }
    }, [stores]);

    // search store - replaced with a dropdown selection as UK does not have many stores yet.
    // const onSearchStore = async () => {
    //     const geolocated = await geolocateSearchLocation(searchKey);
    //     const { data, error } = await findStoresFromPointWithTitle(geolocated, storeType, searchKey);
    //     if (data) {
    //         if (data.length) setSearchKey('');
    //         setMatchedStores(data);
    //     } else {
    //         setError('No matching stores found.');
    //     }
    // };

    // const handleKeyUp = (e) => {
    //     if (e.keyCode === 13) {
    //         onSearchStore();
    //     }
    // };

    // const handleKeyChange = (e) => {
    //     if (error) setError(null);
    //     setSearchKey(e.target.value);
    // };

    // const handleCloseModal = () => {
    //     setMatchedStores([]);
    // };

    //The CMS does not hold store specific data - the price is fetched from booker. The service ids and then the titles (as a fallback) from the CMS are
    // filtered against those from booker to find the price for each service. However the CMS includes "the" in its titles
    // so this must be ignored to match against the titles in Booker which do not have "the". If there is no matching title
    // then the CMS data is used as a fallback meaning there will be no store specific pricing displayed.
    const onRefreshServices = useCallback(async () => {
        const storeLocationId =
            selectedStore?.bookerLocationId ||
            appconfig.booker.defaultLocationId;
        const { data, error } = await useFetchAsync(
            getServices(storeLocationId)
        );

        if (data?.IsSuccess) {
            const bookerServices = data?.Treatments;

            const bookerServiceNames = bookerServices.map((s) =>
                (s.Name || '').toLocaleLowerCase().trim()
            );
            const bookerServiceIds = bookerServices.map((s) => `${s.BrandTreatmentID}`);

            setScreenProducts({
                ...services,
                productsCollection: {
                    ...services.productsCollection,
                    items: services.productsCollection.items.map((item) => {
                        let idx = bookerServiceIds.indexOf(item.productId);

                        if (idx < 0) {
                            idx = bookerServiceNames.indexOf(
                                (item.title || '')
                                    .toLocaleLowerCase()
                                    .replace('the', '')
                                    .trim()
                            );
                        }

                        if (idx >= 0) {
                            return {
                                ...item,
                                price:
                                    bookerServices[idx].Price?.Amount ||
                                    item.price,
                                serviceTime:
                                    bookerServices[idx].TotalDuration ||
                                    item.serviceTime,
                            };
                        }

                        return item;
                    }),
                },
            });
        }
    }, [selectedStore, services, setScreenProducts]);

    // useEffect(async () => {
    //     if (queryData) {
    //         const serviceData = queryData?.screenProductCollection?.items?.filter((item) => item.slug === SERVICES_SLUG);

    //         console.log(serviceData)

    //         setServices(serviceData.length ? serviceData[0] : null);
    //         setScreenProducts(serviceData.length ? serviceData[0] : null);
    //         // const metadataId = serviceData[0]?.metadata?.sys?.id || null;
    //         // if (metadataId) {
    //         //     const data = await getMetaData(metadataId);
    //         //     if (data) setMetadata(data);
    //         // }
    //     }
    // }, [queryData, setServices, setScreenProducts]);

    useEffect(() => {
        if (selectedStore && services) {
            onRefreshServices();
        }
    }, [selectedStore, services, onRefreshServices]);

    if (newLoading) {
        return (
            // todo - replace with skeleton
            <Backdrop
                open
                style={{
                    zIndex: 11,
                    color: '#fff',
                }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    }

    if (screenProducts) {
        return (
            <div style={{ maxWidth: 1295, margin: '0 auto' }}>
                <MetaTagWrapper metadata={metadata} />
                <ConnectedScreenServices
                    screenServicesAddOnsData={screenProducts}
                >
                    <Grid className="blow-services">
                        <Typography className="store-search-header">
                            Select your shop below! Prices vary by location.
                        </Typography>
                        <Grid className="preferred-store-container d-flex flex-column">
                            <Grid className="d-flex align-items-center selected-store">
                                <Typography className="label">
                                    Store Selected:
                                </Typography>
                                <Typography className="value">
                                    {selectedStore.title}
                                </Typography>
                            </Grid>
                            <FormControl>
                                <Select
                                    required={true}
                                    onChange={(e) => {
                                        chooseStore(e.target.value);
                                    }}
                                >
                                    {stores.map((store) => (
                                        <MenuItem value={store}>
                                            <Grid className="store-item">
                                                <Grid
                                                    container
                                                    direction="column"
                                                    className="store-item-inner"
                                                >
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        alignItems="center"
                                                        className="store-title-row"
                                                    >
                                                        <Grid
                                                            xs={7}
                                                            className="d-flex align-items-start"
                                                        >
                                                            <span className="store-title">
                                                                {store.title}
                                                            </span>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justify="flex-strat"
                                                        className="store-location-row"
                                                    >
                                                        <Grid
                                                            xs={7}
                                                            className="d-flex"
                                                            direction="row"
                                                            justify="flex-start"
                                                        >
                                                            <span className="store-location">
                                                                {`${store.contact?.street1}, ${store.contact?.city}, ${store.contact?.postalCode}`}
                                                            </span>
                                                        </Grid>
                                                    </Grid>
                                                    {/* <Grid className="d-flex">
                                                        <span className="store-information">
                                                            {store.information}
                                                        </span>
                                                    </Grid> */}
                                                </Grid>
                                            </Grid>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            {/* <Grid className="search-row d-flex flex-row align-items-end">
                               <TextField
                                    className="search-field-height"
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                               <SearchIcon style={{ fontSize: '30px' }} />
                                          </InputAdornment>
                                      ),
                                   }}
                                    value={searchKey}
                                   placeholder="City, State or Zip"
                                   inputProps={{ 'aria-Label': 'Search city, state or zip' }}
                                    onChange={handleKeyChange}
                                   onKeyUp={handleKeyUp}
                                   error={!!error}
                                />
                                <Button
                                    className="search-button"
                                    variant="outlined"
                                    onClick={onSearchStore}
                               >
                                   Search
                               </Button>
                            </Grid> */}

                            {/* {error && (
                               <Grid>
                                    <p className="error-msg">{error}</p>
                           </Grid>)} */}
                        </Grid>
                    </Grid>
                    {/*     <StoreChooseDialog
                        open={matchedStores.length}
                        handleClose={handleCloseModal}
                        stores={matchedStores}
                    /> */}
                </ConnectedScreenServices>
            </div>
        );
    }
    return null;
};

//NB the selectedStore from the State is specific to Services.
// Not to be confused with selectedStore from the State that is specific to Barfly.
const mapStateToProps = (state) => ({
    selectedStore: getSelectedStore(state),
    stores: getStores(state),
});

const mapDispatchToProps = (dispatch) => ({
    chooseStore: bindActionCreators(setChooseStore, dispatch),
    loadStoresFromContentful: bindActionCreators(
        loadStoresContentful,
        dispatch
    ),
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ServiceLocatorContainer)
);
