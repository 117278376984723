import { gql } from '@apollo/client';
import { getPreviewMode } from '../../state/utils/contentful';

const productAddOnCollections = (productID) => `{
    productCollection(${getPreviewMode()}, where: {productId: "${productID}"}) {
        items {
          type
          productId
          title
          serviceTime
          price
          description {
            json
          }
          bestFor
          imagesCollection(limit: 10) {
            items {
              alternateTitle
              desktopMedia {
                url
              }
            }
          }
        }
      }
  }
`;

export const productionInformationByReference = (bookerReference) => `
  {
    productCollection(where: {bookerReference: "${bookerReference}"}) {
        items {
          type
          productId
          title
          serviceTime
          price
          description {
            json
          }
          bestFor
          imagesCollection(limit: 10) {
            items {
              desktopMedia {
                url
              }
              mobileMedia {
                url
              }
            }
          }
        }
      }
  }
`;

export const getProductInfoFromBooker = (id) => `
{
  drybarGenericGridCardCollection(where: { OR: [{ productId: "${id}" }, { sandboxProductId: "${id}" }] }) {
    items {
      title
      productId
      sandboxProductId
      subtitle
      serviceTime
      price
      bestFor
      description
      imagesCollectionCollection {
        items {
          desktopImageUrl
          mobileImageUrl
        }
      }
    }
  }
}`

export default productAddOnCollections;
