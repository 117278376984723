import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { Link } from '@material-ui/core';

/**
 * A generalised hyperlink that determines the link type that is required by checking
 * the protocol in the URL. If http:// or https:// is used then the component renders
 * and external link which defaults to a new tab. Otherwise the component renders an
 * internally routed SPA link. This is driven by the fact that the MaterialUI Link and
 * HashRouter links need to be combined. This link type is the only link type that should 
 * be used.
 * 
 * @param {Object} props
 * @param {string} props.to the URL
 * @param {string} props.target the target for the URL
 * @param {string} props.key the React key
 * @param {string} props.className the React className field
 *  
 * @returns {JSX.Element} a external/internal hyperlink
 */
export const Hyperlink = ({
    children = "", 
    to = "/", 
    target = undefined, 
    className = undefined, 
    scroll = undefined, 
    onClick= undefined, 
    newkey = undefined, 
    style = undefined
}) => {
    const external = /^(http(s?)):\/\//i.test(to) ? true : false;
    const hash = /[#]/i.test(to) ? true : false;
    const props = {};
    let scrollOnClick = scroll;
    if(newkey !== undefined){
        props["key"] = newkey;
    }
    if(className !== undefined){
        props["className"] = className;
    }
    if(style !== undefined){
        props["style"] = style;
    }
    if(external){
        props["target"] = target === undefined ? '_blank' : target;
        props["href"] = to
    } else {
        props["target"] = target === undefined ? '_self' : target;
        props["component"] = HashLink;
        props["to"] = to;

        if(!hash && !scrollOnClick){
            scrollOnClick = () => {window.scrollTo(0, 0)};
        } 
    }

    props["onClick"] = () => {
        if(onClick){
            onClick();
        }
        if(scrollOnClick){
            scrollOnClick();
        }
    };

    return <Link {...props}>{children}</Link>
}
