import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Typography } from '@material-ui/core';
import { resendVerificationEmail } from '../../../../../../api/auth-api';
import restClient from '../../../../../../api/restClient';

const RegistrationSuccess = ({ openModal, onClose, classes, createdEmail }) => {

    const recoverEmailHandler = () => {
        restClient.post(resendVerificationEmail(createdEmail));

    };

    return (
        <div>
            <Dialog
                open={openModal}
                //onClose={onClose}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                      onClose(event, reason);
                    }
                  }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" className={classes.modalTitle}>
                    <Typography className={classes.modalTitle}>Check your email</Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Please check your email at the address below and follow the instructions to verify your account.
                        If you did not receive an email or it expired
                        you can resend one.
                    </DialogContentText>
                    <Typography className={classes.registerEmail} >{createdEmail}</Typography>
                </DialogContent>
                <div className={classes.dialogActions}>
                    <Button type="submit" className={classes.recoverOperation} variant="outlined" onClick={() => {recoverEmailHandler();}}>
                        Resend my verification email
                    </Button>
                </div>
                <div className={classes.dialogActions}>
                    <Button type="submit"  className={classes.recoverOperation} variant="outlined" onClick={() => {onClose();}}>
                        I received my verification email
                    </Button>
                </div>
            </Dialog>
        </div>
    );
};

export default RegistrationSuccess;
