import { combineReducers } from 'redux';
import account from '../ducks/Account/Account-Reducer';
import booking from '../ducks/Booking/Booking-Reducer';
import events from '../ducks/Events/Events-Reducer';
import faq from '../ducks/Faq/Faq-Reducer';
import home from '../ducks/Home/Home-Reducer';
import franchising from '../ducks/Franchising/Franchising-Reducer';
import dryStyling from '../ducks/DryStyling/Dry-Styling-Reducer';
import theStyles from '../ducks/TheStyles/TheStyles-Reducer';
import barfly from '../ducks/Barfly/Barfly-Reducer';
import buyout from '../ducks/Buyout/Buyout-Reducer';
import resy from '../ducks/Resy/Resy-Reducer';
import subscribe from '../ducks/Subscribe/Subscribe-Reducer';
import service from '../ducks/Service/Service-Reducer';
import modal from '../ducks/Modal/Modal-Reducer';
import auth from '../ducks/Auth/Auth-Reducer';
import worldpay from '../ducks/Worldpay/Worldpay-Reducer';

export default combineReducers({
    account,
    booking,
    events,
    faq,
    home,
    franchising,
    dryStyling,
    theStyles,
    barfly,
    buyout,
    service,
    modal,
    auth,
    resy, 
    subscribe,
    worldpay
});
