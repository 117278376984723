import React, { useEffect, useState } from 'react';
import { Button, Grid, Link, withStyles } from '@material-ui/core';
import { object } from 'prop-types';
import Media from 'react-media';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import { useLocation } from 'react-router-dom';
import { sanitize } from 'dompurify';
import useMoktaAuth from 'utils/oktaAuth';
import { Hyperlink } from '../Links/Hyperlink';
import { getPageLabelFromUrl } from 'utils/helper';
import { TABLET_BREAKPPOINT } from '../../../Helpers/breakpoints';
import { doQuery, getPreviewMode } from '../../../state/utils/contentful';

import AboveHeader from './Partials/AboveHeader';
// import megaInShopAvatar from '../../../assets/images/mega-inshop.png';
// import megaStylesAvatar from '../../../assets/images/mega-styles.png';
// import ConnectedCommonLinks from '../Footer/Common/CommonLinks';

import logo from '../../../assets/images/mainLogo.svg';
import logoSmall from '../../../assets/images/logoSmall.svg';
import mobileMenuIcon from '../../../assets/images/mobile-menu.svg';
import './Header.scss';
import sessionStorage from 'redux-persist/es/storage/session';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setAuthUserDetails } from 'state/ducks/Account/Account-Actions';
import { getOktaUserInfo } from 'state/ducks/Account/Account-Selectors';

const styles = (theme) => ({
    logo: {
        marginTop: '-39px',
        height: '114px',
        margin: 'auto',
        [theme.breakpoints.down(TABLET_BREAKPPOINT)]: {
            margin: '-20px auto -10px',
            width: '200px',
        },
    },

    navbar: {
        margin: '18px auto 10px auto',
        maxWidth: '800px',
        justifyContent: 'space-between',
        display: 'flex',
        width: '100%',
        minHeight: 31,
    },
    underNavbar: {
        backgroundColor: '#FFDD30',

        width: '100%',
        textAlign: 'center',
    },
    logoLink: {
        display: 'inline-flex',
        [theme.breakpoints.down(TABLET_BREAKPPOINT)]: {
            width: '100%',
            overflow: 'hidden',
        },
    },
    divider: {
        height: '5px',
        borderBottom: '1px solid #D6D6D6',
        boxShadow: '0 4px 2px -1px #eee',
        marginBottom: '35px',
    },
});

const Header = ({ classes, config, authUserInfo, setAuthUserInfo }) => {
    const location = useLocation();

    const [sidebarWidth, setSidebarWidth] = useState('0');
    const [mainMarginLeft, setMainMarginLeft] = useState('0');
    const [styleMegaShow, setStyleMegaShow] = useState(false);
    const [shopMegaShow, setShopMegaShow] = useState(false);
    // const [globalConfig, setGlobalConfig] = useState({});
    const [activeRoute, setActiveRoute] = useState('');

    const [isAuthenticated, getUser] = useMoktaAuth();

    // const globalConfigQuery = `{
    //                   channelResourceCollection(${getPreviewMode()}, where: {slug: "resource-global"}) {
    //                     items {
    //                         sys {
    //                             id
    //                         }
    //                         configuration
    //                     }
    //                 }
    //             }`;

    // useEffect(() => {
    //     doQuery(globalConfigQuery).then((data) => {
    //         const channelResourceItem =
    //             data?.channelResourceCollection?.items || [];
    //         if (channelResourceItem && channelResourceItem.length > 0) {
    //             let config = channelResourceItem[0].configuration || {};
                
    //             setGlobalConfig(config);
    //         }
    //     });
    // }, []);

    useEffect(() => {
        if (authUserInfo?.name) {
            return;
        }

        let authd = isAuthenticated();
        if (authd) {
            getUser().then((info) => {
                setAuthUserInfo(info);
            });
        }
    }, []);

    useEffect(() => {
        const currentRoute = location.pathname.split('/')[1];
        if (currentRoute === 'service' || currentRoute === 'help-centre') {
            if (location.pathname.split('/')[2] === 'locator')
                setActiveRoute('locations');
            else setActiveRoute('in-shop');
        }
        if (currentRoute === 'the-styles') setActiveRoute('the styles');
        if (currentRoute === 'events') setActiveRoute('events');
        if (currentRoute === 'barfly-membership') setActiveRoute('membership');
        if (currentRoute === 'franchising') setActiveRoute('franchising');
    }, [location]);

    const desktopDropdownMenu = (classes, nav, visible, setVisible) => {
        const megaOpacity = visible ? 1 : 0;
        const megaZ = visible ? 10000 : -10000;

        const onLinkClick = (label, parentLabel = null) => {
            mParticle.logEvent(
                'Navigation - Link Clicked',
                mParticle.EventType.Navigation,
                {
                    'Source Page': getPageLabelFromUrl(location.pathname),
                    Link: label,
                    'Link Location': parentLabel,
                }
            );
            setVisible(false);
        };

        return (
            <div
                className="mega-dropdown"
                onMouseEnter={() => setVisible(true)}
                onMouseLeave={() => setVisible(false)}
                key={nav.label}
            >
                {nav.label.toLowerCase() === 'in-shop' ? (
                    <div
                        className={`navLink dropbtn${
                            nav.label.toLowerCase() === activeRoute
                                ? ' active'
                                : ''
                        }`}
                    >
                        {window.location.href.indexOf('instore') == -1
                            ? nav.label
                            : ''}
                    </div>
                ) : (
                    <Hyperlink
                        className={`navLink dropbtn${
                            nav.label.toLowerCase() === activeRoute
                                ? ' active'
                                : ''
                        }`}
                        to={nav.link}
                        onClick={() => onLinkClick(nav.label, 'Menu')}
                    >
                        {window.location.href.indexOf('instore') == -1
                            ? nav.label
                            : ''}
                    </Hyperlink>
                )}
                <Grid
                    className="mega-menu-section dropdown-content"
                    style={{ zIndex: megaZ, opacity: megaOpacity }}
                >
                    <div className="d-flex justify-content-center">
                        <div className="d-block pr-100-px sub-menu-section">
                            <h5 className="mega-title">{nav.label}</h5>
                            {nav.SubMenu && nav.SubMenu2 ? (
                                <div className="">
                                    <div className="inline-menu-section">
                                        {nav.SubMenu.map((item, index) => (
                                            <Hyperlink
                                                key={`submenu_${index}`}
                                                newkey={`submenu_${index}`}
                                                className="navLink"
                                                to={item.link}
                                                onClick={() =>
                                                    onLinkClick(
                                                        item.label,
                                                        nav.label
                                                    )
                                                }
                                            >
                                                {item.label}
                                            </Hyperlink>
                                        ))}
                                    </div>
                                    <div className="inline-menu-section">
                                        {nav.SubMenu2.map((item, index) => {
                                            if (!item.separator) {
                                                return (
                                                    <Hyperlink
                                                        key={`submenu2_${index}`}
                                                        newkey={`submenu2_${index}`}
                                                        className="navLink"
                                                        to={item.link}
                                                        onClick={() =>
                                                            onLinkClick(
                                                                item.label,
                                                                nav.label
                                                            )
                                                        }
                                                    >
                                                        {item.label}
                                                    </Hyperlink>
                                                );
                                            }
                                            return (
                                                <div
                                                    className="menu-separator"
                                                    key={`submenu2_${index}`}
                                                />
                                            );
                                        })}
                                    </div>
                                </div>
                            ) : (
                                nav.SubMenu.map((submenu, index) => (
                                    <Hyperlink
                                        key={`submenu_${index}`}
                                        newkey={`submenu_${index}`}
                                        className="navLink"
                                        to={submenu.link}
                                        onClick={() =>
                                            onLinkClick(
                                                submenu.label,
                                                nav.label
                                            )
                                        }
                                    >
                                        {submenu.label}
                                    </Hyperlink>
                                ))
                            )}
                        </div>
                        <div className="d-block">
                            {nav.SubMenuImage && (
                                <img src={nav.SubMenuImage} alt="Mega Logo" />
                            )}
                        </div>
                    </div>
                </Grid>
            </div>
        );
    };

    const mobileDropDownMenu = (nav) => {
        const onLinkClick = (label, parentLabel = null) => {
            mParticle.logEvent(
                'Navigation - Link Clicked',
                mParticle.EventType.Navigation,
                {
                    'Source Page': getPageLabelFromUrl(location.pathname),
                    Link: label,
                    'Link Location': parentLabel,
                }
            );
            setMainMarginLeft('0');
            setSidebarWidth('0');
        };
        return (
            <React.Fragment key={nav.label}>
                <button
                    className="dropdown-btn"
                    onClick={(event) => {
                        onDropDownClick(event.target);
                    }}
                >
                    {nav.label}
                    <i className="fa fa-caret-down" />
                    <i className="fa fa-caret-up" />
                </button>
                <div className="dropdown-container">
                    {nav?.SubMenu?.map((item, index) => (
                        <Hyperlink
                            key={`sub_${index}-${nav.label}`}
                            newkey={`sub_${index}-${nav.label}`}
                            to={item.link}
                            onClick={() => onLinkClick(item.label, nav.label)}
                        >
                            {item.label}
                        </Hyperlink>
                    ))}
                    {nav?.SubMenu2?.map((item, index) => {
                        if (item.separator) {
                            return (
                                <div
                                    key={`sub2_${index}-${nav.label}`}
                                    className="menu-separator"
                                />
                            );
                        }
                        return (
                            <Hyperlink
                                key={`sub2_${index}-${nav.label}`}
                                newey={`sub2_${index}-${nav.label}`}
                                to={item.link}
                                onClick={() =>
                                    onLinkClick(item.label, nav.label)
                                }
                            >
                                {item.label}
                            </Hyperlink>
                        );
                    })}
                </div>
            </React.Fragment>
        );
    };

    function onDropDownClick(target) {
        let buttonElement = target;
        if (target.classList.contains('fa')) {
            buttonElement = target.parentElement;
        }
        buttonElement.classList.toggle('active');
        const dropdownContent = buttonElement.nextElementSibling;
        if (dropdownContent.style.display === 'block') {
            dropdownContent.style.display = 'none';
        } else {
            dropdownContent.style.display = 'block';
        }
    }

    const onMenuLinkClick = (label) => {
        mParticle.logEvent(
            'Navigation - Link Clicked',
            mParticle.EventType.Navigation,
            {
                'Source Page': getPageLabelFromUrl(location.pathname),
                Link: label,
                'Link Location': 'Menu',
            }
        );
    };

    return (
        <Grid className="header-container">
            <AboveHeader config={config} />

            {/* Mobile sidebar */}
            <div
                id="mySidebar"
                className="sidebar"
                style={{ width: sidebarWidth }}
            >
                {isAuthenticated() ? (
                    <div className="sidebar-header">
                        <PersonOutlineOutlinedIcon
                            className={classes.icon}
                            style={{ height: '26px' }}
                        />
                        <span>
                            {authUserInfo?.name
                                ? `hi, ${authUserInfo.name}!`
                                : ''}
                        </span>
                        <Hyperlink
                            to={'/account/information'}
                            onClick={() => {
                                setMainMarginLeft('0');
                                setSidebarWidth('0');
                            }}
                        >
                            My Account
                        </Hyperlink>
                    </div>
                ) : (
                    <div className="sidebar-header">
                        <PersonOutlineOutlinedIcon
                            className={classes.icon}
                            style={{ height: '26px' }}
                        />
                        <Hyperlink
                            to={'/auth/login'}
                            onClick={() => {
                                setMainMarginLeft('0');
                                setSidebarWidth('0');
                            }}
                        >
                            Login or Sign Up
                        </Hyperlink>
                    </div>
                )}
                <span
                    className="closebtn"
                    onClick={() => {
                        setMainMarginLeft('0');
                        setSidebarWidth('0');
                    }}
                >
                    &times;
                </span>
                {config?.header?.siteNavigation.map((nav, index) =>
                    nav?.SubMenu ? (
                        mobileDropDownMenu(nav)
                    ) : (
                        <Hyperlink
                            key={`submenu4_${index}`}
                            newkey={`submenu4_${index}`}
                            to={nav.link + '#'}
                            target={nav.newTab ? '_blank' : ''}
                            onClick={() => {
                                setMainMarginLeft('0');
                                setSidebarWidth('0');
                            }}
                        >
                            {window.location.href.indexOf('instore') == -1
                                ? nav.label
                                : ''}
                        </Hyperlink>
                    )
                )}
                {/* <div className="support-section"> */}
                {/*    <span className="title">CUSTOMER SUPPORT</span> */}
                {/*    <div className="divider"/> */}
                {/*    <span className="phone-number">(877) 379-2279</span> */}
                {/*    <span className="open-date-time">Monday-Friday, 9am - 5pm PST</span> */}
                {/*    <Link href="#">EMAIL US</Link> */}
                {/*    <Link href="#">FIND A DRYBAR LOCATION</Link> */}
                {/*    <div className="divider"/> */}
                {/*    <ConnectedCommonLinks/> */}
                {/* </div> */}
            </div>

            <Media query={{ maxWidth: TABLET_BREAKPPOINT }}>
                {(matches) =>
                    matches ? (
                        <Grid
                            className="mobileHeader"
                            id="main"
                            style={{ marginLeft: mainMarginLeft }}
                        >
                            <Button
                                className="mobileMenuButton"
                                onClick={() => {
                                    setMainMarginLeft('250px');
                                    setSidebarWidth('250px');
                                }}
                            >
                                {window.location.href.indexOf('instore') ==
                                -1 ? (
                                    <img
                                        alt="Drybar"
                                        src={mobileMenuIcon}
                                        className="menuIcon"
                                    />
                                ) : null}
                            </Button>
                            <Hyperlink
                                className={classes.logoLink}
                                to={
                                    window.location.href.indexOf('instore') ==
                                    -1
                                        ? '/'
                                        : false
                                }
                            >
                                <img
                                    className={classes.logo}
                                    src={logoSmall}
                                    alt="Drybar"
                                />
                            </Hyperlink>
                        </Grid>
                    ) : (
                        <>
                            <Hyperlink
                                className={classes.logoLink}
                                to={
                                    window.location.href.indexOf('instore') ==
                                    -1
                                        ? '/'
                                        : false
                                }
                            >
                                <img
                                    className={classes.logo}
                                    src={config?.logo || logo}
                                    alt="Drybar"
                                />
                            </Hyperlink>

                            <Grid className={classes.navbar}>
                                {config?.header?.siteNavigation.map(
                                    (nav, index) =>
                                        nav.SubMenu ? (
                                            desktopDropdownMenu(
                                                classes,
                                                nav,
                                                index === 0
                                                    ? shopMegaShow
                                                    : styleMegaShow,
                                                index === 0
                                                    ? setShopMegaShow
                                                    : setStyleMegaShow
                                            )
                                        ) : (
                                            <Hyperlink
                                                key={`desktopnav_${index}`}
                                                newkey={`desktopnav_${index}`}
                                                to={nav.link}
                                                target={
                                                    nav.newTab ? '_blank' : ''
                                                }
                                                className={`navLink${
                                                    nav.label.toLowerCase() ===
                                                    activeRoute
                                                        ? ' active'
                                                        : ''
                                                }`}
                                                onClick={(e) =>
                                                    onMenuLinkClick(nav.label)
                                                }
                                            >
                                                {/** This is the link for Events, Membership, Gift Cards, Locations */}
                                                {window.location.href.indexOf(
                                                    'instore'
                                                ) == -1
                                                    ? nav.label
                                                    : ''}
                                            </Hyperlink>
                                        )
                                )}
                            </Grid>
                        </>
                    )
                }
            </Media>
            {config?.globalBanners && (
                <Grid
                    style={
                        window.location.href.indexOf('instore') == -1
                            ? config.globalBanners.style
                            : null
                    }
                    className={
                        window.location.href.indexOf('instore') != -1
                            ? 'underNavbar'
                            : null
                    }
                    dangerouslySetInnerHTML={
                        window.location.href.indexOf('instore') == -1
                            ? {
                                  __html: sanitize(
                                      config?.globalBanners.content,
                                      {
                                          ALLOWED_TAGS: ['p', 'q', 'b', 'a'],
                                          ALLOWED_ATTR: ['style', 'href'],
                                      }
                                  ),
                              }
                            : null
                    }
                />
            )}

            {/* <Grid className="underNavbar">
            Your Safety Comes First! We’ve put special measures in place in our shops.
            <a href="/help-centre/drybar-covid-19-safety-update"
               style={{paddingLeft: '5px', color: 'rgba(0, 0, 0, 0.87)', textDecoration: 'underline'}}>Learn More</a>
        </Grid> */}
        </Grid>
    );
};

Header.propTypes = {
    classes: object.isRequired,
    config: object.isRequired,
};

const mapStateToProps = (state) => ({
    authUserInfo: getOktaUserInfo(state),
});

const mapDispatchToProps = (dispatch) => ({
    setAuthUserInfo: bindActionCreators(setAuthUserDetails, dispatch),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(Header));
