/* eslint-disable react/prop-types */
import { Button, TextField, withStyles, InputAdornment } from '@material-ui/core';
import {
    Visibility as VisibilityIcon,
    VisibilityOff as VisibilityOffIcon,
} from '@material-ui/icons';
/* eslint-disable react/jsx-props-no-spreading */
import React, {useRef, useState} from 'react';


const styles = () => ({
    textField: {
        width: '100%',
        color: '#979797',
        marginBottom: '27px',
    },
    textInput: {
        padding: '10px 0',
        fontSize: '20px',
    },
    disabled: {
        borderBottom: 'none',
        '&:before': {
            borderBottom: 'none',
            borderBottomStyle: 'none !important',
        },
    },
    button: {
        textTransform: 'none',
    },
});

const EditableTextField = (props) => {
    const {
            classes, disabled, btnAction, buttonLabel, type, ...others
            } = props;
    const nameRef = useRef(null);

    const focusRef = () => {
        nameRef.current.focus();
    };

    const handleHideShow = () => {
        setHideInputValue(!hideInputValue);
    }

    const [hideInputValue, setHideInputValue] = useState(type === 'password')


    return (
        <TextField
            {...props}
            inputRef={nameRef}
            className={classes.textField}
            type={type === 'password' ? (hideInputValue ? 'password' : 'text') : type}
            InputProps={{
                classes: {
                    root: classes.inputRoot,
                    disabled: classes.disabled,
                    input: classes.textInput,
                },
                endAdornment: (!disabled ? ( type==='password' ? 
                <InputAdornment position="start">
                    {hideInputValue ? 
                    <VisibilityIcon className={classes.visibilityIcon} onClick={() => handleHideShow()} /> : 
                    <VisibilityOffIcon className={classes.visibilityIcon} onClick={() => handleHideShow()} />}
                </InputAdornment> :
                <Button
                    variant="outlined"
                    className={classes.button}
                    onClick={() => {
                        if (btnAction) {
                            focusRef();
                            btnAction();
                        }
                        else{
                            focusRef();
                        }
                    }}
                >
                    {buttonLabel || 'Edit'}
                </Button>
                ) : null),
            }}
        />
    );
};

export default withStyles(styles)(EditableTextField);
