/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import {
    Backdrop,
    CircularProgress,
    Grid,
    withStyles,
} from '@material-ui/core';
import useMoktaAuth from 'utils/oktaAuth';
import { node, object, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getPageLabelFromUrl } from 'utils/helper';
import SectionTitle from '../../../app/Components/SectionTitle';
import accountRoutes from '../../../routes/accountRoutes';
import AccountNav from './AccountNavigation/AccountNav';
import ConnectedDesktopFooter from '../../../app/Components/Footer/CommonFooter/CommonFooter';
import ConnectedDesktopHeader from '../../../app/Components/Header/Header';
import TopGreetingRow from './TopGreetingRow';
import { setAuthUserDetails } from '../../../state/ducks/Account/Account-Actions';
import { getOktaUserInfo } from '../../../state/ducks/Account/Account-Selectors';
import { doQuery, getPreviewMode } from '../../../state/utils/contentful';
import { MOBILE_BREAKPOINT } from '../../../Helpers/breakpoints';
import { withOktaAuth } from '@okta/okta-react';
import useNavigation from '../../../Helpers/useNavigation';

const styles = (theme) => ({
    root: {
        backgroundColor: '#F9F9F9',
        maxWidth: '1200px',
        margin: 'auto',
        padding: '0 0 86px 0',
        // position: 'relative'
    },
    rightSection: {
        flex: 3,
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            width: '100%',
        },
    },
    containerTitle: {
        marginTop: '-24px',
    },
    fullWidth: {
        maxWidth: '100%',
    },
    rowFlexContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    backdrop: {
        zIndex: 11,
        color: '#fff',
    },
});

const AccountWrapper = ({
    classes,
    title,
    children,
    location,
    setAuthUserInfo,
    oktaUserInfo,
}) => {
    // todo replace with useFetch and caching
    const [loading, setLoading] = useState(true);
    const [pwaConfig, setPWAConfig] = useState({});
    const [isAuthenticated, getUser] = useMoktaAuth();

    useEffect(async () => {
        const config = await useNavigation();

        setPWAConfig(config);

        mParticle.logEvent(
            'Account - View Section',
            mParticle.EventType.Navigation,
            {
                'Source Page': getPageLabelFromUrl(location.pathname),
                'Account Section': getPageLabelFromUrl(location.pathname),
            }
        );
    }, []);

    useEffect(async () => {
        setLoading(true);
        await getUser().then((res) => {
            setLoading(false);
            setAuthUserInfo(res);
        });
    }, []);

    if (loading || !oktaUserInfo) {
        return (
            // todo - replace with skeleton
            <Backdrop className={classes.backdrop} open>
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    }

    if (oktaUserInfo) {
        return (
            <>
                <ConnectedDesktopHeader config={pwaConfig} />
                <TopGreetingRow />
                <Grid className={classes.root}>
                    <Grid className={classes.rowFlexContainer}>
                        <Grid style={{ flex: 1 }} />
                        <SectionTitle
                            style={{ flex: 3 }}
                            title={
                                title ||
                                accountRoutes.find((route) =>
                                    location.pathname.includes(route.path)
                                ).label
                            }
                        />
                    </Grid>
                    <Grid className={classes.rowFlexContainer}>
                        {/* Summary Panel on right */}
                        <AccountNav />
                        {/* Left Section */}
                        <Grid className={classes.rightSection}>
                            {typeof children === 'function'
                                ? children({ user: oktaUserInfo })
                                : children}
                        </Grid>
                    </Grid>
                </Grid>
                <ConnectedDesktopFooter config={pwaConfig} />
            </>
        );
    }
    return null;
};

AccountWrapper.propTypes = {
    classes: object.isRequired,
    title: string,
    children: node.isRequired,
};

AccountWrapper.defaultProps = {
    title: '',
};

const mapStateToProps = (state) => ({
    oktaUserInfo: getOktaUserInfo(state),
});

const mapDispatchToProps = (dispatch) => ({
    setAuthUserInfo: bindActionCreators(setAuthUserDetails, dispatch),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withStyles(styles)(withOktaAuth(AccountWrapper))));

const getFooter = (id) => `
{
    drybarSiteNavigation(preview: true, id: "${id}") {
        imageUrl
        slugPrefix
        menuLinksCollection {
          items {
            ... on DrybarSiteNavigation {
              heading
              slugPrefix
              body
              menuLinksCollection {
                items {
                  ... on DrybarNavigationLinkL1 {
                    label
                    footerColumn
                    externalUrl
                  }
                }
              }
            }
          }
        }
      }
}`;

const getNavigators = () => `
{
    drybarSiteNavigationCollection(preview: true) {
        items {
            sys {
                id
            }
            slugPrefix
        }
    }
}`;
