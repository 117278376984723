import { gql } from '@apollo/client';
import {
    getPreviewMode,
    getScreenCollectionPreviewMode,
} from '../../state/utils/contentful';

const storeCollectionContainer = () => gql`{
  drybarLocationCollection(${getScreenCollectionPreviewMode()}, skip: 0, limit: 1000) {
    items {
      title
      number
      bookerLocationId
      type
      information
      contact
      slug
      settings
      arrivalInformation
      storefrontImage {
        internalName
        title
        alternateTitle
        caption
        desktopMedia {
          title
          description
          contentType
          fileName
          size
          url
          width
          height
        }
        mobileMedia {
          title
          description
          contentType
          fileName
          size
          url
          width
          height
        }
      }
    }
  }
}`;

export default storeCollectionContainer;
