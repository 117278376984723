import { MOBILE_BREAKPOINT } from "../../../Helpers/breakpoints";

export const styles = (theme) => ({
  container: {
    backgroundColor: "#fff",
    padding: "36px 22px",
    marginLeft: "15px",
    height: "100%",
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      backgroundColor: "#f9f9f9",
      margin: "0 !important",
      padding: "0 20px 20px",
      width: "100%",
    },
  },
  heading: {
    fontWeight: "800",
    marginBottom: "75px !important",
    paddingBottom: "15px !important",
    borderBottom: "1px solid #D1D1D1",
    color: "#42413D",
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      margin: "20px 0 10px !important",
      textTransform: "uppercase",
      fontFamily: "MrsEavesOT-Roman",
      fontWeight: "normal",
    },
  },
  cardsContainer: {
    position: "relative",
    paddingBottom: "8px",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "nowrap",
      overflowY: "auto",
    },
  },
  cardContainer: {
    marginBottom: '56px'
  },
  cardDetailsContainer: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "32px",
  },
  expiryDateLabel: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.common.grey,
    fontSize: "14px",
    marginBottom: "8px",
  },
  expiryNumberContainer: {
    marginBottom: "8px",
  },
  expiryDateText: {
    display: "inline",
    marginRight: "24px !important",
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.common.grey,
    fontSize: "14px",
  },
  expiredText: {
    display: "inline",
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.common.red,
    fontSize: "14px",
  },
  labelRootDefaultCard: {
    marginBottom: '0',
  },
  defaultCardLabel: {
    fontFamily: theme.typography.fontFamily,
    fontSize: "12px",
  },
  barflyPaymentCheckbox: {
    marginBottom: "12px",
  },
  labelRootBarflyPayment: {
    marginBottom: '0',
  },
  tickSize: {
    transform: "scale(0.75)",
  },
  deleteButtonContainer: {
    display: "flex",
    justifyContent: "center",
  },
  deleteButton: {
    padding: "8px 48px",
    border: "1px solid #696c6e",
    fontFamily: theme.typography.fontFamily,
    textTransform: "initial",
    fontSize: "12px",
  },
  addAnotherCardContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "19px"
  },
  addAnotherCardButton: {
    padding: "8px 96px",
    border: "1px solid #696c6e",
    fontFamily: theme.typography.fontFamily,
    textTransform: "initial",
    fontSize: "16px",
  },
});
