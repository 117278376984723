/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { doQuery, getMetaData, getScreenCollectionPreviewMode } from '../../state/utils/contentful';
import { DocumentToComponent, MetaTagWrapper } from '../../components';

import { loadDryStylingContentful } from '../../state/ducks/DryStyling/Dry-Styling-Actions';

import '../../assets/css/style.scss';
import '../../assets/css/style-responsive.scss';
import './personal-information-rights.scss';

const PersonalInformationRights = ({ loadDataFromContentful }) => {
    const [content, setContent] = useState(null);
    const [metadata, setMetadata] = useState(null);

    const getContentData = async () => {
        const queryQL = `{
            screenCollection(${getScreenCollectionPreviewMode()}, where: {slug: "do-not-sell-my-personal-information"}) {
              items {
                metadata {
                    sys {
                        id
                    }
                }
                title
                description{
                    json
                }
              }
            }
        }`;

        const data = await doQuery(queryQL);
        setContent(data?.screenCollection?.items[0] || null);
        const metaDataId = data?.screenCollection?.items[0]?.metadata?.sys?.id || null;
        if (metaDataId) {
            console.log(metaDataId);
            const metadata = await getMetaData(metaDataId);
            setMetadata(metadata);
        }
    };

    useEffect(() => {
        getContentData();
    }, []);

    return (
        <div className="tab-pane fade show active static-page" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
            {metadata && <MetaTagWrapper metadata={metadata} />}
            <div className="title-container">
                <h5 className="text-uppercase text-center">{content?.title}</h5>
            </div>

            <div className="middle-section">
                <div className="card-body">
                    { content && content.description && content.description.json && <DocumentToComponent doc={content.description.json} />}
                </div>
                <div className="end" />
                {/* <div className="gradient-bottom-decorator"/> */}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
    loadDataFromContentful: bindActionCreators(loadDryStylingContentful, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInformationRights);
