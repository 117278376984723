import createState from 'redux-create-state';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session'; // defaults to localStorage for web and AsyncStorage for react-native-
import utils from '../../utils';

import * as buyoutActions from './Buyout-Actions';

export const INITIAL_STATE = {
    title: '',
    description: '',
    banners: {},
    headlines: {},
    locations: null,
    metadata: {},
    loaded: false,
};

const buyoutPersistConfig = {
    key: 'buyout',
    storage,
};

export default persistReducer(buyoutPersistConfig, utils.createReducer(INITIAL_STATE)({
    [buyoutActions.setBuyoutContent().type]: (state, action) => createState(state || INITIAL_STATE,
        ['metadata', action.payload.metadata],
        ['banners', action.payload.banners],
        ['headlines', action.payload.headlines],
        ['title', action.payload.title],
        ['description', action.payload.description],
        ['loaded', true],
        ['locations', action.payload.locations])
    ,
}));
