import createState from 'redux-create-state';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session'; // defaults to localStorage for web and AsyncStorage for react-native-
import utils from '../../utils';

import * as resyActions from './Resy-Actions';

export const INITIAL_STATE = {
    title: '',
    description: '',
    banners: {},
    headlines: {},
    metadata: {},
    loaded: false,
};

const resyPersistConfig = {
    key: 'resy',
    storage,
};

export default persistReducer(resyPersistConfig, utils.createReducer(INITIAL_STATE)({
    [resyActions.setResyContent().type]: (state, action) => createState(state || INITIAL_STATE,
        ['metadata', action.payload.metadata],
        ['banners', action.payload.banners],
        ['headlines', action.payload.headlines], ['title', action.payload.title], ['description', action.payload.description], ['loaded', true], ['locations', action.payload.locations])
    ,
}));
