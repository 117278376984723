import { gql } from '@apollo/client';
import { getPreviewMode, getScreenCollectionPreviewMode, doQuery } from '../../state/utils/contentful';

const storeLocator = (slug) => gql`{
  storeCollection(${getScreenCollectionPreviewMode()}, where: {AND: [{slug: "${slug}"}]}) {
      items {
        title
        number
        bookerLocationId
        type
        information
        contact
        slug
        mapImage {
          url
        } 
        settings
        arrivalInformation
        storefrontImage {
          internalName
          title
          alternateTitle
          caption
          desktopMedia {
            title
            description
            contentType
            fileName
            size
            url
            width
            height
          }
          mobileMedia {
            title
            description
            contentType
            fileName
            size
            url
            width
            height
          }
        }
      }
    }    
  }`;

export default storeLocator;

export const gqlLoadStores = async (id) => {
  //const data = await doQuery(queryStoresCollection());
  const data = await doQuery(queryStoresCollectionFromContentful(id), true);
  //let stores = data?.storeCollection?.items || [];
  let stores = data?.drybarLocationCollection?.items || [];
  stores = mapStoreData(stores);
  stores = stores.filter((store) => store.bookerLocationId);
  
  return stores;
};

const mapStoreData = (stores) => {
  return stores?.map((store) => {
      return {
          title: store.title,
          number: store.number,
          bookerLocationId: process.env.REACT_APP_DRYBAR_ENV === 'production' ? store.bookerLocationId : store.sandboxLocationId,
          type: store.storeType ? 'Drybar Shop' : '',
          information: parseAnswer(store.operatingMessage),
          contact: {
              city: store.city,
              state: store.county,
              region: store.county,
              social: {
                  instagram: null,
                  twitter: null,
                  facebook: null,
              },
              country: store.country,
              street1: store.street1,
              street2: store.street2,
              postalCode: store.postalCode,
              coordinates: [store.latitude, store.longitude],
              phoneNumber: store.phoneNumber,
          },
          slug: store.slugPrefix,
          settings: {
              enabled: store.enabled,
              visible: store.visibleOn,
              bookable: store.allowBookings,
              operatingHours: [
                  [store.dayrange1, store.timerange1],
                  [store.dayRange2, store.timeRange2],
                  [store.dayRange3, store.timeRange3],
              ],
              operatingStatus: 'OPERATIONAL',
              operatingMessage: parseAnswer(store.operatingMessage),
          },
          arrivalInformation: parseAnswer(store.parkingInformation),
          storeFrontImage: null,
          block730: true,
      };
  });
}

const parseAnswer = (resultedText) => {
  if(!resultedText) {
      return '';
  }
  
  const linkRegex = /\[([^\]]+)\]\(([^\)]+)\)/g;
  resultedText = resultedText?.replace(linkRegex, (match, p1, p2) => {
      return `<a href="${p2?.replace(']', '')}">${p1}</a>`;
  });

  const boldRegex = /__(.*?)__/g;
  resultedText = resultedText.replace(boldRegex, (match, p1) => {
      return `<strong>${p1}</strong>`;
    });

  return resultedText;
};

const queryStoresCollectionFromContentful = (slug) => (
  `{
    drybarLocationCollection(${getPreviewMode()}, where: {slugPrefix: "${slug}"}) {
      items {
        title
        slugPrefix
        storeType
        bookerLocationId
        sandboxLocationId
        parkingInformation
        operatingMessage
        city
        latitude
        longitude
        country
        phoneNumber
        postalCode
        region
        county
        street1
        street2
        allowBookings
        visibleOn
        enabled
        dayrange1
        dayRange2
        dayRange3
        timerange1
        timeRange2
        timeRange3
      }
    }
  }`
);