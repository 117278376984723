import React from 'react';
import { Container, withStyles } from '@material-ui/core';

const styles = (theme) => ({
  container: {
    maxWidth: theme.maxWidth,
  },
});

const SectionContainer = ({ classes, cn, children }) => (
    <Container className={`${classes.container} ${cn}`}>
        {children}
    </Container>
);

export default withStyles(styles)(SectionContainer);
