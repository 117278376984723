import {
    queryEventsPage,
    fetchBannerData,
    fetchEvents
} from './contentfulEventQueries';
import {
    doQuery,
} from '../../state/utils/contentful';

export const gqlLoadEvents = async () => {
    const eventsSectionData = {
        title: '',
        subtitle: '',
        events: [],
        heroImage: '',
        metadata: {},
    };

    let newData = await doQuery(queryEventsPage(), true);
    const pageData = newData?.drybarPageCollection?.items?.[0];
    const bannerId = pageData?.formatofPageCollection?.items?.find(c => c.__typename === 'HeroBanner')?.sys?.id;
    const banner = await doQuery(fetchBannerData(bannerId), true);
    const bannerData = banner?.heroBanner?.mediaCollection?.items?.[0];
    const eventId = pageData?.formatofPageCollection?.items?.find(c => c.__typename === 'DrybarPictureGrid')?.sys?.id;
    const event = await doQuery(fetchEvents(eventId), true);
    const eventsData = event?.drybarPictureGrid?.drybarGridCardsCollection?.items;

    const events = eventsData?.map(ev => {
        return {
            action: {
                title: ev?.buttonctaTitle,
                link: ev?.buttonctaUrl,
            },
            description: [{
                type:'paragraph',
                content: ev?.paragraph
            }],
            subtitle: ev?.subtitle,
            title: ev?.title,
            image: {
                desktopMedia: {url: ev?.imageUrl}
            }
        }
    })

    eventsSectionData.heroImage = {
        mobile: bannerData?.mobileImageUrl,
        desktop: bannerData?.desktopImageUrl,
    }
    eventsSectionData.metadata = {
        pageTitle: pageData?.seoTitle,
        pageKeywords: [],
        pageDescription: pageData?.seoDescription,
        searchKeywords: []
    }
    eventsSectionData.title = parseText(pageData.title);
    eventsSectionData.subtitle = parseText(pageData.subtitle);
    eventsSectionData.events = events;

    return eventsSectionData;
};

const parseText = (resultedText) => {
    if(!resultedText) {
        return '';
    }

    resultedText = resultedText?.replace('\n', '<br />')    
    const linkRegex = /\[([^\]]+)\]\(([^\)]+)\)/g;
    resultedText = resultedText?.replace(linkRegex, (match, p1, p2) => {
        return `<a href="${p2?.replace(']', '')}">${p1}</a>`;
    });

    const boldRegex = /__(.*?)__/g;
    resultedText = resultedText.replace(boldRegex, (match, p1) => {
        return `<strong>${p1}</strong>`;
      });

    return resultedText;
};
