/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import {
    Grid, withStyles, TextField, InputAdornment, Select, MenuItem, FormControl, FormHelperText
} from '@material-ui/core';
import {
    Visibility as VisibilityIcon,
    VisibilityOff as VisibilityOffIcon,
} from '@material-ui/icons';
import React, { useState } from 'react';

const styles = (theme) => ({
    enterEmailPasswordCopy: {
        float: 'left',
        color: '#767676',
    },
    enterYourEmail: {
        width: '100%',
        padding: '7px 0px 0px 0px',
    },
    errorMsg: {
        color: '#EE0000 !important',
    },
    inputContainer: {
        display: 'flex',
        width:'100%'
    },
    dropDownContainer: {
        padding: '7px 0px 0px 0px',
        marginRight: '10px',
        minWidth: '100px'
    }
});

const TextInputField = (props) => {
    const {
        error, onChange, onChangeSelect, value, label, errorMessage, classes, type, select, helperText, ...others
    } = props;

    const [selectValue, setSelectValue] = useState(select?.default || select?.options[0]);
    const [hideInputValue, setHideInputValue] = useState(type === 'password')

    const handleChange = (e) => {
        onChange(e);
    };

    const handleChangeSelect = (e) => {
        onChangeSelect(e);
        setSelectValue(e.target.value);
    }

    const handleHideShow = () => {
        setHideInputValue(!hideInputValue);
    }

    return (
        <Grid>
            {label
                ? <label htmlFor={label} className={classes.enterEmailPasswordCopy}>{label}</label>
                : null}
            <Grid className={classes.inputContainer}>
                {select?.options?.length > 0 &&
                <FormControl className={classes.dropDownContainer}>
                    <Select 
                        value={selectValue} 
                        onChange={handleChangeSelect} 
                        defaultValue={select.default || select.options[0]} 
                        required={select.required}>
                        {select.options.map((option) => (
                            <MenuItem value={option}>{option}</MenuItem>
                        ))}
                    </Select>
                    {error && <FormHelperText> </FormHelperText>}
                </FormControl>}
                <TextField
                    id={label}
                    value={value}
                    onChange={handleChange}
                    inputProps={{ 'aria-describedby': `${label} Error`, 'aria-autocomplete': `${label}`, maxLength: type ==='password'? 128 : null }}
                    FormHelperTextProps={{ id: `${label} Error`, className: classes.errorMsg }}
                    error={error}
                    className={classes.enterYourEmail}
                    helperText={helperText ?? (error ? errorMessage : '')}
                    type={type === 'password' ? (hideInputValue ? 'password' : 'text') : type} 
                    InputProps={type === 'password' ? {
                        endAdornment:
                            <InputAdornment position="start">
                                {hideInputValue ? 
                                <VisibilityIcon className={classes.visibilityIcon} onClick={() => handleHideShow()} /> : 
                                <VisibilityOffIcon className={classes.visibilityIcon} onClick={() => handleHideShow()} />}
                            </InputAdornment>,
                    } : ''}
                    {...others}
                />
            </Grid>
        </Grid>
    );
};

export default withStyles(styles)(TextInputField);
