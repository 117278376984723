/* eslint-disable no-console */
/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core';
import TagManager from 'app/Components/GoogleTagManager';
import config from 'app.config';

import useFetch from '../../../Helpers/useFetch';
import AddOnsSkeleton from './Partials/AddOnsSkeleton';
import AddOns from './AddOns';
import SummaryWrapper from '../SummaryWrapper';
import {
 getNumberOfGuests, getServicesData, getBuyoutLocation, getLocationData, getIsEditExtensions
} from '../../../state/ducks/Booking/Booking-Selectors';
import { setIsEditExtensions } from  '../../../state/ducks/Booking/Booking-Actions';
import { findAddOnServices } from '../../../api/booking-api';
import ExtensionsPage from './Partials/ExtensionsPage';

const { gtm: { booking } } = config;
const tagManagerArgs = {
    dataLayer: {
        event: booking.event,
        form_type: booking.type,
        form_step: booking.step4,
    },
};

const styles = () => ({
    containerTitleExtensions: {
        margin: '-40px auto 40px auto',
    },
});

const AddOnsContainer = ({
    goToNextPage,
    guests,
    classes,
    getSelectedTreatments,
    fromBuyout,
    selectedLocation,
    serviceData,
    isEditExtensions,
    setIsEditExtensions
}) => {
    const { data, error, loading } = useFetch(findAddOnServices({ LocationID: selectedLocation.bookerLocationId }));
    const [showExtensionsPage, setShowExtensionsPage] = useState(false);
    let component = null;

    useEffect(() => {
        TagManager.dataLayer(tagManagerArgs);
        if (fromBuyout) goToNextPage();
        if (serviceData.length === 1 && serviceData[0].data.Name === 'Dry Styling') {
            setShowExtensionsPage(true);
        } else if (!serviceData.find((s) => s.data.Name !== 'Dry Styling')) {
            setShowExtensionsPage(true);
        }
    }, [fromBuyout, goToNextPage, serviceData]);

    useEffect(() => {
        setShowExtensionsPage(isEditExtensions);
    },[isEditExtensions])

    if (loading) {
        component = <AddOnsSkeleton />;
    }

    if (error) {
        console.log(error);
    }

    if (data?.Results?.length) {
        component = <AddOns guests={guests} addonData={data.Results} goToNextPage={() => setShowExtensionsPage(true)} />;

        if (showExtensionsPage) {
            window.scroll(0, 0);
            component = <ExtensionsPage goToNextPage={goToNextPage} />;
        }
    }

    return (
        <SummaryWrapper
            title={showExtensionsPage ? 'DO YOU HAVE EXTENSIONS?' : 'ADD-ONS'}
            classes={showExtensionsPage ? {
                containerTitle: classes.containerTitleExtensions,
            } : null}
            containerTitleCenter={showExtensionsPage}
            nextPage="data-and-time"
            nextButtonEnabled={true}
            onButtonClick={() => {
                !showExtensionsPage ? setShowExtensionsPage(true) : goToNextPage();
            }}
        >
            {component}
        </SummaryWrapper>
    );
};

const mapStateToProps = (state) => ({
    guests: getNumberOfGuests(state),
    getSelectedTreatments: getServicesData(state),
    fromBuyout: getBuyoutLocation(state),
    selectedLocation: getLocationData(state),
    serviceData: getServicesData(state),
    isEditExtensions: getIsEditExtensions(state), 
});

const mapDispatchToProps = (dispatch) => ({
    setIsEditExtensions: bindActionCreators(setIsEditExtensions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AddOnsContainer));
