import createState from 'redux-create-state';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session'; // defaults to localStorage for web and AsyncStorage for react-native-
// eslint-disable-next-line import/no-unresolved
import utils from 'state/utils';

import * as modalActions from './Modal-Actions';

export const INITIAL_STATE = {
    isConfirmModalOpen: false,
};

const modalPersistConfig = {
    key: 'modal',
    storage,
};

export default persistReducer(modalPersistConfig, utils.createReducer(INITIAL_STATE)({
    [modalActions.toggleConfirmModal().type]: (state, action) => createState(
        state || INITIAL_STATE,
        ['isConfirmModalOpen', action.payload],
),
}));
