import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import '../../assets/css/style.scss';
import '../../assets/css/style-responsive.scss';
import { Link } from 'react-router-dom';
import { CircularProgress, Backdrop, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReactMarkdown from 'react-markdown';
import Media from 'react-media';
import { loadFaqContentful } from '../../state/ducks/Faq/Faq-Actions';
import {
    getArticles,
    getFaqSectionHeroImage,
    getFaqSectionSubTitle,
    getFaqSectionTitle,
    getMetaData,
    isFaqLoaded,
} from '../../state/ducks/Faq/Faq-Selectors';
import { MetaTagWrapper, CustomImg } from '../../components';

import './style.scss';
import { OpenInNew } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
    title: {
        fontSize: '20px',
        fontFamily: 'MrsEavesOT-Roman',
        textTransform: 'uppercase',
        color: '#42413D',
        marginBottom: '16px',
    },
    detail: {
        fontSize: '15px',
        color: '#42413D',
    },
}));

//const convertToSlug = (str) => str.replace(/[`~!@#$%^&*()_\-+=\[\]{};:'"\\|\/,.<>?\s]/g, ' ').toLowerCase().replace(/^\s+|\s+$/gm, '').replace(/\s+/g, '-');

const AccordionItem = ({ title, detail, article }) => {
    const classes = useStyles();

    return (
        <div className="mb-4 px-5">
            <h5 className={`${classes.title} pb-1 border-bottom`}>{title}</h5>
            {detail?.map((d) => {
                if (!d.pageLink) {
                    return (
                        <Paper className="mb-3">
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    <Typography className={classes.title}>
                                        {d.question}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div>{parseNode(d.answer?.json)}</div>
                                </AccordionDetails>
                            </Accordion>
                        </Paper>
                    );
                } else {
                    return (
                        <Paper className="row mb-3 py-2 px-0 mx-0">
                            <div className="col">
                                <Link
                                    key={''}
                                    to={{
                                        pathname: d.url,
                                        relatedarticles:
                                            article?.articlesCollection,
                                    }}
                                    className={classes.detail}
                                >
                                    <div style={{ padding: '8px 16px' }}>
                                        <Typography>{d.question}</Typography>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-auto pt-2">
                                <OpenInNew />
                            </div>
                        </Paper>
                    );
                }
            })}
        </div>
    );
};

const HelpCenter = ({
    metadata,
    title,
    subtitle,
    heroImage,
    articles,
    isLoaded,
    loadDataFromContentful,
}) => {
    useEffect(() => {
        loadDataFromContentful({});
    }, []);

    if (!title && !subtitle && !heroImage) {
        return (
            <Backdrop
                open
                style={{
                    zIndex: 11,
                    color: '#fff',
                }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    }

    return (
        <div
            className="tab-pane fade show active faq-page"
            id="nav-home"
            role="tabpanel"
            aria-labelledby="nav-home-tab"
        >
            <MetaTagWrapper metadata={metadata} />
            <div className="middle-section">
                <div className="faq_bg d-flex align-items-center justify-content-center">
                    <Media query={{ maxWidth: 767 }}>
                        {(matches) =>
                            matches ? (
                                <CustomImg
                                    className="img-fluid banner_help"
                                    src={heroImage.mobile}
                                    alt={heroImage.alternateTitle || ''}
                                    style={{ maxWidth: '1295px' }}
                                />
                            ) : (
                                <CustomImg
                                    className="img-fluid banner_help"
                                    src={heroImage.desktop}
                                    alt={heroImage.alternateTitle || ''}
                                    style={{ maxWidth: '1295px' }}
                                />
                            )
                        }
                    </Media>
                </div>
                <div className="clearfix" />
                <div
                    className="inner_detisls d-flex align-items-center justify-content-center"
                    style={{ background: '#f3f3f3' }}
                >
                    <div
                        className="inner_bg_views"
                        style={{ maxWidth: '1295px', background: '#f6f6f6' }}
                    >
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <div className="find_answer">
                                        <h1>{title?.toUpperCase()}</h1>
                                        <ReactMarkdown>
                                            {subtitle}
                                        </ReactMarkdown>
                                    </div>
                                </div>
                            </div>
                            <div className="">
                                {articles.map(
                                    (article, index) =>
                                        article?.title && (
                                            <AccordionItem
                                                key={`item_${index}`}
                                                title={article?.title}
                                                detail={
                                                    article?.articlesCollection
                                                }
                                                article={article}
                                            />
                                        )
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    metadata: getMetaData(state),
    title: getFaqSectionTitle(state),
    subtitle: getFaqSectionSubTitle(state),
    heroImage: getFaqSectionHeroImage(state),
    articles: getArticles(state),
    isLoaded: isFaqLoaded(state),
});

const mapDispatchToProps = (dispatch) => ({
    loadDataFromContentful: bindActionCreators(loadFaqContentful, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HelpCenter);

const newLineText = (text) => {
    const textArr = text.split('\n');
    if (text == '') return <></>;

    let newText;
    if (textArr.length > 1) {
        newText = textArr.map((str) => (
            <span>
                {str}
                <br />
            </span>
        ));
    } else {
        newText = <span>{text}</span>;
    }
    return newText;
};

const parseNode = (node) => {
    if (node) {
        let subNode = '';
        if (node?.content?.length > 0) {
            subNode = node.content.map((subnode) => parseNode(subnode));
        } else {
            subNode = node.value;
        }

        if (node.nodeType == 'text') {
            return <span>{newLineText(subNode)}</span>;
        }
        if (node.nodeType == 'hr') {
            return <hr />;
        }
        if (node.nodeType == 'paragraph') {
            return <p style={{ marginBottom: '15px' }}>{subNode}</p>;
        }
        if (node.nodeType == 'heading-1') {
            return <h1 style={{ marginBottom: '15px' }}>{subNode}</h1>;
        }
        if (node.nodeType == 'heading-2') {
            return <h2 style={{ marginBottom: '15px' }}>{subNode}</h2>;
        }
        if (node.nodeType == 'heading-3') {
            return <h3 style={{ marginBottom: '15px' }}>{subNode}</h3>;
        }
        if (node.nodeType == 'heading-4') {
            return <h4 style={{ marginBottom: '15px' }}>{subNode}</h4>;
        }
        if (node.nodeType == 'heading-5') {
            return <h5 style={{ marginBottom: '15px' }}>{subNode}</h5>;
        }
        if (node.nodeType == 'hyperlink') {
            console.log(node);
            return <a href={node.data.uri}>{subNode}</a>;
        }
        if (node.nodeType == 'document') {
            return <>{subNode}</>;
        }
        console.log('---------- : ', node);
    } else {
        return <></>;
    }
};
