/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import {
    Button, Grid, Typography, withStyles,
} from '@material-ui/core';
import {
    arrayOf, object, string, shape, bool,
} from 'prop-types';
import dayjs from 'dayjs';
import { Link, useHistory } from 'react-router-dom';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import DirectionsIcon from '@material-ui/icons/Directions';
import Media from 'react-media';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';
import { bindActionCreators } from 'redux';
import { direction } from 'Helpers';
import restClient from '../../api/restClient';
import { extractDataMonthYearTime } from '../../Helpers/dateTime';
import { cancelAppointment, cancelItinerary } from '../../api/booking-api';
import { MOBILE_BREAKPOINT } from '../../Helpers/breakpoints';
import { setViewAppointment } from '../../state/ducks/Account/Account-Actions';
import { googleAnalyticsEvents } from 'state/utils/googleAnalyticsEvents';

const styles = (theme) => ({
    container: {
        borderRadius: '4px',
        overflow: 'hidden',
        marginBottom: '11px',
        flexWrap: 'nowrap',
        // minWidth: '600px',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            // minWidth: '393px',
            margin: '9px 0px 0px',
        },
    },
    dateSection: {
        backgroundColor: '#E5E5E5',
        borderRight: '2px solid white',
        width: '93px',
        padding: '18px',
        textAlign: 'center',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            padding: '15px',
        },
    },
    upcomingDateBgColor: {
        backgroundColor: '#FFDD30',
    },
    main: {
        backgroundColor: '#F9F9F9',
        width: 'calc(100% - 95px)',
        padding: '18px',
        justifyContent: 'space-between',
        borderTop: '1px solid #D1D1D1',
        boxSizing: 'border-box',
        flexWrap: 'nowrap',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            backgroundColor: '#FFFFFF',
            padding: '12px',
        },
    },
    heading: {
        fontSize: '15px',
        fontWeight: '600',
        paddingBottom: '6px',
    },
    typography: {
        fontSize: '18px',
        fontWeight: '400',
        wordWrap: 'nowrap',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            fontSize: '15px',
            lineHeight: '20px',
            color: '#42413D',
        },
    },
    services: {
        paddingTop: '31px',
        maxWidth: '70%',
    },
    dateTypography: {
        fontWeight: '400',
    },
    month: {
        fontSize: '15px',
    },
    day: {
        paddingTop: '4px',
        fontSize: '18px',
        fontWeight: '600',
    },
    year: {
        paddingTop: '4px',
        fontSize: '14px',
    },
    time: {
        paddingTop: '9px',
        fontSize: '15px',
    },
    mainButton: {
        backgroundColor: '#FFDD30',
        color: '#54575A',
        '&:hover': {
            backgroundColor: '#b29a21',
        },
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            padding: '8px 0px !important',
            width: '100% !important',
            maxWidth: 'unset !important',
        },
    },
    directionsButton: {
        backgroundColor: 'white',
        boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.04)',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            padding: '8px 0px !important',
        },
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            width: '85px',
        },
    },
    topButtons: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'flex-end',
        boxSizing: 'border-box',
        flexDirection: 'column',

    },
    button: {
        display: 'flex',
        margin: '3px 0',
        fontWeight: '400',
        textTransform: 'none',
        borderRadius: '0',
        minWidth: '79px',
        maxWidth: 'fit-content',
        width: '100%',
        padding: '8px 22px',
        whiteSpace: 'nowrap',
        '&:focus, &:visited, &:hover, &:link, &:active': {
            textDecoration: 'none',
        },
        '& .MuiButton-iconSizeMedium': {
            margin: '5px 3px 5px 0',
        },
    },
    detailsButton: {
        fontSize: '16px',
        textDecoration: 'underline',
        color: '#42413D',
        textUnderlinePosition: 'under',
        padding: '4px',
        alignSelf: 'flex-end',
        margin: '0',
        '&:hover': {
            textDecoration: 'underline',
            color: '#42413D',
            textUnderlinePosition: 'under',
            backgroundColor: 'none',
        },
        '&:focus, &:visited, &:link, &:active': {
            textDecoration: 'none',
        },
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            width: '100% !important',
            maxWidth: 'unset !important',
        },
    },
    textDecorationNone: {
        textDecoration: 'none',
    },

    modalTitle: {
        fontSize: '20px',
        fontWeight: 700,
        lineHeight: '25px',
    },
});

// Appontment Card widget - independent component that takes an appointment object
// the object should include following keys
// date, location, services (Array) and status (past or upcoming)

const AppointmentCard = ({
    classes,
    appointment,
    isUpcoming,
    setViewAppointment,
    contentfulStores,
    group,
}) => {
    const {
        StartDateTimeOffset,
        LocationName,
        AppointmentTreatments,
    } = appointment;

    const [confirmModalVisible, setConfirmModalVisible] = useState(false);
    const [cancellationType, setCancellationType] = useState(1);
    const history = useHistory();

    // TODO: build a query to get a store from the location id.
    const appointmentLocation = contentfulStores?.filter((locId) => locId?.bookerLocationId === appointment?.LocationID);

    let lat;
    let lng;
    if (appointmentLocation.length) {
        lat = appointmentLocation[0].contact?.coordinates[0];
        lng = appointmentLocation[0].contact?.coordinates[1];
    }

    const appointmentDate = extractDataMonthYearTime(StartDateTimeOffset);
    const detailsButtonText = isUpcoming ? 'Cancel' : 'View Details';
    const mainButtonText = isUpcoming ? 'Edit' : 'Rebook';

    const services = AppointmentTreatments.filter((aT) => aT.Treatment.Name !== 'Extensions');
    const isDifferentTime = services.find((s) => s.StartDateTimeOffset !== StartDateTimeOffset);

    const confirmCancelAppointment = async () => {
        let res;
        if (group.groupID) {
            res = await restClient.post(cancelItinerary(group.groupID, appointment.Customer.LocationID));
        } else {
            res = await restClient.post(cancelAppointment(appointment.ID, cancellationType));
        }

        if (res.data.IsSuccess) {
            if (window.dtrum) {
                let actionId = window.dtrum.enterAction('CancelApppointment');
                window.dtrum.actionName('AppointmentCancelled', actionId);
                window.dtrum.addActionProperties(actionId, null, null, { "cancelappointment": "User Cancelled Appointment", "public": "true" }, null);
                window.dtrum.leaveAction(actionId);
            } else { console.log('Warning: DT logging not connected'); }

            gtag(googleAnalyticsEvents.EVENT, googleAnalyticsEvents.BOOKING_CANCELLATION, {
                fee_incurred: cancellationType !== 1 
            });

            window.location.reload();
        }
    };

    const handleEdit = () => {
        mParticle.logEvent(
            'Account - Edit Appointment',
            mParticle.EventType.Other,
            { 'Source Page': 'My Appointments' },
        );
        setViewAppointment({
            isUpcoming,
            group,
        });
    };

    const handleCancel = () => {
        if (isUpcoming) {
            let type = 1;
            // enable only for single for now
            if (!group.groupID && dayjs(group.appointment.StartDateTimeOffset).diff(dayjs(new Date()), 'hour', true) < 12) {
                type = 2;
            }

            setCancellationType(type);
            setConfirmModalVisible(true);
        } else {
            setViewAppointment({
                isUpcoming,
                group,
            });
        }
    };

    return (
        <Grid container className={classes.container}>
            <Grid item className={`${classes.dateSection} ${isUpcoming ? classes.upcomingDateBgColor : ''}`}>
                {
                    // Get date, month, year and time - use class with same name
                    Object.keys(appointmentDate).map((key) => (
                        <Typography key={key} className={`${classes.dateTypography} ${classes[key]}`}>
                            {appointmentDate[key]}
                        </Typography>
                    ))
                }
            </Grid>
            <Grid container className={classes.main}>
                <Grid item>
                    <Grid>
                        <Typography className={`${classes.heading}`}>
                            Location
                        </Typography>
                        <Typography className={classes.typography}>
                            {appointment.Customer.LocationName}
                        </Typography>
                    </Grid>
                    <Grid className={classes.services}>
                        <Typography className={`${classes.heading}`}>
                            {`Service${services?.length ? 's' : ''}`}
                        </Typography>
                        {
                            services?.length > 1 ? services.map((service, idx) => (
                                // TODO change key style
                                <Grid key={`${service.user}${service.service}`} container justify="space-between">
                                    <Typography className={classes.typography}>
                                        {idx === 0 ? 'Me' : `Guest ${idx}`}
                                        {' '}
                                        {isDifferentTime ? `(${extractDataMonthYearTime(service.StartDateTimeOffset).time})` : ''}
                                    </Typography>
                                    <Typography className={classes.typography} style={{ paddingLeft: 10 }}>
                                        {service.TreatmentName}
                                    </Typography>
                                </Grid>
                            )) : (
                                <Typography className={classes.typography}>
                                    {services[0].TreatmentName}
                                </Typography>
                            )
                        }

                    </Grid>
                </Grid>
                <Grid className={classes.buttonContainer}>
                    <Grid className={classes.topButtons}>
                        <Button
                            variant="contained"
                            className={`${classes.button} ${classes.mainButton}`}
                            onClick={handleEdit}
                        >
                            {mainButtonText}
                        </Button>
                        {isUpcoming ? (
                            <Media query={{ maxWidth: 599 }}>
                                {(matches) => (matches ? (
                                    lat && lng
                                        ? (
                                            <Button
                                                variant="contained"
                                                startIcon={<DirectionsIcon />}
                                                className={`${classes.button} ${classes.directionsButton}`}
                                                href={direction(lat, lng)}
                                                target="_blank"
                                                onClick={() => {
                                                mParticle.logEvent(
                                                    'Home - Get Directions',
                                                    mParticle.EventType.Other,
                                                    { 'Source Page': 'My Appointments' },
                                                );
                                            }}
                                            >
                                                <span>Directions</span>
                                            </Button>
)
                                    : (
                                        <Link
                                            to="/booking/location"
                                            style={{ width: '100%', overflow: 'hidden' }}
                                            onClick={() => {
                                        mParticle.logEvent(
                                            'Home - Get Directions',
                                            mParticle.EventType.Other,
                                            { 'Source Page': 'My Appointments' },
                                        );
                                    }}
                                        >
                                            <Button
                                                variant="contained"
                                                startIcon={<DirectionsIcon />}
                                                className={`${classes.button} ${classes.directionsButton}`}
                                            >
                                                <span>Directions</span>
                                            </Button>
                                        </Link>
)
                                ) : (
                                    lat && lng
                                        ? (
                                            <Button
                                                variant="contained"
                                                startIcon={<DirectionsIcon />}
                                                className={`${classes.button} ${classes.directionsButton}`}
                                                href={direction(lat, lng)}
                                                target="_blank"
                                                onClick={() => {
                                                mParticle.logEvent(
                                                    'Home - Get Directions',
                                                    mParticle.EventType.Other,
                                                    { 'Source Page': 'My Appointments' },
                                                );
                                            }}
                                            >
                                                <span>Get Directions</span>
                                            </Button>
)
                                    : (
                                        <Link to="/booking/location" style={{ width: '100%', overflow: 'hidden' }}>
                                            <Button
                                                variant="contained"
                                                startIcon={<DirectionsIcon />}
                                                className={`${classes.button} ${classes.directionsButton}`}
                                                onClick={() => {
                                                mParticle.logEvent(
                                                    'Home - Get Directions',
                                                    mParticle.EventType.Other,
                                                    { 'Source Page': 'My Appointments' },
                                                );
                                            }}
                                            >
                                                <span>Get Directions</span>
                                            </Button>
                                        </Link>
)
                                ))}
                            </Media>
                        ) : null}
                    </Grid>
                    <Button onClick={handleCancel} className={`${classes.button} ${classes.detailsButton}`}>
                        <Typography>
                            {detailsButtonText}
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
            <Dialog
                open={confirmModalVisible}
                onClose={() => setConfirmModalVisible(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" className={classes.modalTitle}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginTop: '10px',
                    }}
                    >
                        <Typography className={classes.modalTitle}>Cancel Appointment</Typography>
                        <CloseIcon
                            onClick={() => setConfirmModalVisible(false)}
                            style={{
                            marginRight: '-5px',
                            cursor: 'pointer',
                        }}
                        />
                    </div>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText style={{ fontSize: '18px', color: '#42413D', padding: '10px 50px 10px 10px' }}>
                        {cancellationType === 1
                            ? 'Are you sure you want to cancel?'
                            : "Oh no, it looks like you're cancelling within 12 hours of your appointment. If you continue, you'll be charged a late cancelation fee. (Sorry!)"}
                    </DialogContentText>
                    <Button
                        style={{
                            background: '#FFDD30',
                            padding: '5px 20px',
                            borderRadius: '2px',
                            marginBottom: '8px',
                            left: '40%'
                        }}
                        onClick={() => {
                            setConfirmModalVisible(false);
                            confirmCancelAppointment();
                        }}
                    >
                        Confirm
                    </Button>
                </DialogContent>
            </Dialog>
        </Grid>
    );
};

AppointmentCard.propTypes = {
    classes: object.isRequired,
    appointment: shape({
        date: string,
        status: string,
        location: string,
        service: arrayOf(shape({
            user: string,
            service: string,
        })),
    }).isRequired,
    isUpcoming: bool,
};

AppointmentCard.defaultProps = {
    isUpcoming: false,
};

const mapDispatchToProps = (dispatch) => ({
    setViewAppointment: bindActionCreators(setViewAppointment, dispatch),
});

export default connect(null, mapDispatchToProps)(withStyles(styles)(AppointmentCard));
