import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Grid, Button, Typography,
} from '@material-ui/core';
import { withOktaAuth } from '@okta/okta-react';
import useMoktaAuth from 'utils/oktaAuth';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TagManager from 'app/Components/GoogleTagManager';
import config from 'app.config';
import MasterCard from '../../assets/images/paymentCard.svg';
import SummaryWrapper from './SummaryWrapper';
import { MOBILE_BREAKPOINT } from '../../Helpers/breakpoints';
import { getCustomerCreditCards } from '../../api/booking-api';
import restClient from '../../api/restClient';
import { getAuthUserInfo, getBuyoutLocation, getLocationData, getServicesData, getAddOnsServiceData, getExtensions, getExtensionAddon, getSelectedCard, getCards } from '../../state/ducks/Booking/Booking-Selectors';
import { getLocations as getBuyoutData } from '../../state/ducks/Buyout/Buyout-Selectors';
import { setRegisterUserInfo, setSelectedCard, setCards } from '../../state/ducks/Booking/Booking-Actions';
import { googleAnalyticsEvents } from 'state/utils/googleAnalyticsEvents';
import { getBookingTotal } from 'state/utils/helpers';
import { getWorldpayStatus } from '../../state/ducks/Worldpay/Worldpay-Selectors';
import { toggleWorldpay } from '../../state/ducks/Worldpay/Worldpay-Actions';


const { gtm: { booking } } = config;
const tagManagerArgs = {
    dataLayer: {
        event: booking.event,
        form_type: booking.type,
        form_step: booking.step7,
    },
};

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    requestNoteCopy: {
        fontSize: '18px',
        textAlign: 'center',
        color: theme.palette.common.grey,
        fontWeight: 800,
        paddingBottom: '15px',
        borderBottom: `1px solid ${theme.palette.common.lightGrey}`,
        [theme.breakpoints.down('sm')]: { display: 'none' },
    },
    borderBottom: {
        borderBottom: `1px solid ${theme.palette.common.lightGrey}`,
        width: '88%',
        margin: '22px 5px 2px 41px',
    },
    cardSelectionCopy: {
        textAlign: 'center',
        margin: '30px 0px 0px 0px',
        fontFamily: theme.typography.fontFamily,
        [theme.breakpoints.down('sm')]: {
            whiteSpace: 'normal',
            margin: '0 0px 30px 0',
            fontSize: '15px',
        },
        fontSize: '17px',
        color: '#42413D',
    },
    masterCard: {
        width: '223px',
        height: '163.07px',
        margin: '0% 2% 8% 2%',
        cursor: 'pointer',
    },
    selectedMasterCard: {
        width: '223px',
        height: '163.07px',
        margin: '0% 2% 8% 2%',
        cursor: 'pointer',
        border: '1px solid #767676',
        borderRadius: '22px',
        [theme.breakpoints.down('sm')]: {
            margin: '0% 2% 0% 2%'
        }
    },
    addMasterCardView: {
        width: '280px',
        [theme.breakpoints.down('sm')]: {
        },
    },
    MasterCardSecond: {
        width: '258px',
        height: '224.07px',
        margin: '11% 0% 0% 0%',
    },
    cardEnding: {
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.common.lightGrey[1],
        fontSize: '14px',
        cursor: 'pointer',
    },
    cardDetails: {
        margin: '-56% 0% 0% 29%',
    },
    AddCreditDetails: {
        margin: '-55% 0% 0% 12%',
        [theme.breakpoints.down('sm')]: {
            margin: '-55% 0% 0% 12%',
        },
    },
    cardDigit: {
        fontFamily: theme.typography.fontFamily,
        margin: '9px 0px 0px 0px',
        cursor: 'pointer',
    },
    proceedSelectedAction: {
        margin: '50px auto 25px',
        width: '50%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            textAlign: 'center',
        },
    },
    proceedAddCard: {
        margin: '50px 0px 25px',
        width: '100%',
        padding: 0,
        [theme.breakpoints.down('sm')]: {
            margin: '0 0 25px',
            width: '100%',
            padding: '20px',
        },
    },
    proceedSelectClick: {
        left: '0.05%',
        right: '55.09%',
        bottom: '66.36%',
        fontFamily: theme.typography.fontFamily,
        fontSize: '18px',
        lineHeight: '45px',
        width: '100%',
        textTransform: 'none',
        color: '#54575A',
        backgroundColor: '#FFDD30',
        '&:hover': {
            backgroundColor: '#b29a21',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '63px',
        },
    },
    addCardBtn: {
        fontFamily: theme.typography.fontFamily,
        fontSize: '18px',
        lineHeight: '45px',
        width: '324px',
        textTransform: 'none',
        color: '#54575A',
        backgroundColor: '#FFDD30',
        margin: '0 auto',
        '&:hover': {
            backgroundColor: '#b29a21',
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
            height: '63px',
        },
    },
    leftDivider: {
        width: '42%',
        margin: '2px 2px 2px 15px',
    },
    rightDivider: {
        width: '42%',
    },
    addCardAction: {
        margin: '50px auto 25px',
        width: '50%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            textAlign: 'center',
        },
    },
    borderBottomBetween: {
        borderBottom: `1px solid ${theme.palette.common.lightGrey[0]}`,
        width: '42%',
        margin: '0px auto 7px auto',
    },
    displayFlex: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: { display: 'none' },
    },
    borderBottomCopy: {
        margin: '7px auto 0px auto',
    },
    leftSectionContainer: {
        backgroundColor: theme.palette.common.white,
        margin: 'auto',
        padding: '26px 22px',
        [theme.breakpoints.down('sm')]: {
            padding: '20px 20px',
            margin: '20px 0',
        },
    },
    leftSectionContainer1: {
        backgroundColor: theme.palette.common.white,
        margin: 'auto',
        padding: '26px 22px',
        width: '754px',
        [theme.breakpoints.down('sm')]: {
            padding: '0px',
            width: '100%',
        },
    },
    addCreditCard: {
        left: '0.05%',
        right: '55.09%',
        bottom: '66.36%',
        fontFamily: theme.typography.fontFamily,
        fontSize: '18px',
        lineHeight: '45px',
        width: '100%',
        textTransform: 'none',
        color: '#54575A',
        border: '1px solid #54575A',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '63px',
            margin: '0px 0px 0px 0px',
        },
    },
    cardSelectionContainer: {
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'nowrap',
            overflowY: 'auto',
        },
    },
    addCreditCardCopy: {
        whiteSpace: 'nowrap',
        fontWeight: '600',
        fontSize: '21px',
    },
    textAlignEndTitle: {
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
        },
    },
    cardInputWrap: {
        marginTop: '40px',
    },
    cardNumber: {
        width: '100%',
    },
    cardNumberCopy: {
        fontFamily: 'URWForm',
        fontSize: '15px',
        lineHeight: '18px',
        color: '#989898',
    },
    inputExpirationMonth: {
        width: '95%',
    },
    inputCVV: {
        width: '100%',
    },
    savedCardText: {
        margin: '30px 0px 0px 21px !important',
        color: '#767676',
        [theme.breakpoints.down('sm')]: { whiteSpace: 'nowrap' },
    },
    defaultCopy: {
        margin: '50px 0px 0px !important',
        fontWeight: '600',
        textAlign: 'center',
    },
    borderOnePx: {
        border: '1px solid #D1D1D1',
        margin: '23px 0px 32px 0px',
        [theme.breakpoints.down('sm')]: {
            border: 'none',
            margin: 0,
        },
    },
    borderOnePxMobile: {
        [theme.breakpoints.down('sm')]: {
            border: '1px solid #D1D1D1',
            margin: '23px 0px 32px 0px',
        },
    },
    closeBtn: {
        position: 'absolute',
        right: 'calc(50% - 377px)',
        top: '40px',
        fontSize: 30,
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            right: 'calc(50% - 277px)',
            top: '40px',
        },
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            right: 'calc(50% - 150px)',
            top: '42px',
            fontSize: 25,
        },

    },
    cardImageWrap: {
        margin: '72px auto',
        width: '33.3%',
        minWidth: '280px',
        padding: '0 !important',
        [theme.breakpoints.down('sm')]: {

        },
    },
    makeDefaultWrap: {
        display: 'flex',
        margin: '50px 0',
        justifyContent: 'center',
        width: '100%',
    },
}));

const AppointmentHold = ({
    goToNextPage, authUserInfo, buyoutLocation, buyout,
    locationData, setAuthUser, setSelectedCard, services,
    addons, extensions, extensionAddon, selectedCard,
    bookingTotal, toggleWorldpay, cards, setCards
}) => {
    const [isAuthenticated, getUser, getUserId] = useMoktaAuth();

    const classes = useStyles();

    const handleCardSelection = (cardKey) => {
        setSelectedCard(cardKey);
    };

    const fetchCards = async () => {
        const customerId = authUserInfo?.bookerID;
        const { data, error } = await restClient.post(getCustomerCreditCards(customerId, locationData.bookerLocationId));
        return data?.CreditCards;
    }

    useEffect(async () => {
        TagManager.dataLayer(tagManagerArgs);

        await getUserId().then((res) => {
            setAuthUser(res);
        });
    }, []);

    useEffect(() => {
        if (authUserInfo.bookerID) {
            fetchCards().then(cards => {
                if (cards) {
                    setCards(cards);
                    /**
                     * Set the default card to be the one selected using filtering.
                     */
                    setSelectedCard(cards.filter(c => c.IsDefault)[0])
                } else {
                    setCards([]);
                    setSelectedCard(null);
                }
            });
        }
    }, [authUserInfo]);

    useEffect(() => {
        if (buyoutLocation) {
            goToNextPage();
        }
    }, [buyoutLocation]);

    const logData = () => {

        let items = services.map(service => ({
            item_id: service.data?.ID?.toString(),
            item_name: service.data?.Name,
            price: service.data?.Price?.Amount,
            quantity: 1,
        }));

        addons.forEach(addonsForUser => {
            items = items.concat(addonsForUser.data?.map(addon => ({
                item_id: addon.ServiceID,
                item_name: addon.ServiceName,
                price: addon.PriceInfo?.Amount,
                quantity: 1,
            })))
        });

        const extensionsQuantity = extensions
            ? (typeof extensions == "boolean" ? services.length : Object.values(extensions)?.filter(extension => extension)?.length)
            : 0

        if (extensions) {
            items = items.concat({
                item_id: extensionAddon.ID.toString(),
                item_name: extensionAddon.Name,
                price: extensionAddon.Price?.Amount,
                quantity: extensionsQuantity
            })
        }

        gtag(googleAnalyticsEvents.EVENT, googleAnalyticsEvents.ADD_PAYMENT_INFO, {
            currency: services[0].data?.Price?.CurrencyCode,
            value: bookingTotal,
            payment_type: selectedCard?.CreditCard?.Type?.Name,
            items: items
        });
    }

    const handleGoToNextPage = () => {
        logData();
        goToNextPage();
    }

    return (
        <SummaryWrapper title="APPOINTMENT HOLD" containerStyle={{ backgroundColor: '#ffffff' }}>
            <Grid className={classes.leftSectionContainer}>
                <Typography className={classes.requestNoteCopy}>
                    Card Selection
                </Typography>
                <div className={classes.borderOnePx} />
                {cards?.length > 0
                    ? (
                        <>
                            <div className={classes.cardSelectionCopy}>
                                Select payment card to hold your appointment.
                            </div>
                            <Typography className={classes.savedCardText}>{`Your saved card${cards.length > 1 ? 's' : ''}`}</Typography>
                            <Grid container spacing={3} className={classes.cardSelectionContainer}>
                                {cards?.map((cardKey, idx) => (
                                    <Grid item sm={4} onClick={() => handleCardSelection(cardKey)}>
                                        <img
                                            src={MasterCard}
                                            alt="Card Info"
                                            className={selectedCard && selectedCard?.ID === cardKey?.ID ? classes.selectedMasterCard : classes.masterCard}
                                        />
                                        <div className={classes.cardDetails}>
                                            <div className={classes.cardEnding}>User card ending</div>
                                            <div className={classes.cardDigit}>{cardKey.CreditCard.Number}</div>
                                        </div>
                                        {
                                            cardKey?.IsDefault && <Typography className={classes.defaultCopy}>Default</Typography>
                                        }
                                    </Grid>
                                ))}
                            </Grid>

                        </>
                    ) : null}
                {
                    cards?.length === 0
                        ? <Typography align="center">You don't have any payment method please add</Typography>
                        : (
                            <div className={classes.proceedSelectedAction}>
                                <Button disabled={!selectedCard} onClick={handleGoToNextPage} className={classes.proceedSelectClick} variant="outlined">
                                    Use Selected Card
                                </Button>
                            </div>
                        )
                }

                <div />
                <div className={classes.displayFlex}>
                    <div className={classes.borderBottomBetween} />
                    <span className={classes.borderBottomCopy}>OR</span>
                    <div className={classes.borderBottomBetween} />
                </div>
                <div className={classes.addCardAction}>
                    {cards?.length > 0 ? (<>
                        <Button className={classes.addCreditCard} variant="outlined" onClick={() => toggleWorldpay()}>
                            Add New Card
                        </Button>
                    </>) : (
                        <>
                            <Button className={classes.addCreditCard} variant="outlined" onClick={() => toggleWorldpay()}>
                                    Add a Debit/Credit Card
                            </Button>
                        </>)}
                </div>
            </Grid>
        </SummaryWrapper>
    );
};

const mapStateToProps = (state) => ({
    authUserInfo: getAuthUserInfo(state),
    fromBuyout: getBuyoutLocation(state),
    buyout: getBuyoutData(state),
    locationData: getLocationData(state),
    buyoutLocation: getBuyoutLocation(state),
    services: getServicesData(state),
    addons: getAddOnsServiceData(state),
    extensions: getExtensions(state),
    extensionAddon: getExtensionAddon(state),
    selectedCard: getSelectedCard(state),
    cards: getCards(state),
    bookingTotal: getBookingTotal(state),
    isWorldpayOpen: getWorldpayStatus(state)
});

const mapDispatchToProps = (dispatch) => ({
    setSelectedCard: bindActionCreators(setSelectedCard, dispatch),
    setCards: bindActionCreators(setCards, dispatch),
    setAuthUser: bindActionCreators(setRegisterUserInfo, dispatch),
    toggleWorldpay: bindActionCreators(toggleWorldpay, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withOktaAuth(AppointmentHold));
