/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import {
    Grid, withStyles, TextField, InputAdornment, Button, Checkbox, Typography,
} from '@material-ui/core';
import { bindActionCreators } from 'redux';
import useMoktaAuth from 'utils/oktaAuth';
import { withOktaAuth } from '@okta/okta-react';
import { connect } from 'react-redux';
import TagManager from 'app/Components/GoogleTagManager';
import config from 'app.config';

import SearchIcon from '@material-ui/icons/Search';
import { useLocation } from 'react-router-dom';
import { getPageLabelFromUrl } from 'utils/helper';
import MapContainer from './Partials/MapContainer';
import {
    setLocationData,
    clearBookingDetails,
} from '../../../state/ducks/Booking/Booking-Actions';
import SectionTitle from '../../../app/Components/SectionTitle';
import LocationNearbyFavourite from './Partials/LocationNearbyFavourite';
import { TABLET_BREAKPPOINT, MOBILE_BREAKPOINT } from '../../../Helpers/breakpoints';
import {
    getLocationData,
    getIsEditEnabled,
} from '../../../state/ducks/Booking/Booking-Selectors';

import { getFavoriteStore } from '../../../state/ducks/Account/Account-Selectors';
import { setFavoriteStore } from '../../../state/ducks/Account/Account-Actions';

import { getCustomerDetailsById } from '../../../api/booking-api';
import restClient from '../../../api/restClient';

const { gtm: { booking } } = config;
const tagManagerArgs = {
    dataLayer: {
        event: booking.event,
        form_type: booking.type,
        form_step: booking.step1,
    },
};

let timer = null;

const styles = (theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        maxWidth: '1903px',
        // height: '800px',
        position: 'relative',
        margin: 'auto',
        marginBottom: '20px',
        [theme.breakpoints.down(TABLET_BREAKPPOINT)]: {
            flexDirection: 'column-reverse',
        },
    },
    mapContainer: {
        width: '100%',
        height: '800px',
        '& > div:first-child': {
            height: '100%',
        },
        [theme.breakpoints.down(TABLET_BREAKPPOINT)]: {
            height: '50vw',
        },
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            height: '70vw',
        },
    },
    leftContent: {
        width: '573px',
        // overflowY: 'scroll',
        minWidth: '350px',
        height: '800px',
        padding: '20px',
        backgroundColor: '#FFFFFF',
        [theme.breakpoints.down(TABLET_BREAKPPOINT)]: {
            width: '100%',
            height: '80vh',
        },
    },
    displayFlexCenter: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: '-20px',
        marginBottom: '30px',
    },
    searchField: {
        display: 'flex',
        flexDirection: 'row',
        height: '40px',
        paddingTop: '10px',
        marginBottom: '20px',
    },
    searchFieldHeight: {
        height: '100%',
    },
    searchButton: {
        marginTop: '-5px',
        background: '#54575A',
        borderRadius: '0px',
        color: '#FFFFFF',
        fontSize: '13px',
        width: '79px',
        height: '35px',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: 'rgb(58, 60, 62);',
        },
    },
    locationTypeButton: {
        width: '50%',
        borderRadius: '0',
        boxSizing: 'border-box',
        height: '38px',
        borderLeft: '1px solid #E5E5E5',
        borderRight: '1px solid #E5E5E5',
        borderBottom: '1px solid #E5E5E5',
        borderTop: '5px solid transparent',
        textTransform: 'none',
        backgroundColor: '#E5E5E5',
    },
    selected: {
        borderTop: '5px solid #FFDD30',
        backgroundColor: '#ffffff',
        fontWeight: '800',
    },
    locationMap: {
        padding: '12px 0px 12px 0px',
    },
    notFoundLocator: {
        backgroundColor: '#F9F9F9',
        borderTop: '1px solid #D1D1D1',
        width: '393px',
        height: '195px',
    },
    selectDrybarCopy: {
        background: '#FFFFFF',
        padding: '13px 2px 2px 2px',
        textAlign: 'center',
    },
    moreBtn: {
        color: '#42413D',
        padding: '17px 10px 15px',
        width: '100%',
        maxWidth: '380px',
        border: '0.5px solid #42413D',
        textDecoration: 'none',
        fontSize: '18px',
        lineHeight: '117%',
        borderRadius: 0,
        margin: '10px 0 20px',
        '&:hover': {
            textDecoration: 'none',
        },
    },
    loadingWrap: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        textAlign: 'center',
        paddingTop: '45%',
        backgroundColor: 'rgba(0,0,0,0.2)',
    },
    locationList: {
        height: '680px',
        overflowY: 'auto',
        [theme.breakpoints.down(TABLET_BREAKPPOINT)]: {
            height: 'calc(80vh - 120px)',
        },
    },
});

const SelectLocation = ({
    classes, goToNextPage, setLocation, locationStore, clearBookingDetails, contentfulStores, favoriteStore, setFavoriteStore, isEditEnabled
}) => {
    const [currentLocation, setCurrentLocation] = useState(null);
    const [locationType, setLocationType] = useState('nearby');
    const [storeType, setStoreType] = useState('Drybar Shop');
    const [searchLocation, setSearchLocation] = useState('');
    const [user, setUser] = useState(null);
    const [customerFields, setCustomerFields] = useState([]);
    const [favouriteLocations, setFavouriteLocations] = useState([]);
    const location = useLocation();
    const [isAuthenticated, getUser, getUserId] = useMoktaAuth();

    const queryString = location.search;
    const urlParams = new URLSearchParams(queryString);

    const loadCurrentLocation = () => {
        // if (!navigator.geolocation) return;
        // navigator.geolocation.getCurrentPosition((position) => {
        //     const pos = {
        //         lat: position.coords.latitude,
        //         lng: position.coords.longitude,
        //     };
        //     setCurrentLocation(pos);
        // }, (err) => {
        //     console.log('-- err : ', err);
        // });
    };

    useEffect(() => {
        TagManager.dataLayer(tagManagerArgs);

        // load current location as default;
        if (isAuthenticated()) {
            getUserId().then((res) => {
                setUser(res);
            }).catch((error) => {
                console.log('-- error : ', error);
            });
        }

        loadCurrentLocation();
    }, []);

    useEffect(() => {
        if (favoriteStore) setFavouriteLocations([favoriteStore]);
    }, [favoriteStore]);

    useEffect(async () => {
        if (user) {
            const customerId = user?.bookerID;
            await restClient.post(getCustomerDetailsById(customerId)).then((response) => {
                if (response) {
                    setCustomerFields(response?.data?.Customer?.Customer?.CustomerFieldValues?.FieldValues);
                }
            }).catch((err) => {
                console.log('errr ===>', err);
            });
        }
    }, [user]);

    useEffect(() => {
        if (customerFields && customerFields.length) {
            const getCustomerFieldsKey = customerFields?.filter((hasKey) => hasKey?.Key === 56378);// 56378//52369
            const getCustomerFvtLocationId = getCustomerFieldsKey?.[0]?.Value?.TextValue?.Value || '-1';
            const locations = contentfulStores.filter((hasLoc) => hasLoc?.bookerLocationId === Number(getCustomerFvtLocationId));
            setFavouriteLocations(locations);
        }
    }, [customerFields]);

    // click event handler of book button that is in store location list and map infoview
    const onLocationSelect = (location) => {

        if (window.dtrum) {
            let actionId = window.dtrum.enterAction('location');
            window.dtrum.actionName('StoreSelected', location);
            window.dtrum.addActionProperties(actionId, null, null, { "locationselected": "location is selected", "public": "true" }, null);
            window.dtrum.leaveAction(actionId);
        } else { console.log('Warning: DT logging not connected'); }

        const isNew = urlParams.has('isNew')

        if(!isEditEnabled || isNew)
            clearBookingDetails();
        
        setLocation(location);
        goToNextPage();
        window.scrollTo(0, 0);
    };

    const handleFavoriteSelection = () => {
        mParticle.logEvent('Booking - Set Filter', mParticle.EventType.Other, {
            'Source Page': getPageLabelFromUrl(location.pathname),
            Filter: 'favorite',
        });
        setLocationType('favorite');
    };

    const handleNearbySelection = () => {
        mParticle.logEvent('Booking - Set Filter', mParticle.EventType.Other, {
            'Source Page': getPageLabelFromUrl(location.pathname),
            Filter: 'nearby',
        });
        setLocationType('nearby');
    };

    // key event handler of search box
    const handleLocationSearch = (e) => {
        if (timer != null) {
            clearTimeout(timer);
            timer = null;
        }
        timer = setTimeout(() => {
            setSearchLocation(e.target.value);
            timer = null;
        }, 500);
    };

    const onChangeCurrentLocation = (event, checked) => {
        // if (checked && navigator.geolocation) {
        //     loadCurrentLocation();
        // } else {
        //     setCurrentLocation(null);
        //     if (favoriteStore) {
        //         setFavouriteLocations([favoriteStore]);
        //     }
        // }

        setCurrentLocation(null);
        if (favoriteStore) {
            setFavouriteLocations([favoriteStore]);
        }
    };

    const onViewShop = (locationData) => {
        mParticle.logEvent('Locations - Book an Appointment', mParticle.EventType.Other, {
            'Source Page': 'Location Selector', 'Page Name': 'Location Selector', 'Page Category': 'Locations Find', 'Location ID': locationData.bookerLocationId, 'Location Title': locationData.title, 'Location Address': `${locationData.contact.street1}, ${locationData.contact.city}, ${locationData.contact.state} ${locationData.contact.postalCode}`,
        });
        setCurrentLocation({lat:locationData.contact.coordinates[0],lng:locationData.contact.coordinates[1]})
    };

    const refresh = (refreshData) => {
        if (refreshData.type == 'favorite') {
            setFavouriteLocations([refreshData.selectedStore]);
            setFavoriteStore(refreshData.selectedStore);
        }
    };

    return (
        <>
            <SectionTitle title="SELECT A LOCATION" />
            {/* <Grid className={classes.displayFlexCenter}>
                <Checkbox
                    // defaultChecked
                    color="default"
                    inputProps={{ 'aria-label': 'checkbox with default color' }}
                    onChange={onChangeCurrentLocation}
                    checked={currentLocation != null}
                    style={{ marginTop: '-17px' }}
                />
                <Typography style={{ marginTop: '-6px' }}>
                    Use Current Location
                </Typography>
            </Grid> */}
            <Grid className={classes.container}>
                <Grid className={classes.leftContent}>
                    {/* <Grid className={classes.searchField}>
                        <TextField
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                                classes: {
                                    root: classes.searchFieldHeight,
                                },
                            }}
                            placeholder="City, Country or Postcode"
                            onChange={(e) => handleLocationSearch(e)}
                        />
                        <Button className={classes.searchButton} variant="outlined">Search</Button>
                    </Grid> */}
                    {/* <Grid>
                        <Button variant="outlined" className={`${classes.locationTypeButton} ${locationType === 'nearby' ? classes.selected : ''}`} onClick={handleNearbySelection}>
                            Nearby
                        </Button>
                        <Button variant="outlined" className={`${classes.locationTypeButton} ${locationType === 'favorite' ? classes.selected : ''}`} onClick={handleFavoriteSelection}>
                            Favourite
                        </Button>
                    </Grid> */}

                    <LocationNearbyFavourite
                        classes={classes}
                        locationType={locationType}
                        storeType={storeType}
                        searchLocation={searchLocation}
                        currentLocation={currentLocation}
                        isBooking
                        onLocationSelect={onLocationSelect}
                        authUserDetail={user}
                        favouriteLocations={favouriteLocations}
                        contentfulStores={contentfulStores}
                        refresh={refresh}
                        onViewShop={onViewShop}
                    />

                </Grid>
                <Grid className={classes.mapContainer}>
                    <MapContainer onLocationSelect={onLocationSelect} locationType={locationType} currentLocation={currentLocation} />
                </Grid>
            </Grid>
        </>
    );
};

const mapStateToProps = (state) => ({
    locationStore: getLocationData(state),
    favoriteStore: getFavoriteStore(state),  
    isEditEnabled: getIsEditEnabled(state),
});

const mapDispatchToProps = (dispatch) => ({
    setLocation: bindActionCreators(setLocationData, dispatch),
    clearBookingDetails: bindActionCreators(clearBookingDetails, dispatch),
    setFavoriteStore: bindActionCreators(setFavoriteStore, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withOktaAuth(SelectLocation)));
