import {
    queryStoresCollection,
    queryStoresCollectionFromContentful,
} from './contentfulBarflyQueries';
import { doQuery } from '../../state/utils/contentful';

export const gqlLoadStores = async () => {
    //const data = await doQuery(queryStoresCollection());
    const data = await doQuery(queryStoresCollectionFromContentful(), true);
    //let stores = data?.storeCollection?.items || [];
    let stores = data?.drybarPageCollection?.items?.[0]?.formatofPageCollection?.items || [];
    stores = mapStoreData(stores);
    stores = stores.filter((store) => store.bookerLocationId);
    
    return stores;
};

const mapStoreData = (stores) => {
    return stores?.map((store) => {
        return {
            title: store.title,
            number: store.number,
            bookerLocationId: process.env.REACT_APP_DRYBAR_ENV === 'production' ? store.bookerLocationId : store.sandboxLocationId,
            type: store.storeType ? 'Drybar Shop' : '',
            information: parseAnswer(store.operatingMessage),
            contact: {
                city: store.city,
                state: store.county,
                region: store.county,
                social: {
                    instagram: null,
                    twitter: null,
                    facebook: null,
                },
                country: store.country,
                street1: store.street1,
                street2: store.street2,
                postalCode: store.postalCode,
                coordinates: [store.latitude, store.longitude],
                phoneNumber: store.phoneNumber,
            },
            slug: store.slugPrefix,
            settings: {
                enabled: store.enabled,
                visible: store.visibleOn,
                bookable: store.allowBookings,
                operatingHours: [
                    ['Mon - Wed', store.timerange1],
                    ['Thur - Sat', store.timeRange2],
                    ['Sun', store.timeRange3],
                ],
                operatingStatus: 'OPERATIONAL',
                operatingMessage: parseAnswer(store.operatingMessage),
            },
            arrivalInformation: parseAnswer(store.parkingInformation),
            storeFrontImage: null,
            block730: true,
        };
    });
}

const parseAnswer = (resultedText) => {
    if(!resultedText) {
        return '';
    }
    
    const linkRegex = /\[([^\]]+)\]\(([^\)]+)\)/g;
    resultedText = resultedText?.replace(linkRegex, (match, p1, p2) => {
        return `<a href="${p2?.replace(']', '')}">${p1}</a>`;
    });

    const boldRegex = /__(.*?)__/g;
    resultedText = resultedText.replace(boldRegex, (match, p1) => {
        return `<strong>${p1}</strong>`;
      });

    return resultedText;
};