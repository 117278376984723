/* eslint-disable max-len */
import {
    Button,
    Grid,
    Typography,
    withStyles,
    CircularProgress,
    Backdrop,
    ListItem,
} from '@material-ui/core';
import { object } from 'prop-types';
import React, { useEffect, useState } from 'react';
// import EditableTextField from '../../app/Components/EditableTextField/EditableTextField';
// import creditBarflyEclipse from '../../assets/images/creditBarflyEclipse.svg';
import MembershipSignupModal from 'app/Components/MembershipSignupModal';
import BarflyContainer from '../../BarflyMembership/GraphqlBarflyMembership';
import {
    MOBILE_BREAKPOINT,
    MEDIUM_MOBILE_BREAKPOINT,
} from '../../../Helpers/breakpoints';
import { useFetchAsync } from '../../../Helpers/useFetch';
import { findCustomerMemberships } from '../../../api/booking-api';
import {
    screenBarfly as BarflyMembership,
    barflyPageQuery,
    fetchBarflyMemberships,
} from '../../../gql/queries/barflyMembership';
import { doQuery } from '../../../state/utils/contentful';
import { useQuery } from '@apollo/client';
import { getChosenStore } from '../../../state/ducks/Barfly/Barfly-Selectors';
import { connect } from 'react-redux';

const styles = (theme) => ({
    container: {
        background: '#fff',
        marginLeft: '15px',
        padding: '34px 22px',
        textAlign: 'center',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            background: '#f9f9f9',
            marginLeft: 0,
            textAlign: 'left',
        },
        minHeight: '577px',
    },
    heading: {
        fontWeight: '800',
        float: 'left',
        paddingBottom: '18px',
        fontSize: '22px',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            float: 'unset',
            textTransform: 'uppercase',
            fontFamily: 'MrsEavesOT-Roman',
            fontWeight: 'normal',
            marginBottom: '10px !important',
        },
    },
    formContainer: {
        margin: '46px 0 0',
        width: '100%',
        backgroundColor: '#F9F9F9F9',
        borderTop: '1px solid #D1D1D1',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            marginTop: 20,
            backgroundColor: '#fff',
            height: 'unset',
            textAlign: 'left',
        },
    },
    formContainer1: {
        margin: '30px 0 0',
        width: '100%',
        height: '60px',
        backgroundColor: '#F9F9F9F9',
        borderTop: '1px solid #D1D1D1',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            margin: '10px 0',
            padding: '0 14px 20px',
            backgroundColor: '#fff',
            height: 'unset',
            textAlign: 'left',
        },
    },
    breflyMembershipBenefits: {
        margin: '20px 2px 2px 2px',
        padding: '20px 0',
        backgroundColor: '#F9F9F9F9',
        borderTop: '1px solid #D1D1D1',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            background: '#FFFFFF !important',
        },
    },
    button: {
        width: '378px',
        height: '63px',
        maxWidth: '100%',
        margin: '20px 0 48px',
    },
    emailPref: {
        textAlign: 'left',
        margin: '32px 0 0',
    },
    emailTypography: {
        margin: '10px 0 20px',
        color: '#979797',
    },
    signatureCopy: {
        textAlign: 'left',
        margin: '4px 2px 2px 17px !important',
        fontSize: '22px',
        fontWeight: '600',
        [theme.breakpoints.down(MEDIUM_MOBILE_BREAKPOINT)]: {
            marginLeft: '14px !important',
        },
    },
    benefits: {
        textAlign: 'left',
        margin: '4px 2px 2px 30px !important',
        fontSize: '16px',
        fontWeight: '500',
        fontFamily: 'URWForm',
        [theme.breakpoints.down(MEDIUM_MOBILE_BREAKPOINT)]: {
            marginLeft: '14px !important',
        },
    },
    availableBenefits: {
        textAlign: 'left',
        margin: '4px 2px 2px 17px !important',
        fontSize: '16px',
        fontWeight: '600',
        fontFamily: 'URWForm',
        [theme.breakpoints.down(MEDIUM_MOBILE_BREAKPOINT)]: {
            marginLeft: '14px !important',
        },
    },
    membershipName: {
        textAlign: 'left',
        margin: '4px 2px 2px 17px !important',
        fontSize: '22px',
        fontWeight: '600',
        float: 'left',
        [theme.breakpoints.down(MEDIUM_MOBILE_BREAKPOINT)]: {
            marginLeft: '14px !important',
        },
    },
    membershipPricePerMonth: {
        textAlign: 'left',
        margin: '4px 2px 2px 17px !important',
        fontSize: '22px',
        fontWeight: '600',
        float: 'right',
        [theme.breakpoints.down(MEDIUM_MOBILE_BREAKPOINT)]: {
            marginLeft: '14px !important',
        },
    },

    billPeriodContainer: {
        padding: 6,
        borderTop: '0.5px solid #979797',
        marginTop: '50px',
    },
    nextBillDate: {
        fontSize: '16px',
        fontWeight: '500',
        fontFamily: 'URWForm',
        textAlign: 'left',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            whiteSpace: 'nowrap',
            textAlign: 'center',
        },
        [theme.breakpoints.down(MEDIUM_MOBILE_BREAKPOINT)]: {
            textAlign: 'left',
        },
    },
    lastBillDate: {
        fontSize: '16px',
        fontWeight: '500',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            whiteSpace: 'nowrap',
            textAlign: 'center',
        },
        [theme.breakpoints.down(MEDIUM_MOBILE_BREAKPOINT)]: {
            textAlign: 'left',
        },
    },
    bulletPoints: {
        listStyle: 'disc',
        display: 'list-item',
        marginLeft: '20px',
        marginBottom: '10px',
    },
    billDateContainer: {
        padding: 14,
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            padding: 8,
        },
        [theme.breakpoints.down(MEDIUM_MOBILE_BREAKPOINT)]: {
            borderRight: '0',
        },
    },
    borderLeftMobile: {
        padding: 14,
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            padding: 8,
        },
    },
    barflyEclipse: {
        margin: '0px 0px 0px -56px',
    },
    creditNumberEclipse: {
        marginTop: '-91px',
    },
    serviceInclude: {
        width: '432px',
        height: '38px',
        backgroundColor: '#E5E5E5',
        margin: '8px 12px 0px 38px',
        borderRadius: '4px',
        paddingTop: '7px',
    },
    theSignature: {
        width: '108px',
        height: '38px',
        backgroundColor: '#E5E5E5',
        margin: '8px 12px 0px 0px',
        borderRadius: '4px',
        paddingTop: '7px',
    },
    serviceIncludeInfo: {
        backgroundColor: '#F9F9F9',
        width: '432px',
        height: '79px',
        margin: '8px 12px 0px 38px',
        paddingTop: '7px',
    },
    theSignatureNumber: {
        width: '108px',
        height: '79px',
        backgroundColor: '#FFFFFF',
        margin: '8px 12px 0px 0px',
        borderRadius: '4px',
        paddingTop: '7px',
        border: '2.5px solid #FFDD30',
        filter: 'drop-shadow(0px 0px 7px rgba(0, 0, 0, 0.1))',
    },
    costTotal: {
        width: '432px',
        height: '50px',
        backgroundColor: '#E5E5E5',
        margin: '8px 12px 0px 38px',
        paddingTop: '7px',
    },
    theSignatureTotal: {
        width: '108px',
        height: '50px',
        backgroundColor: '#54575A',
        margin: '8px 12px 0px 0px',
        paddingTop: '12px',
        color: '#FFFFFF',
        fontSize: '18px',
    },
    thePrimiumTotal: {
        width: '108px',
        height: '50px',
        backgroundColor: '#E5E5E5',
        margin: '8px 12px 0px 0px',
        paddingTop: '12px',
        fontSize: '18px',
    },
    planInfo: {
        display: 'flex',
        margin: '2% 0px 0px 61%',
    },
    yourPlanCopy: {
        margin: '0px 88px 0px 0px',
    },
    cancelMembership: {
        color: '#979797',
        borderBottom: '0.5px solid #979797',
        width: '24%',
        margin: '0 auto !important',
        cursor: 'pointer',
    },
    nameAndPrice: {},
    noMembership: {
        fontSize: '16px',
        marginTop: '30px',
        marginLeft: '18px',
        fontWeight: '700',
        fontFamily: 'URWForm',
        width: '100%',
        textAlign: 'left',
        padding: '20px !important',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            marginTop: '20px !important',
            marginLeft: 0,
            padding: 'unset !important',
        },
    },
    noMembership1: {
        fontSize: '22px',
        marginTop: '30px',
        marginLeft: '18px',
        fontWeight: '600',
        fontFamily: 'URWForm',
        width: '100%',
        textAlign: 'left',
        padding: '20px 0 0 20px !important',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            marginTop: '20px !important',
            marginLeft: 0,
            padding: 'unset !important',
        },
    },
    contactMemberhip: {
        marginTop: '40px !important',
    },
});

/**
 * Static data - make dynamic when connected with API
 */
const AccountBarflyMembership = ({
    classes,
    user,
    contentfulStores,
    selectedStore,
}) => {
    const [customerMembership, setCustomerMembership] = useState([]);
    const [addOns, setAddOns] = useState([]);
    const [remainingAddOns, setRemainingAddOns] = useState([]);
    const [remainingBlowDries, setRemainingBlowDries] = useState([]);
    const [membershipSignupModal, setMembershipSignupModal] = useState(false);
    const [membershipCollection, setMembershipCollection] = useState([]);
    // const BARFLY_MEMBERSHIP = BarflyMembership();
    // const { data, error, loading} = useQuery(BARFLY_MEMBERSHIP);

    useEffect(async () => {
        const locationId = contentfulStores?.[0]?.bookerLocationId;
        const customerId = user?.bookerID;
        if (locationId && customerId) {
            const { data } = await useFetchAsync(
                findCustomerMemberships(locationId, customerId)
            );
            if (data?.IsSuccess) {
                const blowDries = data?.Results?.filter(
                    (item) => item.IsBlowdryBenefit
                );
                const addOns = data?.Results?.filter(
                    (item) => item.IsAddOnBenefit
                );
                countRemainingBenefit(blowDries, setRemainingBlowDries);
                countRemainingBenefit(addOns, setRemainingAddOns);
                const activeMembership = blowDries.filter(
                    (item) => item.IsActiveOrSuspended
                );
                if (activeMembership.length > 0) {
                    setCustomerMembership(activeMembership[0]);
                } else {
                    setCustomerMembership(blowDries[0]);
                }
                setAddOns(addOns);
            }
        }
    }, [contentfulStores, user]);

    const countRemainingBenefit = (benefits, updateState) => {
        var benefitsCount = 0;
        for (var i = 0; i < benefits.length; i++) {
            benefitsCount += benefits?.[i]?.AvailableQuantity;
        }
        updateState(benefitsCount);
    };

    useEffect(async () => {
        const data = await doQuery(barflyPageQuery(), true);
        const pageData = data.drybarPageCollection?.items[0];

        const firstLevelIds = pageData.formatofPageCollection?.items?.map(
            (item) => item.sys.id
        );

        let items = {};

        if (firstLevelIds.length > 1) {
            items = await doQuery(
                fetchBarflyMemberships(firstLevelIds[1]),
                true
            );
        }

        const membershipGrid = items?.drybarMembershipGrid;
        const membershipCollection = membershipGrid?.membershipCardsCollection?.items?.map(
            (item) => {
                return {
                    benefitsCollection: {
                        items: item?.benefitType?.map((b, index) => {
                            return {
                                name: b,
                                value: item?.benefitDescription?.[index],
                            };
                        }),
                    },
                    price: item.price,
                    subtitle: item.subtitle,
                    title: item.title,
                };
            }
        );

        return setMembershipCollection(membershipCollection);
    }, []);

    const lastBillDate = new Date(customerMembership?.LastChargeDateOffset)
        ?.toLocaleDateString('en-GB')
        ?.replace(',', '/');
    const nextBillDate = new Date(customerMembership?.NextChargeDateOffset)
        ?.toLocaleDateString('en-GB')
        ?.replace(',', '/');
    const cancellationDate = new Date(customerMembership?.CancelledDate)
        ?.toLocaleDateString('en-GB')
        ?.replace(',', '/');
    const isCustomerMembership =
        customerMembership?.MembershipBenefit?.MembershipLevelID || '';
    const membershipPrice = customerMembership?.TotalFee || '';
    const membershipSingle = customerMembership?.LevelName?.toLowerCase()?.includes(
        'single'
    );

    return (
        <>
            <Grid className={classes.container}>
                <Typography className={classes.heading}>
                    Your Membership
                </Typography>
                <Grid className={classes.formContainer}>
                    {customerMembership?.HasAutopay ? (
                        <Grid className={classes.nameAndPrice}>
                            <Typography className={classes.membershipName}>
                                {customerMembership.MembershipName}
                            </Typography>
                            <Typography
                                className={classes.membershipPricePerMonth}
                            >
                                £{customerMembership.TotalFee}/month
                            </Typography>
                        </Grid>
                    ) : null}
                    <Grid container className={classes.billPeriodContainer}>
                        {customerMembership?.IsActiveOrSuspended ? (
                            <>
                                <Grid
                                    item
                                    xs={12}
                                    sm={4}
                                    md={4}
                                    className={classes.billDateContainer}
                                >
                                    <Typography
                                        className={classes.nextBillDate}
                                    >
                                        Next bill date: {nextBillDate}
                                    </Typography>
                                    <Typography
                                        className={classes.nextBillDate}
                                    >
                                        Last bill date: {lastBillDate}
                                    </Typography>
                                    <Typography
                                        className={classes.nextBillDate}
                                    >
                                        Preferred Shop:{' '}
                                        {customerMembership.LocationText}
                                    </Typography>
                                </Grid>
                            </>
                        ) : customerMembership?.IsCancelled ? (
                            <>
                                <Grid
                                    item
                                    xs={12}
                                    sm={4}
                                    md={4}
                                    className={classes.billDateContainer}
                                >
                                    <Typography
                                        className={classes.nextBillDate}
                                    >
                                        Preferred Shop:{' '}
                                        {customerMembership.LocationText}
                                    </Typography>
                                    <Typography
                                        className={classes.nextBillDate}
                                    >
                                        Plan Cancelled On: {cancellationDate}
                                    </Typography>
                                    <Typography
                                        className={classes.nextBillDate}
                                    >
                                        Last bill date: {lastBillDate}
                                    </Typography>
                                </Grid>
                            </>
                        ) : (
                            <Typography className={classes.noMembership}>
                                You don't have a current membership.
                            </Typography>
                        )}
                    </Grid>
                </Grid>
                <Typography className={classes.signatureCopy}>
                    Your Benefits
                    <Grid></Grid>
                </Typography>
                {customerMembership?.HasAutopay && (
                    <Grid className={classes.breflyMembershipBenefits}>
                        <Typography className={classes.availableBenefits}>
                            Available Benefits
                        </Typography>
                        {remainingBlowDries > 0 ? (
                            <div className={classes.benefits}>
                                <div className={classes.bulletPoints}>
                                    {remainingBlowDries}{' '}
                                    {remainingBlowDries === 1
                                        ? ' free blow-dry'
                                        : ' free blow-dries'}
                                </div>
                            </div>
                        ) : null}
                        {remainingAddOns > 0 ? (
                            <div className={classes.benefits}>
                                <div className={classes.bulletPoints}>
                                    {' '}
                                    {remainingAddOns}{' '}
                                    {remainingAddOns === 1
                                        ? ' free add-on'
                                        : ' free add-ons '}
                                </div>
                            </div>
                        ) : null}
                        {membershipSingle
                            ? membershipCollection?.[0]?.benefitsCollection.items.map(
                                  (item) =>
                                      item?.value
                                          ?.toLowerCase()
                                          ?.includes('add-on') ? null : (
                                          <div className={classes.benefits}>
                                              <div
                                                  className={
                                                      classes.bulletPoints
                                                  }
                                              >
                                                  {item.name} {item.value}
                                              </div>
                                          </div>
                                      )
                              )
                            : membershipCollection?.[1]?.benefitsCollection.items.map(
                                  (item) =>
                                      item?.value
                                          ?.toLowerCase()
                                          ?.includes('add-on') ? null : (
                                          <div className={classes.benefits}>
                                              <div
                                                  className={
                                                      classes.bulletPoints
                                                  }
                                              >
                                                  {item.name} {item.value}
                                              </div>
                                          </div>
                                      )
                              )}
                    </Grid>
                )}

                <Grid className={classes.formContainer1}>
                    <Typography className={classes.noMembership1}>
                        Barfly Membership Options
                    </Typography>
                </Grid>
                <BarflyContainer
                    isCustomerMembership={isCustomerMembership}
                    AccountMembershipPrice={membershipPrice}
                    isMembership={customerMembership?.HasAutopay}
                    handleUpgradePremium={() =>
                        setMembershipSignupModal('upgrade')
                    }
                />

                {/*<Typography className={`${classes.clickHereText} ${classes.contactMemberhip}`}>*/}
                {/*    If you’d like to suspend or cancel your membership today, please*/}
                {/*    {' '}*/}
                {/*    <Button*/}
                {/*        variant="text"*/}
                {/*        className={classes.cancelButton}*/}
                {/*        onClick={() => setMembershipSignupModal('cancel')}*/}
                {/*    >*/}
                {/*        click here*/}
                {/*    </Button>*/}
                {/*</Typography>*/}
            </Grid>

            {membershipSignupModal && (
                <MembershipSignupModal
                    onClose={() => setMembershipSignupModal(false)}
                    membershipAction={membershipSignupModal}
                />
            )}
        </>
    );
};

AccountBarflyMembership.propTypes = {
    classes: object.isRequired,
};

const mapStateToProps = (state) => ({
    selectedStore: getChosenStore(state),
});

export default withStyles(styles)(
    connect(mapStateToProps)(AccountBarflyMembership)
);

{
    /* <Grid container>
            <Grid item xs={6} className={classes.serviceInclude}><Typography>Services Included</Typography></Grid>
            <Grid item xs={2} className={classes.theSignature}>The Signature</Grid>
            <Grid item xs={2} className={classes.theSignature}>The Premium</Grid>
        </Grid>

        {/* // currently this is static in future will add logic here */
}
// {/* <Grid container>
//             <Grid item xs={6} className={classes.serviceIncludeInfo}><Typography>Number of Blowouts lorem ipsum</Typography></Grid>
//             <Grid item xs={2} className={classes.theSignatureNumber}><Typography>2</Typography></Grid>
//             <Grid item xs={2} className={classes.theSignatureNumber}><Typography>4</Typography></Grid>
//         </Grid>
//         <Grid container>
//             <Grid item xs={6} className={classes.costTotal}><Typography>Cost</Typography></Grid>
//             <Grid item xs={2} className={classes.theSignatureTotal}><Typography>$75</Typography></Grid>
//             <Grid item xs={2} className={classes.thePrimiumTotal}>$135</Grid>
//         </Grid>
//         <Grid className={classes.planInfo}>
//             <Typography className={classes.yourPlanCopy}>Your Plan</Typography>
//             <InfoIcon />
//         </Grid>
//         <Button className={classes.button} variant="contained" color="secondary">
//             Upgrade to Premium
//         </Button>
//     </Grid>
// );
