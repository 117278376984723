/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Box, Button, Grid, IconButton, Typography,
} from '@material-ui/core';
import RemoveCircleOutlineRoundedIcon from '@material-ui/icons/RemoveCircleOutlineRounded';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import axios from 'axios';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import TagManager from 'app/Components/GoogleTagManager';
import config from 'app.config';

import { useLocation } from 'react-router-dom';
import SummaryWrapper from './SummaryWrapper';
import { resetOnGuestChange, setNumberOfGuests } from '../../state/ducks/Booking/Booking-Actions';
import { getNumberOfGuests, getBuyoutLocation } from '../../state/ducks/Booking/Booking-Selectors';
import { MOBILE_BREAKPOINT, TABLET_BREAKPPOINT } from '../../Helpers/breakpoints';
import ContactModal from '../../app/Components/ContactModal';
import appConfig from '../../app.config';
import { googleAnalyticsEvents } from 'state/utils/googleAnalyticsEvents';

const { gtm: { booking } } = config;
const tagManagerArgs = {
    dataLayer: {
        event: booking.event,
        form_type: booking.type,
        form_step: booking.step2,
    },
};

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        padding: '60px',
        [theme.breakpoints.down('sm')]: {
            padding: 0,
        },
    },
    buttonsWrapper: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            // paddingTop: 'calc((100vh - 55px - 100px - 250px) / 2)',
            height: 'auto',
            paddingTop: '20px',
        },
    },
    button: {
        width: '438px',
        height: '73px',
        margin: '12px 0',
        backgroundColor: theme.palette.common.white,
        textTransform: 'none',
        fontSize: '18px',
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        },
        [theme.breakpoints.down('sm')]: {
            margin: '0 auto 20px',
            maxWidth: '100%',
            width: '373px',
            height: '63px',
        },

    },
    selected: {
        backgroundColor: theme.palette.primary.main,
        fontWeight: '800',
        '&:hover': {
            backgroundColor: theme.palette.common.hover[1],
        },
    },
    mobileOnly: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
        },
    },
    selectGuest: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        alignItems: 'center',
        margin: '50px 0',
        [theme.breakpoints.down('sm')]: {
            margin: '20px 0',
        },
    },
    text: {
        textAlign: 'center',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            whiteSpace: 'nowrap',
            // margin: '0px 0px 0px 232px !important',
        },
    },
    icons: {
        fontSize: '40px',
        fontWeight: '100',
    },
    iconButtons: {
        '&:focus':{
            outline: 'red'
        }
    },
    changeGuestButtons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '30px 0',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            // margin: '0px 0px 0px 232px',
        },
    },
    numberOfGuests: {
        fontSize: '26px',
        fontWeight: 800,
        display: 'flex',
        margin: '0 33px',
        paddingTop: '3px',
    },
    moreGuestButton: {
        margin: 'auto',
    },
}));

const HowManySummary = ({
                            totalGuests,
                            setTotalGuests,
                            goToNextPage,
                            resetData,
                            fromBuyout,
                        }) => {
    const [contactModal, setContactModal] = useState(false);
    const classes = useStyles();
    const [preferredShopChoices, setPreferredShopChoices] = useState([]);
    const [preferredStartTimeChoices, setPreferredStartTimeChoices] = useState([]);
    const [partySizeChoices, setPpartySizeChoices] = useState([]);
    const [occasions, setOccasions] = useState([]);
    const location = useLocation();

    useEffect(() => {
        TagManager.dataLayer(tagManagerArgs);
        axios.post(appConfig.apiUrl + '/forms/getForms', {
            subDomain: appConfig.wufoo.subDomain,
            formId: appConfig.wufoo.genericPartyFormId,
            key: btoa(`${appConfig.wufoo.apiKey }:password`),
        })
            .then((res) => {
                const { data } = res;
                const fields = data?.Fields || [];
                fields.forEach((field) => {
                    if (field.Title === 'Preferred Shop') {
                        setPreferredShopChoices(field.Choices.map((choice) => choice.Label || ''));
                    } else if (field.Title === 'Time') {
                        setPreferredStartTimeChoices(field.Choices.map((choice) => choice.Label || ''));
                    } else if (field.Title === 'Party Size') {
                        setPpartySizeChoices(field.Choices.map((choice) => choice.Label || ''));
                    } else if (field.Title === 'Occasion') {
                        setOccasions(field.Choices.map((choice) => choice.Label || ''));
                    }
                });
            })
            .catch((error) => console.log('error in wufoo api ', error));
    }, []);

    const handleMeClick = () => {
        if (totalGuests > 0) {
            resetData();
        }
        setTotalGuests(0);
        handleHowManySelected();
    };

    useEffect(() => {
        if (fromBuyout) {
            handleMeClick();
        }
    }, [fromBuyout, handleMeClick]);

    const addGuest = () => {
        if (totalGuests < 4) {
            setTotalGuests(totalGuests + 1);
            resetData();
        }
    };

    const removeGuest = () => {
        if (totalGuests > 1) {
            setTotalGuests(totalGuests - 1);
            resetData();
        }
    };

    const handleGuestsClick = () => {
        if (totalGuests < 1) {
            addGuest();
        }
    };
    
    const handleHowManySelected = () => {
        gtag(googleAnalyticsEvents.EVENT, googleAnalyticsEvents.BOOKING_ATTENDEE, {
            attendees: totalGuests + 1
        });
        goToNextPage();
    }

    return (
        <SummaryWrapper
            title="WHO&apos;S COMING?"
            nextButtonEnabled={totalGuests}
            onButtonClick={handleHowManySelected}
        >
            <Grid className={classes.container}>
                <Grid className={classes.buttonsWrapper}>
                    <Button
                        onClick={handleMeClick}
                        className={`${classes.button} ${totalGuests === 0 ? classes.selected : ''}`}
                        variant="outlined"
                    >
                        Just Me
                    </Button>
                    <Button
                        onClick={() => handleGuestsClick()}
                        className={`${classes.button} ${totalGuests > 0 ? classes.selected : ''}`}
                        variant="outlined"
                    >
                        {"Me & Guest"}
                    </Button>
                </Grid>
                {totalGuests > 0 ? (
                    <Grid className={classes.selectGuest}>
                        <Typography className={classes.text}>
                            <Box fontSize="18px" marginBottom="12px">
                                How Many Guests?
                            </Box>
                            <Box fontSize="15px" fontStyle="oblique">
                                You may book up to 4 guests.
                            </Box>
                        </Typography>
                        <Grid className={classes.changeGuestButtons}>
                            <IconButton onClick={removeGuest} className={classes.iconButtons}>
                                <RemoveCircleOutlineRoundedIcon className={classes.icons} />
                            </IconButton>
                            <Typography className={classes.numberOfGuests}>
                                {totalGuests}
                            </Typography>
                            <IconButton onClick={addGuest} className={classes.iconButtons}>
                                <AddCircleOutlineRoundedIcon className={classes.icons} />
                            </IconButton>
                        </Grid>
                        <Button
                            onClick={handleHowManySelected}
                            className={`${classes.button} ${classes.selected} ${classes.mobileOnly}`}
                            variant="outlined"
                        >
                            Next
                        </Button>
                        <Grid style={{ textAlign: 'center' }}>
                            <Button
                                variant="text"
                                className={classes.moreGuestButton}
                                onClick={() => setContactModal(true)}
                            >
                                I want to book more than 4
                            </Button>
                            {contactModal ? (
                                <ContactModal
                                    preferredShopChoices={preferredShopChoices}
                                    preferredStartTimeChoices={preferredStartTimeChoices}
                                    partySizeChoices={partySizeChoices}
                                    occasions={occasions}
                                    onClose={() => setContactModal(false)}
                                />
                            ) : null}
                        </Grid>
                    </Grid>
                ) : null}
            </Grid>
        </SummaryWrapper>
    );
};

const mapStateToProps = (state) => ({
    totalGuests: getNumberOfGuests(state),
    fromBuyout: getBuyoutLocation(state),
});

const mapDispatchToProps = (dispatch) => ({
    setTotalGuests: bindActionCreators(setNumberOfGuests, dispatch),
    resetData: bindActionCreators(resetOnGuestChange, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HowManySummary);
