import {
 call, fork, put, takeLatest,
} from 'redux-saga/effects';
import { loadBuyoutContentful, setBuyoutContent } from './Buyout-Actions';
import { gqlLoadBuyout } from '../../../gql/buyout/contentfulBuyoutActions';

function* workerLoadBuyoutContentful({ payload }) {
    const buyoutSectionData = yield call(gqlLoadBuyout, { slug: payload });
    yield put(setBuyoutContent(buyoutSectionData));
}

function* watcherLoadBuyoutContentful() {
    yield takeLatest(loadBuyoutContentful().type, workerLoadBuyoutContentful);
}

export const watchers = {
    watcherLoadBuyoutContentful: fork(watcherLoadBuyoutContentful),
};

export default {
    watchers,
};
