import React, { Component } from 'react';
import Slider from 'react-slick';
import { Hyperlink } from '../../app/Components/Links/Hyperlink';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { connect } from 'react-redux';
import heartImage from '../../assets/images/ic-heart.png';

import { getTheStylesData } from '../../state/ducks/Home/Home-Selectors';
import arrowLeftIcon from '../../assets/images/arrow-left-1.svg';
import arrowRightIcon from '../../assets/images/arrow-right-1.svg';

import {
  Title, Image, CardBody, CardContainer, Link,
} from '../../components/card';
import { HashLink } from 'react-router-hash-link';

const NextArrow = (props) => {
    const { onClick } = props;
    return (
        <button className="slick-arrow right" onClick={onClick}>
            <img src={arrowLeftIcon} alt="slick arrow right" />
        </button>
    );
};
const PrevArrow = (props) => {
    const { onClick } = props;
    return (
        <button className="slick-arrow left" onClick={onClick}>
            <img src={arrowRightIcon} alt="slick arrow left" />
        </button>
    );
};

class StylesSectionView extends Component {
    constructor(props) {
        super(props);

        this.renderCarousel = this.renderCarousel.bind(this);
        this.getSlide = this.getSlide.bind(this);
    }

    getSlide(sliderData, isScreen, index) {
        // TODO -- make the <Link> more dynamic
        // For example, /the-styles should not be hard coded
        // Get the action from the card when its not a style
        // TODO -- make the split between rendering a card and a style
        // more explicit
        
        return isScreen 
        ? (
            <div className="d-flex flex-wrap slider-wrapper"  key={`styles-section-view-${index}`}>
                {sliderData.map((item, index) => (
                    
                    <div className="col-sm-6 col-md-6 slider-wrap" key={index}>
                        <div className="row">
                            <div className="inner-slide-item">
                                <CardContainer data={item} className="d-flex position-relative">
                                    <Link component={HashLink} to={`/the-styles/#${item.slug ? item.slug : ''}`} className="slider_info">
                                        <Image data={sliderData} className="img-fluid card-img-top" />
                                        <CardBody data={sliderData} className="card-img-overlay t_img">
                                            <Title data={sliderData} className="text-uppercase" as="span" />
                                        </CardBody>
                                    </Link>
                                </CardContainer>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        ) : (
            <div className="inner-slide-item"  key={`styles-section-view-${index}`}>
                <CardContainer data={sliderData} className="d-flex position-relative">
                    <Hyperlink to={`/the-styles/#${sliderData.slug}`} className="slider_info">
                        <Image data={sliderData} className="img-fluid card-img-top" />
                        <CardBody data={sliderData} className="card-img-overlay t_img">
                            <Title data={sliderData} className="text-uppercase" as="span" />
                        </CardBody>
                    </Hyperlink>
                </CardContainer>
            </div>
        );
    }

    renderCarousel() {
        const slickSettings = {
            dots: false,
            arrows: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            initialSlide: 0,
            responsive: [
                {
                    breakpoint: 1028,
                    settings: {
                        slidesToShow: 2,
                    },
                },
                {
                    breakpoint: 700,
                    settings: {
                        slidesToShow: 2,
                    },
                },
                {
                    breakpoint: 504,
                    settings: {
                        slidesToShow: 1,
                    },
                },
            ],
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />,
        };

        const isScreen = window.innerWidth > 1028;

        const items = this.props.stylesData.items.filter(item => item.image?.url?.length > 0);

        if(!items.length) {
            return null;
        }

        const countPerPage = isScreen ? 4 : 1;
        const groups = [];
        for (let i = 0; i < items.length; i += countPerPage) {
            const group = [];
            for (let j = 0; j < Math.min(countPerPage, items.length - i); j++) {
                group.push(items[i + j]);
            }
            groups.push(group);
        }

        return (
            <Slider {...slickSettings}>
                {isScreen 
                    ? groups.map((group, index) => this.getSlide(group, isScreen, index))
                    : items.map((item, index) => this.getSlide(item, isScreen, index))}
            </Slider>
        );
    }

    render() {
        if (!this.props.stylesData.items) {
            return <div />;
        }

        const {
            title, subtitle, action, items,
        } = this.props.stylesData;

        return (
            <div className="the_styles_section">
                <div className="border-line">
                    <div className="border-line-img">
                        <img src={heartImage} alt="Heart Icon" />
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-12 my-auto">
                            <div className="the_styles">
                                <h3>{title.toUpperCase()}</h3>
                                <p>{subtitle}</p>
                                <Hyperlink
                                    className="see_all"
                                    to={action.link}
                                >
                                    {action.title}
                                </Hyperlink>
                            </div>
                        </div>
                        <div className="col-lg-9 col-md-12 styles-show">
                            {this.renderCarousel()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    stylesData: getTheStylesData(state),
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(StylesSectionView);
