/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { doQuery, getScreenCollectionPreviewMode } from '../../../state/utils/contentful';
import '../../../assets/css/style.scss';
import '../../../assets/css/style-responsive.scss';
import './extension-policy.scss';

const ExtensionPolicy = () => {
    const [content, setContent] = useState(null);

    const query = `{
        screenCollection(${getScreenCollectionPreviewMode()}, where: {slug: "extension-policy"}) {
          items {
            title
            description{
                json
            }
          }
        }
      }`;
      useEffect(() => {
        doQuery(query)
            .then((data) => {
                setContent(data?.screenCollection?.items?.[0]);
            });
    }, []);

    const newLineText = (text) => {
        const textArr = text.split('\n');
        if (text == '') return <></>;

        let newText;
        if (textArr.length > 1) {
            newText = textArr.map((str) => (
                <span>
                    {str}
                    <br />
                </span>
));
        } else {
            newText = <span>{text}</span>;
        }
        return newText;
    };

    const parseNode = (node) => {
        if (node) {
            let subNode = '';
            if (node?.content?.length > 0) {
                subNode = node.content.map((subnode) => parseNode(subnode));
            } else {
                subNode = node.value;
            }

            if (node.nodeType == 'text') {
                return <span>{newLineText(subNode)}</span>;
            } if (node.nodeType == 'hr') {
                return <hr />;
            } if (node.nodeType == 'paragraph') {
                return <p>{subNode}</p>;
            } if (node.nodeType == 'heading-3') {
                return <h3>{subNode}</h3>;
            } if (node.nodeType == 'hyperlink') {
                return <a>{subNode}</a>;
            } if (node.nodeType == 'document') {
                return <>{subNode}</>;
            }
                console.log('---------- : ', node);
        } else {
            return <></>;
        }
    };

    const parseContent = parseNode(content?.description?.json);

    return (
        <div className="tab-pane fade show active static-page" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
            <div className="title-container">
                <h5 className="text-uppercase text-center">{content?.title}</h5>
            </div>

            <div className="middle-section">
                <div className="card-body">
                    { parseContent }
                </div>
                <div className="end" />
                {/* <div className="gradient-bottom-decorator"/> */}
            </div>
        </div>
    );
};

export default ExtensionPolicy;
