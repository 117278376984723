import {
 Button, Grid, Link, withStyles,
} from '@material-ui/core';
import { object } from 'prop-types';
import React, { useState } from 'react';
import { withRouter, Link as RouterLink } from 'react-router-dom';
import { Hyperlink } from '../../Links/Hyperlink';
import { TABLET_BREAKPPOINT, MOBILE_BREAKPOINT, CONTAINER_BREAKPOINT } from 'Helpers/breakpoints';
import SearchIcon from 'assets/images/search-icon.svg';
import MyAccountIcon from 'assets/images/my-account-icon.svg';
import LocatorIcon from 'assets/images/locator-icon.svg';
import SearchMegaMenu from 'app/Components/Search/SearchMegaMenu';
import { getPageLabelFromUrl } from 'utils/helper';

const styles = (theme) => ({
    buttonContainer: {
        textDecoration: 'none',
        backgroundColor: 'transparent',
        height: '40px',
        borderRight: '4px solid #fff',
        '&:hover': {
            backgroundColor: 'transparent',
            textDecoration: 'none',
        },
        [theme.breakpoints.down(TABLET_BREAKPPOINT)]: {
            height: '49px',
        },
    },
    topButton: {
        color: '#42413D',
        width: '110px',
        fontSize: '15px',
        fontWeight: 400,
        fontFamily: 'DINCondensed-Regular',
        textTransform: 'uppercase',
        [theme.breakpoints.down(TABLET_BREAKPPOINT)]: {
            width: '70px',
        },
        '&:focus, &:hover, &:visited, &:link, &:active': {
            color: '#42413D',
            textDecoration: 'none',
        },
    },
    aboveHeaderContainerWrapper: {
        backgroundColor: '#F9F9F9',
        minHeight: 38
    },
    aboveHeaderContainer: {
        backgroundColor: '#F9F9F9',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        maxWidth: '1295px',
        width: '1295px',
        margin: 'auto',
        minHeight: 38,

        [theme.breakpoints.down(CONTAINER_BREAKPOINT)]: {
            maxWidth: '100%',
            width: '100%',
        },
    },
    selected: {
        backgroundColor: '#fff',
        borderTop: '6px solid #FFDD30',
        borderRight: 'none',
        borderLeft: 'none',
        paddingBottom: '10px',
        fontWeight: 500,
        '& a': {
            fontWeight: 500,
        },
        '&:hover': {
            backgroundColor: '#fff',
        },
    },
    icon: {
        fontWeight: '100',
        margin: '0 7px',
        cursor: 'pointer',
    },
    searchIcon: {
        margin: '0 7px 0',
        paddingBottom: '1px',
        cursor: 'pointer',
    },
    searchIconExpand: {
        margin: '4px 10px 0 40px',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            position: 'absolute',
            top: '56px',
            left: '0',
            marginLeft: '15px',
            zIndex: 2,
        },
    },
});

const AboveHeader = ({
 classes, location, config, history,
}) => {
    const [searchVisible, setSearchVisible] = useState(false);

    const handleSearchVisible = () => {
        setSearchVisible(!searchVisible);
    };

    // const goToLink = (url, label) => {
    //     mParticle.logEvent(
    //         `Navigation - ${label === 'Account' ? 'My Account' : 'Link Clicked'}`,
    //         mParticle.EventType.Navigation,
    //         { 'Source Page': getPageLabelFromUrl(location.pathname) || '', Link: label, 'Link Location': 'Top Menu' },
    //     );
    //     if (url.startsWith('http')) {
    //         window.location.href = url;
    //     } else {
    //         history.push(url);
    //     }
    // };

    return (
        <Grid className={classes.aboveHeaderContainerWrapper}>
            <Grid className={classes.aboveHeaderContainer}>
                {(window.location.href.indexOf("instore") == -1) ?
                    <Grid style={{ display: 'flex' }}>
                        {
                            (config?.header?.globalNavigation || []).map((item, index) => (
                                <Button
                                    className={`${classes.buttonContainer} ${index === 0 ? classes.selected : ''}`}
                                    classes={{ label: classes.topButton }}
                                    key={index}
                                >
                                    <Hyperlink className={classes.topButton} to={item.link === "/booking/location" ? item.link + "?isNew" : item.link}>{item.label}</Hyperlink>
                                </Button>
                            ))
                        }
                    </Grid> : null}
                {(window.location.href.indexOf("instore") == -1) ?
                    <Grid style={{
                        display: 'flex', width: '100%', justifyContent: 'flex-end', alignItems: 'center',
                    }}
                    >
                        {!searchVisible
                            && <img src={SearchIcon} className={classes.searchIcon} onClick={handleSearchVisible} alt="Search Icon" />}
                        {searchVisible && (
                            <>
                                <img
                                    src={SearchIcon}
                                    className={`${classes.searchIcon} ${classes.searchIconExpand}`}
                                    onClick={handleSearchVisible}
                                    alt="Search Icon"
                                />
                                <SearchMegaMenu handleVisible={handleSearchVisible} />
                            </>
                        )}

                        <Hyperlink to={'/account/my-appointments'} style={{ color: '#42413D', paddingBottom: '7px' }}>
                            <img src={MyAccountIcon} className={classes.icon} alt="My Account Icon" />
                        </Hyperlink>
                        <Hyperlink to={'/service/locator'} style={{ color: '#42413D', paddingBottom: '1px' }}>
                            <img src={LocatorIcon} className={classes.icon} alt="Locator Icon" />
                        </Hyperlink>
                    </Grid> : null}
            </Grid>
        </Grid>
    );
};

AboveHeader.propTypes = {
    classes: object.isRequired,
    config: object.isRequired,
};

export default withRouter(withStyles(styles)(AboveHeader));
