export const styles = (theme) => ({
    container: {
        position: 'relative',
    },
    textContainer: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -60%)',
    },
    cardEndingText: {
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.common.lightGrey[1],
        fontSize: '14px',
    },
    maskedCardText: {
        fontFamily: theme.typography.fontFamily,
    },
});
