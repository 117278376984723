const BARFLY_SET_MEMBERSHIP = 'BARFLY/SET_MEMBERSHIP';
const BARFLY_CHOOSE_STORE = 'BARFLY/CHOOSE_STORE';
const BARFLY_LOADS_STORES = 'BARFLY/LOAD_STORES';
const BARFLY_SET_STORES = 'BARFLY/SET_STORES';
const BARFLY_SET_UPDATED_CUSTOMER = 'BARFLY/SET_UPDATED_CUSTOMER';
const BARFLY_SET_CARD = 'BARFLY/SET_CARD';
const BARFLY_FIND_MEMBERSHIP_CARD = 'BARFLY/SET_MEMBERSHIP_CARD';
const BARFLY_REWARDS_CARD_NUMBER = 'BARFLY/SET_REWARDS_CARD_NUMBER';
const BARFLY_CUSTOMERID = 'BARFLY/SET_CUSTOMERID';

// instore barfly reg form:
const BARFLY_SET_BOOKER_ID = 'BARFLY/SET_BOOKER_ID';

export default {
    BARFLY_SET_MEMBERSHIP,
    BARFLY_CHOOSE_STORE,
    BARFLY_LOADS_STORES,
    BARFLY_SET_STORES,
    BARFLY_SET_UPDATED_CUSTOMER,
    BARFLY_SET_CARD,
    BARFLY_FIND_MEMBERSHIP_CARD,
    BARFLY_REWARDS_CARD_NUMBER,
    BARFLY_CUSTOMERID
};
