import {
    Button,
    Grid, Typography, withStyles,
} from '@material-ui/core';
import {
    bool, func, object, // string,
} from 'prop-types';
import React from 'react';
import { compose } from 'recompose';
import { MOBILE_BREAKPOINT, TABLET_BREAKPPOINT } from '../../../../Helpers/breakpoints';

import { getWeekDay, getDateWithFirstTime, getDate } from '../../../../Helpers/dateTime';

const styles = (theme) => ({
    root: {
        width: '90px',
        margin: '0 10px',
        [theme.breakpoints.down('sm')]: {
            width: 'unset',
            margin: '0 2px',
        },
    },
    dateLabel: {
        width: '100%',
        height: '84px',
        background: theme.palette.common.white,
        border: `1px solid ${theme.palette.common.lightGrey[0]}`,
        borderRadius: '4px',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        color: theme.palette.common.grey,
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: { height: '53px', minWidth: '45px' },
    },
    buttonContainer: {
        width: '100%',
        padding: '0',
        minWidth: '90px',
        [theme.breakpoints.down(TABLET_BREAKPPOINT)]: { minWidth: '95px'},
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: { minWidth: '45px' },
    },
    day: {
        fontSize: '15px',
        fontWeight: '400',
        textAlign: 'center',
        marginBottom: '10px',
    },
    date: {
        fontSize: '22px',
        fontWeight: '800',
        margin: '5px',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: { fontSize: '15px'},
    },
    slots: {
        fontSize: '13px'
    },
    selected: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.grey,
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: { height: '54px'},
    },
    disabled: {
        backgroundColor: '#dbdbdb',
        color: 'gray',
        cursor: 'default',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            height: '53px',
            backgroundColor: '#dddddd',
        },

    },
});

const DateCard = ({
    date, onDateSelect, classes, selectedDate, disabled,
}) => {
    const day = getWeekDay(date);
    const selectedDateValue = getDateWithFirstTime(selectedDate);

    return (
        <Grid className={classes.root}>
            <Typography className={classes.day}>
                {day}
            </Typography>
            <Button
                classes={{
                    label: `${classes.dateLabel} ${selectedDateValue === date ? classes.selected : ''} ${disabled ? classes.disabled : ''}`,
                }}
                disabled={disabled}
                variant="outlined"
                className={classes.buttonContainer}
                onClick={() => onDateSelect(date)}
            >
                <Typography className={classes.date}>
                    {getDate(date)}
                </Typography>
            </Button>
        </Grid>
    );
};

DateCard.propTypes = {
    classes: object.isRequired,
    date: object,
    selectedDate: object,
    // slots: string,
    onDateSelect: func.isRequired,
    disabled: bool.isRequired,
};

DateCard.defaultProps = {
    date: {},
    selectedDate: {},
    // slots: '',
};

const enhance = compose(
    withStyles(styles),
);

export default enhance(DateCard);
