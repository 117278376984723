/* eslint-disable import/no-cycle */
/* eslint-disable max-len */
/* eslint-disable no-console */
import React, { useEffect } from 'react';
import { func } from 'prop-types';
import { connect } from 'react-redux';
import {
    CircularProgress, Backdrop,
} from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { useLastLocation } from 'react-router-last-location';
import { withRouter } from 'react-router-dom';

import config from 'app.config';
import TagManager from 'app/Components/GoogleTagManager';
import { getBuyoutLocation } from 'state/ducks/Booking/Booking-Selectors';
import ConnectedSelectLocation from './SelectLocation';
import screenStoreLocatorCollection from '../../../gql/queries/storeCollection';

const SelectStoreLocation = ({ goToNextPage, buyoutLocation, history }) => {
    const STORE_LOCATOR_QUERY = screenStoreLocatorCollection();
    const { data, error, loading } = useQuery(STORE_LOCATOR_QUERY);
    const lastLocation = useLastLocation();

    useEffect(() => {
        if (buyoutLocation) {
            history.push('/booking/select-date');
        }
    }, [buyoutLocation]);

    useEffect(() => {
        if (lastLocation?.pathname === '/' || lastLocation?.pathname === '/home') {
            mParticle.logEvent(
                'Home - Book an Appointment',
                mParticle.EventType.Navigation,
                { 'Source Page': 'Book Type - Book/Rebook' },
            );
        }
    }, [lastLocation]);

    useEffect(() => {
        TagManager.dataLayer({
            dataLayer: {
                page_type: 'Booking Location',
            },
        });
    }, []);

    if (loading) {
        return (
            // todo - replace with skeleton
            <Backdrop
                open
                style={{
                    zIndex: 11,
                    color: '#fff',
                }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    }

    if (error) {
        console.log('errror ===>', error);
    }

    const contentfulStores = data?.storeCollection?.items || [];

    return (
        <div>
            <ConnectedSelectLocation goToNextPage={goToNextPage} contentfulStores={contentfulStores} />
        </div>
    );
};

SelectStoreLocation.propTypes = {
    goToNextPage: func.isRequired,
};

const mapStateToProps = (state) => ({
    buyoutLocation: getBuyoutLocation(state),
});

export default connect(mapStateToProps)(withRouter(SelectStoreLocation));
