/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getAppointments } from '../../../api/booking-api';
import BackdropCircularProgress from '../../../app/Components/common/BackdropCircularProgress';
import { useFetchAsync } from '../../../Helpers/useFetch';
import MyAppointmentsLocators from './MyAppointmentStores';
import { setNextAppointmentDate } from '../../../state/ducks/Account/Account-Actions';

const MyAppointmentsContainer = ({ user, setNextAppointmentDate }) => {
    const customerKey = user?.bookerID;

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [dataFetched, setFetchingData] = useState(false);

    useEffect(async () => {
        if (user?.bookerID) {

            if (!dataFetched && user?.bookerID) {
                setLoading(true);

                setFetchingData(true);

                useFetchAsync(getAppointments(customerKey)).then(res => {
                    const { data: appointmentsData, error: appointmentsError } = res;

                    if (appointmentsData) {
                        setData(appointmentsData);
                    }
                    setError(appointmentsError);
                    setLoading(false);
                }).catch(error => setError(error));
            }
        }
    }, [user]);

    if (loading) {
        return <BackdropCircularProgress />;
    }
    if (error) {
        return null;
    }

    if (data) {
        // Temporary date check for upcoming and past - change with proper values
        const HasActiveMembership = data?.Appointments?.map((membership) => membership?.CustomerHasActiveMembership); // checking if user has membership

        const upcoming = data?.Appointments?.filter((appointment) => (new Date(appointment.StartDateTimeOffset) >= new Date()) && appointment.IsCancelled === false && !appointment.IsPastDue)
            .sort((a, b) => new Date(a.StartDateTimeOffset) - new Date(b.StartDateTimeOffset));
        const past = data?.Appointments?.filter((appointment) => ((new Date(appointment.StartDateTimeOffset) < new Date()) || appointment.IsPastDue === true) && !appointment.IsCancelled).sort((a, b) => new Date(b.StartDateTimeOffset) - new Date(a.StartDateTimeOffset));

        if (upcoming?.length > 0) {
            setNextAppointmentDate(upcoming[0].StartDateTimeOffset);
        } else {
            setNextAppointmentDate('');
        }

        return (
            <MyAppointmentsLocators
                upcoming={upcoming}
                past={past}
                HasActiveMembership={HasActiveMembership}
            />
        );
    }
    return null;
};

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) => ({
    setNextAppointmentDate: bindActionCreators(setNextAppointmentDate, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyAppointmentsContainer);
