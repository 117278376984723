/* eslint-disable max-len */
import {
    Button,
    Grid, Typography, withStyles,
} from '@material-ui/core';
import { object } from 'prop-types';
import React from 'react';
// import EditableTextField from '../../app/Components/EditableTextField/EditableTextField';
import { MOBILE_BREAKPOINT, TABLET_BREAKPPOINT } from '../../Helpers/breakpoints';

const styles = (theme) => ({
    containerShop: {
        background: theme.palette.common.white,
        marginLeft: '15px',
        padding: '34px 22px',
        textAlign: 'center',
        height: '517px',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            background: '#f9f9f9',
            marginLeft: 0,
            height: 'unset',
        },
    },
    heading: {
        float: 'left',
        paddingBottom: '18px',
        fontFamily: 'MrsEavesOT-Roman',
        color: theme.palette.common.grey,
        fontSize: '20px',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            textTransform: 'uppercase',
            fontFamily: 'MrsEavesOT-Roman',
            fontWeight: 'normal',
            marginBottom: '10px !important',
        },
    },
    formContainer: {
        margin: '46px 2px 2px 2px',
        width: '710px',
        height: '285px',
        backgroundColor: theme.palette.common.lightGrey[3],
        borderTop: `1px solid ${theme.palette.common.lightGrey[0]}`,
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            padding: '0 20px 20px',
            backgroundColor: '#fff',
            width: '100%',
            height: 'unset',
        },
    },
    drybarCopy: {
        margin: '55px 0px 24px 0px !important',
        fontSize: '18px',
        color: '#55585B',
        fontWeight: '700',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            margin: '20px 0px !important',
            fontSize: '16px',
            lineHeight: '20px',
        },
    },
    orderHistoryAction: {
        width: '378px',
        height: '63px',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.lightGrey[1],
        fontSize: '16px',
        margin: '35px 0px 0px 0px',
        '&:hover': {
            backgroundColor: theme.palette.common.hover[1],
        },
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            width: '100%',
        },
    },
    subCopy: {
        fontFamily: 'URWForm',
        lineHeight: '32px',
        fontSize: '18px',
        padding: '0 49px !important',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            padding: '0 !important',
            fontSize: '16px',
            lineHeight: '20px',
        },
    },
});

/**
 * Static data - make dynamic when connected with API
 */
const AccountHistory = ({ classes }) => (
    <Grid className={classes.containerShop}>
        <Typography className={classes.heading}>
            View Order History
        </Typography>
        <Grid className={classes.formContainer}>
            <Typography className={classes.drybarCopy}>Your Drybar orders are found a drybar.com.</Typography>
            <Typography className={classes.subCopy}>Your Drybar orders are found a drybar.com. Proin gravida dolor sit amet lacus accumsan et viverra justo vigo. Proin sodales pulvinar sic tempor enorme nueos.</Typography>
        </Grid>
        <Button variant="outlined" className={classes.orderHistoryAction}>View Order History</Button>
    </Grid>
);

AccountHistory.propTypes = {
    classes: object.isRequired,
};

export default withStyles(styles)(AccountHistory);
