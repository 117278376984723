import {
    queryDryStyling,
    fetchBannerData,
    queryCarousel
} from './contentfulDryStylingQueries';
import {
    doQuery,
} from '../../state/utils/contentful';


export const gqlLoadDryStyling = async () => {
    const dryStylingSectionData = {
        title: '',
        subtitle: '',
        description: '',
        action: {
            title: '',
            link: '',
        },
        featuredImage: '',
        featuredVideo: '',
        images: [],
        heroImage: '',
        promo: {
            image: '',
            action: {
                title: '',
                link: '',
            },
        },
        metadata: {}
    };

    const newData = await doQuery(queryDryStyling(), true);
    const pageData = newData?.drybarPageCollection?.items?.[0];
    const bannerId = pageData?.formatofPageCollection?.items?.find(c => c.__typename === 'HeroBanner')?.sys?.id;
    const banner = await doQuery(fetchBannerData(bannerId), true);
    const bannerData = banner?.heroBanner?.mediaCollection?.items?.[0];
    const carousels = [];
    const carouselIds = pageData?.formatofPageCollection?.items?.filter(c => c.__typename === 'Carousel')?.map(x => x?.sys?.id);

    for(const id of carouselIds){
        const carouselWithVideo = await doQuery(queryCarousel(id), true);
        carousels.push(carouselWithVideo?.carousel);
    }

    const videoCarousel = carousels[0];
    const imageCarousel = carousels[1];
    const videoSlides = videoCarousel?.slidesCollection?.items?.[0];
    const imageSlides = imageCarousel?.slidesCollection?.items;

    dryStylingSectionData.title = videoSlides?.title;
    dryStylingSectionData.description = [{content: videoSlides?.subtitle, type: 'paragraph'}];
    dryStylingSectionData.action.title = videoSlides?.buttonCtaTitle || '';
    dryStylingSectionData.action.link = videoSlides?.buttonCtaUrl || '';
    dryStylingSectionData.featuredVideo = videoSlides?.mediaCollection?.items?.[0]?.videoUrl;
    dryStylingSectionData.featuredImage = videoSlides?.mediaCollection?.items?.[0]?.desktopImageUrl;
    dryStylingSectionData.images = imageSlides?.map(slide => slide?.mediaCollection?.items?.[0]?.desktopImageUrl);
    dryStylingSectionData.metadata = {
        pageTitle: pageData?.seoTitle,
        pageKeywords: [],
        pageDescription: pageData?.seoDescription,
        searchKeywords: []
    }
    dryStylingSectionData.heroImage = {
        mobile: bannerData?.mobileImageUrl,
        desktop: bannerData?.desktopImageUrl,
    }

    return dryStylingSectionData;
};
