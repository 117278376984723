import createState from 'redux-create-state';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session'; // defaults to localStorage for web and AsyncStorage for react-native-
import utils from '../../utils';

import * as accountActions from './Account-Actions';

export const INITIAL_STATE = {
    selectedAppointment: null,
    nextAppointmentDate: '',
    favoriteStore: {},
    authUserDetails: null,
};

const homePersistConfig = {
    key: 'account',
    storage,
};

export default persistReducer(homePersistConfig, utils.createReducer(INITIAL_STATE)({
    [accountActions.setViewAppointment().type]: (state, action) => createState(state || INITIAL_STATE,
        ['selectedAppointment', action.payload]),
    [accountActions.setNextAppointmentDate().type]: (state, action) => createState(state || INITIAL_STATE,
        ['nextAppointmentDate', action.payload]),
    [accountActions.setFavoriteStore().type]: (state, action) => createState(state || INITIAL_STATE,
        ['favoriteStore', action.payload]),
    [accountActions.setAuthUserDetails().type]: (state, action) => createState(state || INITIAL_STATE, ['authUserDetails', action.payload]),
}));
