import React from 'react';
import classNames from 'classnames';
import PlayIcon from '@material-ui/icons/PlayArrow';

import styles from "./Android.module.scss"

export const Android = ({ url = "https://play.google.com/store/apps/details?id=com.drybar.uk", display = true}) =>
    display ? <div className={classNames(styles.appStoreLink)}>
        <a className={styles.link} href={url} target="_blank">
            <div className={styles.logo}>
                <PlayIcon className={styles.icon}/>
            </div>
            <div className={styles.text}>
                <div className={styles.strap}>
                    Get it on
                </div>
                <div className={styles.name}>
                    Google Play
                </div>
            </div>
        </a>
    </div> : null;