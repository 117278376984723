import { getPreviewMode } from '../../state/utils/contentful';

const buyoutTopLevelQuery = (slug) => (
    `{
      buyoutCollection(${getPreviewMode()}, where: {slug: "buyout/${slug}"}) {
        items {
          metadata{
            sys {
              id
            }
          }
          title
          slug
          description {
            json
          }
          locations: settings
          marketingComponentsCollection {
            items {
              __typename
              ... on MarketingSection {
                internalName
                sys {
                  id
                }
              }
            }
          }
        }
      }
    }
    `
);

const buyoutSecondLevelQuery = (marketingSectionId) => (
    `{
        marketingSection(${getPreviewMode()}, id: "${marketingSectionId}") {
            marketingComponentsCollection {
              items {
                ... on MarketingCard {
                    title
                    image {
                      desktopMedia {
                        url
                      }
                    }              
                    settings
              }
            }
          }
        }
    }
    `
);

export {
    buyoutTopLevelQuery,
    buyoutSecondLevelQuery,
};
