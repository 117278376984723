const SERVICE_STORES = 'SERVICE/STORES';
const SERVICE_FAVOURITES = 'SERVICE/FAVOURITES';
const SERVICE_FOCUSSTORE = 'SERVICE/FOCUSSTORE';
const SERVICE_CHOOSESTORE='SERVICE/CHOOSESTORE'
export default {
    SERVICE_STORES,
    SERVICE_FAVOURITES,
    SERVICE_FOCUSSTORE,
    SERVICE_CHOOSESTORE
};
