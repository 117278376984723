/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { loadHomeContentful } from '../../state/ducks/Home/Home-Actions';
import { getMetaData } from '../../state/ducks/Home/Home-Selectors';
import HeroSectionView from './slider-section';
import ContainerOuterBorderView from './container-outer-border';
import StylesSectionView from './styles-section';
// import PromoEventsSectionView from './promo-event-section';
import SpecialOfferSectionView from './special-offer-section';
import MobileAppSectionView from './mobile-app-section';
// import SocialSectionView from "./social-section";
import { MetaTagWrapper } from '../../components';
import localisedHeaders from '../home/localised-headers';
import '../../assets/css/style.scss';
import '../../assets/css/style-responsive.scss';
import './home.scss';

const Home = ({ metadata, loadDataFromContentful }) => {
    useEffect(() => {
        loadDataFromContentful({});
        setHomeMetaData({...metadata,"localisedHeaders": localisedHeaders});
    }, []);

const [homeMetadata, setHomeMetaData] = useState({});

    const location = useLocation();

    return (
        <div className="tab-pane fade show homepage active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
            <MetaTagWrapper metadata={homeMetadata} />
            <HeroSectionView />
            <div className="middle_section">
                <ContainerOuterBorderView location={location} />
                <StylesSectionView />
                {/* <PromoEventsSectionView /> */}
                <SpecialOfferSectionView />
                {/* <SocialSectionView/> */}
                <MobileAppSectionView />
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    metadata: getMetaData(state),
});

const mapDispatchToProps = (dispatch) => ({
    loadDataFromContentful: bindActionCreators(loadHomeContentful, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
