// eslint-disable-next-line import/no-cycle
import routes from '../routes/routes';
import accountRoutes from '../routes/accountRoutes';
import serviceRoutes from '../routes/serviceRoutes';
import bookingRoutes from '../routes/bookingRoutes';

export const isExternalURL = (url) => {
    try {
        // eslint-disable-next-line no-restricted-globals
        return new URL(url).host !== (location.host);
    } catch (e) {
        return false;
    }
};

// eslint-disable-next-line consistent-return
export const getPageLabelFromUrl = (u) => {
    // eslint-disable-next-line no-useless-escape
    const url = u.replace(/\#*$/, '').replace(/\/$/, '');
    const route = routes.concat(accountRoutes, serviceRoutes, bookingRoutes).find((r) => r.label && url === r.path);
    if (route) return route.label;

        if (url === '' || url === 'home') return 'Home';
};
