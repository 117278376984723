import {
 call, fork, put, takeLatest,
} from 'redux-saga/effects';
import { loadTheStylesContentful, setTheStylesContent } from './TheStyles-Actions';
import { gqlLoadTheStyles } from '../../../gql/the-styles/contentfulTheStylesActions';

function* workerLoadTheStylesContentful() {
    const theStylesSectionData = yield call(gqlLoadTheStyles);
    yield put(setTheStylesContent(theStylesSectionData));
}

function* watcherLoadTheStylesContentful() {
    yield takeLatest(loadTheStylesContentful().type, workerLoadTheStylesContentful);
}

export const watchers = {
    watcherLoadTheStylesContentful: fork(watcherLoadTheStylesContentful),
};

export default {
    watchers,
};
