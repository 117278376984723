/* eslint-disable no-console */
import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ReactPlayer from 'react-player';
import Slider from 'react-slick';
import Media from 'react-media';
import { useLocation } from 'react-router-dom';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { getPageLabelFromUrl } from 'utils/helper';
import { loadDryStylingContentful } from '../../state/ducks/DryStyling/Dry-Styling-Actions';

import '../../assets/css/style.scss';
import '../../assets/css/style-responsive.scss';
import './dry-styling.scss';
import './dry-styling-responsive.scss';
import {
    getDryStylingSectionAction,
    getDryStylingSectionDescription, getDryStylingSectionFeaturedVideo, getDryStylingSectionFeaturedImage,
    getDryStylingSectionHeroImage, getDryStylingSectionImages, getDryStylingSectionPromo, getDryStylingSectionSubTitle,
    getDryStylingSectionTitle, getDryStylingSectionMetaData,
} from '../../state/ducks/DryStyling/Dry-Styling-Selectors';
import { parsedJSON2Html } from '../../state/utils/contentful';
import { TABLET_BREAKPPOINT } from '../../Helpers/breakpoints';
import { MetaTagWrapper, CustomImg } from '../../components';
import arrowLeftIcon from '../../assets/images/arrow-left-1.svg';
import arrowRightIcon from '../../assets/images/arrow-right-1.svg';

const NextArrow = (props) => {
    const { onClick } = props;
    return (
        <>
            <div className="slick-arrow right" onClick={onClick}>
                <img src={arrowLeftIcon} alt="Arrow Right" />
            </div>
        </>
    );
};
const PrevArrow = (props) => {
    const { onClick } = props;
    return (
        <>
            <div className="slick-arrow left" onClick={onClick}>
                <img src={arrowRightIcon} alt="Arrow Left" />
            </div>
        </>
    );
};

const DryStyling = ({
                        action,
                        title,
                        subtitle,
                        description,
                        heroImage,
                        featuredVideo,
                        featuredImage,
                        images,
                        promo,
                        loadDataFromContentful,
                        metadata,
                    }) => {
    useEffect(() => {
        loadDataFromContentful({});
    }, []);

    const location = useLocation();

    const slickSettings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    dots: true,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    dots: true,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    dots: true,
                },
            },
        ],
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
    };

    const gotoActionLink = (url) => {
        if (url) window.location.assign(url);
    };

    const onVideoStart = () => {
        mParticle.logEvent('Videos - Play Video', mParticle.EventType.Other, {
            'Source Page': getPageLabelFromUrl(location.pathname),
            'Video Url': featuredVideo,
        });
    };

    if (!title && !subtitle && !description) {
        return <div />;
    }

    return (
        <div
            className="tab-pane fade show active dry-styling-page"
            id="nav-home"
            role="tabpanel"
            aria-labelledby="nav-home-tab"
        >
            <MetaTagWrapper metadata={metadata} />
            <div className="banner-section">
                <div className="banner-image">
                    <Media
                        query={{ maxWidth: TABLET_BREAKPPOINT }}
                    >
                        {(matches) => (matches ? (
                            <CustomImg className="d-block w-100" src={heroImage?.mobile} alt="Banner" />
                        ) : (
                            <CustomImg className="d-block w-100" src={heroImage?.desktop} alt="Banner" />
                        ))}
                    </Media>
                </div>
            </div>
            <div className="middle-section">
                <div className="book-styling-section">
                    <div className="row info-row">
                        <div className="text-section">
                            <h1>{title}</h1>
                            <span>{subtitle}</span>
                            <p dangerouslySetInnerHTML={{ __html: parsedJSON2Html(description) }} />
                            <Media
                                query={{ maxWidth: TABLET_BREAKPPOINT }}
                            >
                                {(matches) => (matches ? null : (
                                    <button
                                        className="btn-common btn-yellow btn-lg font-weight-bold responsive-500-w-100"
                                        onClick={() => gotoActionLink(action.link)}
                                    >
                                        {action.title}
                                    </button>
                                ))}
                            </Media>

                        </div>
                        <div className="video-section">
                            <div className="video-wrapper">
                                <ReactPlayer
                                    url={featuredVideo}
                                    light={featuredImage}
                                    playing
                                    className="video-player"
                                    width="756px"
                                    height="399px"
                                    playIcon={<div />}
                                    onStart={onVideoStart}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="slider-row">
                        <Slider {...slickSettings}>
                            {images.map((image, index) => (
                                <div className="style-item" key={index}>
                                    <div className="content">
                                        <CustomImg src={image} alt="style" />
                                    </div>
                                </div>
))}
                        </Slider>
                        <Media
                            query={{ maxWidth: TABLET_BREAKPPOINT }}
                        >
                            {(matches) => (matches ? (
                                <button
                                    className="btn-common btn-yellow btn-lg font-weight-bold w-100"
                                    onClick={() => gotoActionLink(action.link)}
                                >
                                    {action.title}
                                </button>
                            ) : null)}
                        </Media>
                    </div>

                </div>
            </div>

            <div className="banner-section">
                <div className="banner-image" onClick={() => gotoActionLink(promo.action.link)}>
                    <Media
                        query={{ maxWidth: TABLET_BREAKPPOINT }}
                    >
                        {(matches) => (matches ? (
                            <CustomImg className="d-block w-100" src={promo?.image?.mobile} alt="Banner" />
                        ) : (
                            <CustomImg className="d-block w-100" src={promo?.image?.desktop} alt="Banner" />
                        ))}
                    </Media>
                </div>
            </div>
            <div className="d-flex justify-content-center shop-all-kits" />
            <div className="gradient-bottom-decorator" />
        </div>
    );
};

const mapStateToProps = (state) => ({
    title: getDryStylingSectionTitle(state),
    subtitle: getDryStylingSectionSubTitle(state),
    description: getDryStylingSectionDescription(state),
    featuredVideo: getDryStylingSectionFeaturedVideo(state),
    featuredImage: getDryStylingSectionFeaturedImage(state),
    heroImage: getDryStylingSectionHeroImage(state),
    images: getDryStylingSectionImages(state),
    action: getDryStylingSectionAction(state),
    promo: getDryStylingSectionPromo(state),
    metadata: getDryStylingSectionMetaData(state),
});

const mapDispatchToProps = (dispatch) => ({
    loadDataFromContentful: bindActionCreators(loadDryStylingContentful, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DryStyling);
