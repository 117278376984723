import React from 'react';
import { withRouter } from 'react-router-dom';

class Authenticated extends React.Component {
  async componentDidMount() {
    const user = await this.props.userManager.getUser();
    const requiresSignIn = !user || user.expired;

    if (requiresSignIn) {
      const redirect = this.props.location.pathname ? `?redirect=${this.props.location.pathname}` : '';
      this.props.history.replace('/auth/login' + redirect);
    } else {
      //await this.silentlySignIn();
    }
  }

  render() {
    return this.props.children || null
  }

  redirectToSignIn() {
    this.props.userManager.signinRedirect();
  }

  async silentlySignIn(){
    try {
      const user = await this.props.userManager.signinSilent();
      this.props.onSilentSuccess(user);
    } catch (e) {
      this.props.userManager.signinRedirect();
    }
  }
}

export default withRouter(Authenticated)