import BookingActionTypes from "state/ducks/Booking/Booking-ActionTypes";
import { getBookingTotal } from "./helpers";
import { googleAnalyticsEvents } from "./googleAnalyticsEvents"
import { 
    getServicesData,
    getAddOnsServiceData,
    getExtensions,
    getExtensionAddon,
    getNumberOfGuests,
    getSelectedSlot,

 } from "state/ducks/Booking/Booking-Selectors";

const googleAnalyticsMiddleware = store => next => action => {

    switch(action.type) {
        case BookingActionTypes.BOOKING_SERVICE:
            return logBookingService(store, next, action);
        case BookingActionTypes.BOOKING_OVERWRITE_SERVICES_DATA:
            return logBookingService(store, next, action);
        case BookingActionTypes.BOOKING_ADDONS_FOR_USER:
            return logBookingAddon(store, next, action);
        case BookingActionTypes.BOOKING_SET_EXTENSIONS:
            return logBookingExtensions(store, next, action);
        case BookingActionTypes.BOOKING_SLOT_TIME:
            return logBookingSlotTime(store, next, action);
        case BookingActionTypes.BOOKING_REQUEST_NOTES:
            return logBookingRequestNotes(store, next, action);
        default:
            return next(action);
    }
}

const logBookingService = (store, next, action) => {
    
    const services = getServicesData(store.getState());
    const result = next(action);
    let updatedServices = getServicesData(store.getState());
    const updatedBookingTotal = getBookingTotal(store.getState());

    const logEvent = (eventName, service, total) => {
        gtag(googleAnalyticsEvents.EVENT, eventName, {
            currency: service.Price.CurrencyCode,
            value: total,
            items: [{
                item_id: service.ID.toString(),
                item_name: service.Name,
                price: service.Price.Amount,
                quantity: 1
            }]
        })
    }

    services.forEach(service => {
        const matchedService = updatedServices.find(updatedService => updatedService.user === service.user);

        if (matchedService) {
            //remove new services from list so they are only added to cart here
            updatedServices = updatedServices.filter(updatedService => updatedService.user !== matchedService.user)

            if (matchedService.data.ID !== service.data.ID) {
                logEvent(googleAnalyticsEvents.REMOVE_FROM_CART, service.data, updatedBookingTotal);
                logEvent(googleAnalyticsEvents.ADD_TO_CART, matchedService.data, updatedBookingTotal)
            }
        } else {
            logEvent(googleAnalyticsEvents.REMOVE_FROM_CART, service.data, updatedBookingTotal);
        }
    });

    updatedServices.forEach(newService => logEvent(googleAnalyticsEvents.ADD_TO_CART, newService.data, updatedBookingTotal));
    return result;
}


const logBookingAddon = (store, next, action) => {
    const addonsData = getAddOnsServiceData(store.getState()).find(addons => addons.user === action.payload.user);
    const result = next(action);
    const updatedAddons = getAddOnsServiceData(store.getState()).find(addons => addons.user === action.payload.user);    
    const updatedBookingTotal = getBookingTotal(store.getState());

    const logEvent = (eventName, addon, total) => {
        gtag(googleAnalyticsEvents.EVENT, eventName, {
            currency: addon.PriceInfo.CurrencyCode,
            value: total,
            items: [{
                item_id: addon.ServiceID.toString(),
                item_name: addon.ServiceName,
                price: addon.PriceInfo.Amount,
                quantity: 1
            }]
        })
    }

    if(!action.payload.data && addonsData.data) {
        addonsData.data.forEach(addon => logEvent(googleAnalyticsEvents.REMOVE_FROM_CART, addon, updatedBookingTotal))
    }
    else if(action.payload.data) {
        const newAddon = updatedAddons?.data?.find(addon => addon.ServiceID === action.payload.data.ServiceID);
        if(newAddon) {
            logEvent(googleAnalyticsEvents.ADD_TO_CART, newAddon, updatedBookingTotal)
        }
        else {
            const removedAddon = addonsData.data.find(addon =>  addon.ServiceID === action.payload.data.ServiceID)
            logEvent(googleAnalyticsEvents.REMOVE_FROM_CART, removedAddon, updatedBookingTotal)
        }
    }

    return result;
}

const logBookingExtensions = (store, next, action) => {

    const getExtensionCount = (extensions, guests) => {

        if(!extensions) {
            return 0;
        }
        if(typeof extensions == "boolean") {
            return extensions ? guests + 1 : 0
        }
        return Object.values(extensions)?.filter(extension => extension)?.length;
    }

    const logEvent = (eventName, extensionAddon, total, quantity) => {
        gtag(googleAnalyticsEvents.EVENT, eventName, {
            currency: extensionAddon.Price.CurrencyCode,
            value: total,
            items: [{
                item_id: extensionAddon.ID.toString(),
                item_name: extensionAddon.Name,
                price: extensionAddon.Price.Amount,
                quantity: quantity
            }]
        })
    }

    const extensionAddon = getExtensionAddon(store.getState());

    const guests = getNumberOfGuests(store.getState());
    const extensions = getExtensions(store.getState());

    const result = next(action);

    const updatedGuests = getNumberOfGuests(store.getState());
    const updatedExtensions = getExtensions(store.getState());
    const updatedBookingTotal = getBookingTotal(store.getState());

    const quantity = getExtensionCount(updatedExtensions, updatedGuests) - getExtensionCount(extensions, guests); 

    if(quantity > 0) {
        logEvent(googleAnalyticsEvents.ADD_TO_CART, extensionAddon, updatedBookingTotal, quantity)
    } else if (quantity < 0) {
        logEvent(googleAnalyticsEvents.REMOVE_FROM_CART, extensionAddon, updatedBookingTotal, quantity)
    }

    return result;
}

const logBookingSlotTime = (store, next, action) => {
    const slotTime = getSelectedSlot(store.getState())?.Me?.startDateTime;
    const result = next(action);
    const updatedSlotTime = getSelectedSlot(store.getState())?.Me?.startDateTime;

    if (updatedSlotTime && slotTime !== updatedSlotTime) {
        const newDate = new Date(updatedSlotTime);

        gtag(googleAnalyticsEvents.EVENT, googleAnalyticsEvents.BOOKING_DATE, {
            date: `${newDate.getDate()}/${newDate.getMonth()}/${newDate.getFullYear()}`,
            time: `${newDate.getHours()}:${newDate.getMinutes()}`
        })
    }

    return result;
}

const logBookingRequestNotes = (store, next, action) => {

    gtag(googleAnalyticsEvents.EVENT, googleAnalyticsEvents.BOOKING_REQUEST, {
        requests: action?.payload?.length > 0
    })

    return next(action);
}

export default googleAnalyticsMiddleware;