import {
    call,
    fork, put, takeLatest,
} from 'redux-saga/effects';
import {
    editOrRebookAppointment,
    loadBookingState,
    setDateTimeClient,
    setEditEnabled,
    setLocationData,
    setSlotTime,
    setNumberOfGuests,
    setDifferentServiceEachUser,
    setAppointmentIDs,
    availEmployeeBookingSlots,
    availRoomBookingSlot,
    setExtensionAddon,
    overwriteServicesData,
    setExtensions,
    setGroupID,
    setEditLocationID,
    setCouponCode,
} from './Booking-Actions';

function* reloadBookingState({ payload }) {
    const { group, isUpcoming } = payload;

    if(!group?.appointment) { return; }

    const services = group.appointment.AppointmentTreatments.filter((s) => s.Treatment.Name !== 'Extensions');
    const extensionData = group.appointment.AppointmentTreatments.filter((s) => s.Treatment.Name === 'Extensions');
    const dateTime = group.appointment.StartDateTimeOffset;

    yield put(setLocationData({
        bookerLocationId: group.appointment.Customer.LocationID,
        title: group.appointment.Customer.LocationName,
    }));
    yield put(setNumberOfGuests(services.length - 1));

    const isDifferentServices = [...new Set(services.map((s) => s.Treatment.ID))].length > 1;
    yield put(setDifferentServiceEachUser(isDifferentServices));

    const slots = {};
    const employees = {};
    const rooms = {};
    const bookingServices = [];
    const extensions = {};
    let couponCode = null;
    for (let i = 0; i < services.length; i++) {
        const user = i === 0 ? 'Me' : `Guest ${i}`;

        slots[user] = {
            startDateTime: group.appointment.StartDateTimeOffset,
            endDateTime: group.appointment.EndDateTimeOffset,
        };

        employees[user] = {
            service: services[i].EmployeeID,
        };

        rooms[user] = {
            service: services[i].Room.ID,
        };
        bookingServices.push({ user, data: services[i].Treatment });

        if (group.appointments) {
            if (group.appointments[`appoint_${services[i].AppointmentID}`]?.Notes?.includes('Extensions added.')) {
                extensions[user] = true;
            } else {
                extensions[user] = false;
            }
        }

        if (!couponCode) {
            const specials = (services[i].DynamicPrice.Specials || []).find((s) => s.SpecialTypeID === 2);
            if (specials) {
                couponCode = {
                    code: specials.Special.CouponCode,
                    discountAmount: specials.Special.DiscountAmount,
                    discountType: specials.Special.DiscountType,
                    id: specials.Special.ID,
                };
            }
        }
    }

    yield put(setCouponCode(couponCode));

    if (extensionData.length) {
        yield put(setExtensionAddon(extensionData[0].Treatment));
        employees.Me.extension = extensionData[0].EmployeeID;
        rooms.Me.extension = extensionData[0].Room.ID;
    } else {
        yield put(setExtensionAddon(null));
    }

    yield put(overwriteServicesData(bookingServices));

    yield put(setSlotTime(slots));
    yield put(setDateTimeClient(dateTime));

    yield put(availEmployeeBookingSlots(employees));
    yield put(availRoomBookingSlot(rooms));

    if (services.length === 1 && extensionData.length) {
        yield put(setExtensions(true));
    } else {
        yield put(setExtensions(extensions));
    }

    if (group.groupID) {
        yield put(setGroupID(group.groupID));
        yield put(setAppointmentIDs([]));
        yield put(setEditLocationID(group.appointment.Customer.LocationID));
    } else {
        yield put(setGroupID(null));
        yield put(setEditLocationID(null));
        yield put(setAppointmentIDs(services.map((s) => s.AppointmentID)));
    }

    if (isUpcoming) {
        yield put(setEditEnabled(true));
    } else {
        yield put(setEditEnabled(false));
    }
}

// function* workerRebookAndEditAppointment({ payload }) {
//     if (isUpcoming) {
//         yield call(reloadBookingState, appointment);
//     } else {
//         reloadBookingState(appointment);
//     }
//     yield null;
// }

function* watcherReloadBookingState() {
    yield takeLatest(loadBookingState().type, reloadBookingState);
}

function* workerEditOrRebookAppointment({ payload }) {
    const { appointment: { isUpcoming }, history } = payload;

    yield call(reloadBookingState, { payload: payload.appointment });

    if (isUpcoming) {
        yield history.push('/booking/services');
    } else {
        yield history.push('/booking/select-date');
    }
}

function* watcherEditOrRebookAppointment() {
    yield takeLatest(editOrRebookAppointment().type, workerEditOrRebookAppointment);
}

export const watchers = [
    fork(watcherReloadBookingState),
    fork(watcherEditOrRebookAppointment),
];

export default {
    watchers,
};
