import AccountInformation from '../pages/account/UpdateAccountProfile/AccountInformation';
import AccountBarflyMembership from '../pages/account/MyAccountBarfly/AccountBarflyStore';
import AccountFavouriteContainer from '../pages/account/AccountFavouriteShop/AccountFavouriteContainer';
import AccountHistory from '../pages/account/AccountHistory';
import AccountUpdatePassword from '../pages/account/UpdateAccountProfile/UpdatePassword'
import AccountReplenishment from '../pages/account/AccountReplenishment';
import ConnectedRebookAppointmentDetails from '../pages/account/Partials/RebookAppointmentDetails';
import MyAppointmentsContainer from '../pages/account/MyAppointments/MyAppointmentsContainer';
import ManageCardsContainer from '../pages/account/ManageCards/ManageCards';
import MobileAccountNav from '../pages/account/AccountMobileNav/MobileAccountNav';
import CommunicationPreferences from 'pages/account/UpdateAccountProfile/CommunicationPreferences';

export default [{
    path: '/account/information',
    Component: AccountInformation,
    label: 'Account Information',
},
{
    path: '/account/communication-preferences',
    Component: CommunicationPreferences,
    label: 'Communication Preferences',
},
{
    path: '/account/my-appointments',
    Component: MyAppointmentsContainer,
    label: 'My Appointments',
},
{
    path: '/account/manage-cards',
    Component: ManageCardsContainer,
    label: 'Manage Cards',
},
{
    path: '/account/update-password',
    Component: AccountUpdatePassword,
    label: 'Update Password',
},
{
    path: '/account/barfly-membership',
    Component: AccountBarflyMembership,
    label: 'Barfly Membership',
},
{
    path: '/account/favorites',
    Component: AccountFavouriteContainer,
    label: 'Favourites',
},
{
    path: '/account/order-history',
    Component: AccountHistory,
    label: 'Order History',
},
{
    path: '/account/auto-replenishment',
    Component: AccountReplenishment,
    label: 'Auto-Replenishment',
},
{
    path: '/account/appointment-details',
    Component: ConnectedRebookAppointmentDetails,
    label: 'Appointment details',
},
{
    path: '/account/appointment-rebook',
    Component: ConnectedRebookAppointmentDetails,
    label: 'Rebook An Appointment',
},
{
    path: '/account/my-account',
    Component: MobileAccountNav,
    label: 'My Account',
},
];
