/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import {
    Button,
    Grid,
    Typography,
    withStyles,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { object } from 'prop-types';
import React, { useEffect, useCallback } from 'react';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Media from 'react-media';
import restClient from 'api/restClient';
import { findAddOnServices } from 'api/booking-api';
import ConnectedAppointmentSummary from '../../booking/AppointmentSummary/AppointmentSummaryContainer';
import {
    clearBookingDetails,
    editOrRebookAppointment,
    loadBookingState,
    setAddOns,
} from '../../../state/ducks/Booking/Booking-Actions';
import { getSelectedAppointment } from '../../../state/ducks/Account/Account-Selectors';
import { setViewAppointment } from '../../../state/ducks/Account/Account-Actions';
import { MOBILE_BREAKPOINT } from '../../../Helpers/breakpoints';

const styles = (theme) => ({
    containerShop: {
        background: theme.palette.common.white,
        marginLeft: '15px',
        padding: '34px 22px',
        textAlign: 'center',
        height: 'auto',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            marginLeft: 0,
            backgroundColor: '#f9f9f9',
            padding: '0 20px 20px',
            position: 'absolute',
            left: 0,
            top: 0,
        },
    },
    container: {
        width: '100%',
        padding: '0px 0px',
        minWidth: '422px',
        boxShadow: 'none',
        margin: '0',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            width: '100%',
            minWidth: 'unset',
            margin: '0 !important',
            backgroundColor: '#f9f9f9',
        },
    },
    heading: {
        margin: '0px 0px 18px 14px',
        fontFamily: 'MrsEavesOT-Roman',
        color: theme.palette.common.grey,
        fontSize: '23px',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            fontFamily: 'DINCondensed-Regular',
            fontWeight: 'bold',
            fontSize: '22px',
            lineHeight: '30px',
            width: '100%',
        },
    },
    formContainer: {
        margin: '46px 2px 2px 2px',
        width: '710px',
        height: '285px',
        backgroundColor: theme.palette.common.lightGrey[3],
        borderTop: `1px solid ${theme.palette.common.lightGrey[0]}`,
    },
    drybarCopy: {
        margin: '55px 0px 36px 0px',
        fontSize: '18px',
        color: '#55585B',
    },
    orderHistoryAction: {
        display: 'block',
        width: '378px',
        height: '63px',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.lightGrey[1],
        fontSize: '16px',
        margin: '35px auto 0px',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: theme.palette.common.hover[1],
        },
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            display: 'inline-block',
            width: 'calc(50% - 10px)',
            margin: '24px 10px 0 0',
            verticalAlign: 'bottom',
        },
    },
    backToAppointment: {
        color: '#42413D',
        textDecoration: 'none',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            padding: '5px 0',
            minWidth: '1px',
            width: '1px',
            justifyContent: 'unset',
        },
    },
    BackCopy: {
        color: '#42413D',
        fontWeight: '600',
    },
    displayFlex: {
        display: 'flex',
        float: 'right',
    },
    appointmentSummaryTitle: {
        display: 'none',
    },
    cancelAppointDetails: {
        color: '#42413D',
        backgroundColor: '#fff',
        margin: '0 auto',
        textUnderlinePosition: 'under',
        textDecoration: 'underline',
        '&:hover': {
            backgroundColor: '#fff',
        },
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            display: 'inline-block',
            width: 'calc(50% - 10px)',
            marginRight: 'unset',
            margin: '24px 0 0 10px',
            verticalAlign: 'bottom',
        },
    },
    cancelCopy: {
        color: '#42413D',
        textAlign: 'initial',
        borderTop: '1px solid #D1D1D1',
        margin: '20px 0px 0px 0px',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            margin: '50px 0px 0px 0px',
        },
    },
    copyMargin: {
        marginTop: '27px !important',
    },
    children: {
        width: 'auto',
        float: 'left',
    },
    fieldLabel: {
        float: 'left',
    },
    headerContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            padding: '45px 0',
        },
    },
    card: {
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            padding: '16px 16px',
            backgroundColor: '#fff !important',
        },
    },
});

/**
 * Static data - make dynamic when connected with API
 */
const AppointmentDetails = ({
    classes,
    setViewAppointment,
    selectedAppointment,
    reloadState,
    clearBookings,
    editOrRebook,
    history,
    setAddOns,
}) => {
    const isRebookAppointment = !selectedAppointment.isUpcoming;

    useEffect(() => {
        clearBookings();
        reloadState(selectedAppointment);
        fetchAddonsData();
    }, [selectedAppointment]);

    const fetchAddonsData = useCallback(async () => {
        if (!selectedAppointment) return;
        const { group } = selectedAppointment;
        if (!group) return;

        let { appointment, appointments } = group;

        if (!appointments) {
            if(!appointment) {
                return;
            }
            appointments = {};
            appointments[`appoint_${appointment.ID}`] = appointment;
        }

        if (!Object.values(appointments).find((a) => a?.AddOnItems?.length)) {
            return;
        }

        const addons = await restClient.post(findAddOnServices({ LocationID: appointment.Customer.LocationID }));
        if (!addons.data.IsSuccess) {
            return;
        }
        const addonData = addons.data.Results.filter((a) => (a.PriceInfo?.Amount || 0) > 0);

        if (addonData.length === 0) return;

        const addonDataState = [];

        if (appointments) {
            Object.values(appointments).forEach((appt, idx) => {
                const addonDataForUser = {
                    user: idx === 0 ? 'Me' : `Guest ${idx}`,
                    data: [],
                };

                addonDataForUser.data = addonData.filter((addon) => {
                    const addonItemIds = appt.AddOnItems.map((a) => `${a.ItemID}`);
                    return addonItemIds.includes(addon.ServiceID);
                });

                if (addonDataForUser.data.length) {
                    addonDataState.push(addonDataForUser);
                }
            });
        }
        if (addonDataState.length) {
            setAddOns(addonDataState);
        }
    }, [selectedAppointment]);

    const handleClick = () => {
        // close the rebooking dialogue      
        setViewAppointment(null);
        // set the start + end date-time to empty string (default value, trigger logic in date time selector)
        // selectedAppointment.group.appointment.StartDateTime = '';
        // selectedAppointment.group.appointment.StartDateTimeOffset = '';
        // selectedAppointment.group.appointment.EndDateTime = '';
        // selectedAppointment.group.appointment.EndDateTimeOffset = '';
        // call edit or rebook
        editOrRebook({ appointment: selectedAppointment, history });
    };

    return (
        <Grid className={classes.containerShop}>
            <Media query={{ maxWidth: 599 }}>
                {(matches) => (matches ? (
                    <Grid className={classes.headerContainer}>
                        <Button
                            variant="outlined"
                            onClick={() => { setViewAppointment(null); clearBookings() }}
                            className={classes.backToAppointment}
                        >
                            <Grid className={classes.displayFlex}>
                                <ArrowBackIosIcon />
                            </Grid>
                        </Button>
                        <Typography className={classes.heading}>
                            {isRebookAppointment ? 'Appointment Details' : 'Chosen Appointment Details'}
                        </Typography>

                    </Grid>
                ) : (
                    <Grid className={classes.headerContainer}>
                        <Typography className={classes.heading}>
                            {isRebookAppointment ? 'Appointment Details' : 'Chosen Appointment Details'}
                        </Typography>
                        <Button
                            variant="outlined"
                            onClick={() => { setViewAppointment(null); clearBookings() }}
                            className={classes.backToAppointment}
                        >
                            <Grid className={classes.displayFlex}>
                                <ArrowBackIosIcon />
                                <Typography className={classes.BackCopy}>Back</Typography>
                            </Grid>
                        </Button>
                    </Grid>
                ))}
            </Media>

            <ConnectedAppointmentSummary classes={classes} />

            {/* // we don't need Edit Button more on my appointment details have to use edit icons */}

            {isRebookAppointment
                ? (
                    <Button onClick={handleClick} variant="outlined" className={classes.orderHistoryAction}>
                        Rebook Appointment
                    </Button>
)
                : <Button variant="outlined" onClick={handleClick} className={classes.orderHistoryAction}>Edit</Button>}
            {!isRebookAppointment
                ? (
                    <Button onClick={() => { setViewAppointment(null); clearBookings() } } variant="outlined" className={`${classes.orderHistoryAction} ${classes.cancelAppointDetails}`}>
                        Cancel
                    </Button>
)
                : ''}
            <Grid className={classes.cancelCopy}>
                <Typography className={classes.copyMargin}>
                    * If you are a BarFly member then we would communicate things related to that here.              
                </Typography>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    selectedAppointment: getSelectedAppointment(state),
});

const mapDispatchToProps = (dispatch) => ({
    editOrRebook: bindActionCreators(editOrRebookAppointment, dispatch),
    reloadState: bindActionCreators(loadBookingState, dispatch),
    clearBookings: bindActionCreators(clearBookingDetails, dispatch),
    setViewAppointment: bindActionCreators(setViewAppointment, dispatch),
    setAddOns: bindActionCreators(setAddOns, dispatch),
});

AppointmentDetails.propTypes = {
    classes: object.isRequired,
};

export default withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(AppointmentDetails)));
