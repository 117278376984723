/* eslint-disable max-len */
import { Button, Grid, withStyles } from '@material-ui/core';
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import dayjs from 'dayjs';
import { getSlots, getSlotsForMultiServices } from '../../../../api/booking-api';
import {
    getTimeFromDate,
    formatDate,
    getDateWithTimezone,
    splitTimesByDuration,
    getTime,
    getDateString,
    getTimezoneString,
} from '../../../../Helpers/dateTime';
import useFetch from '../../../../Helpers/useFetch';
import ServiceSkeleton from '../../../../app/Components/Skeleton/ServiceSkeleton';

const styles = (theme) => ({
    slotsContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '41px',
        [theme.breakpoints.down('sm')]: {
            paddingTop: 10,
            paddingBottom: 41,
        },
    },
    slotButtonWrap: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    slotButton: {
        width: '438px',
        height: '65px',
        margin: '12px 0',
        fontSize: '18px',
        color: '#42413D',
        backgroundColor: theme.palette.common.white,
        boxShadow: '2px 2px 17px rgba(235, 235, 235, 0.5)',
        border: 'none',
        borderRadius: 'none',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            margin: '12px auto 0',
            height: '65px',
        },

    },
    selected: {
        fontWeight: '800',
    },
    slotsAvailable: {
        fontFamily: theme.typography.fontFamily,
        fontSize: '20px',
        marginLeft: '20px',
        [theme.breakpoints.down('sm')]: {
            // display: 'none'
            fontSize: '16px',
            paddingTop: '5px',
            display: 'inline-block',
        },
    },
    noAvailableText: {
        fontSize: 16,
        marginBottom: 40,
        paddingBottom: 41,
        [theme.breakpoints.down('sm')]: {
            paddingTop: '30px !important',
            paddingBottom: 30,
        },
        textAlign: 'center'
    },
});

const SlotsContainer = ({
    selectedDate,
    handleSelectSlot,
    classes,
    selectedSlot,
    hasLocationId,
    selectedServices,
    guests,
    buyoutLocation,
    buyout,
    selectedLocation,
}) => {
    const [query, setQuery] = useState(getSlotsForMultiServices(getDateWithTimezone(selectedDate), hasLocationId, selectedServices.map((s) => s.data)));

    const { data, error, loading } = useFetch(query);

    const [slotData, setSlotData] = useState([]);

    useEffect(() => {
        if (selectedDate && hasLocationId && selectedServices.length) {
            setQuery(getSlotsForMultiServices(getDateWithTimezone(selectedDate), hasLocationId, selectedServices.map((s) => s.data)));
        }
    }, [selectedDate, hasLocationId, selectedServices]);

    useEffect(() => {
        if (buyout && data && buyoutLocation) {
            // debugger;
            let buyoutTimeslot = buyout.slots
                .filter((c) => {
                    const [startDate, endDate] = c.date.split(' ~ ');
                    if (!endDate) {
                        return dayjs(startDate).format('YYYY-MM-DDT00:00:00Z') === selectedDate;
                    }
                    return dayjs(startDate) <= dayjs(selectedDate) && dayjs(selectedDate) <= dayjs(endDate);
                })
                .map((s) => {
                    let [startDateTime, endDateTime] = s.time.split('-');
                    startDateTime = `${formatDate(selectedDate)}T${startDateTime}:00`;
                    endDateTime = `${formatDate(selectedDate)}T${endDateTime}:00`;
                    return {
                        startDateTime,
                        endDateTime,
                    };
                });
            if (buyoutTimeslot.length === 0) {
                setSlotData([]);
            } else {
                buyoutTimeslot = buyoutTimeslot[0];
                const slots = [];
                data.availability.forEach((t) => {
                    const timezone = getTimezoneString(t.startDateTime);
                    let { startDateTime } = t;
                    if (dayjs(`${buyoutTimeslot.startDateTime}${timezone}`) > dayjs(startDateTime)) {
                        startDateTime = `${buyoutTimeslot.startDateTime}${timezone}`;
                    }
                    let { endDateTime } = t;

                    if (dayjs(`${buyoutTimeslot.endDateTime}${timezone}`) < dayjs(endDateTime)) {
                        endDateTime = `${buyoutTimeslot.startDateTime}${timezone}`;
                    }

                    if (dayjs(endDateTime) >= dayjs(startDateTime)) {
                        slots.push({
                            ...t,
                            startDateTime,
                            endDateTime,
                        });
                    }
                });
                setSlotData(slots);
            }
        } else if (data) {
            const resSlotData = data.availability;
            setSlotData(resSlotData);
        }
    }, [data, buyout, buyoutLocation, selectedDate, setSlotData]);

    if (loading) {
        return <ServiceSkeleton />;
    }

    if (error) {
        return null;
    }

    if (data) {
        return (
            <Grid className={classes.slotsContainer}>
                {
                    slotData && slotData.length > 0 && slotData.filter((s) => getDateString(s.startDateTime) === getDateString(selectedDate)).map((slot) => {
                        const durationDates = splitTimesByDuration(slot.startDateTime, slot.endDateTime, 15);
                        return (
                            <React.Fragment key={slot.startDateTime}>
                                {
                                durationDates.map((s) => {
                                    const date = getTimeFromDate(s);
                                    const startDate = getTimeFromDate(selectedSlot.startDateTime);
                                    const timezone = getTimezoneString(s);
                                    if (selectedLocation.block730) {
                                        if (date === '7:30 am') return null;
                                    }

                                    return (
                                        <Grid className={classes.slotButtonWrap} key={date}>
                                            <Button
                                                onClick={() => handleSelectSlot({
                                                    ...slot,
                                                    startDateTime: s,
                                                })}
                                                className={`${classes.slotButton} ${startDate === date ? classes.selected : ''}`}
                                                variant="outlined"
                                                color="primary"
                                            >
                                                {date}
                                            </Button>
                                        </Grid>
                                    );
                                })
                            }
                            </React.Fragment>
);
                    })
                }
                {
                    slotData && slotData.length === 0 && (
                        <Typography className={classes.noAvailableText}>{<strong>
                            It appears there are no time slots available for today.<br /></strong>}
                        {<strong>Please try another day or give us a call on +44 (0)20 7893 8444 </strong>}
                    </Typography>
)
                }
            </Grid>
        );
    }
    return null;
};

export default withStyles(styles)(SlotsContainer);
