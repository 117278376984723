import React, { useEffect } from 'react';

import { Typography, Grid, withStyles } from '@material-ui/core';
import logoSmall from '../../assets/images/logoSmall.svg';

export const COMING_SOON = 'coming_soon'; 

const styles = (theme) => ({
    container: {
        height: "100%",
        width: "100%"
    },
    aboveHeader: {
        height: 52,
        minHeight: 52,
        width: "100%"
    },
    header: {
        display:"flex",
        height: 100,
        minHeight: 100,
        width: "100%",
        backgroundColor: "white",
        justifyContent: "center",
        alignItems: "top"
    },
    logo: {
        height: '84px',
        margin: 'auto',
        marginTop: 0
    },
    page: {
        minHeight: "80%"
    },
    content: {
        padding: 15,
    },
    sectionTitle: {
        textAlign: 'center',
        fontFamily: 'DINCondensed-Regular',
        //fontWeight: 'bold',
        fontSize: 42,
        lineHeight: '50px',
        color: '#42413D',
    }
});

function ComingSoonHoldingPage({ classes }) {

    useEffect(() =>{
        document.title = "Drybar - Coming Soon"
    }, [])
  
    return (
        <div className={classes.container}>
            <div className={classes.aboveHeader}>

            </div>
            <div className={classes.header}>
                <img className={classes.logo} src={logoSmall} alt="Drybar - Coming Soon" />
            </div>
                <Grid 
                    container   
                    className={classes.page}
                    spacing={0}
                    direction="row"
                    alignItems="center"
                    justifyContent="center">
                    <Grid 
                        item 
                        className={classes.content}
                        xs={12} 
                        md={6}>
                        <div className={classes.page}>
                            <Typography className={classes.sectionTitle}>COMING SOON</Typography>
                        </div>
                    </Grid>
            </Grid>
        </div>
  );
}

export default withStyles(styles)(ComingSoonHoldingPage);
