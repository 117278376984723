import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';

import {
    Button, Grid,
    Input, Typography,
    TextField,
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';

import useMoktaAuth from 'utils/oktaAuth';
import { MOBILE_BREAKPOINT } from '../../../Helpers/breakpoints';
import { getPageLabelFromUrl } from 'utils/helper';
import MasterCard from '../../../assets/images/paymentCard.svg';
import { threeDSTokenise, getCustomerCreditCards, getWorldpayConfig, worldpay3DSAuthentication, addCreditCardCustomer2 } from '../../../api/booking-api';
import { getWorldpayStatus } from '../../../state/ducks/Worldpay/Worldpay-Selectors';
import { toggleWorldpay, setCardAddedWorldpay } from '../../../state/ducks/Worldpay/Worldpay-Actions';
import { getLocationData, getCards } from '../../../state/ducks/Booking/Booking-Selectors';
import { getOktaUserInfo } from '../../../state/ducks/Account/Account-Selectors';
import { getChosenStore, getBarflyMembershipID, getBarflyMembershipPrice } from '../../../state/ducks/Barfly/Barfly-Selectors';
import { setCards, setSelectedCard } from '../../../state/ducks/Booking/Booking-Actions';
import { getAuthUserInfo } from '../../../state/ducks/Booking/Booking-Selectors';
import { getBookerCustomerId } from '../../../state/ducks/Barfly/Barfly-Selectors';
import { setAuthUserDetails } from '../../../state/ducks/Account/Account-Actions';
import restClient from '../../../api/restClient';
import BackdropCircularProgress from '../../../app/Components/common/BackdropCircularProgress';

import { FieldLoading } from './FieldLoading';

import './CreditCardBlock.scss';

const Base64 = {
    _keyStr: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",
    encode: function (e) { var t = ""; var n, r, i, s, o, u, a; var f = 0; e = Base64._utf8_encode(e); while (f < e.length) { n = e.charCodeAt(f++); r = e.charCodeAt(f++); i = e.charCodeAt(f++); s = n >> 2; o = (n & 3) << 4 | r >> 4; u = (r & 15) << 2 | i >> 6; a = i & 63; if (isNaN(r)) { u = a = 64 } else if (isNaN(i)) { a = 64 } t = t + this._keyStr.charAt(s) + this._keyStr.charAt(o) + this._keyStr.charAt(u) + this._keyStr.charAt(a) } return t }, decode: function (e) { var t = ""; var n, r, i; var s, o, u, a; var f = 0; e = e.replace(/[^A-Za-z0-9\+\/\=]/g, ""); while (f < e.length) { s = this._keyStr.indexOf(e.charAt(f++)); o = this._keyStr.indexOf(e.charAt(f++)); u = this._keyStr.indexOf(e.charAt(f++)); a = this._keyStr.indexOf(e.charAt(f++)); n = s << 2 | o >> 4; r = (o & 15) << 4 | u >> 2; i = (u & 3) << 6 | a; t = t + String.fromCharCode(n); if (u != 64) { t = t + String.fromCharCode(r) } if (a != 64) { t = t + String.fromCharCode(i) } } t = Base64._utf8_decode(t); return t }, _utf8_encode: function (e) { e = e.replace(/\r\n/g, "\n"); var t = ""; for (var n = 0; n < e.length; n++) { var r = e.charCodeAt(n); if (r < 128) { t += String.fromCharCode(r) } else if (r > 127 && r < 2048) { t += String.fromCharCode(r >> 6 | 192); t += String.fromCharCode(r & 63 | 128) } else { t += String.fromCharCode(r >> 12 | 224); t += String.fromCharCode(r >> 6 & 63 | 128); t += String.fromCharCode(r & 63 | 128) } } return t }, _utf8_decode: function (e) { var t = ""; var n = 0; var r = c1 = c2 = 0; while (n < e.length) { r = e.charCodeAt(n); if (r < 128) { t += String.fromCharCode(r); n++ } else if (r > 191 && r < 224) { c2 = e.charCodeAt(n + 1); t += String.fromCharCode((r & 31) << 6 | c2 & 63); n += 2 } else { c2 = e.charCodeAt(n + 1); c3 = e.charCodeAt(n + 2); t += String.fromCharCode((r & 15) << 12 | (c2 & 63) << 6 | c3 & 63); n += 3 } } return t }
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    backdrop: {
        zIndex: 11,
        color: '#fff',
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    requestNoteCopy: {
        fontSize: '18px',
        textAlign: 'center',
        color: theme.palette.common.grey,
        fontWeight: 800,
        paddingBottom: '15px',
        borderBottom: `1px solid ${theme.palette.common.lightGrey}`,
        [theme.breakpoints.down('sm')]: { display: 'none' },
    },
    borderBottom: {
        borderBottom: `1px solid ${theme.palette.common.lightGrey}`,
        width: '88%',
        margin: '22px 5px 2px 41px',
    },
    cardSelectionCopy: {
        textAlign: 'center',
        margin: '30px 0px 0px 0px',
        fontFamily: theme.typography.fontFamily,
        [theme.breakpoints.down('sm')]: {
            whiteSpace: 'normal',
            margin: '0 0px 30px 0',
            fontSize: '15px',
        },
        fontSize: '17px',
        color: '#42413D',
    },
    masterCard: {
        width: '223px',
        height: '163.07px',
        margin: '0% 2% 8% 2%',
        cursor: 'pointer',
    },
    selectedMasterCard: {
        width: '223px',
        height: '163.07px',
        margin: '0% 2% 8% 2%',
        cursor: 'pointer',
        border: '1px solid #767676',
        borderRadius: '22px',
    },
    MasterCardSecond: {
        width: '258px',
        height: '224.07px',
        margin: '11% 0% 0% 0%',
    },
    cardEnding: {
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.common.lightGrey[1],
        fontSize: '14px',
        cursor: 'pointer',
    },
    cardDetails: {
        margin: '-56% 0% 0% 29%',
    },
    AddCreditDetails: {
        margin: '-55% 0% 0% 12%',
        [theme.breakpoints.down('sm')]: {
            margin: '-55% 0% 0% 12%',
        },
    },
    proceedSelectedAction: {
        padding: '50px',
        margin: '50px auto 25px !important',
        width: '50%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            textAlign: 'center',
        },
    },
    proceedAddCard: {
        margin: '50px 0px 25px',
        width: '100%',
        padding: 0,
        [theme.breakpoints.down('sm')]: {
            margin: '0 0 25px',
            width: '100%',
            padding: '20px',
        },
    },
    proceedSelectClick: {
        left: '0.05%',
        right: '55.09%',
        bottom: '66.36%',
        fontFamily: theme.typography.fontFamily,
        fontSize: '18px',
        lineHeight: '45px',
        width: '100%',
        textTransform: 'none',
        color: '#54575A',
        backgroundColor: '#FFDD30',
        '&:hover': {
            backgroundColor: '#b29a21',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '63px',
        },
    },
    addCardBtn: {
        fontFamily: theme.typography.fontFamily,
        fontSize: '18px',
        lineHeight: '45px',
        width: '100%',
        maxWidth: '324px',
        textTransform: 'none',
        color: '#54575A',
        backgroundColor: '#FFDD30',
        margin: '0 auto',
        '&:hover': {
            backgroundColor: '#b29a21',
        },
        [theme.breakpoints.down('sm')]: {
            height: '63px',
        },
    },
    leftDivider: {
        width: '42%',
        margin: '2px 2px 2px 15px',
    },
    rightDivider: {
        width: '42%',
    },
    addCardAction: {
        margin: '50px auto 25px',
        width: '50%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            textAlign: 'center',
        },
    },
    borderBottomBetween: {
        borderBottom: `1px solid ${theme.palette.common.lightGrey[0]}`,
        width: '38%',
        margin: '0px 5px 7px 41px',
    },
    displayFlex: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: { display: 'none' },
    },
    borderBottomCopy: {
        margin: '7px 0px 0px 25px',
    },
    leftSectionContainer: {
        backgroundColor: theme.palette.common.white,
        margin: 'auto',
        padding: '26px 22px',
        [theme.breakpoints.down('sm')]: {
            padding: '20px 20px',
            margin: '20px 0',
        },
    },
    leftSectionContainer1: {
        backgroundColor: theme.palette.common.white,
        margin: 'auto',
        padding: '26px 22px',
        width: '754px',
        [theme.breakpoints.down('sm')]: {
            padding: '0px',
            width: '100%',
        },
    },
    addCreditCard: {
        left: '0.05%',
        right: '55.09%',
        bottom: '66.36%',
        fontFamily: theme.typography.fontFamily,
        fontSize: '18px',
        lineHeight: '45px',
        width: '100%',
        textTransform: 'none',
        color: '#54575A',
        border: '1px solid #54575A',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '63px',
            margin: '0px 0px 0px 0px',
        },
    },
    cardSelectionContainer: {
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'nowrap',
            overflowY: 'auto',
        },
    },
    textAlignEndTitle: {
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
        },
    },
    cardInputWrap: {
        marginTop: '40px',
    },
    cardNumber: {
        width: '100%',
    },
    cardNumberCopy: {
        fontFamily: 'URWForm',
        fontSize: '15px',
        lineHeight: '18px',
        color: '#989898',
    },
    inputExpirationMonth: {
        width: '95%',
    },
    inputCVV: {
        width: '100%',
    },
    savedCardText: {
        margin: '30px 0px 0px 21px !important',
        color: '#767676',
        [theme.breakpoints.down('sm')]: { whiteSpace: 'nowrap' },
    },
    defaultCopy: {
        margin: '50px 0px 0px !important',
        fontWeight: '600',
        textAlign: 'center',
    },
    borderOnePx: {
        border: '1px solid #D1D1D1',
        margin: '23px 0px 32px 0px',
        [theme.breakpoints.down('sm')]: {
            border: 'none',
            margin: 0,
        },
    },
    borderOnePxMobile: {
        [theme.breakpoints.down('sm')]: {
            border: '1px solid #D1D1D1',
            margin: '23px 0px 32px 0px',
        },
    },
    closeBtn: {
        position: 'absolute',
        right: 'calc(50% - 377px)',
        top: '40px',
        fontSize: 30,
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            right: 'calc(50% - 277px)',
            top: '40px',
        },
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            right: 'calc(50% - 150px)',
            top: '42px',
            fontSize: 25,
        },

    },
    cardImageWrap: {
        minWidth: '260px',
        height: '180px',
        padding: '0 !important',
        [theme.breakpoints.down('sm')]: {
            minWidth: '240px',
            height: '160px'
        },
    },
    makeDefaultWrap: {
        display: 'flex',
        margin: '10px 0',
        justifyContent: 'center',
        width: '100%',
    },
}));

/**
 * This page has been built to meet the PCI SAQ-A standard using the 
 * Worldpay Access Checkout Version 1 library. Please read the standard
 * in the docs folder to check if your company is in compliance.
 */

/**
 * The worldpay country code for Great Britain.
 */
const GBCountryCode = 'GB'

/**
 * ISO 4217 currency code.
 */
const GBPCurrencyCode = 'GBP'

/**
 * The list of brands that world-pay should accept on the embedded form.
 */
const worldPayAcceptedCardBrands = ["amex", "mastercard", "visa"]

/**
 * Worldpay 3DS DDC message origins (try/production)
 */
const worldpayTry3DSDDCV2Origin = "https://secure-test.worldpay.com";
const worldpayTry3DSDDCV3Origin = "https://centinelapistag.cardinalcommerce.com";
const worldpayProduction3DSDDCV2Origin = "https://centinelapi.cardinalcommerce.com";
const worldpayProduction3DSDDCV3Origin = "https://centinelapi.cardinalcommerce.com";

/**
 * The card brands that drybar accepts.
 */
const cardTypes = [
    {
        id: "1",
        label: 'AmericanExpress',
        worldpayBrand: 'amex'
    },
    {
        id: "2",
        label: 'Visa',
        worldpayBrand: 'visa'

    },
    {
        id: "3",
        label: 'MasterCard',
        worldpayBrand: 'mastercard'
    }
];

/**
 * The default card brand to be selected in the drop-down.
 */
const defaultCardType = "1";

/**
 * The options for the country code selection box.
 */
const countryCodeOptions = [
    { key: GBCountryCode, value: GBCountryCode, label: 'United Kingdom' },
    {
        "label": "Afghanistan",
        "value": "AF",
        "key": "AFG"
    },
    {
        "label": "Albania",
        "value": "AL",
        "key": "ALB"
    },
    {
        "label": "Algeria",
        "value": "DZ",
        "key": "DZA"
    },
    //{
    //    "label": "American Samoa",
    //    "value": "AS",
    //    "key": "ASM"
    //},
    {
        "label": "Andorra",
        "value": "AD",
        "key": "AND"
    },
    {
        "label": "Angola",
        "value": "AO",
        "key": "AGO"
    },
    {
        "label": "Anguilla",
        "value": "AI",
        "key": "AIA"
    },
    //{
    //    "label": "Antarctica",
    //    "value": "AQ",
    //    "key": "ATA"
    //},
    {
        "label": "Antigua and Barbuda",
        "value": "AG",
        "key": "ATG"
    },
    {
        "label": "Argentina",
        "value": "AR",
        "key": "ARG"
    },
    {
        "label": "Armenia",
        "value": "AM",
        "key": "ARM"
    },
    {
        "label": "Aruba",
        "value": "AW",
        "key": "ABW"
    },
    {
        "label": "Australia",
        "value": "AU",
        "key": "AUS"
    },
    {
        "label": "Austria",
        "value": "AT",
        "key": "AUT"
    },
    {
        "label": "Azerbaijan",
        "value": "AZ",
        "key": "AZE"
    },
    {
        "label": "Bahamas",
        "value": "BS",
        "key": "BHS"
    },
    {
        "label": "Bahrain",
        "value": "BH",
        "key": "BHR"
    },
    {
        "label": "Bangladesh",
        "value": "BD",
        "key": "BGD"
    },
    {
        "label": "Barbados",
        "value": "BB",
        "key": "BRB"
    },
    {
        "label": "Belarus",
        "value": "BY",
        "key": "BLR"
    },
    {
        "label": "Belgium",
        "value": "BE",
        "key": "BEL"
    },
    {
        "label": "Belize",
        "value": "BZ",
        "key": "BLZ"
    },
    {
        "label": "Benin",
        "value": "BJ",
        "key": "BEN"
    },
    {
        "label": "Bermuda",
        "value": "BM",
        "key": "BMU"
    },
    {
        "label": "Bhutan",
        "value": "BT",
        "key": "BTN"
    },
    {
        "label": "Bolivia",
        "value": "BO",
        "key": "BOL"
    },
    //{
    //    "label": "Bonaire, Sint Eustatius and Saba",
    //    "value": "BQ",
    //    "key": "BES"
    //},
    {
        "label": "Bosnia and Herzegovina",
        "value": "BA",
        "key": "BIH"
    },
    {
        "label": "Botswana",
        "value": "BW",
        "key": "BWA"
    },
    {
        "label": "Bouvet Island",
        "value": "BV",
        "key": "BVT"
    },
    {
        "label": "Brazil",
        "value": "BR",
        "key": "BRA"
    },
    {
        "label": "British Indian Ocean Territory",
        "value": "IO",
        "key": "IOT"
    },
    {
        "label": "Brunei Darussalam",
        "value": "BN",
        "key": "BRN"
    },
    {
        "label": "Bulgaria",
        "value": "BG",
        "key": "BGR"
    },
    {
        "label": "Burkina Faso",
        "value": "BF",
        "key": "BFA"
    },
    {
        "label": "Burundi",
        "value": "BI",
        "key": "BDI"
    },
    {
        "label": "Cambodia",
        "value": "KH",
        "key": "KHM"
    },
    {
        "label": "Cameroon",
        "value": "CM",
        "key": "CMR"
    },
    {
        "label": "Canada",
        "value": "CA",
        "key": "CAN"
    },
    {
        "label": "Cape Verde",
        "value": "CV",
        "key": "CPV"
    },
    {
        "label": "Cayman Islands",
        "value": "KY",
        "key": "CYM"
    },
    {
        "label": "Central African Republic",
        "value": "CF",
        "key": "CAF"
    },
    {
        "label": "Chad",
        "value": "TD",
        "key": "TCD"
    },
    {
        "label": "Chile",
        "value": "CL",
        "key": "CHL"
    },
    {
        "label": "China",
        "value": "CN",
        "key": "CHN"
    },
    {
        "label": "Christmas Island",
        "value": "CX",
        "key": "CXR"
    },
    {
        "label": "Cocos (Keeling) Islands",
        "value": "CC",
        "key": "CCK"
    },
    {
        "label": "Colombia",
        "value": "CO",
        "key": "COL"
    },
    {
        "label": "Comoros",
        "value": "KM",
        "key": "COM"
    },
    {
        "label": "Congo (The Republic of)",
        "value": "CD",
        "key": "COD"
    },
    //{
    //    "label": "Congo",
    //    "value": "CG",
    //    "key": "COG"
    //},
    {
        "label": "Cook Islands",
        "value": "CK",
        "key": "COK"
    },
    {
        "label": "Costa Rica",
        "value": "CR",
        "key": "CRI"
    },
    {
        "label": "C\u{F4}te d'Ivoire",
        "value": "CI",
        "key": "CIV"
    },
    {
        "label": "Croatia",
        "value": "HR",
        "key": "HRV"
    },
    //{
    //    "label": "Cuba",
    //    "value": "CU",
    //    "key": "CUB"
    //},
    {
        "label": "Cura\u{E7}ao",
        "value": "CW",
        "key": "CUW"
    },
    {
        "label": "Cyprus",
        "value": "CY",
        "key": "CYP"
    },
    {
        "label": "Czech Republic",
        "value": "CZ",
        "key": "CZE"
    },
    {
        "label": "Denmark",
        "value": "DK",
        "key": "DNK"
    },
    {
        "label": "Djibouti",
        "value": "DJ",
        "key": "DJI"
    },
    {
        "label": "Dominica",
        "value": "DM",
        "key": "DMA"
    },
    {
        "label": "Dominican Republic",
        "value": "DO",
        "key": "DOM"
    },
    {
        "label": "Ecuador",
        "value": "EC",
        "key": "ECU"
    },
    {
        "label": "Egypt",
        "value": "EG",
        "key": "EGY"
    },
    {
        "label": "El Salvador",
        "value": "SV",
        "key": "SLV"
    },
    {
        "label": "Equatorial Guinea",
        "value": "GQ",
        "key": "GNQ"
    },
    //{
    //    "label": "Eritrea",
    //    "value": "ER",
    //    "key": "ERI"
    //},
    {
        "label": "Estonia",
        "value": "EE",
        "key": "EST"
    },
    {
        "label": "Eswatini",
        "value": "SZ",
        "key": "SWZ"
    },
    {
        "label": "Ethiopia",
        "value": "ET",
        "key": "ETH"
    },
    {
        "label": "Falkland Islands (Malvinas)",
        "value": "FK",
        "key": "FLK"
    },
    {
        "label": "Faroe Islands",
        "value": "FO",
        "key": "FRO"
    },
    {
        "label": "Fiji",
        "value": "FJ",
        "key": "FJI"
    },
    {
        "label": "Finland",
        "value": "FI",
        "key": "FIN"
    },
    {
        "label": "France",
        "value": "FR",
        "key": "FRA"
    },
    {
        "label": "French Guiana",
        "value": "GF",
        "key": "GUF"
    },
    {
        "label": "French Polynesia",
        "value": "PF",
        "key": "PYF"
    },
    //{
    //    "label": "French Southern Territories",
    //    "value": "TF",
    //    "key": "ATF"
    //},
    {
        "label": "Gabon",
        "value": "GA",
        "key": "GAB"
    },
    {
        "label": "Gambia",
        "value": "GM",
        "key": "GMB"
    },
    {
        "label": "Georgia",
        "value": "GE",
        "key": "GEO"
    },
    {
        "label": "Germany",
        "value": "DE",
        "key": "DEU"
    },
    {
        "label": "Ghana",
        "value": "GH",
        "key": "GHA"
    },
    {
        "label": "Gibraltar",
        "value": "GI",
        "key": "GIB"
    },
    {
        "label": "Greece",
        "value": "GR",
        "key": "GRC"
    },
    {
        "label": "Greenland",
        "value": "GL",
        "key": "GRL"
    },
    {
        "label": "Grenada",
        "value": "GD",
        "key": "GRD"
    },
    {
        "label": "Guadeloupe",
        "value": "GP",
        "key": "GLP"
    },
    {
        "label": "Guam",
        "value": "GU",
        "key": "GUM"
    },
    {
        "label": "Guatemala",
        "value": "GT",
        "key": "GTM"
    },
    {
        "label": "Guernsey",
        "value": "GG",
        "key": "GGY"
    },
    {
        "label": "Guinea",
        "value": "GN",
        "key": "GIN"
    },
    {
        "label": "Guinea-Bissau",
        "value": "GW",
        "key": "GNB"
    },
    {
        "label": "Guyana",
        "value": "GY",
        "key": "GUY"
    },
    {
        "label": "Haiti",
        "value": "HT",
        "key": "HTI"
    },
    {
        "label": "Heard Island and McDonald Islands",
        "value": "HM",
        "key": "HMD"
    },
    {
        "label": "Holy See",
        "value": "VA",
        "key": "VAT"
    },
    {
        "label": "Honduras",
        "value": "HN",
        "key": "HND"
    },
    {
        "label": "Hong Kong SAR",
        "value": "HK",
        "key": "HKG"
    },
    {
        "label": "Hungary",
        "value": "HU",
        "key": "HUN"
    },
    {
        "label": "Iceland",
        "value": "IS",
        "key": "ISL"
    },
    {
        "label": "India",
        "value": "IN",
        "key": "IND"
    },
    {
        "label": "Indonesia",
        "value": "ID",
        "key": "IDN"
    },
    {
        "label": "Iran",
        "value": "IR",
        "key": "IRN"
    },
    {
        "label": "Iraq",
        "value": "IQ",
        "key": "IRQ"
    },
    {
        "label": "Ireland",
        "value": "IE",
        "key": "IRL"
    },
    {
        "label": "Isle of Man",
        "value": "IM",
        "key": "IMN"
    },
    {
        "label": "Israel",
        "value": "IL",
        "key": "ISR"
    },
    {
        "label": "Italy",
        "value": "IT",
        "key": "ITA"
    },
    {
        "label": "Jamaica",
        "value": "JM",
        "key": "JAM"
    },
    {
        "label": "Japan",
        "value": "JP",
        "key": "JPN"
    },
    {
        "label": "Jersey",
        "value": "JE",
        "key": "JEY"
    },
    {
        "label": "Jordan",
        "value": "JO",
        "key": "JOR"
    },
    {
        "label": "Kazakhstan",
        "value": "KZ",
        "key": "KAZ"
    },
    {
        "label": "Kenya",
        "value": "KE",
        "key": "KEN"
    },
    {
        "label": "Kiribati",
        "value": "KI",
        "key": "KIR"
    },
    {
        "label": "Korea (the Democratic People's Republic of)",
        "value": "KP",
        "key": "PRK"
    },
    {
        "label": "Korea (the Republic of)",
        "value": "KR",
        "key": "KOR"
    },
    {
        "label": "Kuwait",
        "value": "KW",
        "key": "KWT"
    },
    {
        "label": "Kyrgyzstan",
        "value": "KG",
        "key": "KGZ"
    },
    {
        "label": "Lao People's Democratic Republic",
        "value": "LA",
        "key": "LAO"
    },
    {
        "label": "Latvia",
        "value": "LV",
        "key": "LVA"
    },
    {
        "label": "Lebanon",
        "value": "LB",
        "key": "LBN"
    },
    {
        "label": "Lesotho",
        "value": "LS",
        "key": "LSO"
    },
    {
        "label": "Liberia",
        "value": "LR",
        "key": "LBR"
    },
    {
        "label": "Libya",
        "value": "LY",
        "key": "LBY"
    },
    {
        "label": "Liechtenstein",
        "value": "LI",
        "key": "LIE"
    },
    {
        "label": "Lithuania",
        "value": "LT",
        "key": "LTU"
    },
    {
        "label": "Luxembourg",
        "value": "LU",
        "key": "LUX"
    },
    {
        "label": "Macao",
        "value": "MO",
        "key": "MAC"
    },
    {
        "label": "Madagascar",
        "value": "MG",
        "key": "MDG"
    },
    {
        "label": "Malawi",
        "value": "MW",
        "key": "MWI"
    },
    {
        "label": "Malaysia",
        "value": "MY",
        "key": "MYS"
    },
    {
        "label": "Maldives",
        "value": "MV",
        "key": "MDV"
    },
    {
        "label": "Mali",
        "value": "ML",
        "key": "MLI"
    },
    {
        "label": "Malta",
        "value": "MT",
        "key": "MLT"
    },
    {
        "label": "Marshall Islands",
        "value": "MH",
        "key": "MHL"
    },
    {
        "label": "Martinique",
        "value": "MQ",
        "key": "MTQ"
    },
    {
        "label": "Mauritania",
        "value": "MR",
        "key": "MRT"
    },
    {
        "label": "Mauritius",
        "value": "MU",
        "key": "MUS"
    },
    {
        "label": "Mayotte",
        "value": "YT",
        "key": "MYT"
    },
    {
        "label": "Mexico",
        "value": "MX",
        "key": "MEX"
    },
    {
        "label": "Micronesia",
        "value": "FM",
        "key": "FSM"
    },
    {
        "label": "Moldova",
        "value": "MD",
        "key": "MDA"
    },
    {
        "label": "Monaco",
        "value": "MC",
        "key": "MCO"
    },
    {
        "label": "Mongolia",
        "value": "MN",
        "key": "MNG"
    },
    {
        "label": "Montenegro",
        "value": "ME",
        "key": "MNE"
    },
    {
        "label": "Montserrat",
        "value": "MS",
        "key": "MSR"
    },
    {
        "label": "Morocco",
        "value": "MA",
        "key": "MAR"
    },
    {
        "label": "Mozambique",
        "value": "MZ",
        "key": "MOZ"
    },
    {
        "label": "Myanmar",
        "value": "MM",
        "key": "MMR"
    },
    {
        "label": "Namibia",
        "value": "NA",
        "key": "NAM"
    },
    {
        "label": "Nauru",
        "value": "NR",
        "key": "NRU"
    },
    {
        "label": "Nepal",
        "value": "NP",
        "key": "NPL"
    },
    {
        "label": "Netherlands",
        "value": "NL",
        "key": "NLD"
    },
    {
        "label": "New Caledonia",
        "value": "NC",
        "key": "NCL"
    },
    {
        "label": "New Zealand",
        "value": "NZ",
        "key": "NZL"
    },
    {
        "label": "Nicaragua",
        "value": "NI",
        "key": "NIC"
    },
    {
        "label": "Niger",
        "value": "NE",
        "key": "NER"
    },
    {
        "label": "Nigeria",
        "value": "NG",
        "key": "NGA"
    },
    {
        "label": "Niue",
        "value": "NU",
        "key": "NIU"
    },
    {
        "label": "Norfolk Island",
        "value": "NF",
        "key": "NFK"
    },
    {
        "label": "Northern Mariana Islands",
        "value": "MP",
        "key": "MNP"
    },
    {
        "label": "Norway",
        "value": "NO",
        "key": "NOR"
    },
    {
        "label": "Oman",
        "value": "OM",
        "key": "OMN"
    },
    {
        "label": "Pakistan",
        "value": "PK",
        "key": "PAK"
    },
    {
        "label": "Palau",
        "value": "PW",
        "key": "PLW"
    },
    {
        "label": "Palestine, State of",
        "value": "PS",
        "key": "PSE"
    },
    {
        "label": "Panama",
        "value": "PA",
        "key": "PAN"
    },
    {
        "label": "Papua New Guinea",
        "value": "PG",
        "key": "PNG"
    },
    {
        "label": "Paraguay",
        "value": "PY",
        "key": "PRY"
    },
    {
        "label": "Peru",
        "value": "PE",
        "key": "PER"
    },
    {
        "label": "Philippines",
        "value": "PH",
        "key": "PHL"
    },
    {
        "label": "Pitcairn",
        "value": "PN",
        "key": "PCN"
    },
    {
        "label": "Poland",
        "value": "PL",
        "key": "POL"
    },
    {
        "label": "Portugal",
        "value": "PT",
        "key": "PRT"
    },
    {
        "label": "Puerto Rico",
        "value": "PR",
        "key": "PRI"
    },
    {
        "label": "Qatar",
        "value": "QA",
        "key": "QAT"
    },
    {
        "label": "Republic of North Macedonia",
        "value": "MK",
        "key": "MKD"
    },
    {
        "label": "Romania",
        "value": "RO",
        "key": "ROU"
    },
    {
        "label": "Russian Federation",
        "value": "RU",
        "key": "RUS"
    },
    {
        "label": "Rwanda",
        "value": "RW",
        "key": "RWA"
    },
    {
        "label": "R\u{E9}union",
        "value": "RE",
        "key": "REU"
    },
    {
        "label": "Saint Barth\u{E9}lemy",
        "value": "BL",
        "key": "BLM"
    },
    {
        "label": "Saint Helena, Ascension and Tristan da Cunha",
        "value": "SH",
        "key": "SHN"
    },
    {
        "label": "Saint Kitts and Nevis",
        "value": "KN",
        "key": "KNA"
    },
    {
        "label": "Saint Lucia",
        "value": "LC",
        "key": "LCA"
    },
    {
        "label": "Saint Martin (French)",
        "value": "MF",
        "key": "MAF"
    },
    {
        "label": "Saint Pierre and Miquelon",
        "value": "PM",
        "key": "SPM"
    },
    {
        "label": "Saint Vincent and the Grenadines",
        "value": "VC",
        "key": "VCT"
    },
    {
        "label": "Samoa",
        "value": "WS",
        "key": "WSM"
    },
    {
        "label": "San Marino",
        "value": "SM",
        "key": "SMR"
    },
    {
        "label": "Sao Tome and Principe",
        "value": "ST",
        "key": "STP"
    },
    {
        "label": "Saudi Arabia",
        "value": "SA",
        "key": "SAU"
    },
    {
        "label": "Senegal",
        "value": "SN",
        "key": "SEN"
    },
    {
        "label": "Serbia",
        "value": "RS",
        "key": "SRB"
    },
    {
        "label": "Seychelles",
        "value": "SC",
        "key": "SYC"
    },
    {
        "label": "Sierra Leone",
        "value": "SL",
        "key": "SLE"
    },
    {
        "label": "Singapore",
        "value": "SG",
        "key": "SGP"
    },
    {
        "label": "Sint Maarten (Dutch part)",
        "value": "SX",
        "key": "SXM"
    },
    {
        "label": "Slovakia",
        "value": "SK",
        "key": "SVK"
    },
    {
        "label": "Slovenia",
        "value": "SI",
        "key": "SVN"
    },
    {
        "label": "Solomon Islands",
        "value": "SB",
        "key": "SLB"
    },
    {
        "label": "Somalia",
        "value": "SO",
        "key": "SOM"
    },
    {
        "label": "South Africa",
        "value": "ZA",
        "key": "ZAF"
    },
    {
        "label": "South Georgia and the South Sandwich Islands",
        "value": "GS",
        "key": "SGS"
    },
    {
        "label": "South Sudan",
        "value": "SS",
        "key": "SSD"
    },
    {
        "label": "Spain",
        "value": "ES",
        "key": "ESP"
    },
    {
        "label": "Sri Lanka",
        "value": "LK",
        "key": "LKA"
    },
    {
        "label": "Sudan",
        "value": "SD",
        "key": "SDN"
    },
    {
        "label": "Suriname",
        "value": "SR",
        "key": "SUR"
    },
    {
        "label": "Svalbard and Jan Mayen",
        "value": "SJ",
        "key": "SJM"
    },
    {
        "label": "Sweden",
        "value": "SE",
        "key": "SWE"
    },
    {
        "label": "Switzerland",
        "value": "CH",
        "key": "CHE"
    },
    {
        "label": "Syrian Arab Republic",
        "value": "SY",
        "key": "SYR"
    },
    {
        "label": "Taiwan Region",
        "value": "TW",
        "key": "TWN"
    },
    {
        "label": "Tajikistan",
        "value": "TJ",
        "key": "TJK"
    },
    {
        "label": "Tanzania, United Republic of",
        "value": "TZ",
        "key": "TZA"
    },
    {
        "label": "Thailand",
        "value": "TH",
        "key": "THA"
    },
    {
        "label": "Timor-Leste",
        "value": "TL",
        "key": "TLS"
    },
    {
        "label": "Togo",
        "value": "TG",
        "key": "TGO"
    },
    {
        "label": "Tokelau",
        "value": "TK",
        "key": "TKL"
    },
    {
        "label": "Tonga",
        "value": "TO",
        "key": "TON"
    },
    {
        "label": "Trinidad and Tobago",
        "value": "TT",
        "key": "TTO"
    },
    {
        "label": "Tunisia",
        "value": "TN",
        "key": "TUN"
    },
    {
        "label": "Turkey",
        "value": "TR",
        "key": "TUR"
    },
    //{
    //    "label": "Turkmenistan",
    //    "value": "TM",
    //    "key": "TKM"
    //},
    {
        "label": "Turks and Caicos Islands",
        "value": "TC",
        "key": "TCA"
    },
    {
        "label": "Tuvalu",
        "value": "TV",
        "key": "TUV"
    },
    {
        "label": "UAE",
        "value": "AE",
        "key": "ARE"
    },
    {
        "label": "Uganda",
        "value": "UG",
        "key": "UGA"
    },
    {
        "label": "Ukraine",
        "value": "UA",
        "key": "UKR"
    },
    {
        "label": "United Kingdom",
        "value": "GB",
        "key": "GBR"
    },
    //{
    //    "label": "US Minor Outlying Islands",
    //    "value": "UM",
    //    "key": "UMI"
    //},
    {
        "label": "United States",
        "value": "US",
        "key": "USA"
    },
    {
        "label": "Uruguay",
        "value": "UY",
        "key": "URY"
    },
    {
        "label": "Uzbekistan",
        "value": "UZ",
        "key": "UZB"
    },
    {
        "label": "Vanuatu",
        "value": "VU",
        "key": "VUT"
    },
    {
        "label": "Venezuela",
        "value": "VE",
        "key": "VEN"
    },
    {
        "label": "Vietnam",
        "value": "VN",
        "key": "VNM"
    },
    {
        "label": "Virgin Islands (British)",
        "value": "VG",
        "key": "VGB"
    },
    {
        "label": "Virgin Islands (U.S.)",
        "value": "VI",
        "key": "VIR"
    },
    {
        "label": "Wallis and Futuna Islands",
        "value": "WF",
        "key": "WLF"
    },
    {
        "label": "Western Sahara",
        "value": "EH",
        "key": "ESH"
    },
    {
        "label": "Yemen, Republic of",
        "value": "YE",
        "key": "YEM"
    },
    {
        "label": "Zambia",
        "value": "ZM",
        "key": "ZMB"
    },
    {
        "label": "Zimbabwe",
        "value": "ZW",
        "key": "ZWE"
    }
];

/**
 * The default country code to be selected.
 */
const defaultCountryCode = GBCountryCode;

/**
* The Worldpay meta-data.
*/
const worldpayFormMetadata = (worldpayAccessGatewayId) =>
({
    /**
     * The merchant id for the Worldpay API
     */
    id: worldpayAccessGatewayId,
    /**
     * The form CSS selector
     */
    form: "#card-form",
    /**
     * The fields and their CSS selectors
     */
    fields: {
        /**
         * The credit card number field section
         */
        pan: {
            selector: "#card-pan",
            placeholder: "0000-0000-0000-0000"
        },
        /**
         * The credit card CVV number field section
         */
        cvv: {
            selector: "#card-cvv",
            placeholder: "CVV"
        },
        /**
         * The credit card expiry date field section
         */
        expiry: {
            selector: "#card-expiry",
            placeholder: "MM/YY"
        }
    },
    /**
     * Enables the formatting of the credit card number
     */
    enablePanFormatting: true,
    /**
     * The styling of the iframe-embedded input boxes
     */
    styles: {
        "input": {
            "font-size": "14px",
            "font-weight": "lighter",
            "letter-spacing": "1px",
        },
        "input::placeholder": {
            "font-size": "14px",
            "color": "lightgray",
            "font-weight": "100",
            "letter-spacing": "1px",
        },
        "input#pan": {
            "font-size": "16px",
            "font-weight": "lighter"
        },
        "input#expiry": {
            "font-size": "16px",
            "font-weight": "lighter"
        },
        "input#cvv": {
            "font-size": "16px",
            "font-weight": "lighter"
        },
        "input.is-valid": {
            "color": "#767676"
        },
        "input.is-invalid": {
            "color": "red"
        },
        "input.is-empty": {
            "color": "#767676"
        },
        "input.is-onfocus": {
            "color": "#767676"
        }
    },
    /**
     * The accessibility meta-data
     */
    accessibility: {
        lang: {
            locale: "en-GB"
        },
        title: {
            enabled: true,
            pan: "The card number goes here",
            expiry: "The expiry date goes here",
            cvv: "The CVV number goes here"
        },
        ariaLabel: {
            pan: "The card number goes here",
            expiry: "The expiry date goes here",
            cvv: "The CVV number goes here"
        },
    },
    /**
     * The allowable card brands
     */
    acceptedCardBrands: worldPayAcceptedCardBrands
});

/**
 * Initialises the form error state object.
 * @param {*} fields The list of field names
 * @returns an error state object
 */
const initFormErrors = (fields = []) => {
    const metadata = {};
    for (const i in fields) {
        metadata[fields[i]] = [];
    }
    return metadata;
}

/**
 * Initialises the form touched state object.
 * @param {*} fields The list of field names
 * @returns a touched state object
 */
const initFormTouched = (fields = []) => {
    const metadata = {};
    for (const i in fields) {
        metadata[fields[i]] = false;
    }
    return metadata;
}

/**
 * Checks if the form has errors.
 */
const hasFormErrors = (errors) => {
    for (const key of Object.keys(errors)) {
        if (errors[key].length > 0) {
            return true
        }
    }
    return false;
}

/**
 * The list of form fields (not hosted by WP).
 */
const defaultFormFields = [
    "cardName",
    "cardType",
    "address1",
    "address2",
    "address3",
    "city",
    "state",
    "postalCode",
    "countryCode",
    "makeDefault"
];

/**
 * Creates a default value state object.
 * @returns a value state object
 */
const defaultFormValues = (cards) => {
    return ({
        "cardName": "",
        "cardType": defaultCardType,
        "address1": "",
        "address2": "",
        "address3": "",
        "city": "",
        "state": "",
        "postalCode": "",
        "countryCode": defaultCountryCode,
        /**
         * The default value for the "make this card my default card" check box depends on
         * whether the card is the first add or not.
         */
        "makeDefault": cards && cards.length > 0 ? false : true
    })
};

/**
 * This regex is for Worldpay schema validation.
 */
const CARD_NAME_REGEX = /.*/i
const ADDRESS1_REGEX = /.*/i
const ADDRESS2_REGEX = /.*/i
const ADDRESS3_REGEX = /.*/i
const CITY_REGEX = /.*/i
const STATE_REGEX = /.*/i
const POSTCODE_REGEX = /.*/i
const COUNTRY_CODE_REGEX = /^[A-Z][A-Z]$/;

/**
 * A modal dialog that allows the user to add a new payment card. The payment card details
 * card-number, card-expiry-date and card-cvv are collected in form fields
 * that are setup and hosted by world pay and fall under PCI compliance (SAQ-A). The other form details;
 * billing card-owner-name, billing-address and make-card-default are managed by drybar.
 * Once the user has entered the details and they pass validation then a request is sent to Worldpay
 * to verify the card details. If the card is "verified" then the non-worldpay-hosted fields are 
 * saved to the database along with a secure token from WP that allows the card details to be used again
 * without exposing them. If the card is deemed "non-verified", then a message is displayed to the user and
 * no further action is taken.
 * 
 * @param {*} props The React props 
 * @returns A JSX element
 */
const CreditCardBlock = ({
    payemtCardDetails, locationData, isWorldpayOpen, toggleWorldpay, setCards, setSelectedCard, cards, bookerCustomerId,
    preferredShop,
    selectedMembershipPlanID,
    selectedMembershipPlanPrice,
    setCardAddedWorldpay,
    oktaUserInfo,
    authUserInfo
}) => {
    // WARNING: no logging solution included.
    // WARNING: we need to check that dynatrace or any other logging/tracing is not picking up CC details
    const isInStoreRoute = () => {
        return /instore/i.test(window.location.href);
    }

    const isBarflySignUp = () => {
        return /instore/i.test(window.location.href);
    }

    const isManageCards = () => {
        return location.pathname === "/account/manage-cards";
    }

    const isUserAuthenticated = () => {
        if (!isInStoreRoute()) return isAuthenticated();
        else return true;
    }

    /**
     * The authentication hook.
     */
    const [isAuthenticated, getUser, getUserId] = useMoktaAuth();
    const [showLoading, setLoading] = useState(false);

    /**
     * The material-ui css-in-js
     */
    const classes = useStyles();

    const location = useLocation();

    /**
     * The Worldpay configuration parameters.
     */
    const [worldpayConfig, setWorldpayConfig] = useState({});

    /**
     * The Worldpay 3DS.
     */
    const [ddcParams, setDdcParams] = useState(false);
    const [challengeParams, setChallengeParams] = useState(false);

    /**
     * The user messages.
     */
    const [generalErrorMsg, setGeneralErrorMsg] = useState(null);
    const [generalWarningMsg, setGeneralWarningMsg] = useState(null);
    const [generalSuccessMsg, setGeneralSuccessMsg] = useState(null);
    const [submitDisabled, setSubmitDisabled] = useState(false);
    /**
     * Form data for the nonworldpay form fields.
     */
    const [formValues, setFormValues] = useState(defaultFormValues(cards));
    const [formErrors, setFormErrors] = useState(initFormErrors(defaultFormFields));
    const [formTouched, setFormTouched] = useState(initFormTouched(defaultFormFields));

    /**
     * Values related to the Worldpay form fields.
     */
    const [wpPanError, setWpPanError] = useState([]);
    const [wpExpiryError, setWpExpiryError] = useState([]);
    const [wpCvvError, setWpCvvError] = useState([]);
    const [wpCardTypeError, setWpCardTypeError] = useState([]);
    const [wpFormIsReady, setWpFormIsReady] = useState(false);

    /**
     * A React reference to the modal dialog element.
     */
    const modalHtmlRef = useRef(null);

    /**
     * A React reference to the Worldpay HTML form element.
     */
    let formHtmlRef = useRef(null);

    /**
     * A React reference to the Worldpay checkout.
     */
    const checkoutRef = useRef(null);

    /**
     * A React reference to the Worldpay HTML submit button element.
     */
    const submitButtonHtmlRef = useRef(null);
    if (submitDisabled == true) {
        setTimeout(() => { setSubmitDisabled(false); }, 10000);
    }

    /**
     * A React reference to the Worldpay HTML form element.
     */
    const formValuesRef = useRef(null);

    /**
     * A React reference to the form data so that it can be accessed
     * within the Worldpay callback handlers.
     */
    formValuesRef.current = formValues;

    /**
     * A React reference to the Worldpay HTML form element.
     * The call back registers the Worldpay Checkout event handlers.
     */
    const formHtmlRefCb = useCallback((element) => {
        if (element !== null) {
            /**
             * Add Worldpay field change handlers. These are called when
             * the validation state of a field changes and not when the 
             * value changes.
             */
            // TODO: is-empty class is used by WP to indicate a field is empty, 
            // these are not set when this handler is called but after!!
            // how to observe the class changed to is-empty and act on it?
            // to correctly apply the error messages one needs to know the is-empty
            // state 
            element.addEventListener("wp:field:change", function (event) {
                switch (event.detail.field.name) {
                    case 'pan': {
                        setWpPanError(!event.detail["is-valid"] ? [...wpPanError, 'The card number is invalid'] : []);
                        break;
                    }
                    case 'expiry': {
                        setWpExpiryError(!event.detail["is-valid"] ? ['The card expiry is invalid'] : []);
                        break;
                    }
                    case 'cvv': {
                        setWpCvvError(!event.detail["is-valid"] ? ['The card CVV is invalid'] : []);
                        break;
                    }
                    default: {
                        throw new Error(`Unrecognised for field ${event.detail.field.name} in Worldpay field change event handler`);
                    }
                }
            });

            /**
             * Add Worldpay card change which is fired when the card
             * type changes base on the card number.
             */
            element.addEventListener("wp:card:change", function (event) {
                let ct = "1";
                const cts = cardTypes.filter(ct => ct.worldpayBrand === event.detail.type);
                if (cts.length === 1) {
                    ct = cts[0].id;
                    setWpCardTypeError([]);
                } else {
                    setWpCardTypeError([...wpCardTypeError, 'The card brand is not supported (amex, visa and matercard only)']);
                }
                setFormValues({ ...formValues, cardType: ct })
            });

            /**
             * Add Worldpay form is ready and loaded. Used to indicate
             * when the Worldpay form fields are loaded and ready.
             */
            element.addEventListener("wp:form:ready", function (event) {
                setWpFormIsReady(true);
            });

            element.addEventListener("wp:form:change", function (event) {
            });

            formHtmlRef.current = element;
        }
    }, []);

    /**
     * Resets the component.
     */
    const reset = () => {
        checkoutRef.current && checkoutRef.current.clearForm(function () {

            reset3ds();

            setWpPanError([]);
            setWpExpiryError([]);
            setWpCvvError([]);
            setWpCardTypeError([]);

            setFormValues(defaultFormValues(cards));
            setFormErrors(initFormErrors(defaultFormFields));
            setFormTouched(initFormTouched(defaultFormFields));

            setGeneralErrorMsg("");
            setGeneralSuccessMsg("");

            mParticle.logEvent(`Booking - Add CC form reset`, mParticle.EventType.Other, {
                'Source Page': getPageLabelFromUrl(location.pathname),
            });
        });
    }

    /**
     * Resets the 3DS process assets.
     */
    const reset3ds = () => {
        /**
         * Remove the Worldpay iframes
         */
        setDdcParams(false);
        try {
            document.getElementById("worldpay-ddc-iframe-holder") && document.getElementById("worldpay-ddc-iframe-holder").replaceChildren();
        } catch { }
        setChallengeParams(false);
        try {
            document.getElementById("worldpay-challenge-iframe-wrapper") && document.getElementById("worldpay-challenge-iframe-wrapper").replaceChildren();
        } catch { }
    }

    /**
     * Validates the form data not hosted by WP.
     * @param {*} values the new form values
     * @param {*} touched the new tuched values
     * @returns 
     */
    const validateForm = (values, touched, isSubmission) => {

        const errors = initFormErrors(defaultFormFields);

        /**
         * Is required.
         */
        if ((isSubmission || (!isSubmission && touched['cardName'])) && values['cardName'] === "") {
            errors['cardName'].push("The card name field is required");
        }

        if ((isSubmission || (!isSubmission && touched['address1'])) && values['address1'] === "") {
            errors['address1'].push("The address line 1 field is required");
        }

        if ((isSubmission || (!isSubmission && touched['city'])) && values['city'] === "") {
            errors['city'].push("The town/city field is required");
        }

        if ((isSubmission || (!isSubmission && touched['countryCode'])) && values['countryCode'] === "") {
            errors['countryCode'].push("The country code field is required");
        }

        if ((isSubmission || (!isSubmission && touched['postalCode'])) && values['countryCode'] === GBCountryCode && values['postalCode'] === "") {
            errors['postalCode'].push("The postal code field is required for UK residents");
        }


        /**
         * Format validation.
         */
        if ((isSubmission || (!isSubmission && touched['cardName'])) && !CARD_NAME_REGEX.test(values['cardName'])) {
            errors['cardName'].push("The card name field is invalid");
        }

        if ((isSubmission || (!isSubmission && touched['cardName'])) && !(values['cardName'].length >= 1 && values['cardName'].length <= 255)) {
            errors['cardName'].push("The card name field is invalid, the length must be between 1 and 255 inclusive");
        }

        if ((isSubmission || (!isSubmission && touched['address1'])) && !ADDRESS1_REGEX.test(values['address1'])) {
            errors['address1'].push("The address line 1 field is invalid");
        }

        if ((isSubmission || (!isSubmission && touched['address2'])) && !ADDRESS2_REGEX.test(values['address2'])) {
            errors['address2'].push("The address line 2 field is invalid");
        }

        if ((isSubmission || (!isSubmission && touched['address3'])) && !ADDRESS3_REGEX.test(values['address3'])) {
            errors['address3'].push("The address line 3 field is invalid");
        }

        if ((isSubmission || (!isSubmission && touched['postalCode'])) && !POSTCODE_REGEX.test(values['postalCode'])) {
            errors['postalCode'].push("The post / zip code field is invalid");
        }

        if ((isSubmission || (!isSubmission && touched['city'])) && !CITY_REGEX.test(values['city'])) {
            errors['city'].push("The town/city field is invalid");
        }

        if ((isSubmission || (!isSubmission && touched['state'])) && !STATE_REGEX.test(values['state'])) {
            errors['state'].push("The county / state / province field is invalid");
        }

        if ((isSubmission || (!isSubmission && touched['countryCode'])) && !COUNTRY_CODE_REGEX.test(values['countryCode'])) {
            errors['countryCode'].push("The country code is invalid, it must be uppercase");
        }

        if ((isSubmission || (!isSubmission && touched['countryCode'])) && !(values['countryCode'].length === 2)) {
            errors['countryCode'].push("The country code is invalid, it must have 2 characters");
        }

        return {
            values: { ...values },
            errors: { ...errors },
            touched: { ...touched }
        }
    }

    /**
     * Form change handler.
     * @param {*} fieldName the field name that called the handler 
     * @param {*} newValue the new value for the field
     */
    const onFormChange = (fieldName, newValue) => {
        const values = { ...formValues, [fieldName]: newValue }
        const touched = { ...formTouched, [fieldName]: true }
        const res = validateForm(values, touched);
        setFormValues({ ...res.values });
        setFormErrors({ ...res.errors });
        setFormTouched({ ...res.touched });
    }

    /**
     * Closed this components modal by calling injected function from parent
     */
    const onCloseModal = () => {
        mParticle.logEvent(`Add CC form modal closed`, mParticle.EventType.Other, {
            'Source Page': getPageLabelFromUrl(location.pathname),
        });
        reset();
        toggleWorldpay();

        /**
         * Update the cards in redux and set selected card to the default.
         */
        if (!isInStoreRoute()) {
            fetchCards().then(cards => {
                setCards(cards)
                if (cards?.length) {
                    const defaultCard = cards.filter(c => c.IsDefault)[0];
                    setSelectedCard(defaultCard);
                }

            });
        }
    }

    /**
     * The Worldpay generate session handler
     * @param {*} error An error message sent by WP if session gen fails
     * @param {*} sessions An object containing card href and cvv that can be passed to WP to provide a link to the card details that they are storing
     * @returns void
     */
    const onGenSession = useCallback((error, sessions) => {

        mParticle.logEvent('Booking - Worldpay checkout generate session handler invoked', mParticle.EventType.Other, {
            'Source Page': getPageLabelFromUrl(location.pathname),
        });

        /**
         * Handle errors from Worldpay session generation.
         */
        if (error) {
            if (/invalidForm: /.test(error)) {
                /**
                 * There has been a validation error in the Worldpay embedded form fields.
                 */
                setGeneralErrorMsg(error.replace("invalidForm: ", ""));
                mParticle.logEvent('Booking - Worldpay checkout form validation error', mParticle.EventType.Other, {
                    'Source Page': getPageLabelFromUrl(location.pathname),
                });
            } else {
                /**
                 * There has been a general HTTP error from the Worldpay session generation.
                 */
                setGeneralErrorMsg("A general error has occurred with card verification");
                mParticle.logEvent(`Booking - Worldpay checkout generate session error - ${error}`, mParticle.EventType.Other, {
                    'Source Page': getPageLabelFromUrl(location.pathname),
                });
            }
            return;
        }

        /**
         * Get the non Worldpay embedded form fields.
         */
        const locationId = isInStoreRoute() ? preferredShop.bookerLocationId : oktaUserInfo.locationId;
        const cardHolderName = formValuesRef.current['cardName'];
        const cardBrand = cardTypes.filter(ct => ct.id === formValuesRef.current['cardType'])[0].id;
        const billingAddress = {
            "address1": formValuesRef.current['address1'],
            "address2": formValuesRef.current['address2'] === "" ? null : formValuesRef.current['address2'],
            "address3": formValuesRef.current['address3'] === "" ? null : formValuesRef.current['address3'],
            "postalCode": formValuesRef.current['postalCode'],
            "city": formValuesRef.current['city'],
            "state": formValuesRef.current['state'] === "" ? null : formValuesRef.current['state'],
            "countryCode": formValuesRef.current['countryCode']
        };

        const customerId = isInStoreRoute() ? bookerCustomerId : oktaUserInfo.bookerID ?? authUserInfo.bookerID;
        console.log("Customer ID: " + customerId);
        const makeDefault = formValuesRef.current['makeDefault'];

        /**
         * Encode the sessionHref as URLs are monitored by the Akamai firewall and are disallowed.
         */
        let sessionHref = Base64.encode(sessions.card);
        let cvcHref = Base64.encode(sessions.cvv);

        const price = isBarflySignUp ? selectedMembershipPlanPrice : 0;

        /**
         * Make the call to the back-end to verify and save the details.
         * The verification process depends upon configuration, either
         * Worldpay Access Checkout or Worldpay 3DS Web will be used.
         * NOTE: The flag config must match here and in the back-end.
         */
        setLoading(true);
        if (worldpayConfig.worldpay3DSFlag) {
            /**
             * Start Worldpay 3DS verification process.
             */
            worldpay3DSVerification(
                locationId,
                customerId,
                cardHolderName,
                cardBrand,
                makeDefault,
                sessionHref,
                billingAddress,
                cvcHref,
                price);
        } else {
            /**
             * Else default to Worldpay Access Checkout verification process.
             */
            worldpayCheckoutVerification(
                locationId,
                customerId,
                cardHolderName,
                cardBrand,
                makeDefault,
                sessionHref,
                billingAddress,
                price);
        }
    });

    /**
     * Verify the card details using the Worldpay Access Checkout API.
     * See https://developer.worldpay.com/docs/access-worldpay/checkout
     * @param {*} locationId 
     * @param {*} customerId 
     * @param {*} cardHolderName 
     * @param {*} cardBrand 
     * @param {*} makeDefault 
     * @param {*} sessionHref the Base64 encoded sessionHref
     * @param {*} billingAddress 
     * @param {*} price 
     */
    const worldpayCheckoutVerification = (
        locationId,
        customerId,
        cardHolderName,
        cardBrand,
        makeDefault,
        sessionHref,
        billingAddress,
        price) => {

        mParticle.logEvent(`Worldpay - Checkout verification being used`, mParticle.EventType.Other, {
            'Source Page': getPageLabelFromUrl(location.pathname),
        });

        /**
         * Make the call to the verify and add payment card backend api.
         */
        restClient.post(addCreditCardCustomer2(
            locationId,
            customerId,
            cardHolderName,
            cardBrand,
            makeDefault,
            sessionHref,
            billingAddress,
            price
        ))
            .then(res => {
                /* 
                 * WARNING: errors are also sent from the back-end with a 200 status code and thus we need to handle errors here also!
                 */
                if (res.data.IsSuccess) {
                    /**
                     * 200 - Success the payment card has been verified and added
                     */
                    mParticle.logEvent('Booking - Backend AddCreditCardCustomer sucessfull, card verified and saved', mParticle.EventType.Other, {
                        'Source Page': getPageLabelFromUrl(location.pathname),
                    });

                    /**
                     * Worldpay Checkout verification complete
                     */
                    reset();
                    setGeneralSuccessMsg("Your card has been verified and saved successfully using Worldpay Checkout")
                    onCloseModal();

                } else {
                    /**
                     * Error condition
                     */
                    switch (res.data.ErrorCode) {
                        case 790: {
                            const msg = "A payment card with this number has already been saved";
                            setGeneralErrorMsg(msg);
                            mParticle.logEvent(`Booking - Save Card Error - ${msg}`, mParticle.EventType.Other, {
                                'Source Page': getPageLabelFromUrl(location.pathname),
                            });
                            break;
                        }
                        default: {
                            const msg = res.data.ErrorMessage;
                            setGeneralErrorMsg(msg);
                            mParticle.logEvent(`Booking - Save Card Error - ${msg}`, mParticle.EventType.Other, {
                                'Source Page': getPageLabelFromUrl(location.pathname),
                            });
                        }
                    }
                }
            })
            .catch(error => {
                mParticle.logEvent('Booking - Save Card Error', mParticle.EventType.Other, {
                    'Source Page': getPageLabelFromUrl(location.pathname),
                });
                if (!error.response) {
                    const msg = "A network error has occurred";
                    setGeneralErrorMsg(msg);
                    mParticle.logEvent(`Booking - Save Card Error - ${msg}`, mParticle.EventType.Other, {
                        'Source Page': getPageLabelFromUrl(location.pathname),
                    });
                    return;
                }
                switch (error.response.status) {
                    /**
                     * 400 - form did not pass validation (non-worldpay embedded)
                     */
                    case 400: {
                        const msg = "The form is invalid, please update and try again";
                        setGeneralErrorMsg(msg)
                        mParticle.logEvent(`Booking - Save Card Error - ${msg}`, mParticle.EventType.Other, {
                            'Source Page': getPageLabelFromUrl(location.pathname),
                        });
                        break;
                    }
                    /**
                     * 402 - the payment card is non-verified by worldpay
                     */
                    case 402: {
                        const msg = "The payment card cannot be verified, please use another card";
                        setGeneralErrorMsg(msg)
                        mParticle.logEvent(`Booking - Save Card Error - ${msg}`, mParticle.EventType.Other, {
                            'Source Page': getPageLabelFromUrl(location.pathname),
                        });
                        break;
                    }
                    /**
                     * 403 - the verification must be done using 3DS
                     */
                    case 403: {
                        const msg = "The verification must be done using 3DS";
                        setGeneralErrorMsg(msg)
                        mParticle.logEvent(`Booking - Save Card Error - ${msg}`, mParticle.EventType.Other, {
                            'Source Page': getPageLabelFromUrl(location.pathname),
                        });
                        break;
                    }
                    /**
                     * 409 - the payment card form field data does not match that on file at WP
                     */
                    case 409: {
                        const msg = "The information does not match the data on file with the card verification provider";
                        setGeneralErrorMsg(msg)
                        mParticle.logEvent(`Booking - Save Card Error - ${msg}`, mParticle.EventType.Other, {
                            'Source Page': getPageLabelFromUrl(location.pathname),
                        });
                        break;
                    }
                    /**
                     * 422 - the payment card number is from a unaccepted brand
                     */
                    case 422: {
                        const msg = "The card number is from an unaccepted brand (must be Visa, Master Card or American Express)";
                        setGeneralErrorMsg(msg)
                        mParticle.logEvent(`Booking - Save Card Error - ${msg}`, mParticle.EventType.Other, {
                            'Source Page': getPageLabelFromUrl(location.pathname),
                        });
                        break;
                    }
                    /**
                     * Unhandleable HTTP error condition
                     */
                    default: {
                        const msg = "There has been a general error whilst verifying and saving the payment card";
                        setGeneralErrorMsg(msg)
                        mParticle.logEvent(`Booking - Save Card Error - ${msg}`, mParticle.EventType.Other, {
                            'Source Page': getPageLabelFromUrl(location.pathname),
                        });
                    }
                }
            });

        mParticle.logEvent(`Booking - Backend AddCreditCardCustomer called`, mParticle.EventType.Other, {
            'Source Page': getPageLabelFromUrl(location.pathname),
        });
    }

    /**
     * Verify the card using Worldpay 3DS Web API (which uses the 3DS2 protocal, falling back to 3DS1)
     * The device data initialisation (DDI).
     * See https://developer.worldpay.com/docs/access-worldpay/3ds/web
     * @param {*} locationId 
     * @param {*} customerId 
     * @param {*} cardHolderName 
     * @param {*} cardBrand 
     * @param {*} makeDefault 
     * @param {*} sessionHref the Base64 encoded sessionHref
     * @param {*} billingAddress 
     * @param {*} price 
     */
    const worldpay3DSVerification = (
        locationId,
        customerId,
        cardHolderName,
        cardBrand,
        makeDefault,
        sessionHref,
        billingAddress,
        cvcHref,
        price) => {

        mParticle.logEvent(`Worldpay - 3DS verification being used`, mParticle.EventType.Other, {
            'Source Page': getPageLabelFromUrl(location.pathname),
        });
        /**
         * Make the call to the verify and add payment card backend api.
         */
        restClient.post(threeDSTokenise(
            locationId,
            customerId,
            cardHolderName,
            cardBrand,
            makeDefault,
            sessionHref,
            billingAddress
            ))
            .then(res => {
                /* 
                 * WARNING: errors are also sent from the back-end with a 200 status code and thus we need to handle errors here also!
                 */
                if (res.data.token) {
                    /**
                     * 200 - Success the payment card has been verified and added
                     */
                    mParticle.logEvent('Worldpay - 3DS DDI successfull', mParticle.EventType.Other, {
                        'Source Page': getPageLabelFromUrl(location.pathname),
                    });

                    /**
                     * Get the tokenHref from the resopnse.
                     */
                    const tokenHref = res.data.token;

                    /**
                     * Go on to to a Worldpay 3DS verification of the details using the token
                     * received from the WP Access Checkout verification stage.
                     */
                    worldpay3DSDataCollection(
                        res.data.jwt,
                        res.data.bin,
                        res.data.url,
                        tokenHref,
                        res.data.transactionReference,
                        cardBrand,
                        customerId,
                        makeDefault,
                        cvcHref,
                        price);
                } else {
                    /**
                     * Error condition
                     */
                    switch (res.data.ErrorCode) {
                        case 790: {
                            const msg = "A payment card with this number has already been saved";
                            setGeneralErrorMsg(msg);
                            mParticle.logEvent(`Booking - Save Card Error - ${msg}`, mParticle.EventType.Other, {
                                'Source Page': getPageLabelFromUrl(location.pathname),
                            });
                            break;
                        }
                        default: {
                            const msg = res.data.ErrorMessage;
                            setGeneralErrorMsg(msg);
                            mParticle.logEvent(`Booking - Save Card Error - ${msg}`, mParticle.EventType.Other, {
                                'Source Page': getPageLabelFromUrl(location.pathname),
                            });
                        }
                    }
                }
            })
            .catch(error => {
                mParticle.logEvent('Booking - Save Card Error', mParticle.EventType.Other, {
                    'Source Page': getPageLabelFromUrl(location.pathname),
                });
                if (!error.response) {
                    const msg = "A network error has occurred";
                    setGeneralErrorMsg(msg);
                    mParticle.logEvent(`Booking - Save Card Error - ${msg}`, mParticle.EventType.Other, {
                        'Source Page': getPageLabelFromUrl(location.pathname),
                    });
                    return;
                }
                switch (error.response.status) {
                    /**
                     * 400 - form did not pass validation (non-worldpay embedded)
                     */
                    case 400: {
                        const msg = "The form is invalid, please update and try again";
                        setGeneralErrorMsg(msg)
                        mParticle.logEvent(`Booking - Save Card Error - ${msg}`, mParticle.EventType.Other, {
                            'Source Page': getPageLabelFromUrl(location.pathname),
                        });
                        break;
                    }
                    /**
                     * 402 - the payment card is non-verified by worldpay
                     */
                    case 402: {
                        const msg = "The payment card cannot be verified, please use another card";
                        setGeneralErrorMsg(msg)
                        mParticle.logEvent(`Booking - Save Card Error - ${msg}`, mParticle.EventType.Other, {
                            'Source Page': getPageLabelFromUrl(location.pathname),
                        });
                        break;
                    }
                    /**
                     * 409 - the payment card form field data does not match that on file at WP
                     */
                    case 409: {
                        const msg = "The information does not match the data on file with the card verification provider";
                        setGeneralErrorMsg(msg)
                        mParticle.logEvent(`Booking - Save Card Error - ${msg}`, mParticle.EventType.Other, {
                            'Source Page': getPageLabelFromUrl(location.pathname),
                        });
                        break;
                    }
                    /**
                     * 422 - the payment card number is from a unaccepted brand
                     */
                    case 422: {
                        const msg = "The card number is from an unaccepted brand (must be Visa, Master Card or American Express)";
                        setGeneralErrorMsg(msg)
                        mParticle.logEvent(`Booking - Save Card Error - ${msg}`, mParticle.EventType.Other, {
                            'Source Page': getPageLabelFromUrl(location.pathname),
                        });
                        break;
                    }
                    /**
                     * Unhandleable HTTP error condition
                     */
                    default: {
                        const msg = "There has been a general error whilst verifying and saving the payment card";
                        setGeneralErrorMsg(msg)
                        mParticle.logEvent(`Booking - Save Card Error - ${msg}`, mParticle.EventType.Other, {
                            'Source Page': getPageLabelFromUrl(location.pathname),
                        });
                    }
                }
            });

        mParticle.logEvent(`Worldpay - 3DS DDI called`, mParticle.EventType.Other, {
            'Source Page': getPageLabelFromUrl(location.pathname),
        });
    }

    /**
     * Verify the card using Worldpay 3DS Web API (which uses the 3DS2 protocal, falling back to 3DS1)
     * The device data collection (DDC) + Challenge
     * See https://developer.worldpay.com/docs/access-worldpay/3ds/web
     * @param {*} jwt 
     * @param {*} bin 
     * @param {*} url 
     * @param {*} tokenHref 
     * @param {*} transactionRef 
     * @param {*} cardType 
     * @param {*} customerId
     * @param {*} isDefault 
     * @param {*} cvcHref 
     * @param {*} price 
     */
    const worldpay3DSDataCollection = (
        jwt,
        bin,
        url,
        tokenHref,
        transactionRef,
        cardType,
        customerId,
        isDefault,
        cvcHref,
        price) => {

        mParticle.logEvent(`Worldpay - 3DS verification being used`, mParticle.EventType.Other, {
            'Source Page': getPageLabelFromUrl(location.pathname),
        });

        /**
         * Make the call to the back-end to do the device data initialization to
         * begin the Device Data Collection (DDC).
         * This request response contains;
         * deviceDataCollection.jwt	- A digitally signed token that contains additional details required for DDC. Expires in 10 minutes for both Try and Production.
         * deviceDataCollection.url	- A POST action on the DDC form. Used to redirect to the issuers DDC page.
         * deviceDataCollection.bin	- First six digits of the card number (Bank Identification Number), used as part of DDC. Returned if a token resource or card number is included in the request.
         */

        mParticle.logEvent(`Worldpay - 3DS DDI success`, mParticle.EventType.Other, {
            'Source Page': getPageLabelFromUrl(location.pathname),
        });
        mParticle.logEvent(`Worldpay - 3DS rendering DDC i-frame`, mParticle.EventType.Other, {
            'Source Page': getPageLabelFromUrl(location.pathname),
        });

        /**
         * Register the Worldpay 3DS DDC message handler before we invoke
         * the DDC i-frame rendering by setting the ddcParams object. 
         * The DDC call typically takes 1-2 seconds, depending on the latency between 
         * the customer's device, the Cardinal servers and, in part, the type of 
         * device data collection performed by the different issuers. 
         * The 3DS specification has the maximum response time at 10 seconds.
         * Note: If no postMessage is provided either retry DDC or send the Authentication 
         * request without the deviceData.collectionReference. This downgrades 
         * the authentication to 3DS1.
         * See https://developer.worldpay.com/docs/access-worldpay/3ds/web/device-data
         */
        const ddcMessageHandler = (event) => {

            /**
             * Return immediately if the message is not from the correct origin.
             */
            if ((worldpayConfig.worldpayEnvironment === 'localhost'
                || worldpayConfig.worldpayEnvironment === 'development'
                || worldpayConfig.worldpayEnvironment === 'qa')) {
                if (event.origin !== worldpayTry3DSDDCV3Origin) {
                    return;
                }
            }
            else if (worldpayConfig.worldpayEnvironment === 'production') {
                if (event.origin !== worldpayProduction3DSDDCV3Origin) {
                    return;
                }
            }

            mParticle.logEvent(`Worldpay - 3DS DDC message handler received a message from ${url} in ${worldpayConfig.worldpayEnvironment}`, mParticle.EventType.Other, {
                'Source Page': getPageLabelFromUrl(location.pathname),
            });

            /**
             * Act on the message
             */
            const data = JSON.parse(event.data);

            if (data.MessageType && data.MessageType === "profile.completed") {
                mParticle.logEvent(`Worldpay - 3DS DDC message handler - profile completed successfully`, mParticle.EventType.Other, {
                    'Source Page': getPageLabelFromUrl(location.pathname),
                });

                /**
                 * Remove the event listener once it has been called successfully.
                 */
                window.removeEventListener("message", ddcMessageHandler);

                /**
                 * The parameters for the 3DS authentication call.
                 */

                /**
                 * UUID, not present or undefined
                 */
                const sessionId = data.SessionId;

                /**
                 * true - Use the SessionId value in deviceData.collectionReference 
                 * as part of the Authentication request
                 * 
                 * false - SessionId is empty. Either retry DDC or send the authentication 
                 * request without the deviceData.collectionReference. This downgrades 
                 * the authentication to 3DS1.
                 */

                /**
                 * Trigger the 3DS authentication in the backend API
                 */
                restClient.post(worldpay3DSAuthentication(transactionRef, tokenHref, sessionId, cardType, customerId, isDefault, cvcHref, price))
                    .then(res => {
                        mParticle.logEvent(`Worldpay - 3DS authentication success with ${sessionId ? "3DS2" : "3DS1"}`, mParticle.EventType.Other, {
                            'Source Page': getPageLabelFromUrl(location.pathname),
                        });

                        /**
                         * Check for booker error cases
                         */
                        if (res.data.ErrorCode) {
                            switch (res.data.ErrorCode) {
                                case 790: {
                                    const msg = "A payment card with this number has already been saved";
                                    setGeneralErrorMsg(msg);
                                    reset3ds();
                                    mParticle.logEvent(`Booking - Save Card Error - ${msg}`, mParticle.EventType.Other, {
                                        'Source Page': getPageLabelFromUrl(location.pathname),
                                    });
                                    return;
                                }
                                default: {
                                    const msg = res.data.ErrorMessage;
                                    setGeneralErrorMsg(msg);
                                    reset3ds();
                                    mParticle.logEvent(`Booking - Save Card Error - ${msg}`, mParticle.EventType.Other, {
                                        'Source Page': getPageLabelFromUrl(location.pathname),
                                    });
                                    return;
                                }
                            }
                        }

                        setLoading(false);

                        /**
                         * Trigger 3DS challenge if required.
                         * See https://developer.worldpay.com/docs/access-worldpay/3ds/web/authentication
                         */
                        switch (res.data.outcome) {
                            case "authenticated": {
                                mParticle.logEvent(`Worldpay - 3DS authentication successful outcome (${res.data.outcome}) with ${sessionId ? "3DS2" : "3DS1"}`, mParticle.EventType.Other, {
                                    'Source Page': getPageLabelFromUrl(location.pathname),
                                });
                                onCloseModal();
                                // If the instore barfly route then set a flag to indicate a card has been added
                                // If we're on the manage cards page we also want to update the state so the page can be re-rendered
                                // with the updated card.
                                if (isInStoreRoute() || isManageCards()) {
                                    setCardAddedWorldpay(true);
                                }
                                break;
                            }
                            case "authenticationFailed": {
                                mParticle.logEvent(`Worldpay - 3DS authentication failed outcome (${res.data.outcome}) with ${sessionId ? "3DS2" : "3DS1"}`, mParticle.EventType.Other, {
                                    'Source Page': getPageLabelFromUrl(location.pathname),
                                });
                                setGeneralErrorMsg("3DS card authentication failed.");
                                reset3ds();
                                break;
                            }
                            case "bypassed": {
                                mParticle.logEvent(`Worldpay - 3DS authentication failed outcome (${res.data.outcome}) with ${sessionId ? "3DS2" : "3DS1"}`, mParticle.EventType.Other, {
                                    'Source Page': getPageLabelFromUrl(location.pathname),
                                });
                                setGeneralErrorMsg("3DS card authentication bypassed.");
                                reset3ds();
                                break;
                            }
                            case "challenged": {
                                mParticle.logEvent(`Worldpay - 3DS authentication challenged outcome (${res.data.outcome}) with ${sessionId ? "3DS2" : "3DS1"}`, mParticle.EventType.Other, {
                                    'Source Page': getPageLabelFromUrl(location.pathname),
                                });

                                /**
                                 * Continue with triggering the challenge flow.
                                 * See https://developer.worldpay.com/docs/access-worldpay/3ds/web/authentication
                                 */

                                /**
                                 * JSON container with extra data required for the challenge.
                                 */
                                const challengeJwt = res.data.challenge.jwt;
                                /**
                                 * POST action on the challenge form. Used to redirect to the issuers challenge page as part of the challenge form.
                                 */
                                const challengeUrl = res.data.challenge.url;
                                /**
                                 * 	This links the authentication response to the subsequent challenge form and verification request.
                                 */
                                const challengeReference = res.data.challenge.reference;

                                setChallengeParams({
                                    challengeJwt,
                                    challengeUrl,
                                    challengeReference,
                                    tokenHref,
                                    customerId,
                                    cardType,
                                    transactionReference: transactionRef,
                                    isDefault,
                                    cvcHref,
                                    price
                                })
                                mParticle.logEvent(`Worldpay - 3DS rendering challenge i-frame with ${sessionId ? "3DS2" : "3DS1"}`, mParticle.EventType.Other, {
                                    'Source Page': getPageLabelFromUrl(location.pathname),
                                });

                                modalHtmlRef.current.scrollTo({ top: 0 });

                                break;
                            }
                            default: {
                                mParticle.logEvent(`Worldpay - 3DS unauthenticated outcome (${res.data.outcome}) with ${sessionId ? "3DS2" : "3DS1"}`, mParticle.EventType.Other, {
                                    'Source Page': getPageLabelFromUrl(location.pathname),
                                });
                                setGeneralErrorMsg("A general error occurred during 3DS authentication.");
                                reset3ds();
                            }
                        }

                    })
                    .catch(err => {
                        mParticle.logEvent(`Worldpay - 3DS authentication error with ${sessionId ? "3DS2" : "3DS1"}`, mParticle.EventType.Other, {
                            'Source Page': getPageLabelFromUrl(location.pathname),
                        });
                    })
            }
        }

        window.addEventListener("message", ddcMessageHandler, false);

        /**
         * Set the DDC parameters.
         */
        setDdcParams({
            ddcJwt: jwt,
            ddcBin: bin,
            ddcUrl: url,
        });

    }

    /**
     * The Worldpay 3DS DDC message handler.
     * @param {*} event 
     * @returns void
     */
    const onWorldpayChallengeSuccessMessage = (event) => {
        if (event && event.data && event.data === 'worldpay-3ds-challenge-success') {
            mParticle.logEvent(`Worldpay - Challenge success message handler called`, mParticle.EventType.Other, {
                'Source Page': getPageLabelFromUrl(location.pathname),
            });
            setGeneralSuccessMsg("Your card has been verified and saved successfully using Worldpay Checkout")
            if (isInStoreRoute() || isManageCards())
                setCardAddedWorldpay(true);

            onCloseModal();
        }
        else if (event && event.data && event.data === 'worldpay-3ds-challenge-failed') {
            mParticle.logEvent(`Worldpay - Challenge error message handler called`, mParticle.EventType.Other, {
                'Source Page': getPageLabelFromUrl(location.pathname),
            });
            setGeneralErrorMsg("Verification with your bank failed. Please try again.");
            reset3ds();
        }
    }

    /**
     * The form submission button click handler function.
     * @param {*} event A react mouse click event
     * @returns void
     */
    const onClick = (event) => {
        event.preventDefault();

        // disable the submit button
        setSubmitDisabled(true);

        mParticle.logEvent('Worldpay checkout submission on-click handler called', mParticle.EventType.Other, {
            'Source Page': getPageLabelFromUrl(location.pathname),
        });

        /**
         * Check if the form is valid or not.
         */
        const validationResult = validateForm(formValuesRef.current, {}, true);

        /**
         * Report the validation errors to the user.
         */
        if (hasFormErrors(validationResult.errors)) {
            setFormErrors({ ...validationResult.errors });
            mParticle.logEvent('Worldpay checkout form validation error', mParticle.EventType.Other, {
                'Source Page': getPageLabelFromUrl(location.pathname),
            });
            // enable the submit button
            setSubmitDisabled(false);
            return;
        }

        /**
         * Generate the Worldpay session which encrypts the payment card details from
         * the Worldpay embedded form into a session token that is passed back as a
         * sessionHref link value.
         */
        checkoutRef.current.generateSessions(onGenSession)
        mParticle.logEvent('Worldpay checkout generate session called', mParticle.EventType.Other, {
            'Source Page': getPageLabelFromUrl(location.pathname),
        });
    }

    /**
     * A handler called after WP has initialised.
     * @param {*} error An error message sent by WP if the init failed
     * @param {*} checkout A reference to the WP checkout object
     * @returns void
     */
    const onWorldPayInit = (error, checkout) => {

        /**
         * An error has occurred whilst trying to initialise Worldpay Checkout
         */
        if (error) {
            setGeneralErrorMsg("A general error has occurred with checkout");
            mParticle.logEvent(`Booking - Worldpay checkout initialisation error - ${error}`, mParticle.EventType.Other, {
                'Source Page': getPageLabelFromUrl(location.pathname),
            });
            return;
        }

        /**
         * Set the checkout reference.
         */
        checkoutRef.current = checkout;
        mParticle.logEvent('Booking - Worldpay checkout initialised', mParticle.EventType.Other, {
            'Source Page': getPageLabelFromUrl(location.pathname),
        });

        /**
         * The effect clen-up handler.
         */
        return () => { }
    }

    /**
     * Makes a call to the back end to get the updated cards.
     * @returns 
     */
    const fetchCards = async () => {
        const user = await getUserId();
        const customerId = user.bookerID;
        const { data, error } = await restClient.post(getCustomerCreditCards(customerId, locationData.bookerLocationId));
        return data?.CreditCards;

    }
    /**
     * Makes a call to an authenticated back end to get the form configuration.
     * @returns 
     * worldpayEnvironment = 'localhost' | 'development' | 'qa' | 'production'
     * worldpayAccessGatewayId = uuid
     * worldpay3DSFlag = true | false
     */
    const fetchWorldpayConfig = async () => {
        const { data, error } = await restClient.post(getWorldpayConfig());
        return data;
    }

    /**
     * Returns true if a field has errors.
     * @param {*} errors The errors object
     * @param {*} fieldName The field id
     */
    const hasFieldErrors = (errors, fieldName) => {
        return errors[fieldName].length > 0;
    }

    /**
     * Renders the class-names for a label.
     */
    const renderLabelClassnames = (errors, fieldName) => {
        return classNames("label", hasFieldErrors(errors, fieldName) ? "field-error" : "")
    }

    /**
     * Renders the Worldpay 3DS Device Data Collection IFrame form.
     * See https://developer.worldpay.com/docs/access-worldpay/3ds/web/device-data
     * 
     * @param {*} ddcJwt Set to the value of 'deviceDataCollection.jwt' from the device data initialization response
     * @param {*} ddcUrl Set the action to the value in the 'deviceDataCollection.url' from the device data initialization response
     * @param {*} ddcBin Use value from 'deviceDataCollection.bin' from the device data initialization response or add the card number
     * @returns 
     */
    const renderWorldpayDdcIFrame = (ddcJwt, ddcUrl, ddcBin) => {
        const ddcIFrameContent = `
        <script>
            window.onload = function() {
                const timer = setInterval(function(){ 
                    //console.log("[3DS] DDC i-frame timer called");
                    if(document.getElementById('worldpay3DSDeviceDataCollectionForm')){
                        //console.log("[3DS] DDC i-frame form mounted");
                        document.getElementById('worldpay3DSDeviceDataCollectionForm').submit();
                        //console.log("[3DS] DDC i-frame submitted");
                        clearInterval(timer);
                    } else {
                        //console.log("[3DS] DDC i-frame timer waiting for form mounting");
                    }
                }, 500);
                //console.log("[3DS] DDC i-frame loaded");
                //console.log("[3DS] DDC i-frame JWT loaded ${ddcJwt}");
                //console.log("[3DS] DDC i-frame BIN loaded ${ddcBin}");
                //console.log("[3DS] DDC i-frame URL loaded ${ddcUrl}");
                //console.log("[3DS] DDC i-frame timer set");
            }

        </script>
        <form id="worldpay3DSDeviceDataCollectionForm" name="devicedata" method="POST" action="${ddcUrl}">
            <input type="hidden" name="Bin" value="${ddcBin}" />
            <input type="hidden" name="JWT" value="${ddcJwt}" />
        </form>

        `
        return (<iframe id="worldpay3DSDeviceDataCollectionIFrame" height="1" width="1" style={{ display: "none" }} srcDoc={ddcIFrameContent} loading="eager"></iframe>);
    }

    /**
     * Renders the Worldpay 3DS Challenge IFrame form.
     * The content within the iframe is from the issuing bank. The bank performs an identity check on your customer.
     * See https://developer.worldpay.com/docs/access-worldpay/3ds/web/challenge-verification
     * 
     * @param {*} challengeJwt A digitally signed token that contains additional details, such as the URL to return to after the challenge screen. Expires in 10 minutes for both Try and Production.
     * @param {*} challengeUrl POST action on the challenge form. Used to redirect to the issuers challenge page as part of the challenge form.
     * @param {*} challengeReference This links the authentication response to the subsequent challenge form and verification request.
     * @param {*} token
     * @param {*} transactionRef
     * @param {*} cardTypeId
     * @param {*} customerId
     * @param {*} isDefault
     * @returns 
     */
    const renderWorldpayChallengeIFrame = (challengeJwt, challengeUrl, challengeReference, token, transactionRef, cardTypeId, customerId, isDefault, cvcHref, price) => {
        const challengeIFrameContent = `
            <script>
                window.onload = function() {
                    const timer = setInterval(function(){ 
                        //console.log("[3DS] Challenge i-frame timer called");
                        if(document.getElementById('worldpay3DSChallengeForm')){
                            //console.log("[3DS] Challenge i-frame form mounted");
                            document.getElementById('worldpay3DSChallengeForm').submit();
                            //console.log("[3DS] Challenge i-frame submitted");
                            clearInterval(timer);
                        } else {
                            //console.log("[3DS] Challenge i-frame timer waiting for form mounting");
                        }
                    }, 500);
                    //console.log("[3DS] Challenge i-frame loaded");
                    //console.log("[3DS] Challenge i-frame JWT loaded ${challengeJwt}");
                    //console.log("[3DS] Challenge i-frame REF loaded ${challengeReference}");
                    //console.log("[3DS] Challenge i-frame URL loaded ${challengeUrl}");
                    //console.log("[3DS] Challenge i-frame timer set");
                }
            </script>
            <form id="worldpay3DSChallengeForm" method="POST" action="${challengeUrl}">
                <input type="hidden" name="JWT" value="${challengeJwt}" />
                <input type="hidden" name="MD" value="token=${token}&cti=${cardTypeId}&cid=${customerId}&tref=${transactionRef}&isd=${isDefault}&c=${cvcHref}&a=${price}" />
            </form>
            `;

        return (<iframe id="worldpay3DSChallengeIFrame" height="400" width="390" srcDoc={challengeIFrameContent} loading="eager" style={{ border: 'none' }} ></iframe>);
    }

    /**
     * Sets up the Worldpay forms and handlers. This method should only be called
     * once during the lifetime of the application. Multiple calls to Worldpay.checkout.init
     * cause message-handlers to build up on the window object with no way to clean them up.
     */
    useEffect(() => {
        /**
         * The script element.
         */
        let script = null;

        /**
         * A lock flag to ensure that if 2 interval handlers are invoked only
         * one runs.
         */
        let hasInitialised = false;

        /**
          * The Worldpay loader interval handler id.
          */
        let wpLoaderIntervalId = 0;

        /**
         * A interval handler that checks to see if and when the user
         * is authorised. When the user authorises for the first time
         * the handler will import the WP library and initialise it.
         */
        const wpLoaderIntervalHandler = () => {

            // mParticle.logEvent('Booking - Worldpay loader interval checking ...', mParticle.EventType.Other, {
            //     'Source Page': getPageLabelFromUrl(location.pathname),
            // });

            /**
             * Only load and initialise Worldpay once, when the user
             * has been authenticated.
             */
            if (!hasInitialised && isUserAuthenticated()) {

                /**
                 * Ensure that the init code can only be run once using
                 * a lock flag.
                 */
                hasInitialised = true;

                /**
                 * Load the form configuration from an authenticated backend
                 * call. The configuration consists of the following parameters;
                 * 
                 * worldpayEnvironment = 'localhost' | 'development' | 'qa' | 'production'
                 * worldpayAccessGatewayId = uuid
                 * worldpay3DSFlag = true | false
                 */
                fetchWorldpayConfig()
                    .then(res => {

                        mParticle.logEvent('Booking - Worldpay config success', mParticle.EventType.Other, {
                            'Source Page': getPageLabelFromUrl(location.pathname),
                        });

                        /**
                         * Get the config from the response.
                         */
                        const worldpayEnvironment = res.worldpayEnvironment;
                        const worldpayBaseUrl = worldpayEnvironment === 'production' ? 'https://access.worldpay.com' : 'https://try.access.worldpay.com';
                        const worldpayAccessGatewayId = res.accessGatewayId;
                        const worldpay3DSFlag = res.threeDSEnabled;

                        /**
                         * Set the Worldpay config for the component.
                         */
                        setWorldpayConfig({
                            worldpayEnvironment,
                            worldpayAccessGatewayId,
                            worldpay3DSFlag,
                            worldpayBaseUrl
                        })

                        /**
                         * Dynamically load the Worldpay Checkout library.
                         */
                        script = document.createElement('script');

                        /**
                         * The library is either the try (https://try.access.worldpay.com) version or the 
                         * production version (https://access.worldpay.com).
                         */
                        script.src = `${worldpayBaseUrl}/access-checkout/v1/checkout.js`;
                        script.async = true;
                        script.onload = () => {
                            /**
                             * Initialise the Worldpay Checkout library.
                             */
                            Worldpay.checkout.init(worldpayFormMetadata(worldpayAccessGatewayId), onWorldPayInit);
                            mParticle.logEvent('Booking - Worldpay library initialised', mParticle.EventType.Other, {
                                'Source Page': getPageLabelFromUrl(location.pathname),
                            });

                            /**
                             * Register Worlday 3DS Challenge Success message handler.
                             */
                            window.removeEventListener("message", onWorldpayChallengeSuccessMessage);
                            window.addEventListener("message", onWorldpayChallengeSuccessMessage, false);

                            /**
                             * Clear the timeout once the Worldpay has been configured, loaded and initialised.
                             */
                            clearInterval(wpLoaderIntervalId);
                            mParticle.logEvent('Booking - Worldpay loader interval cleared', mParticle.EventType.Other, {
                                'Source Page': getPageLabelFromUrl(location.pathname),
                            });
                        }
                        script.onerror = () => {
                            mParticle.logEvent(`Booking - Worldpay Checkout SDK loading error`, mParticle.EventType.Other, {
                                'Source Page': getPageLabelFromUrl(location.pathname),
                            });
                            /**
                             * We have not successfully loaded the WP Checkout SDK, try again.
                             */
                            hasInitialised = false;
                        }
                        document.body.appendChild(script);
                    })
                    .catch(err => {
                        mParticle.logEvent(`Booking - Worldpay config error - ${err}`, mParticle.EventType.Other, {
                            'Source Page': getPageLabelFromUrl(location.pathname),
                        });
                        setGeneralErrorMsg("There has been an error configuring Worldpay");
                        /**
                         * We have not successfully got the config, so init has not happened yet, try again.
                         */
                        hasInitialised = false;
                    })
            } else {
                //  if(hasInitialised){
                //     mParticle.logEvent(`Booking - Worldpay config - already initialising`, mParticle.EventType.Other, {
                //         'Source Page': getPageLabelFromUrl(location.pathname),
                //     });
                //  } else {
                //     mParticle.logEvent(`Booking - Worldpay config error - unauthorised`, mParticle.EventType.Other, {
                //         'Source Page': getPageLabelFromUrl(location.pathname),
                //     });
                //  }   
            }
        }

        /**
         * Set the Worldpay loader interval handler running.
         */
        wpLoaderIntervalId = setInterval(wpLoaderIntervalHandler, 1000);
        mParticle.logEvent('Booking - Worldpay loader interval running', mParticle.EventType.Other, {
            'Source Page': getPageLabelFromUrl(location.pathname),
        });

        return () => {
            /**
             * Clean up the Worldpay Checkout library.
             * NOTE: this does not clean up the window event handlers!
             * and therefore should never be called. This component
             * should not be unmounted/remounted for any reason, only one
             * copy of the form should exist and the initial-effect should
             * only happen once during the life time of the React application.
             * If this was not the case then one could run code (like below, 
             * commented out) to clean up the component.
             */
            //document.body.removeChild(script);
            //Worldpay.checkout = null;
            //Worldpay = null;

            mParticle.logEvent('Booking - Worldpay library cleaned up (except its message handlers on the window object)', mParticle.EventType.Other, {
                'Source Page': getPageLabelFromUrl(location.pathname),
            });
        }
    }, [bookerCustomerId]);

    return (
        <div className="worldpay-modal" id="worldpay-modal" style={{ visibility: isUserAuthenticated() && isWorldpayOpen ? 'visible' : 'hidden' }}>
            <div className="worldpay-modal-content" ref={modalHtmlRef} >
                <div className="payment-card">
                    <div className="payment-card-close-button-row"><span className="payment-card-close-icon" onClick={() => onCloseModal()}>X</span></div>
                    <div className="payment-card-title">Add Payment Card</div>
                    <div className="payment-card-image-row">
                        <div className="payment-card-image-wrapper">
                            <img
                                src={MasterCard}
                                alt="Card Select"
                                className="card-image"
                            />
                            <div className="payment-card-content">
                                <div className="payment-card-image-text">Payment Card</div>
                                <div className="payment-card-image-number">
                                    {payemtCardDetails?.CreditCard?.Number}
                                </div>
                            </div>
                        </div>
                    </div>
                    <form id="card-form" className="checkout" ref={formHtmlRefCb}>

                        <div className={"card-row"}>
                            <section className={"card-col"}>
                                <label id="card-pan-label" className="label" htmlFor="card-pan">
                                    Card number <span className="type"></span> {!wpFormIsReady && <FieldLoading />}
                                </label>
                                <section id="card-pan" className="field"></section>
                                <div id="general-error-msg" className="error" >{wpPanError.join(". ")}</div>
                            </section>
                            <section className="card-col">
                                <label id="card-type-label" className="label" htmlFor="card-type">
                                    Card type
                                </label>
                                <TextField
                                    id="card-type"
                                    select
                                    value={formValues['cardType']}
                                    classes={{
                                        root: classes.carType,
                                    }}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    fullWidth
                                    disabled={true}
                                    onChange={(e) => onFormChange('cardType', e.target.value)}>
                                    {cardTypes.map((c) => (
                                        <option style={{ padding: '20px' }} key={c.id} value={c.id}>
                                            {c.label}
                                        </option>
                                    ))}
                                </TextField>
                                <div id="general-error-msg" className="error" >{wpCardTypeError.join(". ")}</div>
                            </section>
                        </div>
                        <div className={"card-row"}>
                            <section className="card-col">
                                <label id="card-expiry-label" className="label" htmlFor="card-expiry">
                                    Expiry date {!wpFormIsReady && <FieldLoading />}
                                </label>
                                <section id="card-expiry" className="field"></section>
                                <div id="general-error-msg" className="error" >{wpExpiryError.join(". ")}</div>
                            </section>
                            <section className="card-col">
                                <label id="card-cvv-label" className="label" htmlFor="card-cvv">
                                    CVV {!wpFormIsReady && <FieldLoading />}
                                </label>
                                <section id="card-cvv" className="field"></section>
                                <div id="general-error-msg" className="error" >{wpCvvError.join(". ")}</div>
                            </section>
                            
                        </div>

                        <div className={"card-row"}>
                            <section className="card-col">
                                <label id="card-name-label" className={renderLabelClassnames(formErrors, "cardName")} htmlFor="card-name">
                                    Name on card
                                </label>
                                <Input
                                    id="card-owner-name"
                                    required
                                    autoComplete="none"
                                    value={formValues['cardName']}
                                    onChange={(e) => onFormChange('cardName', e.target.value)}
                                    startAdornment=""
                                    placeholder="Name on Card"
                                    className={classes.enterYourEmail}
                                    fullWidth
                                />
                                <div id="general-error-msg" className="error" >{formErrors['cardName'].join(". ")}</div>
                            </section>
                        </div>

                        <div className={"card-row"}>
                            <div className={"card-col"}>
                                <label id="card-address1-label" className={renderLabelClassnames(formErrors, "address1")} htmlFor="card-address1">
                                    Address line 1 <span className="type"></span>
                                </label>
                                <Input
                                    id="card-address1"
                                    required
                                    autoComplete="none"
                                    value={formValues['address1']}
                                    onChange={(e) => onFormChange('address1', e.target.value)}
                                    startAdornment=""
                                    placeholder=""
                                    className={classes.enterYourEmail}
                                    fullWidth
                                />
                                <div id="general-error-msg" className="error" >{formErrors['address1']}</div>
                            </div>
                        </div>
                        <div className={"card-row"}>
                            <div className={"card-col"}>
                                <label id="card-address2-label" className={renderLabelClassnames(formErrors, "address2")} htmlFor="card-address2">
                                    Address line 2 <span className="type"></span>
                                </label>
                                <Input
                                    id="card-address2"
                                    value={formValues['address2']}
                                    onChange={(e) => onFormChange('address2', e.target.value)}
                                    startAdornment=""
                                    placeholder=""
                                    className={classes.enterYourEmail}
                                    fullWidth
                                />
                                <div id="general-error-msg" className="error" >{formErrors['address2']}</div>
                            </div>
                        </div>
                        <div className={"card-row"}>
                            <div className={"card-col"}>
                                <label id="card-address3-label" className={renderLabelClassnames(formErrors, "address3")} htmlFor="card-address3">
                                    Address line 3 <span className="type"></span>
                                </label>
                                <Input
                                    id="card-address3"
                                    value={formValues['address3']}
                                    onChange={(e) => onFormChange('address3', e.target.value)}
                                    startAdornment=""
                                    placeholder=""
                                    className={classes.enterYourEmail}
                                    fullWidth
                                />
                                <div id="general-error-msg" className="error" >{formErrors['address3']}</div>
                            </div>
                        </div>
                        <div className={"card-row"}>
                            <section className="card-col">
                                <label id="card-city-label" className={renderLabelClassnames(formErrors, "city")} htmlFor="card-city">
                                    Town/City
                                </label>
                                <Input
                                    id="card-city"
                                    required
                                    autoComplete="none"
                                    value={formValues['city']}
                                    onChange={(e) => onFormChange('city', e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                                    startAdornment=""
                                    placeholder=""
                                    className={classes.enterYourEmail}
                                    fullWidth
                                />
                                <div id="general-error-msg" className="error" >{formErrors['city']}</div>
                            </section>
                            <section className="card-col">
                                <label id="card-county-label" className={renderLabelClassnames(formErrors, "state")} htmlFor="card-county">
                                    County / State / Province
                                </label>
                                <Input
                                    id="card-county"
                                    autoComplete="none"
                                    value={formValues['state']}
                                    onChange={(e) => onFormChange('state', e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                                    startAdornment=""
                                    placeholder=""
                                    className={classes.enterYourEmail}
                                    fullWidth
                                />
                                <div id="general-error-msg" className="error" >{formErrors['state']}</div>
                            </section>
                        </div>
                        <div className={"card-row"}>
                            <section className="card-col">
                                <label id="card-postal-code-label" className={renderLabelClassnames(formErrors, "postalCode")} htmlFor="card-postal-code">
                                    Post / Zip code
                                </label>
                                <Input
                                    id="card-postal-code"
                                    required
                                    autoComplete="none"
                                    value={formValues['postalCode']}
                                    onChange={(e) => {
                                        const uppercasePostalCode = e.target.value.toLocaleUpperCase();
                                        onFormChange('postalCode', uppercasePostalCode);
                                        e.target.value = uppercasePostalCode; // NOTE: directly setting the value does not trigger change events, therefore no infinite loop will be triggered.
                                    }}
                                    startAdornment=""
                                    placeholder=""
                                    className={classes.enterYourEmail}
                                    fullWidth
                                />
                                <div id="general-error-msg" className="error" >{formErrors['postalCode']}</div>
                            </section>
                            <section className="card-col">
                                <label id="card-country-label" className={renderLabelClassnames(formErrors, "countryCode")} htmlFor="card-country">
                                    Country
                                </label>
                                <TextField
                                    id="card-country"
                                    select
                                    value={formValues['countryCode']}
                                    classes={{
                                        root: classes.enterYourEmail,
                                    }}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    fullWidth
                                    onChange={(e) => onFormChange('countryCode', e.target.value)}>
                                    {countryCodeOptions.map((c) => (
                                        <option style={{ padding: '20px' }} key={c.key} value={c.value}>
                                            {c.label}
                                        </option>
                                    ))}
                                </TextField>
                                <div id="general-error-msg" className="error" >{formErrors['countryCode']}</div>
                            </section>
                        </div>
                        {!isInStoreRoute() &&
                            <Grid className={classes.makeDefaultWrap}>
                                <Checkbox
                                    value={formValues['makeDefault']}
                                    checked={formValues['makeDefault']}
                                    disabled={cards && cards.length === 0 ? true : false}
                                    color="default"
                                    inputProps={{ 'aria-label': 'checkbox with default color' }}
                                    onChange={(e) => onFormChange('makeDefault', e.target.checked)}
                                />
                                <Typography style={{ whiteSpace: 'nowrap', margin: '10px 0' }}>Make Default?</Typography>
                            </Grid>
                        }
                        <div className={"card-row"}>
                            <Button id="submit-button" ref={submitButtonHtmlRef}
                                className={classes.addCardBtn} variant="outlined" onClick={onClick} disabled={submitDisabled}>
                                {!isInStoreRoute() ? "Add Payment Card" : "Pay Now"}
                            </Button>
                        </div>
                        <div className={"card-row general-errors"}>
                            <div id="general-error-msg" className="error" >{generalErrorMsg}</div>
                            <div id="general-warning-msg" className="warning" >{generalWarningMsg}</div>
                            <div id="general-success-msg" className="success" >{generalSuccessMsg}</div>
                        </div>
                    </form>
                    <div id="worldpay-iframe-holder">
                        <div id='worldpay-ddc-iframe-holder'>
                            {ddcParams && renderWorldpayDdcIFrame(ddcParams.ddcJwt, ddcParams.ddcUrl, ddcParams.ddcBin)}
                        </div>
                    </div>
                    <div id='worldpay-challenge-iframe-holder' style={{ visibility: (challengeParams !== false ? 'visible' : 'hidden') }}>
                        <div className="worldpay-challenge-iframe-close-button-row"><span className="payment-card-close-icon" onClick={() => onCloseModal()}>X</span></div>
                        <div className="worldpay-challenge-iframe-title">3DS Challenge</div>
                        <div id='worldpay-challenge-iframe-wrapper'>
                            {challengeParams &&
                                renderWorldpayChallengeIFrame(challengeParams.challengeJwt, challengeParams.challengeUrl, challengeParams.challengeReference, challengeParams.tokenHref, challengeParams.transactionReference, challengeParams.cardType, challengeParams.customerId, challengeParams.isDefault, challengeParams.cvcHref, challengeParams.price)}
                        </div>
                    </div>
                </div>

            </div>
        </div>);
};

const mapStateToProps = (state) => ({
    locationData: getLocationData(state),
    cards: getCards(state),
    isWorldpayOpen: getWorldpayStatus(state),
    bookerCustomerId: getBookerCustomerId(state),
    preferredShop: getChosenStore(state),
    selectedMembershipPlanID: getBarflyMembershipID(state),
    selectedMembershipPlanPrice: getBarflyMembershipPrice(state),
    oktaUserInfo: getOktaUserInfo(state),
    authUserInfo: getAuthUserInfo(state),
});

const mapDispatchToProps = (dispatch) => ({
    toggleWorldpay: bindActionCreators(toggleWorldpay, dispatch),
    setCards: bindActionCreators(setCards, dispatch),
    setSelectedCard: bindActionCreators(setSelectedCard, dispatch),
    setCardAddedWorldpay: bindActionCreators(setCardAddedWorldpay, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreditCardBlock);