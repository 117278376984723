import { doQuery } from '../state/utils/contentful';

const parseGlomo = (resultedText) => {
    if (!resultedText) {
        return '';
    }

    const linkRegex = /\[([^\]]+)\]\(([^\)]+)\)/g;
    resultedText = resultedText?.replace(linkRegex, (match, p1, p2) => {
        return `<a href="${p2?.replace(']', '')}">${p1}</a>`;
    });

    const boldRegex = /__(.*?)__/g;
    resultedText = resultedText.replace(boldRegex, (match, p1) => {
        return `<strong>${p1}</strong>`;
    });

    return resultedText;
};

const glomoRequest = `{
        glomoCollection(where: { slugPrefix: "drybar-glomo" }) {
            items {
              text
              backgroundColor
              fontSize
              padding
              textAlign
            }
          }
    }`;

const getNavigators = () => `
{
    drybarSiteNavigationCollection(preview: true) {
        items {
            sys {
                id
            }
            slugPrefix
        }
    }
}`;

const getNavigator = (id) => `
{
    drybarSiteNavigation(preview: true, id: "${id}") {
        imageUrl
        slugPrefix
        menuLinksCollection {
          items {
            __typename
            ... on DrybarNavigationLinkL1 {
              label
              externalUrl
              sublinkNavImageUrl
              internalLinkCollection {
                items {
                  label
                  externalUrl
                }
              }
            }
          }
        }
    }
}`;

const getFooter = (id) => `
{
    drybarSiteNavigation(preview: true, id: "${id}") {
        imageUrl
        slugPrefix
        menuLinksCollection {
          items {
            ... on DrybarSiteNavigation {
              heading
              slugPrefix
              body
              menuLinksCollection {
                items {
                  ... on DrybarNavigationLinkL1 {
                    label
                    footerColumn
                    externalUrl
                  }
                }
              }
            }
          }
        }
      }
}`;

const useNavigation = async () => {
    const glomoData = await doQuery(glomoRequest, true);
    const navsRequest = await doQuery(getNavigators(), true);

    const navs = navsRequest?.drybarSiteNavigationCollection?.items;
    const mainNav = navs.find((n) => n.slugPrefix === 'drybar-main');
    const mainNavRequest = await doQuery(getNavigator(mainNav?.sys?.id), true);

    const userNav = navs.find((n) => n.slugPrefix === 'drybar-user');
    const userNavRequest = await doQuery(getNavigator(userNav?.sys?.id), true);

    const globalNav = navs.find((n) => n.slugPrefix === 'drybar-global');
    const globalNavRequest = await doQuery(
        getNavigator(globalNav?.sys?.id),
        true
    );

    const footerNav = navs.find((n) => n.slugPrefix === 'drybar-footer');
    const footerNavRequest = await doQuery(getFooter(footerNav?.sys?.id), true);

    const siteNavigation = mainNavRequest?.drybarSiteNavigation?.menuLinksCollection?.items?.map(
        (item) => {
            const menuItems = item?.internalLinkCollection?.items;

            if (menuItems?.length > 6) {
                const firstHalf = menuItems.slice(
                    0,
                    Math.ceil(menuItems.length / 2)
                );
                const secondHalf = menuItems
                    .slice(Math.ceil(menuItems.length / 2))
                    ?.map((i) => {
                        return {
                            link: i?.externalUrl ?? '/',
                            label: i?.label,
                        };
                    });

                if (secondHalf) {
                    secondHalf?.splice(secondHalf?.length - 2, 0, {
                        separator: true,
                    });
                }

                return {
                    link: item?.externalUrl,
                    label: item?.label,
                    SubMenu: firstHalf?.map((i) => {
                        return {
                            link: i?.externalUrl ?? '/',
                            label: i?.label,
                        };
                    }),
                    SubMenu2: secondHalf,
                    SubMenuImage: item?.sublinkNavImageUrl,
                };
            }

            return {
                link: item?.externalUrl,
                label: item?.label,
                SubMenu:
                    item?.internalLinkCollection?.items?.length > 0
                        ? item?.internalLinkCollection?.items?.map((i) => {
                              return {
                                  link: i?.externalUrl ?? '/',
                                  label: i?.label,
                              };
                          })
                        : undefined,
                SubMenuImage: item?.sublinkNavImageUrl,
                newTab: item?.externalUrl?.includes('https://'),
            };
        }
    );

    const userNavigation = userNavRequest?.drybarSiteNavigation?.menuLinksCollection?.items?.map(
        (item) => {
            return {
                link: item?.externalUrl ?? '/',
                label: item?.label,
            };
        }
    );

    const globalNavigation = globalNavRequest?.drybarSiteNavigation?.menuLinksCollection?.items?.map(
        (item) => {
            return {
                link: item?.externalUrl ?? '/',
                label: item?.label,
            };
        }
    );

    const modules =
        footerNavRequest?.drybarSiteNavigation?.menuLinksCollection?.items;

    const mailingModule = modules.find(
        (m) => m.slugPrefix === 'drybar-newsletter'
    );
    const linkModules = modules?.filter(
        (m) => m.slugPrefix !== 'drybar-newsletter'
    );

    const emailOption = {
        body: mailingModule?.body,
        heading: mailingModule?.heading,
        button: {
            label: 'join',
        },
    };

    const footerNavigation = linkModules?.map((m) => {
        return {
            heading: m?.heading,
            entries: m?.menuLinksCollection?.items?.map((link) => {
                return {
                    link: link?.externalUrl ?? '/',
                    label: link?.label,
                };
            }),
        };
    });

    const config = {
        globalBanners: {},
        header: {},
        logo: '',
        footer: {},
    };

    const glomo = glomoData?.glomoCollection?.items?.[0];

    const globalBanners = {
        content: parseGlomo(glomo?.text),
        style: {
            backgroundColor: glomo?.backgroundColor,
            fontSize: glomo?.fontSize + 'px',
            padding: glomo?.padding,
            textAlign: glomo?.textAlign,
        },
    };

    config.globalBanners = globalBanners;
    config.header.siteNavigation = siteNavigation;
    config.header.userNavigation = userNavigation;
    config.header.globalNavigation = globalNavigation;
    config.logo = mainNavRequest?.drybarSiteNavigation?.imageUrl;
    config.footer.emailOption = emailOption;
    config.footer.navigation = footerNavigation;

    return config;
};

export default useNavigation;
