import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { useParams, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { SubscribeForm } from 'app/Components/SubscribeForm';
import Media from 'react-media';

import { toggleConfirmModal } from 'state/ducks/Modal/Modal-Actions';
import {
    getMetaData,
    getHero,
    getItems,
    getTopForm,
    getBottomForm,
    isSubscribeLoaded,
    getHiddenValue,
} from 'state/ducks/Subscribe/Subscribe-Selectors';
import { loadSubscribeContentful, setSubscribeLoaded } from '../../state/ducks/Subscribe/Subscribe-Actions';

import { DocumentToComponent, MetaTagWrapper, CustomImg } from '../../components';

import '../../assets/css/style.scss';
import '../../assets/css/style-responsive.scss';
import './subscribe.scss';
import './subscribe-responsive.scss';

const hiddenvalueid = '10946';

const Subscribe = ({
    hero,
    topForm,
    bottomForm,
    items,
    isLoaded,
    loadPageDataFromContentful,
    metadata,
    toggleConfirmModal,
    hiddenvalue,
    setSubscribeLoaded,
 }) => {
    const [subscribeFormLoading, setSubscribeFormLoading] = useState(false);
    const { slug } = useParams();
    const history = useHistory();

    useEffect(() => {
        if (slug) {
            setSubscribeLoaded(false);
            loadPageDataFromContentful(slug);
        } else {
            history.push('/');
        }
    }, [slug]);

    const onSuccess = () => {
        toggleConfirmModal(true);
    };

    useEffect(() => {
        if (isLoaded && (!topForm && !bottomForm && items.length === 0 && !hero)) {
            setSubscribeLoaded(false);
            history.push('/');
        }
    }, [isLoaded, topForm, bottomForm, items, hero]);

    return (
        <div className="section__background__gray">
            <MetaTagWrapper metadata={metadata} />
            {isLoaded && (
                <div
                    className="tab-pane fade show active mw-1295 m-auto subscribe-page"
                    id="nav-home"
                    role="tabpanel"
                    aria-labelledby="nav-home-tab"
                >
                    {hero && (hero.mobile || hero.desktop) && (
                    <div className="banner-section">
                        <div className="banner-image">
                            <Media query={{ maxWidth: 767 }}>
                                {(matches) => (matches ? (
                                    <CustomImg className="w-100 mx-auto d-block" src={hero.mobile || hero.desktop} alt="Subscribe Hero" style={{ maxWidth: '1295px' }} />
                                ) : (
                                    <CustomImg className="w-100 mx-auto d-block" src={hero.desktop || hero.mobile} alt="Subscribe Hero" style={{ maxWidth: '1295px' }} />
                                ))}
                            </Media>
                        </div>
                    </div>
)}

                    <div className="middle-section">
                        {topForm && (
                        <div className="top-form-container">
                            <div className={` container ${topForm.settings?.container?.textJustify}`}>
                                {topForm.title && (
                                <div className="row">
                                    <div className="col-md-12">
                                        <h3 className={`card__title__size__${(topForm.settings?.title?.size || '').toLowerCase()}`}>{topForm.title}</h3>
                                    </div>
                                </div>
)}
                                {topForm.description && topForm.description.json && (
                                <div className="row">
                                    <div className="col-md-12">
                                        <DocumentToComponent doc={topForm.description.json} />
                                    </div>
                                </div>
)}
                            </div>
                            <div className="form-wrapper">
                                <SubscribeForm
                                    onSuccess={onSuccess}
                                    setLoading={(loading) => setSubscribeFormLoading(loading)}
                                    loading={subscribeFormLoading}
                                    extraData={{
                                        [hiddenvalueid]: hiddenvalue,
                                    }}
                                />
                            </div>
                        </div>
)}
                        {
                            items.map((item, idx) => (
                                <div className="middle-container" key={idx}>
                                    <div className={`container ${item.settings?.container?.textJustify}`}>
                                        {item.title && (
                                        <div className="row">
                                            <div className="col-md-12">
                                                <h3 className={`card__title__size__${(item.settings?.title?.size || '').toLowerCase()}`}>{item.title}</h3>
                                            </div>
                                        </div>
)}
                                        {item.description && item.description.json && (
                                        <div className="row">
                                            <div className="col-md-12">
                                                <DocumentToComponent doc={topForm.description.json} />
                                            </div>
                                        </div>
)}
                                    </div>
                                    {(item.image?.desktop || item.image?.mobile) && (
                                    <Media query={{ maxWidth: 767 }}>
                                        {(matches) => (matches ? (
                                            <CustomImg className="w-100 mx-auto d-block" src={item.image.mobile || item.image.desktop} alt="Subscribe Hero" style={{ maxWidth: '1295px' }} />
                                        ) : (
                                            <CustomImg className="w-100 mx-auto d-block" src={item.image?.desktop || item.image?.mobile} alt="Subscribe Hero" style={{ maxWidth: '1295px' }} />
                                        ))}
                                    </Media>
)}
                                </div>
                            ))
                        }

                        {
                            bottomForm && (
                            <div className="bottom-form-container">
                                <div className={`container ${bottomForm.settings?.container?.textJustify}`}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h3 className={`card__title__size__${(bottomForm.settings?.title?.size || '').toLowerCase()}`}>{bottomForm.title}</h3>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            {bottomForm.description && bottomForm.description.json && <DocumentToComponent doc={bottomForm.description.json} />}
                                        </div>
                                    </div>
                                </div>
                                <div className="form-wrapper">
                                    <SubscribeForm
                                        onSuccess={onSuccess}
                                        setLoading={(loading) => setSubscribeFormLoading(loading)}
                                        loading={subscribeFormLoading}
                                        extraData={{
                                            [hiddenvalueid]: hiddenvalue,
                                        }}
                                    />
                                </div>
                            </div>
)
                        }
                    </div>

                </div>
            )}
        </div>

    );
};

const mapStateToProps = (state) => ({
    metadata: getMetaData(state),
    hero: getHero(state),
    items: getItems(state),
    topForm: getTopForm(state),
    bottomForm: getBottomForm(state),
    isLoaded: isSubscribeLoaded(state),
    hiddenvalue: getHiddenValue(state),
});

const mapDispatchToProps = (dispatch) => ({
    loadPageDataFromContentful: bindActionCreators(loadSubscribeContentful, dispatch),
    toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
    setSubscribeLoaded: bindActionCreators(setSubscribeLoaded, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Subscribe);
