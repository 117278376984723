import {
    Button,
    Typography, withStyles,
} from '@material-ui/core';
import {
    bool, func, object,
} from 'prop-types';
import React from 'react';
import { compose } from 'recompose';

const styles = (theme) => ({
    dateLabel: {
        width: '86px',
        height: '88px',
        background: theme.palette.common.white,
        border: `1px solid ${theme.palette.common.lightGrey[0]}`,
        borderRadius: '4px',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'column',
        color: theme.palette.common.grey,
        padding: '20px 0 15px',
    },
    buttonContainer: {
        border: '1px solid #D1D1D1',
        padding: '20px 25px 12px !important',
        textAlign: 'center',
        borderRadius: '4px',
        background: 'white',
        boxSizing: 'border-box',
    },
    date: {
        fontSize: '22px',
        fontWeight: '800',
    },
    month: {
        textTransform: 'none',
        fontSize: '13px',
        fontWeight: '800',
    },
    selected: {
        backgroundColor: theme.palette.common.lightGrey[1],
        color: theme.palette.common.white,
    },
});

const DateMonthCard = ({
                           value, onClick, classes, isSelected,
                       }) => {
    const dateValue = value.getDate();
    const month = value.toLocaleString('en-GB', { month: 'long' });

    return (
        <Typography
            classes={{
                label: `${classes.dateLabel} ${isSelected ? classes.selected : ''}`,
            }}
            className={classes.buttonContainer}
        >
            <Typography className={classes.date}>
                {dateValue}
            </Typography>
            <Typography className={classes.month}>
                {month}
            </Typography>
        </Typography>
    );
};

DateMonthCard.propTypes = {
    classes: object.isRequired,
    value: object,
    onClick: func,
    isSelected: bool,
};

DateMonthCard.defaultProps = {
    value: {},
    onClick: () => {
    },
    isSelected: false,
};

const enhance = compose(
    withStyles(styles),
);

export default enhance(DateMonthCard);
