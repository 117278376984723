import createState from 'redux-create-state';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session'; // defaults to localStorage for web and AsyncStorage for react-native-
import utils from '../../utils';
import { handleAddonsData, handleServicesData } from '../../utils/helpers';

import * as bookingActions from './Booking-Actions';

export const INITIAL_STATE = {
    location: {},
    dateTime: '',
    slotTime: {},
    services: {
        guestsWithDifferentServices: false,
        serviceData: [],
        availEmployeeSlots: [],
        availRoomSlots: [],
        availableDates: [],
    },
    addonsData: [],
    guests: null,
    editEnabled: false,
    notesMessage: [],
    couponCode: null,
    extensions: null,
    authUser: {},
    authUserDetails: {},
    updatedCustomer: null,
    updatedCard: null,
    buyout: null,
    cards: [],
    selectedCard: null,
    extensionAddon: null,
    appointmentIDs: [],
    groupID: null,
    editLocationID: null,
    isEditExtensions: null,
};

const bookingPersistConfig = {
    key: 'booking',
    storage,
};

export default persistReducer(bookingPersistConfig, utils.createReducer(INITIAL_STATE)({
    [bookingActions.setDateTimeClient().type]: (state, action) => createState(state || INITIAL_STATE, ['dateTime', action.payload]),
    [bookingActions.setLocationData().type]: (state, action) => createState(state || INITIAL_STATE, ['location', action.payload]),
    [bookingActions.setBookingService().type]: (state, action) => createState(state || INITIAL_STATE, ['services.serviceData', handleServicesData(state, action.payload)]),
    [bookingActions.overwriteServicesData().type]: (state, action) => createState(state || INITIAL_STATE, ['services.serviceData', action.payload]),
    // set add-ons selected data
    [bookingActions.setAddOnsServiceUser().type]: (state, action) => createState(state || INITIAL_STATE, ['addonsData', handleAddonsData(state, action.payload)]),
    [bookingActions.setAddOns().type]: (state, action) => createState(state || INITIAL_STATE, ['addonsData', action.payload]),

    [bookingActions.setNumberOfGuests().type]: (state, action) => createState(state || INITIAL_STATE, ['guests', action.payload]),
    [bookingActions.setDifferentServiceEachUser().type]: (state, action) => createState(state || INITIAL_STATE, ['services.guestsWithDifferentServices', action.payload]),
    [bookingActions.resetOnGuestChange().type]: (state) => createState(state || INITIAL_STATE, ['services', INITIAL_STATE.services], ['addonsData', INITIAL_STATE.addonsData], ['extensions', INITIAL_STATE.extensions]),
    [bookingActions.setSlotTime().type]: (state, action) => createState(state || INITIAL_STATE, ['slotTime', action.payload]),
    [bookingActions.clearBookingDetails().type]: () => INITIAL_STATE,
    [bookingActions.setEditEnabled().type]: (state, action) => createState(state || INITIAL_STATE, ['editEnabled', action.payload]),
    [bookingActions.setRequestNoteMessage().type]: (state, action) => createState(state || INITIAL_STATE, ['notesMessage', action.payload]),
    [bookingActions.setExtensions().type]: (state, action) => createState(state || INITIAL_STATE, ['extensions', action.payload]),
    [bookingActions.setCards().type]: (state, action) => createState(state || INITIAL_STATE, ['cards', action.payload]),
    [bookingActions.setSelectedCard().type]: (state, action) => createState(state || INITIAL_STATE, ['selectedCard', action.payload]),

    // these reducers handling the avail employee & room id for create appointment
    [bookingActions.availEmployeeBookingSlots().type]: (state, action) => createState(state || INITIAL_STATE, ['services.availEmployeeSlots', action.payload]),
    [bookingActions.availRoomBookingSlot().type]: (state, action) => createState(state || INITIAL_STATE, ['services.availRoomSlots', action.payload]),

    // set the user info for tamparary basis
    [bookingActions.setRegisterUserInfo().type]: (state, action) => createState(state || INITIAL_STATE, ['authUser', action.payload]),

    // set the available dates in order avoid the duplicates request.
    [bookingActions.setServicesAvailableDates().type]: (state, action) => createState(state || INITIAL_STATE, ['services.availableDates', action.payload]),

    [bookingActions.setBuyoutLocation().type]: (state, action) => createState(state || INITIAL_STATE, ['buyout', action.payload]),
    [bookingActions.setExtensionAddon().type]: (state, action) => createState(state || INITIAL_STATE, ['extensionAddon', action.payload]),
    [bookingActions.setAppointmentIDs().type]: (state, action) => createState(state || INITIAL_STATE, ['appointmentIDs', action.payload]),
    [bookingActions.setGroupID().type]: (state, action) => createState(state || INITIAL_STATE, ['groupID', action.payload]),
    [bookingActions.setEditLocationID().type]: (state, action) => createState(state || INITIAL_STATE, ['editLocationID', action.payload]),
    [bookingActions.setCouponCode().type]: (state, action) => createState(state || INITIAL_STATE, ['couponCode', action.payload]),
    [bookingActions.setIsEditExtensions().type]: (state,action) => createState(state || INITIAL_STATE, ['isEditExtensions', action.payload]),
}));
