/**
 * Load storesCollection
 * @returns {DocumentNode}
 */

import {getPreviewMode} from '../../state/utils/contentful';

const queryStoresCollection = () => (
    `{
      storeCollection(${getPreviewMode()}) {
        items {
          title
          slug
          number
          type
          bookerLocationId
          information
          mapImage {
            url
          }
          contact
          
        }
      }
    }`
);

const queryStoresCollectionFromContentful = () => (
  `{
    drybarPageCollection(${getPreviewMode()},where: { slug: "locator" }) {
      items {
        formatofPageCollection {
          items {
            ... on DrybarLocation {
              title
              slugPrefix
              storeType
              bookerLocationId
              sandboxLocationId
              parkingInformation
              operatingMessage
              city
              latitude
              longitude
              country
              phoneNumber
              postalCode
              region
              county
              street1
              street2
              allowBookings
              visibleOn
              enabled
              timerange1
              timeRange2
              timeRange3
            }
          }
        }
      }
    }
  }`
);

export {
    queryStoresCollection,
    queryStoresCollectionFromContentful
};
