import createState from 'redux-create-state';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import utils from 'state/utils';

import * as worldpayActions from './Worldpay-Actions';

export const INITIAL_STATE = {
    isWorldpayOpen: false,
    isCardAdded: false
};

const worldpayPersistConfig = {
    key: 'worldpay',
    storage,
};

export default utils.createReducer(INITIAL_STATE)({
    [worldpayActions.toggleWorldpay().type]: (state, action) => {
        return createState(state || INITIAL_STATE, ['isWorldpayOpen', !state.isWorldpayOpen],)
    },
    [worldpayActions.setCardAddedWorldpay().type]: (state, action) => {
        return createState(state || INITIAL_STATE, ['isCardAdded', action.payload],)
    }
});