/* eslint-disable import/prefer-default-export */

import buyoutActionTypes from './Buyout-ActionTypes';

export const loadBuyoutContentful = (slug) => ({
    type: buyoutActionTypes.BUYOUT_CONTENT_LOAD,
    payload: slug,
});

export const setBuyoutContent = (payload) => ({
    type: buyoutActionTypes.BUYOUT_SET_CONTENT,
    payload,
});
