import React from 'react';

import Base from './base';

import './card.css';

export default ({
 card, id, format, ...rest
}) =>

    // NOTE:  If we need a different layout we could import
    // the individual components from within Base and organize
    // them differently, as per the needs of the card format
	 (
    <Base card={card} format={format} id={id} {...rest} />
    );
