/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import {
    Backdrop,
    Button,
    Checkbox, CircularProgress, Grid, Tooltip, Typography, withStyles,
} from '@material-ui/core';
import {
    object,
} from 'prop-types';
import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import InfoIcon from '@material-ui/icons/Info';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { setAddOnsServiceUser } from '../../../../state/ducks/Booking/Booking-Actions';
import { isGuestWithDifferentServices, getAddOnsServiceData, getAvailableServiceIds } from '../../../../state/ducks/Booking/Booking-Selectors';
import TreatmentDetailsModal from '../../../../app/Components/TreatmentDetailsModal';
import { productionInformationByReference, getProductInfoFromBooker } from '../../../../gql/queries/productCollection';
import { doQuery } from '../../../../state/utils/contentful';

const styles = (theme) => ({
    root: {
        padding: '20px 0 20px',
        backgroundColor: theme.palette.common.white,
        display: 'flex',
        justifyContent: 'space-between',
        minWidth: '550px',
        marginBottom: '12px',
        boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.06)',
        alignItems: 'center',
        '&:last-child': {
            marginBottom: 0,
        },
        width: '100%',
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        },
        [theme.breakpoints.down('sm')]: {
            padding: '10px 0',
            minWidth: 0,
            height: 'auto',
            minHeight: '85px',
            '&:hover': { backgroundColor: theme.palette.common.white }
        },
        
    },
    content: {
        textAlign: 'left',
        margin: '5px 0px 0px 27px',
        [theme.breakpoints.down('sm')]: {
            margin: '0px 0px 0px 10px',
        }
    },
    buttonContainer: {
        maxWidth: '100%',
        width: '100%',
        padding: '0',
        margin: '5px 0 0px 0',
        [theme.breakpoints.down('sm')]: {
            margin: '3px 0',
        }
    },
    icon: {
        position: 'absolute',
        top: 'calc(50% - 15px)',
        left: 0,
        fontSize: '20px',
        color: theme.palette.common.grey,
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            margin: '5px 18px 0px 15px',
        },
    },
    addOnName: {
        fontSize: '18px',
        display: 'flex',
        fontWeight: '500',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: '17px',
            marginLeft: '0px',
            whiteSpace: 'normal',
        },
    },
    description: {
        fontStyle: 'oblique',
        [theme.breakpoints.down('sm')]: {
            marginLeft: '0px',
            fontSize: '14px',
            whiteSpace: 'initial',
        },
    },
    price: {
        fontSize: '18px',
        fontWeight: '500',
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
            marginLeft: '0px',
        },
    },
    checkbox: {
        color: theme.palette.common.lightGrey[1],
        fontSize: 'px',
        '&:hover': {
            boxShadow: 'none',
            backgroundColor: theme.palette.common.white,
        },
    },
    checkboxRoot: {
        height: 'fit-content',
        width: 'fit-content',
        marginRight: '25px',
        '&:hover': {
            boxShadow: 'none',
            backgroundColor: theme.palette.common.white,
        },
        '&.MuiCheckbox-colorSecondary.Mui-checked:hover': {
            backgroundColor: theme.palette.common.white,
        },
        [theme.breakpoints.down('sm')]: {
            marginRight: '5px',
        },
    },
    displayFlex: {
        display: 'flex',
        position: 'relative',
        paddingLeft: 50,
    },
});

const modalImages = [
    'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQVe5OP0h7GtgXN_kZsXTmSMR_pXcRmq4wETw&usqp=CAU',
    'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTUrQjyM-ZTw0Oike9aRg9ACLbGTHM7uIjcZA&usqp=CAU',
];

const description = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Pro in gravida dolor sit amet lacus accumsan et viverra justo. Donec nunc dui, varius eget nisi et, convallis bibendum est. Nulla vel odio quis nisi finibus rutrum. Sed nec ex sit amet turpis finibus tristique. Cras porta dictum varius.';

// we need user type the service is added for
// we need the addOn data selected
// if same for all, set for all
const AddOnCard = ({
    classes,
    addonData,
    selectedUser,
    isSelectedForUser,
    setSelectedAddonForUser, // setAddonsService, guests, selectedAddonsServices, isDifferentServiceEnabled, selectedUser,
    availableServiceIds,
}) => {
    const [detailsModal, setDetailsModal] = useState(false);
    const addonsPrice = addonData?.PriceInfo?.Amount;

    const [loading, setLoading] = useState(true);
    const [productForPopup, setProductForPopup] = useState(null);

    const addonsDescription = productForPopup?.subtitle;

    // const getDetail = () => {
    //     setLoading(true);
    //     const PRODUCT_ADD_ON_QUERY = productionInformationByReference(addonData.ServiceName);
    //     doQuery(PRODUCT_ADD_ON_QUERY)
    //     .then((data) => {
    //         console.log(data);
    //         setProductForPopup(data?.productCollection?.items?.[0] || null);
    //     })
    //     .catch((error) => {
    //         console.log(error);
    //     });    
    // };

    const getDetailsNew = () => {
        setLoading(true);
        const PRODUCT_ADD_ON_QUERY = getProductInfoFromBooker(addonData.ServiceID);
        doQuery(PRODUCT_ADD_ON_QUERY, true)
            .then((data) => {
                const item = data?.drybarGenericGridCardCollection?.items?.[0];

                const newData = {
                    title: item.title,
                subtitle: item.subtitle,
                price: item.price,
                productId: item.productId ? item?.productId : item?.sandboxProductId,
                slug: item.slugPrefix,
                description: {
                    json: {
                        content: [
                            {
                                content: [
                                    {
                                        value: item.description,
                                        nodeType: 'text',
                                    },
                                ],
                                nodeType: 'paragraph',
                            },
                        ],
                        nodeType: 'document',
                    },
                },
                bestFor: item.bestFor?.join(', '),
                imagesCollection: {
                    items: item?.imagesCollectionCollection?.items?.map(image => {
                        return {
                            desktopMedia: {
                                url: image.desktopImageUrl
                            },
                            mobileMedia: {
                                url: image.mobileImageUrl
                            }
                        }
                    })
                }
                }

                setLoading(false);
                setProductForPopup(newData || null);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    };

    const onOpenDetail = () => {
        setDetailsModal(true);
    };

    useEffect(() => {
        //getDetail();
        getDetailsNew();
        setLoading(false);
    }, []);

    if (loading) {
        return null;
    }

    const handleClick = (addOn) => {
        const addOnObj = {
            user: selectedUser,
            data: addOn,
        };
        setSelectedAddonForUser(addOnObj);
    };

    return (
        <>     
            {detailsModal ? (
                <TreatmentDetailsModal
                    onClose={() => setDetailsModal(false)}
                    productsForPopup={productForPopup}
                />
            ) : null}
            <Grid className={classes.displayFlex}>
                {
                    productForPopup && (
                    <Tooltip
                        disableHoverListener
                        title={addonData.title}
                        placement="left-start"
                        onClick={() => onOpenDetail(addonData.ServiceID)}
                    >
                        <InfoIcon className={classes.icon} />
                    </Tooltip>
)
                }

                <Button
                    variant="contained"
                    className={classes.buttonContainer}
                    onClick={() => {
                        // if (availableServiceIds.indexOf(addonData.ServiceID) >= 0) {
                            handleClick(addonData);
                        // }
                    }}
                    disableRipple
                >
                    <Grid className={classes.root}>
                        <Grid className={classes.content}>
                            <Typography className={classes.addOnName}>
                                {addonData.ServiceName}
                            </Typography>
                            <Typography className={classes.description}>
                                {addonsDescription}
                            </Typography>
                            <Typography className={classes.price}>
                                &#163;
                                {addonsPrice}
                            </Typography>
                        </Grid>
                        <Checkbox
                            checked={isSelectedForUser}
                            className={classes.checkboxRoot}
                            checkedIcon={<CheckCircleIcon className={classes.checkbox} />}
                            icon={<RadioButtonUncheckedIcon className={classes.checkbox} />}
                            disabled={availableServiceIds.indexOf(addonData.ServiceID) < 0}
                        />
                    </Grid>
                </Button>
            </Grid>
        </>
    );
};

AddOnCard.propTypes = {
    classes: object.isRequired,
};

const mapStateToProps = (state) => ({
    isDifferentServiceEnabled: isGuestWithDifferentServices(state),
    selectedAddonsServices: getAddOnsServiceData(state),
    availableServiceIds: getAvailableServiceIds(state),
});

const mapDispatchToProps = (dispatch) => ({
    setSelectedAddonForUser: bindActionCreators(setAddOnsServiceUser, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AddOnCard));
