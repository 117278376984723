import { resyTopLevelQuery, resySecondLevelQuery } from './contentfulResyQueries';
import { doQuery, getMetaData } from '../../state/utils/contentful';

const parseIDs = (data) => {
    const homeComponent = data?.items?.length > 0 ? data?.items[0] : {};
    const items = homeComponent.marketingComponentsCollection?.items || [];
    const ids = items.filter((item) => !!item).map((item) => ({ id: item.sys?.id || '', internalName: item.internalName ? item.internalName : '' }));

    return ids.filter((id) => id);
};

export const gqlLoadResy = async () => {
    const resySectionData = {
        title: '',
        description: {},
        banners: {},
        headlines: {},
        metadata: {},
    };

    let data = await doQuery(resyTopLevelQuery());

    const resyData = data.ressiCollection;
    if (resyData && resyData.items.length > 0) {
        resySectionData.title = resyData.items[0].title || '';
        resySectionData.description = resyData.items[0].description || {};

        const firstLevelIds = parseIDs(data.ressiCollection || {});
        const cardData = [];
        if (firstLevelIds.length > 0) {
            for (let i = 0; i < firstLevelIds.length; i++) {
                data = await doQuery(resySecondLevelQuery(firstLevelIds[i].id));
                cardData.push(data.marketingCard);
            }
        }

        resySectionData.banners = cardData;
        resySectionData.headlines = cardData.filter((el) => !el.isPrimary);
    }

    return resySectionData;
};
