/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/prop-types */
import {
    Button, Checkbox, Grid, Typography, withStyles,
} from '@material-ui/core';
import React, { useState, useCallback } from 'react';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import { Link, useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getPageLabelFromUrl } from 'utils/helper';
import { setExtensions } from '../../../../state/ducks/Booking/Booking-Actions';
import { getExtensions, getNumberOfGuests, isGuestWithDifferentServices } from '../../../../state/ducks/Booking/Booking-Selectors';
import { MOBILE_BREAKPOINT } from '../../../../Helpers/breakpoints';
import SelectUserGuest from '../Common/SelectUserGuest';

const styles = (theme) => ({
    slotButton: {
        width: '438px',
        height: '72px',
        margin: '24px 0 5px',
        fontSize: '18px',
        color: '#42413D',
        backgroundColor: theme.palette.common.white,
        boxShadow: '2px 2px 17px rgba(235, 235, 235, 0.5)',
        border: 'none',
        lineHeight: '1.5',
        borderRadius: 'none',
        '&:hover': {
            border: 'none',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            margin: '24px auto 0',
        },
        textTransform: 'none',
    },
    selected: {
        fontWeight: '800',
        backgroundColor: '#FFDD30',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        // justifyContent: 'center',
        alignItems: 'center',
        padding: '31px 0 0',
        backgroundColor: theme.palette.common.white,
        boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.06)',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            backgroundColor: '#f3f3f3',
            boxShadow: 'none',
        },
    },
    selectContainer: {
        display: 'flex',
        flexDirection: 'column',
        // justifyContent: 'center',
        alignItems: 'center',
        padding: '40px 0',
    },
    content: {
        textAlign: 'left',
        marginLeft: '42px',
        width: '100%',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            marginLeft: '0px'
        }
    },
    addOnName: {
        fontSize: '22px',
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        fontFamily: 'URWForm',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            display: 'none',
        },
    },
    buttonContainerGrid: {
        textAlign: 'center',
        margin: '34px 0px 0px 0px',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            margin: '0px 0px 0px 0px',
            textAlign: 'inherit',
        },
    },
    differentServiceCard: {
        margin: '20px 0',
        display: 'flex',
        alignItems: 'center',
    },
    buttonContainer: {
        maxWidth: '100%',
        width: '378px',
        height: '63px',
        padding: '0',
        margin: '0 0 3px 0',
        border: `1px solid ${theme.palette.secondary[100]}`,
        backgroundColor: theme.palette.common.white,
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        },
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            width: '291px',
            margin: '0 0 3px 0px',
            backgroundColor: '#F9F9F9',
            border: '1px solid #E5E5E5',
        },
    },
});

// `

const ExtensionsPage = ({
    classes, setExtensionAction, extensions, goToNextPage, guests, isGuestWithDifferentServices,
}) => {
    const [selectedUser, setSelectedUser] = useState('Me');
    const [isDifferentServiceEnabled, setIsDifferentServiceEnabled] = useState(typeof extensions === 'object' && extensions !== null);
    const isNext = !guests || (!isDifferentServiceEnabled && !isGuestWithDifferentServices && guests);
    const location = useLocation();

    const clickExtensions = useCallback(
        (val) => {
            let updatedExtensions = {};
            if (isDifferentServiceEnabled || isGuestWithDifferentServices) {
                updatedExtensions = {
                    ...(extensions || {}),
                    [selectedUser]: val,
                };
            } else {
                updatedExtensions = val;
            }

            setExtensionAction(updatedExtensions);

            if (isNext || (extensions && Object.keys(updatedExtensions).length > guests)) {
                mParticle.logEvent('Booking - Confirm Extensions', mParticle.EventType.Other, {
                    'Source Page': getPageLabelFromUrl(location.pathname),
                    Extensions: val,
                });
                goToNextPage();
            }
        },
        [isDifferentServiceEnabled, isGuestWithDifferentServices, setExtensionAction, goToNextPage, selectedUser, extensions],
    );

    return (
        <>
            {
            guests >= 1 && (
            <Grid className={classes.container}>
                <Grid className={classes.content}>
                    <Typography className={classes.addOnName}>
                        Adds approximately 20 mins to your service
                    </Typography>
                </Grid>
                <Grid style={{ display: 'flex', justifyContent: 'center', marginTop: 34 }}>
                    <InfoRoundedIcon />
                    <Typography style={{ margin: '3px 0 0 5px' }}>
                        Learn about our extension policy
                        {' '}
                        <Link to="/help-centre" target="_blank">here</Link>
                        .
                    </Typography>
                </Grid>
                <Grid className={classes.buttonContainerGrid}>
                    <Button
                        variant="contained"
                        className={classes.buttonContainer}
                        onClick={() => {
                            setExtensions(null);
                            goToNextPage();
                        }}
                    >
                        No extensions in our party
                    </Button>
                </Grid>
                {
                    !isGuestWithDifferentServices
                    && (
                    <Grid className={classes.differentServiceCard}>
                        <Checkbox
                            checked={isDifferentServiceEnabled}
                            onClick={() => {
                                setIsDifferentServiceEnabled(!isDifferentServiceEnabled);
                            }}
                            className={classes.checkbox}
                        />
                        <Typography>
                            My guests and I will be getting different extensions
                        </Typography>
                    </Grid>
)
                }
                {
                    (isDifferentServiceEnabled || isGuestWithDifferentServices)
                    && (
                    <div style={{ width: '100%', padding: '20px 0 0' }}>
                        <SelectUserGuest setSelectedUser={setSelectedUser} isDifferentServiceEnabled={isDifferentServiceEnabled} isExtension />
                    </div>
)
                }
            </Grid>
)
        }
            <Grid className={classes.selectContainer}>
                {
                !guests && (
                <>
                    <Grid className={classes.content}>
                        <Typography align="center">
                            Adds approximately 20 mins to your service
                        </Typography>
                    </Grid>
                    <Grid style={{ display: 'flex', justifyContent: 'center', marginTop: 34 }}>
                        <InfoRoundedIcon />
                        <Typography style={{ margin: '3px 0 0 5px', fontSize: '14px' }}>
                            Learn about our extension policy
                            {' '}
                                    <Link to="/help-centre/what-if-i-have-extensions  " target="_blank">here</Link>
                            .
                        </Typography>
                    </Grid>
                </>
)
            }
                <Button
                    onClick={() => clickExtensions(false)}
                    className={`${classes.slotButton} ${((extensions && extensions[selectedUser] === false) || extensions === false) ? classes.selected : ''}`}
                    variant="outlined"
                    color="primary"
                >
                    No
                </Button>
                <Button
                    onClick={() => clickExtensions(true)}
                    className={`${classes.slotButton} ${((extensions && extensions[selectedUser] === true) || extensions === true) ? classes.selected : ''}`}
                    variant="outlined"
                    color="primary"
                >
                    Yes
                    <br />
                    &#163;20
                </Button>
            </Grid>
        </>
);
};

const mapDispatchToProps = (dispatch) => ({
    setExtensionAction: bindActionCreators(setExtensions, dispatch),
});

const mapStateToProps = (state) => ({
    extensions: getExtensions(state),
    guests: getNumberOfGuests(state),
    isGuestWithDifferentServices: isGuestWithDifferentServices(state),
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ExtensionsPage));
