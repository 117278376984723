export const subscribe = (email) => ({
  url: '/marketingContact/updateContactList',
  data: {
    email,
  },
});

export const getMarketingConsent = (email) => ({
  url: '/marketingContact/getConsent',
  params: {
    email: email,
  },
});

export const updateContactList = ({
  sourceType = 'DrybarshopsFooter',
  firstName,
  lastName,
  email,
  homePhone = '',
  phoneNumber = '',
  bookerId = 0,
  ...extra
}) => {
  const data = {
    sourceType: sourceType,
    firstName: firstName,
    lastName: lastName,
    email: email,
    homePhone: homePhone,
    phoneNumber: phoneNumber,
    bookerId: bookerId,
    ...extra,
  };

  return {
    url: '/marketingContact/updateContactList',
    data,
  };
};