/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    Grid,
    Button,
    Typography,
    Backdrop,
    CircularProgress,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import { MOBILE_BREAKPOINT } from '../../../../../Helpers/breakpoints';
import { Link, withRouter } from 'react-router-dom';
import { createUserOkta } from 'api/auth-api';
import restClient from 'api/restClient';
import { updateContactList } from 'api/emarsys-api';
import { getPageLabelFromUrl } from 'utils/helper';
import TagManager from 'app/Components/GoogleTagManager';
import config from 'app.config';
import SectionTitle from '../../../SectionTitle';
import TextInputField from '../TextInputField';
import getSignupFormFields from './signupFormFields';
import RegistrationSuccessDialog from './Partials/RegistrationSuccessModal';
import { MetaTagWrapper } from '../../../../../components';
import { 
    EMAIL_REGEX, 
    PHONE_REGEX, 
    NAME_REGEX, 
    PASSWORD_SPECIAL_OR_NUM_REGEX, 
    PASSWORD_UPPER_CASE_REGEX, 
    PASSWORD_LOWER_CASE_REGEX 
} from 'utils/validation/regex';
import { googleAnalyticsEvents } from 'state/utils/googleAnalyticsEvents';

const styles = (theme) => ({
    requestNoteDetails: {
        float: 'right',
        background: theme.palette.common.white,
        boxShadow: '2px 2px 17px rgba(235, 235, 235, 0.5)',
        margin: 'auto',
        maxWidth: '800px',
        padding: '30px 22px',
        textAlign: 'center',
        width: '100%',
    },
    authOperation: {
        fontSize: '18px',
        lineHeight: '45px',
        color: theme.palette.common.white,
        textTransform: 'capitalize',
        background: '#2A2E2F',
        height: '63px',
        maxWidth: '378px',
        width: '100%',
        marginTop: '40px',
        borderRadius: '0px',
        '&:hover': {
            backgroundColor: 'rgb(58, 60, 62);',
        },
    },
    rippleVisible: {
        backgroundColor: '#767676',
    },
    notification: {
        marginTop: '10px !important',
        color: theme.palette.common.grey,
        '& a': {
            textDecoration: 'underline',
            color: '#006bff',
        },
    },
    requestServiceContainer: {
        background: theme.palette.common.lightGrey[3],
        marginBottom: '86px',
    },
    enterYourEmail: {
        width: '100%',
        padding: '7px 0px 0px 0px',

    },
    enterEmailPassword: {
        padding: '29px 0px 0px 0px',
    },
    enterEmailPasswordCopy: {
        float: 'left',
        color: theme.palette.common.lightGrey[4],
    },
    subscribeCheckbox: {
        color: '#42413D',
    },
    alreadyHaveAccount: {
        color: '#767676',
        marginTop: '29px !important',
    },
    facebookLogin: {
        '& .kep-login-facebook': {
            backgroundColor: theme.palette.common.white,
        },
    },
    backToLogin: {
        color: theme.palette.common.grey,
        textDecoration: 'underline',
        cursor: 'pointer',
        marginLeft: '11px',
    },
    SignUpFormInfo: {
        float: 'right',
        margin: '-54px 0px 0px 0px',
    },
    registerMessage: {
        color: 'red',
        margin: '27px 2px 2px 2px !important',
    },
    backdrop: {
        zIndex: 11,
        color: '#fff',
    },
    modalTitle: {
        textAlign: 'center',
        fontSize: '31px',
        fontFamily: 'DINCondensed-Regular',
        lineHeight: '37px',
        textTransform: 'uppercase',
        fontWeight: '600',
    },
    registerEmail: {
        textAlign: 'center',
        fontSize: '32px',
        color: '#42413D',
        overflowWrap: 'anywhere',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            fontSize: '14px',
        },
    },
    recoverOperation: {
        fontSize: '18px',
        textTransform: 'capitalize',
        height: '63px',
        width: '100%',
        borderRadius: '0px',
        '&:hover': {
            backgroundColor: 'rgb(58, 60, 62);',
            color: '#FFFFFF',
        },
        background: '#F9F9F9',
        padding: '20px 20px 20px 20px',
        border: '1px solid #54575A',
        color: '#54575A',
        boxSixing: 'border-box'
    },
    dialogActions: {
        textAlign:'center', 
        padding: 10, 
        gap: 10
    },
    successStyle: {
        color: 'green',
        fontSize: '0.75rem',
        textAlign: 'left',
        fontWeight: 400,
        lineHeight: 1.66
    },
    errorStyle: {
        color: '#EE0000',
        fontSize: '0.75rem',
        textAlign: 'left',
        fontWeight: 400,
        lineHeight: 1.66
    },
    tipStyle: {
        color: 'Gray',
        fontSize: '0.75rem',
        textAlign: 'left',
        fontWeight: 400,
        lineHeight: 1.66,
    },
    generalErrorMsg:{
        color: '#EE0000',
        fontSize: '0.75rem',
        fontWeight: 400,
        lineHeight: 1.66,
        textAlign: 'center',
        padding: 20
    },
    untestedPasswordCondition: {
        color: 'black'
    },
    passwordIcon: {
        verticalAlign: 'middle', 
        fontSize: '14px'
    }
});

const Registration = ({ classes, location }) => {
    const [signupFormState, setSignUpFormState] = useState({});
    const [signupFormMetadata, setSignupFormMetadata] = useState([]);
    const [hasFormLoaded, setHasFormLoaded] = useState(false)
    const [touched, setTouched] = useState({});
    const [validateField, setValidateField] = useState({});
    const [errors, setErrors] = useState({});
    const [successes, setSuccesses] = useState({});
    const [generalErrorMsg, setGeneralErrorMsg] = useState("");
    const [showLoading, setLoading] = useState(false);
    const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
    const [enteredEmail, setEnteredEmail] = useState('');
    const [isSubscribe, setIsSubscribe] = useState(false);
    const [displayPlaceholder, setDisplayPlaceholder] = useState({});
    const [displayTips, setDisplayTips] = useState(false);
    const [tips, setTips] = useState({uppercase: "An upper case character", lowercase: "A lowercase character", special: "A number or special character", count: "At least 10 characters"});

    useEffect(async () => {
        // get the form fields schema
        const signupFormMetadata = await getSignupFormFields();
        setSignupFormMetadata(signupFormMetadata);
        // set the initial form state + touched values + error
        setGeneralErrorMsg("");
        setSignUpFormState(initFormState(signupFormMetadata));
        setTouched(initTouchedOrValidate(signupFormMetadata));
        setValidateField(initTouchedOrValidate(signupFormMetadata));
        setSuccesses(initSuccesses(signupFormMetadata));
        setErrors(initErrors(signupFormMetadata));
        setDisplayPlaceholder(initTouchedOrValidate(signupFormMetadata));
        // indicate the form has finished its initial load
        setHasFormLoaded(true);
    }, []);

    const reset = (signupFormMetadata) => {
        setSignUpFormState(initFormState(signupFormMetadata));
        setTouched(initTouchedOrValidate(signupFormMetadata));
        setValidateField(initTouchedOrValidate(signupFormMetadata));
        setSuccesses(initSuccesses(signupFormMetadata));
        setErrors(initErrors(signupFormMetadata));
        setIsSubscribe(false)
        setEnteredEmail('')
        setGeneralErrorMsg("");
    }

    const initFormState = (signupFormMetadata) => {
        let state = {};
        for(const field of signupFormMetadata){
            if(field.select?.options?.length > 0){
                const updatedFields = { ...state };
                updatedFields[field.select.name] = field.select.default || field.select.options[0];
                state = updatedFields;
            }
        };
        return state;
    }

    const initTouchedOrValidate = (signupFormMetadata) => {
        const touched = {};
        for(const field of signupFormMetadata){
            touched[field.name] = false;
        };
        return touched;
    }

    const initSuccesses = (signupFormMetadata) => {
        const successes = {};
        for(const field of signupFormMetadata){
            successes[field.name] = [];
        };
        return successes;
    }

    const initErrors = (signupFormMetadata) => {
        const errors = {};
        for(const field of signupFormMetadata){
            errors[field.name] = [];
        };
        return errors;
    }

    const hasFormErrors = (errors) => {
        let hasErrors = false;
        for(const field in errors){
            if(errors[field].length > 0){
                hasErrors = true;
            }
        }
        return hasErrors;
    }

    const getValidateAllFields = (signupFormMetadata) => {
        const validateField = {};
        for(const field of signupFormMetadata){
            validateField[field.name] = true;
        };
        return validateField;
    }

    const showPlaceholder = (field) => {
        const currentPlaceholders = {...initTouchedOrValidate(signupFormMetadata)}
        currentPlaceholders[field.name] = true;
        setDisplayPlaceholder(currentPlaceholders);
    };

    const hidePlaceholder = (e) =>{
        setDisplayPlaceholder(initTouchedOrValidate(signupFormMetadata));
    }

    const hasFieldErrors = (fieldName, errors) => errors[fieldName] && errors[fieldName].length > 0;

    const hasFieldSuccesses = (fieldName, successes) => successes[fieldName] && successes[fieldName].length > 0;

    const handleValidation = (validateField, signupFormState) => {

    // reset the form errors and successes
    const newErrors = { ...initErrors(signupFormMetadata)};
    const newSuccesses = { ...initSuccesses(signupFormMetadata)};

        signupFormMetadata.forEach((field) => {
            if (validateField[field.name] && field.required) {
                if (!(signupFormState[field.name] && signupFormState[field.name]?.length)){
                    newErrors[field.name].push(`${field.label} is required`);
                }
            }
        });

        // validate the first name
        if(validateField['firstName'] && signupFormState.firstName){
            if(signupFormState.firstName.length < 2){
                newErrors.firstName.push('First name is too short');
            } else if(signupFormState.firstName.length > 64) {
                newErrors.firstName.push('First name is too long)');
            } else if (!signupFormState.firstName.match(NAME_REGEX)) {
                newErrors.firstName.push('First name contains invalid characters')
            }
        }

        // validate the last name
        if(validateField['lastName'] && signupFormState.lastName){
            if(signupFormState.lastName.length < 2){
                newErrors.lastName.push('Last name is too short');
            } else if(signupFormState.lastName.length > 50) {
                newErrors.lastName.push('Last name is too long');
            } else if (!signupFormState.lastName.match(NAME_REGEX)) {
                newErrors.lastName.push('Last name contains invalid characters');
            }
        }

        // validate the primary phone
        if (validateField['primaryPhone'] && signupFormState.primaryPhone && !signupFormState.primaryPhone.match(PHONE_REGEX)) {
            newErrors.primaryPhone.push('Invalid phone number');
        }

        // validate the email
        if (validateField['email'] && (signupFormState.email?.length < 6 || (signupFormState.email && !signupFormState.email.match(EMAIL_REGEX)))) {
            newErrors.email.push('Invalid email format');
        }

        // validate password and store errors and successful validation messages
        if(validateField['password'] && signupFormState.password){

            if(signupFormState.password?.length >= 10){
                newSuccesses.password.push(tips["count"])
            } else {
                newErrors.password.push(tips["count"])
            }
            if(PASSWORD_SPECIAL_OR_NUM_REGEX.test(signupFormState.password)){
                newSuccesses.password.push(tips["special"])
            } else {
                newErrors.password.push(tips["special"])
            }

            if(PASSWORD_LOWER_CASE_REGEX.test(signupFormState.password)){
                newSuccesses.password.push(tips["lowercase"])
            } else {
                newErrors.password.push(tips["lowercase"])
            }

            if(PASSWORD_UPPER_CASE_REGEX.test(signupFormState.password)){
                newSuccesses.password.push(tips["uppercase"])
            } else {
                newErrors.password.push(tips["uppercase"])
            }
        }
        
        // validate the confirm password if it is the same as the password
        if (validateField['confirmPassword'] && signupFormState.confirmPassword && signupFormState.password !== signupFormState.confirmPassword) {
            newErrors.confirmPassword.push('Password did not match');
        }

        // return both errors and success messages
        return {newErrors, newSuccesses};
    };

    const postSignup = async (e) => {
        e.preventDefault();
        setDisplayTips(true);
        const {
            firstName, lastName, email, phoneCountryCode, primaryPhone, password, consent
        } = signupFormState;

        const newValidateField = getValidateAllFields(signupFormMetadata);
        setValidateField(newValidateField);

        // validate the current form state
        const newValidationState = handleValidation(newValidateField, signupFormState);
 
        // if its valid then submit the signup and then register for subscription
        if (!hasFormErrors(newValidationState.newErrors)) {
            mParticle.logEvent('Sign Up Attempted', mParticle.EventType.Other, {
                'Source Page': getPageLabelFromUrl(location.pathname),
            });
            setLoading(true);
            setGeneralErrorMsg("");

            restClient.post(createUserOkta({ firstName, lastName, email, phoneNumber: primaryPhone, phoneCountryCode, password }))
                .then(async (res) => {
                    if (res.status === 200) {
                        if (isSubscribe) {
                            // WARNING: this should be done in the api to avoid inconsistencies
                            // =============================================================================
                            await restClient.post(updateContactList({
                                sourceType: 'DrybarshopsUserReg',
                                firstName,
                                lastName,
                                email,
                                primaryPhone,
                                consent: isSubscribe
                            }));
                            gtag(googleAnalyticsEvents.EVENT, googleAnalyticsEvents.NEWSLETTER)
                            // =============================================================================
                        }

                        gtag(googleAnalyticsEvents.EVENT, googleAnalyticsEvents.SIGN_UP, {
                            method: 'web'
                        });
                        
                        const {
                            gtm: {
                                formCompleteEvent,
                                subscription: {
                                    type,
                                    option,
                                },
                            },
                        } = config;
                        const tagManagerArgs = {
                            dataLayer: {
                                event: formCompleteEvent,
                                form_type: 'account_signup',
                                newsletter_opt_in: isSubscribe, // remove if not applicable
                                // subscription_option: 'subscription_optionif opted-in.' // remove if n/a
                            },
                        };
                        TagManager.dataLayer(tagManagerArgs);

                        reset(signupFormMetadata);
                        setLoading(false);
                        setIsSuccessDialogOpen(true);
                        setEnteredEmail(email);
                        
                    } else {
                        setGeneralErrorMsg("An unknown error has occurred")
                    }
                }).catch((errorResponse) => {
                    
                    const  lowerCaseFirstLetter = (str) => str.charAt(0).toLowerCase() + str.slice(1);
                    
                    if(errorResponse.response.status === 400){
                        const data = errorResponse.response.data;
                        // Validation error condition
                        if(data.errors){
                            const newResponseErrors = { ...newValidationState.newErrors };
                            // handle validation error response
                            for(const key in data.errors){
                                const keyParts = key.split('.');
                                const fieldName = lowerCaseFirstLetter(keyParts[1]);
                                newResponseErrors[fieldName] = data.errors[key].slice(0, 1);
                            }
                            setErrors(newResponseErrors);
                        }
                        // Generic error condition
                        else {
                            setGeneralErrorMsg("Something went wrong, please try to login or recover your password?")
                        }
                    }
                    // Generic error condition
                    else {
                        setGeneralErrorMsg("Something went wrong, please try to login or recover your password?")
                    }
                    setLoading(false);
                    setEnteredEmail(email);
                });
        } else {
            setErrors(newValidationState.newErrors);
        }
    };

    const onChange = (value, fieldName) => {
        if(hasFormLoaded){
            // update the touched field value
            const newTouched = {...touched, [fieldName]:true};
            // update the field value
            const newValues = { ...signupFormState, [fieldName]: value};
            if(fieldName==="password"){
                const newValidateField = {...validateField, [fieldName]: true};
                var newValidationState = handleValidation(newValidateField, newValues);
                setValidateField(newValidateField);
            }
            else{
            // validate the new values
            var newValidationState = handleValidation(validateField, newValues);
            }
            // update the state
            setTouched(newTouched)
            setErrors(newValidationState.newErrors);
            setSuccesses(newValidationState.newSuccesses);
            setSignUpFormState(newValues);
        }
    };

    const onBlur = (fieldName) => {
        if(hasFormLoaded) {
            if (touched[fieldName]) {
                const newValidateField = {...validateField, [fieldName]:true};
                const newValidationState = handleValidation(newValidateField, signupFormState);

                setErrors(newValidationState.newErrors);
                setSuccesses(newValidationState.newSuccesses);
                setValidateField(newValidateField);
            }
        }
    }

    const onSubscription = (e) => {
        setIsSubscribe(e.target.checked);
    };

    const onCloseModal = () => {
        setIsSuccessDialogOpen(false);
        reset(signupFormMetadata);
        setSignUpFormState(initFormState(signupFormMetadata));
    }

    const renderGeneralErrorMsg = (errorMsg) => 
        (<div className={classes.generalErrorMsg}>
            <span style={{verticalAlign: 'middle'}}>{errorMsg}</span>
        </div>)

    const renderValidationErrorMsg = (errorMsg, colour) => 
        (<div className={colour}>
            <CloseIcon className={classes.passwordIcon}/>
            <span style={{verticalAlign: 'middle'}}>{errorMsg}</span>
        </div>)

    const renderValidationSuccessMsg = (successMsg) => 
        (<div className={classes.successStyle}>
            <DoneIcon className={classes.passwordIcon}/>
            <span style={{verticalAlign: 'middle'}}>{successMsg}</span>
        </div>)

    const renderPasswordTip = (tip) =>
        (<div className={classes.tipStyle}>
            <span style={{verticalAlign: 'middle'}}>{tip}</span>
        </div>)
    
    return (
        <>
            <Grid container className={classes.requestServiceContainer}>
                <MetaTagWrapper metadata={{ pageTitle: 'Drybar Shops | Sign Up' }} />
                <SectionTitle title="SIGN UP" />
                <Grid className={classes.requestNoteDetails}>
                    <Grid className={classes.requestContainer}>
                        <form
                            onSubmit={postSignup}
                            className={classes.subscribeEmailForm}
                            data-form_type="account_signup"
                        >
                            {signupFormMetadata.map((field, i) => (
                                <Grid className={i !== 0 ? classes.enterEmailPassword : ''} 
                                    key={`grid-text-input-field-${field.name}`}>
                                    <TextInputField
                                        key={`text-input-field-${field.name}`}
                                        value={signupFormState?.[field?.name] || ''}
                                        onBlur={(e) => {onBlur(field.name); hidePlaceholder;}}
                                        placeholder={displayPlaceholder[field.name]? field.placeholder : ""}
                                        onChange={(e) => {onChange(e.target.value, field.name);}}
                                        onFocus={(e) => {showPlaceholder(field); field.name==="password"? setDisplayTips(true) : null}}
                                        className={classes.enterYourEmail}
                                        label={field.label}
                                        error={hasFieldErrors(field.name, errors)}
                                        errorMessage={field.name!=="password"? hasFieldErrors(field.name, errors) && errors[field.name].map(msg => renderValidationErrorMsg(msg)) : null}
                                        type={field.type}
                                        onChangeSelect={field.select ? (e) => onChange(e.target.value, field.select.name) : null}
                                        select={field.select}                                                                
                                    />
                                                        
                                    {field.name==="password"?
                                        <>{displayTips? 
                                                <>
                                                    {hasFieldErrors("password", errors)?
                                                        <div className={classes.errorStyle}>
                                                            <span style={{verticalAlign: 'middle'}}><p style={{marginBottom: '3px'}}> Please enter a password containing:</p></span>
                                                        </div> 
                                                        : 
                                                        null 
                                                    }
                                                    { successes["password"].includes(tips["count"])? renderValidationSuccessMsg(tips["count"]) : validateField["password"]? renderValidationErrorMsg(tips["count"], classes.errorStyle) : renderPasswordTip(tips["count"])}
                                                    { successes["password"].includes(tips["uppercase"])? renderValidationSuccessMsg(tips["uppercase"]) : validateField["password"]? renderValidationErrorMsg(tips["uppercase"], classes.errorStyle) : renderPasswordTip(tips["uppercase"])}
                                                    { successes["password"].includes(tips["lowercase"])? renderValidationSuccessMsg(tips["lowercase"]) : validateField["password"]? renderValidationErrorMsg(tips["lowercase"], classes.errorStyle) : renderPasswordTip(tips["lowercase"])}
                                                    { successes["password"].includes(tips["special"])? renderValidationSuccessMsg(tips["special"]) : validateField["password"]? renderValidationErrorMsg(tips["special"], classes.errorStyle) : renderPasswordTip(tips["special"])}
                                                </>
                                                :
                                                null
                                            }
                                        </>
                                        :
                                        null
                                    }             
                                </Grid>
                            ))}
                            <Grid className={classes.enterEmailPassword}>
                                <FormControlLabel
                                    control={(
                                        <Checkbox
                                            checked={isSubscribe}
                                            onChange={onSubscription}
                                        />
                                    )}
                                    className={classes.subscribeCheckbox}
                                    label="Get on the list and be the first to hear about all things Drybar!"
                                />
                            </Grid>
                            <Typography className={classes.notification}>
                                By signing up you agree to the 
                                {' '}
                                <a href="/help-centre/terms-of-service">terms of service</a> and the Drybar <a href="/help-centre/privacy-policy">privacy policy</a>.
                                Please note we will send you reminders about your appointments.  You can opt out at any time by clicking the unsubscribe link at the bottom of the email.
                            </Typography>
                            <div className={classes.generalErrorMsg}>
                                {generalErrorMsg && renderGeneralErrorMsg(generalErrorMsg)}
                            </div>
                            <Button type="submit" className={classes.authOperation} variant="outlined">
                                Sign Up
                            </Button>
                        </form>
                        <Typography className={classes.alreadyHaveAccount}>
                            Already have an account?
                            <Link to="/auth/login"><span className={classes.backToLogin}>Log In</span></Link>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            {showLoading ? (
                <Backdrop className={classes.backdrop} open>
                    <CircularProgress color="inherit" />
                </Backdrop>
            ) : null}
            {isSuccessDialogOpen
                && <RegistrationSuccessDialog openModal={isSuccessDialogOpen} onClose={onCloseModal} classes={classes} createdEmail={enteredEmail} />}
        </>
    );
};

export default withRouter(withStyles(styles)(Registration));
