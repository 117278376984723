import { getPreviewMode } from '../../state/utils/contentful';

/**
 * Load screenEventsCollection and its children's id
 * @returns {DocumentNode}
 */
const queryHelpCenterCollection = () => (
    `{
      screenHelpCenterCollection(${getPreviewMode()}, where: {slug: "help-center"}) {
        items {
          title
          subtitle
          sys {
            id
          }
          marketingComponentsCollection {
            items {
              ... on MarketingSection {
                sys {
                  id
                }
              }
            }
          }
          metadata {
          	sys {
              id
            }
          }
        }
      }
    }
    `
);

const queryHelpCenterCollectionFromContentful = () => (
  `{
    drybarPageCollection(preview: true, where: { slug: "help-centre" }) {
      items {
        sys {
          id
        }
        title
        subtitle
        tcsAndPoliciesCopy
        seoTitle
        seoDescription
        canonicalUrl
        addANoindexTag
        slug
        formatofPageCollection(preview: true) {
          items {
            __typename
            ... on HeroBanner {
              sys {
                id
              }
            }
            ... on FaqAccordion {
              sys {
                id
              }
            }
          }
        }
      }
    }
  }`
)

/**
 * Load events
 * @param screenHelpCenterId
 * @returns {DocumentNode}
 */
const queryFaqArticles = (id) => (
    `{
      screenHelpCenter(${getPreviewMode()}, id: "${id}") {
        articleGroupsCollection {
          items {
            sys {
              id
            }
            title
            subtitle
            articlesCollection {
              items {
                title
                slug
                sys {
                  id
                }
                description {
                  json
                }
              }
            }
          }
        }
      }
    }
    `
);

const queryFaqArticlesFromContentful = (id) => (
  `{
    drybarPage(${getPreviewMode()}, id: "${id}") {
      formatofPageCollection {
        items {
          __typename
          ... on FaqAccordion {
            sys {
              id
            }
            sectionHeading
            slugPrefix
            faQsCollection {
              items {
                question
                answer {
                  json
                }
                section
                slugPrefix
                pageLink
                url
                sys {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
  `
);

const querySubCatedoryArticle = (subCatedoryArticleId) => (
  `{
    article(${getPreviewMode()}, id: "${subCatedoryArticleId}"){
      title
      subtitle
      slug
      description{
        json
      }
    }
  }
  `
);

const queryMarketingSection = (marketingSectionId) => (
    `{
      marketingSection(${getPreviewMode()}, id:"${marketingSectionId}") {
        marketingComponentsCollection {
          items {
            ... on MarketingCard {
              image {
                alternateTitle
                desktopMedia {
                  url
                }
                mobileMedia {
                  url
                }
              }
            }
          }
        }
      }
    }
    `
);

const queryMarketingSectionFromContentful = (marketingSectionId) => (
  `{
    heroBanner(id: "${marketingSectionId}", ${getPreviewMode()}) {
      targetPageUrl
      slugPrefix
      mediaCollection(${getPreviewMode()}) {
      items {
              internalName
              desktopImageUrl
              mobileImageUrl
              videoUrl
            }
      }
    }
  }
  `
);

const queryTCPageCollectionFromContentful = (slug) => (
  `{
    drybarPageCollection(preview: true, where: { slug: "/help-centre/${slug}" }) {
      items {
        sys {
          id
        }
        title
        subtitle
        tcsAndPoliciesCopy
        seoTitle
        seoDescription
        canonicalUrl
        addANoindexTag
        slug
        formatofPageCollection(preview: true) {
          items {
            __typename
            ... on HeroBanner {
              sys {
                id
              }
            }
            ... on FaqAccordion {
              sys {
                id
              }
            }
          }
        }
      }
    }
  }`
)

export {
    queryHelpCenterCollection,
    queryFaqArticles,
    queryMarketingSection,
    querySubCatedoryArticle,
    queryHelpCenterCollectionFromContentful,
    queryMarketingSectionFromContentful,
    queryFaqArticlesFromContentful,
    queryTCPageCollectionFromContentful
};
