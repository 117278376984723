import React, { useState, useEffect, useRef } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link, useHistory, withRouter } from 'react-router-dom';
import { CircularProgress, Backdrop } from '@material-ui/core';
import Media from 'react-media';
import backArrow2Image from '../../assets/images/back_arrow2.png';
import { loadFaqContentful } from '../../state/ducks/Faq/Faq-Actions';
import {
    getArticles,
    isFaqLoaded,
    getFaqSectionHeroImage,
} from '../../state/ducks/Faq/Faq-Selectors';
import { doQuery, getMetaData } from '../../state/utils/contentful';
import {
    querySubCatedoryArticle,
    queryTCPageCollectionFromContentful,
    queryMarketingSectionFromContentful
} from '../../gql/faq/contentfulFaqQueries';
import {
    MetaTagWrapper,
    DocumentToComponent,
    CustomImg,
} from '../../components';

const relatedArticles = [
    { title: 'Membership Policy', slug: 'membership-policy' },
    { title: 'Privacy Policy', slug: 'privacy-policy' },
    { title: 'Cookie Policy', slug: 'cookie-policy' },
    { title: 'Accessibility Policy', slug: 'accessibility-policy' },
    { title: 'Terms of Service', slug: 'terms-of-service' },
    { title: 'Cancellation & no show', slug: 'cancellation-and-no-show' },
];

const FaqDetailsSectionView = (props) => {
    const history = useHistory();
    const [article, setArticle] = useState(null);
    const [metadata, setMetadata] = useState({});
    const [heroBanner, setHeroBanner] = useState(props.heroImage);
    const slug = props.match.params.id;
    const selfRef = useRef();

    useEffect(() => {
        doQuery(queryTCPageCollectionFromContentful(slug), true).then((res) => {
            const articleData = res.drybarPageCollection?.items?.[0];

            getBanner(articleData?.formatofPageCollection?.items?.[0]?.sys?.id);
            setMetadata({
                pageDescription: articleData?.seoDescription,
                pageKeywords: [],
                pageTitle: articleData?.seoTitle,
                searchKeywords: []
            })

            const copy = parseArticleFormContentful(
                articleData?.tcsAndPoliciesCopy
            );

            const article = {
                title: articleData?.title,
                subtitle: articleData?.subtitle,
                data: `<p>${copy}</p>`,
            };

            setArticle(article);
        });
    }, [slug]);

    useEffect(() => {
        if (!props.isLoaded) props.loadDataFromContentful({});
    }, []);

    const getBanner = async (id) => {
        const data = await doQuery(queryMarketingSectionFromContentful(id), true);

        setHeroBanner({
            alternateTitle: '',
            desktop: data?.heroBanner?.mediaCollection?.items?.[0]?.desktopImageUrl,
            mobile: data?.heroBanner?.mediaCollection?.items?.[0]?.mobileImageUrl,
        })
    }

    const onCick = () => {
        history.push('/help-centre');
    };

    if (!article) {
        return (
            <Backdrop
                open
                style={{
                    zIndex: 11,
                    color: '#fff',
                }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    }

    return (
        <div
            className="tab-pane fade show active faq-page"
            id="nav-home"
            role="tabpanel"
            aria-labelledby="nav-home-tab"
            ref={selfRef}
        >
            {metadata && <MetaTagWrapper metadata={metadata} />}
            <div className="middle-section">
                <div className="mx-auto faq_bg" style={{ maxWidth: 1295 }}>
                    <Media query={{ maxWidth: 767 }}>
                        {(matches) =>
                            matches && heroBanner.mobile ? (
                                <CustomImg
                                    className="img-fluid banner_help"
                                    src={heroBanner.mobile}
                                    alt={heroBanner.alternateTitle || ''}
                                    style={{ maxWidth: '1295px' }}
                                />
                            ) : (
                                <CustomImg
                                    className="img-fluid banner_help"
                                    src={heroBanner.desktop}
                                    alt={heroBanner.alternateTitle || ''}
                                    style={{ maxWidth: '1295px' }}
                                />
                            )
                        }
                    </Media>
                </div>
                <div className="clearfix" />
                <div
                    className="inner_detisls d-flex align-items-center justify-content-center"
                    style={{ background: '#f3f3f3' }}
                >
                    <div
                        className="inner_bg_views"
                        style={{ maxWidth: '1295px' }}
                    >
                        <div className="container">
                            <div className="main_detils-row">
                                <div className="inner_account_history">
                                    <div className="row">
                                        <div className="col-md-5 col-lg-4 order-2 order-md-1">
                                            <div className="order-hist_heading article_title">
                                                <h6>
                                                    <a
                                                        onClick={onCick}
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        <img
                                                            src={
                                                                backArrow2Image
                                                            }
                                                            alt="Back Arrow"
                                                        />{' '}
                                                        &nbsp; Back to Help
                                                        Center
                                                    </a>
                                                </h6>
                                            </div>
                                            <div className="booking_faq mt-0">
                                                <h4>Related Articles</h4>
                                                {relatedArticles.map(
                                                    (item, index) =>
                                                        item.title && (
                                                            <Link
                                                                key={item.id}
                                                                to={{
                                                                    pathname: `/help-centre/${item.slug}`,
                                                                }}
                                                                style={{
                                                                    color:
                                                                        '#42413D',
                                                                    display:
                                                                        'flex',
                                                                }}
                                                            >
                                                                <p
                                                                    style={
                                                                        slug ===
                                                                        item.slug
                                                                            ? {
                                                                                  fontWeight:
                                                                                      'bold',
                                                                                  color:
                                                                                      'black',
                                                                              }
                                                                            : null
                                                                    }
                                                                >
                                                                    {item.title}
                                                                </p>
                                                            </Link>
                                                        )
                                                )}
                                            </div>
                                            {/*<div className="booking_faq booking_numbers">*/}
                                            {/*    <h4>Need immediate help? Call Us.</h4>*/}
                                            {/*    <div className="drybar_services1">*/}
                                            {/*        <p>Drybar Services</p>*/}
                                            {/*        <h5>*/}
                                            {/*            (877) 379-2279*/}
                                            {/*            <span><img src={serviceContactIcon} alt="Contact Icon" /></span>*/}
                                            {/*        </h5>*/}
                                            {/*    </div>*/}
                                            {/*    <div className="drybar_services1">*/}
                                            {/*        <p>Drybar Products</p>*/}
                                            {/*        <h5>*/}
                                            {/*            (800) 646-4479*/}
                                            {/*            <span><img src={serviceContactIcon} alt="Contact Icon" /></span>*/}
                                            {/*        </h5>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                        </div>
                                        <div className="col-md-7 col-lg-8 order-1 order-md-2 pt-0">
                                            <div className="order-hist_heading article_title pb-0">
                                                <h1
                                                    className={
                                                        'faq_heading pt-0'
                                                    }
                                                >
                                                    {article?.title}
                                                </h1>
                                            </div>
                                            <div className="rgiht_actve-actitions mt-0">
                                                <div className="order_hefing_details-bg mt-0">
                                                    <div
                                                        className="paragraph_faq"
                                                        dangerouslySetInnerHTML={{
                                                            __html:
                                                                article.data,
                                                        }}
                                                    ></div>
                                                    {/* <div className="paragraph_faq">
                                                        {article.data}
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    articles: getArticles(state),
    isLoaded: isFaqLoaded(state),
    heroImage: getFaqSectionHeroImage(state),
});

const mapDispatchToProps = (dispatch) => ({
    loadDataFromContentful: bindActionCreators(loadFaqContentful, dispatch),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(FaqDetailsSectionView));

const parseArticleFormContentful = (data) => {
    let splitted = data?.split('\n');

    splitted = splitted?.map((s) => {
        if (s?.includes('###')) {
            return `<h3>${s?.replace('###', '')}</h3></br>`;
        } else if (s?.includes('##')) {
            return `<h2>${s?.replace('##', '')}</h2>`;
        } else {
            return `<p>${s}</p>`;
        }
    });

    let resultedText = splitted?.join('') ?? '';

    const linkRegex = /\[([^\]]+)\]\(([^\)]+)\)/g;
    resultedText = resultedText?.replace(linkRegex, (match, p1, p2) => {
        return `<a href="${p2?.replace(']', '')}">${p1}</a>`;
    });

    const boldRegex = /__(.*?)__/g;
    resultedText = resultedText.replace(boldRegex, (match, p1) => {
        return `<strong>${p1}</strong>`;
      });

    return resultedText;
};
