import { getPreviewMode } from '../../state/utils/contentful';

/**
 * Load screenEventsCollection and its children's id
 * @returns {DocumentNode}
 */
const queryEventsCollection = () => (
    `{
      screenEventsCollection(${getPreviewMode()}, where: {slug: "events"}) {
        items {
          metadata{
            sys {
              id
            }
          }
          title
          subtitle
          sys {
            id
          }
          marketingComponentsCollection {
            items {
              ... on MarketingSection {
                sys {
                  id
                }
              }
            }
          }
        }
      }
    }
    `
);

/**
 * Load events
 * @param screenEventId
 * @returns {DocumentNode}
 */
const queryEvents = (screenEventId) => (
    `{
      screenEvents(${getPreviewMode()}, id: "${screenEventId}") {
        eventsCollection {
          items {
            title
            subtitle
            description {
              json
            }
            image {
              alternateTitle
              desktopMedia {
                url
              }
            }
            actionsCollection {
              items {
                linkToUrl
                title
              }
            }
          }
        }
      }
    }
    `
);

const queryMarketingSection = (marketingSectionId) => (
    `{
      marketingSection(${getPreviewMode()}, id:"${marketingSectionId}") {
        marketingComponentsCollection {
          items {
            ... on MarketingCard {
              image {
                alternateTitle
                desktopMedia {
                  url
                }
                mobileMedia {
                  url
                }
              }
            }
          }
        }
      }
    }
    `
);

const queryEventsPage = () => (
  `{
    drybarPageCollection(${getPreviewMode()}, where: { slug: "events" }) {
      items {
        title
        subtitle
        tcsAndPoliciesCopy
        seoTitle
        seoDescription
        canonicalUrl
        addANoindexTag
        slug
        formatofPageCollection {
          items {
            __typename
            ... on HeroBanner {
              sys {
                id
              }
            }
            ... on DrybarPictureGrid {
              sys {
                id
              }
            }
          }
        }
      }
    }
  }
  `
);

const fetchBannerData = (id) => `
{
  heroBanner(id: "${id}", ${getPreviewMode()}) {
    targetPageUrl
    slugPrefix
    mediaCollection {
      items {
              internalName
              desktopImageUrl
              mobileImageUrl
              videoUrl
            }
    }
  }
}
`

const fetchEvents = (id) => `
{
  drybarPictureGrid(id: "${id}", ${getPreviewMode()}) {
    title
    subtitle
    numberOfColumns
    drybarGridCardsCollection {
      items {
        __typename
        ... on DrybarPictureGridCard {
          title
          subtitle
          imageUrl
          buttonctaUrl
          buttonctaTitle
          paragraph
        }
      }
    }
  }
}`

export {
    queryEventsCollection,
    queryEvents,
    queryMarketingSection,
    queryEventsPage,
    fetchBannerData,
    fetchEvents
};
