import { buyoutTopLevelQuery, buyoutSecondLevelQuery } from './contentfulBuyoutQueries';
import { doQuery, parseJSONFormat, getMetaData } from '../../state/utils/contentful';

const parseIDs = (data) => {
    const homeComponent = data?.items?.length > 0 ? data?.items[0] : {};
    const items = homeComponent.marketingComponentsCollection?.items || [];
    const ids = items.filter((item) => !!item).map((item) => ({ id: item.sys?.id || '', internalName: item.internalName ? item.internalName : '' }));

    return ids.filter((id) => id);
};

const getItems = (data) => (data?.marketingSection?.marketingComponentsCollection?.items?.length > 0 ? data.marketingSection.marketingComponentsCollection.items : []);

const getFiltableFields = (str) => {
    const isPrimary = str.indexOf('Primary') > -1;
    return { isPrimary };
};

const getLocations = (data) => {
    if (data.items) return data.items[0].locations;
    return null;
};

export const gqlLoadBuyout = async ({ slug }) => {
    const buyoutSectionData = {
        title: '',
        description: {},
        banners: {},
        headlines: {},
        metadata: {},
        locations: null,
    };

    let data = await doQuery(buyoutTopLevelQuery(slug));
    const metaDataId = data?.buyoutCollection?.items[0]?.metadata?.sys?.id || null;
    if (metaDataId) {
        const metadata = await getMetaData(metaDataId);
        buyoutSectionData.metadata = metadata;
    }

    const buyoutData = data.buyoutCollection;
    if (buyoutData && buyoutData.items.length > 0) {
        buyoutSectionData.title = buyoutData.items[0].title || '';
        buyoutSectionData.description = buyoutData.items[0].description || {};
        buyoutSectionData.locations = getLocations(data.buyoutCollection || {});

        const firstLevelIds = parseIDs(data.buyoutCollection || {});
        const cardData = [];
        if (firstLevelIds.length > 0) {
            for (let i = 0; i < firstLevelIds.length; i++) {
                data = await doQuery(buyoutSecondLevelQuery(firstLevelIds[i].id));
                cardData.push({ items: getItems(data), ...getFiltableFields(firstLevelIds[i].internalName) });
            }
        }

        buyoutSectionData.banners = cardData.filter((el) => el.isPrimary);
        buyoutSectionData.headlines = cardData.filter((el) => !el.isPrimary);
    }

    return buyoutSectionData;
};
