/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ReactPlayer from 'react-player';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Backdrop, CircularProgress } from '@material-ui/core';
import Media from 'react-media';

import { loadTheStylesContentful } from '../../state/ducks/TheStyles/TheStyles-Actions';
import {
    getStyles,
    getTheStylesSectionDescription,
    getTheStylesSectionHeroImage,
    getTheStylesSectionTitle,
    getMetaData,
    getButtonCta,
    getSliderData,
} from '../../state/ducks/TheStyles/TheStyles-Selectors';

import '../../assets/css/style.scss';
import '../../assets/css/style-responsive.scss';
import './theStyles.scss';
import './theStyles-responsive.scss';
import arrowLeftIcon from '../../assets/images/arrow-left-1.svg';
import arrowRightIcon from '../../assets/images/arrow-right-1.svg';
import StylesDialog from './StylesModal';
import { MetaTagWrapper, CustomImg } from '../../components';
import { useHistory } from 'react-router-dom';

const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <>
            <button className="slick-arrow right" onClick={onClick}>
                <img src={arrowLeftIcon} alt="slick arrow right" />
            </button>
        </>
    );
};
const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <>
            <button className="slick-arrow left" onClick={onClick}>
                <img src={arrowRightIcon} alt="slick arrow left" />
            </button>
        </>
    );
};

const TheStyles = ({
    title,
    description,
    heroImage,
    styles,
    metadata,
    loadDataFromContentful,
    buttonCta,
    sliderData,
}) => {
    const history = useHistory();

    const slickSettings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    dots: true,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    dots: true,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    dots: true,
                },
            },
        ],
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
    };

    const [styleDetailData, setStyleDetailData] = useState({});

    useEffect(() => {
        loadDataFromContentful({});
    }, []);

    useEffect(() => {
        const { hash } = window.location;
        const sectionId = hash.slice(1);
        if (document.getElementById(sectionId)) {
            document.getElementById(sectionId).scrollIntoView();
        }
    }, [title]);

    if (!title && !description && !heroImage) {
        return (
            <Backdrop style={{ zIndex: 11, color: '#fff' }} open>
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    }

    const onStyleViewModal = (styleData) => {
        setStyleDetailData(styleData);
        mParticle.logEvent(
            'Styles - View Style Photos',
            mParticle.EventType.Other,
            { 'Source Page': 'The Styles', Style: styleData.style.title }
        );
    };

    const onNavigateToStyle = (style) => {
        mParticle.logEvent('Styles - View Style', mParticle.EventType.Other, {
            'Source Page': 'The Styles',
            Style: style.title,
        });
    };

    const onBook = (style) => {
        mParticle.logEvent(
            'Styles - Book This Style',
            mParticle.EventType.Other,
            { 'Source Page': 'The Styles', Style: style.title }
        );
        history.push('/booking/location');
    };

    const onVideoSart = () => {
        mParticle.logEvent('Vidoes - Play Video', mParticle.EventType.Other, {
            'Source Page': 'The Styles',
            'Video Url': style.featuredVideo,
        });
    };

    return (
        <div
            className="tab-pane fade show active mw-1295 m-auto thestyles-page"
            id="nav-home"
        >
            <MetaTagWrapper metadata={metadata} />
            <div className="banner-section">
                <div className="banner-image">
                    <Media query={{ maxWidth: 767 }}>
                        {(matches) =>
                            matches ? (
                                <CustomImg
                                    className="img-fluid banner_help"
                                    src={heroImage.mobile.url}
                                    alt={heroImage.alternateTitle}
                                    style={{ maxWidth: '767px' }}
                                />
                            ) : (
                                <CustomImg
                                    className="img-fluid banner_help"
                                    src={heroImage.desktop.url}
                                    alt={heroImage.alternateTitle}
                                    style={{ maxWidth: '1295px' }}
                                />
                            )
                        }
                    </Media>
                </div>
            </div>
            <div className="middle-section">
                <div className="book-appointment-section">
                    <div className="row">
                        <h1>{title.toUpperCase()}</h1>
                        <p>{description}</p>
                        <button
                            className={`btn-common btn-yellow btn-lg font-weight-bold responsive-500-w-100`}
                            onClick={() => history.push(buttonCta?.url)}
                        >
                            {buttonCta?.title}
                        </button>
                    </div>

                    <div className="slider-row">
                        <Slider {...slickSettings}>
                            {sliderData?.items?.map((style, index) => (
                                <div
                                    className="appointment-item"
                                    key={`appointment_${index}`}
                                >
                                    <div className="content">
                                        <AnchorLink
                                            href={`#${style.slug}`}
                                            onClick={() =>
                                                onNavigateToStyle(style)
                                            }
                                        >
                                            <CustomImg
                                                src={
                                                    style?.desktopMedia?.url ||
                                                    ''
                                                }
                                                alt={
                                                    style?.alternateTitle || ''
                                                }
                                                useLqip
                                            />
                                        </AnchorLink>
                                        <div className="mask">
                                            <span className="overlay">
                                                {style?.title}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                    {styles.map((style, sIndex) => (
                        <div
                            className="book-styling-section secondary"
                            id={style.slug}
                            key={sIndex}
                        >
                            <div className="row" id={style.slug}>
                                <div className="text-section">
                                    <div className="yellow-bar-title">
                                        <span className="yellow-bar" />
                                        <h3>{style.title}</h3>
                                    </div>

                                    <p>{style.subtitle}</p>
                                    <button
                                        className="btn-common btn-yellow btn-lg font-weight-bold responsive-500-w-100"
                                        onClick={(e) => onBook(style)}
                                    >
                                        Book This Style
                                    </button>
                                </div>
                                <div className="video-section">
                                    <div className="video-wrapper">
                                        {style.featuredVideo &&
                                            style.featuredVideo !== '' && (
                                                <ReactPlayer
                                                    url={style.featuredVideo}
                                                    className="video-player"
                                                    width="756px"
                                                    height="399px"
                                                    onStart={onVideoSart}
                                                />
                                            )}
                                        {style.featuredVideo === '' &&
                                            style.featured360Gif &&
                                            style.featured360Gif !== '' && (
                                                <CustomImg
                                                    src={
                                                        style.featured360Gif
                                                            .desktopMedia?.url
                                                    }
                                                    width="100%"
                                                    alt={
                                                        style.featured360Gif
                                                            .alternateTitle ||
                                                        ''
                                                    }
                                                    useLqip
                                                    //aspectRatio={`${style.featured360Gif.desktopMedia?.width}x${style.featured360Gif.desktopMedia?.height}`}
                                                />
                                            )}
                                    </div>
                                </div>
                            </div>

                            <div className="slider-row">
                                <Slider {...slickSettings}>
                                    {style.gallery.map((item, iIndex) => (
                                        <div
                                            className="style-item"
                                            key={`style-gallery-${sIndex}-${iIndex}`}
                                        >
                                            <div className="content">
                                                <CustomImg
                                                    src={
                                                        item.images?.[0]
                                                            .desktopMedia
                                                            ?.url || ''
                                                    }
                                                    alt={
                                                        item.images?.[0]
                                                            .alternateTitle ||
                                                        ''
                                                    }
                                                    useLqip
                                                    //aspectRatio={`${item.images?.[0].desktopMedia?.width}x${item.images?.[0].desktopMedia?.height}`}
                                                />
                                                <div className="mask">
                                                    <button
                                                        className="btn-white-trans btn-common w-100 responsive-500-w-100"
                                                        onClick={() =>
                                                            onStyleViewModal({
                                                                style,
                                                                lookIndex: iIndex,
                                                            })
                                                        }
                                                    >
                                                        Show This Model
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </div>
                    ))}
                </div>
                <StylesDialog
                    open={styleDetailData.lookIndex >= 0}
                    handleClose={() => setStyleDetailData({})}
                    bookThisStyle={() => onBook(styleDetailData.style)}
                    styleData={styleDetailData.style || {}}
                    lookIndex={styleDetailData.lookIndex}
                />
                <div className="gradient-bottom-decorator" />
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    title: getTheStylesSectionTitle(state),
    description: getTheStylesSectionDescription(state),
    heroImage: getTheStylesSectionHeroImage(state),
    styles: getStyles(state),
    metadata: getMetaData(state),
    buttonCta: getButtonCta(state),
    sliderData: getSliderData(state),
});

const mapDispatchToProps = (dispatch) => ({
    loadDataFromContentful: bindActionCreators(
        loadTheStylesContentful,
        dispatch
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(TheStyles);
