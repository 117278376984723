/* eslint-disable max-len */
export const addRemoveDataFromArrayInStore = (state = [], payload, key) => {
    const oldData = [...state];
    let newData = [];
    if (oldData.some((data) => data[key] === payload[key])) {
        newData = oldData.filter((data) => data[key] !== payload[key]);
    } else {
        newData = [...oldData, payload];
    }

    return newData;
};

export const handleAddonsData = (state, payload) => {
    const selectedAddons = state.addonsData;
    const addOnsWithoutUser = selectedAddons.filter((data) => data.user !== payload.user);
    // for no addons for user
    if (!payload.data) {
        return addOnsWithoutUser;
    }

    const selectedAddonsForUser = selectedAddons.find((data) => data.user === payload.user)?.data || [];
    const isAddonInUserList = selectedAddonsForUser?.some((data) => data.ServiceID === payload.data.ServiceID);

    let newUserAddonData = [];
    if (!isAddonInUserList) {
        newUserAddonData = [...selectedAddonsForUser, payload.data];
    } else {
        newUserAddonData = selectedAddonsForUser?.filter((data) => data.ServiceID !== payload.data.ServiceID);
    }
    if (newUserAddonData?.length) {
        addOnsWithoutUser.push({ user: payload.user, data: newUserAddonData });
    }
    return addOnsWithoutUser;
};

export const handleServicesData = (state, payload) => {
    const selectedServices = state.services.serviceData;
    const servicesWithoutUser = selectedServices.filter((data) => data.user !== payload.user);
    servicesWithoutUser.push(payload);

    return servicesWithoutUser;
};

export const getAddress = ({
 street, city, state, zip,
}) => [street, city, state, zip].filter((s) => !!s).join(' ');

export const stateNames = () => {
    const MapFullNameAbbr = {
 arizona: 'AZ', alabama: 'AL', alaska: 'AK', arkansas: 'AR', california: 'CA', colorado: 'CO', connecticut: 'CT', districtofcolumbia: 'DC', delaware: 'DE', florida: 'FL', georgia: 'GA', hawaii: 'HI', idaho: 'ID', illinois: 'IL', indiana: 'IN', iowa: 'IA', kansas: 'KS', kentucky: 'KY', louisiana: 'LA', maine: 'ME', maryland: 'MD', massachusetts: 'MA', michigan: 'MI', minnesota: 'MN', mississippi: 'MS', missouri: 'MO', montana: 'MT', nebraska: 'NE', nevada: 'NV', newhampshire: 'NH', newjersey: 'NJ', newmexico: 'NM', newyork: 'NY', northcarolina: 'NC', northdakota: 'ND', ohio: 'OH', oklahoma: 'OK', oregon: 'OR', pennsylvania: 'PA', rhodeisland: 'RI', southcarolina: 'SC', southdakota: 'SD', tennessee: 'TN', texas: 'TX', utah: 'UT', vermont: 'VT', virginia: 'VA', washington: 'WA', westvirginia: 'WV', wisconsin: 'WI', wyoming: 'WY', alberta: 'AB', britishcolumbia: 'BC', manitoba: 'MB', newbrunswick: 'NB', newfoundland: 'NF', northwestterritory: 'NT', novascotia: 'NS', nunavut: 'NU', ontario: 'ON', princeedwardisland: 'PE', quebec: 'QC', saskatchewan: 'SK', yukon: 'YT',
};
    return MapFullNameAbbr;
};

export const convertStateToAbbr = (input) => {
  if (input === undefined) return input;

  const stateFullNameAbbr = stateNames();
  const strInput = input.trim();
  // eslint-disable-next-line no-useless-escape
  const strStateToFind = strInput.toLowerCase().replace(/\ /g, '');
  const foundAbbr = stateFullNameAbbr[strStateToFind];
  return foundAbbr;
};

export const getBookingTotal = (state) => {
    const selectedServices = state.booking.services.serviceData;
    const addons = state.booking.addonsData;
    const extensions = state.booking.extensions
    const guests = state.booking?.guests

    let estimatedTotal = selectedServices.reduce((s, cur) => {
        s += cur?.data?.Price?.Amount;
        return s;
    }, 0);

    estimatedTotal = addons.reduce((sum, addon) => {
        sum += addon.data.reduce((s, addOnData) => {
            s += addOnData.PriceInfo.Amount;
            return s;
        }, 0);
        return sum;
    }, estimatedTotal);

    if (extensions && extensions !== true) {
        estimatedTotal += Object.keys(extensions).reduce((s, cur) => s + (extensions[cur] ? 20 : 0), 0);
    } else if (extensions === true) estimatedTotal += (guests + 1) * 20;

    return estimatedTotal;
}

export default {};
