/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { distance } from '../../Helpers';
import { stateNames } from './helpers';
import appconfig from 'app.config';

const CONTENTFUL_SPACE = process.env.REACT_APP_SPACE_ID;
const CONTENTFUL_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || 'master';
const CONTENTFUL_PREVIEW = process.env.REACT_APP_PREVIEW_MODE || false;
const CONTENTFUL_TOKEN = CONTENTFUL_PREVIEW
    ? process.env.REACT_APP_PREVIEW_TOKEN
    : process.env.REACT_APP_ACCESS_TOKEN;
const CONTENTFUL_SCREEN_COLLECTION_PREVIEW =
    process.env.REACT_APP_SCREEN_COLLECTION_PREVIEW_MODE || false;

/**
 * Inject the preview directive into the assembled GraphQL query:
 * https://www.contentful.com/developers/docs/references/graphql/#/introduction/previewing-content
 *
 * The original thought was to perform a string replacement within the `doQuery` method.
 * But this is less performant and riskier.
 */
export const getPreviewMode = () => `preview: ${CONTENTFUL_PREVIEW}`;

export const getScreenCollectionPreviewMode = () =>
    `preview: ${CONTENTFUL_SCREEN_COLLECTION_PREVIEW}`;

export const doQuery = (queryQL, isContentful) =>
    new Promise((resolve) => {
        window
            .fetch(
                isContentful
                    ? appconfig.contentUrl + '/graphql'
                    : appconfig.contentUrl + `/v2/graphql`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${CONTENTFUL_TOKEN}`,
                    },
                    body: JSON.stringify({
                        query: queryQL,
                    }),
                }
            )
            .then((response) => response.json())
            .then(({ data, errors }) => {
                if (errors) {
                    // eslint-disable-next-line no-console
                    console.error(errors);
                }
                resolve(data);
            });
    });

export const getMetaData = async (metaDataId) => {
    const queryQL = `
        query {     
            metadata(${getPreviewMode()}, id: "${metaDataId}") {
                pageTitle
                pageDescription
                pageKeywords
                searchKeywords    
            }
        }`;
    const data = await doQuery(queryQL);
    return data.metadata;
};

export const MAX_DISTANCE = 100;

// eslint-disable-next-line max-len
export const findStoresFromPointWithTitle = async (
    geolocatedPoints,
    storeType,
    searchStr,
    defaultPoint = null
) => {
    const queryQL = `
        query storeCollection {
            storeCollection(${getScreenCollectionPreviewMode()}, skip: 0, limit: 1000, where:{AND: [{type: "${storeType}"}]}) {
                items {
                    title
                    number
                    bookerLocationId
                    type
                    information
                    contact
                    slug
                    settings
                    arrivalInformation
                    block730
                }
            }
        }
    `;

    const data = await doQuery(queryQL);

    const locationData = data?.storeCollection?.items || [];
    if (locationData.length === 0) {
        return {
            error: 'Can not get the stores from contentful',
        };
    }

    let center = [];

    let storesByTitle = [];
    const storesSlugsByTitle = [];

    if (searchStr && searchStr !== '') {
        let str = searchStr.toLowerCase();
        str = str.replace(/street/gi, 'st');
        // full state names to abbr
        const states = stateNames();
        for (const key in states) {
            const stateName = states[key]; // CA
            const re = new RegExp(key, 'g'); // california
            str = str.replace(re, stateName).toLowerCase();
        }

        storesByTitle = locationData
            .filter(
                (location) =>
                    location.settings.visible &&
                    location.contact &&
                    location.title.toLowerCase().includes(str)
            )
            .sort((a, b) => {
                if (
                    !a.title.toLowerCase().includes(searchStr) &&
                    b.title.toLowerCase().includes(searchStr)
                )
                    return 1;
                return 0;
            });
        storesByTitle.forEach((s) => {
            storesSlugsByTitle.push(s.slug);
            center.push({
                lat: s.contact.coordinates[0],
                lng: s.contact.coordinates[1],
            });
        });
    }

    let storesByAddress = [];

    if (geolocatedPoints && geolocatedPoints.length) {
        storesByAddress = locationData.filter((location) => {
            if (!location.settings.visible) return false;
            if (!location.contact || storesSlugsByTitle.includes(location.slug))
                return false;

            const matchedAddress = geolocatedPoints.find((point) => {
                if (
                    point.formatted_address.includes(
                        location.contact.postalCode
                    )
                )
                    return true;

                if (
                    point.address_components.length <= 5 &&
                    point.address_components.length >= 2
                ) {
                    // eslint-disable-next-line max-len
                    const searchedState =
                        point.address_components[
                            point.address_components.length - 2
                        ];
                    if (
                        searchedState.long_name.includes(
                            location.contact.state
                        ) ||
                        searchedState.short_name.includes(
                            location.contact.region
                        ) ||
                        location.contact.region.includes(
                            searchedState.long_name
                        )
                    ) {
                        if (point.address_components.length === 2) {
                            // search with state
                            return true;
                        }
                        if (point.address_components.length >= 4) {
                            // search with city
                            const searchedCity =
                                point.address_components[
                                    point.address_components.length - 4
                                ];

                            if (
                                searchedCity.long_name.includes(
                                    location.contact.city
                                )
                            ) {
                                if (point.address_components.length === 5) {
                                    const searchedStreet =
                                        point.address_components[0];
                                    if (
                                        location.contact.street1.includes(
                                            searchedStreet.long_name
                                        ) ||
                                        // eslint-disable-next-line max-len
                                        location.contact.street1.includes(
                                            searchedStreet.short_name
                                        )
                                    ) {
                                        return true;
                                    }
                                    return false;
                                }
                                return true;
                            }
                            return false;
                        }
                    }
                }
                return false;
            });
            return !!matchedAddress;
        });
        storesByAddress.forEach((location) => {
            storesSlugsByTitle.push(location.slug);
            center.push({
                lat: location.contact.coordinates[0],
                lng: location.contact.coordinates[1],
            });
        });
    }

    center = center.concat(
        (geolocatedPoints || []).map((g) => g.geometry.location)
    );

    if (center.length === 0 && defaultPoint) {
        center = [defaultPoint];
    }

    if (center.length === 0) {
        return {
            error: 'Can not get the stores from contentful',
        };
    }
    const filteredLocationData = locationData.filter(
        (location) =>
            location.settings.visible &&
            !storesSlugsByTitle.includes(location.slug)
    );
    //.map((location) => {
    //    const storeLat = parseFloat(location.contact.coordinates[0]);
    //    const storeLng = parseFloat(location.contact.coordinates[1]);

    //    const distanceFromCenter = center.reduce((d, curPoint) => {
    //        const curLat = parseFloat(curPoint.lat);
    //        const curLng = parseFloat(curPoint.lng);
    //        // eslint-disable-next-line no-shadow
    //        const distanceFromCenter = distance(curLat, curLng, storeLat, storeLng, 'M');
    //        // eslint-disable-next-line no-param-reassign
    //        if (d > distanceFromCenter) d = distanceFromCenter;
    //        return d;
    //    }, MAX_DISTANCE);
    //    return {
    //        ...location,
    //        distance: distanceFromCenter,
    //    };
    //})
    //.filter((location) => location.distance < MAX_DISTANCE)
    //.sort((location1, location2) => (location1.distance < location2.distance ? -1 : 1));

    return {
        data: storesByTitle.concat(
            storesByAddress.concat(filteredLocationData)
        ),
    };
};

export const findStoresFromPointWithTitleContentful = async (
    locationData,
    geolocatedPoints,
    searchStr,
    defaultPoint = null
) => {
    if (locationData.length === 0) {
        return {
            error: 'Can not get the stores from contentful',
        };
    }

    let center = [];

    let storesByTitle = [];
    const storesSlugsByTitle = [];

    if (searchStr && searchStr !== '') {
        let str = searchStr.toLowerCase();
        str = str.replace(/street/gi, 'st');
        // full state names to abbr
        const states = stateNames();
        for (const key in states) {
            const stateName = states[key]; // CA
            const re = new RegExp(key, 'g'); // california
            str = str.replace(re, stateName).toLowerCase();
        }

        storesByTitle = locationData
            .filter(
                (location) =>
                    location.settings.visible &&
                    location.contact &&
                    location.title.toLowerCase().includes(str)
            )
            .sort((a, b) => {
                if (
                    !a.title.toLowerCase().includes(searchStr) &&
                    b.title.toLowerCase().includes(searchStr)
                )
                    return 1;
                return 0;
            });
        storesByTitle.forEach((s) => {
            storesSlugsByTitle.push(s.slug);
            center.push({
                lat: s.contact.coordinates[0],
                lng: s.contact.coordinates[1],
            });
        });
    }

    let storesByAddress = [];

    if (geolocatedPoints && geolocatedPoints.length) {
        storesByAddress = locationData.filter((location) => {
            if (!location.settings.visible) return false;
            if (!location.contact || storesSlugsByTitle.includes(location.slug))
                return false;

            const matchedAddress = geolocatedPoints.find((point) => {
                if (
                    point.formatted_address.includes(
                        location.contact.postalCode
                    )
                )
                    return true;

                if (
                    point.address_components.length <= 5 &&
                    point.address_components.length >= 2
                ) {
                    // eslint-disable-next-line max-len
                    const searchedState =
                        point.address_components[
                            point.address_components.length - 2
                        ];
                    if (
                        searchedState.long_name.includes(
                            location.contact.state
                        ) ||
                        searchedState.short_name.includes(
                            location.contact.region
                        ) ||
                        location.contact.region.includes(
                            searchedState.long_name
                        )
                    ) {
                        if (point.address_components.length === 2) {
                            // search with state
                            return true;
                        }
                        if (point.address_components.length >= 4) {
                            // search with city
                            const searchedCity =
                                point.address_components[
                                    point.address_components.length - 4
                                ];

                            if (
                                searchedCity.long_name.includes(
                                    location.contact.city
                                )
                            ) {
                                if (point.address_components.length === 5) {
                                    const searchedStreet =
                                        point.address_components[0];
                                    if (
                                        location.contact.street1.includes(
                                            searchedStreet.long_name
                                        ) ||
                                        // eslint-disable-next-line max-len
                                        location.contact.street1.includes(
                                            searchedStreet.short_name
                                        )
                                    ) {
                                        return true;
                                    }
                                    return false;
                                }
                                return true;
                            }
                            return false;
                        }
                    }
                }
                return false;
            });
            return !!matchedAddress;
        });
        storesByAddress.forEach((location) => {
            storesSlugsByTitle.push(location.slug);
            center.push({
                lat: location.contact.coordinates[0],
                lng: location.contact.coordinates[1],
            });
        });
    }

    center = center.concat(
        (geolocatedPoints || []).map((g) => g.geometry.location)
    );

    if (center.length === 0 && defaultPoint) {
        center = [defaultPoint];
    }

    if (center.length === 0) {
        return {
            error: 'Can not get the stores from contentful',
        };
    }
    const filteredLocationData = locationData.filter(
        (location) =>
            location.settings.visible &&
            !storesSlugsByTitle.includes(location.slug)
    );
    //.map((location) => {
    //    const storeLat = parseFloat(location.contact.coordinates[0]);
    //    const storeLng = parseFloat(location.contact.coordinates[1]);

    //    const distanceFromCenter = center.reduce((d, curPoint) => {
    //        const curLat = parseFloat(curPoint.lat);
    //        const curLng = parseFloat(curPoint.lng);
    //        // eslint-disable-next-line no-shadow
    //        const distanceFromCenter = distance(curLat, curLng, storeLat, storeLng, 'M');
    //        // eslint-disable-next-line no-param-reassign
    //        if (d > distanceFromCenter) d = distanceFromCenter;
    //        return d;
    //    }, MAX_DISTANCE);
    //    return {
    //        ...location,
    //        distance: distanceFromCenter,
    //    };
    //})
    //.filter((location) => location.distance < MAX_DISTANCE)
    //.sort((location1, location2) => (location1.distance < location2.distance ? -1 : 1));

    return {
        data: storesByTitle.concat(
            storesByAddress.concat(filteredLocationData)
        ),
    };
};

export const extractIdsFromTopLevel = (data) => {
    const homeComponent = data?.items?.length > 0 ? data?.items[0] : {};
    const items = homeComponent.marketingComponentsCollection?.items || [];
    const ids = items.map((item) => item.sys?.id || '');
    return ids.filter((id) => id);
};

export const extractIdsFromSecondLevel = (data) => {
    const items = data?.marketingComponentsCollection?.items || [];
    const ids = items.map((item) => item.sys?.id || '');
    return ids.filter((id) => id);
};

export const parseJSONFormat = (data) => {
    const contents = data.json?.content || [];
    const description = [];
    contents.forEach((content) => {
        if (content.nodeType === 'paragraph') {
            if (content.content[0].value) {
                description.push({
                    type: 'paragraph',
                    content: content.content[0].value,
                });
            }
        } else if (content.nodeType === 'hr') {
            description.push({
                type: 'hr',
            });
        } else if (content.nodeType === 'unordered-list') {
            const listContents = content.content || [];
            const listData = {
                type: 'list',
                content: [],
            };
            listContents.forEach((listContent) => {
                if (listContent.nodeType === 'list-item') {
                    listData.content.push(
                        listContent.content[0].content[0].value
                    );
                }
            });
            description.push(listData);
        }
    });
    return description;
};

export const parsedJSON2Html = (data) => {
    let htmlString = '';
    for (let i = 0; i < data.length; i++) {
        if (data[i].type === 'paragraph') {
            htmlString += `<p>${data[i].content}</p>`;
        }
    }
    return htmlString;
};
