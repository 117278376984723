import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { toggleConfirmModal } from 'state/ducks/Modal/Modal-Actions';
import { getConfirmModalStatus } from 'state/ducks/Modal/Modal-Selectors';

const useStyles = makeStyles((theme) => ({
  modalContent: {
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.54)',
    '& p': {
      marginBottom: '12px',
    },
  },
  title: {
    color: 'rgba(0, 0, 0, 0.54)',
    padding: '30px 24px 15px',
    '& h2': {
      lineHeight: '37px',
      fontSize: '30px',
      fontWeight: '600',
      fontFamily: 'DINCondensed-Regular',
      textAlign: 'center',
      textTransform: 'uppercase',
    },
  },
  icon: {
    marginBottom: '16px',
    color: 'green',
    fontSize: '60px',
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    width: '50%',
    fontWeight: '600',
    textTransform: 'uppercase',
  },
}));

function ConfirmModal(props) {
  const classes = useStyles();
  const {
 open, onClose, title, content, icon,
} = props;

  const handleClose = () => {
    onClose(false);
  };

  return (
      <Dialog onClose={handleClose} open={open}>
          <DialogTitle className={classes.title}>{title}</DialogTitle>
          <DialogContent className={classes.modalContent}>
              <Typography gutterBottom>
                  {content}
              </Typography>
          </DialogContent>
          {/* <DialogActions className={classes.footer}>
        <Button className={classes.button} variant="contained" color="secondary" onClick={handleClose}>
          Close
        </Button>
      </DialogActions> */}
      </Dialog>
  );
}

ConfirmModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

ConfirmModal.defaultProps = {
  open: false,
  onClose: () => undefined,
  title: null,
  content: '',
};

const mapStateToProps = (state) => ({
    open: getConfirmModalStatus(state),
});

const mapDispatchToProps = (dispatch) => ({
    onClose: bindActionCreators(toggleConfirmModal, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmModal);
