import createState from 'redux-create-state';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session'; // defaults to localStorage for web and AsyncStorage for react-native-
// eslint-disable-next-line import/no-unresolved
import utils from 'state/utils';

import * as modalActions from './Auth-Actions';

export const INITIAL_STATE = {
    redirectUrl: false,
};

const authPersistConfig = {
    key: 'auth',
    storage,
};

export default persistReducer(authPersistConfig, utils.createReducer(INITIAL_STATE)({
    [modalActions.setRedirectUrl().type]: (state, action) => createState(
        state || INITIAL_STATE,
        ['redirectUrl', action.payload]),
    [modalActions.setMoktaUser().type]: (state, action) => createState(
        state || INITIAL_STATE,
        ['moktaUser', action.payload])
}));
