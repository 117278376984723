

export const NodeEnv = {
    development:'development',
    production: 'production'
}

// server config
export default {
    
    /**
     * The build time node environment (development|production)
     */
    env: process.env.NODE_ENV,

    /**
     * The drybar environment (localhost|development|qa|production)
     */
    drybarEnv: process.env.REACT_APP_DRYBAR_ENV,

    url: process.env.REACT_APP_BASE_URL,
    apiUrl: process.env.REACT_APP_API_URL,
    contentUrl: process.env.REACT_APP_CONTENT_URL,

    holding: process.env.REACT_APP_HOLDING_PAGE || 'none',

    booker: {
        defaultLocationId: process.env.REACT_APP_BOOKER_DEFAULT_LOCATIONID,
        prodDefaultLocationId: 51306
    },

    mokta: {
        authority: process.env.REACT_APP_MOKTA_AUTHORITY,
        client_id: process.env.REACT_APP_MOKTA_CLIENT_ID,
        redirect_uri: process.env.REACT_APP_MOKTA_REDIRECT_URI,//if callback is in a separate html client page, then configure oidc with {"response_mode":"query"}
        sign_in_uri: process.env.REACT_APP_MOKTA_LOGIN_URI,
        response_type: 'code',
        scope: "openid profile webapi",
        response_mode: "query",
        pkce: false,
        post_logout_redirect_uri: process.env.REACT_APP_MOKTA_LOGOUT_URI,
        revokeAccessTokenOnSignout: false,
        automaticSilentRenew: false,
        account: process.env.REACT_APP_MOKTA_ACCOUNT,
        recovery: process.env.REACT_APP_MOKTA_RECOVERY,
        sendRequestsWithCredentials: true
    },

    wufoo: {
        subDomain: 'drybar',
        apiKey: '2V0P-FHL0-E3OP-7HEW',
        genericPartyFormId: 'm639jay05gnt4z',
        membershipSignupFormId: 'q1h4eg4w190zl46',
        contactFormId: 'z1srujeu0vb2dvk',
        resyFormId: 'ws7dd7y1p3cll1',
    },

    algolia: {
        appId: process.env.REACT_APP_ALGOLIA_APP_ID, 
        apiKey: process.env.REACT_APP_ALGOLIA_API_KEY,
        index: {
            style: process.env.REACT_APP_ALGOLIA_STYLES_INDEX,
            store: process.env.REACT_APP_ALGOLIA_LOCATIONS_INDEX,
            video: 'production_magento2_default_videos',
        },
        searchType: {
            style: 'Style',
            store: 'Store',
            video: 'Video',
        },
    },
    mobileApp: {
        iphone: 'https://apps.apple.com/gb/app/drybar-uk/id1596751939',
        android: 'https://play.google.com/store/apps/details?id=com.drybar.uk',
    },

    gtm: {
        gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID,
        booking: {
            event: 'form_step',
            type: 'book_appointments',
            step1: 'step1 location',
            step2: 'step2 how many',
            step3: 'step3 services',
            step4: 'step4 extensions',
            step5: 'step5',
            step6: 'step6',
            step7: 'step7',
            step8: 'step8',
            step9: 'step9',
        },
        purchaseEvent: 'eec.purchase',
        formCompleteEvent: 'form_complete',
        subscription: {
            type: 'newsletter',
            option: 'general subscription',
        },
    },
    barfly:{
        rewardsCardPrefixRange: [process.env.REACT_APP_HARRODS_REWARDS_NUMBER_PREFIX_LOWER_BOUND, process.env.REACT_APP_HARRODS_REWARDS_NUMBER_PREFIX_UPPER_BOUND],
    },
    axioscfg: {
        withCredentials: process.env.REACT_APP_AXIOS_WITHCREDENTIALS
    },
};
