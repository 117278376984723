import { gql } from '@apollo/client';
import contentIds from '../contentIds';
import { getPreviewMode } from '../../state/utils/contentful';

const screenStoreLocatorCollection = () => gql`{
    screenStoreLocator(${getPreviewMode()}, id: "${contentIds.storeLocator}") {
        title
        slug
        description {
          json
        }
        metadata {
          sys {
            id
          }
        }
        marketingComponentsCollection(limit: 2) {
          total
          limit
          items {
            ... on MarketingSection {
              title
              internalName
              marketingComponentsCollection(limit: 2) {
                total
                items {
                  __typename
                  ... on MarketingCollection {
                    title
                    internalName
                    marketingComponentsCollection(limit: 2) {
                      items {
                        __typename
                        ... on MarketingCard {
                          title
                          subtitle
                          internalName
                          avatar {
                            url
                          }
                          image {
                            alternateTitle
                            desktopMedia {
                              url
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }    
  }
`;

export default screenStoreLocatorCollection;

export const queryLocationsPageFromContentful = () => (
  `{
    drybarPageCollection(preview: true, where: { slug: "locator" }) {
      items {
        sys {
          id
        }
        title
        subtitle
        tcsAndPoliciesCopy
        seoTitle
        seoDescription
        canonicalUrl
        addANoindexTag
        slug
      }
    }
  }`
)
