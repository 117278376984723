import React, { useState, useEffect } from 'react';
import useMoktaAuth from 'utils/oktaAuth';
import restClient from 'api/restClient';
import { updateContactList } from 'api/emarsys-api';
import {
    Typography,
    Grid,
    TextField,
    Checkbox,
    Button,
    FormControlLabel,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    withStyles,
} from '@material-ui/core';
import { MOBILE_BREAKPOINT, TABLET_BREAKPPOINT } from 'Helpers/breakpoints';
import { EMAIL_REGEX, NAME_REGEX } from 'utils/validation/regex';
import { googleAnalyticsEvents } from 'state/utils/googleAnalyticsEvents';

const styles = (theme) => ({
    content: {
        padding: 15,
    },
    infoText: {
      textAlign: 'center'
    },
    blockItem: {
        boxShadow: '2px 2px 46px rgba(235, 235, 235, 0.5)',
        backgroundColor: 'white',
        padding: '27px 36px',
        marginBottom: 30,
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            padding: 10,
            backgroundColor: 'transparent',
            boxShadow: 'none',
        },
    },
    inputItem: {
        marginBottom: 29,
        '& label': {
            fontFamily: 'URWForm',
            color: '#767676',
            fontSize: 18,
            lineHeight: '25px',
        },
        '& .MuiInput-formControl': {
            marginTop: 30,
        },
        '& textarea': {
            width: '100%',
            border: '1px solid #d1d1d1',
            outline: 'none',
            padding: '32px 26px',
            minHeight: 285,
        },
        '& p.error-msg': {
            color: '#FF0000',
        },
    },
    blockTitle: {
        fontFamily: 'URWForm',
        fontWeight: 600,
        fontSize: 18,
        lineHeight: '45px',
        color: '#42413D',
    },
    width100: {
        width: '100%',
    },
    inputGroup: {
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            padding: 18,
            boxShadow: '2px 2px 46px rgba(235, 235, 235, 0.5)',
            backgroundColor: 'white',
            marginBottom: 19,
        },
    },
    sectionTitle: {
        textAlign: 'center',
        fontFamily: 'DINCondensed-Regular',
        fontWeight: 'bold',
        fontSize: 42,
        lineHeight: '50px',
        color: '#42413D',
    },
    sectionContainer: {
        marginTop: '15px',
    },
    selected: {
        backgroundColor: theme.palette.primary.main,
        fontWeight: '800',
        '&:hover': {
            backgroundColor: theme.palette.common.hover[1],
        },
        height: '55px',
        margin: '0 20px',
        borderRadius: '0px',
        textTransform: 'capitalize',
        fontSize: '18px',
        width: 'calc(100% - 40px)',
        [theme.breakpoints.down(TABLET_BREAKPPOINT + 1)]: {
            margin: '20px 10px 0',
            width: 'calc(100% - 20px)',
        },
        marginTop: 20,
    },
    border: {
        margin: '36px 0',
        borderBottom: '3px dashed #BDBDBD',
    },
    errorText: {
        fontSize: '0.75rem',
        color: '#f44336',
    },
    modalTitle: {
        textAlign: 'center',
        fontSize: '31px',
        fontFamily: 'DINCondensed-Regular',
        lineHeight: '37px',
        textTransform: 'uppercase',
        fontWeight: '600',
    },
    modalHeader: {
        padding: '30px 24px 15px',
    },
});

function UnsubscribeFromMarketing({ classes }) {
    const [firstName, setFirstName] = useState('');
    const [firstNameError, setFirstNameError] = useState('');
    const [lastName, setLastName] = useState('');
    const [lastNameError, setLastNameError] = useState('');
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [dialog, setDialog] = useState(false);
    const [dialogTitle, setDialogTitle] = useState('');
    const [dialogMessage, setDialogMessage] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            setFirstName('Denys');
            setLastName('Shpak');
            setEmail('denys.shpak@harrods.com');
        };

        fetchData();
    }, []);

    const resetValues = () => {
        setFirstName('');
        setLastName('');
        setEmail('');
        setConsent(false);
    };

    const resetErrors = () => {
        setFirstNameError('');
        setLastNameError('');
        setEmailError('');
        setConsentError('');
    };

    const reset = () => {
        resetErrors();
        resetValues();
    };

    const isValid = () => {
        resetErrors();
        let hasError = false;
        if (!firstName) {
            setFirstNameError('* First name is required');
            hasError = true;
        } else if (!NAME_REGEX.test(firstName)) {
            setFirstNameError('* First name is not valid');
            hasError = true;
        }
        if (!lastName) {
            setLastNameError('* Last name is required');
            hasError = true;
        } else if (!NAME_REGEX.test(lastName)) {
            setLastNameError('* Last name is not valid');
            hasError = true;
        }
        if (!email) {
            setEmailError('* Email is required');
            hasError = true;
        } else if (!EMAIL_REGEX.test(email)) {
            setEmailError('* Email is not valid');
            hasError = true;
        }
        return !hasError;
    };

    const handleSubmit = async () => {
        if (isValid()) {
            await restClient
                .post(
                    updateContactList({
                        email,
                        firstName,
                        lastName,
                        consent: false,
                    })
                )
                .then((res) => {
                    gtag(
                        googleAnalyticsEvents.EVENT,
                        googleAnalyticsEvents.NEWSLETTER
                    );
                    setDialogTitle('Success');
                    setDialogMessage('You have successfully unsubscribed');
                    setDialog(true);
                    reset();
                })
                .catch(() => {
                    setDialogTitle('Error');
                    setDialogMessage(
                        'There has been an error trying to sign-up. Please try again later.'
                    );
                    setDialog(true);
                });
        }
    };

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
        >
            <Grid className={classes.sectionContainer} item xs={12} md={6}>
                <Typography variant={'h1'} className={classes.sectionTitle}>
                    UNSUBSCRIBE
                </Typography>
                <Typography variant='body1' className={classes.infoText}>
                    You are signed up to receive marketing communications from
                    Drybar regarding your Barfly membership benefits and the
                    latest events, products and services at the below email
                    address. To unsubscribe, please click the Unsubscribe button
                    below.
                </Typography>
                <div className={classes.content}>
                    <div className={classes.blockItem}>
                        <div className={classes.inputGroup}>
                            <div className={classes.inputItem}>
                                <TextField
                                    id="firstName"
                                    label="First name"
                                    placeholder="Your first name here..."
                                    type="text"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    className={classes.width100}
                                    value={firstName}
                                    onChange={(e) =>
                                        setFirstName(e.target.value)
                                    }
                                    helperText={firstNameError}
                                    error={firstNameError}
                                />
                            </div>
                            <div className={classes.inputItem}>
                                <TextField
                                    id="lastName"
                                    label="Last name"
                                    placeholder="Your last name here..."
                                    type="text"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    className={classes.width100}
                                    value={lastName}
                                    onChange={(e) =>
                                        setLastName(e.target.value)
                                    }
                                    helperText={lastNameError}
                                    error={lastNameError}
                                />
                            </div>
                            <div className={classes.inputItem}>
                                <TextField
                                    id="email"
                                    label="Email"
                                    placeholder="Your email here..."
                                    type="text"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    className={classes.width100}
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    helperText={emailError}
                                    error={emailError}
                                />
                            </div>
                        </div>
                        <Button
                            onClick={handleSubmit}
                            className={classes.selected}
                            variant="outlined"
                        >
                            Unsubscribe
                        </Button>
                        <Dialog
                            open={dialog}
                            onClose={() => setDialog(false)}
                            maxWidth="xs"
                        >
                            <DialogTitle>
                                <Typography className={classes.modalTitle}>
                                    {dialogTitle}
                                </Typography>
                            </DialogTitle>
                            <DialogContent className={classes.modalContent}>
                                <DialogContentText>
                                    {dialogMessage}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    variant="outlined"
                                    onClick={() => setDialog(false)}
                                >
                                    OK
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                </div>
            </Grid>
        </Grid>
    );
}

export default withStyles(styles)(UnsubscribeFromMarketing);
