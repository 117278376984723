// documentToComponent.js
import React, { useState, useEffect } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { makeStyles } from '@material-ui/core';
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'; // needed to add custom options.
import { doQuery, getPreviewMode } from '../state/utils/contentful';
import MarketingVideo from '../app/Components/common/marketingVideo';
import MarketingImage from '../app/Components/common/marketingImage';
import CustomImg from './CustomImg';

/**
 *
 * @param {*} doc: json type of document.
 * optional: can add options for customized html tags.
 */

const StructuredTable = ({ data }) => {
    const { body = [], footer = [], header = [] } = data;
    if (!body) {
        return null;
    }
    const TableHead = () => (
        <thead>
            <tr className="table__header">
                {header.map((head) => (
                    <th dangerouslySetInnerHTML={{ __html: head }} />
                ))}
            </tr>
        </thead>
    );
    const TableFoot = () => (
        <tfoot>
            <tr className="table__footer">
                {footer.map((foot) => (
                    <td dangerouslySetInnerHTML={{ __html: foot }} />
                ))}
            </tr>
        </tfoot>
    );
    const TableBody = () => (
        <tbody>
            {body.map((row) => (
                <tr className="table__body">
                    {row.map((cell) => (
                        <td dangerouslySetInnerHTML={{ __html: cell }} />
                    ))}
                </tr>
            ))}
        </tbody>
    );
    return (
        <table className="structured__table">
            <TableHead />
            <TableBody />
            <TableFoot />
        </table>
    );
};

const MarketingPDF = ({ data }) => (
    <div className="pd-wrapper">
        <a href={data.url || ''} target="_blank">
            <MarketingImage component={data.thumbnail} />
        </a>
        {data.title && <h5>{data.title}</h5>}
    </div>
);

const EmbeddedEntryComponent = (props) => {
    const targetEntryId = props?.data?.target?.sys?.id || '';
    const [structuredData, setStructuredData] = useState(null);
    const [marketingVideo, setMarketingVideo] = useState(null);
    const [marketingPdf, setMarketingPdf] = useState(null);

    const getEntryData = async (entryId) => {
        const queryQL = `
            query {     
                marketingCardStructuredData(${getPreviewMode()}, id: "${entryId}") {
                    structuredData
                }
                marketingVideo(${getPreviewMode()}, id: "${entryId}") {
                    sys {
                      id
                    }
                }
                marketingPdf(${getPreviewMode()}, id: "${entryId}") {
                    title
                    url
                    subtitle
                    thumbnail {
                        sys {
                            id
                        }
                    }
                }
            }`;
        const data = await doQuery(queryQL);

        const tableData =
            data?.marketingCardStructuredData?.structuredData || null;
        if (tableData) setStructuredData(tableData);

        const videoData = data?.marketingVideo || null;
        if (videoData) setMarketingVideo(videoData);

        const pdfData = data?.marketingPdf || null;
        if (pdfData) setMarketingPdf(pdfData);
    };

    useEffect(() => {
        if (targetEntryId === '') return;
        getEntryData(targetEntryId);
    }, [targetEntryId]);

    if (targetEntryId === '') return '';
    if (structuredData && structuredData.type === 'table')
        return (
            <StructuredTable data={structuredData.data} key={targetEntryId} />
        );
    if (marketingVideo)
        return (
            <MarketingVideo
                component={marketingVideo}
                key={targetEntryId}
                id={marketingVideo.sys.id}
            />
        );
    if (marketingPdf)
        return <MarketingPDF data={marketingPdf} key={targetEntryId} />;
    return <div />;
};

const EmbeddedAssetComponent = (props) => {
    const targetAssetId = props?.data?.target?.sys?.id || '';
    const [asset, setAsset] = useState(null);
    const getAssetData = async (assetId) => {
        const queryQL = `
            query {
                asset(${getPreviewMode()}, id: "${assetId}") {
                    title
                    description
                    contentType
                    fileName
                    size
                    url
                    width
                    height
                }
            }
        `;
        const data = await doQuery(queryQL);
        const assetData = data?.asset || null;
        setAsset(assetData);
    };

    useEffect(() => {
        if (targetAssetId === '') return;
        getAssetData(targetAssetId);
    }, [targetAssetId]);

    if (targetAssetId === '') return '';
    if (asset) return <CustomImg src={asset.url} alt={asset.title} />;
    return <div />;
};

const options = {
    renderText: (text) =>
        text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
    renderMark: {
        [MARKS.BOLD]: (text) => <b>{text}</b>,
    },
    renderNode: {
        [BLOCKS.EMBEDDED_ENTRY]: (node) => (
            <EmbeddedEntryComponent data={node.data} />
        ),
        [BLOCKS.EMBEDDED_ASSET]: (node) => (
            <EmbeddedAssetComponent data={node.data} />
        ),
        [INLINES.HYPERLINK]: (node, children) => {
            if (
                window.location.href.indexOf('instore') != -1 ||
                window.location.href.indexOf('barfly-confirm') != -1
            ) {
                return (
                    <a target="_blank" href={node.data.uri}>
                        {children}
                    </a>
                );
            } else {
                return <a href={node.data.uri}>{children}</a>;
            }
        },
    },
};

const useStyles = makeStyles((theme) => ({
    docRoot: {
        '& li': {
            listStyle: 'inherit',
            display: 'list-item',
            '& p': {
                float: 'inherit',
                paddingLeft: '10px',
            },
            // paddingLeft: '10px'
        },
        '& ul': {
            paddingLeft: '20px',
        },
        '& td': {
            border: '1px solid black',
            padding: '10px',
        },
        '& img': {
            width: '100%',
        },
    },
}));

const documentToComponent = (props) => {
    const classes = useStyles();
    const { doc } = props;

    const newDoc = {};

    if (doc && doc.content && doc.content.length > 0) {
        return (
            <div className={classes.docRoot}>
                {documentToReactComponents(doc, options)}
            </div>
        );
    }
    return '';
};

export default documentToComponent;
