/* eslint-disable import/no-cycle */
/* eslint-disable max-len */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { CircularProgress, Backdrop } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import ConnectedScreenAddOnsContainer from '../common/partials/ScreenAddOnsServices/ScreenServicesAddOns';
import {
    screenAddOnsCollections as ScreenAddOnsCollection,
    addOnsPageQuery,
    fetchBannerData,
    fetchItems,
} from '../../../gql/queries/screenAddOnsCollection';
import { MetaTagWrapper } from '../../../components';
import { doQuery } from '../../../state/utils/contentful';

// const ConnectMetaTags = ({
//     screenServicesAddOnsData,
// }) => {
//     const [metadata, setMetadata] = useState(null);

//     useEffect(async () => {
//         if (!screenServicesAddOnsData) return;
//         const metadataId = screenServicesAddOnsData?.metadata?.sys?.id || null;
//         if (metadataId) {
//             const data = await getMetaData(metadataId);
//             if (data) setMetadata(data);
//         }
//     }, [screenServicesAddOnsData]);

//     return <MetaTagWrapper metadata={metadata} />;
// };

const ServiceLocatorContainer = () => {
    const [metadata, setMetadata] = useState(null);
    const [newLoading, setIsLoading] = useState(false);
    const [newData, setData] = useState(null);

    useEffect(async () => {
        setIsLoading(true);

        const data = await doQuery(addOnsPageQuery(), true);
        const pageData = data.drybarPageCollection?.items[0];

        setMetadata({
            pageDescription: pageData?.seoDescription,
            pageKeywords: [],
            pageTitle: pageData?.seoTitle,
            searchKeywords: [],
        });

        const firstLevelIds = pageData.formatofPageCollection?.items?.map(
            (item) => item.sys.id
        );

        let banner = {};
        let items = {};

        if (firstLevelIds.length > 0) {
            banner = await doQuery(
                fetchBannerData(firstLevelIds[0]),
                true
            );

            items = await doQuery(
                fetchItems(firstLevelIds[1]),
                    true
                )
        }

        items = items?.drybarPictureGrid?.drybarGridCardsCollection?.items?.map(item => {
            return {
                title: item.title,
                subtitle: item.subtitle,
                price: item.price,
                productId: item.bookerReference,
                slug: item.slugPrefix,
                description: {
                    json: {
                        content: [
                            {
                                content: [
                                    {
                                        value: item.description,
                                        nodeType: 'text',
                                    },
                                ],
                                nodeType: 'paragraph',
                            },
                        ],
                        nodeType: 'document',
                    },
                },
                bestFor: item.bestFor?.join(', '),
                imagesCollection: {
                    items: item?.imagesCollectionCollection?.items?.map(image => {
                        return {
                            desktopMedia: {
                                url: image.desktopImageUrl
                            },
                            mobileMedia: {
                                url: image.mobileImageUrl
                            }
                        }
                    })
                }
            }
        })

        const finalData = {
            slug: pageData.slug,
            title: pageData.title,
            description: {
                json: {
                    content: [
                        {
                            content: [
                                {
                                    value: pageData.subtitle,
                                    nodeType: 'text',
                                },
                            ],
                            nodeType: 'paragraph',
                        },
                    ],
                    nodeType: 'document',
                },
            },
            marketingComponentsCollection: {
                items: [
                    {
                        desktopMedia: {
                            url:
                                banner?.heroBanner?.mediaCollection?.items?.[0]?.desktopImageUrl,
                        },
                        mobileMedia: {
                            url:
                            banner?.heroBanner?.mediaCollection?.items?.[0]?.mobileImageUrl,
                        },
                    },
                ],
            },
            productsCollection: {
                items: items,
            },
        };

        setData(finalData);

        setIsLoading(false);
    }, []);

    //const SCREEN_ADD_ONS_QUERY = ScreenAddOnsCollection();
    //const { data, error, loading } = useQuery(SCREEN_ADD_ONS_QUERY);

    //useEffect(() => console.log(data), [data]);

    if (newLoading) {
        return (
            // todo - replace with skeleton
            <Backdrop
                open
                style={{
                    zIndex: 11,
                    color: '#fff',
                }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    }

    // if (error) {
    //     console.log('errror ===>', error, data);
    // }

    if (newData) {
        //console.log('add-ons parent', data?.screenProductCollection?.items);

        // let addonData = newData;

        // console.log('newData', newData);

        // if (newData?.screenProductCollection?.items?.length > 0) {
        //     for (
        //         let i = 0;
        //         i < newData.screenProductCollection.items.length;
        //         i++
        //     ) {
        //         const item = newData.screenProductCollection.items[i];
        //         //if (item.slug == 'add-ons') {
        //             addonData = item;
        //         //}
        //     }
        // }

        // console.log('addon', addonData);

        return (
            <div style={{ maxWidth: 1295, margin: '0 auto' }}>
                <MetaTagWrapper metadata={metadata} />
                <ConnectedScreenAddOnsContainer
                    screenServicesAddOnsData={newData}
                />
            </div>
        );
    }
    return null;
};

export default withRouter(ServiceLocatorContainer);
