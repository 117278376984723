/* eslint-disable max-len */

import { findCustomerMemberships } from '../../../api/booking-api';

import { Grid, Typography, withStyles, Button } from '@material-ui/core';
import { object } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { GoogleApiWrapper } from 'google-maps-react';
import {
    MEDIUM_MOBILE_BREAKPOINT,
    MOBILE_BREAKPOINT,
    TABLET_BREAKPPOINT,
} from '../../../Helpers/breakpoints';

import {
    getBarflyMembershipPrice,
    getChosenStore,
    getUpdatedCustomer,
    getNewCard,
    getBarflyMembershipID,
    getBarflyMembershipType,
    getBarflyBlowouts,
    getBarflyAdditionalOffers,
    getRewardsCardNumber,
    getBookerCustomerId,
} from '../../../state/ducks/Barfly/Barfly-Selectors';
import {
    setUpdatedCustomer,
    setCard,
} from '../../../state/ducks/Barfly/Barfly-Actions';
import { setCardAddedWorldpay } from '../../../state/ducks/Worldpay/Worldpay-Actions';
import { getCardAddedStatus } from '../../../state/ducks/Worldpay/Worldpay-Selectors';

import { useFetchAsync } from '../../../Helpers/useFetch';

import 'react-toastify/dist/ReactToastify.css';

const styles = (theme) => ({
    container: {
        maxWidth: '1367px',
        width: '100%',
        margin: 'auto',
        padding: '53px 30px 20px 30px',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            padding: '19px',
        },
    },
    mainTitle: {
        textTransform: 'uppercase',
        fontFamily: 'DINCondensed',
        fontSize: '32px',
        color: '#42413D',
        fontWeight: '600',
        width: '100%',
        textAlign: 'center',
        lineHeight: '35px',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            fontSize: '28px',
            width: '79%',
        },
    },
    titleContainer: {
        display: 'flex',
    },
    displayFlex: {
        display: 'flex',
        alignItems: 'center',
        color: '#42413D',
    },
    subTitle: {
        textAlign: 'center',
        fontSize: '18px',
        marginBottom: '25px',
        fontWeight: '400',
        textTransform: 'none',
        fontFamily: 'AvenirNext',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            fontSize: '15px',
        },
    },
    submitButton: {
        maxWidth: '378px',
        width: '100%',
        height: '63px',
        fontSize: '18px',
        margin: '0px 0px 40px 450px',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            margin: '36px 0px 64px 4px',
        },
    },
    summaryContainer: {
        borderRadius: '0px',
        backgroundColor: '#FFFFFF',
        padding: '28px 35px',
        height: '100%',
        [theme.breakpoints.down(TABLET_BREAKPPOINT)]: {
            padding: '20px 15px',
        },
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            backgroundColor: '#F9F9F9',
        },
        [theme.breakpoints.down(MEDIUM_MOBILE_BREAKPOINT)]: {
            padding: 0,
        },
    },
    summaryTitle: {
        fontFamily: 'MrsEavesSmallCaps',
        /*  fontSize: '25px',*/
        fontSize: '16px',
        lineHeight: '45px',
        textTransform: 'uppercase',
        color: '#42413D',
        margin: '28px 0px 0px 36px',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            fontSize: '15px',
            whiteSpace: 'nowrap',
            margin: '0px',
        },
    },
    membershipCharge: {
        alignItems: 'flex-start',
        margin: '16px 0',
    },
    theFinePrint: {
        width: '1269px',
        borderRadius: '0px',
        backgroundColor: '#FFFFFF',
        marginTop: '5px',
        marginBottom: '40px',
        padding: '28px 35px',
        [theme.breakpoints.down(TABLET_BREAKPPOINT)]: {
            padding: '20px 15px',
        },
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            width: '100%',
        },
    },
    finePrintCopy: {
        fontFamily: 'AvenirNext',
        fontSize: '17px',
        lineHeight: '32px',
        color: '#42413D',
        padding: '25px 25px 0px 25px',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            fontSize: '15px',
            lineHeight: '22px',
        },
    },
    finePrintHeading: {
        fontFamily: 'MrsEavesSmallCaps',
        fontSize: '20px',
        lineHeight: '45px',
        color: '#42413D',
        marginTop: '24px !important',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            fontSize: '15px',
            lineHeight: '22px',
            marginLeft: '2px',
        },
    },
    slashVector: {
        margin: '70px 20px 0 25px',
        content: '""',
        height: '45px',
        transform: 'rotate(20deg)',
        borderRight: '2px solid #42413D',
        // position: 'absolute',
        '&::after': {},
    },
    serviceChargeCotainer: {
        display: 'flex',
        justifyContent: 'center',
        flex: '1',
        borderRadius: '0px',
        backgroundColor: '#F7F8F9',
        borderTop: '1px solid #D1D1D1',
        alignItems: 'left',

        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            backgroundColor: '#FFFFFF',
            borderRadius: '0px',
        },
    },
    servicePriceCopy: {
        border: '1px solid #FFFFFF',
        background: '#FFFFFF',
        boxShadow: '2px 2px 46px rgba(235, 235, 235, 0.5)',
        borderRadius: '50%',
        fontSize: '32px',
        lineHeight: '34px',
        fontWeight: '600',
        color: '#42413D',
        fontFamily: 'AvenirNext-Bold',
        display: 'flex',
        alignItems: 'center',
        width: '130px',
        height: '130px',
        letterSpacing: '-2px',
        margin: '20px 0 0 70px',
        justifyContent: 'center',

        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            background: '#F7F8F9',
            width: '76px',
            height: '76px',
            borderWidth: '0px',
            padding: '12px',
        },
    },
    serviceChargeData: {
        display: 'flex',
        position: 'relative',
        margin: '33px 0px',
        alignItems: 'center',

        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            margin: '11px 0px 0px 5px',
            flexWrap: 'wrap',
        },
    },
    taxApplicable: {
        fontFamily: 'AvenirNext',
        fontSize: '18px',
        lineHeight: '34px',
        color: '#767676',
        margin: '0 0 0 11px',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            margin: '11px 0px 11px 5px',
        },
    },
    monthCopy: {
        fontSize: '25px',
        lineHeight: '34px',
        fontWeight: '600',
        color: '#42413D',
        fontFamily: 'AvenirNext',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            fontSize: '18px',
        },
    },
    theBarflySingle: {
        // width: '125px',
        height: '22px',
        margin: '0 50px 10px',
        fontFamily: 'AvenirNext',
        /*fontSize: '22px',*/
        fontSize: '16px',
        fontWeight: '600',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#54575a',
        display: 'inline-block',
    },
    thePrice: {
        // width: '125px',
        height: '22px',
        margin: '0 0 10px',
        fontFamily: 'AvenirNext',
        /*fontSize: '22px',*/
        fontSize: '16px',
        fontWeight: '600',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#54575a',
        display: 'inline-block',
    },
    blowDryPerMonth: {
        // width: '121px',
        height: '16px',
        margin: '20px 4px 0 0',
        fontFamily: 'AvenirNext',
        /*fontSize: '16px',*/
        fontSize: '14px',
        fontEeight: '500',
        fontstretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#8b9197',
    },
    month: {
        width: '126px',
        height: '33px',
        margin: '100px 0 7px 50px',
        fontFamily: 'AvenirNext',
        fontSize: '24px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#54575a',
    },
    line: {
        height: '38px',
        margin: '6px 60px 4px 111px',
        transform: 'rotate(-209.22deg)',
        border: 'solid 1px #53575a',
    },
    drybarLocation: {
        fontFamily: 'AvenirNext',
        fontSize: '18px',
        lineHeight: '34px',
        // color: '#42413D',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            fontSize: '15px',
            lineHeight: '24px',
        },
    },
    locationDetails: {
        borderRadius: '0px',
        backgroundColor: '#F7F8F9',
        borderTop: '1px solid #D1D1D1',
        margin: '0 0 15px 0',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '10px 20px 20px 10px',

        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            backgroundColor: '#FFFFFF',
            borderRadius: '0px',
        },
    },
    confirmedMap: {
        width: '195px',
        height: 'auto',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            width: '134px',
        },
    },
    bookYourAppointmentToday: {
        maxWidth: '378px',
        width: '100%',
        height: '63px',
        fontSize: '18px',
        margin: '20px auto 40px',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            maxWidth: '318px',
            height: '55px',
            fontSize: '15px',
            margin: '10px auto 30px',
        },
    },
    thankYouDesc: {
        borderTop: '1px solid #D1D1D1',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            width: '90%',
            margin: '0px 13px 0px 22px',
        },
    },
    thankYouHeading: {
        fontFamily: 'DINCondensed',
        fontWeight: '700',
        fontSize: '34px',
        lineHeight: '41px',
        textTransform: 'uppercase',
        color: '#42413D',
        width: '100%',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            width: '251px',
            fontSize: '30px',
            lineHeight: '34px',
        },
    },
    dialogContainer: {
        maxWidth: 890,
        minWidth: 626,
        height: 500,
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            minWidth: 0,
        },
    },
    copyDetails: {
        fontFamily: 'AvenirNext',
        fontSize: '16px',
        lineHeight: '32px',
        color: '#42413D',
        marginTop: '10px',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            fontSize: '15px',
            marginTop: '2px',
            lineHeight: '22px',
            marginLeft: '-23px',
        },
    },
    closeIcon: {
        margin: '20px 0px 0px 30%',
        cursor: 'pointer',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            margin: '34px 0px 0px 1%',
        },
    },
    backPageCopy: {
        fontSize: '16px',
        fontWeight: '600',
        paddingTop: '2px',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            display: 'none',
        },
    },
    modalContainer: {
        background: 'rgba(255, 255, 255, 0.94)',
    },
    mapInfo: {
        height: '260px',
    },
    errorToastContainer: {
        width: '600px',
        top: '80px',
    },
    errorContainer: {
        background: 'rgba(189, 0, 0, 0.7)',
        borderRadius: 12,
        minHeight: 51,
    },
    errorBody: {
        fontSize: '16px',
        lineHeight: '25px',
        color: '#fff',
        textAlign: 'center',
    },
    fieldContainerTitle: {
        height: '13px',
        margin: '39px 884px 0 0',
        fontFamily: 'MillerBanner-Roman',
        fontSize: '16px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#000',
        display: 'inline-block',
    },
    textField: {
        width: '100%',
        margin: '14px 15px',
        '& input': {
            padding: '10px 0',
            fontSize: '20px',
        },
        '& label': {
            fontSize: '18px',
        },
        '& select': {
            padding: '10px 0',
            fontSize: '20px',
        },
        '& option': {
            padding: '15px',
        },
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            margin: '14px 8px',
        },
    },
    singleLineTextFieldsContainer: {
        display: 'flex',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            flexWrap: 'wrap',
        },
    },
    fieldContainer: {
        backgroundColor: '#fff',
        padding: '15px 21px 25px 21px',
        margin: '10px 0 5px',
    },
    pricesVary: {
        fontFamily: 'AvenirNext',
        /*fontSize: '16px',*/
        fontSize: '14px',
        color: '#54575a',
        margin: '20px 0px 20px 0px',
    },
    signingUp: {
        fontFamily: 'AvenirNext',
        /*fontSize: '16px',*/
        fontSize: '14px',
        color: '#54575a',
        textAlign: 'center',
    },
    line: {
        width: '394px',
        height: '1px',
        margin: '13px 2px 20px 0',
        border: 'solid 1px #979797',
    },
    container: {
        background: '#fff',
        marginLeft: '15px',
        padding: '34px 22px 14px 22px',
        textAlign: 'center',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            background: '#f9f9f9',
            marginLeft: 0,
            textAlign: 'left',
        },
        minHeight: '507px',
    },
    heading: {
        fontWeight: '800',
        float: 'left',
        paddingBottom: '18px',
        fontSize: '22px',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            float: 'unset',
            textTransform: 'uppercase',
            fontFamily: 'MrsEavesSmallCaps',
            fontWeight: 'normal',
            marginBottom: '10px !important',
        },
    },
    formContainer: {
        margin: '46px 0 0',
        width: '100%',
        backgroundColor: '#F9F9F9F9',
        borderTop: '1px solid #D1D1D1',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            marginTop: 20,
            backgroundColor: '#fff',
            height: 'unset',
            textAlign: 'left',
        },
    },
    formContainer1: {
        margin: '30px 0 0',
        width: '100%',
        height: '60px',
        backgroundColor: '#F9F9F9F9',
        borderTop: '1px solid #D1D1D1',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            margin: '10px 0',
            padding: '0 14px 20px',
            backgroundColor: '#fff',
            height: 'unset',
            textAlign: 'left',
        },
    },
    breflyMembershipBenefits: {
        margin: '20px 2px 2px 2px',
        padding: '20px 0',
        backgroundColor: '#F9F9F9F9',
        borderTop: '1px solid #D1D1D1',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            background: '#FFFFFF !important',
            margin: '46px 2px 2px -15px',
            width: '377px',
        },
    },
    button: {
        width: '378px',
        height: '63px',
        maxWidth: '100%',
        margin: '20px 0 48px',
    },
    emailPref: {
        textAlign: 'left',
        margin: '32px 0 0',
    },
    emailTypography: {
        margin: '10px 0 20px',
        color: '#979797',
    },
    signatureCopy: {
        textAlign: 'left',
        paddingTop: '20px !important',
        margin: '4px 2px 2px 17px !important',
        fontSize: '22px',
        fontWeight: '600',
        [theme.breakpoints.down(MEDIUM_MOBILE_BREAKPOINT)]: {
            marginLeft: '14px !important',
        },
    },
    benefits: {
        textAlign: 'left',
        margin: '4px 2px 2px 30px !important',
        fontSize: '16px',
        fontWeight: '500',
        //fontFamily: 'AvenirNext',
        [theme.breakpoints.down(MEDIUM_MOBILE_BREAKPOINT)]: {
            marginLeft: '14px !important',
        },
    },
    availableBenefits: {
        textAlign: 'left',
        margin: '4px 2px 2px 17px !important',
        fontSize: '16px',
        fontWeight: '600',
        //fontFamily: 'AvenirNext',
        [theme.breakpoints.down(MEDIUM_MOBILE_BREAKPOINT)]: {
            marginLeft: '14px !important',
        },
    },
    membershipName: {
        textAlign: 'left',
        margin: '4px 2px 2px 17px !important',
        fontSize: '22px',
        fontWeight: '600',
        float: 'left',
        [theme.breakpoints.down(MEDIUM_MOBILE_BREAKPOINT)]: {
            marginLeft: '14px !important',
        },
    },
    membershipPricePerMonth: {
        textAlign: 'left',
        margin: '4px 20px 2px 17px !important',
        fontSize: '22px',
        fontWeight: '600',
        float: 'right',
        [theme.breakpoints.down(MEDIUM_MOBILE_BREAKPOINT)]: {
            marginLeft: '14px !important',
        },
    },

    billPeriodContainer: {
        padding: 6,
        borderTop: '0.5px solid #979797',
        marginTop: '50px',
    },
    nextBillDate: {
        fontSize: '16px',
        fontWeight: '500',
        padding: '5px !important',
        textAlign: 'left',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            whiteSpace: 'nowrap',
            textAlign: 'center',
        },
        [theme.breakpoints.down(MEDIUM_MOBILE_BREAKPOINT)]: {
            textAlign: 'left',
        },
    },
    lastBillDate: {
        fontSize: '16px',
        fontWeight: '500',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            whiteSpace: 'nowrap',
            textAlign: 'center',
        },
        [theme.breakpoints.down(MEDIUM_MOBILE_BREAKPOINT)]: {
            textAlign: 'left',
        },
    },
    bulletPoints: {
        listStyle: 'disc',
        display: 'list-item',
        marginLeft: '20px',
        marginBottom: '5px',
    },
    billDateContainer: {
        padding: 14,
        fontFamily: 'AvenirNext',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            padding: 8,
        },
        [theme.breakpoints.down(MEDIUM_MOBILE_BREAKPOINT)]: {
            borderRight: '0',
        },
    },
    borderLeftMobile: {
        padding: 14,
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            padding: 8,
        },
    },
    barflyEclipse: {
        margin: '0px 0px 0px -56px',
    },
    creditNumberEclipse: {
        marginTop: '-91px',
    },
    serviceInclude: {
        width: '432px',
        height: '38px',
        backgroundColor: '#E5E5E5',
        margin: '8px 12px 0px 38px',
        borderRadius: '4px',
        paddingTop: '7px',
    },
    theSignature: {
        width: '108px',
        height: '38px',
        backgroundColor: '#E5E5E5',
        margin: '8px 12px 0px 0px',
        borderRadius: '4px',
        paddingTop: '7px',
    },
    serviceIncludeInfo: {
        backgroundColor: '#F9F9F9',
        width: '432px',
        height: '79px',
        margin: '8px 12px 0px 38px',
        paddingTop: '7px',
    },
    theSignatureNumber: {
        width: '108px',
        height: '79px',
        backgroundColor: '#FFFFFF',
        margin: '8px 12px 0px 0px',
        borderRadius: '4px',
        paddingTop: '7px',
        border: '2.5px solid #FFDD30',
        filter: 'drop-shadow(0px 0px 7px rgba(0, 0, 0, 0.1))',
    },
    costTotal: {
        width: '432px',
        height: '50px',
        backgroundColor: '#E5E5E5',
        margin: '8px 12px 0px 38px',
        paddingTop: '7px',
    },
    theSignatureTotal: {
        width: '108px',
        height: '50px',
        backgroundColor: '#54575A',
        margin: '8px 12px 0px 0px',
        paddingTop: '12px',
        color: '#FFFFFF',
        fontSize: '18px',
    },
    thePrimiumTotal: {
        width: '108px',
        height: '50px',
        backgroundColor: '#E5E5E5',
        margin: '8px 12px 0px 0px',
        paddingTop: '12px',
        fontSize: '18px',
    },
    planInfo: {
        display: 'flex',
        margin: '2% 0px 0px 61%',
    },
    yourPlanCopy: {
        margin: '0px 88px 0px 0px',
    },
    cancelMembership: {
        color: '#979797',
        borderBottom: '0.5px solid #979797',
        width: '24%',
        margin: '0 auto !important',
        cursor: 'pointer',
    },
    nameAndPrice: {},
    noMembership: {
        fontSize: '16px',
        marginTop: '30px',
        marginLeft: '18px',
        fontWeight: '700',
        fontFamily: 'AvenirNext',
        width: '100%',
        textAlign: 'left',
        padding: '20px !important',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            marginTop: '20px !important',
            marginLeft: 0,
            padding: 'unset !important',
        },
    },
    noMembership1: {
        fontSize: '22px',
        marginTop: '30px',
        marginLeft: '18px',
        fontWeight: '600',
        fontFamily: 'AvenirNext',
        width: '100%',
        textAlign: 'left',
        padding: '20px 0 0 20px !important',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            marginTop: '20px !important',
            marginLeft: 0,
            padding: 'unset !important',
        },
    },
    contactMemberhip: {
        marginTop: '40px !important',
    },
});

const mapStyle = [
    {
        featureType: 'all',
        elementType: 'labels.text.fill',
        stylers: [
            {
                saturation: 36,
            },
            {
                color: '#333333',
            },
            {
                lightness: 40,
            },
        ],
    },
    {
        featureType: 'all',
        elementType: 'labels.text.stroke',
        stylers: [
            {
                visibility: 'on',
            },
            {
                color: '#ffffff',
            },
            {
                lightness: 16,
            },
        ],
    },
    {
        featureType: 'all',
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'administrative',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#fefefe',
            },
            {
                lightness: 20,
            },
        ],
    },
    {
        featureType: 'administrative',
        elementType: 'geometry.stroke',
        stylers: [
            {
                color: '#fefefe',
            },
            {
                lightness: 17,
            },
            {
                weight: 1.2,
            },
        ],
    },
    {
        featureType: 'landscape',
        elementType: 'geometry',
        stylers: [
            {
                color: '#f5f5f5',
            },
            {
                lightness: 20,
            },
        ],
    },
    {
        featureType: 'landscape.natural.landcover',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#ffffff',
            },
        ],
    },
    {
        featureType: 'landscape.natural.terrain',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#ffffff',
            },
        ],
    },
    {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [
            {
                color: '#f5f5f5',
            },
            {
                lightness: 21,
            },
        ],
    },
    {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [
            {
                color: '#dedede',
            },
            {
                lightness: 21,
            },
        ],
    },
    {
        featureType: 'poi.park',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#b1f1bb',
            },
        ],
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#ffffff',
            },
            {
                lightness: 17,
            },
        ],
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry.stroke',
        stylers: [
            {
                color: '#ffffff',
            },
            {
                lightness: 29,
            },
            {
                weight: 0.2,
            },
        ],
    },
    {
        featureType: 'road.arterial',
        elementType: 'geometry',
        stylers: [
            {
                color: '#ffffff',
            },
            {
                lightness: 18,
            },
        ],
    },
    {
        featureType: 'road.local',
        elementType: 'geometry',
        stylers: [
            {
                color: '#ffffff',
            },
            {
                lightness: 16,
            },
        ],
    },
    {
        featureType: 'transit',
        elementType: 'geometry',
        stylers: [
            {
                color: '#f2f2f2',
            },
            {
                lightness: 19,
            },
        ],
    },
    {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [
            {
                color: '#e9e9e9',
            },
            {
                lightness: 17,
            },
        ],
    },
    {
        featureType: 'water',
        elementType: 'geometry.fill',
        stylers: [
            {
                visibility: 'on',
            },
            {
                color: '#ade8ff',
            },
        ],
    },
];
const mapStyles = {
    width: '100%',
    height: '100%',
};
const containerStyle = {
    position: 'relative',
    width: '100%',
    height: '100%',
    borderTop: '1px solid #d1d1d1',
};

// TODO make this component dynamic - remove repetition
const InstoreBarflyConfirmPage = ({
    classes,
    barflyConfirmData,
    /*    membershipPrice,*/
    preferredShop,
    google,
    updatedCustomer,
    newCard,
    setCustomer,
    setNewCard,
    history,
    membershipId,
    location,
    membershipType,
    blowouts,
    additionalOffers,
    rewardsCardNumber,
    user,
    contentfulStores,
    selectedStore,
    bookerCustomerId,
    setCardAddedWorldpay,
    isCardAdded,
}) => {
    const [open, setOpen] = React.useState(false);
    /*    const price = membershipPrice || 0;*/

    const [firstCheckboxData, setFirstCheckData] = React.useState('');
    const [secondCheckboxData, setSecondCheckData] = React.useState('');
    const [confirmationData, setConfirmationData] = React.useState('');
    const [firstChecked, setFirstChecked] = React.useState(false);
    const [secondChecked, setSecondChecked] = React.useState(false);
    const [validationError, setValidationError] = React.useState(false);
    const [processing, setProcessing] = React.useState(false);

    const _mapLoaded = (mapProps, map) => {
        map.setOptions({
            styles: mapStyle,
        });
    };

    // map center pos
    const initialCenter = {
        lat: preferredShop?.contact?.coordinates?.[0],
        lng: preferredShop?.contact?.coordinates?.[1],
    };

    const [customerMembership, setCustomerMembership] = useState([]);
    const [addOns, setAddOns] = useState([]);
    const [remainingAddOns, setRemainingAddOns] = useState([]);
    const [remainingBlowDries, setRemainingBlowDries] = useState([]);
    const [membershipSignupModal, setMembershipSignupModal] = useState(false);
    const [membershipCollection, setMembershipCollection] = useState([]);
    // const BARFLY_MEMBERSHIP = BarflyMembership();
    // const { data, error, loading } = useQuery(BARFLY_MEMBERSHIP);

    useEffect(async () => {
        const locationId = preferredShop.bookerLocationId;
        console.log(locationId);
        console.log(bookerCustomerId);
        window.scrollTo(0, 0);

        if (locationId && bookerCustomerId) {
            const { data } = await useFetchAsync(
                findCustomerMemberships(locationId, bookerCustomerId)
            );
            if (data?.IsSuccess) {
                const blowDries = data?.Results?.filter(
                    (item) => item.IsBlowdryBenefit
                );
                const addOns = data?.Results?.filter(
                    (item) => item.IsAddOnBenefit
                );
                countRemainingBenefit(blowDries, setRemainingBlowDries);
                countRemainingBenefit(addOns, setRemainingAddOns);
                const activeMembership = blowDries.filter(
                    (item) => item.IsActiveOrSuspended
                );
                if (activeMembership.length > 0) {
                    setCustomerMembership(activeMembership[0]);
                } else {
                    setCustomerMembership(blowDries[0]);
                }
                setAddOns(addOns);
            }
        }
        if (isCardAdded) {
            setCardAddedWorldpay(false);
        }
    }, []);

    const countRemainingBenefit = (benefits, updateState) => {
        var benefitsCount = 0;
        for (var i = 0; i < benefits.length; i++) {
            benefitsCount += benefits?.[i]?.AvailableQuantity;
        }
        updateState(benefitsCount);
    };

    const lastBillDate = new Date(customerMembership?.LastChargeDateOffset)
        ?.toLocaleDateString('en-GB')
        ?.replace(',', '/');
    const nextBillDate = new Date(customerMembership?.NextChargeDateOffset)
        ?.toLocaleDateString('en-GB')
        ?.replace(',', '/');
    const cancellationDate = new Date(customerMembership?.CancelledDate)
        ?.toLocaleDateString('en-GB')
        ?.replace(',', '/');
    const isCustomerMembership =
        customerMembership?.MembershipBenefit?.MembershipLevelID || '';
    const membershipPrice = customerMembership?.TotalFee || '';
    const membershipSingle = customerMembership?.LevelName?.toLowerCase()?.includes(
        'single'
    );

    //if (!updatedCustomer) {
    //    history.push('/instore-barfly-membership-enrollment');
    //}

    return (
        <Grid className={classes.container}>
            <Grid className={classes.titleContainer}>
                <Typography className={classes.mainTitle}>
                    Your Barfly Membership{<br />}Is Complete
                </Typography>
            </Grid>
            <Grid className={classes.container}>
                <Typography className={classes.heading}>
                    Your Membership
                </Typography>
                <Grid className={classes.formContainer}>
                    {customerMembership?.HasAutopay ? (
                        <Grid className={classes.nameAndPrice}>
                            <Typography className={classes.membershipName}>
                                {customerMembership.MembershipName}
                            </Typography>
                            <Typography
                                className={classes.membershipPricePerMonth}
                            >
                                £{customerMembership.TotalFee}/month
                            </Typography>
                        </Grid>
                    ) : null}
                    <Grid container className={classes.billPeriodContainer}>
                        {customerMembership?.IsActiveOrSuspended ? (
                            <>
                                <Grid
                                    item
                                    sm={6}
                                    className={classes.billDateContainer}
                                >
                                    <Typography
                                        className={classes.nextBillDate}
                                    >
                                        Next bill date:{' '}
                                        <strong>{nextBillDate}</strong>
                                    </Typography>
                                    {/*<Typography className={classes.nextBillDate}>*/}
                                    {/*    Last bill date: {lastBillDate}*/}
                                    {/*</Typography>*/}
                                    <Typography
                                        className={classes.nextBillDate}
                                    >
                                        Preferred Shop:{' '}
                                        <strong>
                                            {customerMembership.LocationText}
                                        </strong>
                                    </Typography>
                                </Grid>
                            </>
                        ) : customerMembership?.IsCancelled ? (
                            <>
                                <Grid
                                    item
                                    xs={12}
                                    sm={4}
                                    md={4}
                                    className={classes.billDateContainer}
                                >
                                    <Typography
                                        className={classes.nextBillDate}
                                    >
                                        Preferred Shop:{' '}
                                        {customerMembership.LocationText}
                                    </Typography>
                                    <Typography
                                        className={classes.nextBillDate}
                                    >
                                        Plan Cancelled On: {cancellationDate}
                                    </Typography>
                                    <Typography
                                        className={classes.nextBillDate}
                                    >
                                        Last bill date: {lastBillDate}
                                    </Typography>
                                </Grid>
                            </>
                        ) : (
                            <Typography className={classes.noMembership}>
                                Setting up your membership plan...
                            </Typography>
                        )}
                    </Grid>
                </Grid>
                <Typography className={classes.signatureCopy}>
                    Your Benefits
                    <Grid></Grid>
                </Typography>
                {customerMembership?.HasAutopay && (
                    <Grid className={classes.breflyMembershipBenefits}>
                        <Typography className={classes.availableBenefits}>
                            Available Benefits
                        </Typography>
                        {remainingBlowDries > 0 ? (
                            <div className={classes.benefits}>
                                <div className={classes.bulletPoints}>
                                    {remainingBlowDries}{' '}
                                    {remainingBlowDries === 1
                                        ? ' free blow-dry'
                                        : ' free blow-dries'}
                                </div>
                            </div>
                        ) : null}
                        {remainingAddOns > 0 ? (
                            <div className={classes.benefits}>
                                <div className={classes.bulletPoints}>
                                    {' '}
                                    {remainingAddOns}{' '}
                                    {remainingAddOns === 1
                                        ? ' free add-on'
                                        : ' free add-ons '}
                                </div>
                            </div>
                        ) : null}
                        {membershipSingle
                            ? membershipCollection?.[0]?.benefitsCollection.items.map(
                                  (item) =>
                                      item?.value
                                          ?.toLowerCase()
                                          ?.includes('add-on') ? null : (
                                          <div className={classes.benefits}>
                                              <div
                                                  className={
                                                      classes.bulletPoints
                                                  }
                                              >
                                                  {item.name} {item.value}
                                              </div>
                                          </div>
                                      )
                              )
                            : membershipCollection?.[1]?.benefitsCollection.items.map(
                                  (item) =>
                                      item?.value
                                          ?.toLowerCase()
                                          ?.includes('add-on') ? null : (
                                          <div className={classes.benefits}>
                                              <div
                                                  className={
                                                      classes.bulletPoints
                                                  }
                                              >
                                                  {item.name} {item.value}
                                              </div>
                                          </div>
                                      )
                              )}

                        {additionalOffers.map((item) =>
                            item?.value
                                ?.toLowerCase()
                                ?.includes('add-on') ? null : (
                                <div className={classes.benefits}>
                                    <div className={classes.bulletPoints}>
                                        {item.name} {item.value}
                                    </div>
                                </div>
                            )
                        )}
                    </Grid>
                )}
            </Grid>

            {/*<div className={classes.pricesVary}>*/}
            {/*     <p> *Membership prices may vary depending on location. For HBeauty Barfly members, please note that there will be an additional surcharge to redeem services and treatments in the Drybar Knightsbridge location.</p>*/}
            {/*</div>*/}

            {/*{rewardsCardNumber.length !== 0 &&*/}
            {/* <><Grid>    */}
            {/*     <Typography className={classes.fieldContainerTitle}>HARRODS REWARDS CARD NUMBER</Typography>*/}
            {/*</Grid>*/}
            {/*<Grid className={classes.fieldContainer}>*/}
            {/*    <Grid className={classes.singleLineTextFieldsContainer}>*/}
            {/*    </Grid>*/}
            {/*        <TextField*/}
            {/*            value={rewardsCardNumber}*/}
            {/*            className={classes.textField}*/}
            {/*            InputLabelProps={{*/}
            {/*                shrink: true,*/}
            {/*            }}*/}
            {/*            required={false}*/}
            {/*            disabled={true}                                                       */}
            {/*        />*/}
            {/*</Grid></>}*/}

            {/*<Button*/}
            {/*    variant="contained"*/}
            {/*    color="primary"*/}
            {/*    disabled={processing}*/}
            {/*    className={classes.submitButton}*/}
            {/*    onClick={() => {*/}
            {/*    onCompleteMembership();*/}
            {/*}}*/}
            {/*>*/}
            {/*    Complete Membership*/}
            {/*</Button>*/}

            {/*<div className={classes.signingUp}>*/}
            {/*     <p> By signing up to become a Barfly member, I understand that I will be charged a monthly membership fee until my membership is cancelled and I agree to*/}
            {/*    the <a target='_blank' href="https://www.drybarshops.co.uk/help-centre/membership-policy" >Barfly Membership Terms</a> and&nbsp;*/}
            {/*    <a target='_blank' href="https://www.drybarshops.co.uk/help-centre/privacy-policy" >Drybar Privacy Policy</a> Drybar Privacy Policy.</p>*/}

            {/*</div>*/}

            {/*            <ToastContainer*/}
            {/*                style={{*/}
            {/*maxWidth: 640, top: 50, width: '100%', paddingLeft: 20, paddingRight: 20,*/}
            {/*}}*/}
            {/*                position="top-center"*/}
            {/*                closeButton={false}*/}
            {/*            />*/}

            <Grid>
                <Typography className={classes.summaryTitle}>
                    Verify Your Email Address
                </Typography>
                <Grid>
                    <Typography>
                        Please check your email and follow the instructions to
                        verify your account. Once verifed, you can also set up a
                        new password so that you can manage your Barfly
                        Membership online.
                    </Typography>
                </Grid>
            </Grid>

            <Grid style={{ textAlign: 'center' }}>
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.bookYourAppointmentToday}
                    onClick={() => {
                        localStorage.clear();
                        history.push('/instore-barfly-membership');
                    }}
                >
                    Close
                </Button>
            </Grid>
        </Grid>
    );
};

InstoreBarflyConfirmPage.propTypes = {
    classes: object.isRequired,
    barflyConfirmData: object.isRequired,
    google: object,
};

InstoreBarflyConfirmPage.defaultProps = {
    google: {},
};

const mapDispatchToProps = (dispatch) => ({
    setCustomer: bindActionCreators(setUpdatedCustomer, dispatch),
    setNewCard: bindActionCreators(setCard, dispatch),
    setCardAddedWorldpay: bindActionCreators(setCardAddedWorldpay, dispatch),
});

const mapStateToProps = (state) => ({
    preferredShop: getChosenStore(state),
    membershipPrice: getBarflyMembershipPrice(state),
    selectedStore: getChosenStore(state),
    updatedCustomer: getUpdatedCustomer(state),
    newCard: getNewCard(state),
    membershipId: getBarflyMembershipID(state),
    membershipType: getBarflyMembershipType(state),
    blowouts: getBarflyBlowouts(state),
    additionalOffers: getBarflyAdditionalOffers(state),
    rewardsCardNumber: getRewardsCardNumber(state),
    bookerCustomerId: getBookerCustomerId(state),
    isCardAdded: getCardAddedStatus(state),
});

// export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(withOktaAuth(BarflyConfirmPage)));
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    withRouter(
        GoogleApiWrapper({
            apiKey: 'AIzaSyAixN59eNVJmUFjTac2K4KCB88lM6zP4t4',
        })(withStyles(styles)(InstoreBarflyConfirmPage))
    )
);
