/* eslint-disable react/prop-types */
import {
    Box,
    Button, Grid, Typography, withStyles,
} from '@material-ui/core';
import { object } from 'prop-types';
import React, { useCallback } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getPageLabelFromUrl } from 'utils/helper';
import barflyMembershipHeartIcon from '../../../assets/images/barflyMembershipHeartIcon.svg';
import { MEDIUM_MOBILE_BREAKPOINT, MOBILE_BREAKPOINT, TABLET_BREAKPPOINT } from '../../../Helpers/breakpoints';
import { setBarflyMembership } from '../../../state/ducks/Barfly/Barfly-Actions';
import { getChosenStore } from '../../../state/ducks/Barfly/Barfly-Selectors';

const styles = (theme) => ({
    root: {
        padding: '13px',
        backgroundColor: '#fff',
        width: '100%',
        height: '100%',
        [theme.breakpoints.down(TABLET_BREAKPPOINT)]: {
            flexWrap: 'wrap',
        },

        [theme.breakpoints.down(MEDIUM_MOBILE_BREAKPOINT)]: {
            height: 'auto',
        },
    },
    headerTitleText: {
        border: '3px dotted #fff',
        fontFamily: 'DINCondensed-Regular',
        fontWeight: '500',
        fontSize: '42px',
        padding: '10px',
    },
    headerTitle: {
        backgroundColor: '#E2E2E2',
        padding: '8px',
        textAlign: 'center',
        position: 'relative',
        color: '#42413D',
        width: '100%',
    },
    selectButton: {
        maxWidth: '378px',
        width: '100%',
        height: '63px',
        fontSize: '18px',
        margin: '27px 0 0',
        [theme.breakpoints.down(TABLET_BREAKPPOINT)]: {
            flexWrap: 'wrap',
            margin: '14px 5px',
            maxWidth: 'none',
        },
    },
    currentPlanText: {
        marginTop: '27px !important',
        fontSize: '18px',
        fontWeight: '500',
        [theme.breakpoints.down(TABLET_BREAKPPOINT)]: {
            flexWrap: 'wrap',
            margin: '14px 5px !important',
            maxWidth: 'none',
        },
    },
    slashVector: {
        margin: '0 20px',
        '&::after': {
            content: '""',
            height: '60px',
            transform: 'rotate(20deg)',
            borderRight: '2px solid #42413D',
            position: 'absolute',
        },
    },
    accountSlashVector: {
        margin: '0 20px',
        '&::after': {
            content: '""',
            height: '33px',
            margin: '11px 0px 0px -1px',
            transform: 'rotate(20deg)',
            borderRight: '2px solid #42413D',
            position: 'absolute',
        },
    },
    exclusiveBanner: {
        backgroundColor: '#FFDD30',
        fontFamily: 'MrsEavesOT-Roman',
        fontWeight: '500',
        fontSize: '22px',
        color: '#42413D',
        position: 'relative',
        height: '36px',
        margin: '33px 0',
        display: 'flex',
        alignItems: 'center',
        padding: '0 10px',
        lineHeight: '1',
        [theme.breakpoints.down(TABLET_BREAKPPOINT)]: {
            fontSize: '15px',
        },
        '&::before': {
            content: '""',
            border: '18px solid #FFDD30',
            borderRightWidth: '1.5em',
            left: '-2em',
            borderLeftColor: 'transparent',
            position: 'absolute',
            top: '0',
        },
        '&::after': {
            content: '""',
            border: '18px solid #FFDD30',
            borderLeftWidth: '1.5em',
            right: '-2em',
            borderRightColor: 'transparent',
            position: 'absolute',
            top: '0',
        },
    },
    extraContentDivider: {
        color: '#767676',
        position: 'relative',
        fontWeight: '600',
        margin: '10px',
        '&::after': {
            content: '""',
            backgroundColor: 'rgba(118, 118, 118, 0.7)',
            display: 'inline-block',
            height: '1.5px',
            position: 'relative',
            verticalAlign: 'middle',
            width: '28%',
            left: '0.5em',
            bottom: '2px',
            marginRight: '-50%',
        },
        '&::before': {
            content: '""',
            backgroundColor: 'rgba(118, 118, 118, 0.7)',
            display: 'inline-block',
            height: '1.5px',
            position: 'relative',
            verticalAlign: 'middle',
            width: '28%',
            right: '0.5em',
            bottom: '2px',
            marginLeft: '-50%',
        },
    },
    additionalOffersContainer: {
        width: '50%',
        minWidth: 260,
        maxWidth: 260,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '10px 0',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            width: '100%',
            minWidth: 0,
        },
    },
    additionalOfferAccount: {
        width: '81%',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            width: '100%',
        },
    },
    exclusiveBannerFont: {
        fontSize: '14px',
    },
    priceAccountPadding: {
        padding: '27px',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            marginTop: '0 !important',
        },
    },

    rootInnerWrapper: {
        border: '1px solid #E2E2E2',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        justifyContent: 'space-between',
        paddingBottom: '27px',
    },
    topWrapper: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    titleImg: {
        width: '56px',
        margin: '0 auto',
        top: '-35px',
        left: 0,
        right: 0,
        position: 'absolute',
    },
    membershipSection: {
        margin: '10px 0',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            margin: '5px 0',
        },
    },
    membershipLeftSection: {
        width: '25%',
        marginRight: '23px',
        '& > p': {
            display: 'flex',
            justifyContent: 'flex-end',
            color: '#42413D',
            fontWeight: '800',
            [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
                fontSize: '15px',
            },
        },
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            width: '30%',
        },
    },
    membershipRightSection: {
        '& > p': {
            color: '#42413D',
            [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
                fontSize: '15px',
            },
        },
    },
    priceSection: {
        display: 'flex',
        position: 'relative',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            justifyContent: 'center',
        },
    },
    priceSectionInner: {
        display: 'flex',
        position: 'relative',
    },
    priceText: {
        fontWeight: '800',
        color: '#42413D',
        fontFamily: 'URWForm',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            fontSize: '18px !important',
        },
    },
    monthText: {
        fontSize: '28px',
        fontWeight: '800',
        color: '#42413D',
        fontFamily: 'URWForm',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            fontSize: '15px',
        },
    },
    taxText: {
        fontSize: '18px',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            fontSize: '15px',
        },
    },
});

const BarflyMembershipPriceCard = ({
    classes,
    membershipId,
    priceCardHeaderIcon,
    headerTitle,
    price,
    blowouts,
    additionalOffers = [],
    history,
    location,
    chosenStore,
    setMembership,
    isAccountBarfly,
    onClickWithoutLocation,
    user,
    type,
    isCurrentMembership,
    hasMembership,
    sellMemberships
}) => {
    const selectChosenStore = useCallback(
        () => {
            if (chosenStore.bookerLocationId) {
                setMembership({
                    price,
                    id: membershipId,
                    type,
                    blowouts,
                    additionalOffers
                });
                if (user) {
                    mParticle.logEvent('Account - Initiate Barfly Membership Upgrade', mParticle.EventType.Other, {
                        'Source Page': getPageLabelFromUrl(location.pathname),
                        'Page Name': getPageLabelFromUrl(location.pathname),
                        'Page Category': 'Barfly | Select Membership',
                        'Membership Type': type,
                        'Membership Location': chosenStore.bookerLocationId,
                    });
                    history.push('/barfly-membership-enrollment');
                } else {
                    history.push('/auth/login?redirect=/barfly-membership-enrollment');
                }
            } else {
                onClickWithoutLocation();
            }
        },
        [chosenStore, price, membershipId, user],
    );

    return (
        <Grid className={classes.root}>
            <Grid className={classes.rootInnerWrapper}>
                <Grid className={classes.topWrapper}>
                    <Grid className={classes.headerTitle}>
                        <img
                            src={priceCardHeaderIcon || barflyMembershipHeartIcon}
                            alt="Barfly Membership Heart"
                            className={classes.titleImg}
                        />
                        <Typography className={classes.headerTitleText}>
                            {headerTitle}
                        </Typography>
                    </Grid>
                    <Grid className={isAccountBarfly ? `${classes.exclusiveBannerFont} ${classes.exclusiveBanner}` : classes.exclusiveBanner}>
                        {blowouts}
                    </Grid>
                    {
                        additionalOffers.length > 0
                        && (
                        <>
                            <Typography className={classes.extraContentDivider}>
                                PLUS
                            </Typography>
                            <Grid className={isAccountBarfly ? `${classes.additionalOfferAccount} ${classes.additionalOffersContainer}` : classes.additionalOffersContainer}>
                                {additionalOffers.map((additional) => (
                                    <Grid container className={classes.membershipSection}>
                                        <Grid className={classes.membershipLeftSection}>
                                            <Typography>
                                                {additional.name}
                                            </Typography>
                                        </Grid>
                                        <Grid className={classes.membershipRightSection}>
                                            <Typography>
                                                {additional.value}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    ))}
                            </Grid>
                        </>
)
                    }
                </Grid>
                <Grid className={classes.topWrapper}>
                    <Grid className={isAccountBarfly ? classes.priceAccountPadding : ''} style={{ marginTop: '20px' }}>
                        <Typography className={classes.priceSection} style={{ height: isAccountBarfly ? '60px' : '70px' }}>
                            <Box
                                className={classes.priceSectionInner}
                                style={{
                                height: isAccountBarfly ? '60px' : '70px',
                            }}
                            >
                                <Box
                                    className={classes.priceText}
                                    style={{
                                    fontSize: isAccountBarfly ? '20px' : '32px',
                                }}
                                >
                                    &#163;
                                    {price}
                                </Box>
                                <Box
                                    className={isAccountBarfly ? classes.accountSlashVector : classes.slashVector}
                                    style={{
                                        margin: isAccountBarfly ? '0 10px' : '',
                                    }}
                                />
                                <Box className={classes.monthText}>
                                    month
                                </Box>
                            </Box>
                        </Typography>
                        <Typography className={classes.taxText}>
                             
                        </Typography>
                    </Grid>
                    {!sellMemberships ?  <Button
                                        variant="contained"
                                        color="primary"
                                        className={classes.selectButton}
                                        disabled={true}
                                   >
                                   Coming Soon
                                   </Button> :
                    

                                //If the customer does not have a membership they should be allowed to select the plan.
                                hasMembership?.length===0
                                ? (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className={classes.selectButton}
                                        disabled={false}
                                        onClick={selectChosenStore}
                                    >
                                      Select
                                    </Button>
)
                                : 
                                //If this is the customer's membership let them know.
                                isCurrentMembership? <Typography className={classes.currentPlanText}> Current Plan </Typography>
                                //The customer has a membership but it is not this one.
                                : <Button
                                        variant="contained"
                                        color="primary"
                                        className={classes.selectButton}
                                        disabled={true}
                                   >
                                   Contact to change plan
                                   </Button>
                    }
                </Grid>
            </Grid>
        </Grid>
    );
};

BarflyMembershipPriceCard.propTypes = {
    classes: object.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
    setMembership: bindActionCreators(setBarflyMembership, dispatch),
});

const mapStateToProps = (state) => ({
    chosenStore: getChosenStore(state),
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(withRouter(BarflyMembershipPriceCard)));
