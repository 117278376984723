/* eslint-disable max-len */
import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Hyperlink } from '../../Links/Hyperlink';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, FormControl, Button, Link } from '@material-ui/core';
import Media from 'react-media';
import { toggleConfirmModal } from 'state/ducks/Modal/Modal-Actions';
import { getConfirmModalStatus } from 'state/ducks/Modal/Modal-Selectors';
import ConfirmModal from 'app/Components/Modal/ConfirmModal';
import { CONTAINER_BREAKPOINT, TABLET_BREAKPPOINT } from 'Helpers/breakpoints';
import MobileFooterLinks from './MobileFooterLinks';
import CommonFooterAppStore from './CommonAppFooterStore';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme) => ({
    root: {
        background: '#FFFFFF',
    },
    grayText: {
        color: '#42413D',
    },
    containerFooter: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        padding: '80px 40px',
        maxWidth: '1295px',
        width: '1295px',
        margin: 'auto',
        [theme.breakpoints.down(CONTAINER_BREAKPOINT)]: {
            maxWidth: '100%',
            width: '100%',
        },
        [theme.breakpoints.down(TABLET_BREAKPPOINT)]: {
            padding: '0 28px',
            borderTop: '1px solid #D1D1D1',
        },
    },
    footerLogo: {
        [theme.breakpoints.down('md')]: {
            width: '100%',
            margin: '20px 300px',
        },
    },
    footerCopy: {
        fontSize: '17px',
        letterSpacing: '1.1px',
        fontFamily: 'DINCondensed-Regular',
        color: '#42413D',
        borderBottom: '1px solid #D1D1D1',
        textTransform: 'uppercase',
        marginBottom: '20px !important',
        fontWeight: '500',
        paddingBottom: '15px !important',
        paddingRight: '30px !important',
    },
    footerSubCopy: {
        fontFamily: 'URWForm',
        color: '#42413D',
        textTransform: 'uppercase',
        letterSpacing: '1.00677px',
        margin: '22px 0 !important',
        fontSize: '11px',
        paddingRight: '30px !important',
    },
    footerLinkContainer: {
        margin: '0px 50px 0px 0px',
        // minWidth: '158px',
    },
    footerIcon: {
        margin: '0 10px',
    },
    propValueFooterColor: {
        width: '100%',
    },
    footerCopyValueProp: {
        textAlign: 'left',
        width: '100%',
        fontSize: '15px',
        lineHeight: '18px',
        marginBottom: '20px',
        color: '#42413D',
        [theme.breakpoints.down(TABLET_BREAKPPOINT)]: {
            textAlign: 'center',
            fontSize: '17px',
            lineHeight: '21px',
        },
    },
    enterYourEmail: {
        width: '100%',
        height: '40px',
        marginRight: '30px',
    },
    subscribeEmail: {
        background: '#42413D',
        borderRadius: '0px',
        color: '#FFFFFF',
        fontSize: '13px',
        width: '65px',
        height: '40px',
        textTransform: 'uppercase',
        '&:hover': {
            backgroundColor: 'rgb(58, 60, 62);',
        },
        [theme.breakpoints.down(TABLET_BREAKPPOINT)]: {
            margin: '30px 0',
            width: '100%',
        },
    },
    subscribeEmailButton: {
        fontWeight: 500,
        letterSpacing: '1px',
        lineHeight: '21px',
        fontFamily: 'URWForm',
    },
    subscribeEmailForm: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        [theme.breakpoints.down(TABLET_BREAKPPOINT)]: {
            flexDirection: 'column',
        },
        marginTop: '30px',
    },
    subscribeEmailInput: {
        width: '100%',
        marginRight: '17px',
    },
    input: {
        '&::placeholder': {
            fontStyle: 'oblique',
        },
        [theme.breakpoints.down(TABLET_BREAKPPOINT)]: {
            textAlign: 'center',
        },
    },
    menuSection: {
        // paddingRight: '20px'
    },
    joinUsSection: {
        width: '100%',
        [theme.breakpoints.down(TABLET_BREAKPPOINT)]: {
            maxWidth: '100%',
            flexBasis: '100%',
            margin: '50px 0 40px',
        },
    },
    emailUsHeader: {
        fontFamily: 'DINCondensed-Regular',
        fontSize: '1.7rem',
        fontWeight: '400',
        whiteSpace: 'noWrap',
        color: '#42413D',
        marginBottom: '12px !important',
        paddingLeft: '18px !important',
        [theme.breakpoints.down(TABLET_BREAKPPOINT)]: {
            paddingLeft: '0 !important',
        },
    },
    emailUsHeaderBorder: {
        height: '16px',
        width: '18rem',
        backgroundColor: '#FFDD30',
        marginBottom: '-25px',
    },
    joinusHeaderContainer: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down(TABLET_BREAKPPOINT)]: {
            margin: '20px 0',
            alignItems: 'center',
        },
    },
    joinBtnText: {
        fontFamily: 'URWForm',
        fontWeight: '400',
    },
    helperText: {
        paddingTop: '2px !important',
    },
}));

function DryBarCommonFooter(props) {
    const classes = useStyles();
    const { config, isModalOpen, toggleConfirmModal } = props;
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const renderMailingListSignup = () => (
        <FormControl fullWidth className={classes.subscribeEmailForm}>
            {window.location.href.indexOf('instore') == -1 ? (
                <Button
                    className={classes.subscribeEmail}
                    classes={{ label: classes.subscribeEmailButton }}
                    variant="outlined"
                    disabled={loading}
                    onClick={() => {
                        history.push('/mailing-list-sign-up');
                        window.scrollTo(0, 0);
                    }}
                    type="button"
                >
                    {config?.footer?.emailOption?.button?.label}
                </Button>
            ) : null}
        </FormControl>
    );

    const renderLink = (entry) => {
        const openNew = entry.label.toLowerCase() === 'gift cards';
        if (openNew) {
            return (
                <Hyperlink
                    to={entry.link}
                    className={classes.grayText}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    {entry.icon ? (
                        <img
                            alt="Remy Sharp"
                            src={entry.icon}
                            className={classes.footerIcon}
                        />
                    ) : (
                        entry.label
                    )}
                </Hyperlink>
            );
        }
        return (
            <Hyperlink to={entry.link + '#'} className={classes.grayText}>
                {entry.icon ? (
                    <img
                        alt="Remy Sharp"
                        src={entry.icon}
                        className={classes.footerIcon}
                    />
                ) : (
                    entry.label
                )}
            </Hyperlink>
        );
    };

    return (
        <Grid className={classes.root}>
            <Grid container className={classes.containerFooter}>
                {window.location.href.indexOf('instore') == -1 ? (
                    <Media query={{ maxWidth: TABLET_BREAKPPOINT }}>
                        {(matches) =>
                            matches ? (
                                // refactor JOIN us section to indiviual component
                                <>
                                    <Grid
                                        item
                                        md={2}
                                        className={classes.joinUsSection}
                                    >
                                        <Grid
                                            className={
                                                classes.joinusHeaderContainer
                                            }
                                        >
                                            <Grid
                                                className={
                                                    classes.emailUsHeaderBorder
                                                }
                                            />
                                            <Typography
                                                className={
                                                    classes.emailUsHeader
                                                }
                                            >
                                                {
                                                    config?.footer?.emailOption
                                                        ?.heading
                                                }
                                            </Typography>
                                        </Grid>
                                        <Typography
                                            className={
                                                classes.footerCopyValueProp
                                            }
                                        >
                                            {config?.footer?.emailOption?.body}
                                        </Typography>
                                        {renderMailingListSignup()}
                                    </Grid>
                                    <MobileFooterLinks config={config} />
                                </>
                            ) : (
                                <>
                                    {(config?.footer?.navigation || []).map(
                                        (footer, index) => (
                                            <Grid
                                                key={index}
                                                item
                                                className={classes.menuSection}
                                            >
                                                <Typography
                                                    className={
                                                        classes.footerCopy
                                                    }
                                                >
                                                    {footer.heading}
                                                </Typography>
                                                {/* <Grid> */}
                                                {/*    <Typography */}
                                                {/*        style={footer.styleOverride?.help_copy_sub}>{footer.help_copy_sub?.title ? */}
                                                {/*        <Hyperlink className={classes.grayText} */}
                                                {/*              to={footer.help_copy_sub.link}>{footer.help_copy_sub.title}</Hyperlink> : footer.help_copy_sub}</Typography> */}
                                                {/*    <Typography */}
                                                {/*        style={footer.styleOverride?.help_copy_sub_text}>{footer.help_copy_sub_text?.title ? */}
                                                {/*        <Hyperlink className={classes.grayText} */}
                                                {/*              to={footer.help_copy_sub_text.link}>{footer.help_copy_sub_text.title}</Hyperlink> : footer.help_copy_sub_text}</Typography> */}
                                                {/* </Grid> */}
                                                {footer.entries.map(
                                                    (entry, index) => (
                                                        <Typography
                                                            key={`entry_${index}`}
                                                            className={
                                                                classes.footerSubCopy
                                                            }
                                                        >
                                                            {renderLink(entry)}
                                                        </Typography>
                                                    )
                                                )}
                                            </Grid>
                                        )
                                    )}

                                    <Grid
                                        item
                                        xs={3}
                                        className={classes.joinUsSection}
                                    >
                                        <Grid
                                            className={
                                                classes.joinusHeaderContainer
                                            }
                                        >
                                            <Grid
                                                className={
                                                    classes.emailUsHeaderBorder
                                                }
                                            />
                                            <Typography
                                                className={
                                                    classes.emailUsHeader
                                                }
                                            >
                                                {
                                                    config?.footer?.emailOption
                                                        ?.heading
                                                }
                                            </Typography>
                                        </Grid>
                                        <Typography
                                            className={
                                                classes.footerCopyValueProp
                                            }
                                        >
                                            {config?.footer?.emailOption?.body}
                                        </Typography>
                                        {renderMailingListSignup()}
                                    </Grid>
                                </>
                            )
                        }
                    </Media>
                ) : null}
            </Grid>
            {window.location.href.indexOf('instore') == -1 ? (
                <>
                    <CommonFooterAppStore />
                    {isModalOpen && (
                        <ConfirmModal
                            title="You are subscribed!"
                            content="Your email has been registered successfully."
                        />
                    )}
                </>
            ) : null}
        </Grid>
    );
}

const mapStateToProps = (state) => ({
    isModalOpen: getConfirmModalStatus(state),
});

const mapDispatchToProps = (dispatch) => ({
    toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DryBarCommonFooter);
