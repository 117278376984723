import Snippets from './Snippets';

const TagManager = {
  dataScript(dataLayer) {
    const script = document.createElement('script');
    script.innerHTML = dataLayer;
    return script;
  },
  gtm(args) {
    const snippets = Snippets.tags(args);

    const noScript = () => {
      const noscript = document.createElement('noscript');
      noscript.innerHTML = snippets.iframe;
      return noscript;
    };

    const script = () => {
      const script = document.createElement('script');
      script.innerHTML = snippets.script;
      return script;
    };

    const ga4Script = () => {
      const script = document.createElement('script');
      script.setAttribute('src', snippets.ga4Script);
      script.setAttribute('async', '');
      return script;
    }

    const dataScript = this.dataScript(snippets.dataLayerVar);
    const ga4DataScript = this.dataScript(snippets.ga4DataLayerVar);
    return {
      noScript,
      script,
      ga4Script,
      dataScript,
      ga4DataScript
    };
  },
  initialize({
 gtmId, events = {}, dataLayer, dataLayerName = 'dataLayer', auth = '', preview = '',
}) {
    const gtm = this.gtm({
      id: gtmId,
      events,
      dataLayer: dataLayer || undefined,
      dataLayerName,
      auth,
      preview,
    });
    // if (dataLayer) document.head.appendChild(gtm.dataScript);
    // document.head.insertBefore(gtm.script(), document.head.childNodes[0]);
    // document.body.insertBefore(gtm.noScript(), document.body.childNodes[0]);
    document.head.insertBefore(gtm.ga4DataScript, document.head.childNodes[0]);
    document.head.insertBefore(gtm.ga4Script(), document.head.childNodes[0]);
  },
  dataLayer({ dataLayer, dataLayerName = 'dataLayer' }) {
    // if (window[dataLayerName]) return window[dataLayerName].push(dataLayer)
    const snippets = Snippets.dataLayer(dataLayer, dataLayerName);
    const dataScript = this.dataScript(snippets);
    const ga4DataScript = this.dataScript(snippets);
    //document.head.insertBefore(dataScript, document.head.childNodes[0]);
    //document.head.insertBefore(ga4DataScript, document.head.childNodes[0]);
  },
};

export default TagManager;
