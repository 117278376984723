import React, { Component } from 'react';
import { getRibbonData } from '../../state/ducks/Home/Home-Selectors';
import { connect } from 'react-redux';

import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';
import './home.scss';
import ReactPlayer from 'react-player';
import { getPageLabelFromUrl } from 'utils/helper';
import {
Actions, Subtitle, Title, Avatar, Image, CardHead, CardBody, CardContainer,
} from '../../components/card';

class ContainerOuterBorderView extends Component {
    constructor(props) {
        super(props);

        this.isYoutubeLink = this.isYoutubeLink.bind(this);
        this.state = {
            showVideoModal: false,
            videoUrl: '',
        };
    }

    isYoutubeLink(url) {
        if (url) {
            const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            const match = url.match(regExp);
            return match && match[2].length === 11;
        }
        return false;
    }

    onVideoStart = () => {
        mParticle.logEvent('Videos - Play Video', mParticle.EventType.Other, {
            'Source Page': getPageLabelFromUrl(this.props.location.pathname),
            'Video Url': this.state.videoUrl,
        });
    }

    render() {
        if (!this.props.ribbonData.items) {
            return <div />;
        }

        return (
            <div>
                <div className="container">
                    <div className="outer_border">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="main_posiztions">
                                    <div className="bg_strip"><p>{this.props.ribbonData.title}</p></div>
                                </div>
                            </div>
                            {this.props.ribbonData.items.map((item, index) => (
                                <div className="col-md-4 col-sm-12" key={index}>
                                    <CardContainer data={item} className="first_box">
                                        <CardHead className="icon_area">
                                            <Avatar className="icon_area" />
                                            {
                                                this.isYoutubeLink(item?.action?.link)
                                                    ? (
                                                        <Image
                                                            className="w-100"
                                                            onClick={() => this.setState({
                                                               showVideoModal: true,
                                                               videoUrl: item?.action?.link,
                                                           })}
                                                        />
)
                                                    : <Image className="w-100" />
                                            }

                                        </CardHead>
                                        <CardBody className="heading_and_details">
                                            <Title className="h3-responsive" />
                                            <Subtitle />
                                            {
                                                this.isYoutubeLink(item?.action?.link)
                                                    ? (
                                                        <Actions
                                                            className="book_lnk"
                                                            showModal={() => this.setState({
                                                                 showVideoModal: true,
                                                                 videoUrl: item?.action?.link,
                                                             })}
                                                        />
)
                                                    : <Actions className="book_lnk" data={item}/>
                                            }
                                        </CardBody>
                                    </CardContainer>
                                </div>
))}
                        </div>

                        <Dialog
                            open={this.state.showVideoModal}
                            onClose={() => this.setState({ showVideoModal: false })}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            maxWidth="md"
                        >
                            <DialogTitle id="alert-dialog-title" className="">
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <button onClick={() => this.setState({ showVideoModal: false })} style={{ margin: '-6px -20px 0px 64px', cursor: 'pointer' }}>
                                        <CloseIcon />
                                    </button>
                                </div>
                            </DialogTitle>
                            <DialogContent className="dialog-content">
                                <DialogContentText id="alert-dialog-description">
                                    <div className="video-wrapper">
                                        {/* <video loop autoPlay className='video-player'> */}
                                        {/*    <source src={this.state.videoUrl}/> */}
                                        {/* </video> */}
                                        <ReactPlayer
                                            url={this.state.videoUrl}
                                            className="video-player"
                                            onStart={this.onVideoStart}
                                        />
                                    </div>
                                </DialogContentText>
                            </DialogContent>
                        </Dialog>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    ribbonData: getRibbonData(state),
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ContainerOuterBorderView);
