/* eslint-disable import/prefer-default-export */

import accountActionTypes from './Account-ActionTypes';

export const setViewAppointment = (state) => ({
    type: accountActionTypes.ACCOUNT_VIEW_APPOINTMENT,
    payload: state,
});

export const setNextAppointmentDate = (state) => ({
    type: accountActionTypes.ACCOUNT_NEXT_APPOINTMENT_DATE,
    payload: state,
});

export const setFavoriteStore = (state) => ({
    type: accountActionTypes.SET_FAVORITE_STORE,
    payload: state,
});

export const setAuthUserDetails = (data) => ({
    type: accountActionTypes.SET_AUTH_USER_DETAILS,
    payload: data,
});
