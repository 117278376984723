import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Hyperlink } from '../../app/Components/Links/Hyperlink';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { getHeroData } from '../../state/ducks/Home/Home-Selectors';

import {
    Actions,
    Subtitle,
    Title,
    Image,
    CardHead,
    CardBody,
    CardContainer,
} from '../../components/card';
import arrowLeftIcon from '../../assets/images/arrow-left-1.svg';
import arrowRightIcon from '../../assets/images/arrow-right-1.svg';

const NextArrow = (props) => {
    const { onClick } = props;
    return (
        <button className="slick-arrow right" onClick={onClick}>
            <img src={arrowLeftIcon} alt="Slick Arrow Right" />
        </button>
    );
};
const PrevArrow = (props) => {
    const { onClick } = props;
    return (
        <button className="slick-arrow left" onClick={onClick}>
            <img src={arrowRightIcon} alt="Slick Arrow Left" />
        </button>
    );
};

const SliderSectionView = (props) => {
    const {
        heroData: { items, settings },
    } = props;
    const [isMobile, setMobile] = useState(false);

    useEffect(() => {
        function handleResize() {
            setMobile(window.innerWidth < 768);
        }

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const slideContent = (key) => (
        <CardBody key={key} className="text-center text-link-container">
            <CardBody className="text-link-inner">
                <Title className="h3-responsive" />
                <Subtitle />
                <Actions className="book_btn" />
            </CardBody>
        </CardBody>
    );

    const slickSettings = {
        ...(settings?.settings?.slider || {}),
        responsive: [
            {
                breakpoint: '767px',
                settings: {
                    arrows: false,
                },
            },
        ],
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
    };

    return items ? (
        <div className="slider_section">
            <Slider {...slickSettings}>
                {items.map((item, index) => {
                    const link = item && item.action ? item.action.link : null;

                    return link ? (
                        <div key={`slider-section-view-${index}`}>
                            {!isMobile ? (
                                <CardContainer
                                    data={item}
                                    key={`slide-hero-${index}`}
                                    className="slider-inner"
                                >
                                    <CardHead
                                        className="view"
                                        key={`slide-head-${index}`}
                                    >
                                        <Image className="d-block w-100 hero-image" />
                                    </CardHead>
                                    {slideContent(`slide-content-${index}`)}
                                </CardContainer>
                            ) : (
                                <Hyperlink
                                    to={link + '#'}
                                    key={`slide-hero-${index}`}
                                >
                                    <CardContainer
                                        data={item}
                                        className="slider-inner"
                                    >
                                        <CardHead
                                            className="view"
                                            key={`slide-head-${index}`}
                                        >
                                            <Image className="d-block w-100 hero-image" />
                                        </CardHead>
                                        {slideContent(`slide-content-${index}`)}
                                    </CardContainer>
                                </Hyperlink>
                            )}
                        </div>
                    ) : (
                        <CardContainer
                            data={item}
                            key={`slide-hero-${index}`}
                            className="slider-inner"
                        >
                            <CardHead
                                className="view"
                                key={`slide-head-${index}`}
                            >
                                <Image className="d-block w-100 hero-image" />
                            </CardHead>
                            {slideContent(`slide-content-${index}`)}
                        </CardContainer>
                    );
                })}
            </Slider>
        </div>
    ) : (
        <></>
    );
};

const mapStateToProps = (state) => ({
    heroData: getHeroData(state),
});

export default connect(mapStateToProps)(SliderSectionView);
