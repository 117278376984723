import React, { useEffect, useState } from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import DateMonthCard from './DateMonthCard';
import { TABLET_BREAKPPOINT, MOBILE_BREAKPOINT } from '../../../Helpers/breakpoints';
import { getNextAppointmentDate, getOktaUserInfo } from '../../../state/ducks/Account/Account-Selectors';
/* eslint-disable react/prop-types */

const styles = (theme) => ({
    container: {
        borderBottom: '1px solid #D1D1D1',
        backgroundColor: '#fff',
    },
    greetingContainer: {
        height: '189px',
        backgroundColor: '#F9F9F9',
        [theme.breakpoints.down(TABLET_BREAKPPOINT)]: {
            height: '100px'
        },
        
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
        height: '89px'
    },
    },
    widthController: {
        maxWidth: '1400px',
        padding: '29px 38px',
        display: 'flex',
        alignItems: 'center',
        margin: 'auto',
        height: '100%',
    },
    greetingText: {
        fontSize: '30px',
        marginLeft: '80px',
        [theme.breakpoints.down(TABLET_BREAKPPOINT)]: {
            fontSize: '22px',
            marginLeft: '13px',
            whiteSpace: 'nowrap',
        },
    },
    borderedDiv: {
        border: '1px solid #D1D1D1',
        height: '100%',
        margin: '0 75px',
    },
    appointmentDiv: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    nextAppointmentCopy: {
        fontSize: '13px',
        marginBottom: '11px !important',
        whiteSpace: 'nowrap',
    },
});

const TopGreetingRow = ({ user, classes, nextAppointmentDate }) => (
    <Grid className={classes.container}>
        <Grid className={classes.greetingContainer}>
            <Grid className={classes.widthController}>
                <Typography className={classes.greetingText}>
                    {`Hi ${user.given_name}`}
                </Typography>
                <Grid className={classes.borderedDiv} />
                {nextAppointmentDate && (
                <Grid className={classes.appointmentDiv}>
                    <Typography className={classes.nextAppointmentCopy}>
                        Next Appointment
                    </Typography>
                    <DateMonthCard value={new Date(nextAppointmentDate)} />
                </Grid>
)}
            </Grid>
        </Grid>
    </Grid>
);

const mapStateToProps = (state) => ({
    nextAppointmentDate: getNextAppointmentDate(state),
    user: getOktaUserInfo(state),
});

export default connect(mapStateToProps)(withStyles(styles)(TopGreetingRow));
