/* eslint-disable max-len */
import {
    Button,
    Grid,
    Tooltip,
    Typography,
    withStyles,
} from '@material-ui/core';
import {
    bool, func, number, object, string,
} from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import InfoIcon from '@material-ui/icons/Info';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getPageLabelFromUrl } from 'utils/helper';
import { setBookingService } from '../../../../state/ducks/Booking/Booking-Actions';
import { getBuyoutLocation, getServicesData } from '../../../../state/ducks/Booking/Booking-Selectors';

import TreatmentDetailsModal from '../../../../app/Components/TreatmentDetailsModal';
import { productionInformationByReference, getProductInfoFromBooker } from '../../../../gql/queries/productCollection';
import { doQuery } from '../../../../state/utils/contentful';

const styles = (theme) => ({
    buttonsWrapper: {       
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        padding: '60px',
        [theme.breakpoints.down('sm')]: {
            height: 'unset',
            padding: '40px'
        }
    },
    button: {
        width: '438px',
        height: '73px',
        margin: '12px 28px',
        backgroundColor: theme.palette.common.white,
        textTransform: 'none',
        fontSize: '18px',
        padding: '14px',
        [theme.breakpoints.down('sm')]: {
            margin: '12px 0',
            height: '70px',
            fontSize: '16px'
        },
        "&:hover": {
            backgroundColor: 'rgba(255, 221, 48, 0.85)'
        }
    },
    selected: {
        fontWeight: '800',
    },
    serviceName: {
        fontSize: '18px',
        // fontWeight: '800',
    },
    selectedServiceName: {
        fontSize: '18px',
        fontWeight: '800',
        [theme.breakpoints.down('sm')]: {
            fontSize:'15px'
        }
    },
    icon: {
        position: 'absolute',
        top: 'calc(50% - 15px)',
        left: 0,
        fontSize: '20px',
        color: theme.palette.common.grey,
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            margin: '5px 18px 0px 15px',
        },
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        paddingLeft: '50px',
        [theme.breakpoints.down('sm')]: {
            margin: '0 auto',
            maxWidth: '100%',
            width: '402px',
            height: '80px',
            paddingLeft: '45px'
        },
    },
    tooltipService: {
        /*position: 'absolute',
        fontSize: '20px',
        left: 'calc(50% - 270px)',
        top: '50%',
        transform: 'translateY(-50%)',*/
    },
});

const ServiceButton = ({
    isSelected,
    classes,
    service,
    differentServicesSelected,
    guests,
    setSelectedService,
    selectedGuest,
    setAllToSameService,
    goToNextPage,
    fromBuyout,
    hidden,
    selectedServices,
}) => {
    const [detailsModal, setDetailsModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [productForPopup, setProductForPopup] = useState(null);
    const location = useLocation();

    // const getDetails = (serviceBookerProductID) => {
    //     setLoading(true);
    //     const PRODUCT_ADD_ON_QUERY = productionInformationByReference(serviceBookerProductID);
    //     doQuery(PRODUCT_ADD_ON_QUERY)
    //         .then((data) => {
    //             console.log('data', data);
    //             setLoading(false);
    //             setProductForPopup(data?.productCollection?.items?.[0] || null);
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //             setLoading(false);
    //         });
    // };

    const getDetailsNew = (serviceBookerProductID) => {
        setLoading(true);
        const PRODUCT_ADD_ON_QUERY = getProductInfoFromBooker(serviceBookerProductID);
        doQuery(PRODUCT_ADD_ON_QUERY, true)
            .then((data) => {
                const item = data?.drybarGenericGridCardCollection?.items?.[0];

                const newData = {
                    title: item.title,
                subtitle: item.subtitle,
                price: item.price,
                productId: item.productId ? item?.productId : item?.sandboxProductId,
                slug: item.slugPrefix,
                description: {
                    json: {
                        content: [
                            {
                                content: [
                                    {
                                        value: item.description,
                                        nodeType: 'text',
                                    },
                                ],
                                nodeType: 'paragraph',
                            },
                        ],
                        nodeType: 'document',
                    },
                },
                bestFor: item.bestFor?.join(', '),
                imagesCollection: {
                    items: item?.imagesCollectionCollection?.items?.map(image => {
                        return {
                            desktopMedia: {
                                url: image.desktopImageUrl
                            },
                            mobileMedia: {
                                url: image.mobileImageUrl
                            }
                        }
                    })
                }
                }

                setLoading(false);
                setProductForPopup(newData || null);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    };

    const onOpenDetail = () => {
        setDetailsModal(true);
    };

    useEffect(() => {
        getDetailsNew(service.BillableItemID);
    }, []);

    useEffect(() => {
        if (detailsModal) {
            mParticle.logEvent('Opens Services Modal', mParticle.EventType.Other, {
                'Source Page': getPageLabelFromUrl(location.pathname),
                'Service Name': service.Name,
            });
        }
    }, [detailsModal]);


    const onSelect = useCallback(
        async () => {

            if (guests) {
                if (!differentServicesSelected) {
                    await setAllToSameService(service);
                    goToNextPage();
                } else {
                    await setSelectedService({
                        user: selectedGuest || 'Me',
                        data: service,
                    });          

                    const usedGuests = [];

                    [...selectedServices, { user: selectedGuest || 'Me' }].forEach((u) => {
                        if (!usedGuests.includes(u.user)) {
                            usedGuests.push(u.user);
                        }
                    });

                    if (usedGuests.length === guests + 1) {
                        goToNextPage();
                    }
                }
            } else {
                await setSelectedService({
                    user: selectedGuest || 'Me',
                    data: service,
                });

                goToNextPage();
            }
        },
        [guests, selectedGuest, service, setSelectedService, goToNextPage, setAllToSameService],
    );

    useEffect(() => {
        if (fromBuyout) {
            onSelect();
        }
    }, [fromBuyout, onSelect]);

    //if (loading) {
        // if(hidden === true) {
        //     return <></>
        // }
        // else
        //     return (
        //         // todo - replace with skeleton
        //         <Backdrop
        //             open
        //             style={{
        //                 zIndex: 11,
        //                 color: '#fff',
        //             }}
        //         >
        //             <CircularProgress color="inherit"/>
        //         </Backdrop>
        //     );
   //}

    return (
        <>
            {detailsModal ? (
                <TreatmentDetailsModal
                    onClose={() => setDetailsModal(false)}
                    productsForPopup={productForPopup}
                />
            ) : null}
            <Grid className={classes.buttonContainer}>
                {
                    productForPopup
                    && (
                    <Tooltip
                        disableHoverListener
                        placement="left-start"
                        className={classes.tooltipService}
                        onClick={() => onOpenDetail()}
                    >
                        <InfoIcon className={classes.icon} />
                    </Tooltip>
)
                }
                <Button
                    onClick={onSelect}
                    className={`${classes.button} ${isSelected ? classes.selected : ''}`}
                    variant="outlined"
                >
                    <Grid>
                        <Typography className={isSelected ? classes.selectedServiceName : classes.serviceName}>
                            {service.Name}
                        </Typography>
                        <Typography>
                            {
                                !differentServicesSelected && guests
                                    ? `£${service.Price.Amount}`
                                    : `£${service.Price.Amount}`
                            }
                        </Typography>
                    </Grid>
                </Button>
            </Grid>
        </>
    );
};

ServiceButton.propTypes = {
    classes: object.isRequired,
    isSelected: bool,
    service: object.isRequired,
    differentServicesSelected: bool,
    guests: number,
    setSelectedService: func.isRequired,
    selectedGuest: string,
    setAllToSameService: func,
    goToNextPage: func,
};

ServiceButton.defaultProps = {
    differentServicesSelected: true,
    guests: 0,
    selectedGuest: 'Me',
    isSelected: false,
    setAllToSameService: () => {
    },
    goToNextPage: () => {
    },
};

const mapStateToProps = (state) => ({
    fromBuyout: getBuyoutLocation(state),
    selectedServices: getServicesData(state),
});

const mapDispatchToProps = (dispatch) => ({
    setSelectedService: bindActionCreators(setBookingService, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ServiceButton));
