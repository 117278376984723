import {useState} from 'react'
import {Grid, Typography, TextField, Button, Backdrop, CircularProgress} from '@material-ui/core'
import SectionTitle from 'app/Components/SectionTitle';
import { EMAIL_REGEX } from 'utils/validation/regex';
import { resendVerificationEmail } from 'api/auth-api';
import SuccessModal from '../Registration/Partials/RegistrationSuccessModal';
import restClient from 'api/restClient';

const ResendVerificationEmail = ({classes, failureReason}) => {

    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);
    const [generalError, setGeneralError] = useState(null);
    const [touched, setTouched] = useState(false);
    const [tryValidate, setTryValidate] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const handleEmailChange = (e) => {
        const newEmail = e.target.value;

        if (tryValidate) {
            setError(getErrors(newEmail));
        }
        setTouched(true);
        setEmail(newEmail);
    }

    const getErrors = (email) => {
        if (!email?.length > 0) {
            return 'Email is required'
        }
        if (!email.match(EMAIL_REGEX)) {
            return 'Please enter a valid email address';
        }

        return null;
    }

    const handleBlur = () => {
        if (touched) {
            setTryValidate(true);
            setError(getErrors(email))
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setTryValidate(true);

        const newError = getErrors(email);

        if (newError?.length > 0) {
            setError(newError);
            return;
        }

        setLoading(true);
        restClient.post(resendVerificationEmail(email))
            .then(res => {
                setLoading(false);
                setShowSuccessModal(true);
                setGeneralError(null);
            })
            .catch(err => {
                setLoading(false);
                setGeneralError('An error ocurred, please check your email and try again');
            });
    }

    const onCloseSuccessModal = () => {
        setShowSuccessModal(false);
        setEmail('');
    }

    return (
        <>
            <Grid container className={classes.landingSection}>
                <SectionTitle title="EMAIL VERIFICATION FAILED" />
                <Grid className={classes.landingInner}>
                    <Grid className={classes.textSection}>
                        <Typography variant='h5' className={classes.resendEmailSubTitle}>
                            The email verification failed{failureReason ? `: ${failureReason}` : ''}
                        </Typography>
                        <Typography>
                            Please enter your email address to resend the verification email.
                        </Typography>
                    </Grid>
                    <form onSubmit={handleSubmit}>
                        <Grid>
                         <TextField
                                    error={!!error}
                                    helperText={error}
                                    value={email}
                                    onChange={(e) => handleEmailChange(e)}
                                    onBlur={handleBlur}
                                    name="email"
                                    className={classes.emailField}
                                    placeholder={'Your email here...'}
                                />
                        </Grid>
                        <Button type='submit' variant='outlined' className={classes.resendEmailButton}>
                            Resend Verification Email
                        </Button>
                    </form>
                    {generalError ?
                        <Typography className={classes.errorText}>
                            {generalError}
                        </Typography>
                    : null}
                </Grid>
            </Grid>
            {loading && 
                <Backdrop className={classes.backdrop} open>
                    <CircularProgress color='inherit'/>
                </Backdrop>}
            {showSuccessModal && <SuccessModal openModal={showSuccessModal} onClose={onCloseSuccessModal} classes={classes} createdEmail={email} />}
        </>
    );
}

export default ResendVerificationEmail;