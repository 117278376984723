import { useEffect, useState } from 'react';
import {
    Typography,
    withStyles,
    Grid,
    Box,
    Checkbox,
    FormControlLabel,
    Button,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import restClient from '../../../api/restClient';
import { getCustomerCreditCards } from '../../../api/booking-api';
import { styles } from './ManageCards.styles';
import { CreditCard } from '../../../components/CreditCard/CreditCard';
import BackdropCircularProgress from '../../../app/Components/common/BackdropCircularProgress';
import CommonModal from '../../../app/Components/Modal/CommonModal';
import { getCardAddedStatus } from '../../../state/ducks/Worldpay/Worldpay-Selectors';
import { toggleWorldpay } from '../../../state/ducks/Worldpay/Worldpay-Actions';
import { getCards } from '../../../state/ducks/Booking/Booking-Selectors';
import { setCards } from '../../../state/ducks/Booking/Booking-Actions';
import { deleteCreditCard } from '../../../api/booking-api';

const ManageCardsContainer = ({
    user,
    toggleWorldpay,
    isCardAdded,
    classes,
    setCards,
    cards,
}) => {
    const [loading, setLoading] = useState(false);
    const [modalContent, setModalContent] = useState({});

    const formatExpiryDate = (dateStr) => {
        const date = new Date(dateStr);
        return `${date
            .getDay()
            .toString()
            .padStart(2, '0')}/${date
            .getFullYear()
            .toString()
            .substring(date.getFullYear().toString().length, 2)}`;
    };

    async function fetchCards() {
        try {
            setLoading(true);
            if (!user?.bookerID || !user?.locationId) {
                return;
            }

            const { data, status } = await restClient.post(
                getCustomerCreditCards(user.bookerID, user.locationId)
            );

            if (status !== 200) {
                throw new Error(
                    `Received the following HTTP status code: ${status} and response payload: ${JSON.stringify(
                        data
                    )} when requesting payment cards.`
                );
            }

            setCards(data.CreditCards);
            setLoading(false);
        } catch (error) {
            // TODO: We should log errors here once we have a logging solution.
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchCards();
    }, []);

    useEffect(() => {
        // Only re-fetch if a card was added and we need to update userCards.
        // Otherwise, we'll cause an unnecessary fetch and re-render.
        if (isCardAdded === true) {
            fetchCards();
        }
    }, [isCardAdded]);

    return (
        <Box className={classes.container}>
            {loading && <BackdropCircularProgress />}
            <Typography className={classes.heading}>
                Your Saved Cards
            </Typography>
            <Grid container spacing={3} className={classes.cardsContainer}>
                {cards?.map((card) => (
                    <Box className={classes.cardContainer}>
                        <CreditCard maskedCardNumber={card.CreditCard.Number} />
                        <Box className={classes.cardDetailsContainer}>
                            <Typography className={classes.expiryDateLabel}>
                                Expiry Date
                            </Typography>
                            <Box className={classes.expiryNumberContainer}>
                                <Typography
                                    className={classes.expiryDateText}
                                    data-testid="expiration-date"
                                >
                                    {formatExpiryDate(
                                        card.CreditCard.expirationDate
                                    )}
                                </Typography>
                                {card.IsExpired && (
                                    <Typography
                                        className={classes.expiredText}
                                        data-testid="expired-text"
                                    >
                                        Expired
                                    </Typography>
                                )}
                            </Box>
                            <FormControlLabel
                                disabled
                                checked={card.IsDefault}
                                classes={{
                                    root: classes.labelRootDefaultCard,
                                }}
                                control={
                                    <Checkbox
                                        className={classes.tickSize}
                                        name="defaultCard"
                                        inputProps={{
                                            'data-testid':
                                                'is-default-checkbox',
                                        }}
                                    />
                                }
                                label={
                                    <Typography
                                        className={classes.defaultCardLabel}
                                    >
                                        Default Card
                                    </Typography>
                                }
                            />
                            <FormControlLabel
                                className={classes.barflyPaymentCheckbox}
                                disabled
                                checked={card.isMembershipDefault}
                                classes={{
                                    root: classes.labelRootBarflyPayment,
                                }}
                                control={
                                    <Checkbox
                                        className={classes.tickSize}
                                        name="barflyPayment"
                                        inputProps={{
                                            'data-testid':
                                                'barfly-payment-checkbox',
                                        }}
                                    />
                                }
                                label={
                                    <Typography
                                        className={classes.defaultCardLabel}
                                    >
                                        Barfly Payment
                                    </Typography>
                                }
                            />
                            <Box className={classes.deleteButtonContainer}>
                                <Button
                                    className={classes.deleteButton}
                                    variant="outlined"
                                    data-testid="delete-card"
                                    onClick={async () => {
                                        setModalContent({
                                            visible: true,
                                            title: 'Delete Card',
                                            msg: `Are you sure you want to delete your card ${card.CreditCard.Number}?`,
                                            button: 'OK',
                                            secondaryText: 'Cancel',
                                            onPrimaryClick: async () => {
                                                try {
                                                    setLoading(true);
                                                    const response = await restClient.post(
                                                        deleteCreditCard(
                                                            user.bookerID,
                                                            user.locationId,
                                                            card.ID,
                                                            user.email
                                                        )
                                                    );
                                                    await fetchCards();
                                                    setLoading(false);
                                                } catch (e) {
                                                    if (
                                                        e.response.data
                                                            .message ===
                                                        'Has future booking associated with card.'
                                                    ) {
                                                        setModalContent({
                                                            visible: true,
                                                            title:
                                                                'Delete Card',
                                                            msg:
                                                                'You cant delete a card associated with an upcoming appointment.',
                                                            button: 'OK',
                                                        });
                                                    } else if (
                                                        e.response.data
                                                            .message ===
                                                        'Has Barfly subscription associated with card.'
                                                    ) {
                                                        setModalContent({
                                                            visible: true,
                                                            title:
                                                                'Delete Card',
                                                            msg:
                                                                'You cant delete a card linked to your Barfly subscription. Please select another card for your Barfly payments before you delete this.',
                                                            button: 'OK',
                                                        });
                                                    }
                                                    setLoading(false);
                                                }
                                            },
                                        });
                                    }}
                                >
                                    Delete Card
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                ))}
            </Grid>
            <Box className={classes.addAnotherCardContainer}>
                <Button
                    className={classes.addAnotherCardButton}
                    variant="outlined"
                    onClick={() => {
                        // set cards for credit card block.
                        // setCards(userCards);
                        toggleWorldpay();
                    }}
                    data-testid="add-another-card"
                >
                    Add New Card
                </Button>
            </Box>
            {modalContent.visible && (
                <CommonModal
                    {...modalContent}
                    onClose={() => setModalContent({ visible: false })}
                />
            )}
        </Box>
    );
};

const mapStateToProps = (state) => ({
    isCardAdded: getCardAddedStatus(state),
    cards: getCards(state),
});

const mapDispatchToProps = (dispatch) => ({
    toggleWorldpay: bindActionCreators(toggleWorldpay, dispatch),
    setCards: bindActionCreators(setCards, dispatch),
});

export default withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(ManageCardsContainer)
);
