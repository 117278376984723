/* eslint-disable import/no-cycle */
/* eslint-disable max-len */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { CircularProgress, Backdrop } from '@material-ui/core';
import ConnectedServiceLocatorDetail from './ServiceLocatorDetail';
import {
    gqlLoadStores,
} from '../../../../gql/queries/storeLocator';

import './service-locator-detail.scss';

const ServiceLocatorDetailContainer = () => {
    const [store, setStore] = useState({});
    const [loading, setLoading] = useState(false);

    const pathArray = window.location.pathname.split('/');

    let slug = null;
    if (pathArray.length > 0) {
        slug = pathArray[pathArray.length - 1];
    }

    useEffect(async () => {
        if (slug) {
            setLoading(true);
            const stores = await gqlLoadStores(slug);
            setStore(stores?.[0]);
            setLoading(false);
        }
    }, [slug]);

    if (loading) {
        return (
            // todo - replace with skeleton
            <Backdrop
                open
                style={{
                    zIndex: 11,
                    color: '#fff',
                }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    }

    if (store) {
        return (
            <div>
                <ConnectedServiceLocatorDetail storeLocatorData={store} />
            </div>
        );
    }
    return null;
};

export default ServiceLocatorDetailContainer;
