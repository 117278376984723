import React from 'react';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch } from 'react-instantsearch-dom';
import config from '../app.config';

const algoliaClient = algoliasearch(
   config.algolia.appId,
   config.algolia.apiKey,
);

const searchClient = {
  search(requests) {
    const shouldSearch = requests.some(({ params: { query } }) => query !== '');
    if (shouldSearch) {
      return algoliaClient.search(requests);
    }
    return Promise.resolve({
      results: [{ hits: [] }],
    });
  },
  searchForFacetValues: algoliaClient.searchForFacetValues,
};

const AlgoliaSearch = ({ indexName, children, ...rest }) => (
    <InstantSearch indexName={indexName} searchClient={searchClient} {...rest}>
        {children}
    </InstantSearch>
);

export default AlgoliaSearch;
