import { useEffect } from 'react';
/**
 * usekeyPress
 * @param {string} key - the name of the key to respond to, compared against event.key
 * @param {function} action - the action to perform on key press
 */

 export default function useKeypress(key, action) {
     useEffect(() => {
         function onKeyup(e) {
             if (e.key === key) action();
         }
         document.addEventListener('keydown', onKeyup);
         return () => document.removeEventListener('keyup', onKeyup);
     }, []);
 }
