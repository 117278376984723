import { doQuery } from '../../state/utils/contentful';
import {
    queryStylesCollection,
    queryStylesExceptGallery,
    fetchBannerData,
    queryStyling,
    queryCarousel,
} from './contentfulTheStylesQueries';

const parseStylesCollection = (data) => {
    const stylesCollectionData = {
        title: '',
        description: '',
        id: '',
        marketingSectionId: '',
        slug: '',
    };
    try {
        const items = data.stylesCollection?.items || [];
        if (items.length > 0) {
            stylesCollectionData.id = items[0].sys?.id || '';
            stylesCollectionData.title = items[0].title || '';
            stylesCollectionData.slug = items[0].slug || '';
            stylesCollectionData.description = items[0].description?.json?.content[0]?.content[0]?.value || '';
            stylesCollectionData.marketingSectionId = items[0].marketingComponentsCollection?.items[0].sys?.id || '';
        }
    } catch (err) {
        console.error(err);
    }
    return stylesCollectionData;
};

const parseStylesExceptGallery = (data) => {
    const stylesData = [];
    try {
        const items = data.styles?.stylesCollection?.items || [];
        items.forEach((item) => {
            const styleData = {
                title: item.title,
                subtitle: item.subtitle,
                slug: item.slug,
                featuredVideo: item.featuredVideo?.desktopUrl || '',
                featuredImage: item.featuredImage || {},
                featured360Gif: item.featured360Gif || {},
                galleryCollectionIds: (item.galleryCollection?.items || [])
                    .filter((item) => item?.sys?.id)
                    .map((item) => item?.sys?.id),
            };
            stylesData.push(styleData);
        });
    } catch (err) {
        console.error(err);
    }
    return stylesData;
};

export const gqlLoadTheStyles = async () => {
    const theStylesSectionData = {
        title: '',
        description: '',
        heroImage: {},
        styles: [],
        metadata: {},
        buttonCta: {},
    };

    const newData = await doQuery(queryStyling(), true);
    const pageData = newData?.drybarPageCollection?.items?.[0];
    const bannerId = pageData?.formatofPageCollection?.items?.find(
        (c) => c.__typename === 'HeroBanner'
    )?.sys?.id;
    const banner = await doQuery(fetchBannerData(bannerId), true);
    const bannerData = banner?.heroBanner?.mediaCollection?.items?.[0];
    const buttonCta = pageData?.formatofPageCollection?.items?.find(
        (c) => c.__typename === 'ButtonCta'
    );
    const carousels = [];
    const carouselIds = pageData?.formatofPageCollection?.items
        ?.filter((c) => c.__typename === 'Carousel')
        ?.map((x) => x?.sys?.id);

    for (const id of carouselIds) {
        const carouselWithVideo = await doQuery(queryCarousel(id), true);
        carousels.push(carouselWithVideo?.carousel);
    }

    const ribbon = carousels?.find((c) => c.format?.includes('Ribbon'));
    let stylesCarousels = carousels?.filter(
        (c) => !c.format?.includes('Ribbon')
    );

    stylesCarousels = stylesCarousels?.map((style) => {
        const [generalSlide, ...rest] = style?.slidesCollection?.items;

        return {
            title: generalSlide?.title,
            subtitle: generalSlide?.subtitle,
            slug: style?.slugPrefix,
            galleryCollectionIds: rest?.map((r) => r?.slugPrefix),
            gallery: rest?.map((r) => {
                return {
                    title: '',
                    images: [
                        {
                            desktopMedia: {
                                url:
                                    r?.mediaCollection?.items?.[0]
                                        ?.showThisModel1Url,
                            },
                        },
                        {
                            desktopMedia: {
                                url:
                                    r?.mediaCollection?.items?.[0]
                                        ?.showThisModel2Url,
                            },
                        },
                        {
                            desktopMedia: {
                                url:
                                    r?.mediaCollection?.items?.[0]
                                        ?.showThisModel3Url,
                            },
                        },
                    ],
                };
            }),
            featuredImage: {
                desktopMedia: {
                    url:
                        generalSlide?.mediaCollection?.items?.[0]
                            ?.desktopImageUrl,
                },
            },
            featured360Gif: {
                desktopMedia: {
                    url:
                        generalSlide?.mediaCollection?.items?.[0]
                            ?.desktopImageUrl,
                },
            },
            featuredVideo: ''
        };
    });

    theStylesSectionData.sliderData = {
        items: ribbon?.slidesCollection?.items?.map((item) => {
            return {
                slug: `${item?.slugPrefix}`,
                title: item.title,
                desktopMedia: {
                    url: item?.imageUrl,
                    slug: `${item?.slugPrefix}`,
                },
            };
        }),
    };
    theStylesSectionData.buttonCta = {
        title: buttonCta?.buttonCtaTitle,
        url: buttonCta?.buttonCtaUrl,
        style: buttonCta?.buttonCtaStyle,
        target: buttonCta?.newTab ? '_blank' : '_self',
    };
    theStylesSectionData.metadata = {
        pageTitle: pageData?.seoTitle,
        pageKeywords: [],
        pageDescription: pageData?.seoDescription,
        searchKeywords: [],
    };
    theStylesSectionData.heroImage = {
        mobile: { url: bannerData?.mobileImageUrl },
        desktop: { url: bannerData?.desktopImageUrl },
    };
    theStylesSectionData.title = pageData.title;
    theStylesSectionData.description = pageData.subtitle;
    theStylesSectionData.styles = stylesCarousels;

    return theStylesSectionData;
};

export const gqlLoadTheStyleImages = async () => {
    let stylesData = [];
    let data = await doQuery(queryStylesCollection());
    const stylesCollectionData = parseStylesCollection(data);
    if (stylesCollectionData.id) {
        data = await doQuery(queryStylesExceptGallery(stylesCollectionData.id));
        stylesData = parseStylesExceptGallery(data);
    }

    return stylesData;
};
