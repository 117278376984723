import { getPreviewMode } from '../../state/utils/contentful';

/**
 * Load screenHomeCollection and its children's id
 * @returns {DocumentNode}
 */
const homeTopLevelQuery = () => (
    `{
      screenHomeCollection(${getPreviewMode()}, where: {slug: "home"}) {
        items {
          metadata {
            sys{
              id
            }
          }
          title
          slug
          marketingComponentsCollection {
            items {
              __typename
              ... on MarketingSection {
                sys {
                  id
                }
              }
            }
          }
        }
      }
    }
    `
);

const homeTopLevelQueryFromContentful = () => (
  `{
    drybarPageCollection(preview: true, where: { slug: "/" }) {
      items {
        title
        subtitle
        tcsAndPoliciesCopy
        seoTitle
        seoDescription
        canonicalUrl
        addANoindexTag
        slug
        formatofPageCollection(preview: true) {
          items {
            __typename
            ... on Carousel {
              sys {
                id
              }
            }
            ... on DrybarStylesCarousel {
              sys {
                id
              }
            }
          }
        }
      }
    }
  }`
)

/**
 * Load second level ids
 * @param marketingSectionId
 * @returns {DocumentNode}
 */
const homeSecondLevelQuery = (marketingSectionId) => (
    `{
      marketingSection(${getPreviewMode()}, id: "${marketingSectionId}") {
        marketingComponentsCollection {
          items {
            __typename
            ... on MarketingCollection {
              sys {
                id
              }
            }
            ... on MarketingStyles {
              sys {
                id
              }
            }
          }
        }
      }
    }
    `
);

// TODO: under actionsCollection - query attribute linkToContent and corresponding slug
const queryMarketingCardInMarketingCollection = (marketingCollectionId) => (
    `{
      marketingCollection(${getPreviewMode()}, id: "${marketingCollectionId}") {
        title
        settings
        marketingComponentsCollection {
          items {
            ... on MarketingCard {
              title
              subtitle
              actionsCollection {
                items {
                  title
                  linkToUrl
                  style
                }
              }
              image {
                desktopMedia {
                  url
                  contentType
                }
                mobileMedia {
                  url
                  contentType
                }
              }
              avatar {
                url
              }
              icon {
                url
              }
              settings
            }
          }
        }
      }
    }
    `
);

const queryMarketingCardInMarketingCollectionFromContentfull = (marketingCollectionId) => (
  `{
    carousel(preview: true, id: "${marketingCollectionId}") {
      internalName
      title
      autoplay
      dots
      infinite
      slidesToScroll
      slidesToShow
      arrows
      slugPrefix
      format
      speed
      slidesCollection {
        items {
          ... on DrybarStylesItem {
            title
            subtitle
            imageUrl
            slugPrefix
          }
          ... on CarouselSlide {
            slugPrefix
            internalName
            title
            subtitle
            buttonCtaUrl
            buttonCtaTitle
            buttonCtaStyle
            titleDropShadow
            titleSize
            titleColour
            backgroundColour
            textJustify
            textAlign
            mediaCollection {
              items {
                internalName
                desktopImageUrl
                mobileImageUrl
                videoUrl
                showThisModel1Url
                showThisModel2Url
                showThisModel3Url
              }
            }
          }
        }
      }
    }
  }
  `
);

const queryMarketingStyles = (marketingStylesId) => (
    `{
      marketingStyles(${getPreviewMode()}, id: "${marketingStylesId}") {
        title
        subtitle
        actionsCollection {
          items {
            title
            linkToUrl
            style
          }
        }
        marketingComponentsCollection {
          items {
            __typename
            ... on Style {
              title
              subtitle
              slug
              featuredImage {
                desktopMedia {
                  url
                  contentType
                }
                mobileMedia {
                  url
                  contentType
                }
              }
            }
            ... on MarketingCard {
              title
              subtitle
              actionsCollection {
                items {
                  title
                  linkToUrl
                  style
                }
              }
              image {
                desktopMedia {
                  url
                  contentType
                }
                mobileMedia {
                  url
                  contentType
                }
              }
              avatar {
                url
              }
              icon {
                url
              }
              settings
            }
          }    
        }
      }
    }
    `
);

const queryMarketingStylesFromContentfull = (marketingStylesId) => (
  `{
    drybarStylesCarousel(id: "${marketingStylesId}", preview: true) {
      format
      title
      subtitle
      ctaTitle
      ctaUrl
      ctaStyle
      slugPrefix
      carouselItemsCollection(preview: true) {
        items {
          slugPrefix
          title
          subtitle
          imageUrl
        }
      }
    }
  }
  `
);

const querySocialInMarketingCollection = (marketingCollectionId) => (
    `{
      marketingSection(${getPreviewMode()}, id: "${marketingCollectionId}") {
        marketingComponentsCollection {
          items {
            ... on MarketingSocialInstagram {
              title
              hashtag
              handle
            }
          }
        }
      }
    }
    `
);

export {
    homeTopLevelQuery,
    homeSecondLevelQuery,
    queryMarketingCardInMarketingCollection,
    queryMarketingStyles,
    querySocialInMarketingCollection,
    queryMarketingCardInMarketingCollectionFromContentfull,
    queryMarketingStylesFromContentfull,
    homeTopLevelQueryFromContentful
};
