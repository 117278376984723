import { getPreviewMode } from '../../state/utils/contentful';

const subscribeTopLevelQuery = (slug = 'subscribe') => (
    `{
      subscribeLandingPageCollection(${getPreviewMode()}, where: {slug: "${slug}"}) {
        items {
          metadata{
            sys {
              id
            }
          }
          title
          slug
          description {
            json
          }
          locations: settings
          hiddenvalue
          marketingComponentsCollection {
            items {
              __typename
              ... on MarketingSection {
                internalName
                sys {
                  id
                }
              }
            }
          }
        }
      }
    }
    `
);

const subscribeSecondLevelQuery = (marketingSectionId) => (
  `{
      marketingSection(${getPreviewMode()}, id: "${marketingSectionId}") {
          marketingComponentsCollection {
            items {
              ... on MarketingCard {
                  title
                  image {
                    desktopMedia {
                      url
                    }
                    mobileMedia {
                      url
                    }
                  }
                  description {
                    json
                  }
                  settings
            }
          }
        }
      }
  }
  `
);

export {
    subscribeTopLevelQuery,
    subscribeSecondLevelQuery,
};
