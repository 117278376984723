import React, { Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

import { Formik } from 'formik';
import * as Yup from 'yup';
import TagManager from 'app/Components/GoogleTagManager';
import config from 'app.config';

import DialogTitle from '@material-ui/core/DialogTitle';
import { Typography } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';
import appConfig from '../../app.config';
import { strings } from '../../assets/constants';

import './events.scss';
import { NAME_REGEX, EMAIL_REGEX, PHONE_REGEX } from 'utils/validation/regex';

const {
    FIRST_NAME,
    LAST_NAME,
    EMAIL,
    PHONE_NUMBER,
    PREFERRED_SHOP,
    PREFERRED_DATE,
    PREFERRED_START_TIME,
    PARTY_SIZE,
    OCCASION,
    NOTES,
    NOTE_PLACEHOLDER,
    SUBMIT,
} = strings;

const WufooSchema = Yup.object().shape({
    firstName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('First name is required').matches(
        NAME_REGEX,
        'Error: Invalid first name',
    ),
    lastName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Last name is required').matches(
        NAME_REGEX,
        'Error: Invalid last name',
    ),
    email: Yup.string().email().required('Email is required').matches(
        EMAIL_REGEX,
        'Error: Invalid email format',
    ),
    preferredShop: Yup.string().required('Preferred Shop is required'),
    preferredDate: Yup.string().required('Preferred Date is required'),
    preferredStartTime: Yup.string().required('Preferred Start Time is required'),
    occasion: Yup.string().required('Occasion is required'),
    partySize: Yup.string().required('Party Size is required'),
    phoneNumber: Yup.string().nullable().required('Phone number is required')
        .matches(
            PHONE_REGEX,
            'Error: Invalid phone number',
        ),
});

export default class EventDetailView extends Component {
    constructor(props) {
        super(props);
        
        this.initialForm = this.initialForm.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        this.state = this.initialForm();
    }

    initialForm(){
        return {
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            notes: '',
            preferredShop: '',
            preferredDate: '',
            preferredStartTime: '',
            occasion: '',
            partySize: '',
            showModal: false,
            modalHeader: '',
            modalContent: '',
        }
    }

    onSubmit(values, { resetForm }) {
        const formData = {
                Field1: values.firstName,
                Field2: values.lastName,
                Field3: values.email,
                Field10: values.phoneNumber,
                Field12: values.preferredShop,
                Field29: values.preferredDate,
                Field15: values.preferredStartTime,
                Field17: values.partySize,
                Field19: values.notes,
                Field25: values.occasion,
                Field21: this.props.title.toUpperCase(),
            };

        axios.post(appConfig.apiUrl + '/forms/postForms', {
            subDomain: appConfig.wufoo.subDomain,
            formId: appConfig.wufoo.genericPartyFormId,
            key: btoa(`${appConfig.wufoo.apiKey }:password`),
            formData,
        }).then((res) => {
            if (res.status === 200 || res.status === 202) {
                const { gtm: { formCompleteEvent } } = config;
                const tagManagerArgs = {
                    dataLayer: {
                        event: formCompleteEvent,
                        form_type: `event_inquiry-${this.props.title}`,
                    },
                };

                TagManager.dataLayer(tagManagerArgs);

                this.setState({
                    showModal: true,
                    modalHeader: 'Thank you for your message!',
                    modalContent: 'Someone from the Drybar team will contact you shortly!',
                });
            } else {
                this.setState({
                    showModal: true,
                    modalHeader: 'Please try again',
                    modalContent: 'Sorry it looks like some information is not quite right.',
});
            }
            resetForm(this.initialForm());
        })
        .catch((error) => console.log('error in wufoo api ', error));
    }


    render() {
        const {
            preferredShopChoices,
            preferredDateChoices,
            preferredStartTimeChoices,
            partySizeChoices,
            occasions,
        } = this.props;
        const {
title, description, dividerIcon, action,
} = this.props;
        return (
            <div className="event-detail" id={action?.link?.replace('#', '')}>
                <div className="content">
                    <div className="row flex-column align-items-center">
                        <div className="divider row align-items-center w-100">
                            <div className="dash-line flex-grow-1" />
                            <img src={dividerIcon} alt="Divider Icon" />
                            <div className="dash-line flex-grow-1" />
                        </div>
                        <h2>{title.toUpperCase()}</h2>
                        <div className="detail-section w-100">
                            {description.map((item, index) => {
                                return (
                                    <div key={index}>
                                        {item.type === 'paragraph'
                                    && <span className="top d-block w-100" key={index}>{item.content}</span>}
                                        {item.type === 'list'
                                    && (
                                    <ul key={index}>
                                        {item.content.map((listItem, index) => <li key={index}>{listItem}</li>)}
                                    </ul>
                                        )}
                                    </div>);
                            })}
                        </div>
                        <Formik
                            initialValues={this.state}
                            enableReinitialize
                            onSubmit={this.onSubmit}
                            validationSchema={WufooSchema}
                        >
                            {({
                                  values,
                                  errors,
                                  touched,
                                  setFieldTouched,
                                  handleSubmit,
                                  handleChange,
                              }) => (
                                  <form
                                      onSubmit={handleSubmit}
                                      className="row flex-column align-items-center w-100"
                                      data-form_type={`event_inquiry-${title}`}
                                  >
                                      <div className="contact-section w-100">
                                          <div className="row">
                                              <div className="col-md-6">
                                                  <label>{FIRST_NAME}</label>
                                                  <input
                                                      className="form-control"
                                                      type="text"
                                                      name="firstName"
                                                      placeholder={"Your first name here..."}
                                                      value={values.firstName}
                                                      onChange={(e) => {setFieldTouched('firstName'); handleChange(e)}}
                                                  />
                                                  {errors && errors.firstName && touched.firstName
                                                    && <p className="error-msg">{errors.firstName}</p>} 
                                              </div>
                                              <div className="col-md-6">
                                                  <label>{LAST_NAME}</label>
                                                  <input
                                                      className="form-control"
                                                      type="text"
                                                      name="lastName"
                                                      placeholder={"Your last name here..."}
                                                      value={values.lastName}
                                                      onChange={(e) => {setFieldTouched('lastName'); handleChange(e)}}
                                                  />
                                                  {errors && errors.lastName && touched.lastName
                                                && <p className="error-msg">{errors.lastName}</p>}
                                              </div>
                                          </div>
                                          <div className="row">
                                              <div className="col-md-6">
                                                  <label>{EMAIL}</label>
                                                  <input
                                                      className="form-control"
                                                      type="email"
                                                      name="email"
                                                      placeholder={"Your email here..."}
                                                      value={values.email}
                                                      onChange={(e) => {setFieldTouched('email'); handleChange(e)}}
                                                  />
                                                  {errors && errors.email && touched.email && <p className="error-msg">{errors.email}</p>}
                                              </div>
                                              <div className="col-md-6">
                                                  <label>{PHONE_NUMBER}</label>
                                                  <input
                                                      className="form-control"
                                                      type="text"
                                                      name="phoneNumber"
                                                      placeholder={"Your phone number here..."}
                                                      value={values.phoneNumber}
                                                      onChange={(e) => {setFieldTouched('phoneNumber'); handleChange(e)}}
                                                  />
                                                  {errors && errors.phoneNumber
                                                    && touched.phoneNumber && <p className="error-msg">{errors.phoneNumber}</p>}
                                              </div>
                                          </div>
                                          <div className="row">
                                              <div className="col-md-4">
                                                  <label>{PREFERRED_SHOP}</label>
                                                  <select
                                                      className="form-control"
                                                      name="preferredShop"
                                                      value={values.preferredShop}
                                                      onChange={(e) => {setFieldTouched('preferredShop'); handleChange(e)}}
                                                  >
                                                      <option key={'palce-holder-option'} value="" disabled>Your preferred shop here...</option>
                                                      {preferredShopChoices.slice(1,preferredShopChoices.length).map((choice, index) => <option key={index} value={choice}>{choice}</option>)}
                                                  </select>
                                                  {errors && errors.preferredShop
                                                    && touched.preferredShop && <p className="error-msg">{errors.preferredShop}</p>}
                                              </div>
                                              <div className="col-md-4">
                                                  <label>{PREFERRED_DATE}</label>
                                                  <input
                                                      className="form-control"
                                                      type="date"
                                                      min={new Date().toISOString().split('T')[0]}
                                                      max="9999-12-31"
                                                      name="preferredDate"
                                                      placeholder={"Your preferred date here..."}
                                                      value={values.preferredDate}
                                                      onChange={(e) => {setFieldTouched('preferredDate'); handleChange(e)}}
                                                  />
                                                  {errors && errors.preferredDate
                                                    && touched.preferredDate && <p className="error-msg">{errors.preferredDate}</p>}
                                              </div>
                                              <div className="col-md-4">
                                                  <label>{PREFERRED_START_TIME}</label>
                                                  <select
                                                      className="form-control"
                                                      name="preferredStartTime"
                                                      value={values.preferredStartTime}
                                                      onChange={(e) => {
                                                          setFieldTouched('preferredStartTime'); 
                                                          handleChange(e)}
                                                        }>
                                                      <option key={'palce-holder-option'} value="" disabled>Your preferred start time here...</option>
                                                      {preferredStartTimeChoices.map((choice, index) => <option key={index} value={choice}>{choice}</option>)}
                                                  </select>
                                                  {errors && errors.preferredStartTime
                                                    && touched.preferredStartTime && <p className="error-msg">{errors.preferredStartTime}</p>}
                                              </div>
                                          </div>
                                          <div className="row">
                                              <div className="col-md-4">
                                                  <label>{PARTY_SIZE}</label>
                                                  <select
                                                      className="form-control"
                                                      name="partySize"
                                                      
                                                      value={values.partySize}
                                                      onChange={(e) => {setFieldTouched('partySize'); handleChange(e)}}
                                                  >
                                                      <option key={'palce-holder-option'}  value="" disabled>Your party size here...</option>
                                                      {partySizeChoices.map((choice, index) => <option key={index, index} value={choice}>{choice}</option>)}
                                                  </select>
                                                  {errors && errors.partySize
                                                    && touched.partySize && <p className="error-msg">{errors.partySize}</p>}
                                              </div>
                                              <div className="col-md-4">
                                                  <label>{OCCASION}</label>
                                                  <select
                                                      className="form-control"
                                                      name="occasion"
                                                      
                                                      value={values.occasion}
                                                      onChange={(e) => {setFieldTouched('occasion'); handleChange(e)}}
                                                  >
                                                      <option key={'palce-holder-option'} value="" disabled>Your occasion here...</option>
                                                      {occasions.slice(1,occasions.length).map((choice, index) => <option key={index} value={choice}>{choice}</option>)}
                                                  </select>
                                                  {errors && errors.occasion
                                                    && touched.occasion && <p className="error-msg">{errors.occasion}</p>}
                                              </div>
                                          </div>
                                          <div className="row">
                                              <div className="col-12">
                                                  <label className="emphas">{NOTES.toUpperCase()}</label>
                                                  <textarea
                                                      className="form-control"
                                                      placeholder={NOTE_PLACEHOLDER}
                                                      rows="5"
                                                      name="notes"
                                                      value={values.notes}
                                                      onChange={(e) => {setFieldTouched('notes'); handleChange(e)}}
                                                      ></textarea>
                                              </div>
                                          </div>
                                      </div>
                                      <button
                                          type="submit"
                                          className="btn-common btn-yellow btn-lg font-weight-bold responsive-500-w-100 float-center"
                                      >
                                          {SUBMIT}
                                      </button>
                                  </form>
                            )}
                        </Formik>

                        <Dialog
                            open={this.state.showModal}
                            onClose={() => this.setState({ showModal: false })}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            maxWidth="xs"
                            className="events-wufoo-res-form"
                        >
                            <DialogTitle
                                id="alert-dialog-title"
                                className="modal-title modal-header"
                            >
                                <Typography className="modal-title">{this.state.modalHeader}</Typography>
                            </DialogTitle>
                            <DialogContent className="modal-content">
                                <DialogContentText id="alert-dialog-description">
                                    {this.state.modalContent}
                                </DialogContentText>
                            </DialogContent>
                        </Dialog>
                    </div>
                </div>
            </div>
        );
    }
}
