import React, { useEffect, useState, useCallback } from 'react';
import {
    Button, Grid, InputAdornment, TextField, Typography, Checkbox, CircularProgress, Backdrop,
} from '@material-ui/core';
import { useQuery } from '@apollo/client';
import InfiniteScroll from 'react-infinite-scroll-component';
import { array, object, func } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getPageLabelFromUrl } from 'utils/helper';
import { distance } from 'Helpers';
import LocationCard from './LocationCard';
import { setStoresData, setFavouritesData, setFocusStore } from '../../../../state/ducks/Service/Service-Actions';
import { getStoresData, getFavouritesData, getFocusStore } from '../../../../state/ducks/Service/Service-Selectors';
import { findStoresFromPointWithTitleContentful } from '../../../../state/utils/contentful';
import { gqlLoadStores } from '../../../../gql/barfly/contentfulBarflyActions';
import useEvent from '../../../../utils/event/useEvent';
import { setFavouriteLocation } from '../../../../api/booking-api';
import { geolocateSearchLocation } from '../../../../api/google-api';
import restClient from '../../../../api/restClient';
import { googleAnalyticsEvents } from 'state/utils/googleAnalyticsEvents';

const LocationSelection = ({
    classes,
    onLocationSelect,
    locationType,
    storeType,
    storeTypes,
    searchLocation,
    currentLocation,
    setStores,
    stores,
    isBooking,
    favourites,
    setFavourites,
    authUserDetail,
    favouriteLocations,
    contentfulStores,
    refresh,
    onSearch,
    onBookShop,
    onViewShop,
    location,
}) => {
    const [currentPage, setCurrentPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [isAllLoaded, setIsAllLoaded] = useState(false);

    // refs for each location card in list
    const refs = stores.reduce((acc, value) => {
        acc[value.slug] = React.createRef();
        return acc;
    }, {});

    // event handler for scrolling list to store
    // when user click marker on map, this event is emitted.
    const handleFocusStoreEvent = useCallback((store) => {
        refs[store.slug].current.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'start',
        });
    }, [refs]);
    useEvent('focus-store', handleFocusStoreEvent);

    // infinite scroll load more event
    const loadMore = () => {
        setCurrentPage(currentPage + 1);
    };

    // excute query
    useEffect(async () => {
        // favorite options
        if (locationType === 'favorite') {
            if (favouriteLocations.length > 0) {
                setStores(favouriteLocations);
                setIsAllLoaded(true);
            } else {
                setStores([]);
                setIsAllLoaded(true);
            }
        } else {
            // Default search location:  Drybar Brentwood
            let center = {
                lat: '34.0577908',
                lng: '-118.3622365',
            };

            // Override with device location, if set
            if (currentLocation) {
                center = currentLocation;
            }

            setLoading(true);

            // Override with search location, if set
            let geolocated;
            if (searchLocation && searchLocation.trim().length) {
                if (onSearch) {
                    onSearch(searchLocation);
                }
                geolocated = await geolocateSearchLocation(searchLocation);

                // TODO:  Handle error condition, when geolocation fails to produce a result
                if (geolocated) {
                    center = geolocated;
                }
            }

            const stores = await gqlLoadStores();
            const res = await findStoresFromPointWithTitleContentful(stores, geolocated, searchLocation, center);

            setStores([...res.data]);
            setIsAllLoaded(true);

            setLoading(false);
        }
    }, [locationType, storeTypes, storeType, searchLocation, currentLocation, currentPage]);

    const handleFavoriteSelection = async (selectedFavourite) => {
        const customerId = authUserDetail?.bookerID;
        await restClient.post(setFavouriteLocation(selectedFavourite?.bookerLocationId, customerId));
        if (refresh) {
 refresh({
            type: 'favorite',
            value: selectedFavourite?.bookerLocationId,
            selectedStore: selectedFavourite,
        });
}
    };

    const onBook = (locationData) => {

        gtag(googleAnalyticsEvents.EVENT, googleAnalyticsEvents.BOOKING_LOCATATION, {
            location: locationData.title
        });

        onLocationSelect(locationData);

        if (onBookShop) {
            onBookShop(locationData);
        }
    };

    if (stores) {
        return (
            <Grid style={{ position: 'relative' }}>
                <Grid className={classes.locationList} id="scrollWrapper">
                    <InfiniteScroll
                        dataLength={stores.length}
                        next={loadMore}
                        hasMore
                        scrollableTarget="scrollWrapper"
                        loader={(
                            <p style={{ fontSize: '20px', textAlign: 'center', marginBottom: '30px' }}>
                                {isAllLoaded ? '' : 'Loading...'}
                            </p>
                          )}
                    >
                        {
                            stores.length > 0 ? stores.map((data, key) => (
                                <Grid className={classes.locationMap} key={key} ref={refs[data.slug]}>
                                    <LocationCard
                                        onLocationSelect={onBook}
                                        onViewShop={onViewShop}
                                        key={`${data.slug}`}
                                        locationData={data}
                                        currentFavourite={favouriteLocations?.[0]}
                                        handleFavoriteSelection={handleFavoriteSelection}
                                    />
                                </Grid>
                                )) : (
                                <Grid className={classes.locationMap}>
                                    <LocationCard
                                        onLocationSelect={onBook}
                                        onViewShop={onViewShop}
                                        key={`${favouriteLocations?.[0]?.slug}`}
                                        locationData={favouriteLocations?.[0]}
                                        currentFavourite={favouriteLocations?.[0]}
                                        handleFavoriteSelection={handleFavoriteSelection}
                                    />
                                </Grid>)   
                        }
                    </InfiniteScroll>
                </Grid>
                {loading && (
                <div className={classes.loadingWrap}>
                    <CircularProgress color="inherit" />
                </div>
)}
            </Grid>
        );
    }

    return null;
};

LocationSelection.propTypes = {
    classes: object.isRequired,
    onLocationSelect: func.isRequired,
    location: object.isRequired,
    setStores: func.isRequired,
    stores: array.isRequired,
    favourites: array.isRequired,
    focusStore: object.isRequired,
};

const mapStateToProps = (state) => ({
    stores: getStoresData(state),
    favourites: getFavouritesData(state),
    focusStore: getFocusStore(state),
});

const mapDispatchToProps = (dispatch) => ({
    setStores: bindActionCreators(setStoresData, dispatch),
    setFavourites: bindActionCreators(setFavouritesData, dispatch),
    setFocusStore: bindActionCreators(setFocusStore, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LocationSelection));
