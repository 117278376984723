import React, { useEffect, useState } from 'react';
import Media from 'react-media';
import { withRouter } from 'react-router-dom';
import CommonFooter from './app/Components/Footer/CommonFooter/CommonFooter';
import Header from './app/Components/Header/Header';
import { TABLET_BREAKPPOINT } from './Helpers/breakpoints';

import useNavigation from './Helpers/useNavigation';

// eslint-disable-next-line react/prop-types
const AppShell = ({ children, location }) => {
    const isSecurePage =
        location?.pathname?.includes('/account') ||
        location?.pathname?.includes('/booking');
    const isAuthPage = location.pathname.includes('/auth');
    const [pwaConfig, setPWAConfig] = useState({});

    useEffect(async () => {
        const config = await useNavigation();

        setPWAConfig(config);
    }, []);

    return (
        <>
            <div style={{ overflow: 'hidden' }}>
                {!isSecurePage || isAuthPage ? (
                    <>
                        <Header config={pwaConfig} />
                    </>
                ) : null}
                <div style={{ minHeight: '500px' }}>{children}</div>
                {!isSecurePage || isAuthPage ? (
                    <CommonFooter config={pwaConfig} />
                ) : null}
            </div>
            <Media query={{ maxWidth: TABLET_BREAKPPOINT }} />
        </>
    );
};

export default withRouter(AppShell);
