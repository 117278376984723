/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect, useCallback } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
    Grid,
    Checkbox,
    withStyles,
    Typography,
    Backdrop,
    CircularProgress,
    DialogActions,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    TextField,
    Select,
    MenuItem,
    FormControl,
    FormHelperText
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { object } from 'prop-types';
import { compose } from 'recompose';
import { withOktaAuth } from '@okta/okta-react';
import useMoktaAuth from 'utils/oktaAuth';
import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';
import TagManager from 'app/Components/GoogleTagManager';
import config from 'app.config';
import { SlideDown } from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css';
import { updateUser, getPhoneCountryCodes } from 'api/auth-api';
import SectionTitle from '../../../app/Components/SectionTitle';
import AppointmentSummaryContainer from '../AppointmentSummary/AppointmentSummaryContainer';
import {
    createAppointment,
    createItinerary,
    bookItinerary,
    cancelAppointment,
    getServices,
    cancelItinerary,
    updatePromoCode,
    applyPromoCodeToOrder,
    addAddonsToAppointment,
    getCustomer,
} from '../../../api/booking-api';
import restClient from '../../../api/restClient';
import {
    getIsEditEnabled,
    getSelectedSlot,
    getLocationData,
    getAvailableEmpId,
    getAvailableRoomId,
    getNotesMessage,
    getServicesDataAsObject,
    getExtensions,
    getNumberOfGuests,
    getExtensionAddon,
    getAppointmentIDs,
    getGroupID,
    getEditLocationID,
    getCouponCode,
    getAddOnsServiceObject,
    getBuyoutLocation,
    getSelectedCard,
} from '../../../state/ducks/Booking/Booking-Selectors';
import {
    clearBookingDetails,
    setAppointmentIDs,
    setExtensionAddon,
    setEditLocationID,
    setGroupID,
} from '../../../state/ducks/Booking/Booking-Actions';
import { getSelectedAppointment } from 'state/ducks/Account/Account-Selectors';
import { setViewAppointment } from 'state/ducks/Account/Account-Actions';
import { PHONE_REGEX } from 'utils/validation/regex';
import { googleAnalyticsEvents } from 'state/utils/googleAnalyticsEvents';
import { getBookingTotal } from 'state/utils/helpers';

import { getEndDate } from '../../../Helpers/dateTime';

const PhoneNumberSchema = Yup.object().shape({
    phoneNumber: Yup.string().nullable().required('Error: Phone number is required')
        .matches(PHONE_REGEX,'Error: Invalid phone number'),
});

const { gtm: { booking } } = config;
const tagManagerArgs = {
    dataLayer: {
        event: booking.event,
        form_type: booking.type,
        form_step: booking.step8,
    },
};

const useStyles = (theme) => ({
    summaryCopy: {
        textAlign: 'center',
        fontFamily: 'URWForm',
    },
    requestNoteDetails: {
        float: 'right',
        background: '#FFFFFF',
        boxShadow: '2px 2px 17px rgba(235, 235, 235, 0.5)',
        maxWidth: '754px',
        margin: '0 auto 40px',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    },
    nextClick: {
        fontSize: '18px',
        lineHeight: '45px',
        textTransform: 'none',
        width: '378px',
        maxWidth: '100%',
        height: '63px',
        margin: 'auto',
        color: '#54575A',
        backgroundColor: '#FFDD30',
        '&:hover': {
            backgroundColor: '#b29a21',
        },
    },
    haveAnyRequest: {
        fontFamily: 'DINCondensed-Regular',
        fontSize: '39px',
        textTransform: 'uppercase',
        color: '#42413D',
    },
    requestServiceContainer: {
        background: '#F9F9F9',
    },
    locationSummary: {
        background: '#F9F9F9',
    },
    locationCopy: {
        fontFamily: 'sans-serif',
        fontSize: '15px',
    },
    locationName: {
        fontFamily: 'sans-serif',
        width: '86%',
    },
    editLocationSummary: {
        width: '100%',
        display: 'inline-flex',
    },
    editLocationIcon: {
        cursor: 'pointer',
    },
    promoCodeInput: {
        width: '86%',
    },
    applyPromoCode: {
        width: '79px',
        background: '#54575A',
        borderRadius: '0px',
        color: '#FFFFFF',
        fontSize: '13px',
    },
    estimatedTotal: {
        width: '97%',
        display: 'inline-flex',
        background: '#E5E5E5;',
    },
    estimatedCopy: {
        width: '100%',
        fontFamily: 'sans-serif',
        color: '#42413D',
    },
    estimatedPrice: {
        width: '10%',
        float: 'right',
        fontFamily: 'sans-serif',
    },
    cancellationPolicy: {},
    cancellationPolicyIcon: {
        marginRight: '14px',
    },
    cancellationPolicyCopy: {
        fontFamily: 'URWForm',
        fontSize: '16px',
        fontWeight: 'bold',
    },
    cancellationPolicyDetail: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'flex-end',
        fontFamily: 'URWForm',
        fontSize: '14px',
    },
    policyPiece: {
        marginRight: '4px',
    },
    cancellationPolicySeeMore: {
        marginLeft: '10px',
        fontWeight: 600,
        fontSize: '15px',
        letterSpacing: '-.5px',
        textDecoration: 'underline',
        cursor: 'pointer',
    },
    understandPolicy: {},
    notChangeCopy: {
        textAlign: 'center',
        fontFamily: 'URWForm',
        fontSize: '13px',
        margin: '40px 0 30px !important',
    },
    atBottomBorder: {
        borderBottom: '1px solid #D1D1D1',
        width: '94%',
    },
    barflyBottomCopy: {
        fontFamily: 'URWForm',
        fontSize: '15px',
        padding: '25px 0',
    },
    content: {
        margin: '15px 37px',
        display: 'flex',
        flexDirection: 'column',
    },
    textContainer: {
        display: 'flex',
    },
    checkbox: {
        padding: '0',
        marginRight: '14px',
    },
    checkboxContainer: {
        margin: '30px 0 !important',
    },
    cancellationPolicyContainer: {
        margin: '10px 0 !important',
    },
    backdrop: {
        zIndex: 11,
        color: '#fff',
    },
    appointmentErrorMessage: {
        border: '1px solid',
        background: 'red',
        color: 'white',
        padding: '13px',
    },

    modalTitle: {
        textAlign: 'center',
        fontSize: '31px',
        fontFamily: 'DINCondensed-Regular',
        lineHeight: '37px',
        textTransform: 'uppercase',
        fontWeight: '600',
    },
    modalHeader: {
        padding: '30px 24px 15px',
    },
    phoneInputContainer: {
        display: 'flex',
        width:'100%',
    },
    phoneCountryCodes: {
        minWidth: '100px',
    },
    phoneCountryCodesContainer: {
        marginRight: '30px',
    }
});

const ReviewContainer = ({
    history,
    classes,
    slot,
    selectedServices,
    isEditEnabled,
    locationSelectedData,
    notes,
    availableEmployees,
    availableRooms,
    extensions,
    extensionAddon,
    guests,
    addons,
    selectedCard,
    appointmentIDs,
    groupID,
    setExtensionAddon,
    editLocationID,
    setEditLocationID,
    setGroupID,
    couponCode,
    buyoutLocation,
    bookingTotal,
    selectedAppointment,
    setSelectedAppointment,
}) => {
    const [showLoader, setShowLoader] = useState(false);
    const hasLocationId = locationSelectedData?.bookerLocationId;
    const [appointmentMessage, setAppointmentMsg] = useState('');
    const [user, setUser] = useState(null);
    const [customer, setCustomer] = useState(null);
    const [policyView, seeWholePolicy] = useState(false);
    const [isCheckPolicy, setIsCheckPolicy] = useState(false);
    const [isUpdatedPhoneNumber, setIsUpdatedPhoneNumber] = useState(false);
    const [isAuthenticated, getUser] = useMoktaAuth();

    const [showPhoneNumberInputDialog, setShowPhoneNumberInputDialog] = useState(false);
    const [phoneCountryCodes, setPhoneCountryCodes] = useState(['UK +44'])

    const [phoneNumberError, setPhoneNumberError] = useState(null);

    useEffect(() => {
        TagManager.dataLayer(tagManagerArgs);
        getUser().then((res) => {
            setUser(res);
        });

        restClient.post(getPhoneCountryCodes())
        .then(result => {
            if(result.status === 200){
                setPhoneCountryCodes(result.data);
            }
        })
    }, []);

    useEffect(async () => {
        if (extensions && !extensionAddon && hasLocationId) {
            const { data, error } = await restClient.post(getServices(hasLocationId));
            if (data.IsSuccess) {
                const extensionData = data?.Treatments.filter((s) => s.Name === 'Extensions');
                if (extensionData.length) {
                    setExtensionAddon(extensionData[0]);
                }
            }
        }
    }, [extensionAddon, extensions, hasLocationId]);

    const errorHandlers = (data, defaultErrorMessage = null) => {
        let errorMessage = null;
        if (data.ArgumentErrors) {
            errorMessage = data.ArgumentErrors[0].ErrorMessage;
        } else if (data.ErrorMessage) {
            errorMessage = data.ErrorMessage;
        } else {
            errorMessage = defaultErrorMessage;
        }

        if (errorMessage) {
            setAppointmentMsg(errorMessage);
        }
    };

    const logData = (transactionId) => {
        let items = Object.values(selectedServices)?.map(service => ({
            item_id: service.ID?.toString(),
            item_name: service.Name,
            price: service.Price?.Amount,
            quantity: 1,
        }));

        if(Object.values(addons)?.length) {
            Object.values(addons).forEach(addonsForUser => {
                items = items.concat(addonsForUser.map(addon => ({
                    item_id: addon.ServiceID,
                    item_name: addon.ServiceName,
                    price: addon.PriceInfo?.Amount,
                    quantity: 1,
                })))
            });
        }

        const extensionsQuantity = extensions 
        ? (typeof extensions == "boolean" ? Object.values(selectedServices)?.length : Object.values(extensions).filter(extension => extension)?.length)
        : 0

        if(extensionsQuantity > 0) {
            items = items.concat({
                item_id: extensionAddon.ID?.toString(),
                item_name: extensionAddon.Name,
                price: extensionAddon.Price?.Amount,
                quantity: extensionsQuantity
            })
        }

        gtag(googleAnalyticsEvents.EVENT, googleAnalyticsEvents.PURCHASE, {
            currency: Object.values(selectedServices)[0]?.Price?.CurrencyCode,
            value: bookingTotal,
            transaction_id: transactionId?.toString(),
            coupon: couponCode?.code,
            items: items
        });
    }

    const isBookingConfirm = useCallback(async () => {

        if (!isCheckPolicy && !buyoutLocation) {
            setAppointmentMsg('You didn\'t check the policy');
            return;
        }

        if (!customer || (
            (!customer.HomePhone || (customer.HomePhone && (customer.HomePhone || '').trim() === ''))
            && (!customer.WorkPhone || (customer.WorkPhone && (customer.WorkPhone || '').trim() === ''))
            && (!customer.CellPhone || (customer.CellPhone && (customer.CellPhone || '').trim() === ''))
        )) {
            setShowPhoneNumberInputDialog(true);
            return;
        }

        if (Object.keys(selectedServices).length > 0) {
            setShowLoader(true);
            const items = [];
            const guestInfo = {
                FirstName: user?.firstname,
                LastName: user?.lastname,
                Email: user.email,
                mobilePhone: customer.CellPhone.replace(/\-/g, ''),
                homePhone: customer.homePhone,
                ID: user?.bookerID,
            };
            let modifiedNotes = notes || '';

            // HACK - modified notes should be a string
            if(typeof modifiedNotes !== 'string') {
                modifiedNotes = modifiedNotes.toString();
            }

            Object.keys(selectedServices).forEach((k) => {
                modifiedNotes = notes || '';
                const startTime = slot[k].startDateTime;
                const duration = selectedServices[k].TotalDuration;
                let totalDuration = duration + (addons[k]?.reduce((s, addonData) => {
                    if (addonData.Duration !== 5) {
                        s += addonData.Duration;
                    }
                    modifiedNotes = `${modifiedNotes} AddOn: ${addonData.ServiceName}.`;
                    return s;
                }, 0) || 0);

                if ((extensions === true || (extensions && extensions[k])) && guests) {
                    modifiedNotes = `Extensions added. ${modifiedNotes}`;
                    totalDuration += extensionAddon.TotalDuration;
                }
                const endTime = getEndDate(startTime, totalDuration);

                items.push({
                    // TODO: we need to update the logic for selecting the room and empolyee
                    EmployeeID: availableEmployees[k].service,
                    RoomID: availableRooms[k].service,
                    StartDateTimeOffset: guests > 0 ? startTime : undefined,
                    EndDateTimeOffset: guests > 0 ? endTime : undefined,
                    StartTimeOffset: guests === 0 ? startTime : undefined,
                    EndTimeOffset: guests === 0 ? endTime : undefined,
                    TreatmentID: selectedServices[k].ID,
                    Guest: guests > 0 ? guestInfo : undefined,
                    AppointmentNotes: guests > 0 ? modifiedNotes : undefined,
                    TotalDuration: totalDuration,
                    IsDurationOverridden: guests === 0 ? true : undefined,
                });

                if (extensions === true && guests === 0) {
                    items.push({
                        EmployeeID: availableEmployees[k].extension,
                        RoomID: availableRooms[k].extension,
                        StartTimeOffset: guests === 0 ? endTime : undefined,
                        EndTimeOffset: guests === 0 ? getEndDate(endTime, extensionAddon.TotalDuration) : undefined,
                        TreatmentID: extensionAddon.ID,
                        IsDurationOverridden: true,
                    });
                }

                if (window.dtrum) {
                    let actionId = window.dtrum.enterAction('bookAppointment');
                    window.dtrum.actionName('appointmentBooked', actionId);
                    window.dtrum.addActionProperties(actionId, null, null, { "appointmentbooked": "Drybar appointment booked.", "public": "true" }, null);
                    window.dtrum.leaveAction(actionId);
                }
                else { console.log('Warning: DT logging not connected'); }
            });

            const wrongItem = items.find((item) => {
                try {
                    if (item.StartTimeOffset && item.EndTimeOffset) {
                        return Date.parse(item.StartTimeOffset) > Date.parse(item.EndTimeOffset);
                    } if (item.StartDateTimeOffset && item.EndDateTimeOffset) {
                        return Date.parse(item.StartDateTimeOffset) > Date.parse(item.EndDateTimeOffset);
                    }
                    return true;
                } catch (e) {
                    return true;
                }
            });

            if (wrongItem) {
                setShowLoader(false);
                setAppointmentMsg('Something went wrong! Please rebook');
                return true;
            }

            const payload = {
                locationId: hasLocationId,
                authUser: guestInfo || {},
                items,
                slot,
                notes: modifiedNotes,
                couponCode: couponCode ? couponCode.code : '',
                cardTokenId: selectedCard?.ID
            };

            let updateSelectedAppointment = false;

            if (isEditEnabled) {

                if (appointmentIDs.length > 0) {

                    if(appointmentIDs.includes(selectedAppointment?.group?.appointment?.ID)) {
                        updateSelectedAppointment = true;
                    }

                    const cancelRequests = appointmentIDs.map((appointmentID) => restClient.post(cancelAppointment(appointmentID)));
                    const cancelData = await Promise.all(cancelRequests);

                    if (cancelData.filter((c) => !c.data.IsSuccess).length) {
                        setAppointmentMsg('Can not edit this Appointment');
                        setShowLoader(false);
                        return;
                    }
                        setAppointmentIDs([]);
                }
                if (groupID && editLocationID) {
                    const cancelData = await restClient.post(cancelItinerary(groupID, editLocationID));
                    setGroupID(null);
                    setEditLocationID(null);
                }
                if(!selectedAppointment?.group?.groupID || groupID === selectedAppointment.group.groupID) {
                    updateSelectedAppointment = true;
                }
            }

            const createRequest = guests === 0 ? restClient.post(createAppointment(payload)) : restClient.post(createItinerary(payload));
            try {
                const res = await createRequest;
                const { Appointment } = res.data;

                let appointments = res.data.Appointment ? [res.data.Appointment] : res.data.Items;

                if (res.data.IsSuccess) {
                    if (guests > 0) {
                        const res1 = await restClient.post(bookItinerary(res.data.ID, `${user.firstname}'s friends`, res.data.ID));
                        if (!res1.data.IsSuccess) {
                            errorHandlers(res1.data);
                        }
                    }

                    if (Object.keys(addons).length) {
                        const apptsPerService = Object.keys(selectedServices).reduce((s, cur) => {
                            const appt = appointments.find((a) => availableEmployees[cur].service === a.EmployeeID && availableRooms[cur].service === (a.RoomID || a.Room.ID));

                            if (appt) {
                                s[cur] = appt;
                            }
                            return s;
                        }, {});

                        const addonsRequest = Object.keys(addons).filter((k) => addons[k] && addons[k].length).reduce((s, k) => {
                            const curRequests = addons[k].map((addon) => (restClient.post(addAddonsToAppointment({
                                locationId: hasLocationId,
                                appointmentId: (apptsPerService[k].AppointmentID || apptsPerService[k].ID),
                                serviceId: addon.ServiceID,
                            }))));
                            s = [...s, ...curRequests];
                            return s;
                        }, []);

                        if (addonsRequest.length) {
                            const addonsRes = await Promise.all(addonsRequest);

                            const addonItemsPerAppointmentID = addonsRes.map((addonRes) => addonRes.data).reduce((s, addon) => {
                                if (!s[addon.Appointment.ID]) {
                                    s[addon.Appointment.ID] = [];
                                }

                                const addonItems = addon.Appointment.AddOnItems || [];

                                addonItems.forEach((a) => {
                                    const addonIds = s[addon.Appointment.ID].map(
                                        (i) => i.ItemID,
                                    );
                                    if (!addonIds.includes(a.ItemID)) {
                                        s[addon.Appointment.ID].push(a);
                                    }
                                });

                                return s;
                            }, {});

                            appointments = appointments.map((a) => {
                                if (!addonItemsPerAppointmentID[a.ID || a.AppointmentID]) return a;
                                return {
                                    ...a,
                                    AddOnItems: [...addonItemsPerAppointmentID[a.ID || a.AppointmentID]],
                                };
                            });
                        }
                    }

                    if (couponCode && couponCode.id) {
                        const res2 = await restClient.post(applyPromoCodeToOrder({
                            orderId: guests > 0 ? res.data.OrderID : Appointment.OrderID,
                            specialIds: [couponCode.id],
                            locationId: hasLocationId,
                        }));
                        if (!res2.data.IsSuccess) {
                            errorHandlers(res2.data);
                        }
                    }

                    if (couponCode && couponCode.promocode) {
                        await restClient.post(updatePromoCode(couponCode.promocode));
                    }

                    if(updateSelectedAppointment) {        
                        setSelectedAppointment({
                            isUpcoming: true,
                            group: {
                                bookingNumbers: appointments.map(appointment => appointment.BookingNumber),
                            }
                        });
                    }

                    logData(appointments[0]?.PaymentID ?? res.data?.ID);

                    clearBookingDetails();

                    history.push({
                        pathname: '/appointment-confirm',
                        state: {
                            appointmentDetails: appointments,
                        },
                    });
                } else {
                    errorHandlers(res.data);
                }
            } catch (e) {
            }
            setShowLoader(false);
        }
    }, [
        selectedServices,
        slot,
        user,
        customer,
        extensions,
        groupID,
        editLocationID,
        availableEmployees,
        availableRooms,
        hasLocationId,
        notes,
        guests,
        isEditEnabled,
        isCheckPolicy,
        extensionAddon,
        setAppointmentMsg,
    ]);

    const onUpdateUserPhoneNumber = async (values) => {
        try {

            /* TODO: Now we need to update Booker with the revised phone number */
            //await restClient.post(updateUserOkta({
            //    primaryPhone: values.phoneNumber,
            //    firstName: user?.firstname,
            //    lastName: user?.lastname,
            //    email: user?.email,
            //}));
            setCustomer({
                ...customer,
                CellPhone: values.phoneNumber,
                PhoneCountryCode: values.phoneCountryCode
            });
            setIsUpdatedPhoneNumber(true);
            setShowPhoneNumberInputDialog(false);
        } catch (e) {
            setPhoneNumberError('Error: Phone number is incorrect');
        }
    };

    const onClosePhoneNumberInputDialog = () => {
        setShowPhoneNumberInputDialog(false);
        setPhoneNumberError(null);
    }

    useEffect(() => {
        if (isUpdatedPhoneNumber && customer && (customer.CellPhone || '').trim() !== '') {
            isBookingConfirm();
            setIsUpdatedPhoneNumber(false);
        }
    }, [customer, isUpdatedPhoneNumber]);

    useEffect(async () => {
        if (!customer && user && user.bookerID) {
            const { data } = await restClient.post(getCustomer(user.bookerID));
            if (data.Customer?.Customer) {
                setCustomer(data.Customer?.Customer);
            }
        }
    }, [user, customer]);

    return (
        <>
            <Grid container className={classes.requestServiceContainer}>
                <SectionTitle title="REVIEW & Confirm" />
                <Grid className={classes.requestNoteDetails}>
                    <AppointmentSummaryContainer fullWidth showPromoCode />
                    <Grid className={classes.content}>
                        {!buyoutLocation && (
                        <div className={classes.cancellationPolicy}>
                            <Typography
                                className={`${classes.textContainer} ${classes.cancellationPolicyContainer}`}
                                onClick={() => seeWholePolicy(!policyView)}
                            >
                                {
                                    policyView ? <RemoveCircleIcon className={classes.cancellationPolicyIcon} />
                                        : <AddCircleIcon className={classes.cancellationPolicyIcon} />
                                }
                                <span className={classes.cancellationPolicyCopy}>Cancellation {'&'} No-Show Policy</span>
                            </Typography>
                            <SlideDown>
                                {
                                    policyView && (
                                    <div className={classes.cancellationPolicyDetail}>
                                       If you are unable to attend your appointment or wish to cancel, please call to cancel or visit the Appointments tab within Your Account. If you cancel on less than 12 hours' notice or don't show up, your card will be charged 50% of your treatment fee. For group bookings, your card will be charged for each guest in your party who cancels on less than 12 hours' notice or doesn't show up.
                                    </div>
)
                                }
                            </SlideDown>
                            <Typography className={`${classes.textContainer} ${classes.checkboxContainer}`}>
                                <Checkbox
                                    className={classes.checkbox}
                                    defaultChecked
                                    color="default"
                                    inputProps={{ 'aria-label': 'checkbox with default color' }}
                                    onClick={() => setIsCheckPolicy(!isCheckPolicy)}
                                    checked={isCheckPolicy}
                                />
                                <span className={classes.cancellationPolicyCopy}>
                                    Yes, I understand this policy
                                </span>
                            </Typography>

                        </div>
)}
                        <Button
                            className={classes.nextClick}
                            onClick={() => isBookingConfirm()}
                            variant="contained"
                            color="secondary"
                            disabled={!customer}
                        >
                            {isEditEnabled ? 'Update Appointment' : 'Book This Appointment'}
                        </Button>

                        {!buyoutLocation && (
                        <div className={classes.notChangeCopy}>
                            You will not be charged until after your appointment
                        </div>
)}
                        {appointmentMessage?.length > 0
                        && (
                        <div className={classes.appointmentErrorMessage}>
                            {appointmentMessage}
                        </div>
)}

                        {!buyoutLocation && <div className={classes.atBottomBorder} />}
                        <div className={classes.barflyBottomCopy}>
                            * If you're a Barfly, additional discounts will be taken in-shop at time of appointment.
                        </div>
                    </Grid>

                </Grid>
            </Grid>
            {showLoader ? (
                <Backdrop className={classes.backdrop} open>
                    <CircularProgress color="inherit" />
                </Backdrop>
            ) : null}
            <Dialog
                open={showPhoneNumberInputDialog}
                onClose={onClosePhoneNumberInputDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" className={`${classes.modalTitle} ${classes.modalHeader}`}>
                    <Typography className={classes.modalTitle}>Please update your phone number</Typography>
                </DialogTitle>
                <Formik
                    initialValues={{
                        phoneCountryCode: phoneCountryCodes[0],
                        phoneNumber: '',
                    }}
                    enableReinitialize
                    onSubmit={onUpdateUserPhoneNumber}
                    validationSchema={PhoneNumberSchema}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleSubmit,
                        handleChange,
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <DialogContent>
                                <DialogContentText>
                                    Uh oh! Looks like you haven't added a phone number to your profile. Please update your phone number here! The shop may need to contact you regarding your appointments or account.
                                </DialogContentText>
                                <Grid className={classes.phoneInputContainer}>
                                    <FormControl className={classes.phoneCountryCodesContainer}>
                                        <Select 
                                            value={values.phoneCountryCode}
                                            name="phoneCountryCode"
                                            onChange={handleChange} 
                                            className={classes.phoneCountryCodes}
                                        >
                                            {phoneCountryCodes.map((option) => (
                                                <MenuItem value={option}>{option}</MenuItem>
                                            ))}
                                        </Select>
                                        {(errors && touched.phoneNumber && errors.phoneNumber) && <FormHelperText> </FormHelperText>}
                                    </FormControl>
                                    <TextField
                                        value={values.phoneNumber}
                                        fullWidth
                                        name="phoneNumber"
                                        onChange={handleChange}
                                        error={(errors && touched.phoneNumber && errors.phoneNumber) || !!phoneNumberError}
                                        helperText={(errors && touched.phoneNumber && errors.phoneNumber) || phoneNumberError}
                                    />
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    style={{
                                    padding: '5px 20px',
                                    borderRadius: '2px',
                                    marginBottom: '8px',
                                    textDecoration: 'none',
                                }}
                                    type="submit"
                                >
                                    Update
                                </Button>
                            </DialogActions>
                        </form>
)}
                </Formik>

            </Dialog>
        </>
    );
};

ReviewContainer.propTypes = {
    history: object.isRequired,
};

const mapStateToProps = (state) => ({
    slot: getSelectedSlot(state),
    selectedServices: getServicesDataAsObject(state),
    isEditEnabled: getIsEditEnabled(state),
    locationSelectedData: getLocationData(state),
    notes: getNotesMessage(state),
    availableEmployees: getAvailableEmpId(state),
    availableRooms: getAvailableRoomId(state),
    extensions: getExtensions(state),
    extensionAddon: getExtensionAddon(state),
    guests: getNumberOfGuests(state),
    addons: getAddOnsServiceObject(state),
    selectedCard: getSelectedCard(state),
    appointmentIDs: getAppointmentIDs(state),
    groupID: getGroupID(state),
    editLocationID: getEditLocationID(state),
    couponCode: getCouponCode(state),
    buyoutLocation: getBuyoutLocation(state),
    bookingTotal: getBookingTotal(state),
    selectedAppointment: getSelectedAppointment(state),
});

const mapDispatchToProps = (dispatch) => ({
    clearState: bindActionCreators(clearBookingDetails, dispatch),
    setAppointmentIDs: bindActionCreators(setAppointmentIDs, dispatch),
    setExtensionAddon: bindActionCreators(setExtensionAddon, dispatch),
    setEditLocationID: bindActionCreators(setEditLocationID, dispatch),
    setGroupID: bindActionCreators(setGroupID, dispatch),
    setSelectedAppointment: bindActionCreators(setViewAppointment, dispatch),
});

const enhance = compose(
    withRouter,
    withStyles(useStyles),
    connect(mapStateToProps, mapDispatchToProps),
);

export default enhance(withOktaAuth(ReviewContainer));
