const BOOKING_DATE_TIME = 'BOOKING/DATE_TIME';
const BOOKING_LOCATION = 'BOOKING/LOCATION';
const BOOKING_SERVICE = 'BOOKING/SERVICE';
const BOOKING_HOW_MANY = 'BOOKING/HOW_MANY';
const BOOKING_ADDONS_FOR_USER = 'BOOKING/ADDONS_USER';
const BOOKING_ADDONS = 'BOOKING/ADDONS';
const BOOKING_DIFFERENT_SERVICE_FOR_GUEST = 'BOOKING/DIFFERENT_SERVICE_FOR_GUEST';
const RESET_ON_GUEST_CHANGE = 'RESET_ON_GUEST_CHANGE';
const BOOKING_SLOT_TIME = 'BOOKING/SLOT_TIME';
const BOOKING_OVERWRITE_SERVICES_DATA = 'BOOKING/OVERWRITE_SERVICES_DATA';
const BOOKING_CLEAR_DATA = 'BOOKING/CLEAR_DATA';
const BOOKING_REBOOK_EDIT_APPOINTMENT = 'BOOKING/REBOOK_EDIT_APPOINTMENT';
const BOOKING_LOAD_BOOKING_STATE = 'BOOKING/LOAD_BOOKING_STATE';
const BOOKING_IS_EDIT_ENABLED = 'BOOKING/IS_EDIT_ENABLED';
const BOOKING_REQUEST_NOTES = 'BOOKING/NOTES_MESSAGE';
const BOOKING_SET_EXTENSIONS = 'BOOKING/SET_EXTENSIONS';
const BOOKING_SET_AVAIL_EMPLOYEE = 'BOOKING/SET_AVAIL_EMPLOYEE';
const BOOKING_SET_AVAIL_ROOM = 'BOOKING/SET_AVAIL_ROOM';
const BOOKING_SET_REGISTER_USER = 'BOOKING/SET_REGISTER_USER';
const BOOKING_SET_AVAILABLE_DATES = 'BOOKING/SET_AVAILABLE_DATES';
const SET_AUTH_USER_DETAILS = 'AUTH/SET_AUTH_USER_DETAIL';
const BOOKING_SET_BUYOUT_LOCATION = 'BOOKING/BUYOUT_LOCATION';
const BOOKING_SET_CARDS = 'BOOKING/SET_CARDS';
const BOOKING_SET_SELECTED_CARD = 'BOOKING/SET_SELECTED_CARD';
const BOOKING_SET_EXTENSION_DATA = 'BOOKING/SET_EXTENSION_DATA';
const BOOKING_SET_APPOINTMENT_IDS = 'BOOKING/SET_APPOINTMENT_IDS';
const BOOKING_SET_GROUP_ID = 'BOOKING/SET_GROUP_ID';
const BOOKING_SET_EDIT_LOCATION_ID = 'BOOKING/SET_EDIT_LOCATION_ID';
const BOOKING_SET_COUPONCODE = 'BOOKING/BOOKING_SET_COUPONCODE';
const BOOKING_SET_IS_EDIT_EXTENSIONS = 'BOOKING/SET_IS_EDIT_EXTENSIONS';

export default {
    BOOKING_DATE_TIME,
    BOOKING_LOCATION,
    BOOKING_SERVICE,
    BOOKING_HOW_MANY,
    BOOKING_DIFFERENT_SERVICE_FOR_GUEST,
    BOOKING_ADDONS_FOR_USER,
    BOOKING_ADDONS,
    RESET_ON_GUEST_CHANGE,
    BOOKING_SLOT_TIME,
    BOOKING_OVERWRITE_SERVICES_DATA,
    BOOKING_CLEAR_DATA,
    BOOKING_REBOOK_EDIT_APPOINTMENT,
    BOOKING_LOAD_BOOKING_STATE,
    BOOKING_IS_EDIT_ENABLED,
    BOOKING_REQUEST_NOTES,
    BOOKING_SET_EXTENSIONS,
    BOOKING_SET_AVAIL_EMPLOYEE,
    BOOKING_SET_AVAIL_ROOM,
    BOOKING_SET_REGISTER_USER,
    BOOKING_SET_AVAILABLE_DATES,
    SET_AUTH_USER_DETAILS,
    BOOKING_SET_BUYOUT_LOCATION,
    BOOKING_SET_CARDS,
    BOOKING_SET_SELECTED_CARD,
    BOOKING_SET_EXTENSION_DATA,
    BOOKING_SET_APPOINTMENT_IDS,
    BOOKING_SET_GROUP_ID,
    BOOKING_SET_EDIT_LOCATION_ID,
    BOOKING_SET_COUPONCODE,
    BOOKING_SET_IS_EDIT_EXTENSIONS
};
