/* eslint-disable max-len */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { CircularProgress, Backdrop } from '@material-ui/core';
import { object } from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
    screenBarfly as BarflyMembership,
    barflyPageQuery,
    fetchBannerData,
    fetchBarflyMemberships,
} from '../../gql/queries/barflyMembership';
import ConnectedBarflyMembership from './BarflyMembershipEnrollment';
import ConnectedBarflyConfirm from './Partials/BarflyConfirmCard';
import './BarflyMembership.scss';
import { doQuery } from '../../state/utils/contentful';

const BarflyContainer = ({
    location,
    isCustomerMembership,
    AccountMembershipPrice,
    isMembership,
    handleUpgradePremium,
}) => {
    const [metadata, setMetadata] = useState({});
    const [loading, setIsLoading] = useState(false);
    const [newData, setData] = useState({});

    // const BARFLY_MEMBERSHIP = BarflyMembership();
    // const { data, error, loading } = useQuery(BARFLY_MEMBERSHIP);

    useEffect(async () => {
        setIsLoading(true);

        const data = await doQuery(barflyPageQuery(), true);
        const pageData = data.drybarPageCollection?.items[0];

        setMetadata({
            pageDescription: pageData?.seoDescription,
            pageKeywords: [],
            pageTitle: pageData?.seoTitle,
            searchKeywords: [],
        });

        const firstLevelIds = pageData.formatofPageCollection?.items?.map(
            (item) => item.sys.id
        );

        let banner = {};
        let items = {};

        if (firstLevelIds.length > 0) {
            banner = await doQuery(fetchBannerData(firstLevelIds[0]), true);

            items = await doQuery(
                fetchBarflyMemberships(firstLevelIds[1]),
                true
            );
        }

        const membershipGrid = items?.drybarMembershipGrid;
        const membershipCollection = membershipGrid?.membershipCardsCollection?.items?.map(
            (item) => {
                return {
                    benefitsCollection: {
                        items: item?.benefitType?.map((b, index) => {
                            return {
                                name: b,
                                value: item?.benefitDescription?.[index],
                            };
                        }),
                    },
                    price: item.price,
                    subtitle: item.subtitle,
                    title: item.title,
                };
            }
        );

        const finalData = {
            finePrint: pageData?.formatofPageCollection?.items?.[3]?.finePrint,
            thankYou: {
                json: {
                    content: [
                        {
                            content: [
                                {
                                    nodeType: 'text',
                                    value:
                                        pageData?.formatofPageCollection
                                            ?.items?.[2]?.description,
                                    marks: [],
                                },
                            ],
                            nodeType: 'paragraph',
                        },
                    ],
                },
            },
            membershipsCollection: {
                items: membershipCollection,
            },
            marketingComponentsCollection: {
                items: [
                    {
                        desktopMedia: {
                            url:
                                banner?.heroBanner?.mediaCollection?.items?.[0]
                                    ?.desktopImageUrl,
                        },
                        mobileMedia: {
                            url:
                                banner?.heroBanner?.mediaCollection?.items?.[0]
                                    ?.mobileImageUrl,
                        },
                    },
                ],
            },
        };

        setData(finalData);

        setIsLoading(false);
    }, []);

    if (loading) {
        return (
            <Backdrop
                open
                style={{
                    zIndex: 11,
                    color: '#fff',
                }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    }

    // if (error) {
    //     console.log(error);
    // }

    if (newData && !loading) {
        //TODO for now left this as it is. Should be changed to fetch data from contentful
        // const barflyMembershipCollection =
        //     data.barfly?.membershipsCollection?.items || [];

        const newBarflyMembershipCollection =
            newData?.membershipsCollection?.items || [];

        // console.log(data?.barfly, newData);

        const isConfirmBarfly = location.pathname.includes('/barfly-confirm');
        const isAccountBarfly = location.pathname.includes(
            '/account/barfly-membership'
        );
        return (
            <div className="light-bg">
                {isConfirmBarfly ? (
                    <ConnectedBarflyConfirm barflyConfirmData={newData} />
                ) : (
                    <ConnectedBarflyMembership
                        barflyMembershipCollection={
                            newBarflyMembershipCollection
                        }
                        isAccountBarfly={isAccountBarfly}
                        isCustomerMembership={isCustomerMembership}
                        AccountMembershipPrice={AccountMembershipPrice}
                        isMembership={isMembership}
                        handleUpgradePremium={handleUpgradePremium}
                        bannerImage={
                            newData?.marketingComponentsCollection?.items?.[0]
                                ?.desktopMedia?.url
                        }
                    />
                )}
            </div>
        );
    }
    return null;
};

BarflyContainer.propTypes = {
    location: object.isRequired,
};

export default withRouter(BarflyContainer);
