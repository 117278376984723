import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import { Typography } from '@material-ui/core';

const PasswordResetAlert = ({
openModal, classes, onClose, message, title
}) => (
    <div>
        <Dialog
            open={openModal}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="xs"
        >
            <DialogTitle id="alert-dialog-title" className={`${classes.modalTitle} ${classes.modalHeader}`}>
                <Typography className={classes.modalTitle}>{title}</Typography>
            </DialogTitle>
            <DialogContent className={classes.modalContent}>
                <DialogContentText id="alert-dialog-description">
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={() => onClose()}>
                    OK
                </Button>
            </DialogActions>

        </Dialog>
    </div>
    );

export default PasswordResetAlert;
