import { Switch, Redirect, Route } from 'react-router-dom';
import React from 'react';
import bookingRoutes from '../../routes/bookingRoutes';
import BookingWrapper from './BookingWrapper';
import Authenticated from '../../utils/authenticated';
import { useAuth } from 'oidc-react';

const Bookng = () => {
    const auth = useAuth();

    return (
    <BookingWrapper>
        {({ goToNextPage }) => (
            <Switch>
                {
                    bookingRoutes.map(({ path, Component, privateRoute }) => (
                        privateRoute ? (
                            <Route
                                path={path}
                                render={() => (
                                    <Authenticated userManager={auth.userManager}>
                                    <Component goToNextPage={goToNextPage} />
                                    </Authenticated>
                                )}
                            />
                        ) : (
                            <Route
                                exact
                                path={path}
                                render={() => (
                                    <Component goToNextPage={goToNextPage} />
                                )}
                            />
                        )
                    ))
                }
                <Route render={() => <Redirect to="/404" />} />
            </Switch>
        )}
    </BookingWrapper>
    );
};

export default Bookng;
