/* eslint-disable max-len */
import {
    Box,
    Grid, Typography, withStyles, Button,
    DialogTitle, Dialog, DialogContent, Checkbox, FormHelperText, TextField
} from '@material-ui/core';
import { object } from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import CloseIcon from '@material-ui/icons/Close';
import {
    Map, GoogleApiWrapper, Marker, InfoWindow,
} from 'google-maps-react';
import { toast, ToastContainer } from 'react-toastify';
import { MEDIUM_MOBILE_BREAKPOINT, MOBILE_BREAKPOINT, TABLET_BREAKPPOINT } from '../../../Helpers/breakpoints';

import { doQuery, getPreviewMode } from '../../../state/utils/contentful';
import {
    getBarflyMembershipPrice,
    getChosenStore,
    getUpdatedCustomer,
    getNewCard,
    getBarflyMembershipID,
    getBarflyMembershipType,
    getBarflyBlowouts,
    getBarflyAdditionalOffers,
    getRewardsCardNumber,
} from '../../../state/ducks/Barfly/Barfly-Selectors';
import { setUpdatedCustomer, setCard } from '../../../state/ducks/Barfly/Barfly-Actions';
import { useFetchAsync } from '../../../Helpers/useFetch';
import { formatDate } from '../../../Helpers/dateTime';
import {
    addMembershipToOrder,
    createOrder,
    paymentMembershipInitial,
    placeMembershipOrder,
    placeOrder,
} from '../../../api/booking-api';

import 'react-toastify/dist/ReactToastify.css';
import markerIcon from '../../../assets/images/inactiveMarker.svg';
import restClient from '../../../api/restClient';
import TagManager from 'app/Components/GoogleTagManager';
import config from 'app.config';

import { DocumentToComponent } from '../../../components';
import { getPageLabelFromUrl } from 'utils/helper';
import { createMetadataMiddleware } from 'instantsearch.js/es/middlewares';

const styles = (theme) => ({
    container: {
        maxWidth: '1367px',
        width: '100%',
        margin: 'auto',
        padding: '53px',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            padding: '19px',
        },

    },
    mainTitle: {
        textTransform: 'uppercase',
        fontFamily: 'DINCondensed-Regular',
        fontSize: '42px',
        color: '#42413D',
        fontWeight: '500',
        width: '100%',
        textAlign: 'center',
        lineHeight: '51px',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            fontSize: '31px',
            width: '79%',
            lineHeight: '37px',
        },
    },
    titleContainer: {
        display: 'flex',
    },
    displayFlex: {
        display: 'flex',
        alignItems: 'center',
        color: '#42413D',
    },
    subTitle: {
        textAlign: 'center',
        fontSize: '18px',
        marginBottom: '25px',
        fontWeight: '400',
        textTransform: 'none',
        fontFamily: 'URWForm',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            fontSize: '15px',
        },
    },
    submitButton: {
        maxWidth: '378px',
        width: '100%',
        height: '63px',
        fontSize: '18px',
        margin: '0px 0px 40px 450px',
        [theme.breakpoints.down(TABLET_BREAKPPOINT)]: {
            margin: '0px 25% 40px',
        },
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            margin: '0px 0px 36px 4px',
        },
    },
    summaryContainer: {
        borderRadius: '0px',
        backgroundColor: '#FFFFFF',
        padding: '28px 35px',
        height: '100%',
        [theme.breakpoints.down(TABLET_BREAKPPOINT)]: {
            padding: '20px 15px',
        },
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            backgroundColor: '#F9F9F9',
        },
        [theme.breakpoints.down(MEDIUM_MOBILE_BREAKPOINT)]: {
            padding: 0,
        },
    },
    summaryTitle: {
        fontFamily: 'MrsEavesOT-Roman',
        fontSize: '25px',
        lineHeight: '45px',
        textTransform: 'uppercase',
        color: '#42413D',
        margin: '28px 0px 0px 36px',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            fontSize: '15px',
            whiteSpace: 'nowrap',
            margin: '0px',
        },
    },
    membershipCharge: {
        alignItems: 'flex-start',
        margin: '16px 0',
    },
    theFinePrint: {
        width: '1269px',
        borderRadius: '0px',
        backgroundColor: '#FFFFFF',
        marginTop: '5px',
        marginBottom: '40px',
        padding: '28px 35px',
        [theme.breakpoints.down(TABLET_BREAKPPOINT)]: {
            padding: '20px 15px',
            width: '100%'
        },
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            width: '100%',
        },
    },
    finePrintCopy: {
        fontFamily: 'URWForm',
        fontSize: '17px',
        lineHeight: '32px',
        color: '#42413D',
        padding: '25px 25px 0px 25px',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            fontSize: '15px',
            lineHeight: '22px',
        },
    },
    finePrintHeading: {
        fontFamily: 'MrsEavesOT-Roman',
        fontSize: '20px',
        lineHeight: '45px',
        color: '#42413D',
        marginTop: '24px !important',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            fontSize: '15px',
            lineHeight: '22px',
            marginLeft: '2px',
        },
    },
    slashVector: {
        margin: '70px 20px 0 25px',
        content: '""',
        height: '45px',
        transform: 'rotate(20deg)',
        borderRight: '2px solid #42413D',
        // position: 'absolute',
        '&::after': {},
    },
    serviceChargeCotainer: {
        display: 'flex',
        justifyContent: 'center',
        flex: '1',
        borderRadius: '0px',
        backgroundColor: '#F7F8F9',
        borderTop: '1px solid #D1D1D1',
        alignItems: 'left',

        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            backgroundColor: '#FFFFFF',
            borderRadius: '0px',
        },
    },
    servicePriceCopy: {
        border: '1px solid #FFFFFF',
        background: '#FFFFFF',
        boxShadow: '2px 2px 46px rgba(235, 235, 235, 0.5)',
        borderRadius: '50%',
        fontSize: '32px',
        lineHeight: '34px',
        fontWeight: '600',
        color: '#42413D',
        fontFamily: 'URWForm-Bold',
        display: 'flex',
        alignItems: 'center',
        width: '130px',
        height: '130px',
        letterSpacing: '-2px',
        margin: '20px 0 0 70px',
        justifyContent: 'center',

        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            background: '#F7F8F9',
            width: '76px',
            height: '76px',
            borderWidth: '0px',
            padding: '12px',
        },

    },
    serviceChargeData: {
        display: 'flex',
        position: 'relative',
        margin: '33px 0px',
        alignItems: 'center',

        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            margin: '11px 0px 0px 5px',
            flexWrap: 'wrap',
        },
    },
    proceedSelectedAction: {
        width: '50%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            textAlign: 'center',
        },
    },
    taxApplicable: {
        fontFamily: 'URWForm',
        fontSize: '18px',
        lineHeight: '34px',
        color: '#767676',
        margin: '0 0 0 11px',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            margin: '11px 0px 11px 5px',
        },
    },
    monthCopy: {
        fontSize: '25px',
        lineHeight: '34px',
        fontWeight: '600',
        color: '#42413D',
        fontFamily: 'URWForm',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            fontSize: '18px',
        },
    },
    theBarflySingle: {
        // width: '125px',
        float: 'left',
        height: '22px',
        margin: '0 100px 10px 0px',
        fontFamily: 'URWForm',
        fontSize: '22px',
        fontWeight: '600',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#54575a',
        display: 'inline-block',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            fontSize: '18px',
            margin: '0 50px 10px 0px',
        },
        [theme.breakpoints.down(TABLET_BREAKPPOINT)]: {
            fontSize: '18px',
            margin: '0 70px 10px 0px',
        },
    },
    thePrice: {
        // width: '125px',
        float: 'right',
        height: '22px',
        margin: '0 0 10px',
        fontFamily: 'URWForm',
        fontSize: '22px',
        fontWeight: '600',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#54575a',
        display: 'inline-block',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            fontSize: '18px',
        },
        [theme.breakpoints.down(TABLET_BREAKPPOINT)]: {
            fontSize: '18px',
        },
    },
    blowDryPerMonth: {
        // width: '121px',
        height: '16px',
        margin: '20px 4px 0 0',
        fontFamily: 'URWForm',
        fontSize: '16px',
        fontEeight: '500',
        fontstretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#8b9197',
        display: 'list-item'
    },
    month: {
        width: '126px',
        height: '33px',
        margin: '100px 0 7px 50px',
        fontFamily: 'URWForm',
        fontSize: '24px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#54575a',
    },
    line: {
        height: '38px',
        margin: '6px 60px 4px 111px',
        transform: 'rotate(-209.22deg)',
        border: 'solid 1px #53575a',
    },
    drybarLocation: {
        fontFamily: 'URWForm',
        fontSize: '18px',
        lineHeight: '34px',
        // color: '#42413D',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            fontSize: '15px',
            lineHeight: '24px',
        },
    },
    locationDetails: {
        borderRadius: '0px',
        backgroundColor: '#F7F8F9',
        borderTop: '1px solid #D1D1D1',
        margin: '0 0 15px 0',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '10px 20px 20px 10px',

        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            backgroundColor: '#FFFFFF',
            borderRadius: '0px',
        },
    },
    confirmedMap: {
        width: '195px',
        height: 'auto',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            width: '134px',
        },
    },
    bookYourAppointmentToday: {
        maxWidth: '378px',
        fontSize: '18px',
        margin: '20px auto 40px',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            maxWidth: '318px',
            height: '55px',
            fontSize: '15px',
            margin: '10px auto 30px',
        },
    },
    nameAndPrice: {

    },
    allTheBenefits: {
        margin: '5% 0 5% 6%'
    },
    headingSpace: {
        marginBottom: '12%'
    },
    thankYouDesc: {
        borderTop: '1px solid #D1D1D1',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            width: '90%',
            margin: '0px 13px 0px 22px',
        },
    },
    thankYouHeading: {
        fontFamily: 'DINCondensed-Regular',
        fontWeight: '700',
        fontSize: '34px',
        lineHeight: '41px',
        textTransform: 'uppercase',
        color: '#42413D',
        width: '100%',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            width: '251px',
            fontSize: '30px',
            lineHeight: '34px',
        },
    },
    dialogContainer: {
        minWidth: 626,
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            minWidth: 0,
        },
    },
    copyDetails: {
        fontFamily: 'URWForm',
        fontSize: '16px',
        lineHeight: '32px',
        color: '#42413D',
        marginTop: '10px',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            fontSize: '15px',
            marginTop: '2px',
            lineHeight: '22px',
            marginLeft: '-23px',
        },
    },
    closeIcon: {
        margin: '20px 0px 0px 30%',
        cursor: 'pointer',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            margin: '34px 0px 0px 1%',
        },
    },
    backPageCopy: {
        fontSize: '16px',
        fontWeight: '600',
        paddingTop: '2px',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            display: 'none',
        },
    },
    modalContainer: {
        background: 'rgba(255, 255, 255, 0.94)',
    },
    mapInfo: {
        height: '260px',

    },
    errorToastContainer: {
        width: '600px',
        top: '80px',
    },
    errorContainer: {
        background: 'rgba(189, 0, 0, 0.7)',
        borderRadius: 12,
        minHeight: 51,
    },
    errorBody: {
        fontSize: '16px',
        lineHeight: '25px',
        color: '#fff',
        textAlign: 'center',
    },
    fieldContainerTitle: {
        height: '13px',
        margin: '39px 884px 0 0',
        fontFamily: 'MillerBanner-Roman',
        fontSize: '16px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#000',
        display: 'inline-block',
    },
    textField: {
        width: '100%',
        margin: '14px 15px',
        '& input': {
            padding: '10px 0',
            fontSize: '20px',
        },
        '& label': {
            fontSize: '18px',
        },
        '& select': {
            padding: '10px 0',
            fontSize: '20px',
        },
        '& option': {
            padding: '15px',
        },
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            margin: '14px 8px',
        },
    },
    singleLineTextFieldsContainer: {
        display: 'flex',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            flexWrap: 'wrap',
        },
    },
    fieldContainer: {
        backgroundColor: '#fff',
        padding: '15px 21px 25px 21px',
        margin: '10px 0 5px',
    },
    pricesVary: {
        fontFamily: 'URWForm',
        fontSize: '16px',
        color: '#54575a',
        margin: '20px 0px 20px 0px'
    },
    signingUp: {
        fontFamily: 'URWForm',
        fontSize: '16px',
        color: '#54575a',
        textAlign: 'center',
    },
    line: {
        width: '394px',
        height: '1px',
        margin: '13px 2px 20px 0',
        border: 'solid 1px #979797',
    },
    error: {
        textAlign: 'center',
        color: '#FF0000' 
    }
});

const mapStyle = [
    {
        featureType: 'all',
        elementType: 'labels.text.fill',
        stylers: [
            {
                saturation: 36,
            },
            {
                color: '#333333',
            },
            {
                lightness: 40,
            },
        ],
    },
    {
        featureType: 'all',
        elementType: 'labels.text.stroke',
        stylers: [
            {
                visibility: 'on',
            },
            {
                color: '#ffffff',
            },
            {
                lightness: 16,
            },
        ],
    },
    {
        featureType: 'all',
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'administrative',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#fefefe',
            },
            {
                lightness: 20,
            },
        ],
    },
    {
        featureType: 'administrative',
        elementType: 'geometry.stroke',
        stylers: [
            {
                color: '#fefefe',
            },
            {
                lightness: 17,
            },
            {
                weight: 1.2,
            },
        ],
    },
    {
        featureType: 'landscape',
        elementType: 'geometry',
        stylers: [
            {
                color: '#f5f5f5',
            },
            {
                lightness: 20,
            },
        ],
    },
    {
        featureType: 'landscape.natural.landcover',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#ffffff',
            },
        ],
    },
    {
        featureType: 'landscape.natural.terrain',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#ffffff',
            },
        ],
    },
    {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [
            {
                color: '#f5f5f5',
            },
            {
                lightness: 21,
            },
        ],
    },
    {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [
            {
                color: '#dedede',
            },
            {
                lightness: 21,
            },
        ],
    },
    {
        featureType: 'poi.park',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#b1f1bb',
            },
        ],
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#ffffff',
            },
            {
                lightness: 17,
            },
        ],
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry.stroke',
        stylers: [
            {
                color: '#ffffff',
            },
            {
                lightness: 29,
            },
            {
                weight: 0.2,
            },
        ],
    },
    {
        featureType: 'road.arterial',
        elementType: 'geometry',
        stylers: [
            {
                color: '#ffffff',
            },
            {
                lightness: 18,
            },
        ],
    },
    {
        featureType: 'road.local',
        elementType: 'geometry',
        stylers: [
            {
                color: '#ffffff',
            },
            {
                lightness: 16,
            },
        ],
    },
    {
        featureType: 'transit',
        elementType: 'geometry',
        stylers: [
            {
                color: '#f2f2f2',
            },
            {
                lightness: 19,
            },
        ],
    },
    {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [
            {
                color: '#e9e9e9',
            },
            {
                lightness: 17,
            },
        ],
    },
    {
        featureType: 'water',
        elementType: 'geometry.fill',
        stylers: [
            {
                visibility: 'on',
            },
            {
                color: '#ade8ff',
            },
        ],
    },
];
const mapStyles = {
    width: '100%',
    height: '100%',
};
const containerStyle = {
    position: 'relative',
    width: '100%',
    height: '100%',
    borderTop: '1px solid #d1d1d1',
};

// TODO make this component dynamic - remove repetition
const BarflyConfirmPage = ({
    classes,
    barflyConfirmData,
    membershipPrice,
    preferredShop,
    google,
    updatedCustomer,
    newCard,
    setCustomer,
    setNewCard,
    history,
    membershipId,
    location,
    membershipType,
    blowouts,
    additionalOffers,
    rewardsCardNumber,

}) => {
    const [open, setOpen] = React.useState(false);
    const price = membershipPrice || 0;

    const [generalErrorMsg, setGeneralErrorMsg] = useState(null);
    const [firstCheckboxData, setFirstCheckData] = React.useState('');
    const [secondCheckboxData, setSecondCheckData] = React.useState('');
    const [confirmationData, setConfirmationData] = React.useState('');
    const [firstChecked, setFirstChecked] = React.useState(false);
    const [secondChecked, setSecondChecked] = React.useState(false);
    const [validationError, setValidationError] = React.useState(false);
    const [processing, setProcessing] = React.useState(false);

    const finePrintCollection = barflyConfirmData?.finePrint?.json || {};
    const thankYouCollection = barflyConfirmData?.thankYou?.json || {};

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        history.push('/booking/location');
    };

    // const gplQuery = `{
    //     channelResourceCollection(${getPreviewMode()}, where: {slug: "barfly-configurations"}) {
    //         items {
    //             sys {
    //             id
    //             }
    //             configuration
    //         }
    //     }
    // }`;
    useEffect(() => {
        if (!updatedCustomer) {
            history.push('/barfly-membership-enrollment');
        }
        //else {
        //     doQuery(gplQuery).then((data) => {
        //         const channelResourceItem = data?.channelResourceCollection?.items || [];
        //         if (channelResourceItem && channelResourceItem.length > 0) {
        //             setFirstCheckData((channelResourceItem[0].configuration?.barfly?.checkbox_one || '').replace('${price}', `£${ price}`));
        //             setSecondCheckData((channelResourceItem[0].configuration?.barfly?.checkbox_two || '').replace('${price}', `£${ price}`));
        //             setConfirmationData((channelResourceItem[0].configuration?.barfly?.confirmation_text || '').replace('${price}', `£${ price}`));
        //         }
        //     });
        // }

        return () => {
            setCustomer(null);
            setNewCard(null);
        };
    }, []);

    const errorHandlers = (data, defaultErrorMessage = null, errorName = null) => {
        setProcessing(false);

        let errorMessage = null;
        if (data?.ArgumentErrors) {
            errorMessage = `${data?.ArgumentErrors?.[0]?.ArgumentName} - ${data?.ArgumentErrors?.[0]?.ErrorMessage}`;
        } else if (data?.ErrorMessage) {
            errorMessage = data.ErrorMessage;
        } else {
            errorMessage = defaultErrorMessage;
        }

        if (errorMessage) {
            console.log(`${errorName ? `${errorName}: ` : ''}${errorMessage}`);
            toast.error('Please contact Drybar Customer Service.', {
                className: classes.errorContainer,
                bodyClassName: classes.errorBody,
            });
        }
    };



    const placeOrderForMembership = async (orderId, totalPrice) => {
        const { data } = await useFetchAsync(placeMembershipOrder(updatedCustomer.customer.ID.toString(), preferredShop.bookerLocationId, newCard.ID, rewardsCardNumber?.length === 0 ? null : rewardsCardNumber, orderId, totalPrice.Amount,));
        if (data?.IsSuccess) return data;
        errorHandlers(data, 'Cannot add Payment for Order. Please try later.', 'Add Payment to Order');
        return false;
    };

    const onCompleteMembership = async () => {
        // createOrder

        setProcessing(true);

        mParticle.logEvent('Account - Confirm Barfly Membership Upgrade', mParticle.EventType.Other, {
            'Source Page': getPageLabelFromUrl(location.pathname),
            'Page Name': getPageLabelFromUrl(location.pathname),
            'Page Category': 'Barfly | Membership Confirmation',
            'Membership Type': membershipType,
            'Membership Location': preferredShop.bookerLocationId,
        });

        const createOrderResponse = await restClient.post(createOrder(updatedCustomer.customer.ID.toString(), preferredShop.bookerLocationId)).catch(error => {
            if (error?.response?.status === 400) {
                errorHandlers(error?.response?.data?.ErrorMessage, 'Something went wrong. Please try later.', 'create order');
                history.push('/account/barfly-membership');
            }
        });

        if (!createOrderResponse?.data?.IsSuccess) {
            return;
        }

        const orderId = createOrderResponse?.data?.Order?.ID || '';
        const locationId = preferredShop.bookerLocationId;

        let today = new Date();
        today = `${formatDate(today)}T00:00:00+00:00`;


        // //worldpay payment call
        // if(config.barfly.worldpayPayment==="true" || config.drybarEnv==='production')
        // {
        const resPayment = await restClient.post(paymentMembershipInitial(newCard.ID, updatedCustomer.customer.ID.toString(), membershipPrice * 100, orderId, "", "", rewardsCardNumber?.length === 0 ? null : rewardsCardNumber))
            .catch(error => {
                if (error?.response?.status !== 202) {
                    if (error?.response?.status === 404) {
                        toast.error('We’re sorry, but the card you’ve selected appears to have expired. Please add a new card and try again.', {
                            className: classes.errorContainer,
                            bodyClassName: classes.errorBody,
                        });
                    }
                    else {
                        var ref = "";
                        if (error?.response?.data?.ResponseCode !== "")
                            ref = 'Ref: ' + error?.response?.data?.ResponseCode;

                        switch (error?.response?.data?.ResponseCode) {
                            case '51':
                            case '65':
                                setGeneralErrorMsg('We\'re sorry, this card has been declined by your payment provider. Please try again with a different payment method. [' + ref + ']');
                                errorHandlers(error?.response?.data?.ErrorMessage, 'We\'re sorry, this card has been declined by your payment provider.Please try again with a different payment method. [' + ref + ']', 'payment membership initial insufficient funds');
                                break;
                            case '46':
                                setGeneralErrorMsg('We\'re sorry, this card has been declined by your payment provider. Please try again with a different payment method. [Ref: C1]');
                                errorHandlers(error?.response?.data?.ErrorMessage, 'We\'re sorry, this card has been declined by your payment provider.Please try again with a different payment method. [Ref: C1]', 'payment membership initial closed account');
                                break;
                            case '13':
                                setGeneralErrorMsg('We\'re sorry, there was a problem processing this payment.Please try again or use another payment card. [' + ref + ']');
                                errorHandlers(error?.response?.data?.ErrorMessage, 'We\'re sorry, there was a problem processing this payment.Please try again or use another payment card. [' + ref + ']', 'payment membership initial bank declined');
                                break; 
                            default:
                                setGeneralErrorMsg('We\'re sorry, there was a problem processing this payment.Please check your card details or try another payment method. [' + ref + ']');
                                errorHandlers(error?.response?.data?.ErrorMessage, 'We\'re sorry, there was a problem processing this payment.Please check your card details or try another payment method. [' + ref + ']', 'payment membership initial');
                                break;
                        }
                    }
                }
            });

        if (resPayment?.status !== 202) {
            return;
        }


        // add membership to order
        const { data: data1, error: error1 } = await useFetchAsync(addMembershipToOrder({
            orderId, locationId, billingCycleStartDate: today, membershipId, initiationFee: membershipPrice
        }));
        if (error1) {
            console.log(error1);
        }

        if (!data1.IsSuccess) {
            errorHandlers(data1, 'Something went wrong. Please try later.', 'Add membership to order');
            return;
        }

        const isPlaceOrder = await placeOrderForMembership(orderId, data1.Order.GrandTotal);
        if (!isPlaceOrder) return;

        else handleClickOpen();

        const { gtm: { formCompleteEvent, purchaseEvent } } = config;
        const tagManagerCompleteArgs = {
            dataLayer: {
                event: formCompleteEvent,
                form_type: 'membership_signup',
            },
        };

        const gtmProducts = isPlaceOrder?.Order?.Items?.map((item) => ({
            id: item.ID,
            name: membershipType,
            price: membershipPrice,
            category: 'membership',
            quantity: 1,
        }));
        const tagManagerPaymentArgs = {
            dataLayer: {
                event: purchaseEvent,
                locationId,
                locationName: preferredShop?.Name, // per Steve, via order payload
                ecommerce: {
                    currencyCode: 'USD', // optional
                    purchase: {
                        actionField: {
                            id: orderId, // Booker Transaction ID
                            affiliation: 'Drybar Holdings',
                            revenue: membershipPrice, // Incl. tax and shipping. Format like price
                        },
                        products: gtmProducts,
                    },
                },
            },
        };
        TagManager.dataLayer(tagManagerCompleteArgs);
        TagManager.dataLayer(tagManagerPaymentArgs);
        //  else {
        //     errorHandlers(data, 'Can not create the Order for Customer. Please try later.', 'Create order');
        // }

        setProcessing(false);
    };

    const _mapLoaded = (mapProps, map) => {
        map.setOptions({
            styles: mapStyle,
        });
    };

    // map center pos
    const initialCenter = {
        lat: preferredShop?.contact?.coordinates?.[0],
        lng: preferredShop?.contact?.coordinates?.[1],
    };

    if (!updatedCustomer) {
        history.push('/barfly-membership-enrollment');
    }

    return (
        <Grid className={classes.container}>
            <Grid className={classes.titleContainer}>
                <Grid style={{ paddingTop: '12px' }}>
                    <Link to="/barfly-membership-enrollment" className={classes.displayFlex}>
                        <ArrowBackIosIcon style={{ fontSize: '34px' }} />
                        <Typography className={classes.backPageCopy}>Back</Typography>
                    </Link>
                </Grid>
                <Typography className={classes.mainTitle}>
                    Let’s confirm your details
                    {' '}
                    <Box className={classes.subTitle}>
                        and finalize your barfly membership
                    </Box>
                </Typography>
            </Grid>
            <Grid container className="justify-content-between">
                <Grid item sm={6} xs={12}>
                    <Grid item className={classes.summaryContainer}>
                        <Typography className={classes.summaryTitle}>
                            Summary of Charges
                        </Typography>
                        <Grid className={classes.membershipCharge}>
                            <Grid className={classes.serviceChargeCotainer}>
                                <Grid className={classes.serviceChargeData}>
                                    <Grid>
                                        <Grid className={classes.headingSpace}>
                                            <Grid className={classes.nameAndPrice}>
                                                <Box className={classes.theBarflySingle}>
                                                    {membershipType}
                                                </Box>
                                                <Box className={classes.thePrice}>
                                                    &#163;
                                                    {price}/month
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <Grid className={classes.allTheBenefits}>
                                            <Box className={classes.blowDryPerMonth}>
                                                {blowouts}
                                            </Box>
                                            {additionalOffers.map((item) => (<Box className={classes.blowDryPerMonth}>
                                                {item.name + " " + item.value}
                                            </Box>))}</Grid></Grid>
                                    <Grid>
                                    </Grid>
                                </Grid>

                                {/* <Box className={classes.slashVector} >/</Box>
                                    <Box className={classes.monthCopy}>
                                        month
                                    </Box> */}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item sm={6} xs={12}>
                    <Grid item className={classes.summaryContainer}>
                        <Typography className={classes.summaryTitle}>
                            Preferred Shop
                        </Typography>
                        <Grid className={`${classes.membershipCharge}`}>
                            <Grid className={classes.locationDetails}>
                                <Typography
                                    className={classes.drybarLocation}
                                    style={{
                                        fontWeight: '600',
                                        fontSize: '20px',
                                        lineHeight: '28px',
                                        color: '#54575a',
                                    }}
                                >
                                    {preferredShop.title}
                                </Typography>
                                <Typography
                                    className={classes.drybarLocation}
                                    style={{ fontSize: '16px', lineHeight: '20px', marginTop: '15px', color: '#54575a', }}
                                >
                                    {`${preferredShop.contact?.street1}, ${preferredShop.contact?.city},
                                       ${preferredShop.contact?.postalCode}`}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid className={classes.mapInfo}>
                            <Map
                                google={google}
                                containerStyle={containerStyle}
                                zoom={14}
                                style={mapStyles}
                                initialCenter={
                                    initialCenter
                                }
                                center={initialCenter}
                                // bounds={bounds}
                                onReady={(mapProps, map) => _mapLoaded(mapProps, map)}
                            >
                                <Marker
                                    title={preferredShop?.contact?.street1}
                                    name={preferredShop?.title}
                                    position={initialCenter}
                                    icon={markerIcon}
                                    style={{ margin: '-51px 0px -12px 155px' }}

                                />
                            </Map>
                            {/* <img src={preferredShop.mapImage?.url} alt="confirmed-map"
                                     className={classes.confirmedMap}/> */}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <div className={classes.pricesVary}>
                <p>* Membership prices may vary depending on location. For H beauty Barfly members, please note that there will be an additional surcharge to redeem services and treatments in the Drybar Knightsbridge location.</p>
            </div>

            {rewardsCardNumber.length !== 0 &&
                <><Grid>
                    <Typography className={classes.fieldContainerTitle}>HARRODS REWARDS CARD NUMBER</Typography>
                </Grid>
                    <Grid className={classes.fieldContainer}>
                        <Grid className={classes.singleLineTextFieldsContainer}>
                        </Grid>
                        <TextField
                            value={rewardsCardNumber}
                            className={classes.textField}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            required={false}
                            disabled={true}
                        />
                    </Grid></>}

            <Typography className={classes.finePrintHeading}>
                THE FINE PRINT
            </Typography>
            <Grid className={classes.theFinePrint}>
                <DocumentToComponent doc={finePrintCollection} />



            </Grid>


            <div className={"general-errors"}>
                <div id="general-error-msg" className={classes.error} >{generalErrorMsg}</div>
            </div>

            <div className={classes.proceedSelectedAction}>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={processing}
                    className={classes.submitButton}
                    onClick={() => {
                        onCompleteMembership();
                    }}
                >
                    Complete Membership
                </Button>
            </div>

            <div className={classes.signingUp}>
                <p> By signing up to become a Barfly member, I understand that I will be charged a monthly membership fee until my membership is cancelled and I agree to
                    the <a target='_blank' href="https://www.drybarshops.co.uk/help-centre/membership-policy" >Barfly Membership Terms</a> and&nbsp;
                    <a target='_blank' href="https://www.drybarshops.co.uk/help-centre/privacy-policy" >Drybar Privacy Policy</a>.</p>

            </div>

            <ToastContainer
                style={{
                    maxWidth: 640, top: 50, width: '100%', paddingLeft: 20, paddingRight: 20,
                }}
                position="top-center"
                closeButton={false}
            />
            <Grid>
                <Dialog
                    maxWidth="md"
                    onClose={handleClose}
                    aria-labelledby="max-width-dialog-title"
                    open={open}
                    classes={{
                        paperWidthMd: classes.dialogContainer,
                        container: classes.modalContainer,
                    }}
                >
                    <Grid style={{ display: 'flex', justifyContent: 'space-between', paddingRight: 24 }}>
                        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                            <Typography className={classes.thankYouHeading}>
                                {thankYouCollection?.content[0]?.content[0]?.value}
                            </Typography>
                        </DialogTitle>
                        <CloseIcon onClick={handleClose} className={classes.closeIcon} />
                    </Grid>

                    <DialogContent className={classes.thankYouDesc}>
                        <Typography className={classes.copyDetails}>
                            {thankYouCollection?.content[1]?.content[0]?.value}
                        </Typography>
                        {/* <DocumentToComponent doc={thankYouCollection} /> */}
                    </DialogContent>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.bookYourAppointmentToday}
                        onClick={() => {
                            history.push('/booking/location');
                        }}
                    >
                        Book Your Appointment Today
                    </Button>
                </Dialog>
            </Grid>
        </Grid>
    );
};

BarflyConfirmPage.propTypes = {
    classes: object.isRequired,
    barflyConfirmData: object.isRequired,
    google: object,
};

BarflyConfirmPage.defaultProps = {
    google: {},
};

const mapDispatchToProps = (dispatch) => ({
    setCustomer: bindActionCreators(setUpdatedCustomer, dispatch),
    setNewCard: bindActionCreators(setCard, dispatch),
});

const mapStateToProps = (state) => ({
    membershipPrice: getBarflyMembershipPrice(state),
    preferredShop: getChosenStore(state),
    updatedCustomer: getUpdatedCustomer(state),
    newCard: getNewCard(state),
    membershipId: getBarflyMembershipID(state),
    membershipType: getBarflyMembershipType(state),
    blowouts: getBarflyBlowouts(state),
    additionalOffers: getBarflyAdditionalOffers(state),
    rewardsCardNumber: getRewardsCardNumber(state),
});

// export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(withOktaAuth(BarflyConfirmPage)));
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(GoogleApiWrapper({
    apiKey: 'AIzaSyAixN59eNVJmUFjTac2K4KCB88lM6zP4t4',
})(withStyles(styles)(BarflyConfirmPage))));
