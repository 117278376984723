/* eslint-disable import/prefer-default-export */

import subscribeActionTypes from './Subscribe-ActionTypes';

export const loadSubscribeContentful = (slug) => ({
    type: subscribeActionTypes.SUBSCRIBE_CONTENT_LOAD,
    payload: slug,
});

export const setSubscribeContent = (payload) => ({
    type: subscribeActionTypes.SUBSCRIBE_SET_CONTENT,
    payload,
});

export const setSubscribeLoaded = (payload) => ({
    type: subscribeActionTypes.SUBSCRIBE_SET_LOADED,
    payload,
});
