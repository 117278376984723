/* eslint-disable import/prefer-default-export */

import resyActionTypes from './Resy-ActionTypes';

export const loadResyContentful = () => ({
    type: resyActionTypes.RESY_CONTENT_LOAD,
});

export const setResyContent = (payload) => ({
    type: resyActionTypes.RESY_SET_CONTENT,
    payload,
});
