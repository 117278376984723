const ACCOUNT_VIEW_APPOINTMENT = 'ACCOUNT/VIEW_APPOINTMENT';
const ACCOUNT_NEXT_APPOINTMENT_DATE = 'ACCOUNT/NEXT_APPOINTMENT_DATE';
const SET_FAVORITE_STORE = 'ACCOUNT/SET_FAVORITE_STORE';
const SET_AUTH_USER_DETAILS = 'ACCOUNT/AUTH_USER_DETAILS';

export default {
    ACCOUNT_VIEW_APPOINTMENT,
    ACCOUNT_NEXT_APPOINTMENT_DATE,
    SET_FAVORITE_STORE,
    SET_AUTH_USER_DETAILS,
};
