import {
    Button,
    Grid,
    Typography,
    withStyles,
    Backdrop,
    CircularProgress,
    FormControl,
    Select,
    MenuItem,
} from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import jwtDecode from 'jwt-decode';
import { object } from 'prop-types';
import React, { useRef, useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { PASSWORD_SPECIAL_OR_NUM_REGEX, PASSWORD_UPPER_CASE_REGEX, PASSWORD_LOWER_CASE_REGEX } from '../../../../src/utils/validation/regex'
import { getCustomer } from 'api/booking-api';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getPageLabelFromUrl } from 'utils/helper';
import restClient from 'api/restClient';
import EditableTextField from '../../../app/Components/EditableTextField/EditableTextField';
import { MOBILE_BREAKPOINT } from '../../../Helpers/breakpoints';
import { ValidateLink, CreateUserIdentity } from '../../../../src/api/auth-api'
import TextInputField from 'app/Components/Auth/Partials/TextInputField';
import { getCountryCodes } from 'app/Components/Auth/Partials/Registration/signupFormFields';


const styles = (theme) => ({
    container: {
        background: '#fff',
        marginLeft: '15px',
        padding: '34px 22px',
        textAlign: 'center',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            background: '#f9f9f9',
            marginLeft: 0,
        },
    },

    errorStyle: {
        color: '#EE0000',
        fontSize: '0.75rem',
        textAlign: 'left',
        fontWeight: 400,
        lineHeight: 1.66
    },
    tipStyle: {
        color: 'Gray',
        fontSize: '0.75rem',
        textAlign: 'left',
        fontWeight: 400,
        lineHeight: 1.66,
    },

    successStyle: {
        color: 'green',
        fontSize: '0.75rem',
        textAlign: 'left',
        fontWeight: 400,
        lineHeight: 1.66
    },

    dropDownContainer: {
        padding: '12px 0px 0px 0px',
        marginRight: '25px',
        minWidth: '100px'
    },

    inputContainer: {
        display: 'flex',
        width: '100%'
    },

    heading: {
        fontWeight: '800',
        textAlign: 'left',
        paddingBottom: '10px !important',
        borderBottom: '1px solid #D1D1D1',
        color: '#42413D',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            textTransform: 'uppercase',
            fontFamily: 'MrsEavesOT-Roman',
            fontWeight: 'normal',
        },
    },

    borderlessHeading: {
        fontWeight: '800',
        textAlign: 'left',
        paddingBottom: '10px !important',
        color: '#42413D',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            textTransform: 'uppercase',
            fontFamily: 'MrsEavesOT-Roman',
            fontWeight: 'normal',
        },
    },


    errorHeading: {
        fontWeight: '800',
        textAlign: 'center',
        paddingBottom: '10px !important',
        color: '#42413D',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            textTransform: 'uppercase',
            fontFamily: 'MrsEavesOT-Roman',
            fontWeight: 'normal',
        },
    },
    formContainer: {
        padding: '30px 0 0',
    },

    button: {
        width: '378px',
        height: '63px',
        maxWidth: '100%',
        margin: '20px 0 48px',
    },

    dialog: {
        padding: '25px',
    },
    backdrop: {
        zIndex: 11,
        color: '#fff',
    },
    modalTitle: {
        textAlign: 'center',
        fontSize: '31px',
        fontFamily: 'DINCondensed-Regular',
        lineHeight: '37px',
        textTransform: 'uppercase',
        fontWeight: '600',
    },
    passwordIcon: {
        verticalAlign: 'middle',
        fontSize: '14px'
    }
});

const SetPassword = ({
    classes
}) => {
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
    const [showLoading, setLoading] = useState(false);
    const [touched, setTouched] = useState({});
    const [passwordErrors, setPasswordErrors] = useState({});
    const [passwordSuccesses, setPasswordSuccesses] = useState([]);
    const [passwordValues, setPasswordValues] = useState({});
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({});
    const passwordLabels = { newPassword: "New password", confirmNewPassword: "Confirm new password" };
    const passwordFields = ["newPassword", "confirmNewPassword"];
    const [verifyPasswords, setVerifyPasswords] = useState(false);
    const [displayTips, setDisplayTips] = useState(false);
    const [displayTipValidation, setDisplayTipValidation] = useState(false);
    const [tips, setTips] = useState({ uppercase: "An upper case character", lowercase: "A lowercase character", special: "A number or special character", count: "At least 10 characters", previous: "You cannot use passwords that have been used before." })
    const [vLink, setvLink] = useState("");
    const [isLinkValid, setIsLinkValid] = useState(false);

    useEffect(() => {
        mParticle.logEvent('Account - Edit Profile', mParticle.EventType.Other, {
            'Source Page': getPageLabelFromUrl(location.pathname),
        });
        initPage();
        initSearchParam();
        initValidateLink();
    }, [vLink]);


    const initPage = () => {
        setPasswordValues(initPasswordValues());
        setTouched(initTouched());
        setPasswordErrors(initErrors());
    }

    const initPasswordValues = () => {
        const initPasswordValues = {}
        for (const field of passwordFields) {
            initPasswordValues[field] = "";
        }
        return initPasswordValues;
    }

    const initValidateLink = async () => { 
        await restClient.post(ValidateLink(
            vLink
        )).then((res) => {
            if (res.status === 200)
                setIsLinkValid(true);
            else
                setIsLinkValid(false);
        });
        setIsLinkValid(true);
    }

    const initSearchParam = () => {
        const params = new URLSearchParams(window.location.search);
        setvLink(params.get('vLink'));
    }

    const initErrors = () => {
        const initErrors = {};
        for (const field of passwordFields) {
            initErrors[field] = [];
        }
        return initErrors;
    }

    const initTouched = () => {
        const initTouched = {};
        for (const field of passwordFields) {
            initTouched[field] = false;
        }
        return initTouched;
    }

    const setPasswordsTouched = () => {
        const setPasswordsTouched = {}
        for (const field of passwordFields) {
            setPasswordsTouched[field] = true;
        }
        return setPasswordsTouched;
    }

    const handlePasswordFieldsChange = (e) => {
        const newPasswordValues = { ...passwordValues, [e.target.name]: e.target.value };
        const newTouched = { ...touched, [e.target.name]: true }
        const newPasswordValidation = validatePassword(newPasswordValues, newTouched);
        setTouched(newTouched);
        setPasswordErrors(newPasswordValidation.Errors);
        setPasswordSuccesses(newPasswordValidation.Successes);
        setPasswordValues(newPasswordValues);
        setPassword(e.target.value);
    };

    const verifyPasswordsMatch = (e) => {
        setVerifyPasswords(true);
    }

    const hasFormErrors = (errors) => {
        let hasErrors = false;
        for (const field in errors) {
            if (errors[field].length > 0) {
                hasErrors = true;
            }
        }
        return hasErrors;
    }

    const hasPasswordErrors = (fieldName, passwordErrors) => passwordErrors[fieldName] && passwordErrors[fieldName].length > 0;

    const hasPasswordSuccesses = (passwordSuccesses) => passwordSuccesses.length > 0;

    const renderValidationErrorMsg = (errorMsg) =>
        <div className={classes.errorStyle}>
            <CloseIcon className={classes.passwordIcon} />
            <span style={{ verticalAlign: 'middle' }}>{errorMsg}</span>
        </div>

    const renderValidationSuccessMsg = (successMsg) =>
        <div className={classes.successStyle}>
            <DoneIcon className={classes.passwordIcon} />
            <span style={{ verticalAlign: 'middle' }}>{successMsg}</span>
        </div>

    const renderPasswordTip = (tip) =>
    (<div className={classes.tipStyle}>
        <span style={{ verticalAlign: 'middle' }}>{tip}</span>
    </div>)

    const validatePassword = (newPasswordValues, newTouched, confirmPasswordsMatch) => {

        // reset the password errors and successes
        const errors = { ...initErrors() };
        const successes = [];

        // validate the required fields if they have been touched
        for (const field in newPasswordValues) {
            if (newTouched[field] && !(newPasswordValues[field]?.length > 0)) {
                errors[field].push(`${passwordLabels[field]} is required`);
            }
        };

        // validate password and store errors and successful validation messages
        if (newPasswordValues.newPassword) {

            if (newPasswordValues.newPassword?.length >= 10) {
                successes.push(tips["count"])
            } else {
                errors.newPassword.push(tips["count"])
            }

            if (PASSWORD_SPECIAL_OR_NUM_REGEX.test(newPasswordValues.newPassword)) {
                successes.push(tips["special"])
            } else {
                errors.newPassword.push(tips["special"])
            }

            const passwordLowerCaseMsg = "A lower case character";
            if (PASSWORD_LOWER_CASE_REGEX.test(newPasswordValues.newPassword)) {
                successes.push(tips["lowercase"])
            } else {
                errors.newPassword.push(tips["lowercase"])
            }

            const passwordUpperCaseMsg = "An upper case character";
            if (PASSWORD_UPPER_CASE_REGEX.test(newPasswordValues.newPassword)) {
                successes.push(tips["uppercase"])
            } else {
                errors.newPassword.push(tips["uppercase"])
            }
        }

        // validate the confirm password if it has been touched and is the same as the password
        if (confirmPasswordsMatch && newPasswordValues.confirmNewPassword !== newPasswordValues.newPassword || verifyPasswords && newTouched["confirmNewPassword"] && newPasswordValues.confirmNewPassword !== newPasswordValues.newPassword && newPasswordValues.confirmNewPassword !== "") {
            errors.confirmNewPassword.push('Password did not match');
        }

        // return both errors and success messages
        return { Errors: errors, Successes: successes };

    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setDisplayTips(true);
        setDisplayTipValidation(true);
        const passwordsTouched = setPasswordsTouched();
        setTouched(passwordsTouched);
        const passwordValidation = validatePassword(passwordValues, passwordsTouched, true);
        const errors = passwordValidation.Errors;
        const successes = passwordValidation.Successes;
        if (hasFormErrors(errors)) {
            setPasswordErrors(errors);
            setPasswordSuccesses(successes);
            setLoading(false);
            return
        }
        else {
            try { 
                await restClient.post(CreateUserIdentity(
                    vLink,
                    password,
                )).then((res) => { 
                    if (res.status === 200)
                        window.location.href = "/auth/login";
                    else
                        setIsLinkValid(false);
                });

                setShowConfirmationDialog(true);
            } catch (err) {
                const errors = initErrors();
                if (err.response?.status == 400) {
                    setErrors({ generic: 'An error has occured, please try again later.' }); 
                }
                setPasswordErrors(errors);
                console.error();
                setLoading(false);
            }
            setLoading(false);
        };
    }

    return (
        <>
            {isLinkValid ?
                <Grid className={classes.container}>
                    <Grid className={classes.formContainer}>
                        <form onSubmit={handleSubmit}>
                            <Typography className={classes.borderlessHeading}>
                                Set Password
                            </Typography>
                            <>
                                <Grid>
                                    <EditableTextField
                                        style={{ marginTop: '27px' }}
                                        placeholder="Your new password here..."
                                        type="password"
                                        label="New Password"
                                        name="newPassword"
                                        onChange={(e) => { handlePasswordFieldsChange(e); setDisplayTipValidation(true); }}
                                        onFocus={() => { setDisplayTips(true) }}
                                        inputProps={{ maxLength: 128 }}
                                        error={errors && touched.newPassword && errors.newPassword}
                                        helperText={errors && touched.newPassword && errors.newPassword}
                                    />
                                    {displayTips ?
                                        <>
                                            {passwordErrors["newPassword"].includes(tips["previous"]) ? renderValidationErrorMsg(tips["previous"]) : null}
                                            {hasPasswordErrors("newPassword", passwordErrors) ?
                                                <div className={classes.errorStyle} style={{ marginBottom: '3px' }}>
                                                    <span style={{ verticalAlign: 'middle' }}> Please enter a password containing:</span>
                                                    <br></br>
                                                </div>
                                                : null
                                            }
                                            {displayTipValidation ? (passwordSuccesses.includes(tips["count"]) ? renderValidationSuccessMsg(tips["count"]) : renderValidationErrorMsg(tips["count"])) : renderPasswordTip(tips["count"])}
                                            {displayTipValidation ? (passwordSuccesses.includes(tips["uppercase"]) ? renderValidationSuccessMsg(tips["uppercase"]) : renderValidationErrorMsg(tips["uppercase"])) : renderPasswordTip(tips["uppercase"])}
                                            {displayTipValidation ? (passwordSuccesses.includes(tips["lowercase"]) ? renderValidationSuccessMsg(tips["lowercase"]) : renderValidationErrorMsg(tips["lowercase"])) : renderPasswordTip(tips["lowercase"])}
                                            {displayTipValidation ? (passwordSuccesses.includes(tips["special"]) ? renderValidationSuccessMsg(tips["special"]) : renderValidationErrorMsg(tips["special"])) : renderPasswordTip(tips["special"])}
                                        </>
                                        : null
                                    }
                                </Grid>
                                <Grid>
                                    <EditableTextField
                                        style={{ marginTop: '27px' }}
                                        placeholder="Your new pasword confirmation here..."
                                        type="password"
                                        label="Confirm New Password"
                                        name="confirmNewPassword"
                                        onChange={handlePasswordFieldsChange}
                                        inputProps={{ maxLength: 128 }}
                                        error={errors && touched.confirmNewPassword && errors.confirmNewPassword}
                                        helperText={errors && touched.confirmNewPassword && errors.confirmNewPassword}
                                        onBlur={verifyPasswordsMatch}
                                    />
                                    <p>{hasPasswordErrors("confirmNewPassword", passwordErrors) && passwordErrors["confirmNewPassword"].map(msg => renderValidationErrorMsg(msg))}</p>
                                </Grid>
                            </>
                            <Grid>
                                <Button
                                    className={classes.button}
                                    variant="contained"
                                    color="secondary"
                                    type="submit"
                                >
                                    Save
                                </Button>
                            </Grid>
                        </form>
                        {errors.generic ? (
                            <Typography color="error">
                                {`Error: ${errors.generic}`}
                            </Typography>
                        ) : null}
                    </Grid>
                    {showLoading ? (
                        <Backdrop className={classes.backdrop} open>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    ) : null}
                </Grid>

                :
                <Typography className={classes.errorHeading}>
                    An error has occurred processing your request.
                </Typography>
            }
        </>
    );
};

SetPassword.propTypes = {
    classes: object.isRequired,
    user: object.isRequired,
};


export default connect(null, null)(withRouter(withStyles(styles)(SetPassword)));