import React from 'react';
import { Hyperlink } from '../app/Components/Links/Hyperlink';
import slugify from 'slugify';
import { isExternalURL } from '../utils/helper';

const normalizeValue = (value) => slugify(value, {
    replacement: '_',
    lower: true,
});

export const Actions = ({
 data, className, showModal, ...rest
}) => {
    if (!(data && data.action && data.action.title)) {
        return null;
    }

    // TODO: conditionally set the link value based on `data.action.reference`
    const { link } = data.action;
    const style = normalizeValue(data.action.style || '');
    const wrapperStyles = normalizeValue(data.settings.title.color || '');

    return (
        <span className={[className, wrapperStyles].join(' ')}>
            {
                showModal
                    ? <button onClick={showModal} className={`${style}`} {...rest}>{data?.action.title}</button>
                    : (
                        isExternalURL(link) ? <Hyperlink to={link} href={link} className={`${style}`} draggable="false" {...rest}>{data?.action.title}</Hyperlink>
                        : (
                            <Hyperlink to={link} className={`${style}`} {...rest}>
                                {data?.action.title}
                                <span className="visually-hidden">{data.title}</span>
                            </Hyperlink>
)
                    )
            }
        </span>
    );
};

export const Subtitle = ({ data, className, ...rest }) => {
    if (!(data && data.subtitle && data.subtitle.length)) {
        return null;
    }

    return (
        <p dangerouslySetInnerHTML={{ __html: data.subtitle }} className={className} {...rest} />
    );
};

const titleSettings = (data) => {
    if (!(data.settings && data.settings.title)) {
        return [];
    }

    return Object.keys(data.settings.title).map((key) => {
        if (data.settings.title[key] && data.settings.title[key] !== true) {
            return `title_${normalizeValue(key)}__${normalizeValue(data.settings.title[key].toString())}`;
        } if (data.settings.title[key]) {
            return `title_${normalizeValue(key)}`;
        }
        return '';
    });
};

export const Title = ({
 data, className, as, ...rest
}) => {
    if (!(data && data.title && data.title.length)) {
        return null;
    }

    if (as === 'span') {
        return (
            <span className={`${className} ${titleSettings(data).join(' ')}`} {...rest}>
                {data.title.toUpperCase()}
            </span>
        );
    }

    return (
        <h3 className={`${className} ${titleSettings(data).join(' ')}`} {...rest}>
            {data.title.toUpperCase()}
        </h3>
    );
};

// TODO -- consider the other conditions that might drive the image alt text
const altText = (data) => {
    if (data.image && data.image.alternateText) {
        return data.image.alternateText;
    }

    if (data.image && data.image.title) {
        return data.image.title;
    }

    if (data.title && data.title.length) {
        return data.title;
    }

    return null;
};

// TODO -- Confirm <div className="mask rgba-black-light" /> is needed, as I
// did not see any styles referencing it
// TODO -- Account for Mobile image
// TODO -- Account for additional responsiveness
export const Image = ({ data, className, ...rest }) => {
    if (!(data && data.image)) {
        return null;
    }

    const isSupportingWebpFormat = (format) => format === 'image/jpeg' || format === 'image/png';

    return (
        <picture>
            {
                data.mobileImage?.url ? (
                    <>
                        {
                            isSupportingWebpFormat(data.image.format) ? (
                                <source
                                    type="image/webp"
                                    media="(min-width: 768px)"
                                    srcSet={`${data.image.url}?fm=webp`}
                                />
                            ) : (
                                <source
                                    type={`${data.image.format}`}
                                    media="(min-width: 768px)"
                                    srcSet={`${data.image.url}`}
                                />
                            )
                        }
                        {
                            isSupportingWebpFormat(data.mobileImage.format) ? (
                                <source
                                    type="image/webp"
                                    media="(max-width: 768px)"
                                    srcSet={`${data.mobileImage.url}?fm=webp`}
                                />
                            ) : (
                                <source
                                    type={`${data.mobileImage.format}`}
                                    media="(max-width: 767px)"
                                    srcSet={`${data.mobileImage.url}`}
                                />
                            )
                        }
                    </>
                ) : (
                    <>
                        <source
                            type={`${data.image.format}`}
                            srcSet={`${data.image.url}`}
                        />
                    </>
                )
            }

            <img src={data.image.url} alt={altText(data)} className={className} {...rest} />
        </picture>
    );
};

export const Avatar = ({ data, className, ...rest }) => {
    if (!(data && data.avatar)) {
        return null;
    }

    return (
        <div className="icon_img">
            <span>
                <img src={data.avatar} alt={altText(data)} className={className} {...rest} />
            </span>
        </div>
    );
};

export const Icon = ({ data, className, ...rest }) => {
    if (!(data && data.icon)) {
        return null;
    }

    return (
        <div className="icon_img">
            <img src={data.icon} alt={altText(data)} className={className} {...rest} />
        </div>
    );
};

export const Link = ({
 data, href, children, className, ...rest
}) => {
    const childrenWithProps = React.Children.map(children, (child) => (React.isValidElement(child) ? React.cloneElement(child, { data }) : child));

    return (
        <Hyperlink to={href} className={className} {...rest}>
            {childrenWithProps}
        </Hyperlink>
    );
};

const containerSettings = (data) => {
    if (!(data.settings && data.settings.container)) {
        return [];
    }

    return Object.keys(data.settings.container).map((key) => `container_${normalizeValue(key)}__${normalizeValue(data.settings.container[key].toString())}`);
};

export const CardContainer = ({
data, children, className, ...rest
}) => {
    const childrenWithProps = React.Children.map(children, (child) => (React.isValidElement(child) ? React.cloneElement(child, { data }) : child));

    return (
        <div className={`${className} ${containerSettings(data).join(' ')}`} {...rest}>
            {childrenWithProps}
        </div>
    );
};

// export const CardHead = CardContainer
export const CardHead = ({
data, children, className, ...rest
}) => {
    const childrenWithProps = React.Children.map(children, (child) => (React.isValidElement(child) ? React.cloneElement(child, { data }) : child));

    return (
        <div className={className} {...rest}>
            {childrenWithProps}
        </div>
    );
};

// export const CardBody = CardContainer
// export const Icon = Avatar
export const CardBody = CardHead;
