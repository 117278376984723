/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import React from 'react';
import {
    array,
    object,
} from 'prop-types';
import {
    Typography, Grid, Button,
} from '@material-ui/core';
import Slider from 'react-slick';
import { withRouter } from 'react-router-dom';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-magic-slider-dots/dist/magic-dots.css';
import MagicSliderDots from 'react-magic-slider-dots';
import { getPageLabelFromUrl } from 'utils/helper';
import { DocumentToComponent } from '../../../../../../../components';

const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <>
            <div className="slick-arrow right" />
        </>
    );
};
const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <>
            <div className="slick-arrow left" />
        </>
    );
};

const parseAnswer = (resultedText) => {
    if (!resultedText) {
        return '';

        console.log(resultedText);
    }

    const linkRegex = /\[([^\]]+)\]\(([^\)]+)\)/g;
    resultedText = resultedText?.replace(linkRegex, (match, p1, p2) => {
        return `<a href="${p2?.replace(']', '')}">${p1}</a>`;
    });

    const boldRegex = /__(.*?)__/g;
    resultedText = resultedText.replace(boldRegex, (match, p1) => {
        return `<strong>${p1}</strong>`;
    });

    return resultedText;
};

const ServicesAddOnsCollection = ({
                                      classes, bookAddOnsData, history, location,
                                  }) => {
    const bookAddOnsCollection = bookAddOnsData || [];
    const defaultAddOnsSubtitle = 'Miracle Smoothing Sealant';
    const isBlowServices = location?.pathname?.includes('blow-services');

    const handleMeClick = (item) => {
        mParticle.logEvent('Services - Book an Appointment', mParticle.EventType.Other, { 'Source Page': getPageLabelFromUrl(location.pathname), Service: item?.title });
        history.push('/booking/location');
        window.scrollTo(0, 0)
    };
    const settings = {
        dots: true,
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        appendDots: (dots) => (
            <MagicSliderDots
                dots={dots}
                numDotsToShow={4}
                dotWidth={30}
                dotContainerClassName={`magic-dots slick-dots ${classes.sliderDotsWrap}`}
            />
),
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
    };

    return (
        <Grid container className={classes.collectionCotainer}>
            {bookAddOnsCollection.map((items) => (
                <>
                    <Grid item xs={3} className={classes.bookYourAddOns}>
                        <Slider {...settings}>
                            {items?.imagesCollection?.items?.map((imageCollection) => (
                                <img
                                    src={imageCollection?.desktopMedia?.url}
                                    alt={imageCollection?.alternateTitle || ''}
                                    className={classes.imageAddOns}
                                />
                            ))}
                        </Slider>
                        <Grid className={classes.addOnsDetails}>
                            <Grid className={!isBlowServices ? classes.addOnsDetailsContent : ''}>
                                {!isBlowServices ? <Typography className={classes.addOnsCopy}>{items?.title}</Typography> : null}
                                <Grid className={classes.displayflex}>
                                    {isBlowServices ? (
                                        <>
                                            <Typography className={classes.addOnsCopy}>{items?.title}</Typography>
                                            {items.price
                                            && (
                                            <Typography className={`${classes.addOnsTextBold} ${classes.margin}`}>
                                                &#163;
                                                {items.price}
                                            </Typography>
)}
                                        </>
                                    ) : (
                                        <>
                                            <Typography
                                                className={classes.subtitle}
                                            >
                                                {items?.subtitle || defaultAddOnsSubtitle}
                                            </Typography>
                                            {items.price
                                            && (
                                            <Typography className={`${classes.addOnsTextBold} ${classes.marginLeft}`}>
                                                &#163;
                                                {items.price}
                                            </Typography>
)}
                                        </>
                                    )}
                                </Grid>
                                {isBlowServices ? (
                                    <>
                                        <Grid className={classes.addOnsDescServices}>
                                            <Typography className={`${classes.addOnsTextBold} ${classes.howWorksMargin}`}>
                                                How
                                                It Works
                                            </Typography>
                                            <Typography className={classes.addOnsDescJson} dangerouslySetInnerHTML={{ __html: parseAnswer(items?.description?.json?.content?.[0]?.content?.[0]?.value) }} >
                                            </Typography>
                                            {
                                                items.serviceTime                                               
                                                ? <Grid className={classes.serviceTime}>
                                                        <Typography className={classes.servicesTime}>
                                                            Approximate Service
                                                            Time
                                                        </Typography>
                                                        <Typography
                                                            className={classes.addOnsTextBold}
                                                        >
                                                            {items.serviceTime}
                                                            {' '}
                                                            mins
                                                        </Typography>
                                                    </Grid>
                                                : null
                                            }
                                        </Grid>
                                    </>
                                ) : (
                                    <>
                                        <Grid className={classes.addOnsDesc}>

                                            <Typography className={classes.addOnsDescJson}>{items?.description?.json?.content?.[0]?.content?.[0]?.value}</Typography>

                                        </Grid>
                                    </>
                                )}
                            </Grid>

                            {items.bestFor && !isBlowServices && (
                            <div>
                                <Typography className={classes.bestForCopy}>Best For</Typography>
                                <Typography className={classes.subtitle}>{items.bestFor}</Typography>
                            </div>
)}
                            {
                                !items.bestFor && !isBlowServices && <div style={{ marginTop: 24, minHeight: 49 }} />
                            }
                        </Grid>
                        <Button
                            onClick={() => handleMeClick(items)}
                            className={classes.selected}
                            variant="outlined"
                        >
                            Book Now
                        </Button>
                    </Grid>
                </>
            ))}
        </Grid>
    );
};

ServicesAddOnsCollection.propTypes = {
    bookAddOnsData: array.isRequired,
    classes: object.isRequired,
    history: object.isRequired,
    location: object.isRequired,
};

export default withRouter(ServicesAddOnsCollection);
