import { gql } from '@apollo/client';
import { getScreenCollectionPreviewMode } from '../../state/utils/contentful';

const marketingPromoDataQuery = () => gql`{
    screenCollection(${getScreenCollectionPreviewMode()}, where: {slug: "booking-confirmation-web"}) {
    items {
            title
            description {
                json        
            }
            metadata {
                sys {
                    id
                }
            }
            marketingComponentsCollection(limit: 10) {
                items {
                    ...on MarketingImage {
                        desktopMedia {
                            url
                        }
                    }
                }
            }
        }
    }
}`;

export default marketingPromoDataQuery;
