import createState from 'redux-create-state';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session'; // defaults to localStorage for web and AsyncStorage for react-native-
import utils from '../../utils';

import * as subscribeActions from './Subscribe-Actions';

export const INITIAL_STATE = {
    banner: null,
    topForm: null,
    bottomForm: null,
    items: [],
    metadata: {},
    hiddenvalue: '',
    loaded: false,
};

const subscribePersistConfig = {
    key: 'subscribe',
    storage,
};

export default persistReducer(subscribePersistConfig, utils.createReducer(INITIAL_STATE)({
    [subscribeActions.setSubscribeContent().type]: (state, action) => createState(state || INITIAL_STATE,
        ['metadata', action.payload.metadata],
        ['banner', action.payload.banner],
        ['items', action.payload.items],
        ['topForm', action.payload.topForm],
        ['bottomForm', action.payload.bottomForm],
        ['hiddenvalue', action.payload.hiddenvalue],
        ['loaded', true]),
    [subscribeActions.setSubscribeLoaded().type]: (state, action) => createState(state || INITIAL_STATE,
        ['loaded', action.payload]),
}));
