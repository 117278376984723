/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import {
    Grid, Typography, withStyles, FormControl, Input, Button, TextField,
} from '@material-ui/core';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { object } from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useHistory, useLocation } from 'react-router-dom';
// import EditableTextField from '../../app/Components/EditableTextField/EditableTextField';
import { getPageLabelFromUrl } from 'utils/helper';
import FavouriteSelectedShop from '../../booking/SelectLocation/Partials/LocationCard';
import FavouriteSelectedMock from '../../../__mocks__/favouriteShop.json';
import SectionTitle from '../../../app/Components/SectionTitle';
import LocationNearbyFavourite from '../../booking/SelectLocation/Partials/LocationNearbyFavourite';
import { MOBILE_BREAKPOINT, TABLET_BREAKPPOINT } from '../../../Helpers/breakpoints';

import { getFavoriteStore } from '../../../state/ducks/Account/Account-Selectors';
import { setFavoriteStore } from '../../../state/ducks/Account/Account-Actions';

let timer = null;

const styles = (theme) => ({
    containerShop: {
        background: '#fff',
        marginLeft: '15px',
        padding: '34px 22px',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            background: '#f9f9f9',
            marginLeft: 0,
        },
    },
    heading: {
        marginBottom: '18px !important',
        fontFamily: 'MrsEavesOT-Roman',
        color: '#42423D',
        fontSize: '20px',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            textTransform: 'uppercase',
            fontFamily: 'MrsEavesOT-Roman',
            fontWeight: 'normal',
            marginBottom: '10px !important',
        },
    },
    formContainer: {
        margin: '0 0 30px',
        padding: '0 20px 20px',
        width: '100%',
        // height: '181px',
        backgroundColor: '#F9F9F9F9',
        borderTop: '1px solid #D1D1D1',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            padding: '0 0 20px',
            backgroundColor: '#fff',
        },
    },
    textWrapper: {
        display: 'flex',
        fontSize: '15px',
        width: '366px',
        textAlign: 'initial',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            width: '100%',
            lineHeight: '20px',
        },
    },
    borderPixelLocation: {
        border: 'none',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            border: '1px solid #D1D1D1',
        },
    },
    distance: {
        color: '#989898',
        float: 'left',
        margin: '1% 0% 0% 11%',
    },
    optionsButtonsContainer: {
        width: '66%',
        margin: '0px 0px 0px 52px',
        textAlign: 'initial',
        padding: '0px',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            width: 'calc(100% - 30px)',
            margin: '0 15px',
            padding: '15px',
            backgroundColor: '#f9f9f9',
            '& > p': {
                fontSize: '15px',
                lineHeight: '20px',
            },

        },
    },
    favoriteShop: {
        margin: '69px 0px 0px 0px',
    },
    enterYourEmail: {
        width: '100%',
        height: '40px',
    },
    subscribeEmail: {
        background: '#54575A',
        borderRadius: '0px',
        color: '#FFFFFF',
        fontSize: '13px',
        width: '124px',
        height: '40px',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: 'rgb(58, 60, 62);',
        },
    },
    subscribeEmailForm: {
        display: 'flex',
        flexDirection: 'row',
        width: '30%',
        minWidth: '673px',
        borderTop: '1px solid #D1D1D1',
        padding: '21px 2px 2px 2px',
    },
    nonFavouriteShop: {
        fontSize: '15px',
        lineHeight: '22px',
        marginTop: '20px !important',
    },

    leftContent: {
        width: '100%',
        minWidth: '350px',
        minHeight: '200px',
        maxHeight: '800px',
        backgroundColor: '#FFFFFF',
        [theme.breakpoints.down(TABLET_BREAKPPOINT)]: {
            width: '100%',
            minWidth: 'unset',
            height: '80vh',
            backgroundColor: '#f9f9f9',

        },
    },
    searchField: {
        display: 'flex',
        flexDirection: 'row',
        height: '40px',
        paddingTop: '10px',
        marginBottom: '20px',
    },
    searchFieldHeight: {
        height: '100%',
    },
    searchButton: {
        marginTop: '-5px',
        background: '#54575A',
        borderRadius: '0px',
        color: '#FFFFFF',
        fontSize: '13px',
        width: '79px',
        height: '35px',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: 'rgb(58, 60, 62);',
        },
    },
    locationTypeButton: {
        width: '50%',
        borderRadius: '0',
        boxSizing: 'border-box',
        height: '38px',
        borderLeft: '1px solid #E5E5E5',
        borderRight: '1px solid #E5E5E5',
        borderBottom: '1px solid #E5E5E5',
        borderTop: '5px solid transparent',
        textTransform: 'none',
        backgroundColor: '#E5E5E5',
    },
    selected: {
        borderTop: '5px solid #FFDD30',
        backgroundColor: '#ffffff',
        fontWeight: '800',
    },
    locationList: {
        height: '680px',
        overflowY: 'auto',
        [theme.breakpoints.down(TABLET_BREAKPPOINT)]: {
            height: 'calc(80vh - 20px)',
        },
    },
    container: {
        [theme.breakpoints.down(TABLET_BREAKPPOINT)]: {
            padding: '12px',
            backgroundColor: '#fff',
        },
    },
    icon: {
        [theme.breakpoints.down(TABLET_BREAKPPOINT)]: {
            marginRight: '10px',
        },
    },
});

/**
 * Static data - make dynamic when connected with API
 */
const AccountFavouriteShop = ({
 classes, contentfulStores, customerFields, authUser, favoriteStore, setFavoriteStore,
}) => {
    // here checking the location Id with content ful store
    const customerFieldsValue = customerFields?.CustomerFieldValues?.FieldValues || [];
    const getCustomerFieldsKey = customerFieldsValue.filter((hasKey) => hasKey?.Key === 56378);// 56378//52369
    const getCustomerFvtLocationId = getCustomerFieldsKey?.[0]?.Value?.TextValue?.Value || '';
    const locations = contentfulStores.filter((hasLoc) => hasLoc?.bookerLocationId === Number(getCustomerFvtLocationId));

    const [favouriteLocations, setFavouriteLocations] = useState(locations);
    const [storeType, setStoreType] = useState('Drybar Shop');
    const [searchLocation, setSearchLocation] = useState('');

    const history = useHistory();

    const location = useLocation();

    const currentLocation = null;

    useEffect(() => {
        if (favoriteStore) setFavouriteLocations([favoriteStore]);
    }, [favoriteStore]);

    // click event handler of book button that is in store location list and map infoview
    const onLocationSelect = (location) => {
        history.push(`/service/locator/detail/${location.slug}`);
    };

    // key event handler of search box
    const handleLocationSearch = (e) => {
        if (timer != null) {
            clearTimeout(timer);
            timer = null;
        }
        timer = setTimeout(() => {
            setSearchLocation(e.target.value);
            timer = null;
        }, 500);
    };

    const refresh = (refreshData) => {
        if (refreshData.type == 'favorite') {
            // let locations = contentfulStores.filter(hasLoc => hasLoc?.bookerLocationId === Number(refreshData.value));
            // console.log(refreshData.selectedStore)
            // console.log(refreshData.selectedStore, contentfulStores)

            setFavouriteLocations([refreshData.selectedStore]);
            mParticle.logEvent('Account - Change Favorite Shop', mParticle.EventType.Other, { 'Source Page': getPageLabelFromUrl(location.pathname) });
            setFavoriteStore(refreshData.selectedStore);
        }
    };

    return (
        <Grid className={classes.containerShop}>
            <Typography className={classes.heading}>
                YOUR FAVOURITE SHOP
            </Typography>
            <Grid className={classes.formContainer}>
                {favouriteLocations.length < 1
                && <Typography className={classes.nonFavouriteShop}>You have not selected a favorite Drybar Shop.</Typography>}

                {favouriteLocations.length > 0 && (
                <>
                    {favouriteLocations?.map((data) => (
                        <FavouriteSelectedShop
                            onLocationSelect={onLocationSelect}
                            key={`${data.lat}`}
                            locationData={data}
                            classes={classes}
                            currentFavourite={data}
                        />
                    ))}
                </>
)}
            </Grid>

            <Typography className={classes.heading}>
                SELECT A DRYBAR LOCATION
            </Typography>

            <Grid className={classes.searchField}>
                <TextField
                    fullWidth
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                        classes: {
                            root: classes.searchFieldHeight,
                        },
                    }}
                    placeholder="City, County or Postcode"
                    onChange={(e) => handleLocationSearch(e)}
                />
                <Button className={classes.searchButton} variant="outlined">Search</Button>
            </Grid>
            <Grid className={classes.leftContent}>
                { searchLocation != '' && (
                <LocationNearbyFavourite
                    classes={classes}
                    locationType="nearby"
                    storeType={storeType}
                    searchLocation={searchLocation}
                    currentLocation={currentLocation}
                    isBooking
                    onLocationSelect={onLocationSelect}
                    authUserDetail={authUser.user}
                    favouriteLocations={favouriteLocations}
                    contentfulStores={contentfulStores}
                    refresh={refresh}
                />
)}

            </Grid>
        </Grid>
    );
};

AccountFavouriteShop.propTypes = {
    classes: object.isRequired,
};

const mapStateToProps = (state) => ({
    favoriteStore: getFavoriteStore(state),
});

const mapDispatchToProps = (dispatch) => ({
    setFavoriteStore: bindActionCreators(setFavoriteStore, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AccountFavouriteShop));
