import ServiceStoreLocationContainer from '../pages/service/locators/ServiceStoreLocationContainer';
import GraphqlMeetOurStylist from '../pages/service/stylist/GraphqlMeetOurStylist';
import GraphqlScreenAddOnsCollection from '../pages/service/addOns/GraphqlScreenAddOnsCollection';
import GraphqlServicesCollection from '../pages/service/services/GraphqlServiceCollections';
import GraphqlScreenButterCup from '../pages/service/butterCup/GraphqlScreenButterCup';
import ServiceLocatorDetailContainer from '../pages/service/locators/detail/ServiceLocatorDetailContainer';

export default [
    {
        path: '/service/add-ons',
        Component: GraphqlScreenAddOnsCollection,
        label: 'Service Add-Ons',
    },
    {
        path: '/service/blow-services',
        Component: GraphqlServicesCollection,
        label: 'Blow Services',
    },
    {
        path: '/service/butter-cup',
        Component: GraphqlScreenButterCup,
        label: 'Butter Cup',
    },
    {
        path: '/service/meet-stylist',
        Component: GraphqlMeetOurStylist,
        label: 'Meet Stylist',
    },
    {
        path: '/service/locator/detail/:slug',
        Component: ServiceLocatorDetailContainer,
    },
    {
        path: '/service/locator',
        Component: ServiceStoreLocationContainer,
        label: 'Service Locator',
    },
];
