/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import TagManager from 'app/Components/GoogleTagManager';
import config from 'app.config';

import { bindActionCreators } from 'redux';
import { doQuery } from 'state/utils/contentful';
import { getAvailableDates } from '../../../api/booking-api';
import { getWeekByFirstDate } from '../../../Helpers/dateTime';
import { getSelectedDate, getLocationData, getBuyoutLocation } from '../../../state/ducks/Booking/Booking-Selectors';
import { setDateTimeClient, setLocationData } from '../../../state/ducks/Booking/Booking-Actions';
import BackdropCircularProgress from '../../../app/Components/common/BackdropCircularProgress';
import SummaryWrapper from '../SummaryWrapper';
import DateTime from './DateTime';
import { getLocations as getBuyoutData } from '../../../state/ducks/Buyout/Buyout-Selectors';
import StoreByLocationId from '../../../gql/queries/storeByLocationId';

const { gtm: { booking } } = config;
const tagManagerArgs = {
    dataLayer: {
        event: booking.event,
        form_type: booking.type,
        form_step: booking.step5,
    },
};

const DateTimeContainer = (props) => {
    const selectedDate = props.selectedDate ? new Date(props.selectedDate) : new Date();
    const hasLocationId = props?.getLocationId?.bookerLocationId;

    const [week, setWeek] = useState(getWeekByFirstDate(selectedDate));

    useEffect(() => {
        TagManager.dataLayer(tagManagerArgs);
    }, []);

    useEffect(async () => {
        if (props.buyoutLocation && hasLocationId) {
            const locationData = await doQuery(StoreByLocationId(hasLocationId));
            if (locationData.storeCollection.items && locationData.storeCollection.items.length) {
                props.setLocationData(locationData.storeCollection.items[0]);
            }
        }
    }, [props.buyoutLocation, hasLocationId]);

    useEffect(() => {
        const oldWeek = getWeekByFirstDate(selectedDate);
        if (week[0] !== oldWeek[0]) setWeek(getWeekByFirstDate(selectedDate));
    }, [selectedDate, week, setWeek]);

    const DATE_FETCH_QUERY = getAvailableDates(week[0], week[6], hasLocationId);

    return (
        <SummaryWrapper
            title="DATE & TIME"
            containerStyle={{ padding: '0px', minWidth: '800px' }}
            useFetch={DATE_FETCH_QUERY}
        >
            {({ data, error, loading }) => {
                if (loading) {
                    return <BackdropCircularProgress />;
                }

                if (error) {
                    return null;
                }

                if (data) {
                    return (
                        <DateTime
                            hasLocationId={hasLocationId}
                            availableDates={data?.[0]?.serviceCategories?.[0]?.services?.[0]?.availability || []}
                            {...props}
                        />
                    );
                }

                return <></>;
            }}
        </SummaryWrapper>
    );
};

const mapStateToProps = (state) => ({
    selectedDate: getSelectedDate(state),
    getLocationId: getLocationData(state),
    buyoutLocation: getBuyoutLocation(state),
    buyout: getBuyoutData(state),
});

const mapDispatchToProps = (dispatch) => ({
    setSelectedDate: bindActionCreators(setDateTimeClient, dispatch),
    setLocationData: bindActionCreators(setLocationData, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DateTimeContainer);
