import { getPreviewMode } from '../../state/utils/contentful';

/**
 * Load screenEventsCollection and its children's id
 * @returns {DocumentNode}
 */
const queryDryStylingCollection = () => (
    `{
      screenDryStylingCollection(${getPreviewMode()}, where: {slug: "dry-styling"}) {
        items {
          metadata {
            pageTitle
            pageDescription
            pageKeywords
            searchKeywords
          }
          sys {
            id
          }
          title
          subtitle
          description {
            json
          }
          actionsCollection {
            items {
              title
              linkToUrl
            }
          }          
          video {
            previewImage {
              desktopMedia {
                url
              }
            }
            desktopUrl
          }
        }  
      }
    }
    `
);

const queryDryStyling = () => (
  `{
    drybarPageCollection(preview: true, where: { slug: "dry-styling" }) {
      items {
        title
        subtitle
        tcsAndPoliciesCopy
        seoTitle
        seoDescription
        canonicalUrl
        addANoindexTag
        slug
        formatofPageCollection(preview: true) {
          items {
            __typename
            ... on HeroBanner {
              sys {
                id
              }
            }
            ... on Carousel {
              sys {
                id
              }
            }
          }
        }
      }
    }
  }
  `
);

const fetchBannerData = (id) => `
{
  heroBanner(id: "${id}", preview: true) {
    targetPageUrl
    slugPrefix
    mediaCollection(preview: true) {
      items {
              internalName
              desktopImageUrl
              mobileImageUrl
              videoUrl
            }
    }
  }
}
`;

/**
 * Load events
 * @param sreenDryStylingId
 * @returns {DocumentNode}
 */
const queryImagesAndMarketingSectionIds = (sreenDryStylingId) => (
    `{
      screenDryStyling(${getPreviewMode()}, id: "${sreenDryStylingId}") {
        imagesCollection {
          items {
            desktopMedia {
              url
            }
          }
        }
        marketingComponentsCollection {
          items {
            ... on MarketingSection {
              sys {
                id
              }
            }
          }
        }
      }
    }
    `
);

const queryMarketingSection = (marketingSectionId) => (
    `{
      marketingSection(${getPreviewMode()}, id:"${marketingSectionId}") {
        marketingComponentsCollection {
          items {
            ... on MarketingCard {
              image {
                desktopMedia {
                  url
                }
              }
              actionsCollection {
                items {
                  title
                  linkToUrl
                }
              }
            }
          }
        }
      }
    }
    `
);

const queryCarousel = (marketingCollectionId) => (
  `{
    carousel(preview: true, id: "${marketingCollectionId}") {
      internalName
      title
      autoplay
      dots
      infinite
      slidesToScroll
      slidesToShow
      arrows
      slugPrefix
      format
      speed
      slidesCollection {
        items {
          ... on DrybarStylesItem {
            title
            subtitle
            imageUrl
            slugPrefix
          }
          ... on CarouselSlide {
            slugPrefix
            internalName
            title
            subtitle
            buttonCtaUrl
            buttonCtaTitle
            buttonCtaStyle
            titleDropShadow
            titleSize
            titleColour
            backgroundColour
            textJustify
            textAlign
            mediaCollection {
              items {
                internalName
                desktopImageUrl
                mobileImageUrl
                videoUrl
                showThisModel1Url
                showThisModel2Url
                showThisModel3Url
              }
            }
          }
        }
      }
    }
  }
  `
);

export {
    queryDryStylingCollection,
    queryImagesAndMarketingSectionIds,
    queryMarketingSection,
    queryDryStyling,
    fetchBannerData,
    queryCarousel
};
