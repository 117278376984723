import React from 'react';
import classNames from 'classnames';
import AppleIcon from '@material-ui/icons/Apple';

import styles from "./Apple.module.scss"

export const Apple = ({ url = "https://apps.apple.com/gb/app/drybar-uk/id1596751939", display = true}) =>
    display ? <div className={classNames(styles.appStoreLink)}>
        <a className={styles.link} href={url} target="_blank">
            <div className={styles.logo}>
                <AppleIcon className={styles.icon}/>
            </div>
            <div className={styles.text}>
                <div className={styles.strap}>
                    Download on the
                </div>
                <div className={styles.name}>
                    App Store
                </div>
            </div>
        </a>
    </div> : null;

