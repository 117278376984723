/* eslint-disable react/prop-types */
import React, { useEffect, useState, setState } from 'react';
import { Switch, Route, withRouter, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { getRedirectUrl } from 'state/ducks/Auth/Auth-Selectors';
import Authenticated from 'utils/authenticated';
import Callback from 'utils/callback';
import { getPageLabelFromUrl } from 'utils/helper';
import AppShell from './AppShell';
import routes from './routes/routes';
import { connect } from 'react-redux';
import { useAuth } from 'oidc-react';
import useMoktaAuth from 'utils/oktaAuth';

import './assets/css/bootstrap.scss';
import './assets/css/font-awesome.min.scss';

const App = ({ history, location, redirectUrl }) => {
    const auth = useAuth();
    const [
        isAuthenticated,
        getUser,
        getUserId,
        sessionCookieReset,
    ] = useMoktaAuth();
    useEffect(() => {
        if (location.pathname && getPageLabelFromUrl(location.pathname)) {
            mParticle.logEvent('View Page', mParticle.EventType.Other, {
                'Source Page': getPageLabelFromUrl(location.pathname),
                'Page Name': getPageLabelFromUrl(location.pathname),
                'Page Category': location.pathname.replace(/^\//, ''),
            });
        }
        if (location.pathname === '/logout/callback') {
            sessionCookieReset()
                .then(() => {
                    sessionStorage.clear();
                    localStorage.clear();
                    history.push('/');
                })
                .catch((error) => {
                    console.log(error);
                    sessionStorage.clear();
                    localStorage.clear();
                    history.push('/');
                });
        }
    }, [location.pathname]);

    return (
        <AppShell>
            <Switch>
                <Route
                    key={`route_callback`}
                    path="/login/callback"
                    render={() => (
                        <>
                            <Callback
                                onSuccess={(user) => {
                                    if (redirectUrl) {
                                        history.push(redirectUrl);
                                    } else {
                                        history.push('/');
                                    }
                                }}
                                onError={(e) => {
                                    if (e.error === 'login_required') {
                                        console.log(e);
                                        auth.signIn();
                                    }
                                }}
                                userManager={auth.userManager}
                            ></Callback>
                        </>
                    )}
                />
                {routes.map(({ path, Component, privateRoute }, index) =>
                    privateRoute ? (
                        <Route
                            key={`route_${index}`}
                            path={path}
                            render={() => (
                                <>
                                    <Authenticated
                                        userManager={auth.userManager}
                                    >
                                        <Component />
                                    </Authenticated>
                                </>
                            )}
                        />
                    ) : (
                        <Route
                            key={`route_${index}`}
                            exact
                            path={path}
                            render={() => (
                                <>
                                    <Component />
                                </>
                            )}
                        />
                    )
                )}
                <Route render={() => <Redirect to="/404" />} />
            </Switch>

            {/* Don't inject the script for localhost, there's no point, you can debug locally. */}
            <Helmet>
                {process.env.REACT_APP_DRYBAR_ENV === 'development' && (
                    <script
                        type="text/javascript"
                        src="https://js-cdn.dynatrace.com/jstag/15fc9f135f3/bf03334wlw/8ee9c7302a247332_complete.js"
                        crossorigin="anonymous"
                    ></script>
                )}
                {process.env.REACT_APP_DRYBAR_ENV === 'qa' && (
                    <script
                        type="text/javascript"
                        src="https://js-cdn.dynatrace.com/jstag/15fc9f135f3/bf03334wlw/8ee9c7302a247332_complete.js"
                        crossorigin="anonymous"
                    ></script>
                )}
                {process.env.REACT_APP_DRYBAR_ENV === 'production' && (
                    <script
                        type="text/javascript"
                        src="https://js-cdn.dynatrace.com/jstag/15fc9f135f3/bf03334wlw/f9b1967ff95845b0_complete.js"
                        crossorigin="anonymous"
                    ></script>
                )}
            </Helmet>
        </AppShell>
    );
};

const mapStateToProps = (state) => ({
    redirectUrl: getRedirectUrl(state),
});

export default connect(mapStateToProps)(withRouter(App));
