import React, { useState, useEffect } from 'react';
import { Button, Grid, Typography, withStyles } from '@material-ui/core';
import { object } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AppointmentCard from '../../../app/Components/AppointmentCard';
import BarflyImage from '../../../assets/images/barfly-membership.svg';
import AppointmentDetails from './AppointmentDetails';
import { MOBILE_BREAKPOINT } from '../../../Helpers/breakpoints';
import { getSelectedAppointment } from '../../../state/ducks/Account/Account-Selectors';
import { setViewAppointment } from 'state/ducks/Account/Account-Actions';

const styles = (theme) => ({
    container: {
        backgroundColor: '#fff',
        padding: '36px 22px',
        marginLeft: '15px',
        height: '100%',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            backgroundColor: '#f9f9f9',
            // padding: '0px 22px',
            // minHeight: "456px"
            margin: '0 !important',
            padding: '0 20px 20px',
            width: '100%',
        },
    },
    heading: {
        fontWeight: '800',
        marginBottom: '15px !important',
        color: '#42413D',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            margin: '20px 0 10px !important',
            textTransform: 'uppercase',
            fontFamily: 'MrsEavesOT-Roman',
            fontWeight: 'normal',
        },
    },
    noAppointmentText: {
        backgroundColor: '#F9F9F9',
        padding: '80px 45px',
        fontSize: '18px',
        textAlign: 'center',
        borderTop: '1px solid #D1D1D1',
        marginBottom: '35px',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            padding: '20px',
            backgroundColor: '#fff',
        },
    },
    bookButton: {
        width: '378px',
        maxWidth: '100%',
        height: '63px',
        fontSize: '18px',
    },
    noUpcomingContainer: {
        textAlign: 'center',
        width: '100%',
    },
    barfleyContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '45px 0',
        textDecoration: 'underline',
        textUnderlinePosition: 'under',
    },
});

const MyAppointments = ({
    classes,
    upcoming,
    past,
    contentfulStores,
    history,
    HasActiveMembership,
    selectedAppointment,
    setSelectedAppointment,
}) => {
    const HasMembership = HasActiveMembership?.[0];

    const [upcomingGroups, setUpcomingGroups] = useState([]);
    const [pastGroups, setPastGroups] = useState([]);

    const handleBookAppointment = () => {
        history.push('/booking/location');
    };

    const getGroups = (list) => {
        const groups = list.reduce((obj, cur) => {
            if (cur.GroupID) {
                const groupKey = `group_${cur.GroupID}`;
                if (!obj[groupKey]) {
                    obj[groupKey] = {
                        bookingNumbers: [],
                        appointment: cur,
                        groupID: cur.GroupID,
                        appointments: {},
                    };
                }
                obj[groupKey].bookingNumbers.push(cur.BookingNumber);
                obj[groupKey].appointments[`appoint_${cur.ID}`] = cur;
                return obj;
            }
            obj[`group_${cur.BookingNumber}`] = {
                appointment: cur,
                bookingNumbers: [cur.BookingNumber],
            };
            return obj;
        }, {});
        return Object.values(groups);
    };

    useEffect(() => {
        if (upcoming && upcoming.length) {
            const groups = getGroups(upcoming);
            setUpcomingGroups(groups);
        }
    }, [upcoming, setUpcomingGroups]);

    useEffect(() => {
        if (past && past.length) {
            const groups = getGroups(past);
            setPastGroups(groups);
        }
    }, [past, setPastGroups]);

    if (selectedAppointment) {
        if (!selectedAppointment.group.appointment) {
            // find matching appointment from upcoming
            const group = upcomingGroups.find((upcomingGroup) =>
                upcomingGroup.bookingNumbers.includes(
                    selectedAppointment.group.bookingNumbers[0]
                )
            );

            if (group) {
                setSelectedAppointment({
                    isUpcoming: true,
                    group: group,
                });
            }
        }
        return <AppointmentDetails />;
    }

    return (
        <Grid className={classes.container}>
            <Typography className={classes.heading}>Upcoming</Typography>
            {upcomingGroups?.length ? (
                upcomingGroups.map((group) => (
                    <AppointmentCard
                        isUpcoming
                        appointment={group.appointment}
                        contentfulStores={contentfulStores}
                        group={group}
                    />
                ))
            ) : (
                <Grid className={classes.noUpcomingContainer}>
                    <Grid className={classes.noAppointmentText}>
                        <Typography style={{ lineHeight: '2' }}>
                            You do not have any upcoming appointments.
                            <br />
                            Book one from here or easily rebook from one of your
                            previous visits.
                        </Typography>
                    </Grid>
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.bookButton}
                        onClick={() => handleBookAppointment()}
                    >
                        Book an Appointment
                    </Button>

                    <Grid className={classes.barfleyContainer}>
                        <img
                            style={{ margin: '0 32px' }}
                            src={BarflyImage}
                            alt="Barfly"
                        />
                        <Typography>
                            <a
                                style={{ color: '#42413D' }}
                                href={
                                    HasMembership
                                        ? '/booking/location'
                                        : '/barfly-membership'
                                }
                            >
                                Save 10% on your rebook with a Barfly Membership
                            </a>
                        </Typography>
                    </Grid>
                </Grid>
            )}
            <Typography
                className={classes.heading}
                style={{ marginTop: '30px' }}
            >
                Past
            </Typography>
            {pastGroups?.length ? (
                pastGroups.map((group) => (
                    <AppointmentCard
                        appointment={group.appointment}
                        contentfulStores={contentfulStores}
                        group={group}
                    />
                ))
            ) : (
                <Typography>You don't have any Past Appointments.</Typography>
            )}
        </Grid>
    );
};

MyAppointments.propTypes = {
    classes: object.isRequired,
};

const mapStateToProps = (state) => ({
    selectedAppointment: getSelectedAppointment(state),
});

const mapDispatchToProps = (dispatch) => ({
    setSelectedAppointment: bindActionCreators(setViewAppointment, dispatch),
});

export default withRouter(
    withStyles(styles)(
        connect(mapStateToProps, mapDispatchToProps)(MyAppointments)
    )
);
