export const googleAnalyticsEvents = {
    EVENT: "event",
    SIGN_UP: "sign_up",
    BARFLY_SIGN_UP: "barfly_sign_up",
    LOGIN: "login",
    BOOKING_LOCATION: 'booking_location',
    BOOKING_GROUP: "booking_group",
    BOOKING_ATTENDEE: 'booking_attendee',
    ADD_TO_CART: "add_to_cart",
    REMOVE_FROM_CART: "remove_from_cart",
    BOOKING_DATE: "booking_date",
    BOOKINFG_REQUEST: "booking_request",
    ADD_PAYMENT_INFO: "add_payment_info",
    PURCHASE: 'purchase',
    BOOKING_CANCELLATION: 'booking_cancellation',
    NEWSLETTER: 'newsletter',
}