import {
 call, fork, put, takeLatest,
} from 'redux-saga/effects';
import { loadResyContentful, setResyContent } from './Resy-Actions';
import { gqlLoadResy } from '../../../gql/resy/contentfulResyActions';

function* workerLoadResyContentful({ payload }) {
    const resySectionData = yield call(gqlLoadResy, { slug: payload });
    yield put(setResyContent(resySectionData));
}

function* watcherLoadResyContentful() {
    yield takeLatest(loadResyContentful().type, workerLoadResyContentful);
}

export const watchers = {
    watcherLoadContentful: fork(watcherLoadResyContentful),
};

export default {
    watchers,
};
