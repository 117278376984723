/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Grid, Typography, Button, Box,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import dayjs from 'dayjs';

import CalendarTodayIcon from '@material-ui/icons/CalendarToday';

import TagManager from 'app/Components/GoogleTagManager';
import config from 'app.config';
import { direction } from 'Helpers';
import BookedTime from '../../../assets/images/BookedTime.svg';
import CalendarBooked from '../../../assets/images/CalendarBooked.svg';
import BlowoutAddOns from '../../../assets/images/barfly.svg';
import {
    getExtensionAddon, getExtensions, getLocationData, getNumberOfGuests, getSelectedDate, getSelectedSlot, getServicesData, getAddOnsServiceObject, getServiceTime,
} from '../../../state/ducks/Booking/Booking-Selectors';
import { getDateDDmm, getNumberOfDaysBetween, getTimeFromDate } from '../../../Helpers/dateTime';
import CustomCalLink from '../../../app/Components/CustomCalendarLink';
import StoreByLocationId from '../../../gql/queries/storeByLocationId';
import MarketingPromoDataQuery from '../../../gql/queries/confirmMarketingPromo';
import { doQuery } from '../../../state/utils/contentful';
import MetaTagWrapper from '../../../components/MetaTagWrapper';

const useStyles = makeStyles((theme) => ({
    summaryCopy: {
        marginTop: '20px !important',
        textAlign: 'center',
        fontFamily: theme.typography.fontFamily[0],
        fontWeight: '800',
    },
    summaryCopySub: {
        fontWeight: '300',
        fontStyle: 'oblique',
    },
    summaryCopyLocation: {
        padding: '10px 10px !important',
        marginBottom: '5px !important',
        position: 'relative',
        textAlign: 'center',
        fontWeight: '600',
        fontFamily: theme.typography.fontFamily[0],
    },
    requestNoteDetails: {
        float: 'right',
        background: theme.palette.common.white,
        boxShadow: '2px 2px 17px rgba(235, 235, 235, 0.5)',
    },
    allSetTitle: {
        fontFamily: 'DINCondensed-Regular',
        fontSize: '39px',
        fontWeight: '800',
        textTransform: 'uppercase',
        color: theme.palette.common.grey,
    },
    allSetCopy: {
        margin: '25px auto 38px !important',
    },
    requestServiceContainer: {},
    locationSummary: {
        background: theme.palette.common.lightGrey[3],
    },
    BookedTime: {
        background: theme.palette.common.white,
        border: `1px solid ${theme.palette.common.lightGrey[0]}`,
        boxSizing: 'border-box',
        borderRadius: '4px',
        textAlign: 'center',
        width: '208px',
        height: '208px',
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        justifyContent: 'space-aaound',
        padding: '50px 0',
    },
    gridAll: {
        margin: '0 auto',
        borderRadius: '0px',
        background: '#fff',
        boxShadow: '2px 2px 46px rgba(235, 235, 235, 0.5)',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        padding: '19px',
    },
    parentGrid: {
        margin: '5px 0px 0px 40px',
    },
    parentDiv: {
        background: theme.palette.secondary[200],
        width: '100%',
        backgroundColor: '#F8F8F8',
        borderTop: '1px solid #D1D1D1',
        padding: '23px',
    },
    parentSection: {
        display: 'flex',
        justifyContent: 'space-around',
    },
    nextAction: {
        background: theme.palette.common.lightGrey[1],
    },
    nextClick: {
        fontFamily: theme.typography.fontFamily[0],
        fontSize: '18px',
        lineHeight: '45px',
        color: theme.palette.common.black,
        width: '378px',
        maxWidth: '100%',
        textTransform: 'capitalize',
        margin: '32px auto',
    },
    addToCalendar: {
        fontFamily: theme.typography.fontFamily[0],
        fontSize: '15px',
        textDecoration: 'underline',
    },
    alignItemCenter: {
        textAlign: 'center',
    },
    displayFlex: {
        display: 'flex',
    },
    borderBottomCopy: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        fontFamily: 'MrsEavesOT-Roman',
        fontSize: '20px',
        '&::before': {
            content: '""',
            borderTop: '2px dashed #BDBDBD',
            flex: '1 0 20px',
            marginRight: '10px',
        },
        '&::after': {
            content: '""',
            borderTop: '2px dashed #BDBDBD',
            flex: '1 0 20px',
            marginLeft: '10px',
        },
        margin: '40px auto',
    },
    dashedBorder: {
        borderBottom: `3px dashed ${theme.palette.common.lightGrey[2]}`,
    },
    chaserShine: {
        textAlign: 'center',
        marginTop: 20,
        '& img': {
            width: '100%',
        },
    },
    container: {
        // background: theme.palette.common.lightGrey[3],
        maxWidth: '800px',
        margin: '75px auto',
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        textAlign: 'center',
    },
    showCalendarAction: {
        display: 'flex',
        color: '#42413D',
        textTransform: 'none',
        [theme.breakpoints.down('sm')]: {
            whiteSpace: 'nowrap',
        },
    },
    getDirection: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: '8px',
    },
    linkGetDirection: {
        fontSize: '15px',
        lineHeight: '22px',
        textDecoration: 'underline',
        color: '#42413d',
        marginLeft: '10px',
    },
    guestTitle: {
        width: '100%',
    },
}));

function BookingConfirmationContainer({
    selectedStore,
    date,
    addons,
    slot,
    services,
    location,
    extensions,
    extensionAddon,
    guests,
    serviceTime,
}) {
    const classes = useStyles();
    const [selectedLocation, setLocation] = useState(selectedStore);
    const [promoaData, setPromoaData] = useState(null);
    const locationState = location?.state || '';
    const Treatment = locationState?.appointmentDetails?.Treatment || '';
    const [calendarEvent, setCalendarEvent] = useState(null);

    useEffect(() => {
        const guests = Object.keys(slot);
        if (slot && guests.length > 0) {
            const startTime = dayjs(slot[guests[0]].startDateTime);
            const endTime = startTime.add(serviceTime, 'minutes');
            const event = {
                title: 'Drybar Booking',
                description: '',
                startTime: slot[guests[0]].startDateTime,
                endTime: endTime.format('YYYY-MM-DDTHH:mm:ssZ'),
                location: `${selectedLocation?.contact?.street1 }, ${ selectedLocation?.contact?.city } ${ selectedLocation?.contact?.state } ${ selectedLocation?.contact?.postalCode}`,
                url: `${window.location.protocol }//${ window.location.host }/account/my-appointments`,
            };
            console.log('Calendar Event:', event);
            setCalendarEvent(event);
        }
    }, [slot]);

    useEffect(async () => {
        if (selectedLocation.bookerLocationId && !selectedLocation.title) {
            const { storeCollection } = await doQuery(StoreByLocationId(selectedLocation.bookerLocationId).loc.source.body);
            if (storeCollection.items.length) {
                setLocation(storeCollection.items[0]);
            }
        }
    }, [selectedLocation]);

    useEffect(async () => {
        const { screenCollection } = await doQuery(MarketingPromoDataQuery().loc.source.body);
        if (screenCollection.items.length) {
            setPromoaData(screenCollection.items[0]);
        }
    }, []);

    useEffect(() => {
        const result = locationState?.appointmentDetails;
        const { gtm: { booking, formCompleteEvent, purchaseEvent } } = config;

        const gtmProducts = result?.map((item) => {
            let service = null;
            if (item.Treatment) {
                service = item.Treatment;
            } else if (item.TreatmentID) {
                service = services.map((s) => s.data).find((s) => s.ID === item.TreatmentID);
            }
            if (service) {
                return {
                    id: `${service.ID}`,
                    name: service.Name,
                    price: parseFloat(service.Price?.Amount).toFixed(2),
                    category: 'service',
                    quantity: 1,
                };
            } return {};
        });

        const tagManagerCompleteArgs = {
            dataLayer: {
                event: formCompleteEvent,
                form_type: booking.type,
            },
        };
        const currencyCode = result?.[0].DynamicPrice?.FinalPrice.CurrencyCode || result?.[0]?.Treatment?.Price?.CurrencyCode;
        const extensionPrice = extensions === true ? (extensionAddon.Price.Amount * (guests + 1)) : extensions ? Object.keys(extensions).reduce((sum, cur) => {
            if (extensions[cur]) {
                sum += extensionAddon.Price.Amount;
            }
            return sum;
        }, 0) : 0;

        const tagManagerPaymentArgs = {
            dataLayer: {
                event: purchaseEvent,
                locationId: selectedStore.bookerLocationId,
                locationName: selectedStore.title,
                ecommerce: {
                    currencyCode, // optional
                    purchase: {
                        actionField: {
                            id: result?.[0]?.BookingNumber, // Booker booking number
                            affiliation: 'Drybar Holdings',
                            revenue: result?.reduce((sum, item) => {
                                if (item.DynamicPrice?.FinalPrice?.Amount) {
                                    sum += item.DynamicPrice?.FinalPrice?.Amount;
                                } else if (item?.Treatment?.Price?.Amount) {
                                    sum += item?.Treatment?.Price?.Amount;
                                }
                                return sum;
                            }, 0) + extensionPrice, // Gross sale/expectedamount from booking
                            coupon: result?.[0]?.AppointmentPayment?.CouponCode, // promocode used, if applicable
                        },
                        products: gtmProducts,
                    },
                },
            },
        };

        console.log('tagManagerPaymentArgs:', tagManagerPaymentArgs);
        TagManager.dataLayer(tagManagerCompleteArgs);
        TagManager.dataLayer(tagManagerPaymentArgs);
    }, []);

    return (
        <Grid className={classes.container}>
            <MetaTagWrapper metadata={{ pageTitle: 'Confirmation' }} />
            <Typography className={classes.allSetTitle} tabindex="0">
                you’re all set!
            </Typography>
            <Typography className={classes.allSetCopy}>
                Please don’t blow us off! If you can’t make your appointment, please be sure to cancel so we can try to fill your spot. Thank you!
            </Typography>
            <Grid className={classes.gridAll}>
                <div className={classes.summaryCopyLocation}>
                    <div>{`${selectedLocation?.title }, ${ selectedLocation?.contact?.street1 }, ${ selectedLocation?.contact?.city}`}</div>
                    <div className={classes.getDirection}>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.2541 7.15803L14.2541 7.158L14.2487 7.15263L8.84737 1.75128C8.379 1.28291 7.621 1.28291 7.15263 1.75128L1.75128 7.15263C1.28291 7.621 1.28291 8.379 1.75128 8.84737L7.15263 14.2487C7.621 14.7171 8.379 14.7171 8.84737 14.2487L14.2464 8.84969C14.7249 8.38003 14.7083 7.62391 14.2541 7.15803ZM9.7973 8.05485V8.003V7.403H9.1973H6.7967H6.1967V8.003V9.20345H6.1964V7.40285L6.19655 7.4027H9.1973H9.7973V6.8027V6.75085L10.4493 7.40285L9.7973 8.05485Z" stroke="#42413D" strokeWidth="1.2" />
                        </svg>
                        <a
                            href={direction(selectedLocation?.contact?.coordinates[0], selectedLocation?.contact?.coordinates[1])}
                            className={classes.linkGetDirection}
                            target="_blank"
                        >
                            Get Directions
                        </a>
                    </div>
                </div>

                {
                    Object.keys(slot).map((user) => {
                        const s = slot[user];
                        return (
                            <div className={classes.parentDiv}>
                                <Typography className={classes.guestTitle}>
                                    {user}
                                </Typography>
                                <Grid className={classes.parentSection}>
                                    <Grid className={classes.BookedTime}>
                                        <img src={BookedTime} alt="booked-time" height={42} />
                                        <Typography className={classes.summaryCopy}>
                                            {getTimeFromDate(s.startDateTime)}
                                        </Typography>
                                    </Grid>
                                    <Grid className={classes.BookedTime}>
                                        <img src={CalendarBooked} alt="booked-time" height={42} />
                                        <Typography className={classes.summaryCopy}>
                                            <Box>
                                                {getDateDDmm(new Date(s.startDateTime))}
                                            </Box>
                                            <Box className={classes.summaryCopySub}>
                                                {getNumberOfDaysBetween(new Date(s.startDateTime))
                                                 ? (`in ${getNumberOfDaysBetween(new Date(s.startDateTime))} day${getNumberOfDaysBetween(new Date(s.startDateTime)) > 1 ? 's' : ''}`)
                                                 : 'Today'}
                                            </Box>
                                        </Typography>
                                    </Grid>
                                    <Grid className={classes.BookedTime}>
                                        <img src={BlowoutAddOns} alt="Add-on" height={42} />
                                        <Typography className={classes.summaryCopy}>
                                            {Treatment.Name ? (
                                                <Box>
                                                    {Treatment.Name}
                                                </Box>
                                        ) : null}
                                            <Box className={Treatment.Name ? classes.summaryCopySub : ''}>
                                                {`${addons[user]?.length || 'No'} Add-Ons`}
                                            </Box>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </div>
);
                    })
                }

                <Link className={classes.nextClick} to="/account/my-appointments">
                    <Button
                        style={{
fontSize: '18px', lineHeight: '45px', width: '378px', maxWidth: '378px',
}}
                        variant="contained"
                        color="primary"
                    >
                        Manage Appointments
                    </Button>
                </Link>

                <Button variant="container" className={classes.showCalendarAction}>
                    <CalendarTodayIcon style={{ height: '15px', marginRight: '3px' }} />
                    {calendarEvent && (
                    <CustomCalLink event={calendarEvent}>
                        <Typography style={{ fontSize: '15px', height: '20px' }}>
                            Add To Calendar
                        </Typography>
                    </CustomCalLink>
)}
                </Button>
            </Grid>
            {/* <Typography className={classes.borderBottomCopy}>OUR SPECIAL OFFER</Typography> */}
            <Grid className={classes.chaserShine}>
                {promoaData && <img src={promoaData.marketingComponentsCollection.items[0].desktopMedia.url} alt="chase-shine" />}
            </Grid>
        </Grid>

    );
}

const mapStateToProps = (state) => ({
    slot: getSelectedSlot(state),
    date: getSelectedDate(state),
    services: getServicesData(state),
    addons: getAddOnsServiceObject(state),
    selectedStore: getLocationData(state),
    extensions: getExtensions(state),
    extensionAddon: getExtensionAddon(state),
    guests: getNumberOfGuests(state),
    serviceTime: getServiceTime(state),
});

export default withRouter(connect(mapStateToProps)(BookingConfirmationContainer));
