import React, { useEffect, useState } from 'react';
import {
 Grid, Paper, Typography, withStyles,
} from '@material-ui/core';
import { MOBILE_BREAKPOINT } from 'Helpers/breakpoints';
import LocatorIcon from 'assets/images/locator-icon.svg';
import config from 'app.config';
import BackImage from './BackImage';

const styles = (theme) => ({
  paper: {
    textAlign: 'left',
    padding: '30px',
    '& a': {
      display: 'block',
      color: '#42413D',
      '&:hover': {
        textDecoration: 'none',
        color: '#000',
      },
    },
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      padding: '15px',
    },
  },
  flexRow: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      flexDirection: 'column',
    },
  },
  category: {
    fontSize: '18px',
    paddingBottom: '20px !important',
    color: '#767676',
  },
  title: {
    fontSize: '20px',
    fontWeight: 500,
  },
  content: {
    padding: '15px 30px',
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      textAlign: 'center',
    },
  },
  link: {
    textDecoration: 'underline',
  },
  anchor: {
    fontSize: '22px',
    fontWeight: 500,
    color: '#42413D !important',
  },
  anchorBorder: {
    borderBottom: '2px solid #42413D',
    '&:hover': {
      textDecoration: 'none',
      borderColor: 'black',
      color: 'black !important',
    },
  },
  lastItem: {
    position: 'relative',
    height: '220px',
    overflow: 'hidden',
    boxshadow: 'none',
    opacity: 0.65,
    '&::after': {
      content: '""',
      width: 'calc(100% - 24px)',
      height: '5rem',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,0),#f5f5f5)',
      position: 'absolute',
      bottom: 0,
    },
  },
  address: {
    display: 'flex',
    alignItems: 'center',
    padding: '4px 0',
  },
  icon: {
    display: 'block',
    margin: '0 8px 4px 0',
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      display: 'none',
    },
  },
  ellipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
  },
  ellipsis6: {
    '-webkit-line-clamp': 6,
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      '-webkit-line-clamp': 2,
    },
  },
});

function SearchItem({
  classes,
  category,
  styles,
  media,
  title,
  address,
  content,
  price,
  slug,
  slugText = 'View Result',
  isLast = false,
}) {
  const { algolia: { searchType } } = config;
  const CATEGORY = [
    searchType.style,
    searchType.store,
    searchType.video,
    searchType.product,
  ];
  const [stylesImage, setStylesImage] = useState(null);
  useEffect(async () => {
    if (category === CATEGORY[0] && !media) {
      let img = '';
      styles.forEach((item) => {
        if (item.title === title) img = item.featuredImage;
      });

      setStylesImage(img);
    }
  }, []);

  return (
      <Grid
          item
          xs={12}
          className={`${isLast ? classes.lastItem : ''}`}
          data-result_element_type={category}
      >
          <Paper className={classes.paper}>
              <Typography className={classes.category}>{category}</Typography>
              <Grid container>
                  <Grid item xs={12} md={10} className={classes.flexRow}>
                      <a href={slug}>
                          <BackImage image={media || stylesImage} isStore={category === CATEGORY[1]} />
                      </a>
                      <a href={slug}>
                          <div className={classes.content}>
                              <Typography className={classes.title}>{title}</Typography>
                              {price && <Typography>{price}</Typography>}
                              {address && (
                              <div className={classes.address}>
                                  <img src={LocatorIcon} className={classes.icon} alt="Locator Icon" />
                                  <Typography>{address}</Typography>
                              </div>
)}
                              <Typography
                                  className={`${classes.ellipsis6} ${classes.ellipsis}`}
                                  dangerouslySetInnerHTML={{ __html: content }}
                              />
                          </div>
                      </a>
                  </Grid>
                  <Grid item xs={12} md={2} className="d-flex align-items-center justify-content-center">
                      <a href={slug} className={`${classes.anchor} ${classes.anchorBorder}`}>{slugText}</a>
                  </Grid>
              </Grid>
          </Paper>
      </Grid>
  );
}

export default withStyles(styles)(SearchItem);
