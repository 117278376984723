import React from 'react';
import classNames from 'classnames';
import styles from "./AppStores.module.scss"

export const AppStores = ({children}) => 
    <div className={classNames(styles.appStores)}>
        {children.map((child,index) => 
            <div className={styles.link} key={index}>
                {child}
            </div>)
        }
    </div>