/* eslint-disable no-shadow */
/* eslint-disable no-case-declarations */
/* eslint-disable prefer-destructuring */
/* eslint-disable max-len */
// get full date-time selectors
export const getSelectedDateTime = (state) => state.booking?.dateTime || '';
export const getLocationData = (state) => state.booking?.location || {};
export const getServicesData = (state) => state.booking?.services?.serviceData || [];
export const getServicesDataAsObject = (state) => getServicesData(state).reduce((obj, curService) => {
        // eslint-disable-next-line no-param-reassign
        obj[curService.user] = curService.data;
        return obj;
    }, {});
export const getServicesAvailableDates = (state) => state.booking?.services?.availableDates || [];
export const getNumberOfGuests = (state) => state.booking?.guests;
export const isGuestWithDifferentServices = (state) => state.booking?.services?.guestsWithDifferentServices;
export const getAddOnsServiceData = (state) => state.booking?.addonsData || [];
export const getAddOnsObject = (state) => getAddOnsServiceData(state).reduce((obj, curService) => {
        // eslint-disable-next-line no-param-reassign
        obj[curService.user] = curService.data;
        return obj;
    }, {});
export const getAddOnsServiceObject = (state) => getAddOnsServiceData(state).reduce((obj, curService) => {
        // eslint-disable-next-line no-param-reassign
        obj[curService.user] = curService.data;
        return obj;
    }, {});
export const getIsEditEnabled = (state) => state.booking?.editEnabled || false;
export const getNotesMessage = (state) => state.booking?.notesMessage || '';

export const getIsEditExtensions = (state) => state.booking?.isEditExtensions || false;

export const getAddonsByUser = (state, user) => state.booking.addonsData?.find((data) => data.user === user)?.data || [];
export const getSelectedDate = (state) => state.booking.dateTime || '';
export const getSelectedSlot = (state) => state.booking.slotTime;
export const getExtensions = (state) => state.booking.extensions;
export const getExtensionAddon = (state) => state.booking.extensionAddon;
export const getCards = (state) => state.booking.cards;
export const getSelectedCard = (state) => state.booking.selectedCard;

export const getAvailableEmpId = (state) => state?.booking?.services?.availEmployeeSlots || {};
export const getAvailableRoomId = (state) => state?.booking?.services?.availRoomSlots || {};
export const getAuthUserInfo = (state) => state?.booking?.authUser || {};
export const getAppointmentIDs = (state) => state?.booking?.appointmentIDs || [];
export const getGroupID = (state) => state?.booking?.groupID;
export const getEditLocationID = (state) => state?.booking?.editLocationID;
export const getCouponCode = (state) => state?.booking?.couponCode || '';

export const getAvailableServiceIds = (state) => {
    const availableDates = getServicesAvailableDates(state);
    return availableDates.reduce((ids, cur) => {
        const serviceIdsByCategory = cur.serviceCategories.reduce((ids1, cur1) => [
                ...ids1,
                ...cur1.services.map((s) => s.serviceId),
            ], []);
        return [
            ...ids,
            ...serviceIdsByCategory,
        ];
    }, []);
};

export const getServicesDataFormatted = (state) => {
    const servicesData = getServicesData(state) || [];
    const formattedData = {};
    const { ID, EmployeeIDs, RoomIDs } = servicesData[0]?.data || {};
    // TODO temporary 0th index for developement
    formattedData.serviceId = ID;
    formattedData.employeeId = EmployeeIDs?.[0];
    formattedData.roomId = RoomIDs?.[1];

    return formattedData;
};

export const isDataSelected = (state, type) => {

    const locationData = getLocationData(state);
    const servicesData = getServicesData(state);
    const slotData = getSelectedSlot(state);
    const guestsData = getNumberOfGuests(state);

    switch (type) {
        case 'How Many':
            if (locationData?.bookerLocationId) {
                return true;
            }
            return false;
        case 'Services':
            if (locationData?.bookerLocationId && guestsData !== null) {
                return true;
            }
            return false;
        case 'Add-ons':
            if (locationData?.bookerLocationId && guestsData !== null && 
                servicesData?.length === state.booking.guests + 1) {
                return true;
            }
            return false;
        case 'Date/Time':
            {
                if (locationData?.bookerLocationId && guestsData !== null && 
                    servicesData?.length === state.booking.guests + 1) {
                    return true;
                }
            }
            return false;
        case 'Notes':
            {
                if (locationData?.bookerLocationId && guestsData !== null && 
                    servicesData?.length === state.booking.guests + 1 && slotData?.Me) {
                    return true;
                }
                return false;
            }
        case 'Hold':
            {
                return false;
            }
        case 'Review':
            {
                return false;
            }
        default:
            return true;
        }
};

export const getBuyoutLocation = (state) => state.booking.buyout;

export const getServiceTime = (state) => {
    const serviceData = getServicesData(state);
    const addonData = getAddOnsObject(state);
    const extensions = getExtensions(state);
    const extensionData = getExtensionAddon(state);

    return Math.max(...serviceData.map(({ user, data: service }) => service.TotalDuration
            + (
                addonData[user]
                    ? addonData[user].reduce((s, c) => s + c.Duration, 0)
                    : 0
            ) + (
                // eslint-disable-next-line no-nested-ternary
                extensions && extensionData
                ? (
                    extensions === true || extensions[user] ? extensionData.TotalDuration : 0
                )
                : 0
            )));
};

export default {};
