/* eslint-disable no-console */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { object } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
    Button,
    Grid,
    Input,
    Typography,
    FormHelperText,
    InputAdornment
} from '@material-ui/core';
import {
    Visibility as VisibilityIcon,
    VisibilityOff as VisibilityOffIcon,
} from '@material-ui/icons';

import { getPageLabelFromUrl } from 'utils/helper';
import SectionTitle from '../../../SectionTitle';
import { changePassword } from '../../../../../api/auth-api';
import restClient from '../../../../../api/restClient';
import ChangePasswordDialog from './Partials/PasswordResetAlert';

const TOKEN_INVALID_MSG = "The token is invalid. Please resend your request."

const REQUIRED_PASSWORD_MSG = "* New password is required"
const REQUIRED_PASSWORD_CONFIRM_MSG = "* New password confirmation is required"
const PASSWORDS_MUST_MATCH_MSG = "* Your new password and the new password confirmation must match"
const PASSWORD_PRECOND_FAILED_MSG = "* You cannot use passwords that have been used before"
const PASSWORD_LEN_MSG = "* The password is not long enough, it must be at least 10 characters"

const PASSWORD_CHANGED_MSG = "Your password has been changed successfully. You can now login."

const useStyles = (theme) => ({
    requestNoteDetails: {
        float: 'right',
        background: theme.palette.common.white,
        boxShadow: '2px 2px 17px rgba(235, 235, 235, 0.5)',
        margin: 'auto',
        maxWidth: '800px',
        padding: '30px 22px',
        textAlign: 'center',
        width: '754px',
    },
    authOperation: {
        fontSize: '18px',
        lineHeight: '45px',
        color: theme.palette.common.white,
        textTransform: 'capitalize',
        background: theme.palette.secondary.main,
        height: '63px',
        width: '378px',
        borderRadius: '0px',
        '&:hover': {
            backgroundColor: 'rgb(58, 60, 62);',
        },
    },
    requestServiceContainer: {
        background: theme.palette.common.lightGrey[3],
        marginBottom: '86px',
    },
    enterYourEmail: {
        width: '100%',
        padding: '7px 0px 0px 0px',
    },
    enterEmailPassword: {
        padding: '29px 0px 0px 0px',
    },
    enterEmailPasswordCopy: {
        float: 'left',
        color: theme.palette.common.lightGrey[4],
    },
    recoverPassword: {
        color: theme.palette.common.lightGrey[4],
        textDecoration: 'underline',
        marginTop: '29px',
        cursor: 'pointer',
    },
    logInWithGoogleFacebook: {
        textAlign: 'center',
    },
    facebookLogin: {
        '& .kep-login-facebook': {
            backgroundColor: theme.palette.common.white,
        },
    },
    visibilityIcon: {
        float: 'right',
        margin: '-33px 0px 0px 0px',
        cursor: 'pointer',
    },
    fieldsContainer: {
        marginBottom: '69px',
    },
    errorMessage: {
        color: '#FF0000',
        textAlign: 'left',
        paddingTop: '5px',
        paddingLeft: '3px',
    },
    backdrop: {
        zIndex: 11,
        color: '#fff',
    },
    modalTitle: {
        textAlign: 'center',
        fontSize: '31px',
        fontFamily: 'DINCondensed-Regular',
        lineHeight: '37px',
        textTransform: 'uppercase',
        fontWeight: '600',
    },
    modalHeader: {
        padding: '30px 24px 15px',
    },
    registerEmail: {
        textAlign: 'center',
        fontSize: '32px',
        color: '#42413D',
    },
    recoverOperation: {
        fontSize: '18px',
        lineHeight: '45px',
        textTransform: 'capitalize',
        height: '63px',
        width: '378px',
        borderRadius: '0px',
        '&:hover': {
            backgroundColor: 'rgb(58, 60, 62);',
            color: '#FFFFFF',
        },
        background: '#F9F9F9',
        margin: '14px 2px 19px 111px',
        border: '1px solid #54575A',
        color: '#54575A',
    },
});

const PasswordResetLanding = ({
    classes,
}) => {
    const [password, setPasswordValue] = useState('');
    const [passwordConfirm, setPasswordConfirmValue] = useState('');
    const [passwordError, setPasswordError] = useState(null);
    const [passwordConfirmError, setPasswordConfirmError] = useState(null);
    const [hideShowPassword, setHideShowPassword] = useState(true);
    const [hideShowPasswordConfirm, setHideShowPasswordConfirm] = useState(true);
    const [token, setToken] = useState("")
    const [isTokenValid, setIsTokenValid] = useState(true);
    const [success, setSuccess] = useState(false);
    const history = useHistory();
    const { search } = useLocation()
    
    useEffect(async () => {

        const query = queryString.parse(search)
        const pt = query["pt"];

        if(pt === undefined) { 
            history.push("/auth/login"); 
            return; 
        } else {
            setToken(pt)
        }
        
        try{
            // this call is to validate the one-time token from the querystring (has blank password means its a validation check)
            // WARNING: this back-end api enables the public to probe it for valid tokens!!
            const res = await restClient.post(changePassword(pt,""));
            // success 200 means that the token is valid
            mParticle.logEvent('Reset Password Token Check - Valid Token', mParticle.EventType.Other, {
                'Source Page': getPageLabelFromUrl(location.pathname)
            });
            setIsTokenValid(true);
        } catch(e){
            // in the case that the token is invalid, display a message
            if (e.response && e.response.data && e.response.data.status === 400) {
                mParticle.logEvent('Reset Password Token Check - Invalid Token', mParticle.EventType.Other, {
                    'Source Page': getPageLabelFromUrl(location.pathname)
                });
                setIsTokenValid(false);
            } 
            else {
                mParticle.logEvent('Reset Password Token Check - Validate Token Error', mParticle.EventType.Other, {
                    'Source Page': getPageLabelFromUrl(location.pathname)
                });
                setIsTokenValid(false);
            }
        }
    }, []);

    const handlePasswordChange = (e) => {
        setPasswordError('');
        setPasswordValue(e.target.value);
    };

    const handlePasswordConfirmChange = (e) => {
        setPasswordConfirmError('');
        setPasswordConfirmValue(e.target.value);
    };

    // WARNING: this toggles a password input to a text input, which seems insecure!
    const handleHideShowPassword = () => {
        setHideShowPassword(!hideShowPassword);
    };

    // WARNING: this toggles a password input to a text input, which seems insecure!
    const handleHideShowConfirmPassword = () => {
        setHideShowPasswordConfirm(!hideShowPasswordConfirm);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (password.length === 0) {
            return setPasswordError(REQUIRED_PASSWORD_MSG);
        }

        if (passwordConfirm.length === 0) {
            return setPasswordConfirmError(REQUIRED_PASSWORD_CONFIRM_MSG);
        }

        if (password.length < 10) {
            return setPasswordError(PASSWORD_LEN_MSG);
        }

        if (passwordConfirm.length < 10) {
            return setPasswordConfirmError(PASSWORD_LEN_MSG);
        }

        if (password !== passwordConfirm) {
            setPasswordError(PASSWORDS_MUST_MATCH_MSG);
            return setPasswordConfirmError(PASSWORDS_MUST_MATCH_MSG);
        }

        if(password){
            const lengthRuleMet = password?.length >= 10;
            const numOrSpecialCharRuleMet = new RegExp('[$&+,:;=?@#|\'<>^*()%-.!0-9]').test(password);
            const upperCaseCharRuleMet = new RegExp('[A-Z]').test(password);
            const lowerCaseCharRuleMet = new RegExp('[a-z]').test(password);

            if(!numOrSpecialCharRuleMet || !lowerCaseCharRuleMet || !upperCaseCharRuleMet || !lengthRuleMet){
                const errorMsg = `* Password must contain ${!lengthRuleMet ? "; At least 10 characters":""}${!numOrSpecialCharRuleMet ? "; A number or special character":""}${!lowerCaseCharRuleMet ? "; A lower case character":""}${!upperCaseCharRuleMet ? "; An upper case character":""}`;
                setPasswordError(errorMsg);
                return setPasswordConfirmError(errorMsg);
            }
        }

        mParticle.logEvent('Reset Password - Invoked', mParticle.EventType.Other, {
            'Source Page': getPageLabelFromUrl(location.pathname)
        });

        try {
            const res = await restClient.post(changePassword(token,password));
            mParticle.logEvent('Reset Password - Change Password Success', mParticle.EventType.Other, {
                'Source Page': getPageLabelFromUrl(location.pathname)
            });
            setSuccess(true)

            // Clean up password values from state
            setPasswordValue('');
            setPasswordConfirmValue('');
            setPasswordError(null);
            setPasswordConfirmError(null); 

        } catch (e) {
            if (e.response && e.response.data && e.response.data.status === 400) {
                mParticle.logEvent('Reset Password - Change Password Invalid Params', mParticle.EventType.Other, {
                    'Source Page': getPageLabelFromUrl(location.pathname)
                });
                setIsTokenValid(false);
                // Clean up password values from state
                setPasswordValue('');
                setPasswordConfirmValue('');
                setPasswordError(null);
                setPasswordConfirmError(null); 
            } 
            // if a password change is disallowed for example its in current use!! 
            // WARNING: having this as a public api will allow users to probe for a users current password
            else if (e.response && e.response.data && e.response.data.status === 412) {
                mParticle.logEvent('Reset Password - Invalid Precondition', mParticle.EventType.Other, {
                    'Source Page': getPageLabelFromUrl(location.pathname)
                });
                setIsTokenValid(true);
                // Clean up password values from state
                setPasswordError(PASSWORD_PRECOND_FAILED_MSG);
                setPasswordConfirmError(PASSWORD_PRECOND_FAILED_MSG);
                setPasswordValue('');
                setPasswordConfirmValue('');
            }
            else {
                mParticle.logEvent('Reset Password - Change Password Error', mParticle.EventType.Other, {
                    'Source Page': getPageLabelFromUrl(location.pathname)
                });
            }
        }
    };

    
    const displayPlaceholder = (e) => {
        e.target.placeholder = `Your ${e.target.name} here...`;
    };

    const hidePlaceholder = (e) =>{
        e.target.placeholder = "";
    }

    const handlePasswordChangeSuccess = () => {
        setSuccess(false);
        setPasswordValue('');
        setPasswordConfirmValue('');
        setPasswordError(null);
        setPasswordConfirmError(null);
        history.push("/auth/login");
    };

     const handleInvalidToken = () => {
        setSuccess(false);
        setPasswordValue('');
        setPasswordConfirmValue('');
        setPasswordError(null);
        setPasswordConfirmError(null);
        history.push("/auth/recover-password");
    };

    return (
        <>
            <Grid container className={classes.requestServiceContainer}>
                <SectionTitle title="Reset Password" />
                <Grid className={classes.requestNoteDetails}>
                    <Grid className={classes.requestContainer}>
                        <form onSubmit={handleSubmit} className={classes.subscribeEmailForm}>
                            <Grid>
                                <Grid className={classes.fieldsContainer}>
                                    <Typography className={classes.enterEmailPasswordCopy}>New Password</Typography>
                                    <Input
                                        classes={{
                                            input: classes.input,
                                        }}
                                        id="standard-adornment-amount"
                                        value={password}
                                        onChange={(e) => handlePasswordChange(e)}
                                        onFocus={displayPlaceholder}
                                        onBlur={hidePlaceholder}
                                        startAdornment=""                                       
                                        name="new password"
                                        className={classes.enterYourEmail}
                                        error={!!passwordError}
                                        type={hideShowPassword ? 'password' : 'text'}
                                        endAdornment={<InputAdornment position="start">{hideShowPassword ? <VisibilityIcon className={classes.visibilityIcon} onClick={() => handleHideShowPassword()} /> : <VisibilityOffIcon className={classes.visibilityIcon} onClick={() => handleHideShowPassword()} />}</InputAdornment>}
                                    />
                                    <FormHelperText>
                                        {passwordError}
                                    </FormHelperText>
                                </Grid>
                                <Grid className={classes.fieldsContainer}>
                                    <Typography className={classes.enterEmailPasswordCopy}>Confirm New Password</Typography>
                                    <Input
                                        classes={{
                                            input: classes.input,
                                        }}
                                        id="standard-adornment-amount"
                                        value={passwordConfirm}
                                        onChange={(e) => handlePasswordConfirmChange(e)}
                                        onFocus={displayPlaceholder}
                                        onBlur={hidePlaceholder}
                                        startAdornment=""
                                        name="new password confirmation"
                                        className={classes.enterYourEmail}
                                        error={!!passwordConfirmError}
                                        type={hideShowPasswordConfirm ? 'password' : 'text'}
                                        endAdornment={<InputAdornment position="start">{hideShowPasswordConfirm ? <VisibilityIcon className={classes.visibilityIcon} onClick={() => handleHideShowConfirmPassword()} /> : <VisibilityOffIcon className={classes.visibilityIcon} onClick={() => handleHideShowConfirmPassword()} />}</InputAdornment>}
                                    />
                                    <FormHelperText>
                                        {passwordConfirmError}
                                    </FormHelperText>
                                </Grid>
                            </Grid>
                            <Button type="submit" className={classes.authOperation} variant="outlined">
                                Reset Password
                            </Button>
                        </form>
                    </Grid>
                </Grid>
                {success && <ChangePasswordDialog openModal={success} classes={classes} onClose={handlePasswordChangeSuccess} message={PASSWORD_CHANGED_MSG} title={"Password Changed"}/>} 
                {!isTokenValid && <ChangePasswordDialog openModal={!isTokenValid} classes={classes} onClose={handleInvalidToken} message={TOKEN_INVALID_MSG} title={"Token Invalid"}/>} 
            </Grid>
        </>
    );
};

PasswordResetLanding.prototype = {
    classes: object.isRequired,
};

export default withStyles(useStyles)(PasswordResetLanding);
