import { makeStyles } from '@material-ui/core/styles';
import {
    Box,
    Button,
    Grid, TextField, Typography, withStyles, Tooltip
} from '@material-ui/core';
import { object } from 'prop-types';
import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import isEqual from 'react-fast-compare';
import InfoIcon from '@material-ui/icons/Info';
import RewardsCardModal from '../../app/Components/RewardsCardModal';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Link, withRouter } from 'react-router-dom';
import Media from 'react-media';
import { withOktaAuth } from '@okta/okta-react';
import useMoktaAuth from 'utils/oktaAuth';
import { getPageLabelFromUrl } from 'utils/helper';
import { MOBILE_BREAKPOINT } from '../../Helpers/breakpoints';


import { getCustomer, findCustomerMemberships } from '../../api/booking-api';
import { useFetchAsync } from 'Helpers/useFetch';
import restClient from '../../api/restClient';
import usStates from './Partials/usStates.json';
import BackdropCircularProgress from '../../app/Components/common/BackdropCircularProgress';
import { getUpdatedCustomer, getNewCard, getChosenStore, getBarflyMembershipPrice } from '../../state/ducks/Barfly/Barfly-Selectors';
import { setUpdatedCustomer, setCard, setRewardsCardNumber } from '../../state/ducks/Barfly/Barfly-Actions';
import MasterCard from '../../assets/images/paymentCard.svg';
import { getCustomerCreditCards } from '../../api/booking-api';
import { getAuthUserInfo, getBuyoutLocation, getLocationData, getServicesData, getAddOnsServiceData, getExtensions, getExtensionAddon, getSelectedCard, getCards } from '../../state/ducks/Booking/Booking-Selectors';
import { getLocations as getBuyoutData } from '../../state/ducks/Buyout/Buyout-Selectors';
import { setRegisterUserInfo, setSelectedCard, setCards } from '../../state/ducks/Booking/Booking-Actions';
import { getBookingTotal } from 'state/utils/helpers';
import { getWorldpayStatus } from '../../state/ducks/Worldpay/Worldpay-Selectors';
import { toggleWorldpay } from '../../state/ducks/Worldpay/Worldpay-Actions';
import appConfig from '../../app.config'



const cardTypes = [
    {
        id: 1,
        label: 'AmericanExpress',
    },
    {
        id: 2,
        label: 'Visa',
    },
    {
        id: 3,
        label: 'MasterCard',
    }
];

const styles = makeStyles((theme) => ({
    container: {
        maxWidth: '1367px',
        width: '100%',
        margin: 'auto',
        padding: '34px',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            padding: '19px',
        },
    },
    fieldContainer: {
        backgroundColor: '#fff',
        padding: '15px 21px 25px 21px',
        margin: '10px 0 5px',
    },
    textField: {
        width: '100%',
        margin: '14px 15px',
        '& input': {
            padding: '10px 0',
            fontSize: '20px',
        },
        '& label': {
            fontSize: '18px',
        },
        '& select': {
            padding: '10px 0',
            fontSize: '20px',
        },
        '& option': {
            padding: '15px',
        },
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            margin: '14px 8px',
        },
    },
    singleLineTextFieldsContainer: {
        display: 'flex',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            flexWrap: 'wrap',
        },
    },
    leftSectionContainer: {
        backgroundColor: theme.palette.common.white,
        margin: 'auto',
        padding: '26px 22px',
        [theme.breakpoints.down('sm')]: {
            padding: '20px 20px',
            margin: '20px 0',
        },
    },
    borderBottom: {
        borderBottom: `1px solid ${theme.palette.common.lightGrey}`,
        width: '88%',
        margin: '22px 5px 2px 41px',
    },
    borderBottomBetween: {
        borderBottom: `1px solid ${theme.palette.common.lightGrey[0]}`,
        width: '42%',
        margin: '0px auto 7px auto',
    },
    borderBottomCopy: {
        margin: '7px auto 0px auto',
    },
    borderOnePx: {
        border: '1px solid #D1D1D1',
        margin: '23px 0px 32px 0px',
        [theme.breakpoints.down('sm')]: {
            border: 'none',
            margin: 0,
        },
    },
    tooltip: {
        display: 'inline-block',
        marginLeft: '5px',
    },
    fieldContainerTitle: {
        height: '13px',
        margin: '39px 884px 0 0',
        fontFamily: 'MillerBanner-Roman',
        fontSize: '16px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#000',
        display: 'inline-block',
    },
    mainTitle: {
        textTransform: 'uppercase',
        fontFamily: 'DINCondensed-Regular',
        fontSize: '42px',
        color: '#42413D',
        fontWeight: '500',
        width: '100%',
        textAlign: 'center',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            fontSize: '31px',
        },
    },
    titleContainer: {
        display: 'flex',
    },
    displayFlex: {
        display: 'flex',
        alignItems: 'center',
        color: '#42413D',
    },
    subTitle: {
        textAlign: 'center',
        fontSize: '18px',
        marginBottom: '25px',
        fontWeight: '400',
        textTransform: 'none',
        fontFamily: 'URWForm',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            fontSize: '15px',
        },
    },
    submitButton: {
        maxWidth: '378px',
        width: '100%',
        height: '63px',
        fontSize: '18px',
        margin: 'auto',
    },
    addressOneWidth: {
        width: '70%',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            width: '100%',
        },
    },
    addressTwoWidth: {
        width: '30%',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            width: '100%',
        },
    },
    updateInfo: {
        width: 'fit-Content',
        height: '16px',
        margin: '5px 0px 39px 0px',
        fontFamily: 'URWForm',
        fontSize: '13px',
        fontWeight: '500',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#54575a',
        padding: '0px 0px 0px 0px'
    },
    cardSelectionContainer: {
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'nowrap',
            overflowY: 'auto',
        },
    },
    addCreditCard: {
        left: '0.05%',
        right: '55.09%',
        bottom: '66.36%',
        fontFamily: theme.typography.fontFamily,
        fontSize: '18px',
        lineHeight: '45px',
        width: '100%',
        textTransform: 'none',
        color: '#54575A',
        border: '1px solid #54575A',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '63px',
            margin: '0px 0px 0px 0px',
        },
    },
    addCardAction: {
        margin: '50px auto 25px',
        width: '50%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            textAlign: 'center',
        },
    },
    cardColumn: {
        position: 'relative',
        maxWidth: '223px',
        height: '163.07px',
        margin: '0% 2% 8% 2%',
        width: '33%',
    },
    masterCard: {
        width: '223px',
        height: '163.07px',
        margin: '0% 2% 8% 2%',
        cursor: 'pointer',
    },
    cardDetails: {
        margin: '-140px 0% 0% 70px',
    },
    cardEnding: {
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.common.lightGrey[1],
        fontSize: '14px',
        cursor: 'pointer',
    },
    cardDigit: {
        fontFamily: theme.typography.fontFamily,
        margin: '9px 0px 0px 0px',
        cursor: 'pointer',
    },
    defaultCopy: {
        margin: '15px 0px 0px !important',
        fontWeight: '600',
        textAlign: 'center',
        width: '223px',
    },
    selectedMasterCard: {
        width: '223px',
        height: '163.07px',
        margin: '0% 2% 8% 2%',
        cursor: 'pointer',
        border: '1px solid #767676',
        borderRadius: '22px',
    },
    savedCardText: {
        margin: '30px 0px 0px 21px !important',
        color: '#767676',
        [theme.breakpoints.down('sm')]: { whiteSpace: 'nowrap' },
    },
    proceedSelectedAction: {
        margin: '50px auto 25px',
        width: '50%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            textAlign: 'center',
        },
    },
    proceedAddCard: {
        margin: '50px 0px 25px',
        width: '100%',
        padding: 0,
        [theme.breakpoints.down('sm')]: {
            margin: '0 0 25px',
            width: '100%',
            padding: '20px',
        },
    },
    proceedSelectClick: {
        left: '0.05%',
        right: '55.09%',
        bottom: '66.36%',
        fontFamily: theme.typography.fontFamily,
        fontSize: '18px',
        lineHeight: '45px',
        width: '100%',
        textTransform: 'none',
        color: '#54575A',
        backgroundColor: '#FFDD30',
        '&:hover': {
            backgroundColor: '#b29a21',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '63px',
        },
    },
    icon: {
        fontSize: '20px',
        color: theme.palette.common.grey,
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            margin: '5px 18px 0px 15px',
        },
    },
    generalErrorMsg: {
        color: '#EE0000',
        fontSize: '0.75rem',
        fontWeight: 500,
        lineHeight: 1.66,
        marginLeft: '15px',
    },
    cardNumber: {
        width: '141px',
        height: '16px',
        margin: '80px 84px 6px 24px',
        fontFamily: 'URWForm',
        fontSize: '12px',
        fontWeight: '500',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#9d9d9d',
    },
    XXXXXXXXXXXX2222: {
        width: '185px',
        height: '22px',
        margin: '6px 40px 15px 24px',
        fontFamily: 'URWForm',
        fontSize: '16px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#a6a6a6',
    },
    expiryDate: {
        width: '74px',
        height: '16px',
        margin: '15px 151px 6px 24px',
        fontFamily: 'URWForm',
        fontSize: '12px',
        fontWeight: '500',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#9d9d9d',
    },
    span: {
        width: '97px',
        height: '22px',
        margin: '6px 128px 0 24px',
        fontFamily: 'URWForm',
        fontSize: '16px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#a6a6a6',
    }


}));

// TODO make this component dynamic - remove repetition
const BarflyEnrollmentPage = ({ history, updatedCustomer, setCustomer, newCard, setNewCard, preferredShop, location, authUserInfo, setAuthUser, setSelectedCard,
    toggleWorldpay, locationData, selectedCard, setRewardsCardNumber, customerCards, setCustomerCards
}) => {
    const [user, setUser] = useState(null);
    const [isAuthenticated, getUser, getUserId] = useMoktaAuth();
    const [showRewardsModal, setShowRewardsModal] = useState(false);
    const [rewardsError, setShowRewardsError] = useState(false);

    const rewardsPrefixLowerBound = parseInt(appConfig.barfly.rewardsCardPrefixRange[0]);
    const rewardsPrefixHigherBound = parseInt(appConfig.barfly.rewardsCardPrefixRange[1]);

    const classes = styles();

    //redirects active customers back to barfly membership page.
    // useEffect(async() => {
    //     const locationId = preferredShop?.bookerLocationId;
    //     const customerId = JSON.stringify(user?.ID);
    //     const { data } = await useFetchAsync(findCustomerMemberships(locationId, customerId));
    //     if (data?.IsSuccess) {
    //         const activeMembership = data?.Results?.filter((x) => x.IsActiveOrSuspended);
    //         if(activeMembership?.length > 0){         
    //             history.push('/account/barfly-membership');
    //         }
    //     }
    // },[user]);

    useEffect(async () => {
        //        TagManager.dataLayer(tagManagerArgs);

        await getUserId().then((res) => {
            setAuthUser(res);
        });
    }, []);

    useEffect(async () => {
        //        if (!user) {
        //    console.log("No user");
        //    console.log(user);
        //const res = await getUserId();//.then(async (res) => {
        //await getUserId().then((res) => {
        //    setAuthUser(res);
        //});
        // console.log('setting user');
        if (authUserInfo.bookerID) {
            await restClient.post(getCustomer(authUserInfo.bookerID)).then(customer => {
                // console.log("Customer received");
                setUser(customer.data?.Customer?.Customer);
                // console.log(customer)
                // console.log(customer.data?.Customer?.Customer)
                // console.log("Customer Set");
                // console.log(user);
            });

            console.log("refreshing cards");
            await restClient.post(getCustomerCreditCards(authUserInfo.bookerID, locationData.bookerLocationId)).then(resCC => {
                if (resCC) {
                    // console.log("Cards received");
                    setCustomerCards(resCC?.data?.CreditCards);
                    // console.log("Cards Set");
                    // console.log(user);
                    /**
                     * Set the default card to be the one selected using filtering.
                     */
                    setSelectedCard(resCC?.data?.CreditCards.filter(c => c.IsDefault)[0])
                    // console.log("default set");
                }
                else {
                    setCustomerCards([]);
                    // console.log("no cards set");

                    //setSelectedCard(null);
                    // console.log("no card set");
                    // console.log(user);
                }

                console.log("after setting cards");

            });
        }
        //});
        //      }
    }, [authUserInfo]);

    //useEffect(() => {
    //    console.log("user is " + JSON.stringify(user));
    //   if(user) { 

    //    restClient.post(getCustomerCreditCards(JSON.stringify(user.ID), locationData.bookerLocationId)).then(resCC => {
    //        console.log(resCC.data);
    //        if (resCC) {

    //            console.log("Cards received");
    //            setCustomerCards(resCC.data.CreditCards);
    //            console.log("Cards Set");
    //            console.log(user);
    //            /**
    //             * Set the default card to be the one selected using filtering.
    //             */
    //            //setSelectedCard(resCC.data.CreditCards.filter(c => c.IsDefault)[0])
    //               console.log("default set");
    //        } else { 
    //               setCustomerCards([]);
    //               console.log("no cards set");

    //               //setSelectedCard(null);
    //               console.log("no card set");
    //            console.log(user);
    //        } 
    //    }); 

    //    }
    //    console.log("first useEffect finished");
    //   }

    //, [user, setUser]);

    // useEffect(() => {
    //     console.log("user: " + JSON.stringify(user));
    //     console.log("user?.bookerID: " + user?.bookerID);
    //     if (user.bookerID) {
    //         console.log("Has bookerID already");
    //         console.log(user);

    //         if (user == null) {
    //             console.log("no user, fetching");
    //             const res = getUserId();
    //             setUser(res);
    //             console.log("set user:" + user);
    //         }

    //         fetchCards().then(cards => {
    //             if(cards) {
    //                 console.log("cards fetched");
    //                 setCards(cards);
    //                 console.log("cards set 2");
    //                 console.log(user);
    //                 /**
    //                  * Set the default card to be the one selected using filtering.
    //                  */

    //                 //setSelectedCard(cards.filter(c => c.IsDefault)[0])
    //                 console.log("default set 2");
    //             } else {
    //                 setCards([]);
    //                 console.log("no cards set 2");
    //                 //setSelectedCard(null);
    //                 console.log("no card set 2");
    //                 console.log("user 2 : " + user);
    //             }
    //         });
    //     }
    //     console.log("second useEffect finished");
    //     console.log(user);
    // }, [user]);



    // useEffect(() => {
    //     console.log("AuthUserInfo: " + authUserInfo);
    //     console.log("AuthUserInfo?.bookerID: " + authUserInfo?.bookerID);
    //     if (authUserInfo.bookerID) {
    //         console.log("Has bookerID already");
    //         console.log(user);
    //         fetchCards().then(cards => {
    //             if(cards) {
    //                 console.log("cards fetched");
    //                 setCards(cards);
    //                 console.log("cards set 2");
    //                 console.log(user);
    //                 /**
    //                  * Set the default card to be the one selected using filtering.
    //                  */

    //                 //setSelectedCard(cards.filter(c => c.IsDefault)[0])
    //                 console.log("default set 2");
    //             } else {
    //                 setCards([]);
    //                 console.log("no cards set 2");
    //                 //setSelectedCard(null);
    //                 console.log("no card set 2");
    //                 console.log(user);
    //             }
    //         });
    //     }
    //     console.log("second useEffect finished");
    //     console.log(user);
    // }, [authUserInfo]);

    if (!preferredShop.contact) {
        history.push('/barfly-membership');
    }

    const handleCardSelection = (cardKey) => {
        setSelectedCard(cardKey);
    };

    const fetchCards = async () => {
        console.log(authUserInfo);
        const customerId = authUserInfo?.bookerID;
        console.log(`customerID: ${customerId}`);
        const { data, error } = await restClient.post(getCustomerCreditCards(customerId, locationData.bookerLocationId));
        return data?.CreditCards;
    }

    const goToConfirmBarfly = async (values) => {
        setCustomer({ customer: user });
        setNewCard(selectedCard);
        history.push('/barfly-confirm');
    };

    const onOpenRewards = () => {
        setShowRewardsModal(true);
    };

    const validateRewardsCard = (number) => {
        if (number && number.length !== 0 && (number.length !== 16 || isNaN(number) || !(rewardsPrefixLowerBound <= parseInt(number.slice(0, 8)) && parseInt(number.slice(0, 8)) <= rewardsPrefixHigherBound))) {
            setShowRewardsError(true);
        }
        else {
            setShowRewardsError(false);
            setRewardsCardNumber(number);
        }
        console.log(number.length);
    }

    const getCardExpirationDate = (cardExpirationDate) => {
        const year = cardExpirationDate.getFullYear().toString().slice(2);
        const month = cardExpirationDate.getMonth() + 1;
        let fullMonth = "";
        if (month < 10) {
            fullMonth = `0${month}`;
        }
        else {
            fullMonth = month;
        }
        return `${fullMonth}/${year}`
    }

    if (!user) {
        return <BackdropCircularProgress />;
    }

    const MaterialUIField = (label, fieldName, disabled) => (
        <Field name={fieldName}>
            {
                ({ field, meta }) => (
                    <TextField
                        label={label}
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        {...field}
                        error={!!meta.error}
                        helperText={meta.error}
                        disabled={disabled}
                    />
                )
            }
        </Field>
    );

    const onBack = () => {
        mParticle.logEvent('Navigation - Go Back', mParticle.EventType.Navigation, {
            'Source Page': getPageLabelFromUrl(location.pathname),
        });
    };

    if (user) {
        return (
            <Formik
                initialValues={{
                    ...user,
                }}
                enableReinitialize
                onSubmit={goToConfirmBarfly}
            >
                {({
                    values,
                    errors,
                    handleSubmit,
                }) => (
                    <form
                        onSubmit={handleSubmit}
                        data-form_type="membership_signup"
                    >
                        <Grid className={classes.container}>
                            <Media
                                query={{ maxWidth: MOBILE_BREAKPOINT }}
                            >
                                {
                                    (matches) => (matches
                                        ? (
                                            <Grid className={classes.titleContainer}>
                                                <Grid style={{ paddingTop: '12px' }}>
                                                    <Link to="/barfly-membership" className={classes.displayFlex} onClick={onBack}>
                                                        <ArrowBackIosIcon style={{ fontSize: '34px' }} />
                                                    </Link>
                                                </Grid>
                                                <Typography className={classes.mainTitle}>
                                                    <Box>
                                                        Lets Get
                                                    </Box>
                                                    <Box>
                                                        A Bit More Information
                                                    </Box>
                                                    <Box className={classes.subTitle}>
                                                        and complete your barfly membership
                                                    </Box>
                                                </Typography>
                                            </Grid>
                                        )
                                        : (
                                            <Grid className={classes.titleContainer}>
                                                <Grid style={{ paddingTop: '12px' }}>
                                                    <Link to="/barfly-membership" className={classes.displayFlex} onClick={onBack}>
                                                        <ArrowBackIosIcon style={{ fontSize: '34px' }} />
                                                        <Typography style={{
                                                            fontSize: '16px',
                                                            fontWeight: '600',
                                                            paddingTop: '2px',
                                                        }}
                                                        >
                                                            Back
                                                        </Typography>
                                                    </Link>
                                                </Grid>
                                                <Typography className={classes.mainTitle}>
                                                    Lets Get A Bit More Information
                                                    <Box className={classes.subTitle}>
                                                        and complete your barfly membership
                                                    </Box>
                                                </Typography>
                                            </Grid>
                                        ))
                                }
                            </Media>

                            <Typography className={classes.fieldContainerTitle}>GENERAL INFORMATION</Typography>
                            <Grid className={classes.fieldContainer}>
                                <Grid className={classes.singleLineTextFieldsContainer}>
                                    {MaterialUIField('First Name', 'FirstName', true)}
                                    {MaterialUIField('Last Name', 'LastName', true)}
                                </Grid>
                                <Grid className={classes.singleLineTextFieldsContainer}>
                                    {MaterialUIField('Email', 'Email', true)}
                                    {MaterialUIField('Phone Number', 'CellPhone', true)}

                                </Grid>

                            </Grid>
                            <div className={classes.updateInfo}>
                                <p >
                                    If you would like to update your personal details, please go to the <a href="/account/information" >Account Information</a> page.
                                </p>
                            </div>

                            {showRewardsModal ? (
                                <RewardsCardModal
                                    onClose={() => setShowRewardsModal(false)}
                                />
                            ) : null}
                            <Grid>
                                <Typography className={classes.fieldContainerTitle}>HARRODS REWARDS</Typography>
                                <Tooltip
                                    disableHoverListener
                                    placement="left-start"
                                    className={classes.tooltip}
                                    onClick={() => onOpenRewards()}
                                >
                                    <InfoIcon className={classes.icon} />
                                </Tooltip>
                            </Grid>


                            <Grid className={classes.fieldContainer}>
                                <Grid className={classes.singleLineTextFieldsContainer}>
                                </Grid>

                                <TextField
                                    label={'Harrods Rewards card number (Optional)'}
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    required={false}
                                    onBlur={(e) => { validateRewardsCard(e.target.value); }}
                                    placeholder={'Enter your 16 digit Rewards card number'}
                                />

                                {rewardsError ? <div className={classes.generalErrorMsg}><p> Rewards Number must be a 16 digit number </p></div> : null}
                            </Grid>
                            <div className={classes.updateInfo}>
                                <p >
                                    Use of your Harrods Rewards loyalty card is subject to the  <a target='_blank' href="https://www.harrods.com/en-gb/legal/harrods-rewards" >terms of conditions</a> of the Harrods Rewards programme.
                                </p>
                            </div>

                            <p className={classes.fieldContainerTitle}>PAYMENT CARD</p>
                            <Grid className={classes.fieldContainer}>
                                <div className={classes.borderOnePx} />
                                {customerCards?.length > 0 ?
                                    (
                                        <>
                                            <Typography className={classes.savedCardText}>{`Your saved card${customerCards?.length > 1 ? 's' : ''}`}</Typography>
                                            <Grid container spacing={3} className={classes.cardSelectionContainer}>
                                                {customerCards?.map((cardKey, idx) => (
                                                    <Grid item sm={4} onClick={() => handleCardSelection(cardKey)}>
                                                        <img
                                                            src={MasterCard}
                                                            alt="Card Info"
                                                            className={selectedCard && selectedCard?.ID === cardKey?.ID ? classes.selectedMasterCard : classes.masterCard}
                                                        />
                                                        <div className={classes.cardDetails}>
                                                            <div className={classes.cardEnding}>User card ending</div>
                                                            <div className={classes.cardDigit}>{cardKey.CreditCard.Number}</div>
                                                        </div>
                                                        <div className={classes.cardNumber}>
                                                            Card Number
                                                        </div>
                                                        <div className={classes.XXXXXXXXXXXX2222}>
                                                            {`XXXX XXXX XXXX ${cardKey.CreditCard.Number.slice(12)}`}
                                                        </div>
                                                        <div className={classes.expiryDate}>
                                                            Expiry Date
                                                        </div>
                                                        <div className={classes.span}>
                                                            {getCardExpirationDate(new Date(cardKey.CreditCard.expirationDate))}
                                                        </div>
                                                        {
                                                            cardKey?.IsDefault && <Typography className={classes.defaultCopy}>Default</Typography>
                                                        }
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </>
                                    ) : null}

                                <div className={classes.addCardAction}>
                                    {customerCards?.length > 0 ? (<>
                                        <Button className={classes.addCreditCard} variant="outlined" onClick={() => toggleWorldpay()}>
                                            Add New Card
                                        </Button>
                                    </>) : (
                                        <>
                                            <Button className={classes.addCreditCard} variant="outlined" onClick={() => toggleWorldpay()}>
                                                Add a Debit/Credit Card
                                            </Button>
                                        </>)}
                                </div>
                                <div />

                            </Grid>

                            {customerCards?.length !== 0 &&
                                <div className={classes.proceedSelectedAction}>
                                    <Button disabled={!selectedCard}
                                        onClick={goToConfirmBarfly}
                                        className={classes.proceedSelectClick} variant="outlined">
                                        Use Selected Card
                                    </Button>
                                </div>
                            }
                        </Grid>

                    </form>
                )}
            </Formik>

        );
    }
    return null;
};

BarflyEnrollmentPage.propTypes = {
    classes: object.isRequired,
    history: object.isRequired
};

const mapStateToProps = (state) => ({
    updatedCustomer: getUpdatedCustomer(state),
    newCard: getNewCard(state),
    preferredShop: getChosenStore(state),
    authUserInfo: getAuthUserInfo(state),
    fromBuyout: getBuyoutLocation(state),
    buyout: getBuyoutData(state),
    locationData: getLocationData(state),
    buyoutLocation: getBuyoutLocation(state),
    services: getServicesData(state),
    addons: getAddOnsServiceData(state),
    extensions: getExtensions(state),
    extensionAddon: getExtensionAddon(state),
    selectedCard: getSelectedCard(state),
    bookingTotal: getBookingTotal(state),
    isWorldpayOpen: getWorldpayStatus(state),
    customerCards: getCards(state)
});

const mapDispatchToProps = (dispatch) => ({
    setCustomer: bindActionCreators(setUpdatedCustomer, dispatch),
    setNewCard: bindActionCreators(setCard, dispatch),
    setSelectedCard: bindActionCreators(setSelectedCard, dispatch),
    setAuthUser: bindActionCreators(setRegisterUserInfo, dispatch),
    toggleWorldpay: bindActionCreators(toggleWorldpay, dispatch),
    setRewardsCardNumber: bindActionCreators(setRewardsCardNumber, dispatch),
    setCustomerCards: bindActionCreators(setCards, dispatch)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)((withOktaAuth(BarflyEnrollmentPage))));
