import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { object, array } from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
    Button,
    Grid,
    Typography,
    withStyles,
    Select,
    MenuItem,
    FormControl,
} from '@material-ui/core';
import { withOktaAuth } from '@okta/okta-react';
import useMoktaAuth from 'utils/oktaAuth';
import { toast, ToastContainer } from 'react-toastify';
import {
    chooseStore,
    loadStoresContentful,
    setFindMembershipDetails,
} from '../../state/ducks/Barfly/Barfly-Actions';
import barflyHeader from '../../assets/images/barflyHeader.jpg';
import BarflyMembershipPriceCard from './Partials/BarflyMembershipPriceCard';
import barflyMembershipHeartDark from '../../assets/images/barflyMembershipHeartDark.svg';
import {
    MEDIUM_MOBILE_BREAKPOINT,
    MOBILE_BREAKPOINT,
    TABLET_BREAKPPOINT,
} from '../../Helpers/breakpoints';
import {
    getChosenStore,
    getStores,
    isStoreLoaded,
    getSelectedLocMembershipPrice,
} from '../../state/ducks/Barfly/Barfly-Selectors';
import MembershipSignupModal from '../../app/Components/MembershipSignupModal';

import { useFetchAsync } from '../../Helpers/useFetch';

import {
    findMemberships,
    findCustomerMemberships,
} from '../../api/booking-api';
import appConfig from '../../app.config';
import axios from 'axios';

import 'react-toastify/dist/ReactToastify.css';
import './BarflyMembership.scss';

const styles = (theme) => ({
    barflyPageContainer: {
        maxWidth: '1400px',
        width: '100%',
        margin: 'auto',
        backgroundColor: '#fff',
        padding: '36px',
        [theme.breakpoints.down(TABLET_BREAKPPOINT)]: {
            padding: '5px',
        },
    },
    barflyAccountPageContainer: {
        padding: '0px',
        [theme.breakpoints.down(TABLET_BREAKPPOINT)]: {
            backgroundColor: '#f9f9f9',
        },
    },
    headerImage: {
        margin: '5px 0',
        width: '100%',
    },
    mainContent: {
        margin: '50px 0 130px',
        [theme.breakpoints.down(TABLET_BREAKPPOINT)]: {
            margin: '30px 0 130px',
        },
        [theme.breakpoints.down(MEDIUM_MOBILE_BREAKPOINT)]: {
            margin: '30px 0',
        },
    },
    storeSearchHeader: {
        fontWeight: '800',
        fontSize: '25px',
        lineHeight: '29px',
    },
    searchFieldHeight: {
        height: '100%',
        fontSize: '18px',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            fontSize: '15px',
        },
    },
    memberShipDetailsContainer: {
        backgroundImage:
            'linear-gradient(135deg, #ffffff 35.71%, #e2e2e2 35.71%, #e2e2e2 50%, #ffffff 50%, #ffffff 85.71%, #e2e2e2 85.71%, #e2e2e2 100%)',
        backgroundSize: '9.90px 9.90px',
        padding: '36px 20px',
        display: 'flex',
        border: '2px solid #E2E2E2',
        boxSizing: 'border-box',
        [theme.breakpoints.down(TABLET_BREAKPPOINT)]: {
            flexWrap: 'wrap',
            padding: '10px 4px',
        },
        [theme.breakpoints.down(MEDIUM_MOBILE_BREAKPOINT)]: {
            background: 'transparent',
            borderWidth: 0,
        },
    },
    memberShipAccountDetails: {
        padding: '17px 0px',
    },
    barflyMembershipPriceCard: {
        padding: '18px 14px',
        position: 'relative',
        [theme.breakpoints.down(TABLET_BREAKPPOINT)]: {
            padding: '14px',
        },
        [theme.breakpoints.down(MEDIUM_MOBILE_BREAKPOINT)]: {
            backgroundImage:
                'linear-gradient(135deg, #ffffff 35.71%, #e2e2e2 35.71%, #e2e2e2 50%, #ffffff 50%, #ffffff 85.71%, #e2e2e2 85.71%, #e2e2e2 100%)',
            backgroundSize: '9.90px 9.90px',
            border: '2px solid #E2E2E2',
            marginBottom: 110,
        },
    },
    darkCard: {
        backgroundColor: '#42413D',
        color: '#fff',
    },
    callUsContainer: {
        background: '#F7F8F9',
        borderTop: '1px solid #D1D1D1',
        padding: '42px 20px 60px 20px',
        textAlign: 'center',
    },
    callUsText: {
        fontSize: '25px',
        color: '#42413D',
        marginBottom: '42px',
    },
    callUsButton: {
        border: '1px solid #42413D',
        maxWidth: '378px',
        width: '100%',
        padding: '15px',
        textTransform: 'none',
        fontSize: '18px',
        borderRadius: '0',
        marginTop: '42px',
        display: 'inline-block',
        color: '#42413D',
        textDecoration: 'none',
    },
    cancelButton: {
        color: '#42413D',
        textDecoration: 'underline',
        fontSize: '18px',
        lineHeight: '18px',
        margin: '-3px 0 0',
        padding: '0 5px',
    },
    upgradeButton: {
        color: '#42413D',
        textDecoration: 'underline',
        fontSize: '25px',
        padding: '0 5px',
        lineHeight: 1,
    },
    clickHereText: {
        margin: '26px auto !important',
        textAlign: 'center',
        fontSize: '18px',
        color: '#767676',
    },
    selectButton: {
        maxWidth: '378px',
        height: '48px',
        fontSize: '15px',
        [theme.breakpoints.down(TABLET_BREAKPPOINT)]: {
            flexWrap: 'wrap',
            margin: '14px 0',
            maxWidth: 'none',
            width: '100%',
        },
    },
    barflyAccountUpgrade: {
        display: 'flex',
        marginLeft: '97px',
        [theme.breakpoints.down(TABLET_BREAKPPOINT)]: {
            display: 'block',
            marginLeft: '0px',
        },
    },
    error: {
        color: '#f44336',
    },
    membershipTipText: {
        lineHeight: '48px',
        textAlign: 'center',
    },
    membershipTip: {
        position: 'absolute',
        top: 'calc(100% + 50px)',
        width: 'calc(100% - 28px)',
        left: 14,

        [theme.breakpoints.down(TABLET_BREAKPPOINT)]: {
            width: 'calc(100% - 28px)',
        },
        [theme.breakpoints.down(MEDIUM_MOBILE_BREAKPOINT)]: {
            top: 'calc(100% + 20px)',
            left: '50%',
            transform: 'translateX(-50%)',
        },
    },
    upgradeBtn: {
        fontSize: '15px',
        maxWidth: '374px',
        height: '48px',
    },
    errorContainer: {
        background: 'rgba(189, 0, 0, 0.7)',
        borderRadius: 12,
        minHeight: 51,
    },
    errorBody: {
        fontSize: '16px',
        lineHeight: '25px',
        color: '#fff',
        textAlign: 'center',
    },
});

const BarflyMembershipEnrollment = ({
    classes,
    barflyMembershipCollection,
    selectedStore,
    chooseStore,
    storeLoaded,
    stores,
    loadStoresFromContentful,
    isAccountBarfly,
    setMembershipData,
    getLocationMembership,
    isCustomerMembership,
    history,
    AccountMembershipPrice,
    isMembership,
    location,
    handleUpgradePremium,
    bannerImage,
}) => {
    // const [searchKey, setSearchKey] = useState('');
    // const [matchedStores, setMatchedStores] = useState([]);
    const [customerMembership, setCustomerMembership] = useState(null);
    const [storeType, setStoreType] = useState('Drybar Shop');
    const [user, setUser] = useState(null);
    const [isAuthenticated, getUser, getUserId] = useMoktaAuth();

    // const [memberships, setMemberships] = useState([]);
    const [membershipSignupModal, setMembershipSignupModal] = useState(false);
    const [error, setError] = useState(null);
    const [preferredShopChoices, setPreferredShopChoices] = useState([]);

    //From the CMS
    const singleMembership =
        barflyMembershipCollection?.length > 0
            ? barflyMembershipCollection[0]
            : {
                  id: '',
                  price: 0,
              };
    const doubleMembership =
        barflyMembershipCollection?.length > 1
            ? barflyMembershipCollection?.[1]
            : {
                  id: '',
                  price: 0,
              };

    useEffect(async () => {
        if (selectedStore?.bookerLocationId) {
            console.log('store already selected');
        } else {
            console.log('store not selected, picking KB');
            chooseStore(stores[0]);
            loadCustomerFindMembership();
        }
    }, [stores]);

    useEffect(() => {
        axios
            .post(appConfig.apiUrl + '/forms/getForms', {
                subDomain: appConfig.wufoo.subDomain,
                formId: appConfig.wufoo.genericPartyFormId,
                key: btoa(`${appConfig.wufoo.apiKey}:password`),
            })
            .then((res) => {
                const { data } = res;
                const fields = data?.Fields || [];
                fields.forEach((field) => {
                    if (field.Title === 'Preferred Shop') {
                        setPreferredShopChoices(
                            field.Choices.map((choice) => choice.Label || '')
                        );
                    }
                });
            })
            .catch((error) => console.log('error in wufoo api ', error));
    }, []);

    const loadCustomerFindMembership = async () => {
        const locationId = selectedStore?.bookerLocationId;
        const customerId = user?.bookerID;
        const { data } = await useFetchAsync(
            findCustomerMemberships(locationId, customerId)
        );
        if (data?.IsSuccess) {
            //Need to confirm if first index will always be addOns or Blowdrys
            const activeMembership = data?.Results?.filter(
                (x) => x.IsActiveOrSuspended
            );
            if (activeMembership?.length > 0) {
                setCustomerMembership(activeMembership[0]);
                //Must set disabled dropdown to customer's store if they have an active membership.
                const customersStore = stores.filter(
                    (store) =>
                        store.bookerLocationId ===
                        activeMembership[0].LocationID.toString()
                );
                chooseStore(customersStore?.[0] ?? stores[0]);
            } else {
                chooseStore(stores[0]);
            }
        } else {
            chooseStore(stores[0]);
        }
    };

    useEffect(async () => {
        if (isAuthenticated()) {
            if (user) {
                loadStoresFromContentful({});
                loadCustomerFindMembership();
            } else {
                try {
                    const res = await getUserId();
                    setUser(res);
                } catch (e) {}
            }
        } else {
            await loadStoresFromContentful({});
        }
    }, [user]);

    // useEffect(() => {
    //     if (!isAuthenticated()) {
    //         chooseStore(stores[0]);
    //     }
    // }, [chooseStore, isAuthenticated, stores]);

    // search store
    // const onSearchStore = useCallback(
    //     async () => {
    //         if (searchKey === '') {
    //             showErrors();
    //             return;
    //         }

    //         mParticle.logEvent('Account - Find a Shop for Barfly Membership', mParticle.EventType.Search, {
    //             'Source Page': getPageLabelFromUrl(location.pathname),
    //             'Page Name': getPageLabelFromUrl(location.pathname),
    //             'Page Category': 'Barfly | Find Shop',
    //             'Search Term': searchKey,
    //         });

    //         // Override with search location, if set
    //         const geolocated = await geolocateSearchLocation(searchKey);

    //         const { data, error } = await findStoresFromPointWithTitle(geolocated, storeType, searchKey);

    //         if (data) {
    //             if (data.length) setSearchKey('');
    //             setMatchedStores(data);
    //         }

    //         if (error) {
    //             showErrors(error);
    //         }
    //     },
    //     [searchKey, setMatchedStores, setSearchKey, setError],
    // );

    const onRefreshMemberships = async () => {
        const storeLocationId = selectedStore?.bookerLocationId;
        if (storeLocationId) {
            const { data, error } = await useFetchAsync(
                findMemberships(storeLocationId)
            );
            if (error) {
                console.log(error);
            }

            if (data?.IsSuccess) {
                let memberships = data.Results || [];
                //filter out the dummy plans that booker would not allow us to remove
                memberships = memberships.filter(
                    (membershipPlan) => membershipPlan.IsActive
                );
                memberships = memberships.sort(
                    (m1, m2) =>
                        m1.MembershipBillableItem?.Price?.Amount -
                        m2?.MembershipBillableItem?.Price?.Amount
                );

                // managing the membership details through redux price would be there if user selected any location - US comment

                // Sets the memberhship plans for the location selected in barfly state.
                setMembershipData(memberships);
            }
        }
    };

    useEffect(() => {
        if (selectedStore) {
            onRefreshMemberships();
        } else {
        }
    }, [selectedStore]);

    // const handleCloseModal = () => {
    //     onRefreshMemberships();
    //     setMatchedStores([]);
    // };

    // const handleKeyUp = (e) => {
    //     if (e.keyCode === 13) {
    //         onSearchStore();
    //     }
    // };

    // const handleKeyChange = useCallback(
    //     (e) => {
    //         if (error) setError(null);
    //         setSearchKey(e.target.value);
    //     },
    //     [error, setSearchKey, setError],
    // );

    const showErrors = (errorMessage = 'Please select a store.') => {
        toast.error(errorMessage, {
            className: classes.errorContainer,
            bodyClassName: classes.errorBody,
        });
    };

    // const onSelectStore = (store) => {
    //     mParticle.logEvent('Account - Select a Shop for Barfly Membership', mParticle.EventType.Other, {
    //         'Source Page': getPageLabelFromUrl(location.pathname),
    //         'Page Name': getPageLabelFromUrl(location.pathname),
    //         'Page Category': 'Barfly | Find Shop',
    //         'Membership Location': store.bookerLocationId,
    //     });
    // };

    // checking the if customer has membership available or not through findCustomerMembership Id ---- for now LocationId is static just for testing

    // Check if customer has a membership from findCustomerMemberships end point.
    const hasMembershipAvail = customerMembership?.IsActiveOrSuspended
        ? customerMembership?.MembershipBenefit?.MembershipLevelID
        : '';

    //Currently DefaultInitiationFee is same as MembershipBillableItem.Price.Amount. Latter value is recurring fee and is being provided to confirm and review page as price.

    //Select the pricing information based on the selected stores single and double memberships else fall back onto the CMS for barfly which is range of values.
    const chosenSingleMembershipPrice =
        getLocationMembership[0]?.MembershipBillableItem?.Price?.Amount ??
        singleMembership.price;
    const chosenDoubleMembershipPrice =
        getLocationMembership[1]?.MembershipBillableItem?.Price?.Amount ??
        doubleMembership.price;

    return (
        <Grid
            className={
                isAccountBarfly
                    ? `${classes.barflyAccountPageContainer} ${classes.barflyPageContainer}`
                    : classes.barflyPageContainer
            }
            id="barfly-membership"
        >
            {!isAccountBarfly && (
                <img
                    className={classes.headerImage}
                    src={bannerImage ? bannerImage : barflyHeader}
                    alt="Barfly Membership"
                />
            )}
            <Grid className={classes.mainContent}>
                {!isAccountBarfly && (
                    <>
                        <Typography className={classes.storeSearchHeader}>
                            Select your shop below to get started! Prices vary
                            by location.
                        </Typography>
                        <Grid className="preferred-store-container d-flex flex-column">
                            <Grid className="d-flex align-items-center selected-store">
                                <Typography className="label">
                                    Store Selected:
                                </Typography>
                                <Typography className="value">
                                    {selectedStore.title}
                                </Typography>
                            </Grid>
                            <FormControl>
                                <Select
                                    required={true}
                                    disabled={
                                        customerMembership?.IsActiveOrSuspended
                                    }
                                    onChange={(e) => {
                                        chooseStore(e.target.value);
                                    }}
                                >
                                    {stores.map((store) => (
                                        <MenuItem value={store}>
                                            <Grid className="store-item">
                                                <Grid
                                                    container
                                                    direction="column"
                                                    className="store-item-inner"
                                                >
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        alignItems="center"
                                                        className="store-title-row"
                                                    >
                                                        <Grid
                                                            xs={7}
                                                            className="d-flex align-items-start"
                                                        >
                                                            <span className="store-title">
                                                                {store.title}
                                                            </span>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justify="flex-strat"
                                                        className="store-location-row"
                                                    >
                                                        <Grid
                                                            xs={7}
                                                            className="d-flex"
                                                            direction="row"
                                                            justify="flex-start"
                                                        >
                                                            <span className="store-location">
                                                                {`${store.contact?.street1},${store.contact?.street2}, ${store.contact?.city}, ${store.contact?.postalCode}`}
                                                            </span>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </>
                )}

                <Grid
                    className={
                        isAccountBarfly
                            ? `${classes.memberShipAccountDetails} ${classes.memberShipDetailsContainer}`
                            : classes.memberShipDetailsContainer
                    }
                    container
                >
                    <Grid
                        className={classes.barflyMembershipPriceCard}
                        item
                        xs={12}
                        sm={6}
                    >
                        <BarflyMembershipPriceCard
                            user={user}
                            membershipId={getLocationMembership?.[0]?.ID ?? ''}
                            headerTitle={singleMembership.title}
                            price={chosenSingleMembershipPrice}
                            blowouts={singleMembership.subtitle}
                            additionalOffers={
                                singleMembership?.benefitsCollection?.items
                            }
                            isAccountBarfly={isAccountBarfly}
                            onClickWithoutLocation={() => showErrors()}
                            type={singleMembership.title}
                            isCurrentMembership={
                                getLocationMembership?.[0]?.MembershipLevel
                                    .ID === hasMembershipAvail ?? false
                            }
                            hasMembership={hasMembershipAvail}
                            sellMemberships={
                                getLocationMembership?.[0]?.SellOnline
                            }
                        />
                        {isAccountBarfly && (
                            <>
                                {(getLocationMembership?.[0]?.ID ===
                                    hasMembershipAvail ??
                                    false) && (
                                    <Typography
                                        className={classes.membershipTip}
                                    >
                                        Your Current Plan
                                    </Typography>
                                )}
                            </>
                        )}
                    </Grid>
                    <Grid
                        className={classes.barflyMembershipPriceCard}
                        item
                        xs={12}
                        sm={6}
                    >
                        <BarflyMembershipPriceCard
                            user={user}
                            membershipId={getLocationMembership?.[1]?.ID ?? ''}
                            headerTitle={doubleMembership.title}
                            classes={{
                                headerTitle: classes.darkCard,
                            }}
                            priceCardHeaderIcon={barflyMembershipHeartDark}
                            price={chosenDoubleMembershipPrice}
                            blowouts={doubleMembership.subtitle}
                            additionalOffers={
                                doubleMembership?.benefitsCollection?.items
                            }
                            isAccountBarfly={isAccountBarfly}
                            onClickWithoutLocation={() => showErrors()}
                            type={doubleMembership.title}
                            isCurrentMembership={
                                getLocationMembership?.[1]?.MembershipLevel
                                    .ID === hasMembershipAvail ?? false
                            }
                            hasMembership={hasMembershipAvail}
                            sellMemberships={
                                getLocationMembership?.[1]?.SellOnline
                            }
                        />

                        {isAccountBarfly && (
                            <>
                                {(getLocationMembership?.[1]?.ID ===
                                    hasMembershipAvail ??
                                    false) && (
                                    <Typography
                                        className={`${classes.membershipTip} ${classes.membershipTipText}`}
                                    >
                                        Your Current Plan
                                    </Typography>
                                )}
                                {/* {
                                isCustomerMembership !== 102304 && (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={`${classes.upgradeBtn} ${classes.membershipTip}`}     
                                    disabled={false}                               
                                >
                                    {isMembership ? 'Upgrade to Premium' : 'Select'}
                                </Button>
)
                            } */}
                            </>
                        )}
                    </Grid>
                    {/* <Grid
                        className={classes.barflyMembershipPriceCard}
                        item
                        xs={12}
                        sm={6}
                    >
                        <BarflyMembershipPriceCard
                            user={user}
                            membershipId={getLocationMembership?.[1]?.ID ?? ''}
                            headerTitle={doubleMembership.title}
                            classes={{
                                headerTitle: classes.darkCard,
                            }}
                            priceCardHeaderIcon={barflyMembershipHeartDark}
                            price={chosenDoubleMembershipPrice}
                            blowouts={doubleMembership.subtitle}
                            additionalOffers={
                                doubleMembership.benefitsCollection.items
                            }
                            isAccountBarfly={isAccountBarfly}
                            onClickWithoutLocation={() => showErrors()}
                            type={doubleMembership.title}
                            isCurrentMembership={
                                getLocationMembership?.[1]?.MembershipLevel
                                    .ID === hasMembershipAvail ?? false
                            }
                            hasMembership={hasMembershipAvail}
                            sellMemberships={
                                getLocationMembership?.[1]?.SellOnline
                            }
                        />

                        {isAccountBarfly && (
                            <>
                                {(getLocationMembership?.[1]?.ID ===
                                    hasMembershipAvail ??
                                    false) && (
                                    <Typography
                                        className={`${classes.membershipTip} ${classes.membershipTipText}`}
                                    >
                                        Your Current Plan
                                    </Typography>
                                )}
                            </>
                        )}
                    </Grid> */}
                </Grid>

                <>
                    <Typography
                        style={{
                            margin: '26px auto',
                            textAlign: 'center',
                            fontSize: '18px',
                            color: '#767676',
                        }}
                    ></Typography>
                    <Typography className={classes.clickHereText}>
                        If you’d like to suspend or cancel your membership
                        today, please
                        {/* contact <a href="/contact">Customer Service</a>.  */}
                        <Button
                            variant="text"
                            className={classes.cancelButton}
                            onClick={() => setMembershipSignupModal('cancel')}
                        >
                            click here
                        </Button>
                        .
                    </Typography>
                </>

                {membershipSignupModal ? (
                    <MembershipSignupModal
                        onClose={() => setMembershipSignupModal(false)}
                        membershipAction={membershipSignupModal}
                        preferredShopChoices={preferredShopChoices}
                    />
                ) : null}
            </Grid>
            <ToastContainer
                style={{
                    maxWidth: 640,
                    top: 50,
                    width: '100%',
                    paddingLeft: 20,
                    paddingRight: 20,
                }}
                hideProgressBar
                position="top-center"
                closeButton={false}
                autoClose={5000}
            />
        </Grid>
    );
};

BarflyMembershipEnrollment.propTypes = {
    classes: object.isRequired,
    barflyMembershipCollection: array.isRequired,
};

const mapStateToProps = (state) => ({
    storeLoaded: isStoreLoaded(state),
    stores: getStores(state),
    selectedStore: getChosenStore(state),
    getLocationMembership: getSelectedLocMembershipPrice(state),
});

const mapDispatchToProps = (dispatch) => ({
    loadStoresFromContentful: bindActionCreators(
        loadStoresContentful,
        dispatch
    ),
    chooseStore: bindActionCreators(chooseStore, dispatch),
    setMembershipData: bindActionCreators(setFindMembershipDetails, dispatch),
});

export default withRouter(
    withStyles(styles)(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(withOktaAuth(BarflyMembershipEnrollment))
    )
);
