import React, { useEffect } from 'react';
import TagManager from 'app/Components/GoogleTagManager';
import MetaTags from 'react-meta-tags';

const MetaTagWrapper = (props) => {
    const { metadata } = props;
    useEffect(() => {
        if (metadata && metadata.pageTitle) {
            TagManager.dataLayer({
                dataLayer: {
                    page_type: metadata.pageTitle,
                },
            });
        }
    }, [metadata]);
    return (
        <MetaTags>
            <title>{metadata?.pageTitle || ''}</title>
            <meta name="description" content={metadata?.pageDescription || 'description'} />
            <meta name="keywords" content={metadata?.pageKeywords?.join()} />
            {metadata?.localisedHeaders?.map((tag) => <link rel={tag.props.rel} hreflang={tag.props.hreflang} href={tag.props.href}></link>)}
            <meta property="og:title" content="Drybar" />
            <meta property="og:image" content="path/to/image.jpg" />
        </MetaTags>
    );
};

export default MetaTagWrapper;
