import worldpayActionTypes from './Worldpay-ActionTypes';

export const toggleWorldpay = () => ({
    type: worldpayActionTypes.TOGGLE_WORLDPAY,
    payload: null
});

export const setCardAddedWorldpay = (data) => ({
    type: worldpayActionTypes.WORLDPAY_ISCARDADDED,
    payload: data
})