/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import { useQuery } from '@apollo/client';
import { screenBarfly as BarflyMembership, barflyPageQuery } from '../../gql/queries/barflyMembership';
import { doQuery } from '../../state/utils/contentful';
import useMoktaAuth from 'utils/oktaAuth';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import { object, array } from 'prop-types';
import markerIcon from '../../assets/images/inactiveMarker.svg';
import { DocumentToComponent } from '../../components';

import { bindActionCreators } from 'redux';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import getSignupFormFields from '../../app/Components/Auth/Partials/Registration/InstoreSignupFormFields';
import TextInputField from '../../app/Components/Auth/Partials/InstoreTextInputField';
import {
    Box,
    Grid,
    Button,
    Typography,
    Backdrop,
    CircularProgress,
} from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import {
    MEDIUM_MOBILE_BREAKPOINT,
    MOBILE_BREAKPOINT,
    TABLET_BREAKPPOINT,
} from '../../Helpers/breakpoints';
import {
    setBookerCustomerId,
    setRewardsCardNumber,
} from '../../state/ducks/Barfly/Barfly-Actions';
import {
    getChosenStore,
    getBarflyMembershipID,
    getBarflyMembershipPrice,
    getBarflyMembershipType,
    getBarflyBlowouts,
    getBarflyAdditionalOffers,
} from '../../state/ducks/Barfly/Barfly-Selectors';
import { Link, withRouter } from 'react-router-dom';
import { createPlaceMembershipOrder } from '../../api/booking-api';
import { getCardAddedStatus } from '../../state/ducks/Worldpay/Worldpay-Selectors';
import restClient from 'api/restClient';
import { getPageLabelFromUrl } from 'utils/helper';
import TagManager from 'app/Components/GoogleTagManager';
import config from 'app.config';
import classNames from 'classnames';
import Media from 'react-media';
import { EMAIL_REGEX, PHONE_REGEX, NAME_REGEX } from 'utils/validation/regex';
import { googleAnalyticsEvents } from 'state/utils/googleAnalyticsEvents';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { createUserLiteOkta } from 'api/auth-api';
import { Formik } from 'formik';
import { getWorldpayStatus } from '../../state/ducks/Worldpay/Worldpay-Selectors';
import {
    toggleWorldpay,
    setCardAddedWorldpay,
} from '../../state/ducks/Worldpay/Worldpay-Actions';
import appConfig from '../../app.config';

const containerStyle = {
    position: 'relative',
    width: '100%',
    height: '100%',
    borderTop: '1px solid #d1d1d1',
};

const mapStyle = [
    {
        featureType: 'all',
        elementType: 'labels.text.fill',
        stylers: [
            {
                saturation: 36,
            },
            {
                color: '#333333',
            },
            {
                lightness: 40,
            },
        ],
    },
    {
        featureType: 'all',
        elementType: 'labels.text.stroke',
        stylers: [
            {
                visibility: 'on',
            },
            {
                color: '#ffffff',
            },
            {
                lightness: 16,
            },
        ],
    },
    {
        featureType: 'all',
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'administrative',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#fefefe',
            },
            {
                lightness: 20,
            },
        ],
    },
    {
        featureType: 'administrative',
        elementType: 'geometry.stroke',
        stylers: [
            {
                color: '#fefefe',
            },
            {
                lightness: 17,
            },
            {
                weight: 1.2,
            },
        ],
    },
    {
        featureType: 'landscape',
        elementType: 'geometry',
        stylers: [
            {
                color: '#f5f5f5',
            },
            {
                lightness: 20,
            },
        ],
    },
    {
        featureType: 'landscape.natural.landcover',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#ffffff',
            },
        ],
    },
    {
        featureType: 'landscape.natural.terrain',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#ffffff',
            },
        ],
    },
    {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [
            {
                color: '#f5f5f5',
            },
            {
                lightness: 21,
            },
        ],
    },
    {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [
            {
                color: '#dedede',
            },
            {
                lightness: 21,
            },
        ],
    },
    {
        featureType: 'poi.park',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#b1f1bb',
            },
        ],
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#ffffff',
            },
            {
                lightness: 17,
            },
        ],
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry.stroke',
        stylers: [
            {
                color: '#ffffff',
            },
            {
                lightness: 29,
            },
            {
                weight: 0.2,
            },
        ],
    },
    {
        featureType: 'road.arterial',
        elementType: 'geometry',
        stylers: [
            {
                color: '#ffffff',
            },
            {
                lightness: 18,
            },
        ],
    },
    {
        featureType: 'road.local',
        elementType: 'geometry',
        stylers: [
            {
                color: '#ffffff',
            },
            {
                lightness: 16,
            },
        ],
    },
    {
        featureType: 'transit',
        elementType: 'geometry',
        stylers: [
            {
                color: '#f2f2f2',
            },
            {
                lightness: 19,
            },
        ],
    },
    {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [
            {
                color: '#e9e9e9',
            },
            {
                lightness: 17,
            },
        ],
    },
    {
        featureType: 'water',
        elementType: 'geometry.fill',
        stylers: [
            {
                visibility: 'on',
            },
            {
                color: '#ade8ff',
            },
        ],
    },
];
const mapStyles = {
    width: '100%',
    height: '100%',
};
const styles = (theme) => ({
    passwordIcon: {
        verticalAlign: 'middle',
        fontSize: '14px',
    },
    container: {
        maxWidth: '1367px',
        width: '100%',
        margin: 'auto',
        padding: '53px',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            padding: '19px',
        },
    },
    backdrop: {
        zIndex: 11,
        color: '#fff',
    },
    mainTitle: {
        textTransform: 'uppercase',
        fontFamily: 'DINCondensed-Regular',
        fontSize: '38px',
        color: '#42413D',
        fontWeight: '500',
        width: '100%',
        textAlign: 'center',

        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            fontSize: '31px',
        },
    },
    titleContainer: {
        display: 'flex',
    },
    displayFlex: {
        display: 'flex',
        alignItems: 'center',
        color: '#42413D',
    },
    subTitle: {
        textAlign: 'center',
        fontSize: '18px',
        marginBottom: '25px',
        fontWeight: '400',
        textTransform: 'none',
        fontFamily: 'URWForm',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            fontSize: '15px',
        },
    },
    submitButton: {
        maxWidth: '378px',
        width: '100%',
        height: '63px',
        fontSize: '18px',
        margin: '0px 0px 40px 450px',
        [theme.breakpoints.down(TABLET_BREAKPPOINT)]: {
            margin: '0px 25% 40px',
        },
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            margin: '0px 0px 36px 4px',
        },
    },
    summaryContainer: {
        borderRadius: '0px',
        backgroundColor: '#FFFFFF',
        padding: '28px 35px',
        height: '100%',
        [theme.breakpoints.down(TABLET_BREAKPPOINT)]: {
            padding: '20px 15px',
        },
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            backgroundColor: '#F9F9F9',
        },
        [theme.breakpoints.down(MEDIUM_MOBILE_BREAKPOINT)]: {
            padding: 0,
        },
    },
    summaryTitle: {
        fontFamily: 'MrsEavesSmallCaps',
        fontSize: '16px',
        lineHeight: '45px',
        textTransform: 'uppercase',
        color: '#42413D',
        margin: '28px 0px 0px 36px',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            fontSize: '15px',
            whiteSpace: 'nowrap',
            margin: '0px',
        },
    },
    membershipCharge: {
        alignItems: 'flex-start',
        margin: '16px 0',
    },
    theFinePrint: {
        width: 'auto',
        borderRadius: '0px',
        backgroundColor: '#FFFFFF',
        marginTop: '5px',
        marginBottom: '40px',
        padding: '14px 35px',
        [theme.breakpoints.down(TABLET_BREAKPPOINT)]: {
            padding: '20px 15px',
            width: '100%',
        },
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            width: '100%',
        },
    },
    finePrintCopy: {
        fontFamily: 'AvenirNext',
        fontSize: '17px',
        lineHeight: '32px',
        color: '#42413D',
        padding: '25px 25px 0px 25px',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            fontSize: '15px',
            lineHeight: '22px',
        },
    },
    finePrintHeading: {
        fontFamily: 'MrsEavesSmallCaps',
        fontSize: '20px',
        lineHeight: '45px',
        color: '#42413D',
        marginTop: '24px !important',
        padding: '0px 15px !important',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            fontSize: '15px',
            lineHeight: '22px',
            marginLeft: '2px',
        },
    },
    slashVector: {
        margin: '70px 20px 0 25px',
        content: '""',
        height: '45px',
        transform: 'rotate(20deg)',
        borderRight: '2px solid #42413D',
        // position: 'absolute',
        '&::after': {},
    },
    serviceChargeCotainer: {
        display: 'flex',
        justifyContent: 'center',
        flex: '1',
        borderRadius: '0px',
        backgroundColor: '#F7F8F9',
        borderTop: '1px solid #D1D1D1',
        alignItems: 'left',

        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            backgroundColor: '#FFFFFF',
            borderRadius: '0px',
        },
    },
    servicePriceCopy: {
        border: '1px solid #FFFFFF',
        background: '#FFFFFF',
        boxShadow: '2px 2px 46px rgba(235, 235, 235, 0.5)',
        borderRadius: '50%',
        fontSize: '32px',
        lineHeight: '34px',
        fontWeight: '600',
        color: '#42413D',
        fontFamily: 'URWForm-Bold',
        display: 'flex',
        alignItems: 'center',
        width: '130px',
        height: '130px',
        letterSpacing: '-2px',
        margin: '20px 0 0 70px',
        justifyContent: 'center',

        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            background: '#F7F8F9',
            width: '76px',
            height: '76px',
            borderWidth: '0px',
            padding: '12px',
        },
    },
    serviceChargeData: {
        display: 'flex',
        position: 'relative',
        margin: '10px 0px',
        padding: '10px',
        alignItems: 'center',
        [theme.breakpoints.down(TABLET_BREAKPPOINT)]: {
            margin: '8px 0px 0px 5px',
            flexWrap: 'wrap',
        },
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            margin: '11px 0px 0px 5px',
            flexWrap: 'wrap',
        },
    },
    taxApplicable: {
        fontFamily: 'AvenirNext',
        fontSize: '18px',
        lineHeight: '34px',
        color: '#767676',
        margin: '0 0 0 11px',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            margin: '11px 0px 11px 5px',
        },
    },
    monthCopy: {
        fontSize: '25px',
        lineHeight: '34px',
        fontWeight: '600',
        color: '#42413D',
        fontFamily: 'AvenirNext',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            fontSize: '18px',
        },
    },
    theBarflySingle: {
        // width: '125px',
        float: 'left',
        height: '22px',
        margin: '0 100px 10px 0px',
        fontFamily: 'URWForm',
        fontSize: '20px',
        fontWeight: '600',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#54575a',
        display: 'inline-block',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            fontSize: '18px',
            margin: '0 25px 10px 0px',
        },
        [theme.breakpoints.down(TABLET_BREAKPPOINT)]: {
            fontSize: '20px',
            margin: '0 20px 10px 0px',
        },
    },
    thePrice: {
        // width: '125px',
        float: 'right',
        height: '22px',
        margin: '0 0 10px',
        fontFamily: 'URWForm',
        fontSize: '20px',
        fontWeight: '600',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#54575a',
        display: 'inline-block',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            fontSize: '18px',
        },
        [theme.breakpoints.down(TABLET_BREAKPPOINT)]: {
            fontSize: '20px',
        },
    },
    blowDryPerMonth: {
        // width: '121px',
        height: '16px',
        margin: '20px 4px 0 0',
        fontFamily: 'URWForm',
        fontSize: '16px',
        fontEeight: '500',
        fontstretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: theme.palette.common.lightGrey[1],
        display: 'list-item',
    },
    month: {
        width: '126px',
        height: '33px',
        margin: '100px 0 7px 50px',
        fontFamily: 'URWForm',
        fontSize: '24px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#54575a',
    },
    line: {
        height: '38px',
        margin: '6px 60px 4px 111px',
        transform: 'rotate(-209.22deg)',
        border: 'solid 1px #53575a',
    },
    drybarLocation: {
        fontFamily: 'URWForm',
        fontSize: '20px',
        lineHeight: '34px',
        // color: '#42413D',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            fontSize: '18px',
            lineHeight: '24px',
        },
    },
    locationDetails: {
        borderRadius: '0px',
        backgroundColor: '#F7F8F9',
        borderTop: '1px solid #D1D1D1',
        margin: '0 0 15px 0',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '15px 20px 20px 15px',

        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            backgroundColor: '#FFFFFF',
            borderRadius: '0px',
        },
    },
    confirmedMap: {
        width: '195px',
        height: 'auto',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            width: '134px',
        },
    },
    bookYourAppointmentToday: {
        maxWidth: '378px',
        fontSize: '18px',
        margin: '20px auto 40px',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            maxWidth: '318px',
            height: '55px',
            fontSize: '15px',
            margin: '10px auto 30px',
        },
    },
    nameAndPrice: {},
    allTheBenefits: {
        padding: '2px',
        margin: '5% 0 5% 6%',
    },
    headingSpace: {
        marginBottom: '12%',
    },
    thankYouDesc: {
        borderTop: '1px solid #D1D1D1',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            width: '90%',
            margin: '0px 13px 0px 22px',
        },
    },
    thankYouHeading: {
        fontFamily: 'DINCondensed',
        fontWeight: '700',
        fontSize: '34px',
        lineHeight: '41px',
        textTransform: 'uppercase',
        color: '#42413D',
        width: '100%',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            width: '251px',
            fontSize: '30px',
            lineHeight: '34px',
        },
    },
    dialogContainer: {
        minWidth: 626,
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            minWidth: 0,
        },
    },
    copyDetails: {
        fontFamily: 'AvenirNext',
        fontSize: '16px',
        lineHeight: '32px',
        color: '#42413D',
        marginTop: '10px',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            fontSize: '15px',
            marginTop: '2px',
            lineHeight: '22px',
            marginLeft: '-23px',
        },
    },
    closeIcon: {
        margin: '20px 0px 0px 30%',
        cursor: 'pointer',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            margin: '34px 0px 0px 1%',
        },
    },
    backPageCopy: {
        fontSize: '16px',
        fontWeight: '600',
        paddingTop: '2px',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            display: 'none',
        },
    },
    modalContainer: {
        background: 'rgba(255, 255, 255, 0.94)',
    },
    mapInfo: {
        height: '260px',
    },
    errorToastContainer: {
        width: '600px',
        top: '80px',
    },
    errorContainer: {
        background: 'rgba(189, 0, 0, 0.7)',
        borderRadius: 12,
        minHeight: 51,
    },
    errorBody: {
        fontSize: '16px',
        lineHeight: '25px',
        color: '#fff',
        textAlign: 'center',
    },
    fieldContainerTitle: {
        height: '13px',
        margin: '39px 884px 10px 0',
        fontFamily: 'MillerBanner-Roman',
        fontSize: '16px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#000',
        display: 'inline-block',
    },
    textField: {
        width: '100%',
        margin: '2px 2px',
        '& input': {
            padding: '5px 0',
            fontSize: '20px',
        },
        '& label': {
            fontSize: '18px',
            marginBottom: '1px !important',
        },
        '& select': {
            padding: '0px 0',
            fontSize: '20px',
            marginTop: '0px',
        },
        '& option': {
            padding: '0px',
        },
        '& dropDownContainer': {
            marginTop: '0px',
        },
        '& input::placeholder': {
            fontSize: '16px',
        },
        [theme.breakpoints.down(TABLET_BREAKPPOINT)]: {
            margin: '1px 0px',
        },
    },
    singleLineTextFieldsContainer: {
        display: 'flex',
        padding: '0px 15px 5px 15px',
        marginTop: '15px',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            flexWrap: 'wrap',
            padding: '0px',
        },
        '& label': {
            marginBottom: '15px',
            width: '350px',
        },
        '& input': {
            padding: '5px 0',
            fontSize: '20px',
        },
        '& input::placeholder': {
            fontSize: '16px',
        },
    },
    fieldContainer: {
        backgroundColor: '#fff',
        padding: '0px 10px 1px 10px',
        margin: '2px 0 2px',
    },
    pricesVary: {
        fontFamily: 'URWForm',
        fontSize: '14px',
        color: '#54575a',
        margin: '20px 0px 20px 0px',
    },
    signingUp: {
        fontFamily: 'URWForm',
        fontSize: '14px',
        color: '#54575a',
        textAlign: 'center',
        marginTop: '20px',
    },
    line: {
        width: '394px',
        height: '1px',
        margin: '13px 2px 20px 0',
        border: 'solid 1px #979797',
    },

    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    requestNoteCopy: {
        fontSize: '18px',
        textAlign: 'center',
        color: theme.palette.common.grey,
        fontWeight: 800,
        paddingBottom: '15px',
        borderBottom: `1px solid ${theme.palette.common.lightGrey}`,
        [theme.breakpoints.down('sm')]: { display: 'none' },
    },
    borderBottom: {
        borderBottom: `1px solid ${theme.palette.common.lightGrey}`,
        width: '88%',
        margin: '22px 5px 2px 41px',
    },
    cardSelectionCopy: {
        textAlign: 'center',
        margin: '30px 0px 0px 0px',
        fontFamily: theme.typography.fontFamily,
        [theme.breakpoints.down('sm')]: {
            whiteSpace: 'normal',
            margin: '0 0px 30px 0',
            fontSize: '15px',
        },
        fontSize: '17px',
        color: '#42413D',
    },
    masterCard: {
        width: '223px',
        height: '163.07px',
        margin: '0% 2% 8% 2%',
        cursor: 'pointer',
    },
    selectedMasterCard: {
        width: '223px',
        height: '163.07px',
        margin: '0% 2% 8% 2%',
        cursor: 'pointer',
        border: '1px solid #767676',
        borderRadius: '22px',
    },
    MasterCardSecond: {
        width: '258px',
        height: '224.07px',
        margin: '11% 0% 0% 0%',
    },
    cardEnding: {
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.common.lightGrey[1],
        fontSize: '14px',
        cursor: 'pointer',
    },
    cardDetails: {
        margin: '-56% 0% 0% 29%',
    },
    AddCreditDetails: {
        margin: '-55% 0% 0% 12%',
        [theme.breakpoints.down('sm')]: {
            margin: '-55% 0% 0% 12%',
        },
    },
    proceedSelectedAction: {
        margin: '50px auto 25px',
        width: '50%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            textAlign: 'center',
        },
    },
    proceedAddCard: {
        margin: '50px 0px 25px',
        width: '100%',
        padding: 0,
        [theme.breakpoints.down('sm')]: {
            margin: '0 0 25px',
            width: '100%',
            padding: '20px',
        },
    },
    proceedSelectClick: {
        left: '0.05%',
        right: '55.09%',
        bottom: '66.36%',
        fontFamily: theme.typography.fontFamily,
        fontSize: '18px',
        lineHeight: '45px',
        width: '100%',
        textTransform: 'none',
        color: '#54575A',
        backgroundColor: '#FFDD30',
        '&:hover': {
            backgroundColor: '#b29a21',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '63px',
        },
    },
    test: {
        textAlign: 'center',
    },
    addCardBtn: {
        fontFamily: theme.typography.fontFamily,
        fontSize: '18px',
        lineHeight: '45px',
        width: '100%',
        maxWidth: '324px',
        textTransform: 'none',
        color: '#54575A',
        backgroundColor: '#FFDD30',
        margin: '0 auto',
        '&:hover': {
            backgroundColor: '#b29a21',
        },
        [theme.breakpoints.down('sm')]: {
            height: '63px',
        },
    },
    leftDivider: {
        width: '42%',
        margin: '2px 2px 2px 15px',
    },
    rightDivider: {
        width: '42%',
    },
    addCardAction: {
        margin: '50px auto 25px',
        width: '50%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            textAlign: 'center',
        },
    },
    borderBottomBetween: {
        borderBottom: `1px solid ${theme.palette.common.lightGrey[0]}`,
        width: '38%',
        margin: '0px 5px 7px 41px',
    },
    displayFlex: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: { display: 'none' },
    },
    borderBottomCopy: {
        margin: '7px 0px 0px 25px',
    },
    leftGridContainer: {
        backgroundColor: theme.palette.common.white,
        margin: 'auto',
        padding: '26px 22px',
        [theme.breakpoints.down('sm')]: {
            padding: '20px 20px',
            margin: '20px 0',
        },
    },
    leftGridContainer1: {
        backgroundColor: theme.palette.common.white,
        margin: 'auto',
        padding: '26px 22px',
        width: '754px',
        [theme.breakpoints.down('sm')]: {
            padding: '0px',
            width: '100%',
        },
    },
    addCreditCard: {
        left: '0.05%',
        right: '55.09%',
        bottom: '66.36%',
        fontFamily: theme.typography.fontFamily,
        fontSize: '18px',
        lineHeight: '45px',
        width: '100%',
        textTransform: 'none',
        color: '#54575A',
        border: '1px solid #54575A',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '63px',
            margin: '0px 0px 0px 0px',
        },
    },
    cardSelectionContainer: {
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'nowrap',
            overflowY: 'auto',
        },
    },
    textAlignEndTitle: {
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
        },
    },
    cardInputWrap: {
        marginTop: '40px',
    },
    cardNumber: {
        width: '100%',
    },
    cardNumberCopy: {
        fontFamily: 'AvenirNext',
        fontSize: '15px',
        lineHeight: '18px',
        color: '#989898',
    },
    inputExpirationMonth: {
        width: '95%',
    },
    inputCVV: {
        width: '100%',
    },
    savedCardText: {
        margin: '30px 0px 0px 21px !important',
        color: '#767676',
        [theme.breakpoints.down('sm')]: { whiteSpace: 'nowrap' },
    },
    defaultCopy: {
        margin: '50px 0px 0px !important',
        fontWeight: '600',
        textAlign: 'center',
    },
    borderOnePx: {
        border: '1px solid #D1D1D1',
        margin: '23px 0px 32px 0px',
        [theme.breakpoints.down('sm')]: {
            border: 'none',
            margin: 0,
        },
    },
    borderOnePxMobile: {
        [theme.breakpoints.down('sm')]: {
            border: '1px solid #D1D1D1',
            margin: '23px 0px 32px 0px',
        },
    },
    closeBtn: {
        position: 'absolute',
        right: 'calc(50% - 377px)',
        top: '40px',
        fontSize: 30,
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            right: 'calc(50% - 277px)',
            top: '40px',
        },
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            right: 'calc(50% - 150px)',
            top: '42px',
            fontSize: 25,
        },
    },
    cardImageWrap: {
        minWidth: '280px',
        height: '200px',
        padding: '0 !important',
        [theme.breakpoints.down('sm')]: {},
    },
    makeDefaultWrap: {
        display: 'flex',
        margin: '50px 0',
        justifyContent: 'center',
        width: '100%',
    },
    container: {
        maxWidth: '1367px',
        width: '100%',
        margin: 'auto',
        padding: '34px',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            padding: '19px',
        },
    },
    fieldContainer: {
        backgroundColor: '#fff',
        padding: '5px 15px 5px 15px',
        margin: '0px 0 5px',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            padding: '5px 0px 5px 0px',
        },
    },

    leftGridContainer: {
        backgroundColor: theme.palette.common.white,
        margin: 'auto',
        padding: '26px 22px',
        [theme.breakpoints.down('sm')]: {
            padding: '20px 20px',
            margin: '20px 0',
        },
    },
    borderBottom: {
        borderBottom: `1px solid ${theme.palette.common.lightGrey}`,
        width: '88%',
        margin: '22px 5px 2px 41px',
    },
    borderBottomBetween: {
        borderBottom: `1px solid ${theme.palette.common.lightGrey[0]}`,
        width: '42%',
        margin: '0px auto 7px auto',
    },
    borderBottomCopy: {
        margin: '7px auto 0px auto',
    },
    borderOnePx: {
        border: '1px solid #D1D1D1',
        margin: '23px 0px 32px 0px',
        [theme.breakpoints.down('sm')]: {
            border: 'none',
            margin: 0,
        },
    },
    tooltip: {
        display: 'inline-block',
        marginLeft: '5px',
    },
    fieldContainerTitle: {
        height: '13px',
        margin: '39px 884px 0 0',
        fontFamily: 'MillerBanner-Roman',
        fontSize: '16px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#000',
        display: 'inline-block',
    },
    titleContainer: {
        display: 'flex',
    },
    displayFlex: {
        display: 'flex',
        alignItems: 'center',
        color: '#42413D',
    },
    submitButton: {
        maxWidth: '378px',
        width: '100%',
        height: '63px',
        fontSize: '18px',
        margin: 'auto',
    },
    addressOneWidth: {
        width: '70%',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            width: '100%',
        },
    },
    addressTwoWidth: {
        width: '30%',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            width: '100%',
        },
    },
    updateInfo: {
        width: 'fit-Content',
        height: '16px',
        margin: '5px 0px 39px 0px',
        fontFamily: 'AvenirNext',
        fontSize: '13px',
        fontWeight: '500',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#54575a',
        padding: '0px 0px 0px 0px',
    },
    cardSelectionContainer: {
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'nowrap',
            overflowY: 'auto',
        },
    },
    addCreditCard: {
        left: '0.05%',
        right: '55.09%',
        bottom: '66.36%',
        fontFamily: theme.typography.fontFamily,
        fontSize: '18px',
        lineHeight: '45px',
        width: '100%',
        textTransform: 'none',
        color: '#54575A',
        border: '1px solid #54575A',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '63px',
            margin: '0px 0px 0px 0px',
        },
    },
    addCardAction: {
        margin: '50px auto 25px',
        width: '50%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            textAlign: 'center',
        },
    },
    addCardBtn: {
        fontFamily: theme.typography.fontFamily,
        fontSize: '18px',
        lineHeight: '45px',
        width: '100%',
        maxWidth: '324px',
        textTransform: 'none',
        color: '#54575A',
        backgroundColor: '#FFDD30',
        margin: '0 auto',
        '&:hover': {
            backgroundColor: '#b29a21',
        },
        [theme.breakpoints.down('sm')]: {
            height: '63px',
        },
    },
    cardColumn: {
        position: 'relative',
        maxWidth: '223px',
        height: '163.07px',
        margin: '0% 2% 8% 2%',
        width: '33%',
    },
    masterCard: {
        width: '223px',
        height: '163.07px',
        margin: '0% 2% 8% 2%',
        cursor: 'pointer',
    },
    cardDetails: {
        margin: '-140px 0% 0% 70px',
    },
    cardEnding: {
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.common.lightGrey[1],
        fontSize: '14px',
        cursor: 'pointer',
    },
    cardDigit: {
        fontFamily: theme.typography.fontFamily,
        margin: '9px 0px 0px 0px',
        cursor: 'pointer',
    },
    defaultCopy: {
        margin: '75px 0px 0px !important',
        fontWeight: '600',
        textAlign: 'center',
        width: '223px',
    },
    selectedMasterCard: {
        width: '223px',
        height: '163.07px',
        margin: '0% 2% 8% 2%',
        cursor: 'pointer',
        border: '1px solid #767676',
        borderRadius: '22px',
    },
    savedCardText: {
        margin: '30px 0px 0px 21px !important',
        color: '#767676',
        [theme.breakpoints.down('sm')]: { whiteSpace: 'nowrap' },
    },
    proceedSelectedAction: {
        margin: '50px auto 25px',
        width: '50%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            textAlign: 'center',
        },
    },
    proceedAddCard: {
        margin: '50px 0px 25px',
        width: '100%',
        padding: 0,
        [theme.breakpoints.down('sm')]: {
            margin: '0 0 25px',
            width: '100%',
            padding: '20px',
        },
    },
    proceedSelectClick: {
        left: '0.05%',
        right: '55.09%',
        bottom: '66.36%',
        fontFamily: theme.typography.fontFamily,
        fontSize: '18px',
        lineHeight: '45px',
        width: '100%',
        textTransform: 'none',
        color: '#54575A',
        backgroundColor: '#FFDD30',
        '&:hover': {
            backgroundColor: '#b29a21',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '63px',
        },
    },
    icon: {
        fontSize: '20px',
        color: theme.palette.common.grey,
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            margin: '5px 18px 0px 15px',
        },
    },
    generalErrorMsg: {
        color: '#EE0000',
        fontSize: '0.75rem',
        fontWeight: 500,
        lineHeight: 1.66,
        marginLeft: '15px',
    },
    cardNumber: {
        width: '141px',
        height: '16px',
        margin: '80px 84px 6px 24px',
        fontFamily: 'AvenirNext',
        fontSize: '12px',
        fontWeight: '500',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#9d9d9d',
    },
    XXXXXXXXXXXX2222: {
        width: '185px',
        height: '22px',
        margin: '6px 40px 15px 24px',
        fontFamily: 'AvenirNext',
        fontSize: '16px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#a6a6a6',
    },
    expiryDate: {
        width: '74px',
        height: '16px',
        margin: '15px 151px 6px 24px',
        fontFamily: 'AvenirNext',
        fontSize: '12px',
        fontWeight: '500',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#9d9d9d',
    },
    span: {
        width: '97px',
        height: '22px',
        margin: '6px 128px 0 24px',
        fontFamily: 'AvenirNext',
        fontSize: '16px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#a6a6a6',
    },
    checkout: {
        margin: '0px',
        padding: '0px',
    },
});

//-------------------------------------------------
//  CONSTANTS FOR USER/LITE

const InstoreBarflyEnrollmentPage = ({
    barflyMembershipCollection,
    barflyConfirmData,
    classes,
    location, // Check Why needed
    history,
    preferredShop,
    selectedMembershipPlanID,
    toggleWorldpay,
    membershipType,
    blowouts,
    additionalOffers,
    membershipPrice,
    google,
    setCardAddedWorldpay,
    bookerCustomerId,
    isCardAdded,
    isWorldpayOpen,
}) => {
    const [isAuthenticated, getUser, getUserId] = useMoktaAuth();
    const [rewardsError, setShowRewardsError] = useState(false);
    const price = membershipPrice || 0;
    const [user, setUser] = useState(null);
    const [signupFormState, setSignUpFormState] = useState({});
    const [signupFormMetadata, setSignupFormMetadata] = useState([]);
    const [hasFormLoaded, setHasFormLoaded] = useState(false);
    const [touched, setTouched] = useState({});
    const [validateField, setValidateField] = useState({});
    const [errors, setErrors] = useState({});
    const [successes, setSuccesses] = useState({});
    const [generalErrorMsg, setGeneralErrorMsg] = useState('');
    const [rewardsCardNumber, setRewardsCardNumber] = useState('');
    const [customerId, setCustomerId] = useState('');
    const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
    const [enteredEmail, setEnteredEmail] = useState('');
    const [isSubscribe, setIsSubscribe] = useState(false);
    const [displayPlaceholder, setDisplayPlaceholder] = useState({});
    const [displayTips, setDisplayTips] = useState(false);
    const [cardAdded, setCardAdded] = useState(false);
    const [showLoading, setLoading] = useState(false);
    const BARFLY_MEMBERSHIP = BarflyMembership();
    const [finePrintCollection, setFinePrintCollection] = useState({});
    // const { data, error, loading } = useQuery(BARFLY_MEMBERSHIP);
    // const finePrintCollection = data?.barfly?.finePrint?.json || {};

    useEffect(async () => {
        const data = await doQuery(barflyPageQuery(), true);
        const pageData = data.drybarPageCollection?.items[0];
        setFinePrintCollection(
            pageData?.formatofPageCollection?.items?.[3]?.finePrint || {}
        );
    }, []);

    const renderLabelClassnames = (errors, fieldName) => {
        return classNames(
            'label',
            hasFieldErrors(errors, fieldName) ? 'field-error' : ''
        );
    };

    const onBack = () => {
        mParticle.logEvent(
            'Navigation - Go Back',
            mParticle.EventType.Navigation,
            {
                'Source Page': getPageLabelFromUrl(location.pathname),
            }
        );
    };

    useEffect(async () => {
        // get the form fields schema
        const signupFormMetadata = await getSignupFormFields();
        setSignupFormMetadata(signupFormMetadata);
        // set the initial form state + touched values + error
        setGeneralErrorMsg('');
        setSignUpFormState(initFormState(signupFormMetadata));
        setTouched(initTouchedOrValidate(signupFormMetadata));
        setValidateField(initTouchedOrValidate(signupFormMetadata));
        setSuccesses(initSuccesses(signupFormMetadata));
        setErrors(initErrors(signupFormMetadata));
        setDisplayPlaceholder(initTouchedOrValidate(signupFormMetadata));
        //
        window.scrollTo(0, 0);
        // indicate the form has finished its initial load
        setHasFormLoaded(true);

        if (isCardAdded) {
            setCardAddedWorldpay(true);
        }
    }, []);

    useEffect(() => {
        if (isCardAdded) {
            setCardAddedWorldpay(true);
            if (isWorldpayOpen) {
                setTimeout(toggleWorldpay(), 2000);
            }

            setLoading(true);
            createPlaceMembershipOrderRequest();
        }
    }, [isCardAdded]);

    useEffect(() => {
        if (!isWorldpayOpen && !isCardAdded) {
            setLoading(false);
        } else if (isWorldpayOpen) {
            setLoading(true);
        }
    }, [isWorldpayOpen]);

    const reset = (signupFormMetadata) => {
        setSignUpFormState(initFormState(signupFormMetadata));
        setTouched(initTouchedOrValidate(signupFormMetadata));
        setValidateField(initTouchedOrValidate(signupFormMetadata));
        setSuccesses(initSuccesses(signupFormMetadata));
        setErrors(initErrors(signupFormMetadata));
        setEnteredEmail('');
        setGeneralErrorMsg('');
    };

    const rewardsPrefixLowerBound = parseInt(
        appConfig.barfly.rewardsCardPrefixRange[0]
    );
    const rewardsPrefixHigherBound = parseInt(
        appConfig.barfly.rewardsCardPrefixRange[1]
    );

    const initFormState = (signupFormMetadata) => {
        let state = {};
        for (const field of signupFormMetadata) {
            if (field.select?.options?.length > 0) {
                const updatedFields = { ...state };
                updatedFields[field.select.name] =
                    field.select.default || field.select.options[0];
                state = updatedFields;
            }
        }
        return state;
    };

    const initTouchedOrValidate = (signupFormMetadata) => {
        const touched = {};
        for (const field of signupFormMetadata) {
            touched[field.name] = false;
        }
        return touched;
    };

    const initSuccesses = (signupFormMetadata) => {
        const successes = {};
        for (const field of signupFormMetadata) {
            successes[field.name] = [];
        }
        return successes;
    };

    const initErrors = (signupFormMetadata) => {
        const errors = {};
        for (const field of signupFormMetadata) {
            errors[field.name] = [];
        }
        return errors;
    };

    const hasFormErrors = (errors) => {
        let hasErrors = false;
        for (const field in errors) {
            if (errors[field].length > 0) {
                hasErrors = true;
            }
        }
        return hasErrors;
    };

    const getValidateAllFields = (signupFormMetadata) => {
        const validateField = {};
        for (const field of signupFormMetadata) {
            validateField[field.name] = true;
        }
        return validateField;
    };

    const showPlaceholder = (field) => {
        const currentPlaceholders = {
            ...initTouchedOrValidate(signupFormMetadata),
        };
        currentPlaceholders[field.name] = true;
        setDisplayPlaceholder(currentPlaceholders);
    };

    const hidePlaceholder = (e) => {
        setDisplayPlaceholder(initTouchedOrValidate(signupFormMetadata));
    };

    const hasFieldErrors = (fieldName, errors) =>
        errors[fieldName] && errors[fieldName].length > 0;

    const hasFieldSuccesses = (fieldName, successes) =>
        successes[fieldName] && successes[fieldName].length > 0;

    const handleValidation = (validateField, signupFormState) => {
        // reset the form errors and successes
        const newErrors = { ...initErrors(signupFormMetadata) };
        const newSuccesses = { ...initSuccesses(signupFormMetadata) };

        signupFormMetadata.forEach((field) => {
            if (validateField[field.name] && field.required) {
                if (
                    !(
                        signupFormState[field.name] &&
                        signupFormState[field.name]?.length
                    )
                ) {
                    newErrors[field.name].push(`${field.label} is required`);
                }
            }
        });

        // validate the first name
        if (validateField['firstName'] && signupFormState.firstName) {
            if (signupFormState.firstName.length < 2) {
                newErrors.firstName.push('First name is too short');
            } else if (signupFormState.firstName.length > 64) {
                newErrors.firstName.push('First name is too long)');
            } else if (!signupFormState.firstName.match(NAME_REGEX)) {
                newErrors.firstName.push(
                    'First name contains invalid characters'
                );
            }
        }

        // validate the last name
        if (validateField['lastName'] && signupFormState.lastName) {
            if (signupFormState.lastName.length < 2) {
                newErrors.lastName.push('Last name is too short');
            } else if (signupFormState.lastName.length > 50) {
                newErrors.lastName.push('Last name is too long');
            } else if (!signupFormState.lastName.match(NAME_REGEX)) {
                newErrors.lastName.push(
                    'Last name contains invalid characters'
                );
            }
        }

        // validate the primary phone
        if (
            validateField['primaryPhone'] &&
            signupFormState.primaryPhone &&
            !signupFormState.primaryPhone.match(PHONE_REGEX)
        ) {
            newErrors.primaryPhone.push('Invalid phone number');
        }

        // validate the email
        if (
            validateField['email'] &&
            (signupFormState.email?.length < 6 ||
                (signupFormState.email &&
                    !signupFormState.email.match(EMAIL_REGEX)))
        ) {
            newErrors.email.push('Invalid email format');
        }

        // validate the confirm Email
        if (
            validateField['emailConfirm'] &&
            (signupFormState.emailConfirm?.length < 6 ||
                (signupFormState.emailConfirm &&
                    !signupFormState.emailConfirm.match(EMAIL_REGEX)))
        ) {
            newErrors.emailConfirm.push('Invalid email format');
        } else if (
            signupFormState.emailConfirm?.length > 0 &&
            signupFormState.email?.length > 0 &&
            signupFormState.emailConfirm != signupFormState.email
        ) {
            newErrors.emailConfirm.push('Emails do not match');
        }

        // validate the rewards card number
        if (
            validateField['rewardsCard'] &&
            signupFormState.rewardsCard &&
            signupFormState.rewardsCard.length !== 0 &&
            (signupFormState.rewardsCard?.length !== 16 ||
                isNaN(signupFormState.rewardsCard) ||
                !(
                    rewardsPrefixLowerBound <=
                        parseInt(signupFormState.rewardsCard.slice(0, 8)) &&
                    parseInt(signupFormState.rewardsCard.slice(0, 8)) <=
                        rewardsPrefixHigherBound
                ))
        ) {
            newErrors.rewardsCard.push(
                'Enter your 16 digit Harrods Rewards card number'
            );
        }

        setRewardsCardNumber(signupFormState.rewardsCard);

        // return both errors and success messages
        return { newErrors, newSuccesses };
    };

    const _mapLoaded = (mapProps, map) => {
        map.setOptions({
            styles: mapStyle,
        });
    };

    // map center pos
    const initialCenter = {
        lat: preferredShop?.contact?.coordinates?.[0],
        lng: preferredShop?.contact?.coordinates?.[1],
    };

    //-------------------------------
    // CONSTANTS FROM ORIGINAL BARFLYENROLLMENTPAGE

    //--------------------------------
    const isInStoreRoute = () => {
        return /instore/i.test(window.location.href);
    };

    const goToPayment = async (e) => {
        e.preventDefault();
        setLoading(true);

        const {
            firstName,
            lastName,
            email,
            emailConfirm,
            phoneCountryCode,
            primaryPhone,
            rewardsCard,
        } = signupFormState;
        const newValidateField = getValidateAllFields(signupFormMetadata);
        setValidateField(newValidateField);

        /*         validate the current form state*/
        const newValidationState = handleValidation(
            newValidateField,
            signupFormState
        );

        /*      if its valid then submit the signup and then register for subscription*/
        if (!hasFormErrors(newValidationState.newErrors)) {
            mParticle.logEvent('Sign Up Attempted', mParticle.EventType.Other, {
                'Source Page': getPageLabelFromUrl(location.pathname),
            });
            setGeneralErrorMsg('');
            toggleWorldpay();
            //            setLoading(false);
            restClient
                .post(
                    createUserLiteOkta({
                        firstName,
                        lastName,
                        email,
                        phoneNumber: primaryPhone,
                        phoneCountryCode,
                    })
                )
                .then(async (res) => {
                    if (res.status === 200) {
                        bookerCustomerId(res.data.uid);
                        setCustomerId(res.data.uid);
                        //setLoading(false);
                        //toggleWorldpay();
                        gtag(
                            googleAnalyticsEvents.EVENT,
                            googleAnalyticsEvents.BARFLY_SIGN_UP,
                            {
                                method: 'web',
                            }
                        );

                        const {
                            gtm: {
                                formCompleteEvent,
                                subscription: { type, option },
                            },
                        } = config;
                        const tagManagerArgs = {
                            dataLayer: {
                                event: formCompleteEvent,
                                form_type: 'account_signup',
                                /*                                newsletter_opt_in: isSubscribe, // remove if not applicable*/
                                // subscription_option: 'subscription_optionif opted-in.' // remove if n/a
                            },
                        };
                        TagManager.dataLayer(tagManagerArgs);

                        //reset(signupFormMetadata);
                        setIsSuccessDialogOpen(true);
                        setEnteredEmail(email);
                    } else {
                        setGeneralErrorMsg('An unknown error has occurred');
                    }
                })
                .catch((errorResponse) => {
                    const lowerCaseFirstLetter = (str) =>
                        str.charAt(0).toLowerCase() + str.slice(1);

                    if (errorResponse.status === 400) {
                        const data = errorResponse.response.data;
                        // Validation error condition
                        if (data.errors) {
                            const newResponseErrors = {
                                ...newValidationState.newErrors,
                            };
                            // handle validation error response
                            for (const key in data.errors) {
                                const keyParts = key.split('.');
                                const fieldName = lowerCaseFirstLetter(
                                    keyParts[1]
                                );
                                newResponseErrors[fieldName] = data.errors[
                                    key
                                ].slice(0, 1);
                            }
                            setErrors(newResponseErrors);
                        }
                        // Generic error condition
                        else {
                            setGeneralErrorMsg(
                                'Something went wrong, please try to sign-up again?'
                            );
                        }
                    }
                    // Generic error condition
                    else {
                        setGeneralErrorMsg(
                            'Something went wrong,  please try to sign-up again?'
                        );
                    }
                    setLoading(false);
                    setEnteredEmail(email);
                });
        } else {
            setLoading(false);

            // Set the form errors
            setErrors(newValidationState.newErrors);
            window.scroll(0, 20);
        }

        //setProcessing(true);

        //mParticle.logEvent('Account - Confirm Barfly Membership Upgrade', mParticle.EventType.Other, {
        //    'Source Page': getPageLabelFromUrl(location.pathname),
        //    'Page Name': getPageLabelFromUrl(location.pathname),
        //    'Page Category': 'Barfly | Membership Confirmation',
        //    'Membership Type': membershipType,
        //    'Membership Location': preferredShop.bookerLocationId,
        //});

        //if (error) {
        //    console.log(error);
        //}

        //if (data && data.IsSuccess) {
        //    const orderId = data.Order?.ID || '';
        //    const locationId = preferredShop.bookerLocationId;

        //    let today = new Date();
        //    today = `${formatDate(today)}T00:00:00+00:00`;

        //    ////worldpay payment call
        //    //if (config.barfly.worldpayPayment === "true" || config.drybarEnv === 'production') {
        //    //    const { dataPayment, errorPayment } = await useFetchAsync(paymentMembershipInitial(newCard.ID, updatedCustomer.customer.ID.toString(), membershipPrice * 100, orderId, "", ""));

        //    //    if (errorPayment) {
        //    //        console.log(errorPayment);
        //    //    }

        //    //    if (!dataPayment?.IsSuccess) {
        //    //        errorHandlers(dataPayment, 'Something went wrong. Please try later.', 'payment membership initial');
        //    //        return;
        //    //    }
        //    //}

        //    // add membership to order
        //    const { data: data1, error: error1 } = await useFetchAsync(addMembershipToOrder({
        //        orderId, locationId, billingCycleStartDate: today, membershipId, initiationFee: membershipPrice
        //    }));
        //    if (error1) {
        //        console.log(error1);
        //    }

        //    if (!data1.IsSuccess) {
        //        errorHandlers(data1, 'Something went wrong. Please try later.', 'Add membership to order');
        //        return;
        //    }

        //    const isPlaceOrder = await placeOrderForMembership(orderId, data1.Order.GrandTotal);
        //    if (!isPlaceOrder) return;

        //    else handleClickOpen();

        //    const { gtm: { formCompleteEvent, purchaseEvent } } = config;
        //    const tagManagerCompleteArgs = {
        //        dataLayer: {
        //            event: formCompleteEvent,
        //            form_type: 'membership_signup',
        //        },
        //    };

        //    const gtmProducts = isPlaceOrder?.Order?.Items?.map((item) => ({
        //        id: item.ID,
        //        name: membershipType,
        //        price: membershipPrice,
        //        category: 'membership',
        //        quantity: 1,
        //    }));
        //    const tagManagerPaymentArgs = {
        //        dataLayer: {
        //            event: purchaseEvent,
        //            locationId,
        //            locationName: preferredShop?.Name, // per Steve, via order payload
        //            ecommerce: {
        //                currencyCode: 'USD', // optional
        //                purchase: {
        //                    actionField: {
        //                        id: orderId, // Booker Transaction ID
        //                        affiliation: 'Drybar Holdings',
        //                        revenue: membershipPrice, // Incl. tax and shipping. Format like price
        //                    },
        //                    products: gtmProducts,
        //                },
        //            },
        //        },
        //    };
        //    TagManager.dataLayer(tagManagerCompleteArgs);
        //    TagManager.dataLayer(tagManagerPaymentArgs);
        //} else {
        //    errorHandlers(data, 'Can not create the Order for Customer. Please try later.', 'Create order');
        //}

        //setProcessing(false);
    };

    const createPlaceMembershipOrderRequest = async () => {
        if (isCardAdded) console.log('rewardsCardNumber: ' + rewardsCardNumber);
        setLoading(true);
        restClient
            .post(
                createPlaceMembershipOrder(
                    customerId.toString(),
                    preferredShop.bookerLocationId,
                    selectedMembershipPlanID,
                    null,
                    rewardsCardNumber ?? ''
                )
            )
            .then(async (res) => {
                if (res.status === 200) {
                    //close worldpay
                    setLoading(false);
                    history.push('/instore-barfly-confirm');
                } else {
                    setGeneralErrorMsg('An unknown error has occurred');
                }
            })
            .catch((errorResponse) => {
                const lowerCaseFirstLetter = (str) =>
                    str.charAt(0).toLowerCase() + str.slice(1);

                if (errorResponse.status === 400) {
                    const data = errorResponse.response.data;
                    // Validation error condition
                    if (data.errors) {
                        const newResponseErrors = {
                            ...newValidationState.newErrors,
                        };
                        // handle validation error response
                        for (const key in data.errors) {
                            const keyParts = key.split('.');
                            const fieldName = lowerCaseFirstLetter(keyParts[1]);
                            newResponseErrors[fieldName] = data.errors[
                                key
                            ].slice(0, 1);
                        }
                        setErrors(newResponseErrors);
                    }
                    // Generic error condition
                    else {
                        setGeneralErrorMsg(
                            'Something went wrong, please try to sign-up again?'
                        );
                    }
                }
                // Generic error condition
                else {
                    setGeneralErrorMsg(
                        'Something went wrong, please try to sign-up again?'
                    );
                }
                //setLoading(false);
                //setEnteredEmail(email);
            });
    };

    const onChange = (value, fieldName) => {
        if (hasFormLoaded) {
            // update the touched field value
            const newTouched = { ...touched, [fieldName]: true };
            // update the field value
            const newValues = { ...signupFormState, [fieldName]: value };
            // validate the new values
            var newValidationState = handleValidation(validateField, newValues);
            // update the state
            setTouched(newTouched);
            setErrors(newValidationState.newErrors);
            setSuccesses(newValidationState.newSuccesses);
            setSignUpFormState(newValues);
        }
    };

    const onBlur = (fieldName) => {
        if (hasFormLoaded) {
            if (touched[fieldName]) {
                const newValidateField = {
                    ...validateField,
                    [fieldName]: true,
                };
                const newValidationState = handleValidation(
                    newValidateField,
                    signupFormState
                );

                setErrors(newValidationState.newErrors);
                setSuccesses(newValidationState.newSuccesses);
                setValidateField(newValidateField);
            }
        }
    };

    const onCloseModal = () => {
        setLoading(false);
        setIsSuccessDialogOpen(false);
        reset(signupFormMetadata);
        setSignUpFormState(initFormState(signupFormMetadata));
    };

    const renderGeneralErrorMsg = (errorMsg) => (
        <div className={classes.generalErrorMsg}>
            <span style={{ verticalAlign: 'middle' }}>{errorMsg}</span>
        </div>
    );

    const renderValidationErrorMsg = (errorMsg, colour) => (
        <div className={colour}>
            <CloseIcon className={classes.passwordIcon} />
            <span style={{ verticalAlign: 'middle' }}>{errorMsg}</span>
        </div>
    );

    const renderValidationSuccessMsg = (successMsg) => (
        <div className={classes.successStyle}>
            <DoneIcon className={classes.passwordIcon} />
            <span style={{ verticalAlign: 'middle' }}>{successMsg}</span>
        </div>
    );

    return (
        <Formik enableReinitialize onSubmit={goToPayment}>
            {({ handleSubmit }) => (
                <form
                    onSubmit={handleSubmit}
                    data-form_type="membership_signup"
                >
                    <Grid className={classes.container}>
                        <Media query={{ maxWidth: TABLET_BREAKPPOINT }}>
                            {(matches) =>
                                matches ? (
                                    <Grid className={classes.titleContainer}>
                                        <Grid style={{ paddingTop: '12px' }}>
                                            <Link
                                                to="/instore-barfly-membership"
                                                className={classes.displayFlex}
                                                onClick={onBack}
                                            >
                                                <ArrowBackIosIcon
                                                    style={{ fontSize: '34px' }}
                                                />
                                            </Link>
                                        </Grid>
                                        <Typography
                                            className={classes.mainTitle}
                                        >
                                            <Box>Lets Get</Box>
                                            <Box>A Bit More Information</Box>
                                            <Box className={classes.subTitle}>
                                                and complete your barfly
                                                membership
                                            </Box>
                                        </Typography>
                                    </Grid>
                                ) : (
                                    <Grid className={classes.titleContainer}>
                                        <Grid style={{ paddingTop: '12px' }}>
                                            <Link
                                                to="/instore-barfly-membership"
                                                className={classes.displayFlex}
                                                onClick={onBack}
                                            >
                                                <ArrowBackIosIcon
                                                    style={{ fontSize: '34px' }}
                                                />
                                                <Typography
                                                    style={{
                                                        fontSize: '16px',
                                                        fontWeight: '600',
                                                        paddingTop: '2px',
                                                    }}
                                                >
                                                    Back
                                                </Typography>
                                            </Link>
                                        </Grid>
                                        <Typography
                                            className={classes.mainTitle}
                                        >
                                            Lets Get A Bit More Information
                                            <Box className={classes.subTitle}>
                                                and complete your barfly
                                                membership
                                            </Box>
                                        </Typography>
                                    </Grid>
                                )
                            }
                        </Media>

                        <Typography className={classes.fieldContainerTitle}>
                            GENERAL INFORMATION
                        </Typography>

                        <Grid container className={classes.fieldContainer}>
                            {showLoading ? (
                                <Backdrop className={classes.backdrop} open>
                                    <CircularProgress color="inherit" />
                                    <div style={{ marginLeft: '15px' }}>
                                        Setting up Membership...
                                    </div>
                                </Backdrop>
                            ) : null}

                            <Grid>
                                <div className="payment-card">
                                    <Grid>
                                        <div
                                            id="details-form"
                                            className="checkout"
                                        >
                                            {signupFormMetadata.map(
                                                (field, i) => (
                                                    <Grid
                                                        className={
                                                            classes.fieldContainer
                                                        }
                                                        key={`grid-text-input-field-${field.name}`}
                                                    >
                                                        <TextInputField
                                                            autoComplete="none"
                                                            name={
                                                                'notASearch-${field.name}'
                                                            }
                                                            key={`text-input-field-${field.name}`}
                                                            value={
                                                                signupFormState?.[
                                                                    field?.name
                                                                ] || ''
                                                            }
                                                            onBlur={(e) => {
                                                                onBlur(
                                                                    field.name
                                                                );
                                                                hidePlaceholder;
                                                            }}
                                                            placeholder={
                                                                displayPlaceholder[
                                                                    field.name
                                                                ]
                                                                    ? field.placeholder
                                                                    : ''
                                                            }
                                                            onChange={(e) => {
                                                                onChange(
                                                                    field.name.includes(
                                                                        'Name'
                                                                    )
                                                                        ? e.target.value
                                                                              .charAt(
                                                                                  0
                                                                              )
                                                                              .toUpperCase() +
                                                                              e.target.value.slice(
                                                                                  1
                                                                              )
                                                                        : e
                                                                              .target
                                                                              .value,
                                                                    field.name
                                                                );
                                                            }}
                                                            onFocus={(e) => {
                                                                showPlaceholder(
                                                                    field
                                                                );
                                                                setDisplayTips(
                                                                    true
                                                                );
                                                            }}
                                                            onPaste={(e) => {
                                                                if (
                                                                    field.type ==
                                                                        'email' &&
                                                                    field.name.indexOf(
                                                                        'Confirm'
                                                                    ) > -1
                                                                ) {
                                                                    e.preventDefault();
                                                                    var plc =
                                                                        field.placeholder;
                                                                    field.placeholder =
                                                                        'Please re-type your email here';
                                                                    showPlaceholder(
                                                                        field
                                                                    );
                                                                    setTimeout(
                                                                        () => {
                                                                            field.placeholder = plc;
                                                                            showPlaceholder(
                                                                                field
                                                                            );
                                                                        },
                                                                        2000
                                                                    );
                                                                }
                                                            }}
                                                            className={
                                                                classes.textField
                                                            }
                                                            label={field.label}
                                                            error={hasFieldErrors(
                                                                field.name,
                                                                errors
                                                            )}
                                                            errorMessage={
                                                                field.name !==
                                                                'password'
                                                                    ? hasFieldErrors(
                                                                          field.name,
                                                                          errors
                                                                      ) &&
                                                                      errors[
                                                                          field
                                                                              .name
                                                                      ].map(
                                                                          (
                                                                              msg
                                                                          ) =>
                                                                              renderValidationErrorMsg(
                                                                                  msg
                                                                              )
                                                                      )
                                                                    : null
                                                            }
                                                            type={field.type}
                                                            onChangeSelect={
                                                                field.select
                                                                    ? (e) =>
                                                                          onChange(
                                                                              e
                                                                                  .target
                                                                                  .value,
                                                                              field
                                                                                  .select
                                                                                  .name
                                                                          )
                                                                    : null
                                                            }
                                                            select={
                                                                field.select
                                                            }
                                                        />
                                                    </Grid>
                                                )
                                            )}
                                        </div>
                                    </Grid>

                                    <Grid>
                                        <Grid
                                            container
                                            className="justify-content-between"
                                        >
                                            <Grid item sm={6} xs={12}>
                                                <Grid
                                                    item
                                                    className={
                                                        classes.summaryContainer
                                                    }
                                                >
                                                    <Typography
                                                        className={
                                                            classes.summaryTitle
                                                        }
                                                    >
                                                        Summary of Benefits
                                                    </Typography>
                                                    <Grid
                                                        className={
                                                            classes.membershipCharge
                                                        }
                                                    >
                                                        <Grid
                                                            className={
                                                                classes.serviceChargeCotainer
                                                            }
                                                        >
                                                            <Grid
                                                                className={
                                                                    classes.serviceChargeData
                                                                }
                                                            >
                                                                <Grid>
                                                                    <Grid
                                                                        className={
                                                                            classes.headingSpace
                                                                        }
                                                                    >
                                                                        <Grid
                                                                            className={
                                                                                classes.nameAndPrice
                                                                            }
                                                                        >
                                                                            <Box
                                                                                className={
                                                                                    classes.theBarflySingle
                                                                                }
                                                                            >
                                                                                {
                                                                                    membershipType
                                                                                }
                                                                            </Box>
                                                                            <Box
                                                                                className={
                                                                                    classes.thePrice
                                                                                }
                                                                            >
                                                                                &#163;
                                                                                {
                                                                                    price
                                                                                }{' '}
                                                                                /
                                                                                mth
                                                                            </Box>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid
                                                                        className={
                                                                            classes.allTheBenefits
                                                                        }
                                                                    >
                                                                        <Box
                                                                            className={
                                                                                classes.blowDryPerMonth
                                                                            }
                                                                        >
                                                                            {
                                                                                blowouts
                                                                            }
                                                                        </Box>
                                                                        {additionalOffers.map(
                                                                            (
                                                                                item
                                                                            ) =>
                                                                                !item?.value
                                                                                    ?.toLowerCase()
                                                                                    .includes(
                                                                                        'add-on'
                                                                                    ) ? null : (
                                                                                    <Box
                                                                                        className={
                                                                                            classes.blowDryPerMonth
                                                                                        }
                                                                                    >
                                                                                        {item.name +
                                                                                            ' ' +
                                                                                            item.value}
                                                                                    </Box>
                                                                                )
                                                                        )}
                                                                        {additionalOffers.map(
                                                                            (
                                                                                item
                                                                            ) =>
                                                                                item?.value
                                                                                    ?.toLowerCase()
                                                                                    .includes(
                                                                                        'add-on'
                                                                                    ) ? null : (
                                                                                    <Box
                                                                                        className={
                                                                                            classes.blowDryPerMonth
                                                                                        }
                                                                                    >
                                                                                        {item.name +
                                                                                            ' ' +
                                                                                            item.value}
                                                                                    </Box>
                                                                                )
                                                                        )}
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid></Grid>
                                                            </Grid>

                                                            {/* <Box className={classes.slashVector} >/</Box>
                                    <Box className={classes.monthCopy}>
                                        month
                                    </Box> */}
                                                        </Grid>
                                                        <div
                                                            className={
                                                                classes.pricesVary
                                                            }
                                                        >
                                                            <p>
                                                                {' '}
                                                                ** Membership
                                                                prices may vary
                                                                depending on
                                                                location. For H
                                                                beauty Barfly
                                                                members, please
                                                                note that there
                                                                will be an
                                                                additional
                                                                surcharge to
                                                                redeem services
                                                                and treatments
                                                                in the Drybar
                                                                Knightsbridge
                                                                location.
                                                            </p>
                                                        </div>{' '}
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item sm={6} xs={12}>
                                                <Grid
                                                    item
                                                    className={
                                                        classes.summaryContainer
                                                    }
                                                >
                                                    <Typography
                                                        className={
                                                            classes.summaryTitle
                                                        }
                                                    >
                                                        Preferred Shop
                                                    </Typography>
                                                    <Grid
                                                        className={`${classes.membershipCharge}`}
                                                    >
                                                        <Grid
                                                            className={
                                                                classes.locationDetails
                                                            }
                                                        >
                                                            <Typography
                                                                className={
                                                                    classes.drybarLocation
                                                                }
                                                                style={{
                                                                    fontWeight:
                                                                        '600',
                                                                    lineHeight:
                                                                        '28px',
                                                                    color:
                                                                        '#54575a',
                                                                }}
                                                            >
                                                                {
                                                                    preferredShop.title
                                                                }
                                                            </Typography>
                                                            <Typography
                                                                className={
                                                                    classes.drybarLocation
                                                                }
                                                                style={{
                                                                    fontSize:
                                                                        '16px',
                                                                    lineHeight:
                                                                        '20px',
                                                                    marginTop:
                                                                        '15px',
                                                                    color:
                                                                        '#54575a',
                                                                }}
                                                            >
                                                                {`${preferredShop.contact?.street1}, ${preferredShop.contact?.city},
                                       ${preferredShop.contact?.postalCode}`}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid
                                                        className={
                                                            classes.mapInfo
                                                        }
                                                    >
                                                        <Map
                                                            google={google}
                                                            containerStyle={
                                                                containerStyle
                                                            }
                                                            zoom={14}
                                                            style={mapStyles}
                                                            initialCenter={
                                                                initialCenter
                                                            }
                                                            center={
                                                                initialCenter
                                                            }
                                                            // bounds={bounds}
                                                            onReady={(
                                                                mapProps,
                                                                map
                                                            ) =>
                                                                _mapLoaded(
                                                                    mapProps,
                                                                    map
                                                                )
                                                            }
                                                        >
                                                            <Marker
                                                                title={
                                                                    preferredShop
                                                                        ?.contact
                                                                        ?.street1
                                                                }
                                                                name={
                                                                    preferredShop?.title
                                                                }
                                                                position={
                                                                    initialCenter
                                                                }
                                                                icon={
                                                                    markerIcon
                                                                }
                                                                style={{
                                                                    margin:
                                                                        '-51px 0px -12px 155px',
                                                                }}
                                                            />
                                                        </Map>
                                                        {/* <img src={preferredShop.mapImage?.url} alt="confirmed-map"
                                     className={classes.confirmedMap}/> */}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid>
                                            <Typography
                                                className={
                                                    classes.finePrintHeading
                                                }
                                            >
                                                THE FINE PRINT
                                            </Typography>
                                            <Grid
                                                className={classes.theFinePrint}
                                            >
                                                <DocumentToComponent
                                                    doc={finePrintCollection}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid className={classes.fieldContainer}>
                                        <div className={classes.test}>
                                            <Button
                                                id="submit-button"
                                                className={classes.addCardBtn}
                                                variant="outlined"
                                                onClick={goToPayment}
                                            >
                                                Purchase Membership
                                            </Button>
                                        </div>
                                        <div className={classes.signingUp}>
                                            <p>
                                                {' '}
                                                By signing up to become a Barfly
                                                member, I understand that I will
                                                be charged a monthly membership
                                                fee until my membership is
                                                cancelled and I agree to the{' '}
                                                <a
                                                    target="_blank"
                                                    href="https://www.drybarshops.co.uk/help-centre/membership-policy"
                                                >
                                                    Barfly Membership Terms
                                                </a>{' '}
                                                and&nbsp;
                                                <a
                                                    target="_blank"
                                                    href="https://www.drybarshops.co.uk/help-centre/privacy-policy"
                                                >
                                                    Drybar Privacy Policy
                                                </a>{' '}
                                                Drybar Privacy Policy.
                                            </p>
                                        </div>
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            )}
        </Formik>
    );
};

InstoreBarflyEnrollmentPage.propTypes = {
    barflyConfirmData: object,
    google: object,
};

InstoreBarflyEnrollmentPage.defaultProps = {
    google: {},
};

const mapStateToProps = (state) => ({
    preferredShop: getChosenStore(state),
    selectedMembershipPlanID: getBarflyMembershipID(state),
    selectedMembershipPlanPrice: getBarflyMembershipPrice(state),
    /*    bookerCustomerId: getBookerCustomerId(state)*/
    membershipPrice: getBarflyMembershipPrice(state),
    selectedStore: getChosenStore(state),
    membershipId: getBarflyMembershipID(state),
    membershipType: getBarflyMembershipType(state),
    blowouts: getBarflyBlowouts(state),
    additionalOffers: getBarflyAdditionalOffers(state),
    barflyMembershipCollection: array.isRequired,
    selectedMembershipPlanPrice: getBarflyMembershipPrice(state),
    isWorldpayOpen: getWorldpayStatus(state),
    isCardAdded: getCardAddedStatus(state),
});

const mapDispatchToProps = (dispatch) => ({
    toggleWorldpay: bindActionCreators(toggleWorldpay, dispatch),
    bookerCustomerId: bindActionCreators(setBookerCustomerId, dispatch),
    setCardAddedWorldpay: bindActionCreators(setCardAddedWorldpay, dispatch),
    setRewardsCardNumber: bindActionCreators(setRewardsCardNumber, dispatch),
});

/*export default withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(InstoreBarflyEnrollmentPage)));*/
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    withRouter(
        GoogleApiWrapper({
            apiKey: 'AIzaSyAixN59eNVJmUFjTac2K4KCB88lM6zP4t4',
        })(withStyles(styles)(InstoreBarflyEnrollmentPage))
    )
);
