export const getServices = (hasLocationId) => ({
  url: '/booker/FindTreatments',
  data: {
    method: 'POST',
    urlParams: {},
    data: {
      LocationID: hasLocationId,
    },
  },
});

export const getAddOnsData = (getSelectedTreatmentId) => ({
    url: '/booker/GetTreatmentAddOns',
    data: {
        method: 'GET',
        urlParams: {
          treatmentId: getSelectedTreatmentId,
        },
        data: {},
    },
});

export const getAvailableDates = (startDate, endDate, hasLocationId) => ({
  url: '/booker/AvailableDates',
  data: {
    method: 'GET',
    urlParams: {},
    data: {
      locationIds: hasLocationId,
      fromDate: startDate,
      toDate: endDate,
    },
  },
});

export const getSlots = (selectedDate, hasLocationId, service) => ({
  url: '/booker/Availability1Day',
  data: {
    method: 'GET',
    urlParams: {},
    data: {
      LocationId: hasLocationId,
      fromDateTime: selectedDate,
      IncludeEmployees: true,
      serviceId: service.ID,
    },
  },
});

export const getSlotsForMultiServices = (selectedDate, locationId, services) => ({
  url: '/booker/MultiGuest1Day',
  data: {
    method: 'POST',
    urlParams: {},
    data: {
      IncludeEmployees: true,
      IncludeFreelancers: false,
      locationId,
      fromDateTime: selectedDate,
      itineraries: services.map((s) => ({
        serviceId: s.ID,
      })),
    },
  },
});

export const findMemberships = (locationId) => ({
  url: '/booker/FindMemberships',
  data: {
    method: 'POST',
    urlParams: {},
    data: {
      PageSize: 5,
      LocationID: locationId,
    },
  },
});

export const findCustomerMemberships = (locationId, customerId) => ({

  url: '/booker/FindCustomerMemberships',
  data: {
    method: 'POST',
    urlParams: {},
    data: {
      CustomerID: customerId,
      LocationID: locationId,
    },
  },
});

export const createOrder = (customerId, locationId) => ({
  url: '/booker/CreateOrder',
  data: {
    method: 'POST',
    urlParams: {},
    data: {
      CustomerID: customerId,
      LocationID: locationId,
    },
  },
});
export const createPlaceMembershipOrder = (customerId, locationId, membershipPlanId, tokenId, rewardsCardNumber) => ({
    url: '/booker/CreateAndPlaceMembershipOrder',
    data: {
        method: 'POST',
        urlParams: {},
        data: {
            CustomerID: customerId,
            LocationID: locationId,
            MembershipPlanID: membershipPlanId, 
            TokenId: tokenId,
            RewardsCardNumber: rewardsCardNumber,
        },
    },
});


export const paymentMembershipInitial = (tokenId, customerId, chargeAmount, orderId, groupId, customerMembershipId, rewardsCardNumber) => ({
  url: '/payment/MembershipInitial',
  data:{
      TokenId: tokenId,
      CustomerID: customerId,
      ChargeAmount: chargeAmount,
      OrderId: orderId,
      GroupId: groupId,
      CustomerMembershipId: customerMembershipId,
      RewardsCardNumber: rewardsCardNumber
    },
});

export const addMembershipToOrder = ({
 orderId, locationId, billingCycleStartDate, membershipId, initiationFee
}) => ({
  url: '/booker/AddMembershipToOrder',
  data: {
    method: 'POST',
    urlParams: {
      orderId,
    },
    data: {
      BillingCycleStartDateOffset: billingCycleStartDate,
      InitiationFee: initiationFee,
      LocationID: locationId,
      AutoRenew: true,
      IncludeBenefits: true,
      PaymentPlanID: membershipId,
      ID: orderId,
    },
  },
});

export const updateCustomerMembershipCreditCardOnFile = ({
 CustomerMembershipID, LocationID, CustomerID, CustomerCreditCardID,
}) => ({
  url: '/booker/UpdateCustomerMembershipCreditCardOnFile',
  data: {
    method: 'POST',
    urlParams: {},
    data: {
      CustomerMembershipID,
      LocationID,
      CustomerID,
      CustomerCreditCardID,
    },
  },
});

export const addAddonsToAppointment = ({ locationId, appointmentId, serviceId }) => ({
  url: '/booker/AddAddonItemToAppointment',
  data: {
    method: 'POST',
    urlParams: {},
    data: {
      LocationID: locationId,
      AppointmentID: appointmentId,
      AddonItemID: serviceId,
      AddonItemTypeID: 1,
      Quantity: 1,
    },
  },
});

export const createAppointment = ({
 locationId, authUser, items, slot, notes, couponCode, cardTokenId
}) => ({
  url: '/booker/CreateAppointment',
  data: {
    method: 'POST',
    urlParams: {},
    data: {
      AppointmentDateOffset: slot[Object.keys(slot)[0]].startDateTime,
      AppointmentTreatmentDTOs: items,
      Customer: {
        ...authUser,        
        SendEmail: true,
      },
      ResourceTypeID: 1,
      LocationID: locationId,
      Notes: notes.toString(),
      AppointmentPayment: {
        CouponCode: couponCode,
      },
      cardTokenId,
    },
  },
});

export const createItinerary = ({ locationId, authUser, items, cardTokenId }) => ({
  url: '/booker/CreateItinerary',
  data: {
    method: 'POST',
    urlParams: {},
    data: {
      LocationID: locationId,
      GroupName: `${authUser.FirstName}'s friends`,
      ItineraryItems: items,
      cardTokenId
    },
  },
});

export const applyPromoCodeToOrder = ({ orderId, specialIds, locationId }) => ({
  url: '/booker/AddSpecialToOrder',
  data: {
    method: 'POST',
    urlParams: {
      orderId,
    },
    data: {
      ID: orderId,
      SpecialIDs: specialIds,
      LocationID: locationId,
    },
  },
});

export const cancelItinerary = (ID, LocationID) => ({
  url: '/booker/CancelItinerary',
  data: {
    method: 'POST',
    urlParams: {
      itineraryId: ID,
    },
    data: {
      ID,
      LocationID,
    },
  },
});

export const addAddOnsAppoints = (items) => ({
  url: '/booker/UpdateItineraryItemAddOns',
  data: {
    method: 'POST',
    urlParams: {},
    data: {
      ItineraryItemAddOns: items,
    },
  },
});

export const bookItinerary = (itineraryId, groupName, id) => (
  {
    url: '/booker/BookItinerary',
    data: {
        method: 'POST',
        urlParams: {
          itineraryId,
        },
        data: {
          GroupName: groupName,
          ID: id,
        },
    },
}
);

export const findAddOnServices = ({ Name, LocationID }) => ({
  url: '/booker/FindAddOnServices',
  data: {
    method: 'POST',
    urlParams: {},
    data: {
      Name,
      LocationID,
      AddonsOnly: true,
    },
  },
});

export const updateAppointment = ({ startDate, endDate }) => ({
  url: '/booker/UpdateAppointment',
  data: {
    method: 'POST',
    urlParams: {},
    data: {
      AppointmentDateOffset: startDate,
      AppointmentTreatmentDTOs: [
        {
          EmployeeID: 119629,
          StartTimeOffset: startDate,
          EndTimeOffset: endDate,
          TreatmentID: 2490102,
          RoomID: 11519,
        }],
      Customer: {
        FirstName: 'Steve',
        LastName: 'Monkeytime',
        Email: 'steve.witkos@thedrybar.com',
        HomePhone: '7147881059',
        ID: '119704791',
      },
      ResourceTypeID: 1,
      LocationID: '1639',
    },
  },

});

export const cancelAppointment = (ID, type = 1) => ({
  url: '/booker/CancelAppointment',
  data: {
    method: 'PUT',
    urlParams: {},
    data: {
      ID,
      AppointmentCancellationType: {
        ID: type,
      },
    },
  },
});

export const getAppointments = (customerKey) => ({
  url: '/booker/GetCustomerAppointments',
  data: {
    method: 'GET',
    urlParams: {
      customerId: customerKey,
    },
    data: {
      Count: 20,
      PageNumber: 1,
      ShowAppointmentIconFlags: true,
    },
  },
});

export const validatePromoCode = (promoCode) => ({
  url: '/promo/getList',
  params: {
    code: promoCode,
  },
});

export const updatePromoCode = (promoCode) => ({
  url: '/promo/updateItem',
  data: {
    code: promoCode,
  },
});

export const getSpecialByCode = (promoCode, locationId) => ({
  url: '/booker/GetSpecialByCode',
  data: {
    method: 'GET',
    urlParams: {
      locationId,
    },
    data: {
      couponcode: promoCode,
    },
  },
});

/**
 * A method called to verify a users payment card details using the Worldpay Checkout process.
 * See https://developer.worldpay.com/docs/access-worldpay/checkout/
 * @param {*} locationId The drybar location 
 * @param {*} customerId The drybar customer
 * @param {*} nameOnCard The name on the payment card
 * @param {*} cardType The payment card brand
 * @param {*} isDefault Set to true if the user wants this card to be the default that is used in future transactions
 * @param {*} sessionHRef The Worldpay sessionHRef token
 * @param {*} billingAddress The payment card's owner's billing address
 * @returns 200 - card verified and saved
 *          400 - invalid form data
 *          402 - non-verified payment card
 *          409 - payment card details conflict (the entered form details do not match those on record with WP)
 *          Other 404, 500 etc
 */ 
 export const addCreditCardCustomer2 = (locationId, customerId, nameOnCard, cardType, isDefault = false, sessionHRef = 'error-the-session-href-has-not-been-set', billingAddress) => ({
  url: '/booker/AddCreditCardToCustomer',
  data: {
    method: 'POST',
    urlParams: {},
    data: {
      SpaID: locationId,
      CustomerID: customerId,
      CreditCard: {
        Type: {
          ID: cardType,
        },
        Number: "",
        NameOnCard: nameOnCard
      },
      BillingAddress: billingAddress,
      IsDefault: isDefault,
      TokenizedCardReference: sessionHRef
    },
  },
});

export const deleteCreditCard = (customerId, spaId, id, email) => ({
  url: '/booker/DeleteCustomerCreditCard',
  data: {
    method: 'POST',
    urlParams: {},
    "data": {
      "CustomerID": customerId,
      "SpaID": spaId,
      "ID": id,
      "Email": email
    }
  },
});

/**
 * A method called to verify a users payment card details using the 3DS process.
 * See https://developer.worldpay.com/docs/access-worldpay/3ds/web/
 * @param {*} locationId The drybar location 
 * @param {*} customerId The drybar customer
 * @param {*} nameOnCard The name on the payment card
 * @param {*} cardType The payment card brand
 * @param {*} isDefault Set to true if the user wants this card to be the default that is used in future transactions
 * @param {*} sessionHRef The Worldpay sessionHRef token
 * @param {*} billingAddress The payment card's owner's billing address
 * @returns 200 - card verified and saved
 *          400 - invalid form data
 *          402 - non-verified payment card
 *          409 - payment card details conflict (the entered form details do not match those on record with WP)
 *          Other 404, 500 etc
 */ 
export const threeDSTokenise = (locationId, customerId, nameOnCard, cardType, isDefault = false, sessionHRef = 'error-the-session-href-has-not-been-set', billingAddress) => ({
  url: '/payment/3ds/tokenise',
  data: {
    method: 'POST',
    urlParams: {},
    data: {
      SpaID: locationId,
      CustomerID: customerId,
      CreditCard: {
        Type: {
          ID: cardType,
        },
        Number: "",
        NameOnCard: nameOnCard
      },
      BillingAddress: billingAddress,
      IsDefault: isDefault,
      TokenizedCardReference: sessionHRef
    },
  },
});

export const addCreditCardCustomer = (locationId, maskedCardNumber, cvv, customerId, nameOnCard, type, expirationDate, isDefault = false, worldpayToken = 'error-the-world-pay-token-has-not-been-set') => ({
  url: '/booker/AddCreditCardToCustomer',
  data: {
    method: 'POST',
    urlParams: {},
    data: {
      SpaID: locationId,
      CustomerID: customerId,
      CreditCard: {
        Type: {
          ID: type,
        },
        Number: maskedCardNumber,
        NameOnCard: nameOnCard,
        ExpirationDateOffset: expirationDate
      },
      IsDefault: isDefault,
      TokenizedCardReference: worldpayToken
    },
  },
});

export const addPaymentToOrder = (orderId, {
 Number, NameOnCard, ExpirationDateOffset, SecurityCode,
}, Amount, BillingZip) => ({
	url: '/booker/AddPaymentToOrder',
	data: {
    method: 'POST',
    urlParams: {
        orderId,
    },
    data: {
			ReturnPartialOrderObject: 'true',
			PaymentItem: {
				Amount: {
					Amount,
				},
				Method: {
						ID: '1',
				},
				CreditCard: {
					Number,
					SecurityCode,
          NameOnCard,
          BillingZip,
					ExpirationDateOffset,
					Type: {
						ID: '2',
						Name: 'Visa',
					},
				},
			},
    },
  },
});

export const placeMembershipOrder = (customerID, locationID, cardTokenID, digitalRewardsCardNum, orderID, amount) => ({
  url:'/booker/PlaceMembershipOrder',
  data: {
    method: "POST",
    urlParams: {
      orderId: orderID
    },
    data: {
      customerID: customerID,
      locationID: locationID,
      cardTokenId: cardTokenID,
      digitalRewardsCardNum: digitalRewardsCardNum,
      paymentItem: {
        customPaymentMethodID: null,
        amount: {
          amount: amount
        },
        method: {
          id: 4,
          name: "Cash Equivalent"
      }
    }
  }}
});

export const placeOrder = (orderId, {
Number, NameOnCard, SecurityCode, ExpirationDateOffset, Type,
}, price, BillingZip) => ({
	url: '/booker/PlaceOrder',
	data: {
    method: 'POST',
    urlParams: {
        orderId,
    },
    data: {
			ReturnPartialOrderObject: 'true',
			PaymentItem: {
				Amount: {
          Amount: price.Amount,
          CurrencyCode: price.CurrencyCode,
				},
				Method: {
						ID: '1',
				},
				CreditCard: {
					Number,
					SecurityCode,
          NameOnCard,
          BillingZip,
					ExpirationDateOffset,
					Type: {
						ID: Type,
					},
				},
			},
    },
  },
});

export const getCustomer = (customerId) => ({
  url: '/booker/GetCustomer',
  data: {
    method: 'GET',
    urlParams: {
      customerId,
    },
    data: {
      includeFieldValues: true,
    },
  },
});

export const updateCustomer = (customer) => ({
  url: '/booker/UpdateCustomer',
  data: {
    method: 'PUT',
    urlParams: {
      customerId: customer.ID,
    },
    data: {
      LocationID: customer.LocationID,
      CustomerID: customer.ID,
      Customer: {
        LastName: customer.LastName,
        FirstName: customer.FirstName,
        Email: customer.Email,
        Address: customer.Address,
        CellPhone: customer.CellPhone,
        DateOfBirthOffset: customer.DateOfBirthOffset,
      },
    },
  },
});

/**
 * An authenticated call to get the Worldpay config.
 * 
 * @returns {
 *  worldpayEnvironment: string
 *  accessGatewayId: string
 *  threeDSEnabled: boolean
 * }
 */
export const getWorldpayConfig = () => ({
  url: '/payment/worldpay/init',
  data: {
    method: 'POST',
    urlParams: {},
    data: {},
  },
});

/**
 * 
 * @param {*} transactionReference - The drybar-worldpay transaction reference
 * @param {*} tokenReference - The token-href provided by the Worldpay Access Checkout verification process 
 * @param {*} sessionId - The sessionId passed back from DDC
 * @param {*} cardTypeId - The card type
 * @param {*} customerId - The customer id
 * @param {*} isDefault - Is this default card
 * @returns 
 */
export const worldpay3DSAuthentication = (transactionReference, tokenReference, sessionId, cardTypeId, customerId, isDefault, cvcHref, price) => ({
  url: '/payment/3ds/authentication',
  data: {
    TransactionReference: transactionReference, // WARNING: should be created in api
    TokenReference: tokenReference, // WARNING: should this be available externally from the API (is it secure to do so?)
    SessionId: sessionId,
    CardTypeId: cardTypeId,
    CustomerId: customerId,
    IsDefault: isDefault,
    CVCHref: cvcHref,
    Amount: price
  },
});

export const getCustomerCreditCards = (customerId, locationId) => ({
  url: '/booker/GetCustomerCreditCards',
  data: {
    method: 'POST',
    urlParams: {},
    data: {
      CustomerID: customerId,
      SpaID: locationId,
    },
  },
});

export const getBookerTimeSlot = ({
startTime, locationId, serviceId, employeeId, roomId,
}) => ({
  url: '/booker/TimeSlot',
  data: {
    method: 'POST',
    urlParams: {},
    data: {
      locationId,
      serviceId,
      startDateTime: startTime,
      employeeId,
      roomId,
    },
  },
});

export const setFavouriteLocation = (fvtLocation, customerId) => ({
  url: '/booker/SetCustomerFavoriteLocation',
  data: {
    method: 'POST',
    urlParams: {
      CustomerID: String(customerId)
    },
    data: {
      CustomerID: String(customerId),
      LocationID: fvtLocation,
      CustomerFieldValues: {
        FieldValues: [
          {
            Key: 56378,
            Value: {
              TextValue: {
                Value: String(fvtLocation),
              },
            },
          },
        ],
      },
    },
  },
});

export const getCustomerDetailsById = (customerId) => ({
  url: '/booker/GetCustomer',
  data: {
    method: 'GET',
    urlParams: {
      customerId,
    },
    data: {
      Count: 1,
      PageNumber: 1,
      ShowAppointmentIconFlags: true,
      includeFieldValues: true,
    },
  },
});

export const closeOrder = (orderId) => ({
  url: '/booker/CloseOrder',
  data: {
    method: 'POST',
    urlParams: {
      orderId,
    },
    data: {
      ID: orderId,
    },
  },
});

export default {
  getServices,
  getAddOnsData,
};
