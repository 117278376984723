import { getCountryCodes } from "app/Components/Auth/Partials/Registration/signupFormFields";

export const createUserOkta = ({
 firstName, lastName, email, phoneNumber, phoneCountryCode, password
}) => ({
    url: '/user/',
    data: {
        profile: {
            firstName,
            lastName,
            email,
            login: email,
            phoneCountryCode: phoneCountryCode,
            primaryPhone: phoneNumber,
            password: password
        },
    },
});

export const createUserLiteOkta = ({
    firstName, lastName, email, phoneNumber, phoneCountryCode
}) => ({
    url: '/user/Lite',
    data: {
        profile: {
            firstName,
            lastName,
            email,
            phoneCountryCode: phoneCountryCode,
            primaryPhone: phoneNumber,
        },
    },
});

export const instoreAccess = () => ({
    url: '/user/storeAccess',
    data: {}
});

export const updateUserOkta = (profile) => ({
    url: '/okta/update-user',
    data: profile,
});

export const updateUser = (firstName, lastName, primaryPhone, phoneCountryCode, customerID, locationID, rewardsCardID, password) => ({
    url:'/booker/UpdateCustomer', 
    data: {
        method: 'PUT',
        urlParams: { 
            customerID: customerID.toString()   
        },    
        data:{
            customerID: customerID.toString(),
            locationID,
            password: password,
            customer:{
                firstName,
                lastName,
                cellPhone: primaryPhone,
                phoneCountryCode,
                rewardsCardID
            },
        },
    }
});
export const recoverPassword = (email) => ({
    url: '/user/sendLink',
    params: {
      linkType: 'passwordRecovery'
    },
    data: {
        email: email
    },
});

export const UpdatePassword = (email, oldPassword, newPassword) => ({
    url: '/user/password',
    params: {},
    data: {
        email: email,
        oldPassword: oldPassword,
        newPassword: newPassword,
    },
});

export const changePassword = (token, password) => ({
    url: '/user/password',
    params: {},
    data: {token, password},
});

export const confirmEmail = (nonce) => ({
    url: '/user/account/confirm',
    data: {
        nonce: nonce
    }
});

export const resendVerificationEmail = (email) => ({
    url: '/user/sendLink',
    params: { linkType: 'accountVerify'},
    data: {
        email: email
    }
});

export const oktaTest = (idToken, nonce) => ({
    url: '/testOkta',
    data: {},
    headers: {
        Authorization: `Bearer ${idToken}`,
        nonce,
    },
});

export const getPhoneCountryCodes = (locationId = 1) => ({
    url: '/booker/GetPhoneCountryCodes',
    data: {
        method: "POST",
        urlParams: {
            locationId: locationId
        }
    }
});

export const CreateUserIdentity = (vLink, newPassword) => ({
    url: '/user/createUserIdentity',
    params: {},
    data: {
        vLink: vLink,
        password: newPassword,
    },
});

export const ValidateLink = (vLink) => ({
    url: '/user/ValidateLink',
    params: {},
    data: {
        vLink: vLink
    },
});


export default {
    createUserOkta,
    createUserLiteOkta,
    recoverPassword,
    getPhoneCountryCodes
};
