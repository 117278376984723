import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getAuthUserInfo } from '../../../state/ducks/Booking/Booking-Selectors';
import { getWorldpayStatus } from '../../../state/ducks/Worldpay/Worldpay-Selectors';
import { toggleWorldpay } from '../../../state/ducks/Worldpay/Worldpay-Actions';
import useMoktaAuth from 'utils/oktaAuth';

import './ModalOverlay.scss';

const ModalOverlay = ({authUserInfo, isWorldpayOpen}) => {
    const [isAuthenticated, getUser] = useMoktaAuth();
    return (<div className="modal-overlay" 
          id="modal-overlay"  
          style={{visibility: isAuthenticated() && isWorldpayOpen ? 'visible' : 'hidden'}}>
    </div>);
}

const mapStateToProps = (state) => ({
    authUserInfo: getAuthUserInfo(state),
    isWorldpayOpen: getWorldpayStatus(state)
});

const mapDispatchToProps = (dispatch) => ({
    toggleWorldpay: bindActionCreators(toggleWorldpay, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalOverlay);