/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { withOktaAuth } from '@okta/okta-react';
import SectionTitle from '../SectionTitle';
import restClient from '../../../api/restClient';
import { confirmEmail } from '../../../api/auth-api';
import ResendVerificationEmail from './Partials/ResendVerificationEmail/ResendVerificationEmail';
import { MOBILE_BREAKPOINT } from 'Helpers/breakpoints';

const useStyles = makeStyles((theme) => ({
    landingSection: {
        display: 'flex',
        justifyContent: 'center',
    },
    landingInner: {
        background: '#FFFFFF',
        boxShadow: '1px 2px 5px #ccc',
        borderRadius: '2px',
        padding: '40px 50px',
        marginTop: '30px',
        marginBottom: '30px',
        textAlign: 'center',
    },
    textSection: {
        fontSize: '18px',
        textAlign: 'center',
    },
    bottomSection: {
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            alignItems: 'center',
        },
    },
    openAppBtn: {
        background: '#FFDD30',
        color: '#333',
        width: '250px',
        fontSize: '18px',
        fontWeight: 'bold',
        textAlign: 'center',
        padding: '15px 70px !important',
        borderRadius: '1px',
        margin: '5px !important',
    },
    bookBtn: {
        background: '#000000',
        color: '#eee',
        width: '250px',
        fontSize: '18px',
        fontWeight: 'bold',
        padding: '15px 70px !important',
        borderRadius: '1px',
        textAlign: 'center',
        margin: '5px !important',
    },
    emailField: {
        width: '100%',
        margin: '20px 0 40px 0'
    },
    resendEmailButton: {
        fontSize: '18px',
        lineHeight: '45px',
        color: theme.palette.common.white,
        textTransform: 'capitalize',
        background: theme.palette.secondary.main,
        height: '63px',
        maxWidth: '378px',
        width: '100%',
        borderRadius: '0px',
        margin: '10px auto 10px auto',
        '&:hover': {
            backgroundColor: 'rgb(58, 60, 62);',
        },
    },
    backdrop: {
        zIndex: 11,
        color: '#fff',
    },
    modalTitle: {
        textAlign: 'center',
        fontSize: '31px',
        fontFamily: 'DINCondensed-Regular',
        lineHeight: '37px',
        textTransform: 'uppercase',
        fontWeight: '600',
    },
    registerEmail: {
        textAlign: 'center',
        fontSize: '32px',
        color: '#42413D',
        overflowWrap: 'anywhere',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            fontSize: '14px',
        },
    },
    recoverOperation: {
        fontSize: '18px',
        textTransform: 'capitalize',
        height: '63px',
        width: '100%',
        borderRadius: '0px',
        '&:hover': {
            backgroundColor: 'rgb(58, 60, 62);',
            color: '#FFFFFF',
        },
        background: '#F9F9F9',
        padding: '20px 20px 20px 20px',
        border: '1px solid #54575A',
        color: '#54575A',
        boxSixing: 'border-box'
    },
    dialogActions: {
        textAlign:'center', 
        padding: 10, 
        gap: 10
    },
    errorText: {
        color: 'red'
    },
    resendEmailSubTitle: {
        color: '#333',
        marginBottom: '10px',
    }
}));

const Landing = () => {
    const classes = useStyles();
    const [emailStatusUnknown, setEmailStatusUnknown] = useState(true)
    var [emailConfirmed, setEmailConfirmed] = useState(false);
    var [emailVerifyFailureReason, setEmailVerifyFailureReason] = useState(null);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const nonce = urlParams.get('pt');
        restClient.post(confirmEmail(nonce))
            .then((res) => {
                setEmailStatusUnknown(false);
                setEmailConfirmed(true);
            })
            .catch((err) => {
                setEmailStatusUnknown(false);
                setEmailConfirmed(false);
                if (err.response.status == 410) {
                    setEmailVerifyFailureReason('verification expired');
                } else {
                    setEmailVerifyFailureReason('general error');
                }
            });
    }, []);

    // useEffect(() => {
    //     if (isAuthenticated()) {
    //         getUser().then((res) => {
    //            const identityRequest = {
    //                userIdentities: {
    //                    email: res.email,
    //                    customerid: res.bookerID,
    //                },
    //            };
    //            const identityCallback = function (result) {
    //                if (result.getUser()) {
    //                    const currentUser = mParticle.Identity.getCurrentUser();
    //                    currentUser.setUserAttribute('$FirstName', res.firstname);
    //                    currentUser.setUserAttribute('$LastName', res.lastname);
    //                    currentUser.setUserAttribute('Phone', '');
    //                    currentUser.setUserAttribute('Postal Code', '');
    //                    currentUser.setUserAttribute('Most Frequented Shop', '');
    //                    currentUser.setUserAttribute('Text Notification Opt-in', '');
    //                    currentUser.setUserAttribute('Email Opt-in', res.email);

    //                    mParticle.logEvent('Sign Up Complete', mParticle.EventType.Other, {
    //                        'Source Page': getPageLabelFromUrl(location.pathname),
    //                        $FirstName: firstName,
    //                        $LastName: lastName,
    //                        'Email Opt-in': email,
    //                        Phone: '',
    //                        'Postal Code': '',
    //                        'Most Frequented Shop': '',
    //                        'Text Notification Opt-in': '',
    //                    });
    //                }
    //            };
    //            mParticle.Identity.login(identityRequest, identityCallback);
    //         });
    //     }
    // }, [isAuthenticated]);

    if(emailStatusUnknown){
        return null;
    }

    if (!emailConfirmed) {
        return <ResendVerificationEmail classes={classes} failureReason={emailVerifyFailureReason}/>;
    };

    return (
        <>
            <Grid container className={classes.landingSection}>
                <SectionTitle title="YOUR EMAIL IS VALIDATED" />
                <Grid className={classes.landingInner}>
                    <Grid className={classes.textSection}>
                        <Typography style={{ color: '#333' }}>
                            Thanks for signing up to drybar! We're happy to have
                            you.
                        </Typography>
                        <Typography style={{ padding: '50px 0 30px', fontSize: '18px', fontWeight: 'bold' }}>
                            What would you like to do
                            now?
                        </Typography>
                    </Grid>
                    <Grid className={classes.bottomSection}>
                        <Link to="/booking/location">
                            <Typography
                                className={classes.bookBtn}
                            >
                                BOOK
                            </Typography>
                        </Link>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default withOktaAuth(Landing);
