/* eslint-disable prefer-destructuring */
/* eslint-disable max-len */
export const isSubscribeLoaded = (state) => state.subscribe?.loaded || false;
export const getHiddenValue = (state) => state.subscribe?.hiddenvalue || false;
export const getMetaData = (state) => state.subscribe?.metadata;
export const getTopForm = (state) => state.subscribe?.topForm;
export const getBottomForm = (state) => state.subscribe?.bottomForm;
export const getItems = (state) => state.subscribe?.items || [];
export const getHero = (state) => state.subscribe?.banner;

export default {};
