/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import {
    Accordion, withStyles, AccordionDetails, AccordionSummary, Typography, Button, Link,
} from '@material-ui/core';
import React from 'react';
import { Hyperlink } from '../../Links/Hyperlink';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import footerMock from '../../../../__mocks__/footerMock.json';

const styles = () => ({
    accordianContainer: {
        boxShadow: 'none',
        width: '100%',
    },
    grayText: {
        color: '#42413D',
    },
    accordinRoot: {
        minHeight: 'auto',
        margin: 'auto !important',
    },
    headingFont: {
        fontFamily: 'DINCondensed-Regular',
        textTransform: 'uppercase',
        fontSize: '17px',
        fontWeight: '400',
        color: '#42413D',
        letterSpacing: '1.09333px',
    },
    linkText: {
        textTransform: 'none',
        fontSize: '13px',
        letterSpacing: '1.30881px',
        color: '#42413D',
        display: 'block',
        width: '100%',
    },
    button: {
        padding: '15px 0',
        width: '100%',
        textAlign: 'left',
    },
    labeldiv: {
        flexDirection: 'column',
        textAlign: 'left',
    },
});

const ExpansionPanel = withStyles({
    root: {
        padding: 0,
        borderTop: '1px solid #D1D1D1',
        borderBottom: '1px solid #D1D1D1',
        marginBottom: '-1px',
        marginTop: '-1px',
        // boxShadow: 'none',
        // '&:not(:last-child)': {
        //     borderBottom: 0,
        // },
        // '&:before': {
        //     display: 'none',
        // },
        '& .expanded': {
            margin: '20px 0 10px',
        },
    },
    content: {
        fontFamily: 'DINCondensed-Regular',
        margin: '20px 0 10px',
        '& .expanded': {
            margin: '20px 0 10px',
        },
    },
})(AccordionSummary);

const ExpansionDetails = withStyles({
    root: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '8px 0 16px',
    },
})(AccordionDetails);

const MobileFooterLinks = ({ classes, config }) => ((config?.footer?.navigation || []).map((footer, index) => (
    <Accordion
        className={classes.accordianContainer}
        classes={{ root: classes.accordinRoot }}
        key={`footer_${index}`}
    >

        <ExpansionPanel
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id={`${footer.title}-footer`}
        >
            <Typography className={classes.headingFont}>{footer.heading}</Typography>
        </ExpansionPanel>
        <ExpansionDetails>
            {/* {footer.help_copy_sub ? ( */}
            {/*    <Button className={classes.button} classes={{label: classes.labeldiv}} variant="outlined"> */}
            {/*        <Typography style={footer.styleOverride?.help_copy_sub} */}
            {/*                    className={classes.linkText}>{footer.help_copy_sub?.title ? */}
            {/*            <Hyperlink className={classes.grayText} */}
            {/*                  to={footer.help_copy_sub.link}>{footer.help_copy_sub.title}</Hyperlink> : footer.help_copy_sub}</Typography> */}
            {/*        <Typography style={footer.styleOverride?.help_copy_sub_text} */}
            {/*                    className={classes.linkText}>{footer.help_copy_sub_text?.title ? */}
            {/*            <Hyperlink className={classes.grayText} */}
            {/*                  to={footer.help_copy_sub_text.link}>{footer.help_copy_sub_text.title}</Hyperlink> : footer.help_copy_sub_text}</Typography> */}
            {/*    </Button> */}
            {/* ) : null} */}
            {
                footer.entries.map((entry, index) => (
                    <Button key={`entry_${index}`} className={classes.button} variant="outlined">
                        <Typography className={classes.linkText}>
                            <Hyperlink
                                to={entry.link}
                                className={classes.grayText}
                            >
                                {entry.icon ? (
                                    <img
                                        alt="Remy Sharp"
                                        src={entry.icon}
                                        className={classes.footerIcon}
                                    />
                                ) : entry.label}
                            </Hyperlink>
                        </Typography>
                    </Button>
                ))
            }
        </ExpansionDetails>
    </Accordion>
)));

export default withStyles(styles)(MobileFooterLinks);
