/* eslint-disable react/prop-types */
import React from 'react';
import {
    Box,
    Button, Grid, Typography, withStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


const styles = (theme) => ({
    container: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        height: '100%',
        width: '100%',
        background: 'rgba(255, 255, 255, 0.92)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: '10',
        flexWrap: 'wrap',
        overflow: 'scroll',
    },
    calendarContainer: {
        boxShadow: '0px 0px 64px rgba(0, 0, 0, 0.12)',
        background: '#fff',
        padding: '28px',
        maxWidth: '749px',
        width: '749px',
        boxSizing: 'content-box',
        marginTop: '20px',
        marginBottom: '20px',
        [theme.breakpoints.down(749)]: {
            maxWidth: '100%',
            marginTop: '0',
        },
    },
    header: {
        display: 'flex',
        alignItems: 'center',
    },
    headerText: {
        fontFamily: 'DINCondensed-Regular',
        fontSize: '35px',
        fontWeight: '700',
        color: '#42413D',
        width: '100%',
        textAlign: 'center',
        marginLeft: '32px',
    },
    closeIcon: {
        fontSize: '45px',
        color: '#42413D',
        margin: '5px 0',
    },
    image: {
        maxWidth: '221px',
        height: '221px',
        objectFit: 'cover',
        margin: '16px',
        border: '1.5px solid #CACACA',
    },
    oneImage: {
        width: '100%',
    },
    heading: {
        fontFamily: 'MrsEavesOT-Roman',
        borderBottom: '1px solid #D1D1D1',
        lineHeight: '2.5',
        fontSize: '18px',
    },
    description: {
        margin: '30px !important',
        fontSize: '18px !important',
    },
    priceText: {
        borderTop: '1px solid #D1D1D1',
        backgroundColor: '#F9F9F9',
        padding: '22px 29px !important',
        display: 'flex',
        justifyContent: 'space-between',
    },
    rewardsBlock:{
        marginBottom: '40px',
    }
});

const RewardsCardModal = ({
                                   onClose,
                                   classes,
                                   productsForPopup,
                               }) => {
    const handleOutsideClick = (event) => {
        if (event.target === event.currentTarget) {
            onClose();
        }
    };


    return (
        <Grid onClick={handleOutsideClick} className={classes.container}>
            <Grid className={classes.calendarContainer}>
                <Grid className={classes.header}>
                    <Typography className={classes.headerText}>
                        Harrods Rewards
                    </Typography>
                    <Button onClick={onClose} style={{ padding: 0 }}>
                        <CloseIcon className={classes.closeIcon} />
                    </Button>
                </Grid>
                <Grid style={{ margin: 'auto', marginLeft: '30px', marginRight: '40px' }}>
                    <Grid className={classes.description}>
                        <div className={classes.rewardsBlock}>
                            Offering exceptional benefits, Harrods Rewards has been designed to enhance your shopping experience at Harrods locations. <a target='_blank' href="https://www.harrods.com/en-gb/harrods-rewards?icid=footer_customer-service_harrods-rewards" >Sign up today</a> to
                             earn points as you spend. Points can be converted into cash at any time. 
                        </div>
                        <div className={classes.rewardsBlock}>
                            For more information, please see <a target='_blank' href="https://www.harrods.com/en-gb/harrods-rewards?icid=footer_customer-service_harrods-rewards" >Harrods Rewards page</a> on Harrods.com.
                        </div>
                        <div className={classes.rewardsBlock}>
                           <p>Don’t know your card number? <a target='_blank' href="https://digitalrewards.harrods.com/en-gb/retrieve-card-number" >Retrieve your Rewards card number</a>.</p> 
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default withStyles(styles)(RewardsCardModal);
