import { gql } from '@apollo/client';
import { getPreviewMode } from '../../state/utils/contentful';

const screenAddOnsCollections = () => gql`{
    screenProductCollection(${getPreviewMode()}, limit: 2) {
      items {
        slug
        title
        metadata {
          sys {
            id
          }
        }
        description {
          json
        }
        marketingComponentsCollection {
          items{
            ...on MarketingImage {
              alternateTitle
              desktopMedia {
                url
              }
              mobileMedia {
                url
              }
            }
            ...on MarketingCard {
              sys{
                id
              }
              image {
                alternateTitle
                desktopMedia {
                  url
                }
                mobileMedia {
                  url
                }
              }
            }
          }
        }
        productsCollection {
          items {
            title
            subtitle
            price
            slug
            description {
              json
            }
            bestFor
            imagesCollection(limit: 3) {
              items {
                alternateTitle
                desktopMedia {
                  url
                }
                mobileMedia {
                  url
                }
              }
            }
            serviceTime
          }
        }
      }
    }    
  }
`;

const addOnsPageQuery = () =>
    `{
      drybarPageCollection(preview: true, where: { slug: "/service/add-ons" }) {
        items {
          title
          subtitle
          tcsAndPoliciesCopy
          seoTitle
          seoDescription
          canonicalUrl
          addANoindexTag
          slug
          formatofPageCollection(preview: true) {
            items {
              __typename
              ... on HeroBanner {
                sys {
                  id
                }
              }
              ... on DrybarPictureGrid {
                sys {
                  id
                }
              }
            }
          }
        }
      }
    }`;

const blowServicesPageQuery = () =>
    `{
      drybarPageCollection(preview: true, where: { slug: "blow-services" }) {
        items {
          title
          subtitle
          tcsAndPoliciesCopy
          seoTitle
          seoDescription
          canonicalUrl
          addANoindexTag
          slug
          formatofPageCollection(preview: true) {
            items {
              __typename
              ... on HeroBanner {
                sys {
                  id
                }
              }
              ... on DrybarPictureGrid {
                sys {
                  id
                }
              }
              ... on GenericBanner {
                sys {
                  id
                }
              }
            }
          }
        }
      }
    }`;

const fetchBannerData = (id) => `
{
  heroBanner(id: "${id}", preview: true) {
    targetPageUrl
    slugPrefix
    mediaCollection(preview: true) {
      items {
              internalName
              desktopImageUrl
              mobileImageUrl
              videoUrl
            }
    }
  }
}
`;

const fetchGenericBannerData = (id) => `
{
  genericBanner(id: "${id}", preview: true) {
    targetPageUrl
    mediaCollection(preview: true) {
      items {
              internalName
              desktopImageUrl
              mobileImageUrl
              videoUrl
            }
    }
  }
}
`;

const fetchItems = (id) => `
{drybarPictureGrid(id: "${id}", preview: true) {
  title
  subtitle
  numberOfColumns
  drybarGridCardsCollection(preview: true) {
    items {
      __typename
      ... on DrybarGenericGridCard {
        title
        subtitle
        price
        bestFor
        serviceTime
        type
        description
        slugPrefix
        bookerReference
        productId
        sandboxProductId
        imagesCollectionCollection {
          items {
            internalName
            desktopImageUrl
            mobileImageUrl
            videoUrl
          }
        }
      }
    }
  }
}}
`

export { screenAddOnsCollections, addOnsPageQuery, fetchBannerData, fetchItems, blowServicesPageQuery, fetchGenericBannerData };
