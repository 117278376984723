import {
    queryMarketingSectionFromContentful,
    queryHelpCenterCollectionFromContentful,
    queryFaqArticlesFromContentful
} from './contentfulFaqQueries';
import { doQuery, getMetaData } from '../../state/utils/contentful';

export const parseHelpCenterCollectionFromContentful = (data) => {
    const helpCenterCollectionData = {
        title: data?.title,
        subtitle: data?.subtitle,
        id: '',
        marketingSectionId: '',
        metadata : {
            pageDescription: data?.seoDescription,
            pageKeywords: [],
            pageTitle: data?.seoTitle,
            searchKeywords: []
        }
    };
    try {
            const marketingItem = data?.formatofPageCollection?.items?.find(i => i.__typename === 'HeroBanner');

            helpCenterCollectionData.id = data.sys?.id || '';
            helpCenterCollectionData.marketingSectionId = marketingItem.sys?.id || '';
            
    } catch (err) {
        console.error(err);
    }

    return helpCenterCollectionData;
};

export const parseMarketingSectionFromContentful = (data) => {
    let heroImage = {};

    try {
        heroImage = {
            alternateTitle: '',
            desktop: data?.heroBanner?.mediaCollection?.items?.[0]?.desktopImageUrl,
            mobile: data?.heroBanner?.mediaCollection?.items?.[0]?.mobileImageUrl,
        };
    } catch (err) {
        console.error(err);
    }
    return heroImage;
};

const parseArticlesFromContentful = (data) => {
    const articleGroups = data?.drybarPage?.formatofPageCollection?.items?.filter(i => i.__typename === 'FaqAccordion');
    const articlesData = [];

    try {
        articleGroups.forEach((articleGroup) => {
            const articlesCollection = articleGroup?.faQsCollection?.items || [];

            const articleData = {
                id: articleGroup?.sys?.id,
                title: articleGroup?.sectionHeading,
                subtitle: '',
                article: '',
                articlesCollection,
            };

            articlesData.push(articleData);
        });
    } catch (err) {
        console.error(err);
    }

    return articlesData;
};

export const gqlLoadFaq = async () => {
    let helpCenterSectionData = {
        title: '',
        subtitle: '',
        articles: [],
        heroImage: {},
        metadata: {},
    };

    let newData = await doQuery(queryHelpCenterCollectionFromContentful(), true);

    const pageData = newData.drybarPageCollection?.items[0];
    helpCenterSectionData = parseHelpCenterCollectionFromContentful(pageData);

    if (helpCenterSectionData.marketingSectionId) {
        newData = await doQuery(queryMarketingSectionFromContentful(helpCenterSectionData.marketingSectionId), true);
        helpCenterSectionData.heroImage = parseMarketingSectionFromContentful(newData);
    }

    if (helpCenterSectionData.id) {
        newData = await doQuery(queryFaqArticlesFromContentful(helpCenterSectionData.id), true);
        helpCenterSectionData.articles = parseArticlesFromContentful(newData);
    }

    console.log(helpCenterSectionData)

    return helpCenterSectionData;
};
