import {
    array, bool, func, number, object,
} from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withOktaAuth } from '@okta/okta-react';
import useMoktaAuth from 'utils/oktaAuth';
import { Grid, withStyles } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import TagManager from 'app/Components/GoogleTagManager';
import config from 'app.config';
import { getServices, getAvailableDates } from '../../../api/booking-api';
import {
 getNumberOfGuests, getServicesData, isGuestWithDifferentServices, getLocationData, getSelectedDate, getServicesAvailableDates, getAvailableServiceIds, getBuyoutLocation, getExtensionAddon, getAddonsByUser,
} from '../../../state/ducks/Booking/Booking-Selectors';
import {
 setRegisterUserInfo, setServicesAvailableDates, setAddOnsServiceUser, setExtensionAddon,
} from '../../../state/ducks/Booking/Booking-Actions';

import { getWeekByFirstDate } from '../../../Helpers/dateTime';
import restClient from '../../../api/restClient';
import SummaryWrapper from '../SummaryWrapper';
import MultipleUserServices from './MultipleUserServices';
import ServiceSkeleton from '../../../app/Components/Skeleton/ServiceSkeleton';
import { MOBILE_BREAKPOINT } from '../../../Helpers/breakpoints';
import ServiceButton from './Partials/ServiceButton';
import { getLocations as getBuyoutData } from '../../../state/ducks/Buyout/Buyout-Selectors';

const { gtm: { booking } } = config;
const tagManagerArgs = {
    dataLayer: {
        event: booking.event,
        form_type: booking.type,
        form_step: booking.step3,
    },
};

const PAGE_TITLE = 'WHAT SERVICE?';

const styles = (theme) => ({
    buttonsWrapper: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        padding: '60px',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            padding: '10px 0',
            height: 'unset',
        },
    },
});

const SelectServicesContainer = ({
    classes,
    guests,
    isDifferentServiceEnabled,
    selectedServices,
    goToNextPage,
    getLocationId,
    setAuthUserInfo,
    selectedDate,
    setServicesAvailableDates,
    buyout,
    fromBuyout,
    extensionAddon,
    setExtension,
    getAddonsByUserFromState,
    setSelectedAddonForUser
}) => {
    const hasLocationId = getLocationId?.bookerLocationId;
    const [availableDatesError, setErrors] = useState(null);
    const [isAuthenticated, getUser, getUserId] = useMoktaAuth();
    useEffect(() => {
        TagManager.dataLayer(tagManagerArgs);
        getUserId().then((res) => {
            setAuthUserInfo(res);
       });
    }, []);

    useEffect(() => {
        setExtension(null);
    }, [getLocationId]);

    useEffect(() => {
        const selDate = selectedDate ? new Date(selectedDate) : new Date();
        restClient.post(
            getAvailableDates(getWeekByFirstDate(selDate)[0], getWeekByFirstDate(selDate)[6], hasLocationId),
        ).then((res) => {
            if (res) {
                setServicesAvailableDates(res.data);
            }
        }).catch((err) => {
            setErrors(err);
        });
    }, [selectedDate, setErrors]);

    const clearAddOns = () => {
        selectedServices.forEach((service) => {
            if(service.data.Name === "Dry Styling"){
                const addOns = getAddonsByUserFromState(service.user);
                if(addOns?.length) {
                    addOns.forEach((addOn) => {
                        const addOnObj = {
                            user: service.user,
                            data: addOn,
                        };
                        setSelectedAddonForUser(addOnObj);
                    });      
                }
            }
        });
    }

    useEffect(() => {
        clearAddOns();
    }, [selectedServices]);

    const handleGoToNextPage = () => {
        clearAddOns();
        goToNextPage();
    }

    return (
        <SummaryWrapper
            title={PAGE_TITLE}
            nextButtonEnabled={guests && isDifferentServiceEnabled && selectedServices.length === guests + 1}
            useFetch={getServices(hasLocationId)}
            onButtonClick={handleGoToNextPage}
        >
            {({ data, error, loading }) => {
                if (loading) {
                    return <ServiceSkeleton />;
                }

                if (error) {
                    return null;
                }

                if (data?.Treatments?.length) {
                    const resData = [];
                    let extensionService;

                    data?.Treatments.forEach((s) => {
                        if (s.Name === 'Extensions') {
                            extensionService = s;
                        } else {
                            resData.push(s);
                        }
                    });

                    if (extensionService && !extensionAddon) {
                        setExtension(extensionService);
                    }

                    const services = resData.filter((isOnline) => isOnline?.AllowCustomersToBookOnline === true && (!fromBuyout || (fromBuyout && buyout && buyout.treatments.includes(`${ isOnline.ID}`)))).sort((a, b) => {
                        if (a.Name > b.Name) return 1;
                        if (a.Name < b.Name) return -1;
                        return 0;
                    });

                    if (guests) {
                        return (
                            <MultipleUserServices
                                guests={guests}
                                isDifferentServiceEnabled={isDifferentServiceEnabled}
                                services={services}
                                selectedServices={selectedServices}
                                goToNextPage={handleGoToNextPage}
                            />
                        );
                    }

                    return (
                        <Grid className={classes.buttonsWrapper}>
                            {services.map((service) => (
                                <ServiceButton
                                    goToNextPage={handleGoToNextPage}
                                    service={service}
                                    isSelected={selectedServices.some((s) => s.user === 'Me' && s.data.Name === service.Name)}
                                />
                            ))}
                        </Grid>
                    );
                }
                return <></>;
            }}
        </SummaryWrapper>
    );
};

SelectServicesContainer.propTypes = {
    guests: number,
    isDifferentServiceEnabled: bool,
    selectedServices: array,
    classes: object.isRequired,
    goToNextPage: func,
};

SelectServicesContainer.defaultProps = {
    guests: 0,
    isDifferentServiceEnabled: false,
    selectedServices: [],
    goToNextPage: () => { },
};

const mapStateToProps = (state) => ({
    guests: getNumberOfGuests(state),
    isDifferentServiceEnabled: isGuestWithDifferentServices(state),
    availableDates: getServicesAvailableDates(state),
    selectedServices: getServicesData(state),
    getLocationId: getLocationData(state),
    selectedDate: getSelectedDate(state),
    availableServiceIds: getAvailableServiceIds(state),
    fromBuyout: getBuyoutLocation(state),
    buyout: getBuyoutData(state),
    extensionAddon: getExtensionAddon(state),
    getAddonsByUserFromState: (user) => getAddonsByUser(state, user),
});

const mapDispatchToProps = (dispatch) => ({
    setAuthUserInfo: bindActionCreators(setRegisterUserInfo, dispatch),
    setServicesAvailableDates: bindActionCreators(setServicesAvailableDates, dispatch),
    setExtension: bindActionCreators(setExtensionAddon, dispatch),
    setSelectedAddonForUser: bindActionCreators(setAddOnsServiceUser, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withOktaAuth(SelectServicesContainer)));
