import React from 'react';
import { Link } from 'react-router-dom';
import {
 Box, Button, Typography, withStyles,
} from '@material-ui/core';
import { MOBILE_BREAKPOINT } from 'Helpers/breakpoints';
import { Container, Title } from 'components';
import NotFoundImg from 'assets/images/404.png';

const styles = (theme) => ({
  section: {
    textAlign: 'center',
    padding: '80px 20px',
    '& a': {
      textDecoration: 'none',
    },
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      padding: '40px 20px',
    },
  },
  title: {
    color: theme.typography.color,
  },
  text: {
    fontSize: theme.fontSize.lg1,
    margin: '1em 0 !important',
  },
  smallText: {
    fontSize: theme.fontSize.md1,
    letterSpacing: '3.9px',
    textTransform: 'uppercase',
    fontFamily: theme.typography.fontFamily[2],
    color: theme.typography.color,
  },
  dotLine: {
    width: '200px',
    margin: '0 15px',
    borderBottom: 0,
    borderTop: `3px dotted ${theme.palette.common.lightGrey[2]}`,
  },
  button: {
    width: '378px',
    maxWidth: '100%',
    height: '63px',
    margin: '20px 10px',
    fontSize: theme.fontSize.md1,
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      width: '100%',
      margin: '15px 0',
    },
  },
  box: {
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      marginTop: '30px',
    },
  },
  image: {
    width: '700px',
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      width: '100%',
    },
  },
});

function NotFound({ classes }) {
  return (
      <Container cn={classes.section}>
          <Title className={classes.title}>WELL, THIS BLoWs</Title>
          <Typography className={classes.text}>So sorry, the page you're looking for doesn't seem to exist!</Typography>

          <Link to="/booking/location">
              <Button variant="contained" color="primary" className={classes.button}>
                  Book an Appointment
              </Button>
          </Link>
          <Link to="/">
              <Button variant="contained" color="secondary" className={classes.button}>
                  Back to Home
              </Button>
          </Link>

          {/* <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        mt={10}
        mb={2}
        className={classes.box}
      >
        <hr className={classes.dotLine} />
        <span className={classes.smallText}>Our Special Offer</span>
        <hr className={classes.dotLine} />
      </Box> */}
          {/* <img src={NotFoundImg} className={classes.image} alt="404" /> */}
      </Container>
  );
}

export default withStyles(styles)(NotFound);
