/* eslint-disable import/no-unresolved */
import NotFound from 'pages/not-found';
import SubscribeView from 'pages/subscribe/Subscribe';
import Booking from '../pages/booking/Bookng';
import BookingConfirmation from '../pages/booking/Confirmation/BookingConfirmation';

import LogInContainer from '../app/Components/Auth/LogInContainer';
import Landing from '../app/Components/Auth/Landing';
import SignUpContainer from '../app/Components/Auth/Partials/Registration/RegistrationUser';
import Account from '../pages/account/Account';
import Service from '../pages/service/Service';
// eslint-disable-next-line import/no-cycle
import Home from '../pages/home/Home';
import LogoutCallback from '../pages/home/LogoutCallback';
import Events from '../pages/events/Events';
import HelpCenter from '../pages/faqs/help-center';
import FaqDetailsSectionView from '../pages/faqs/faq-details-section';
import TheStyles from '../pages/the-styles/TheStyles';
import DryStyling from '../pages/dry-styling/dry-styling';
import BarflyMembershipEnrollment from '../pages/BarflyMembership/GraphqlBarflyMembership';
import BarflyEnrollmentPage from '../pages/BarflyMembership/BarflyEnrollmentPage';
import InstoreBarflyMembershipEnrollment from '../pages/BarflyMembership/InstoreGraphqlBarflyMembership';
import InstoreBarflyEnrollmentPage from '../pages/BarflyMembership/InstoreBarflyEnrollmentPage';
import PrivacyPolicy from '../pages/privacy-policy/privacy-policy';
import ExtensionPolicy from '../pages/booking/extension-policy/extension-policy';
import TermsOfServices from '../pages/terms-of-services/terms-of-services';
import ConnectedRecoverPassword from '../app/Components/Auth/Partials/RecoverPassword/ResetPassword';
import AccessibilityPolicy from '../pages/accessability-policy/accessibility-policy';
import CancellationPolicy from '../pages/cancellation-policy/cancellation-policy';
import MembershipPolicy from '../pages/membership-policy/membership-policy';
import Contact from '../pages/contact/contact';
import BuyOut from '../pages/buyout/Buyout';
import SearchResult from '../pages/search-result';
import PersonalInformationRights from '../pages/personal-information-rights/personal-information-rights';
import PasswordResetLanding from 'app/Components/Auth/Partials/PasswordResetLanding/PasswordResetLanding';
import DrybarMailingListSignUp from 'pages/marketing/drybar-mailing-list-sign-up';
import UnsubscribeFromMarketing from 'pages/marketing/unsubscribe-from-marketing';
import SetPassword from '../pages/account/common/SetPassword';

export default [
    {
        path: '/service/:page',
        Component: Service,
        label: 'Service',
    },
    {
        path: '/service/locator/detail/:id',
        Component: Service,
    },
    {
        path: '/auth/login',
        Component: LogInContainer,
        label: 'Sign In',
    },
    {
        path: '/auth/recover-password',
        Component: ConnectedRecoverPassword,
        label: 'Recover Password',
    },
    {
        path: '/auth/reset-landing',
        Component: PasswordResetLanding,
        label: 'Reset Landing',
    },
    {
        path: '/auth-landing',
        Component: Landing,
        label: 'Auth Landing',
    },
    {
        path: '/auth/sign-up',
        Component: SignUpContainer,
        label: 'Sign Up',
    },
    {
        path: '/appointment-confirm',
        Component: BookingConfirmation,
        label: 'Booking Confirmation',
    },
    {
        path: '/booking/:page',
        Component: Booking,
    },
    {
        path: '/events',
        Component: Events,
        label: 'Events',
    },
    {
        path: '/help-centre/:id',
        Component: FaqDetailsSectionView,
    },
    {
        path: '/help-centre',
        Component: HelpCenter,
        label: 'Help Centre',
    },
    {
        path: '/dry-styling',
        Component: DryStyling,
        label: 'Dry Styling',
    },
    {
        path: '/the-styles',
        Component: TheStyles,
        label: 'The Styles',
    },
    {
        path: '/subscribe/:slug',
        Component: SubscribeView,
        label: 'Subscribe',
    },
    {
        path: '/barfly-confirm',
        Component: BarflyMembershipEnrollment, 
        label: 'Barfly Confirmation',
    },
    {
        path: '/instore-barfly-confirm',
        Component: InstoreBarflyMembershipEnrollment,
        label: 'Barfly Confirmation',
    },
    {
        path: '/barfly-membership',
        Component: BarflyMembershipEnrollment,
        label: 'Barfly Account Information',
    },
    {
        path: '/instore-barfly-membership',
        Component: InstoreBarflyMembershipEnrollment,
        label: 'Barfly Account Information',
    },
    {
        path: '/search-result',
        Component: SearchResult,
        label: 'Search Result',
    },
    {
        path: '/privacy-policy',
        Component: PrivacyPolicy,
        label: 'Privacy Policy',
    },
    {
        path: '/extension-policy',
        Component: ExtensionPolicy,
        label: 'Extension Policy',
    },
    {
        path: '/terms-of-service',
        Component: TermsOfServices,
        label: 'Terms of Services',
    },
    {
        path: '/accessibility-policy',
        Component: AccessibilityPolicy,
        label: 'Accessibility Policy',
    },
    {
        path: '/cancellation-policy',
        Component: CancellationPolicy,
        label: 'Cancellation Policy',
    },
    /*{
        path: '/personal-information-rights',
        Component: PersonalInformationRights,
        label: 'Personal Information Rights',
    },*/
    {
        path: '/membership-policy',
        Component: MembershipPolicy,
        label: 'Membership Policy',
    },
    {
        path: '/mailing-list-sign-up',
        Component: DrybarMailingListSignUp,
        label: 'Drybar Mailing List Sign Up',
    },
    {
        path: '/contact',
        Component: Contact,
        label: 'Contact',
    },
    {
        path: '/buyout/:slug',
        Component: BuyOut,
        label: 'Buyout',
    },
    {
        path: '/logout/callback',
        Component: LogoutCallback
    },
    {
        path: '/',
        Component: Home,
    },
    {
        path: '/404',
        Component: NotFound,
    },
    {
        path: '/barfly-membership-enrollment',
        Component: BarflyEnrollmentPage,
        privateRoute: true,
    },
    {
        path: '/instore-barfly-membership-enrollment',
        Component: InstoreBarflyEnrollmentPage,

    },
    {
        path: '/account/:page',
        Component: Account,
        privateRoute: true,
    },
    {
        path: '/set-password',
        Component: SetPassword,
    },
    {
        path: '/unsubscribe',
        Component: UnsubscribeFromMarketing
    }
];
