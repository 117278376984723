/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Media from 'react-media';
import { bindActionCreators } from 'redux';
import { object } from 'prop-types';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useLocation } from 'react-router-dom';
import {
    Button, Grid, Typography, withStyles,
} from '@material-ui/core';
import TagManager from 'app/Components/GoogleTagManager';
import config from 'app.config';
import { getPageLabelFromUrl } from 'utils/helper';
import SectionTitle from '../../app/Components/SectionTitle';
import { setRequestNoteMessage } from '../../state/ducks/Booking/Booking-Actions';
import { getNotesMessage } from '../../state/ducks/Booking/Booking-Selectors';

const { gtm: { booking } } = config;
const tagManagerArgs = {
    dataLayer: {
        event: booking.event,
        form_type: booking.type,
        form_step: booking.step6,
    },
};

const styles = (theme) => ({
    gd:
    {
        margin: '0 auto',
        background: '#fff',
        boxShadow: '0px 0px 20px rgba(0,0,0,.2)',
        padding: '30px',
        textAlign: 'center',
    },
    requestNoteDetails: {
        float: 'right',
        background: '#FFFFFF',
        boxShadow: '2px 2px 17px rgba(235, 235, 235, 0.5)',
        margin: 'auto',
        maxWidth: '800px',
        padding: '30px 22px',
        textAlign: 'center',
        backgroundColor: '#fafafa',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            padding: '0px 18px'
        },
    },
    requestNoteCopy: {
        fontFamily: 'sans-serif',
        fontSize: '16px',
        textAlign: 'center',
        color: '#42413D',
    },
    requestContainer: {
        background: '#F7F8F9',
        height: '302px',
        width: '100%',
        padding: '38px 26px',
        borderTop: '1px solid #D1D1D1',
        [theme.breakpoints.down('sm')]: {
            padding: '38px 0',
            padding: 0,
            borderTop: 'none',
            minHeight: '160px',
            height: '200px'
        },
    },
    textArea: {
        border: '1px solid #D1D1D1',
        resize: 'none',
        height: '100%',
        width: '657.47px',
        padding: '32px 26px',
        fontSize: '18px',
        fontFamily: 'URWForm',
        fontStyle: 'oblique',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            padding: '20px',
        },
    },
    nextClick: {
        fontSize: '18px',
        lineHeight: '45px',
        color: '#54575A',
        textTransform: 'capitalize',
        background: '#FFDD30',
        height: '63px',
        width: '378px',
        marginTop: '48px',
        borderRadius: '0px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginTop: '12px'
        },
    },
    haveAnyRequest: {
        fontFamily: 'DINCondensed-Regular',
        fontSize: '39px',
        textTransform: 'uppercase',
        color: '#42413D',
    },
    requestServiceContainer: {
        background: '#F9F9F9',
        marginBottom: '86px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: 0,
        },
    },
    topText: {
        marginBottom: '15px',
        fontSize: '20px',
        fontSize: '15px',
        lineHeight: '21px',
        textAlign: 'center',
        width: '92%',
        margin: '0 auto 30px !important',
        display: 'block',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
});

const CustomerSchema = Yup.object().shape({
    notesMessage: Yup.string().max(200, 'Loo long!'),
});

const pageTitle = 'HAVE ANY REQUEST?';

const paragraphText = 'Is it your first time? Anything special we should know about? Would you like to request a favourite stylist? While we can’t guarantee it, we’ll do our very best to make it happen!';

const NotesCard = ({
 classes, goToNextPage, setNotesMessageContainer, getSavedNoteMessage,
}) => {
    const location = useLocation();

    TagManager.dataLayer(tagManagerArgs);

    const setNotesContainerMessage = (values) => {
        if (typeof values.notesMessage !== 'string') {
            setNotesMessageContainer('');
        } else {
            setNotesMessageContainer(values.notesMessage);
        }
        goToNextPage();
    };

    return (
        <Formik
            initialValues={{
                notesMessage: typeof getSavedNoteMessage !== 'string' ? '' : getSavedNoteMessage,
            }}
            enableReinitialize
            onSubmit={setNotesContainerMessage}
            validationSchema={CustomerSchema}
        >
            {
                ({
                    values,
                    errors,
                    handleSubmit,
                    handleChange,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <Grid container className={classes.requestServiceContainer}>
                            <Media query={{ maxWidth: 599 }}>
                                {(matches) => (matches ? (
                                    <>
                                        <SectionTitle title={pageTitle} />
                                        <Grid className={classes.requestNoteDetails}>
                                            <Typography className={classes.topText}>
                                                {paragraphText}
                                            </Typography>
                                            <Grid className={classes.requestContainer}>
                                                <textarea
                                                    rows="30"
                                                    cols="79"
                                                    placeholder="Your request here..."
                                                    draggable="false"
                                                    className={classes.textArea}
                                                    name="notesMessage"
                                                    value={values.notesMessage}
                                                    onChange={handleChange}
                                                />
                                                {
                                                    errors && errors.notesMessage && (
                                                    <Typography color="error">
                                                        {errors.notesMessage}
                                                    </Typography>
)
                                                }
                                            </Grid>
                                            <Grid>
                                                <Button
                                                    className={classes.nextClick}
                                                    type="submit"
                                                    variant="outlined"
                                                >
                                                    Next
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </>
                                )
                                    : (
                                        <>
                                            <SectionTitle title={pageTitle} />
                                            <Grid
                                                className={classes.requestNoteDetails}
                                                style={{
                                                    padding: '30px',
                                                    margin: '0 auto',
                                                    background: '#fff',
                                                    width: '754px',
                                                    textAlign: 'center',
                                                    marginBottom: '50px',
                                                }}
                                            >
                                                <Typography className={classes.topText}>
                                                    {paragraphText}
                                                </Typography>
                                                <Grid className={classes.requestContainer} style={{ marginTop: '30px' }}>
                                                    <textarea
                                                        rows="20"
                                                        cols="70"
                                                        placeholder="Your request here..."
                                                        draggable="false"
                                                        className={classes.textArea}
                                                        name="notesMessage"
                                                        value={values.notesMessage}
                                                        onChange={handleChange}
                                                    />
                                                    {
                                                        errors && errors.notesMessage && (
                                                        <Typography color="error">
                                                            {errors.notesMessage}
                                                        </Typography>
)
                                                    }
                                                </Grid>
                                                <Grid>
                                                    <Button
                                                        className={classes.nextClick}
                                                        type="submit"
                                                        variant="outlined"
                                                    >
                                                        Next
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </>
                                    ))}
                            </Media>
                        </Grid>
                    </form>
                )
            }
        </Formik>

    );
};

NotesCard.propTypes = {
    classes: object.isRequired,
};

const mapStateToProps = (state) => ({
    getSavedNoteMessage: getNotesMessage(state),
});

const mapDispatchToProps = (dispatch) => ({
    setNotesMessageContainer: bindActionCreators(setRequestNoteMessage, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NotesCard));
