import React, { useEffect } from 'react';
import Image from 'lqip-react';
import { useMediaQuery } from 'react-responsive';

const CustomImg = ({
 src, useLqip, aspectRatio, ...rest
}) => {
    if (!src) return '';

    const imageSet = [];

    imageSet.push(`${src}?q=50&w=295 295w`);
    imageSet.push(`${src}?q=50&w=590 590w`);
    imageSet.push(`${src}?q=50&w=1180 1180w`);
    imageSet.push(`${src}?q=50&w=1295 1295w`);

    if (useLqip && aspectRatio) {
        let size = '?q=50&w=295';

        const small = useMediaQuery({
            query: '(min-width: 295px)',
        });

        const medium = useMediaQuery({
            query: '(min-width: 590px)',
        });

        const large = useMediaQuery({
            query: '(min-width: 1180px)',
        });

        if (small) {
            size = '?q=50&w=590';
        }

        if (medium) {
            size = '?q=50&w=1180';
        }

        if (large) {
            size = '?q=50&w=1295';
        }

        return (
            <div className="lqip-wrapper">
                <picture>
                    <Image
                        src={`${src}${size}`}
                        thumbnail={`${src}?q=15&w=10&fm=jpg`}
                        aspectRatio={aspectRatio}
                        lazyLoad="all"
                        color="#F7F8F9"
                        {...rest}
                    />
                </picture>
            </div>
        );
    }

    return (
        <img
            loading="lazy"
            sizes="(max-width: 1180px) 100vw, 1180px"
            srcSet={imageSet.join(', ')}
            src={src}
            {...rest}
        />
    );
};

export default CustomImg;
