import { createTheme } from '@material-ui/core/styles'

const theme = createTheme({
    palette: {
        primary: {
            main: '#FFDD30',
        },
        secondary: {
            main: '#54575A',
            100: '#E5E5E5',
            200: 'F8F8F8',
        },
        common: {
            black: '#000',
            white: '#FFFFFF',
            grey: '#42413D',
            lightGrey: ['#D1D1D1', '#54575A', '#BDBDBD', '#F9F9F9', '#989898'],
            hover: ['rgb(58, 60, 62)', '#b29a21'],
            red: '#B83F3F'
        },
    },
    typography: {
        fontFamily: ['URWForm', 'DINCondensed-Regular', 'MrsEavesOT-Roman'],
        color: '#42413D',
    },
    maxWidth: 1295,
    fontSize: {
        xl: 70,
        lg: 42,
        lg1: 32,
        md: 20,
        md1: 18,
        sm: 16,
        xs: 12,
    },
    overrides: {
        MuiButton: {
            root: {
                '&:focus': {
                    outline: 'none',
                },
            },
            contained: {
                color: '#54575A',
                fontFamily: 'URWForm',
                fontSize: '13px',
                margin: '3px 0',
                fontWeight: '400',
                textTransform: 'none',
                borderRadius: '0',
                maxWidth: 'fit-content',
                padding: '8px 22px',
                whiteSpace: 'nowrap',
                boxShadow: 'none',
            },
            text: {
                color: '#42413D',
                fontFamily: 'URWForm',
                fontWeight: '200',
                textTransform: 'none',
                borderRadius: '0',
                maxWidth: 'fit-content',
                whiteSpace: 'nowrap',
                fontSize: '16px',
                textDecoration: 'underline',
                textUnderlinePosition: 'under',
                padding: '4px',
                alignSelf: 'flex-end',
                margin: '0',
                '&:hover': {
                    textDecoration: 'underline',
                    color: '#42413D',
                    textUnderlinePosition: 'under',
                    backgroundColor: 'none',
                },
            },
            outlined: {
                border: 'none',
            },
        },
    },
});

export default theme;
