import { subscribeTopLevelQuery, subscribeSecondLevelQuery } from './contentfulSubscribeQueries';
import { doQuery } from '../../state/utils/contentful';

const parseIDs = (data) => {
    const homeComponent = data?.items?.length > 0 ? data?.items[0] : {};
    const items = homeComponent.marketingComponentsCollection?.items || [];
    const ids = items.filter((item) => !!item).map((item) => ({ id: item.sys?.id || '', internalName: item.internalName ? item.internalName : '' }));

    return ids.filter((id) => id);
};

export const gqlLoadSubscribe = async ({ slug }) => {
    const subscribeSectionData = {
        banner: null,
        topForm: null,
        items: [],
        bottomForm: null,
        hiddenvalue: '',
    };

    let data = await doQuery(subscribeTopLevelQuery(slug));

    const subscribeData = data.subscribeLandingPageCollection;
    if (subscribeData && subscribeData.items.length > 0) {
        const firstLevelIds = parseIDs(subscribeData || {});
        subscribeSectionData.hiddenvalue = subscribeData.items[0].hiddenvalue;

        if (firstLevelIds.length > 0) {
            for (let i = 0; i < firstLevelIds.length; i++) {
                data = await doQuery(subscribeSecondLevelQuery(firstLevelIds[i].id));
                if (firstLevelIds[i].internalName.toLowerCase().includes('hero')) {
                    subscribeSectionData.banner = {
                        desktop: data.marketingSection.marketingComponentsCollection.items[0]?.image?.desktopMedia?.url,
                        mobile: data.marketingSection.marketingComponentsCollection.items[0]?.image?.mobileMedia?.url,
                    };
                } else if (firstLevelIds[i].internalName.toLowerCase().includes('bottom form')) {
                    subscribeSectionData.bottomForm = {
                        title: data.marketingSection.marketingComponentsCollection.items[0]?.title,
                        description: data.marketingSection.marketingComponentsCollection.items[0]?.description,
                        image: {
                            desktop: data.marketingSection.marketingComponentsCollection.items[0]?.image?.desktopMedia?.url,
                            mobile: data.marketingSection.marketingComponentsCollection.items[0]?.image?.mobileMedia?.url,
                        },
                        settings: data.marketingSection.marketingComponentsCollection.items[0]?.settings,
                    };
                } else if (firstLevelIds[i].internalName.toLowerCase().includes('form')) {
                    subscribeSectionData.topForm = {
                        title: data.marketingSection.marketingComponentsCollection.items[0]?.title,
                        description: data.marketingSection.marketingComponentsCollection.items[0]?.description,
                        image: {
                            desktop: data.marketingSection.marketingComponentsCollection.items[0]?.image?.desktopMedia?.url,
                            mobile: data.marketingSection.marketingComponentsCollection.items[0]?.image?.mobileMedia?.url,
                        },
                        settings: data.marketingSection.marketingComponentsCollection.items[0]?.settings,
                    };
                } else {
                    subscribeSectionData.items = [
                        ...subscribeSectionData.items,
                        ...data.marketingSection.marketingComponentsCollection.items.map((item) => ({
                            title: item.title,
                            description: item.description,
                            image: {
                                desktop: item.image?.desktopMedia?.url,
                                mobile: item.image?.mobileMedia?.url,
                            },
                            settings: item.settings,
                        })),
                    ];
                }
            }
        }
    }

    return subscribeSectionData;
};
