import React from 'react';
import { withStyles } from '@material-ui/core';
import NoImage from 'assets/images/noimage.png';

const styles = () => ({
  bgImageWrapper: {
    overflow: 'hidden',
    border: '1.5px solid #E3E3E3',
  },
  bgImage: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
    backgroundSize: 'cover',
    flexGrow: '0',
    flexShrink: '0',
    transition: 'all .2s ease-in-out',
    margin: '0 auto',
    '&:hover': {
      transform: 'scale(1.1, 1.1)',
    },
  },
});

function BackImage({
  classes,
  image,
  width = '200px',
  height = '200px',
  isStore,
}) {
  return (
      <div className={classes.bgImageWrapper} style={{ width }}>
          <div
              className={classes.bgImage}
              style={{
          backgroundImage: `url('${image}'), url('${NoImage}')`,
          width: isStore ? '140px' : width,
          height,
        }}
          />
      </div>
  );
}

export default withStyles(styles)(BackImage);
