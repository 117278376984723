/* eslint-disable prefer-destructuring */
/* eslint-disable max-len */

export const getTitle = (state) => state.buyout?.title || '';
export const getDescription = (state) => state.buyout?.description || [];
export const getBanners = (state) => state.buyout?.banners || {};
export const getHeadlines = (state) => state.buyout?.headlines || {};
export const getLocations = (state) => state.buyout?.locations || null;
export const isBuyoutLoaded = (state) => state.buyout?.loaded || false;
export const getExtensionAddon = (state) => state.buyout?.extensionAddon;
export const getMetaData = (state) => state.buyout?.metadata || {};

export default {};
